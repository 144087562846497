<table class="mat-calendar-table" role="presentation">
  <thead class="mat-calendar-table-header">
    <tr>
      <th class="mat-calendar-table-header-divider" colspan="4"></th>
    </tr>
  </thead>
  <tbody
    mat-calendar-body
    [label]="_yearLabel"
    [rows]="_months"
    [todayValue]="_todayMonth!"
    [startValue]="_selectedMonth!"
    [labelMinRequiredCells]="2"
    [numCols]="4"
    [cellAspectRatio]="4 / 7"
    [activeCell]="_dateAdapter.getMonth(activeDate)"
    (selectedValueChange)="_monthSelected($event)"
    (keydown)="_handleCalendarBodyKeydown($event)"
  ></tbody>
</table>
