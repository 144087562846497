import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridModule } from '@roadrecord/grid';
import { ListComponent } from './list.component';

@NgModule({
  imports: [CommonModule, GridModule],
  declarations: [ListComponent],
  exports: [ListComponent],
})
export class ListModule {}
