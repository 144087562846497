import { Pipe, PipeTransform } from '@angular/core';
import { UniversalImportFileType } from '../../../model/universal-import-config';

@Pipe({
  name: 'acceptedFileType',
})
export class AcceptedFileTypePipe implements PipeTransform {
  transform(value: UniversalImportFileType[], justCsvKey: string, justXSLXKey: string, justJsonKey: string, allKey: string): any {
    // Ha csak egy elem van, vizsgáljuk meg, hogy melyik fájltípust tartalmazza
    if (value.length === 1) {
      switch (value[0]) {
        case 'CSV':
          return justCsvKey;
        case 'ZIP':
        case 'JSON':
          return justJsonKey;
        case 'EXCEL':
          return justXSLXKey;
        default:
          return allKey;
      }
    }
    // Ha pontosan két elem van és az egyik 'ZIP', akkor 'justJsonKey'-t adunk vissza
    else if (value.length === 2 && value.includes('ZIP')) {
      return justJsonKey;
    }

    // Ha egyik feltétel sem teljesül, visszaadjuk az allKey-t
    return allKey;
  }
}
