import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxsModule } from '@ngxs/store';
import { MessageDialogComponent } from './message-dialog.component';
import { MessageDialogService } from './message-dialog.service';
import { PortalModule } from '@angular/cdk/portal';
import { TransformNativeRefToPortalRefPipe } from './transform-native-ref-to-portal-ref.pipe';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SanitizePipeModule } from '@roadrecord/common/common';

@NgModule({
  declarations: [MessageDialogComponent, TransformNativeRefToPortalRefPipe],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    TranslocoModule,
    ReactiveFormsModule,
    A11yModule,
    NgxsModule,
    MatProgressBarModule,
    PortalModule,
    MatSlideToggleModule,
    SanitizePipeModule,
  ],
  exports: [MessageDialogComponent, MatDialogModule],
  entryComponents: [MessageDialogComponent],
})
export class MessageDialogModule {
  // azert kell forRoot mert kulonben nem mukodik az entryComponent ha root-ba van injectalva a service
  static forRoot(): ModuleWithProviders<MessageDialogModule> {
    return {
      ngModule: MessageDialogModule,
      providers: [MessageDialogService],
    };
  }
}
