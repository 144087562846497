<mat-card
  #beforeMenuPanelCardRef
  *ngIf="
    !isWebadmin &&
    subscriptionInfo !== SubscriptionInfoEnum.VALID_SUBSCRIPTION &&
    (subscriptionInfo === SubscriptionInfoEnum.EXPIRING_SUBSCRIPTION ||
      (subscriptionInfo === SubscriptionInfoEnum.EXPIRED_SUBSCRIPTION && !isUsAppType) ||
      !(subscriptionInfo === SubscriptionInfoEnum.EXPIRED_SUBSCRIPTION && isUsAppType && subscriptionNumberOfDaysElapsed >= 15)) &&
    (open$ | async) === true
  "
  class="before-menu-panel-card"
>
  <mat-card-content><rr-subscription-display></rr-subscription-display></mat-card-content>
</mat-card>
<div
  class="menu-tab-gropup-wrapper"
  [ngClass]="{ 'is-closed': (open$ | async) === false }"
  [attr.selected-tab-index]="selectedTabIndex"
  [ngStyle]="{
    height: getWrapperHeight()
  }"
>
  <ng-container *ngIf="menuPages$ | async as menuPages">
    <ng-container *ngIf="menuPages.length > 0 && !loading; else loadingMenu">
      <mat-tab-group
        #tabGroup
        color="warn"
        [selectedIndex]="selectedTabIndex"
        (selectedIndexChange)="onSelectedTabIndexChange($event)"
        @simpleFadeIn
      >
        <mat-tab
          *ngFor="let menuPage of menuPages; trackBy: trackByFn"
          [disabled]="
            disabledMenuPageGroup.indexOf(menuPage.groupUniqId) > -1 ||
            (viewportService.isMobile && menuPage.groupUniqId === 'MONTH_ACTIVITIES')
          "
        >
          <ng-template mat-tab-label>
            <mat-icon [svgIcon]="menuPage.icon" [matTooltip]="menuPage.label | transloco" matTooltipPosition="right"></mat-icon>
            <ng-template [ngIf]="open$ | async"> {{ menuPage.label | transloco }} </ng-template>
          </ng-template>

          <rr-menu-page
            [customTemplateArea]="menuPage?.customAreaComponent"
            [menuPageRoute]="menuPage.route"
            [customBackButtonLabelCallback]="menuPage.customBackButtonLabelCallback"
            [customBackButtonLabel]="menuPage.customBackButtonLabel"
            [items]="menuPage | getItem: { excludedItems$: excludedItems$, disabledMenuItems$: disabledMenuItems$ } | async"
            [isClosed]="(open$ | async) === false"
            [beforeMenuPanelCardRef]="beforeMenuPanelCardRef"
            (closeMenu)="onCloseMenu($event)"
            (selectMenuItem)="onSelectMenuItem()"
            (selectWithoutRoute)="onSelectWithoutRoute($event)"
          ></rr-menu-page>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
    <ng-template #loadingMenu>
      <mat-progress-spinner class="loading" color="warn" mode="indeterminate"></mat-progress-spinner>
    </ng-template>
  </ng-container>
</div>
