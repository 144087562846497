import { deepmerge } from '@roadrecord/deepmerge';
import { translocoServiceLocator } from '../transloco-service.locator';
import { flatten } from '@ngneat/transloco';

/**
 * kulcs alapjan megtalalja a forditas eredeti utvonalat
 *
 * @param mergeStore
 * @param key
 */
export function getOriginalPath(mergeStore: { [key: string]: string }, key: string) {
  return `${mergeStore[originalPathGenerator(key)]}.${key}`;
}

export const ORIGINAL_PATH_KEY = '__ORIGINAL_PATH_KEY';
export function originalPathGenerator(key: string) {
  return `__${key.replace(new RegExp('\\.', 'g'), '_')}_${ORIGINAL_PATH_KEY}`;
}

/**
 * objectet feltolti az original path-val
 *
 * @param side
 * @param sideKey
 * @param beforeKey
 * @param result
 */
export function addOriginalPath(side: {}, sideKey: string, beforeKey = '', result = {}): any {
  Object.entries(side).forEach(entry => {
    if (typeof entry[1] === 'string') {
      Object.defineProperty(result, originalPathGenerator(`${beforeKey}${beforeKey.length > 0 ? '.' : ''}${entry[0]}`), {
        writable: false,
        enumerable: true,
        value: sideKey,
      });
    } else {
      result = addOriginalPath(entry[1], sideKey, `${beforeKey}${beforeKey.length > 0 ? '.' : ''}${entry[0]}`, result);
    }
  });
  return result;
}

/**
 * TODO cache result, reset active lang change
 * Csak az original path -val feltoltott objectet adja vissza
 *
 * @param leftSideKey
 * @param rightSideKey
 */
export function mergeTranslateObjects(leftSideKey: string, rightSideKey: string) {
  let leftSide = translocoServiceLocator.service.translateObject(leftSideKey) || {};
  leftSide = addOriginalPath(leftSide, leftSideKey);

  let rightSide = translocoServiceLocator.service.translateObject(rightSideKey) || {};
  rightSide = addOriginalPath(rightSide, rightSideKey);

  return deepmerge(leftSide, rightSide);
}

/**
 * forditasokat adja vissza
 *
 * @param leftSideKey
 * @param rightSideKey
 */
export function simpleMergeTranslateObjects(leftSideKey: string, rightSideKey: string) {
  return deepmerge(
    translocoServiceLocator.service.translateObject(leftSideKey) || {},
    translocoServiceLocator.service.translateObject(rightSideKey) || {}
  );
}

export function mergeTranslateObjectsFlatten(leftSideKey: string, rightSideKey: string) {
  return flatten(mergeTranslateObjects(leftSideKey, rightSideKey));
}

export function simpleMergeTranslateObjectsFlatten(leftSideKey: string, rightSideKey: string) {
  return flatten(simpleMergeTranslateObjects(leftSideKey, rightSideKey));
}
