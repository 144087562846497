import { InjectionToken } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { isNil } from '@roadrecord/type-guard';

export const PRESENTER_SAVE_PLUGIN_OPTIONS_TOKEN = new InjectionToken<PresenterSavePluginOptionsModel>('PRESENTER_SAVE_OPTIONS_TOKEN');

export enum PresenterSaveSaveDataCallbackStrategy {
  BEFORE_RUN_PLUGIN,
  AFTER_RUN_PLUGIN,
}

interface PresenterSavePluginOptionsModelInterface {
  bindOnSubmitMethodName?: string;
  routingWhenSaveAndNotReset?: SimpleBooleanFN | boolean;
  routingModifyExtraParams?: NavigationExtras;
  routingModifyExtraParamsCb?: string;
  responseOkCallback?: () => string | string;
  responseErrorCallback?: () => string | string;
  presenterSaveSaveDataCallbackStrategy?: PresenterSaveSaveDataCallbackStrategy;
  sendSetQueryParams?: (mode: 'CREATE' | 'MODIFY') => object;
  // experimental features
  modifyPatchMode?: boolean;
  patchRemoveFieldsBeforeDiff?: string[];
}

type SimpleBooleanFN = () => boolean;

export class PresenterSavePluginOptionsModel implements PresenterSavePluginOptionsModelInterface {
  /**
   * milyen metodus nevhez bindolja magat (az az metodus nev ami a view submit emitter-re ra van kotve!)
   */
  bindOnSubmitMethodName = 'onSubmit';
  /**
   * amikor sikeres mentes van es nincs reset mod akkor legyen-e route
   */
  routingWhenSaveAndNotReset: SimpleBooleanFN | boolean = true;
  routingModifyExtraParams: NavigationExtras;
  routingModifyExtraParamsCb: string;
  responseOkCallback: () => string | string;
  responseErrorCallback: () => string | string;
  presenterSaveSaveDataCallbackStrategy: PresenterSaveSaveDataCallbackStrategy = PresenterSaveSaveDataCallbackStrategy.AFTER_RUN_PLUGIN;
  sendSetQueryParams?: (mode: 'CREATE' | 'MODIFY') => object;
  // experimental features
  modifyPatchMode = false;
  patchRemoveFieldsBeforeDiff = [];

  constructor(data?: PresenterSavePluginOptionsModelInterface) {
    if (!isNil(data)) {
      Object.assign(this, data);
    }
  }
}
