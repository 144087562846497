import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@roadrecord/common/common';
import { Observable } from 'rxjs';
import { IResetPasswordUser } from './model/IResetPasswordUser';
import { UserLoginModel } from './model/user-login-model';
import { UserModel } from './model/user.model';
import { getRegisterReferer } from './layout/register/get-register-referer/get-register-referer-rr.function';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';
import { RegisterSocialModel } from './model/register-social.model';
import { environment } from '@roadrecord/environment';

@Injectable({ providedIn: 'root' })
export class AuthService<DATAMODEL extends UserModel> {
  constructor(private http: HttpClient, @Inject(WINDOW) private readonly window: Window, @Inject(DOCUMENT) private document: Document) {}

  private _unauthFirstAnimDisabled = true;

  get unauthFirstAnimDisabled(): boolean {
    return this._unauthFirstAnimDisabled;
  }

  set unauthFirstAnimDisabled(value: boolean) {
    this._unauthFirstAnimDisabled = value;
  }

  /**
   * Change password request
   */
  changePassword(old_password: string, new_password: string, new_password_confirmation: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}user/change-password/`, {
      old_password,
      new_password,
      new_password_confirmation,
    });
  }

  login(login: UserLoginModel): Observable<HttpResponse<DATAMODEL>> {
    return this.http.post<DATAMODEL>(`${environment.apiUrl}auth/login/`, login, {
      observe: 'response',
    });
  }

  sendNewPassword(email: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}auth/forgetpassword/`, { email });
  }

  resendActivation(email: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}resendactivation/`, { email });
  }

  resetPassword(user: IResetPasswordUser): Observable<any> {
    return this.http.post(`${environment.apiUrl}resetpassword/`, user);
  }

  logout(): Observable<void> {
    return this.http.get<void>(`${environment.apiUrl}auth/logout/`);
  }

  refreshToken(token: string): Observable<{ token: string }> {
    return this.http.post<{ token: string }>(`${environment.apiUrl}api-token-refresh/`, { token });
  }

  register(user: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}accounts/`, user);
  }

  registerV2(user: any): Observable<any> {
    return this.http.post(`${environment.api2Url}accounts/`, user);
  }

  registerV3(user: any, registration_type: string): Observable<any> {
    return this.http.post(`${environment.api3Url}accounts/registration/${registration_type}/`, user);
  }

  loginWithGoogle(cookieService: CookieService, fingerprint: string) {
    // TODO Meta-resolution-t kuldeni kene
    /*
    this.window.location.href = `http://localhost:8003/api/v1/auth/social/google/start/?referer=${encodeURIComponent(
      getRegisterReferer(cookieService, this.document)
    )}&device_fingerprint=${fingerprint}`;
*/
    this.window.location.href = `${environment.apiUrl}auth/social/google/start/?referer=${encodeURIComponent(
      getRegisterReferer(cookieService, this.document)
    )}&device_fingerprint=${fingerprint}`;
  }

  loginWithApple(cookieService: CookieService, fingerprint: string) {
    this.window.location.href = `${environment.apiUrl}auth/social/apple/start/?referer=${encodeURIComponent(
      getRegisterReferer(cookieService, this.document)
    )}&device_fingerprint=${fingerprint}`;
  }

  loginWithFacebook(cookieService: CookieService, fingerprint: string) {
    // Develop eseten
    /*
    this.window.location.href = `http://localhost:9002/api/v1/auth/social/facebook/start/?referer=${encodeURIComponent(
      getRegisterReferer(cookieService, this.document)
    )}&device_fingerprint=${fingerprint}`;
*/
    this.window.location.href = `${environment.apiUrl}auth/social/facebook/start/?referer=${encodeURIComponent(
      getRegisterReferer(cookieService, this.document)
    )}&device_fingerprint=${fingerprint}`;
  }

  getCompanyRegistrationCompleteDatas() {
    return this.http.get<RegisterSocialModel>(`${environment.apiUrl}company/complete-registration/`);
  }

  getCompanyRegistrationCompleteDatasV2() {
    return this.http.get<{
      meta: {
        password_required: boolean;
      };
      value: Partial<RegisterSocialModel>;
    }>(`${environment.api2Url}company/complete-registration/`);
  }

  saveCompanyRegistrationCompleteDatas(model: Partial<RegisterSocialModel>) {
    return this.http.put(`${environment.apiUrl}company/complete-registration/`, model);
  }

  saveCompanyRegistrationCompleteDatasV2(model: Partial<RegisterSocialModel>) {
    return this.http.put(`${environment.api2Url}company/complete-registration/`, model);
  }
}
