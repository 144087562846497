import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { checkModifiedFormPopupState } from '../../form/check-modify/function/check-modified-form-popup.function';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { timer } from 'rxjs';
import { ServerErrorTypeEnum } from '../enum/server-error-type.enum';
import { FieldHttpError } from '../exception/field-http-error';
import { HandledHttpError } from '../exception/handled-http.error';
import { NotFoundEndpointError } from '../exception/not-found-endpoint.error';
import { NotFoundInternetError } from '../exception/not-found-internet.error';
import { NotFoundServerError } from '../exception/not-found-server.error';
import { RRHttpErrorResponse } from '../exception/rr-http-error-response';
import { isNil } from '@roadrecord/type-guard';

export function handleHttpError(
  error: RRHttpErrorResponse,
  window: Window,
  translocoService: TranslocoService,
  messageDialogService: MessageDialogService,
  router: Router,
  alertCb: (error: RRHttpErrorResponse, translocoService: TranslocoService) => void
): RRHttpErrorResponse {
  if (error instanceof NotFoundServerError) {
    messageDialogService
      .openError(
        {
          id: null,
          title: 'COMMON.ERRORS.NOT_FOUND_SERVER.TITLE',
          text: 'COMMON.ERRORS.NOT_FOUND_SERVER.TEXT',
          confirmLabel: 'COMMON.ERRORS.NOT_FOUND_SERVER.BUTTON',
          htmlMode: true,
        },
        { maxWidth: 500 }
      )
      .afterClosed()
      .subscribe(() => window.location.reload());
    return new HandledHttpError(error);
  } else if (error instanceof NotFoundInternetError) {
    messageDialogService
      .openError(
        {
          id: null,
          text: 'COMMON.ERRORS.NOT_FOUND_INTERNET.TEXT',
          confirmLabel: 'COMMON.ERRORS.NOT_FOUND_INTERNET.BUTTON',
        },
        { maxWidth: 500 }
      )
      .afterClosed()
      .subscribe(() => window.location.reload());
    return new HandledHttpError(error);
  } else if (error instanceof NotFoundEndpointError) {
    messageDialogService
      .openWarning(
        {
          id: null,
          text: 'COMMON.NOT_FOUND_ENDPOINT',
          confirmLabel: 'COMMON.BACK',
        },
        { maxWidth: 500 }
      )
      .afterClosed()
      .subscribe(() => {
        checkModifiedFormPopupState.disableNextCheck = true;
        router.navigate(['/']);
        timer(1000).subscribe(() => (checkModifiedFormPopupState.disableNextCheck = false));
      });
    return new HandledHttpError(error);
  } else if (!isNil(error.error) && error.error.error_type !== undefined) {
    switch (error.error.error_type) {
      case ServerErrorTypeEnum.APPLICATION_ERROR:
      case ServerErrorTypeEnum.WARNING:
      case ServerErrorTypeEnum.INFORMATION:
        error = new HandledHttpError(error);

        alertCb(error, translocoService);
        // showSweetAlertFromErrorObject(error, this.translocoService).subscribe(NoopFunction);
        return error;

      case ServerErrorTypeEnum.FIELD_ERROR:
        return new FieldHttpError(error);
    }
  } else if (isNil(error.error)) {
    messageDialogService
      .openError(
        {
          id: null,
          text: 'COMMON.UNKNOWN_ERROR_WITHOUT_MESSAGE',
          confirmLabel: 'COMMON.BACK',
          htmlMode: true,
        },
        { maxWidth: 500 }
      )
      .afterClosed()
      .subscribe(() => {
        checkModifiedFormPopupState.disableNextCheck = true;
        router.navigate(['/']);
        timer(1000).subscribe(() => (checkModifiedFormPopupState.disableNextCheck = false));
      });
    return new HandledHttpError(error);
  }
  return error;
}
