// KELL EZ A DI-BE ?

export const validationMessagesConfig /*: ValidationMessageModel[] */ = [
  {
    errorKey: 'required',
    translateKey: 'COMMON.VALIDATION.REQUIRED',
  },
  {
    errorKey: 'minlength',
    translateKey: 'COMMON.VALIDATION.MIN_LENGTH',
  },
  {
    errorKey: 'maxlength',
    translateKey: 'COMMON.VALIDATION.MAX_LENGTH',
  },
  {
    errorKey: 'numberRequired',
    translateKey: 'COMMON.VALIDATION.NUMBER_REQUIRED',
  },
  {
    errorKey: 'notValidTime',
    translateKey: 'COMMON.VALIDATION.NOT_VALID_TIME',
  },
  {
    errorKey: 'mask',
    translateKey: 'COMMON.VALIDATION.MASK',
  },
  {
    errorKey: 'greaterThan',
    translateKey: 'COMMON.VALIDATION.GREATER_THAN',
  },
  {
    errorKey: 'min',
    translateKey: 'COMMON.VALIDATION.MIN',
  },
  {
    errorKey: 'max',
    translateKey: 'COMMON.VALIDATION.MAX',
  },
];
