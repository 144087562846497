import { ChangeDetectionStrategy, Component, Input, SimpleChanges } from '@angular/core';
import { SplitButtonAction, SplitButtonActionType } from '../model/split-button-action';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ActivatedRoute, Router } from '@angular/router';
import { isFunction } from '@roadrecord/type-guard';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'rr-split-button',
  templateUrl: './split-button.component.html',
  styleUrls: ['./split-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplitButtonComponent {
  @Input() label!: string;
  @Input() disabled = false;
  @Input() actions: SplitButtonAction[] = [];
  @Input() color: ThemePalette | undefined = 'accent';
  @Input() data: any;
  @Input() buttonStyle: string | undefined = 'mat-raised-button';

  constructor(private router: Router, private route: ActivatedRoute) {}

  private _withoutSeparator = false;

  get withoutSeparator(): boolean {
    return this._withoutSeparator;
  }

  @Input()
  set withoutSeparator(value: boolean) {
    this._withoutSeparator = coerceBooleanProperty(value);
  }

  private _startWithFirstElement = false;

  get startWithFirstElement(): boolean {
    return this._startWithFirstElement;
  }

  @Input()
  set startWithFirstElement(value: boolean) {
    this._startWithFirstElement = coerceBooleanProperty(value);
  }

  private _stroked = false;
  trackByFn = (index: number, action: SplitButtonAction) => JSON.stringify(action);

  get stroked(): boolean {
    return this._stroked;
  }

  @Input()
  set stroked(value: boolean) {
    this._stroked = coerceBooleanProperty(value);
  }

  onClickAction(action: SplitButtonActionType) {
    if (isFunction(action.action)) {
      action.action();
    } else {
      this.onNavigate(action);
    }
  }

  private onNavigate(action: SplitButtonActionType) {
    const route = Array.isArray(action.route) && action.route.length > 0 ? action.route : [];
    if (action.relativeRoute) {
      this.router.navigate(route, { relativeTo: this.route });
    } else {
      this.router.navigate(route, { relativeTo: this.route });
    }
  }
}
