// tslint:disable-next-line:nx-enforce-module-boundaries
import { ImaskNumberConfig, ImaskNumberConfigInterface } from '../../../../common/src/lib/model/imask-number.config';
import { MaskedNumber } from 'imask';

/**
 * replaced file
 */

class ImaskNumberConfigHu extends ImaskNumberConfig {
  resolve(
    value: string,
    flags?: {
      input?: boolean;
      tail?: boolean;
      raw?: boolean;
    }
  ): string {
    value = value.replace('.', ',');
    return super.resolve(value, flags);
  }
}

export function globalImaskNumberConfigGenerator(data?: Partial<ImaskNumberConfigInterface>): MaskedNumber {
  return new ImaskNumberConfigHu(Object.assign({ thousandsSeparator: ' ', radix: ',', mapToRadix: ['.'] }, data || {}));
}
