import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from '@roadrecord/type-guard';

export interface SumPipeOptions {
  path: string;
}

@Pipe({
  name: 'sumPipe',
})
export class SumPipe implements PipeTransform {
  static transformValue(value: any[], args?: SumPipeOptions): any {
    if (!isNil(args)) {
      return value.reduce((current, next) => {
        const paths = args.path.split('.');
        paths.forEach(path => (next = next[path]));
        return current + next;
      }, 0);
    }
    return value.reduce((current, next) => current + next, 0);
  }

  transform(value: any[], args?: SumPipeOptions): any {
    return SumPipe.transformValue(value, args);
  }
}
