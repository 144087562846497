import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { ValidatingModule } from '@roadrecord/validating';
import { DaySelectFormControlComponent } from './day-select-form-control.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatTooltipModule,

    ValidatingModule,

    TranslocoModule,
  ],
  declarations: [DaySelectFormControlComponent],
  exports: [DaySelectFormControlComponent],
})
export class DaySelectFormControlModule {}
