import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { RemoteCommandAction, UserStateKeyRefreshAction, WINDOW } from '@roadrecord/common/common';
import { handleHttpError } from '../http/function/handle-http-error.function';
import { RRHttpErrorResponse } from '../http/exception/rr-http-error-response';
import { throwHttpError } from '../http/function/throw-http-error.function';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { asapScheduler, Observable, of, Subject, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { BatchRequestModel } from './model/batch-request.model';
import { BatchResponseModel } from './model/batch-response.model';
import { environment } from '@roadrecord/environment';
import { HandleErrorObject } from '../http/function/handle-error-object.function';

@Injectable({ providedIn: 'root' })
export class BatchRequestService {
  private run = false;
  private requests: BatchRequestModel[];
  private response$: Subject<any>;

  constructor(
    private store: Store,
    private http: HttpClient,
    private translocoService: TranslocoService,
    private router: Router,
    @Inject(WINDOW) private window: Window,
    private messageDialogService: MessageDialogService
  ) {}

  start(): void {
    if (this.run === true) {
      throw new Error('Batch request running...');
    }
    this.run = true;
    this.response$ = new Subject<any>();
    this.requests = [];
  }

  add<MODEL>(request: BatchRequestModel): Observable<MODEL> {
    this.requests.push({ name: `${this.requests.length}`, ...request });
    const index = this.requests.length - 1;
    return this.response$.pipe(
      map(responses => responses[index]),
      switchMap((response: BatchResponseModel<MODEL>) => {
        if ([200, 204].indexOf(response.code) > -1) {
          // OK Success
          return of(response.body);
        } else {
          const error = throwHttpError(
            new RRHttpErrorResponse({
              error: response.body,
              statusText: `${response.code}`,
              headers: response.headers as any,
              status: response.code,
              url: request.relative_url,
            })
          ) as RRHttpErrorResponse;
          handleHttpError(
            error,
            this.window,
            this.translocoService,
            this.messageDialogService,
            this.router,
            (_error: RRHttpErrorResponse, translocoService: TranslocoService) => HandleErrorObject.handleErrorObject(_error)
          );
          return throwError(error);
        }
      })
    );
  }

  /**
   * hibak kezeleserol a hivonak kell gondoskodnia!
   */
  end(): Observable<any[]> {
    if (this.run === false) {
      throw new Error('Batch request not running...');
    }
    this.run = false;
    return this.batchRequest(this.requests).pipe(
      tap(() => {
        delete this.requests;
        delete this.response$;
      })
    );
  }

  /**
   * hibak kezeleserol a hivonak kell gondoskodnia!
   */
  batchRequest<MODEL>(requests: BatchRequestModel[]): Observable<BatchResponseModel<MODEL>[]> {
    return this.http
      .post<BatchResponseModel<MODEL>[]>(`${environment.baseUrl}/batch/`, { batch: requests })
      .pipe(
        map(responses =>
          responses.map(oneReponse => {
            // Megprobaljuk parse-ni a valaszt
            try {
              // safe json parse
              oneReponse.body = JSON.parse(`${oneReponse.body}`);
            } catch (e) {
              oneReponse.body = undefined;
            }

            // state refresh header keresese
            const userStateRefresh = oneReponse.headers.find(header => header.name.toUpperCase() === 'USER-STATE-REFRESH');
            if (userStateRefresh !== undefined && userStateRefresh.value.length > 0) {
              const keys = userStateRefresh.value.split(',');
              keys.forEach(key => this.store.dispatch(new UserStateKeyRefreshAction(key)));
            }
            const restCommand = oneReponse.headers.find(header => header.name.toUpperCase() === 'REST-COMMAND');
            if (restCommand !== undefined && restCommand.value.length > 0) {
              const keys = restCommand.value.split('||');
              keys.forEach(key => asapScheduler.schedule(() => this.store.dispatch(new RemoteCommandAction(key as any))));
            }
            return oneReponse;
          })
        ),
        tap(responses => this.response$.next(responses))
      );
  }

  get requestCount(): number {
    if (this.requests === undefined) {
      return 0;
    }
    return this.requests.length;
  }
}
