import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { VehicleAccountListModel } from '../model/vehicle-account-list.model';

@Component({
  selector: 'rr-collected-data-bottom-sheet',
  templateUrl: './collected-data-bottom-sheet.component.html',
  styleUrls: ['./collected-data-bottom-sheet.component.scss'],
})
export class CollectedDataBottomSheetComponent {
  private deleted: VehicleAccountListModel[] = [];

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: VehicleAccountListModel[],
    private bottomSheetRef: MatBottomSheetRef<CollectedDataBottomSheetComponent>
  ) {}

  removeItem(item: VehicleAccountListModel) {
    const index = this.data.indexOf(item);
    this.deleted.push(this.data[index]);
    this.data.splice(index, 1);
  }

  onClickCancel() {
    this.bottomSheetRef.dismiss();
  }

  onClickSubmit() {
    this.bottomSheetRef.dismiss({ data: this.data, restore: this.deleted });
  }
}
