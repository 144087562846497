<div class="filter-bar">
  <ng-container *ngIf="windowMode === false">
    <button
      type="button"
      (click)="changeFilter('ALL')"
      *rrNgIfTyped="countAll"
      [disabled]="currentFilter === 'ALL' || countAll === 0 || disabled"
      [ngClass]="{ active: currentFilter === 'ALL' }"
      color="accent"
      mat-button
      [matTooltip]="'RECOMMENDATION.FILTER_BAR.TOOLTIP.ALL' | transloco"
    >
      {{ 'RECOMMENDATION.FILTER_BAR.LABEL.ALL' | transloco: { countAll: countAll } }}
    </button>
    <button
      type="button"
      (click)="changeFilter(RecommendationStatusEnum.OK)"
      *rrNgIfTyped="countOK && windowMode === false"
      [disabled]="currentFilter === RecommendationStatusEnum.OK || countOK === 0 || disabled"
      [ngClass]="{ active: currentFilter === RecommendationStatusEnum.OK }"
      color="accent"
      mat-button
      [matTooltip]="'RECOMMENDATION.FILTER_BAR.TOOLTIP.OK' | transloco"
    >
      {{ 'RECOMMENDATION.FILTER_BAR.LABEL.OK' | transloco: { countOK: countOK } }}
    </button>
  </ng-container>
  <ng-container *ngIf="windowMode === false || countWarning > 0">
    <button
      type="button"
      (click)="changeFilter(RecommendationStatusEnum.WARNING)"
      *rrNgIfTyped="countWarning"
      [disabled]="currentFilter === RecommendationStatusEnum.WARNING || countWarning === 0 || disabled"
      [ngClass]="{ active: currentFilter === RecommendationStatusEnum.WARNING }"
      color="accent"
      mat-button
      [matTooltip]="'RECOMMENDATION.FILTER_BAR.TOOLTIP.WARNING' | transloco"
    >
      {{ 'RECOMMENDATION.FILTER_BAR.LABEL.WARNING' | transloco: { countWarning: countWarning } }}
    </button>
  </ng-container>
  <ng-container *ngIf="windowMode === false || countError > 0">
    <button
      type="button"
      (click)="changeFilter(RecommendationStatusEnum.ERROR)"
      *rrNgIfTyped="countError && (windowMode === false || countError > 0)"
      [disabled]="currentFilter === RecommendationStatusEnum.ERROR || countError === 0 || disabled"
      [ngClass]="{ active: currentFilter === RecommendationStatusEnum.ERROR }"
      color="accent"
      mat-button
      [matTooltip]="'RECOMMENDATION.FILTER_BAR.TOOLTIP.ERROR' | transloco"
    >
      {{ 'RECOMMENDATION.FILTER_BAR.LABEL.ERROR' | transloco: { countError: countError } }}
    </button>
  </ng-container>
</div>
