export const PARTNER_FROM_GEOCODING_COPY_FIELDS = [
  'street',
  'postcode',
  'city',
  'name',
  'local_country',
  'house_number',
  'country',
  'longitude',
  'latitude',
];
