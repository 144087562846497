import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FIRST_STEPS_STEPPING_PATH } from '@roadrecord/common/common';
import { WrapperComponent } from './wrapper/wrapper.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: `${FIRST_STEPS_STEPPING_PATH}` },
  { path: FIRST_STEPS_STEPPING_PATH, component: WrapperComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FirstStepsRoutingModule {}
