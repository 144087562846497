import { ComponentFactoryResolver, NgModuleRef } from '@angular/core';
import { PreferencesContentWindowTypeEnum } from '../model/preferences-content-window-type.enum';

export interface UIOptions {
  width?: string;
  height: string;
  rzHandles?: string;
  minWidth?: string;
  maxWidth?: string;
}

export class PreferencesOpenContentWindowAction {
  static readonly type = '[Preferences] Open content window';
  constructor(
    readonly windowType: PreferencesContentWindowTypeEnum,
    readonly title: string,
    readonly cmp: any,
    readonly uiOptions?: UIOptions,
    readonly showMaximizeAndMinimizeButtons = true,
    readonly componentFactoryResolver: ComponentFactoryResolver = null,
    readonly ngModuleRef: NgModuleRef<any> = null,
    readonly videoUrl?: string,
    readonly inputs?: { [key: string]: any }
  ) {}
}
