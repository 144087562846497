import {
  AbstractControlOptions,
  AsyncValidatorFn,
  FormArray as NgFormArray,
  FormControl as NgFormControl,
  FormGroup as NgFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { isNil } from '@roadrecord/type-guard';

export function remoteErrorValidator(): ValidatorFn | any /*csak any-vel ment*/ {
  return (control: FormControl) => {
    const { value, remoteErrorValue, remoteError } = control;
    if (!isNil(remoteError) && value === remoteErrorValue) {
      return { customError: remoteError };
    }
    return null;
  };
}

export class FormGroup extends NgFormGroup {
  private _remoteError: string | null = null;
  /**
   * Ebben taroljuk a hiba kivaltodasakor levo control erteket
   */
  private _remoteErrorValue: string | null = null;

  submitted = false;
  private remoteErrors: { path: string; message: string }[] | null = null;

  reset(value?: unknown, options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    this.submitted = false;
    super.reset(value, options);
  }

  setRemoteErrors(errors: { path: string; message: string }[]) {
    this.remoteErrors = errors;

    errors.forEach(({ path, message }) => {
      const control = this.get(path) as FormControl | FormArray | FormGroup;
      if (isNil(control)) {
        console.error(`[FormGroup] setRemoteErrors => Not found control: ${path}`);
      } else {
        control.setRemoteError(message);
        // validatorok miatt kell, viszont nem a legoptimalisabb mert mindig vegig ertisiti a parent lancot
        control.updateValueAndValidity({ onlySelf: false, emitEvent: false });
      }
    });
  }

  clearRemoteErrors() {
    if (Array.isArray(this.remoteErrors)) {
      this.remoteErrors.forEach(({ path }) => {
        const control = this.get(path) as FormControl | FormArray | FormGroup;
        if (isNil(control)) {
          console.error(`[FormGroup] clearRemoteErrors => Not found control: ${path}`);
        } else {
          control.clearRemoteError();
        }
      });
    }
  }

  get remoteError() {
    return this._remoteError;
  }

  get remoteErrorValue(): string | null {
    return this._remoteErrorValue;
  }

  setRemoteError(error: string) {
    this._remoteError = error;
    this._remoteErrorValue = this.value;
  }

  clearRemoteError() {
    this._remoteError = null;
    this._remoteErrorValue = null;
  }
}

export class FormArray extends NgFormArray {
  private _remoteError: string | null = null;
  /**
   * Ebben taroljuk a hiba kivaltodasakor levo control erteket
   */
  private _remoteErrorValue: string | null = null;

  get remoteError() {
    return this._remoteError;
  }

  get remoteErrorValue(): string | null {
    return this._remoteErrorValue;
  }

  setRemoteError(error: string) {
    this._remoteError = error;
    this._remoteErrorValue = this.value;
  }

  clearRemoteError() {
    this._remoteError = null;
    this._remoteErrorValue = null;
  }
}

function isOptionsObj(
  validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null
): validatorOrOpts is AbstractControlOptions {
  return validatorOrOpts != null && !Array.isArray(validatorOrOpts) && typeof validatorOrOpts === 'object';
}

export class FormControl extends NgFormControl {
  private _remoteError: string | null = null;
  /**
   * Ebben taroljuk a hiba kivaltodasakor levo control erteket
   */
  private _remoteErrorValue: string | null = null;

  constructor(
    formState?: any,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(formState, validatorOrOpts, asyncValidator);
    // hozza fuzzuk a sajat remote validator-t
    this.setValidators(this.validator);
  }

  setValidators(validators: ValidatorFn | ValidatorFn[] | null) {
    if (!isNil(validators)) {
      validators = Validators.compose([remoteErrorValidator(), ...(Array.isArray(validators) ? validators : [validators])]);
    } else {
      validators = remoteErrorValidator();
    }
    super.setValidators(validators);
  }

  get remoteError() {
    return this._remoteError;
  }

  get remoteErrorValue(): string | null {
    return this._remoteErrorValue;
  }

  setRemoteError(error: string) {
    this._remoteError = error;
    this._remoteErrorValue = this.value;
  }

  clearRemoteError() {
    this._remoteError = null;
    this._remoteErrorValue = null;
  }
}
