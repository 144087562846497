import { StateContext } from '@ngxs/store';
import { produce } from '@ngxs-labs/immer-adapter';
import { AbstractPeriodContextStateModel } from '../model/abstract-period-context-state.model';

export function periodContextRefreshRemoteStateErrorActionFn<STATEMODEL extends AbstractPeriodContextStateModel<any>>(
  ctx: StateContext<STATEMODEL>
): any {
  produce<STATEMODEL>(ctx, draft => {
    draft.loading = false;
  });
}
