import { Pipe, PipeTransform } from '@angular/core';
import { AppTypeEnum, environment } from '@roadrecord/environment';

@Pipe({
  name: 'appTypeIsUs',
})
export class AppTypeIsUsPipe implements PipeTransform {
  transform(val: null): any {
    return environment.appType === AppTypeEnum.US;
  }
}
