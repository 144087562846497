import { TranslocoService } from '@ngneat/transloco';
import { GridColumnModel } from '@roadrecord/grid';
import { MILEAGE_RECORD_TYPE_ENUM, MileageListModel } from '@roadrecord/mileage/model';
import moment from 'moment';
import { MileageDateFormat } from '@roadrecord/mileage/common';
import { DecimalPipe } from '@angular/common';
import { isNil } from '@roadrecord/type-guard';
import { FuelTypeEnum } from '@roadrecord/vehicle/model/common';

const translatePrefix = 'MILEAGE.LIST';
const columnTranslatePrefix = `${translatePrefix}.COLUMN`;

const emptyStringOrTranslate = (value: unknown, translateKey: string, decimalPipe: DecimalPipe, translocoService: TranslocoService) =>
  isNil(value) ? '' : translocoService.translate(`${columnTranslatePrefix}.${translateKey}.VALUE`, { value: decimalPipe.transform(value) });

export const listColumnConfig: (_this: {
  translocoService: TranslocoService;
  dateFormat: MileageDateFormat;
  currentPeriodContext: any;
}) => GridColumnModel<MileageListModel>[] = (_this: {
  translocoService: TranslocoService;
  dateFormat: MileageDateFormat;
  currentPeriodContext: any;
}): GridColumnModel<MileageListModel>[] => {
  const decimalPipe = new DecimalPipe(_this.translocoService.getActiveLang());
  return [
    {
      name: 'datetime',
      displayNameTranslateKey: `${columnTranslatePrefix}.MILEAGE_DATE_TIME`,
      cellTooltipPosition: 'above',
      cellContentTemplateCallback: (model: MileageListModel) => {
        let dateTimeFormat: string;
        switch (model.record_type) {
          case MILEAGE_RECORD_TYPE_ENUM.END_MONTH_ODOMETER_READING:
            dateTimeFormat = _this.dateFormat.yearMonth;
            break;
          case MILEAGE_RECORD_TYPE_ENUM.FILLING:
            dateTimeFormat = _this.dateFormat.fullDateTime;
            break;
          case MILEAGE_RECORD_TYPE_ENUM.MONTHLY_STARTING_ODOMETER_READING:
          case MILEAGE_RECORD_TYPE_ENUM.FIX_ODOMETER_READING:
          case MILEAGE_RECORD_TYPE_ENUM.MONTHLY_CLOSING_ODOMETER_READING:
            dateTimeFormat = _this.dateFormat.fullDate;
            break;
        }

        return moment(model.datetime).format(dateTimeFormat);
      },
      cellTooltipFn: (model: MileageListModel) =>
        `${translatePrefix}.CELL_TOOLTIP.MILEAGE_DATE_TIME.${MILEAGE_RECORD_TYPE_ENUM.getEnumNameByValue(model.record_type)}`,
      cellCssClassFn: (model: MileageListModel) =>
        [MILEAGE_RECORD_TYPE_ENUM.MONTHLY_STARTING_ODOMETER_READING, MILEAGE_RECORD_TYPE_ENUM.END_MONTH_ODOMETER_READING].indexOf(
          model.record_type
        ) > -1
          ? ['bold']
          : undefined,
    },
    {
      name: 'mileage',
      displayNameTranslateKey: `${columnTranslatePrefix}.MILEAGE.TITLE`,
      cellContentTemplateCallback: (model: MileageListModel) =>
        emptyStringOrTranslate(model.mileage, 'MILEAGE', decimalPipe, _this.translocoService),
    },
    {
      name: 'quantity',
      displayNameTranslateKey: `${columnTranslatePrefix}.QUANTITY.TITLE`,
      cellContentTemplateCallback: (model: MileageListModel) =>
        emptyStringOrTranslate(
          model.quantity,
          _this.currentPeriodContext.vehicle.fuel_type === FuelTypeEnum.ELECTRIC ? 'QUANTITY_ELECTRIC' : 'QUANTITY',
          decimalPipe,
          _this.translocoService
        ),
    },
    {
      name: 'cost',
      displayNameTranslateKey: `${columnTranslatePrefix}.COST.TITLE`,
      cellContentTemplateCallback: (model: MileageListModel) =>
        emptyStringOrTranslate(model.cost, 'COST', decimalPipe, _this.translocoService),
    },
    {
      name: 'difference_between_fillings',
      displayNameTranslateKey: `${columnTranslatePrefix}.DIFFERENCE_BETWEEN_FILLINGS.TITLE`,
      cellContentTemplateCallback: (model: MileageListModel) =>
        emptyStringOrTranslate(model.difference_between_fillings, 'DIFFERENCE_BETWEEN_FILLINGS', decimalPipe, _this.translocoService),
    },
    {
      name: 'monthly_distance',
      displayNameTranslateKey: `${columnTranslatePrefix}.MONTHLY_DISTANCE.TITLE`,
      cellContentTemplateCallback: (model: MileageListModel) =>
        emptyStringOrTranslate(model.monthly_distance, 'MONTHLY_DISTANCE', decimalPipe, _this.translocoService),
    },
  ];
};
