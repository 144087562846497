<rr-fueling-data-form
  #dataForm
  (deleted)="onDelete()"
  (newConsumptionDataRequestSignal)="onNewConsumptionDataRequestSignal($event)"
  (submitForm)="onSubmit($event)"
  [consumption]="consumption"
  [editModel]="editModel$ | async"
  [isNew]="isNew$ | async"
  [previous_mileage]="previous_mileage"
  [selectedDayText]="selectedDayText"
  [mileageCalculationValue]="$any(mileageCalculationValue)"
  [selectedTime]="selectedTime"
  [disableAutoOpenFields]="disableAutoOpenFields"
  [smrMode]="smrMode"
  [canCalculation]="canCalculation"
  [calculated_distance]="calculated_distance"
  [calculated_fueling_mileage]="calculated_fueling_mileage"
></rr-fueling-data-form>

<div fxLayout="row" fxLayoutAlign="center center" fxFlex.gt-sm>
  <div fxLayout="column" fxLayoutAlign="center stretch" class="wid100">
    <rr-progress-when-first-loading>
      <mat-card>
        <mat-progress-bar color="warn" mode="indeterminate" *ngIf="loading$ | async"></mat-progress-bar>
        <mat-card-header>
          <mat-card-title>
            {{ ((isNew$ | async) === true ? 'FUELING.DETAILS.TITLE.NEW' : 'FUELING.DETAILS.TITLE.MODIFY') | transloco }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content> <ng-container *ngTemplateOutlet="dataForm.formTpl"></ng-container> </mat-card-content>
        <mat-card-actions>
          <button
            type="button"
            mat-icon-button
            [disabled]="loading$ | async"
            (click)="onBack()"
            matTooltipPosition="right"
            [matTooltip]="'COMMON.ACTION.TOOLTIP.CANCEL' | transloco"
          >
            <mat-icon svgIcon="arrow-left"></mat-icon>
          </button>
          <span class="flex-spacer"></span>
          <ng-container *ngTemplateOutlet="dataForm.buttonsTpl"></ng-container>
        </mat-card-actions>
      </mat-card>
    </rr-progress-when-first-loading>
  </div>
</div>
