import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { isNil } from '@roadrecord/type-guard';
import { PreferencesCloseContentWindowAction } from './action/preferences-close-content-window.action';
import { Store } from '@ngxs/store';
import { mapTo } from 'rxjs/operators';

@Injectable()
export class CloseDialogsBeforeRouteGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!isNil(next.data) && !isNil(next.data.closeWindowKeys) && Array.isArray(next.data.closeWindowKeys)) {
      return this.store.dispatch(next.data.closeWindowKeys.map(key => new PreferencesCloseContentWindowAction(key))).pipe(mapTo(true));
    }
    console.warn('Not found close window keys');
    return true;
  }
}
