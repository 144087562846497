import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RecommendationCancelAction } from '../recommendation/state/action/recommendation-cancel.action';
import { RecommendationState } from '../recommendation/state/recommendation.state';
import { mapTo } from 'rxjs/operators';

@Injectable()
export class LeaveCancelGuard implements CanDeactivate<any> {
  constructor(private store: Store) {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const run = this.store.selectSnapshot<boolean>(RecommendationState.run);
    if (run) {
      return this.store.dispatch(new RecommendationCancelAction()).pipe(mapTo(true));
    }

    return true;
  }
}
