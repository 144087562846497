import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TrialTimeExpiredComponent } from './expired/trial-time-expired/trial-time-expired.component';
import { TrialTimeExpiredLayoutGuard } from './expired-trial-time-layout-guard.service';
import { AutoSubscribeComponent } from './expired/auto-subscribe/auto-subscribe.component';

const routes: Routes = [
  {
    path: 'expired',
    component: TrialTimeExpiredComponent,
    canActivate: [TrialTimeExpiredLayoutGuard],
    canDeactivate: [TrialTimeExpiredLayoutGuard],
    children: [
      {
        path: 'payment-response',
        component: TrialTimeExpiredComponent,
      },
    ],
  },
  {
    path: 'auto-subscribe',
    component: AutoSubscribeComponent,
    canActivate: [TrialTimeExpiredLayoutGuard],
    canDeactivate: [TrialTimeExpiredLayoutGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [TrialTimeExpiredLayoutGuard],
})
export class ExpiredTrialTimeRoutingModule {}
