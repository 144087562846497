export enum FuelTypeHuEnum {
  G95 = '95',
  GASOLINE_ = 'GASOLINE',
  MIX = 'MIX',
  LPG = 'LPG',
  DIESEL = 'DIESEL',
  HYBRID = 'HYBRID',
  ELECTRIC = 'ELECTRIC',
  DIESEL_HYBRID = 'DIESEL_HYBRID',
  // MOTOR_BICYCLE_95 = '95_MOTOR_BICYCLE',
  PLUGIN_95_HYBRID = 'PLUGIN_95_HYBRID',
  PLUGIN_DIESEL_HYBRID = 'PLUGIN_DIESEL_HYBRID',
}
