import { StateContext } from '@ngxs/store';
import { UserStateKeyRefreshAction } from '@roadrecord/common/common';
import { asapScheduler } from 'rxjs';
import { AbstractPeriodContextStateModel } from '../model/abstract-period-context-state.model';
import { PeriodContextRefreshRemoteStateAction } from '../action/period-context-refresh-remote-state.action';

export function detectUserStateKeyRefreshActionFn<STATEMODEL extends AbstractPeriodContextStateModel<any>>(prefix: string) {
  return ({ dispatch }: StateContext<STATEMODEL>, action: UserStateKeyRefreshAction): any => {
    if (action.keyPath === `${prefix}.period-context`) {
      asapScheduler.schedule(() => dispatch(new PeriodContextRefreshRemoteStateAction()));
    }
  };
}
