import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { BatchRequestService, commonHttpStreamErrorHandler, ENTITY_SERVICE_TOKEN, MaybeHandleHttpError } from '@roadrecord/utils';
import {
  CHARGING_STATION_ROUTE_PATH,
  HEAD_OFFICE_OR_SITE_PATH,
  NoopFunction,
  PARTNER_ANONYM_RENAME_FULL_ROUTE_PATH,
  PARTNER_ROUTE_PATH,
  ViewportService,
} from '@roadrecord/common/common';
import { deepmerge } from '@roadrecord/deepmerge';
import { FragmentDialogTypeEnum, FragmentHideDialogAction, FragmentRemoveDialogAction } from '@roadrecord/fragment-dialog';
import {
  ButtonTypeEnum,
  generateDefaultGridHeaderGroupActions,
  generateDefaultGridHeaderOtherActions,
  GridBottomSheetButtonConfig,
  GridCloseBottomSheetAction,
  GridColumnModel,
  GridComponent,
  gridDefaultRightClickOption,
  GridHeaderGroupAction,
  GridHeaderOtherAction,
  GridOpenBottomSheetAction,
  GridRightClickOptionModel,
  NotEntitiesBackgroundImageType,
} from '@roadrecord/grid';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { RightClickMenuItemModel } from '@roadrecord/right-click-menu';
import { isNil } from '@roadrecord/type-guard';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Subscription, timer } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { partnerServiceFactory } from '../partner-service.factory';
import { PartnerService } from '../partner.service';
import { BulkEditTripReasonBottomSheetComponent } from './bulk-edit-trip-reason-bottom-sheet/bulk-edit-trip-reason-bottom-sheet.component';
import { BulkEditTypeBottomSheetComponent } from './bulk-edit-type-bottom-sheet/bulk-edit-type-bottom-sheet.component';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { partnerListColumnConfig } from './model/partner-list-column.config';
import { chargingStationListColumnConfig } from './model/charging-stations-list-column.config';
import { setIconFnPartner } from './set-icon-fn-partner.function';
import { setIconFnChargingStation } from './set-icon-fn-charging-station.function';
import {
  PARTNER_ADRESS_FORMATTER,
  PartnerAddressFormatterReturnType,
  PartnerListModel,
  PartnerModel,
  PartnerTypeEnum,
} from '@roadrecord/partner/model';
import { gotoAnonymPartners } from '../anonym-partners/goto-anonyms-partner.function';

@UntilDestroy()
@Component({
  selector: 'rr-list',
  styleUrls: ['./list.component.scss'],
  templateUrl: './list.component.html',
  providers: [
    {
      provide: PartnerService,
      useFactory: partnerServiceFactory,
      deps: [ActivatedRoute, HttpClient],
    },
    {
      provide: ENTITY_SERVICE_TOKEN,
      useExisting: PartnerService,
    },
  ],
})
export class ListComponent implements AfterViewInit, OnDestroy {
  displayedColumns: GridColumnModel<PartnerListModel>[];
  @ViewChild('rrGrid', { static: true })
  rrGrid: GridComponent;
  titleIcon: string;
  layoutTitle: string[];
  readonly gridNotEntitiesDataLabel: string;
  readonly gridNotEntitiesDataLinkLabel: string;
  rightClickContextMenuOption: GridRightClickOptionModel<PartnerModel>;
  partnerType: PartnerTypeEnum;
  @ViewChildren('bulkEditTripReasonBottomSheetRef') private bulkEditTripReasonBottomSheetRef: QueryList<TemplateRef<any>>;
  @ViewChildren(BulkEditTripReasonBottomSheetComponent) private bulkEditTripReasonBottomSheetComponent: QueryList<
    BulkEditTripReasonBottomSheetComponent
  >;
  @ViewChildren('bulkEditTypeBottomSheetRef') private bulkEditTypeBottomSheetRef: QueryList<TemplateRef<any>>;
  @ViewChildren(BulkEditTypeBottomSheetComponent) private bulkEditTypeBottomSheetComponent: QueryList<BulkEditTypeBottomSheetComponent>;
  loadingBottomSheet$ = new BehaviorSubject(false);
  private fragmentDialogActionsSubscription: Subscription;
  private newHotkeyRef: Hotkey | Hotkey[];
  setIconFn: any;
  readonly gridFilterLabel: string;
  readonly gridFilterPlaceholder: string;
  readonly gridFilterHint: string;
  readonly notEntitiesBackgroundImage: NotEntitiesBackgroundImageType;
  headerGroupActions: GridHeaderGroupAction<any>[] = [];
  headerOtherActions: GridHeaderOtherAction<any>[] = [];
  anonymBackURL: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private viewportService: ViewportService,
    private store: Store,
    private matSnackBar: MatSnackBar,
    readonly translocoService: TranslocoService,
    private partnerService: PartnerService,
    private batchRequestService: BatchRequestService,
    private actions$: Actions,
    private messageDialogService: MessageDialogService,
    private hotkeysService: HotkeysService,
    @Inject(PARTNER_ADRESS_FORMATTER) readonly partnerAddressFormatter: PartnerAddressFormatterReturnType
  ) {
    this.partnerType = route.snapshot.data.type;
    switch (this.partnerType) {
      case PartnerTypeEnum.PARTNER:
        this.anonymBackURL = PARTNER_ROUTE_PATH;
        this.notEntitiesBackgroundImage = 'partnerek';
        this.setIconFn = setIconFnPartner;
        this.displayedColumns = partnerListColumnConfig(this);
        this.layoutTitle = ['PARTNER.LIST.TITLE.PARTNER', 'PARTNER.LIST.SELECTED_TITLE.PARTNER'];
        this.titleIcon = 'account-group';
        this.gridNotEntitiesDataLabel = 'PARTNER.LIST.NOT_ENTITIES_DATA_LABEL.PARTNER';
        this.gridNotEntitiesDataLinkLabel = 'PARTNER.LIST.NOT_ENTITIES_DATA_LINK_LABEL.PARTNER';
        this.gridFilterLabel = 'PARTNER.LIST.FILTER_LABEL.PARTNER.LABEL';
        this.gridFilterPlaceholder = 'PARTNER.LIST.FILTER_LABEL.PARTNER.PLACEHOLDER';
        this.gridFilterHint = 'PARTNER.LIST.FILTER_LABEL.PARTNER.HINT';

        break;
      case PartnerTypeEnum.HEAD_OFFICE_OR_SITE:
        this.anonymBackURL = HEAD_OFFICE_OR_SITE_PATH;
        this.notEntitiesBackgroundImage = 'hq-szines';
        this.setIconFn = setIconFnPartner;
        this.displayedColumns = partnerListColumnConfig(this);
        this.layoutTitle = ['PARTNER.LIST.TITLE.HEAD_OFFICE_OR_SITE', 'PARTNER.LIST.SELECTED_TITLE.HEAD_OFFICE_OR_SITE'];
        this.titleIcon = 'home-map-marker';
        this.gridNotEntitiesDataLabel = 'PARTNER.LIST.NOT_ENTITIES_DATA_LABEL.HEAD_OFFICE_OR_SITE';
        this.gridNotEntitiesDataLinkLabel = 'PARTNER.LIST.NOT_ENTITIES_DATA_LINK_LABEL.HEAD_OFFICE_OR_SITE';
        this.gridFilterLabel = 'PARTNER.LIST.FILTER_LABEL.HEAD_OFFICE_OR_SITE.LABEL';
        this.gridFilterPlaceholder = 'PARTNER.LIST.FILTER_LABEL.HEAD_OFFICE_OR_SITE.PLACEHOLDER';
        this.gridFilterHint = 'PARTNER.LIST.FILTER_LABEL.HEAD_OFFICE_OR_SITE.HINT';

        break;
      case PartnerTypeEnum.CHARGING_STATION:
        this.anonymBackURL = CHARGING_STATION_ROUTE_PATH;
        this.notEntitiesBackgroundImage = 'toltoallomasok';
        this.setIconFn = setIconFnChargingStation;
        this.displayedColumns = chargingStationListColumnConfig(this);
        this.layoutTitle = ['PARTNER.LIST.TITLE.CHARGING_STATION', 'PARTNER.LIST.SELECTED_TITLE.CHARGING_STATION'];
        this.titleIcon = 'gas-station';
        this.gridNotEntitiesDataLabel = 'PARTNER.LIST.NOT_ENTITIES_DATA_LABEL.CHARGING_STATION';
        this.gridNotEntitiesDataLinkLabel = 'PARTNER.LIST.NOT_ENTITIES_DATA_LINK_LABEL.CHARGING_STATION';
        this.gridFilterLabel = 'PARTNER.LIST.FILTER_LABEL.CHARGING_STATION.LABEL';
        this.gridFilterPlaceholder = 'PARTNER.LIST.FILTER_LABEL.CHARGING_STATION.PLACEHOLDER';
        this.gridFilterHint = 'PARTNER.LIST.FILTER_LABEL.CHARGING_STATION.HINT';

        break;
    }

    this.subscribeFragmentDialogActions();

    this.addNewHotkey();
  }

  private addNewHotkey() {
    timer(0).subscribe(() => {
      this.newHotkeyRef = this.hotkeysService.add(
        new Hotkey(
          'alt+shift+n',
          () => {
            this.router.navigate(['./new'], { relativeTo: this.route });
            return true;
          },
          ['INPUT'],
          this.translocoService.translate('COMMON.ACTION.ADD')
        )
      );
    });
  }

  private openBulkEditTripReason(): void {
    this.loadingBottomSheet$.next(false);
    this.store.dispatch(
      new GridOpenBottomSheetAction(this.rrGrid.gridId, {
        panelRef: this.bulkEditTripReasonBottomSheetRef.first,
        loading$: this.loadingBottomSheet$,
        buttons: [
          this.getBottomSheetCancelButton(),
          {
            label: 'COMMON.ACTION.EDIT',
            click: () => {
              this.loadingBottomSheet$.next(true);
              // disable control
              this.bulkEditTripReasonBottomSheetComponent.first.tripReasonControl.disable();
              // disable buttons
              // snackbar loading
              this.matSnackBar.open(this.translocoService.translate('COMMON.SNACKBAR.MODIFY_START'), undefined, { duration: 9999999 });
              // send rest
              const selectedPartners = this.rrGrid.selection.selected;
              this.partnerService
                .bulkEditTripReason(
                  selectedPartners,
                  this.bulkEditTripReasonBottomSheetComponent.first.tripReasonControl.value,
                  this.batchRequestService
                )
                .subscribe(
                  response => {
                    const errors = response
                      .map((resp, index) => {
                        if (resp.code !== 200) {
                          return {
                            response: resp,
                            partner: selectedPartners[index],
                          };
                        }
                        return null;
                      })
                      .filter(resp => resp !== null);
                    if (errors.length > 0) {
                      const rows = errors.map(error => `<tr><td>${error.partner.name}</td><td>${error.response.body.detail}</td></tr>`);
                      const captionKey = 'PARTNER.LIST.BULK_EDIT_TRIP_REASON_BOTTOM_SHEET.ERROR.CAPTION.';
                      const errorTable = `<table><caption>${this.translocoService.translate(
                        `${captionKey}${selectedPartners.length === errors.length ? 'FULL_ERROR' : 'ERROR'}`,
                        { successCount: selectedPartners.length - errors.length }
                      )}</caption><thead><tr><th>${this.translocoService.translate(
                        'PARTNER.LIST.BULK_EDIT_TRIP_REASON_BOTTOM_SHEET.ERROR.HEADER.PARTNER'
                      )}</th><th>${this.translocoService.translate(
                        'PARTNER.LIST.BULK_EDIT_TRIP_REASON_BOTTOM_SHEET.ERROR.HEADER.ERROR'
                      )}</th></tr></thead><tbody>${rows.join('')}</tbody></table>`;
                      this.messageDialogService.openWarning(
                        {
                          id: null,
                          text: errorTable,
                          htmlMode: true,
                        },
                        {
                          panelClass: ['with-table-content', 'has-scroll-table', 'responsive'],
                        }
                      );
                    }
                    this.loadingBottomSheet$.next(false);
                    // snackbar remove loading
                    // snackbar success
                    this.matSnackBar.open(this.translocoService.translate('COMMON.SNACKBAR.MODIFY_SUCCESS'));
                    this.store.dispatch(new GridCloseBottomSheetAction(this.rrGrid.gridId));
                    // reset control when success
                    this.bulkEditTripReasonBottomSheetComponent.first.tripReasonControl.reset('');
                    this.rrGrid.refresh();
                  },
                  commonHttpStreamErrorHandler(() => {
                    this.loadingBottomSheet$.next(false);
                    this.matSnackBar.dismiss();
                  })
                );
            },
            color: 'primary',
            type: ButtonTypeEnum.RAISED,
            disabled: () =>
              this.bulkEditTripReasonBottomSheetComponent.length === 1
                ? this.bulkEditTripReasonBottomSheetComponent.first.tripReasonControl.invalid ||
                  this.bulkEditTripReasonBottomSheetComponent.first.tripReasonControl.disabled
                : false,
          },
        ],
      })
    );
  }

  private subscribeFragmentDialogActions(): void {
    this.fragmentDialogActionsSubscription = combineLatest([
      this.actions$.pipe(
        ofActionSuccessful(FragmentHideDialogAction),
        filter(action => action.dialogType === FragmentDialogTypeEnum.NEW_TRIP_REASON),
        first()
      ),
      this.actions$.pipe(
        // back button kezelese
        ofActionSuccessful(FragmentRemoveDialogAction),
        filter(action => action.dialogType === FragmentDialogTypeEnum.NEW_TRIP_REASON),
        first()
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe((_actions: [FragmentHideDialogAction, FragmentRemoveDialogAction]) => {
        if (_actions[0] !== undefined && _actions[0].data !== undefined) {
          this.bulkEditTripReasonBottomSheetComponent.first.tripReasonControl.patchValue(_actions[0].data);
        }

        if (_actions[0] !== undefined && _actions[1] !== undefined) {
          this.fragmentDialogActionsSubscription.unsubscribe();
          this.subscribeFragmentDialogActions();
        }
      });
  }

  private getBottomSheetCancelButton(): GridBottomSheetButtonConfig<PartnerModel> {
    return {
      label: 'COMMON.ACTION.CANCEL',
      click: () => this.cancelBottomSheet(),
      type: ButtonTypeEnum.SIMPLE,
    };
  }

  private openBulkEditType(): void {
    this.loadingBottomSheet$.next(false);
    this.store.dispatch(
      new GridOpenBottomSheetAction(this.rrGrid.gridId, {
        panelRef: this.bulkEditTypeBottomSheetRef.first,
        loading$: this.loadingBottomSheet$,
        buttons: [
          this.getBottomSheetCancelButton(),
          {
            label: 'PARTNER.LIST.BULK_EDIT_TYPE_BOTTOM_SHEET.BUTTONS.SUBMIT',
            click: () => {
              this.loadingBottomSheet$.next(true);
              // disable control
              this.bulkEditTypeBottomSheetComponent.first.typeControl.disable();
              // disable buttons
              // snackbar loading
              this.matSnackBar.open(this.translocoService.translate('COMMON.SNACKBAR.MODIFY_START'), undefined, { duration: 9999999 });
              // send rest
              const selectedPartners = this.rrGrid.selection.selected;
              this.partnerService
                .bulkEditType(selectedPartners, this.bulkEditTypeBottomSheetComponent.first.typeControl.value, this.batchRequestService)
                .subscribe(
                  response => {
                    const errors = response
                      .map((resp, index) => {
                        if (resp.code !== 200) {
                          return {
                            response: resp,
                            partner: selectedPartners[index],
                          };
                        }
                        return null;
                      })
                      .filter(resp => resp !== null);
                    if (errors.length > 0) {
                      const rows = errors.map(error => `<tr><td>${error.partner.name}</td><td>${error.response.body.detail}</td></tr>`);
                      const captionKey = 'PARTNER.LIST.BULK_EDIT_TYPE_BOTTOM_SHEET.ERROR.CAPTION.';
                      const errorTable = `<table><caption>${this.translocoService.translate(
                        `${captionKey}${selectedPartners.length === errors.length ? 'FULL_ERROR' : 'ERROR'}`,
                        { successCount: selectedPartners.length - errors.length }
                      )}</caption><thead><tr><th>${this.translocoService.translate(
                        'PARTNER.LIST.BULK_EDIT_TYPE_BOTTOM_SHEET.ERROR.HEADER.PARTNER'
                      )}</th><th>${this.translocoService.translate(
                        'PARTNER.LIST.BULK_EDIT_TYPE_BOTTOM_SHEET.ERROR.HEADER.ERROR'
                      )}</th></tr></thead><tbody>${rows.join('')}</tbody></table>`;
                      this.messageDialogService.openWarning(
                        {
                          id: null,
                          text: errorTable,
                          htmlMode: true,
                        },
                        {
                          panelClass: ['with-table-content', 'has-scroll-table', 'responsive'],
                        }
                      );
                    }

                    this.loadingBottomSheet$.next(false);
                    // snackbar remove loading
                    // snackbar success
                    this.matSnackBar.open(this.translocoService.translate('COMMON.SNACKBAR.MODIFY_SUCCESS'));
                    this.store.dispatch(new GridCloseBottomSheetAction(this.rrGrid.gridId));
                    // reset control when success
                    this.bulkEditTypeBottomSheetComponent.first.typeControl.reset('');
                    this.rrGrid.refresh();
                  },
                  commonHttpStreamErrorHandler(() => {
                    this.loadingBottomSheet$.next(false);
                    this.matSnackBar.dismiss();
                  })
                );
            },
            color: 'primary',
            type: ButtonTypeEnum.RAISED,
            disabled: () =>
              this.bulkEditTypeBottomSheetComponent.length === 1
                ? this.bulkEditTypeBottomSheetComponent.first.typeControl.invalid ||
                  this.bulkEditTypeBottomSheetComponent.first.typeControl.disabled
                : false,
          },
        ],
      })
    );
  }

  private cancelBottomSheet(): void {
    this.store.dispatch(new GridCloseBottomSheetAction(this.rrGrid.gridId));
  }

  ngAfterViewInit(): void {
    const maybeOptions: RightClickMenuItemModel<PartnerModel>[] = [];

    const mapItem = {
      label: 'PARTNER.LIST.FAB.MAP',
      icon: 'map-marker-multiple',
      click: () => this.router.navigate(['./map'], { relativeTo: this.route }),
      enabled: () =>
        !(
          isNil(this.rrGrid.dataSource.data) ||
          (Array.isArray(this.rrGrid.dataSource.data) && this.rrGrid.dataSource.data.length === 0) ||
          this.viewportService.isMobile
        ),
    };

    timer(0).subscribe(() => {
      const gridDefault = gridDefaultRightClickOption(this.rrGrid);
      const afterElements = [];
      const bulkEditType = {
        icon: 'account-group',
        label: 'PARTNER.LIST.FAB.BULK_TYPE_EDIT',
        enabled: () =>
          !isNil(this.rrGrid.dataSource.data) &&
          Array.isArray(this.rrGrid.dataSource.data) &&
          this.rrGrid.dataSource.data.length > 1 &&
          this.rrGrid.selection.selected.length > 1,
        click: () => this.openBulkEditType(),
      };
      if (this.partnerType !== PartnerTypeEnum.CHARGING_STATION) {
        const bulkEditTripReason = {
          ...bulkEditType,
          icon: 'book-open-page-variant',
          label: 'PARTNER.LIST.FAB.BULK_TRIP_REASON_EDIT',
          click: () => this.openBulkEditTripReason(),
        };
        gridDefault.rows.splice(gridDefault.rows.length - 2, 0, bulkEditTripReason);
        afterElements.push(bulkEditTripReason);
      }

      gridDefault.rows.splice(gridDefault.rows.length - 2, 0, bulkEditType);
      afterElements.push(bulkEditType);

      this.rightClickContextMenuOption = deepmerge(gridDefault, {
        rows: [...maybeOptions, mapItem],
        grid: [...maybeOptions, mapItem, { divider: true }, ...afterElements],
      });
    });

    const headerGroupActions: GridHeaderGroupAction<any>[] = [
      {
        type: 'ITEM',
        label: { translate: 'FINALIZATION_OF_ROUTE.CALENDAR.HEADER.TOP_RIGHT_MENU.RENAME_ANONYM_PARTNERS_FROM_PARTNER.LABEL' },
        icon: 'rename-box',
        action: () => this.gotoAnonymPartnersRename(),
      },
      { type: 'DIVIDER' },
      ...generateDefaultGridHeaderGroupActions<any>(this.rrGrid),
      { type: 'DIVIDER' },
    ];

    if (this.partnerType !== PartnerTypeEnum.CHARGING_STATION) {
      headerGroupActions.push({
        type: 'ITEM',
        icon: 'book-open-page-variant',
        label: { translate: 'PARTNER.LIST.FAB.BULK_TRIP_REASON_EDIT' },
        disabled: () => this.rrGrid.selection.selected.length <= 1,
        action: () => this.openBulkEditTripReason(),
      });
    }

    headerGroupActions.push({
      type: 'ITEM',
      icon: 'account-group',
      label: { translate: 'PARTNER.LIST.FAB.BULK_TYPE_EDIT' },
      disabled: () => this.rrGrid.selection.selected.length <= 1,
      action: () => this.openBulkEditType(),
    });
    this.headerGroupActions = headerGroupActions;

    const headerOtherActions: GridHeaderOtherAction<any>[] = [
      ...generateDefaultGridHeaderOtherActions<any>(this.rrGrid),
      { type: 'DIVIDER' },
    ];

    headerOtherActions.push({
      type: 'ITEM',
      icon: 'map-marker-multiple',
      label: { translate: 'PARTNER.LIST.FAB.MAP' },
      route: ['./map'],
      relativeRoute: true,
      disabled: () =>
        isNil(this.rrGrid.dataSource.data) ||
        (Array.isArray(this.rrGrid.dataSource.data) && this.rrGrid.dataSource.data.length === 0) ||
        this.viewportService.isMobile,
    });

    this.headerOtherActions = headerOtherActions;
  }

  ngOnDestroy(): void {
    this.hotkeysService.remove(this.newHotkeyRef);
  }

  gotoAnonymPartnersRename(): void {
    gotoAnonymPartners(this.partnerService, this.router, () => {}, { fromPartner: true, backUrl: this.anonymBackURL }).subscribe(
      NoopFunction,
      error => {
        MaybeHandleHttpError.maybeHandleHttpError(error);
      }
    );

    /*
    this.router.navigate([PARTNER_ANONYM_RENAME_FULL_ROUTE_PATH], {
      queryParams: { fromPartner: true, backUrl: this.anonymBackURL },
      state: {
        list: [],
      },
    });
*/
  }
}
