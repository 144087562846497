<div id="unauthenticatedFooter" class="unauthenticated-footer">
  <p class="first-row">
    Copyright ©
    <a [href]="'USER.UNAUTHENTICATED_FOOTER.LINK.ROADRECORD' | transloco" target="_blank">{{
      'USER.UNAUTHENTICATED_FOOTER.TEXT.ROADRECORD' | transloco
    }}</a>
    <span>&nbsp;2001-{{ getThisYear() }}</span>
  </p>
  <p class="second-row">
    <a class="eula" [href]="'USER.UNAUTHENTICATED_FOOTER.LINK.EULA' | transloco" target="_blank">{{
      'USER.UNAUTHENTICATED_FOOTER.TEXT.EULA' | transloco
    }}</a>
    <span class="point-separator"></span>
    <a class="privacy-policy" [href]="'USER.UNAUTHENTICATED_FOOTER.LINK.PRIVACY_POLICY' | transloco" target="_blank">{{
      'USER.UNAUTHENTICATED_FOOTER.TEXT.PRIVACY_POLICY' | transloco
    }}</a>
    <span class="point-separator"></span>
    <a class="customer-service" [href]="'USER.UNAUTHENTICATED_FOOTER.LINK.CUSTOMER_SERVICE' | transloco" target="_blank">{{
      'USER.UNAUTHENTICATED_FOOTER.TEXT.CUSTOMER_SERVICE' | transloco
    }}</a>
  </p>
  <p class="third-row">
    <a class="server" [href]="'USER.UNAUTHENTICATED_FOOTER.LINK.SERVER' | transloco" target="_blank"
      >{{ 'USER.UNAUTHENTICATED_FOOTER.TEXT.SERVER' | transloco }} {{ serverVersion$ | async }}</a
    >
    <span class="point-separator"></span>
    <a class="client" [href]="'USER.UNAUTHENTICATED_FOOTER.LINK.CLIENT' | transloco" target="_blank"
      >{{ 'USER.UNAUTHENTICATED_FOOTER.TEXT.CLIENT' | transloco }} {{ clientVersion }}</a
    >
  </p>
</div>
