import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { isNil } from '@roadrecord/type-guard';
import { PoiMoveSaveModel } from './model/poi-move-save.model';
import { LocationModel } from '@roadrecord/partner/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'rr-poimove-dialog',
  templateUrl: './poimove-dialog.component.html',
})
export class PoimoveDialogComponent implements OnInit {
  @ViewChild('poimoveFormTpl', { static: true })
  poimoveFormTpl: TemplateRef<any>;
  @ViewChild('poimoveButtonsTpl', { static: true })
  poimoveButtonsTpl: TemplateRef<any>;
  @ViewChild('addressMatSelect', { static: false })
  addressMatSelect: MatSelect;
  @Input()
  pois: LocationModel[];
  form: FormGroup & { submitted?: boolean };
  addressControl = new FormControl(undefined);
  twoWaySwitchControl = new FormControl(true);
  @Output()
  modify = new EventEmitter<PoiMoveSaveModel>();
  visibleAddressList = true;

  constructor(
    private cdr: ChangeDetectorRef,
    private readonly matSnackBar: MatSnackBar,
    private readonly translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.twoWaySwitchControl.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(value => {
      this.visibleAddressList = value;
      this.addressControl.patchValue(null);
    });
  }

  onSubmit(): void {
    this.cdr.detectChanges();
    const withoutAddressChange = !this.visibleAddressList;
    this.form.submitted = true;
    if (isNil(this.pois) || this.pois.length === 1) {
      // one poi mode
      this.modify.emit({
        withoutAddressChange,
        data: this.pois[0],
      });
    } else {
      // to many poi mode
      const finishCb = (poi?: LocationModel) => {
        this.form.disable();
        const addressHash = this.form.value.address;
        this.modify.emit({
          withoutAddressChange,
          data: !isNil(poi) ? poi : this.pois.find(_poi => _poi.hash === addressHash),
        });
        this.form.reset();
        this.form.enable();
      };

      if (withoutAddressChange === true) {
        // Cím nélküli módban nem kötelező címet választani, hisz csak a koordinatakat írjuk felül
        return finishCb(this.pois[0]);
      }

      if (this.form === undefined || (this.form.valid && this.pois.length === 1)) {
        return finishCb();
      } else if (this.addressMatSelect !== undefined) {
        if (isNil(this.addressMatSelect.value)) {
          this.addressMatSelect.focus();
          this.addressMatSelect.open();
          this.matSnackBar.open(
            this.translocoService.translate('PARTNER.DETAILS.POI_MOVE_DIALOG.TO_MANY_ADDRESS.SNACKBAR.SELECT_BEFORE_CHANGE')
          );
        } else {
          finishCb();
        }
      }
    }
  }

  private initForm(): void {
    this.form = new FormGroup({
      address: this.addressControl,
    });
  }
}
