import { MaskedNumber } from 'imask';

/**
 * csak azert kell, mert a MaskedNumberOptions osztaly nem publikus
 */
export interface ImaskNumberConfigInterface {
  /**
   * digits after point, 0 for integers
   */
  scale?: number;
  /**
   * disallow negative
   */
  signed?: boolean;
  /**
   * any single char
   */
  thousandsSeparator?: string;
  /**
   * if true, then pads zeros at end to the length of scale
   */
  padFractionalZeros?: boolean;
  /**
   * appends or removes zeros at ends
   */
  normalizeZeros?: boolean;
  /**
   * fractional delimiter
   */
  radix?: string;
  /**
   * symbols to process as radix
   */
  mapToRadix?: string[];
  /**
   * additional number interval options (e.g.)
   */
  min?: number;
  /**
   * additional number interval options (e.g.)
   */
  max?: number;
}

export class ImaskNumberConfig extends MaskedNumber {
  mapToRadix: string[];
  max: number;
  min: number;
  normalizeZeros: boolean;
  padFractionalZeros: boolean;
  radix: string;
  scale: number;
  signed: boolean;
  thousandsSeparator: string;

  constructor(data: Partial<ImaskNumberConfigInterface>) {
    super(
      Object.assign(
        {
          mask: Number,
          scale: 2,
          signed: false,
          padFractionalZeros: false,
          normalizeZeros: true,
          thousandsSeparator: ' ',
          radix: ',',
          mapToRadix: ['.'],
        },
        data
      )
    );
  }

  /**
   * Fix:
   * Ha ures string kerul a mezobe akkor 0 erteket ir vissza a control-ba,
   * de az nem jelenik meg, igy inkabb ures string-et adunk vissza a control fele.
   *
   * Varunk a valaszra: https://github.com/uNmAnNeR/imaskjs/issues/432
   */
  // @ts-ignore
  get typedValue(): number {
    if (this.unmaskedValue.length === 0) {
      return '' as any;
    }
    return Number(this.unmaskedValue);
  }

  set typedValue(n: number) {
    super.typedValue = n;
  }
}
