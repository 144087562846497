import { AfterViewInit, ChangeDetectorRef, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { RRCalendarComponent, setHeaderDayNumberCssClass } from '@roadrecord/calendar';
import { deletePopup, MessageDialogService } from '@roadrecord/message-dialog';
import { commonHttpStreamErrorHandler, ENTITY_SERVICE_TOKEN } from '@roadrecord/utils';
import { RightClickMenuItemModel } from '@roadrecord/right-click-menu';
import moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { FuelingService } from '../fueling.service';
import { FuelingModel } from '../model/fueling.model';
import { FuelingCalendarEvent } from './model/fueling-calendar-event';
import { FuelingCalendarMonthViewDay } from './model/fueling-calendar-month-view-day';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DocumentDataCheckerActionsEnum, isDocumentDataCheckerRouteStateModel } from '@roadrecord/document-data-checker-action/model';
import { fuelingRangeOpenWindowAction } from '@roadrecord/fueling-range/open-window-action';
import { monthStatusListOpenWindowAction } from '@roadrecord/mileage/common';
import { LoadableService } from '@roadrecord/external-packages/ngx-loadable';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { PreferencesContentWindowTypeEnum, PreferencesState } from '@roadrecord/preferences-state/common';
import { periodContextChangeFunction } from './period-context-change.function';
import { dblClickCellFunction } from './dbl-click-cell.function';
import { UpdatedMileageListAction } from '@roadrecord/mileage/state';
import { SplitButtonAction } from '@roadrecord/split-button';
import { UniversalImportService } from '@roadrecord/universal-import/common';
import { clickFuelingImportRightMenuButton } from './click-fix-import.function';
import { fuelingImportAction } from '../import/window-content/window-content.action';
import { fuelingImportEntityDefaultOrder, FuelingUniversalImportService } from '../import/fueling-universal-import.service';
import { FinalizationOfRouteUniversalImportService } from '@roadrecord/finalization-of-route/common';
import { calendarExtraProviders } from './calendar-extra.providers';
import { calendarExtraTopRightButtons } from './calendar-extra-top-right-buttons';

@UntilDestroy()
@Component({
  selector: 'rr-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['calendar.component.scss'],
  providers: [FuelingService, { provide: ENTITY_SERVICE_TOKEN, useExisting: FuelingService }, ...calendarExtraProviders],
})
export class CalendarComponent implements OnInit, AfterViewInit {
  viewDate$: Observable<Date>;
  editModel: FuelingModel;
  rightClickContextMenuOption: RightClickMenuItemModel<FuelingModel[]>[];
  readonly setHeaderDayNumberCssClassFn = setHeaderDayNumberCssClass;
  topRightMenuAfterButtons: SplitButtonAction[] = [
    {
      type: 'ITEM',
      label: { translate: 'MILEAGE.LIST_WINDOW_CONTENT.HEADER_TITLE' },
      icon: 'clock-end',
      action: () => this.openMonthStatusListWindow(),
      disabled: () =>
        this.store.selectSnapshot(PreferencesState.hasOpenedContentWindow)(PreferencesContentWindowTypeEnum.MONTH_STATUS_LIST),
    },
  ];
  @ViewChild(RRCalendarComponent, { static: true }) readonly calendarComponent: RRCalendarComponent;

  constructor(
    private fuelingService: FuelingService,
    private cdr: ChangeDetectorRef,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private injector: Injector,
    private translocoService: TranslocoService,
    private messageDialogService: MessageDialogService,
    private loadableService: LoadableService,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    private actions$: Actions
  ) {
    this.viewDate$ = this.store.select(this.periodContextStateSelectorsService.viewDate);

    this.detectAutoRefreshFromPopup();
  }

  private detectAutoRefreshFromPopup() {
    this.actions$
      .pipe(ofActionSuccessful(UpdatedMileageListAction), untilDestroyed(this))
      .subscribe(() => this.calendarComponent.refresh());
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        untilDestroyed(this),
        take(1),
        map(() => window.history.state),
        filter(state => isDocumentDataCheckerRouteStateModel(state))
      )
      .subscribe(state => {
        switch (state.action) {
          case DocumentDataCheckerActionsEnum.FLOATING_WINDOW__FUELING_RANGE:
            fuelingRangeOpenWindowAction(
              this.store,
              this.loadableService,
              this.injector,
              this.translocoService as any,
              this.matSnackBar
            ).then();
            break;
          case DocumentDataCheckerActionsEnum.FLOATING_WINDOW__MILEAGE_LIST:
            monthStatusListOpenWindowAction(
              this.store,
              this.loadableService,
              this.injector,
              this.translocoService as any,
              this.matSnackBar
            ).then();
            break;
        }
      });
  }

  onClickCell($event: Partial<FuelingCalendarMonthViewDay>): any {
    dblClickCellFunction(this.router, this.route, $event).then();
  }

  onContextMenuOnDayCell(day: FuelingCalendarMonthViewDay): void {
    const selectedDayText = moment(day.date).format('YYYY-MM-DD');
    this.rightClickContextMenuOption = [
      ...day.events.map(event => ({
        label: `${event.title} (${moment(event.fuelingData.fueling_datetime).format('HH:mm')})`,
        subMenu: [
          {
            label: 'COMMON.ACTION.EDIT',
            icon: 'pencil',
            click: () =>
              this.router.navigate(['day-list', event.fuelingData.id], {
                relativeTo: this.route,
                queryParams: { selectedDay: selectedDayText },
              }),
          },
          {
            label: 'COMMON.ACTION.DELETE',
            icon: 'delete',
            click: () =>
              deletePopup({
                matSnackBar: this.matSnackBar,
                translocoService: this.translocoService,
                messageDialogService: this.messageDialogService,
                slideToggleLabel: 'FUELING.DELETE_DIALOG_RECALCULATION_TOGGLE',
                yesCallback: (finishCallback: () => void, result) => {
                  this.calendarComponent.showLoading();
                  const matSnackBarRef = this.matSnackBar.open(
                    this.translocoService.translate('FUELING.CALENDAR.DELETING_IN_PROCESS', { items: event.title }),
                    undefined,
                    { duration: 999999 }
                  );
                  this.fuelingService.removeV2(event.fuelingData.id, result.slideToggleValue).subscribe(
                    () => {
                      matSnackBarRef.dismiss();
                      finishCallback();
                      this.calendarComponent.refresh();
                    },
                    commonHttpStreamErrorHandler(() => this.calendarComponent.hideLoading())
                  );
                },
                noCallback: undefined,
              }),
          },
        ],
      })),
    ];

    if (day.events.length > 0) {
      this.rightClickContextMenuOption.push({ divider: true });
    }
    this.rightClickContextMenuOption.push(
      {
        label: 'COMMON.ACTION.ADD',
        icon: 'plus',
        click: () =>
          this.router.navigate(['new'], {
            relativeTo: this.route,
            queryParams: { selectedDay: selectedDayText },
          }),
      },
      {
        label: 'FUELING.CALENDAR.OPEN_LIST',
        icon: 'format-list-bulleted-square',
        visible: (items: FuelingModel[]) => items.length > 0,
        click: () =>
          this.router.navigate(['day-list'], {
            relativeTo: this.route,
            queryParams: { selectedDay: selectedDayText },
          }),
      }
    );
    this.cdr.detectChanges();
  }

  fillCalendar(data: unknown): unknown[] {
    const keys = Object.keys(data);
    const events: FuelingCalendarEvent[] = [];

    keys.forEach(day => {
      data[day].forEach(fueling => {
        events.push({
          start: moment(day, 'YYYY-MM-DD').toDate(),
          title: fueling.comment,
          fuelingData: fueling.fueling,
        });
      });
    });

    return events;
  }

  private openMonthStatusListWindow() {
    monthStatusListOpenWindowAction(this.store, this.loadableService, this.injector, this.translocoService as any, this.matSnackBar).then();
  }

  onPeriodContextChange($event: boolean) {
    periodContextChangeFunction($event, this.router);
  }

  ngAfterViewInit() {
    const extraButtons = calendarExtraTopRightButtons({
      calendarComponent: this.calendarComponent,
      universalImportService: this.injector.get(UniversalImportService, null),
      router: this.router,
      route: this.route,
      store: this.store,
      injector: this.injector,
      translocoService: this.translocoService,
      messageDialogService: this.messageDialogService,
      loadableService: this.loadableService,
      matSnackBar: this.matSnackBar,
    });
    if (extraButtons.length > 0) {
      this.topRightMenuAfterButtons = [...this.topRightMenuAfterButtons, ...extraButtons];
    }
  }
}
