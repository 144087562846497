import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  afterViewInitMethodName,
  HasAfterInitView,
  HasViewModelPlugin,
  HasViewSubmitPlugin,
  PresenterStateController,
  SaveModel,
  ViewRemoteFieldErrorPlugin,
} from '@roadrecord/utils';
import { rrFormErrorStateMatcher } from '@roadrecord/validating';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TripReasonModel } from '@roadrecord/trip-reason/model';
import { FragmentDialogTypeEnum, getMetadataByTypesFunction } from '@roadrecord/fragment-dialog';
import { isNotEmptyString } from '@roadrecord/type-guard';
import { Store } from '@ngxs/store';
import { CapitalizeDirective } from '@roadrecord/common/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'rr-data-form, rr-trip-reason-data-form',
  templateUrl: './data-form.component-hu.html',
  styleUrls: ['./data-form.component.scss'],
  providers: [rrFormErrorStateMatcher],
  animations: [
    trigger('transitionMessages', [
      state('enter', style({ width: '100%', opacity: 1, transform: 'translateY(0%)' })),
      transition('void => enter', [
        style({ opacity: 0, width: '100%', transform: 'translateY(-100%)' }),
        animate('350ms cubic-bezier(0.55, 0, 0.55, 0.2)'),
      ]),
      transition('* => void', [
        animate('200ms cubic-bezier(0.55, 0, 0.55, 0.2)', style({ width: '100%', opacity: 0, transform: 'translateY(-100%)' })),
      ]),
    ]),
  ],
})
export class DataFormComponent
  implements OnInit, OnChanges, HasViewModelPlugin<TripReasonModel>, HasViewSubmitPlugin<TripReasonModel>, HasAfterInitView {
  @ViewChild('formTpl', { static: true })
  formTpl: TemplateRef<any>;
  @ViewChild('buttonsTpl', { static: true })
  buttonsTpl: TemplateRef<any>;
  @Input()
  hasSubmitAndNew = true;
  @Input()
  isNew = true;
  @Input()
  editModel: TripReasonModel;
  @Input()
  hasDelete = true;
  @Output()
  submitForm = new EventEmitter<SaveModel<TripReasonModel>>();
  @Output()
  delete = new EventEmitter<void>();
  validationRules: any;
  form: FormGroup;
  tripReasonControl = new FormControl(undefined, Validators.compose([Validators.required, Validators.minLength(3)]));
  isDefaultControl = new FormControl(false);
  isPrivateControl = new FormControl(false, Validators.required);
  disabledWhyEnableIsPrivateAndIsDefault = false;

  constructor(
    private renderer2: Renderer2,
    private fb: FormBuilder,
    @Optional() presenterStateController: PresenterStateController<TripReasonModel, number>,
    private store: Store
  ) {
    if (presenterStateController !== null) {
      const viewRemoteFieldErrorPlugin: ViewRemoteFieldErrorPlugin<TripReasonModel, number> = presenterStateController.get(
        ViewRemoteFieldErrorPlugin
      );
      viewRemoteFieldErrorPlugin.settedRemoteErrors$.pipe(untilDestroyed(this)).subscribe(() => this.setEditModelUIRestriction());
    }
  }

  ngOnInit(): void {
    this.initForm();
  }

  setEditModelUIRestriction(): void {
    if (this.isPrivateControl.value === true) {
      this.isDefaultControl.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}

  onSubmit(): void {}

  onSubmitReset(): void {}

  onDelete(): void {
    this.delete.emit();
  }

  private checkPrivateIsDefault(): void {
    combineLatest([
      this.isDefaultControl.valueChanges,
      this.isPrivateControl.valueChanges.pipe(
        tap(isPrivate => (isPrivate ? this.isDefaultControl.disable() : this.isDefaultControl.enable()))
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(values => (this.disabledWhyEnableIsPrivateAndIsDefault = values[0] === true && values[1] === true));
  }

  private initForm(): void {
    this.form = this.fb.group({
      trip_reason: this.tripReasonControl,
      is_private: this.isPrivateControl,
      is_default: this.isDefaultControl,
    });

    this.checkPrivateIsDefault();
  }

  [afterViewInitMethodName](): void {
    if (this.isNew) {
      const fragmentDialogSearchValue = getMetadataByTypesFunction(this.store, [FragmentDialogTypeEnum.NEW_TRIP_REASON]);
      if (isNotEmptyString(fragmentDialogSearchValue)) {
        this.tripReasonControl.setValue(CapitalizeDirective.capitalizeText(fragmentDialogSearchValue));
      }
    }
  }
}
