import { RRFormWithDynamicFormBottomSheetConfig } from '@roadrecord/utils';
import { Injector } from '@angular/core';
import { VehicleModel, VehicleRelatedRemoteModel } from '@roadrecord/vehicle/model/common';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { dynamicFormConfigHelper } from '@roadrecord/dynamic-form';
import { FuelTypeHuEnum, VehicleHuModel } from '@roadrecord/vehicle/model/hu';
import { globalImaskNumberConfigGenerator } from '@roadrecord/imask/common';

/**
 * file replacement
 */

export function searchMissingFieldConfig(
  bottomSheetData: RRFormWithDynamicFormBottomSheetConfig<VehicleModel> & { vehicleRelated: VehicleRelatedRemoteModel },
  injector: Injector
): FormlyFieldConfig[] {
  const periodContextVehicle = bottomSheetData.data as VehicleHuModel;
  const selectedVehicleType = bottomSheetData.vehicleRelated.get_vehicle_fuels_and_consumption.find(
    vehicleType => vehicleType.id === periodContextVehicle.vehicle_type
  );
  const fuelTypesList = selectedVehicleType.fuel_types;
  const findFuelType = fuelTypesList.find(fuelType => fuelType.id === periodContextVehicle.fuel_type);
  const localAverageFuelList = findFuelType.average_fuel;

  const field: FormlyFieldConfig[] = [
    {
      type: 'flex-layout',
      templateOptions: {
        cssClass: 'w-100',
        flex: {
          fxLayout: 'column',
          fxLayoutGap: '0',
          fxFlex: '100%',
        },
      },
      fieldGroup: [
        {
          type: 'rr-two-way-switch',
          key: 'is_basic_norm',
          templateOptions: {
            hostClass: ['mt-2', 'mb-1', 'd-block'],
            required: true,
            transloco: true,
            flex: {
              fxFlex: '100%',
            },
            fieldLabel: 'VEHICLE.DETAILS.DATA_FORM.LABEL.IS_BASIC_NORM.LABEL',
            iconNameFalse: 'format-section',
            iconNameTrue: 'book-open-variant',
            optionFalse: 'VEHICLE.DETAILS.DATA_FORM.LABEL.IS_BASIC_NORM.FALSE',
            optionFalseTooltip: 'VEHICLE.DETAILS.DATA_FORM.TOOLTIP.IS_BASIC_NORM.FALSE',
            optionTrue: 'VEHICLE.DETAILS.DATA_FORM.LABEL.IS_BASIC_NORM.TRUE',
            optionTrueTooltip: 'VEHICLE.DETAILS.DATA_FORM.TOOLTIP.IS_BASIC_NORM.TRUE',
            disabled:
              periodContextVehicle.fuel_type === FuelTypeHuEnum.ELECTRIC ||
              periodContextVehicle.fuel_type.indexOf(FuelTypeHuEnum.HYBRID) > -1,
          },
        },
        {
          type: 'input',
          key: 'average_fuel',
          templateOptions: {
            required: true,
            transloco: true,
            label: 'VEHICLE.DETAILS.DATA_FORM.LABEL.LOCAL_AVARAGE_FUEL',
            placeholder: 'VEHICLE.DETAILS.DATA_FORM.LABEL.LOCAL_AVARAGE_FUEL',
            flex: {
              fxFlex: '100%',
            },
            numberMaskOptions: {
              mask: globalImaskNumberConfigGenerator({ max: 99 }),
              __disable_deep_copy__: true,
            },
            suffix:
              periodContextVehicle.fuel_type === FuelTypeHuEnum.ELECTRIC ? 'COMMON.UNIT.KWH_PER_100KM' : 'COMMON.UNIT.LITER_PER_100KM',
          },
          hideExpression: model => model.is_basic_norm === false,
        },
        {
          type: 'select',
          key: 'consumption_flat_rate',
          templateOptions: {
            required: true,
            transloco: true,
            label: 'VEHICLE.DETAILS.DATA_FORM.LABEL.LOCAL_AVARAGE_FUEL',
            placeholder: 'VEHICLE.DETAILS.DATA_FORM.LABEL.LOCAL_AVARAGE_FUEL',
            flex: {
              fxFlex: '100%',
            },
            options: localAverageFuelList.reduce((prev, curr) => {
              prev.push({ value: curr.id, label: curr.text });
              return prev;
            }, []),
          },
          hideExpression: model => model.is_basic_norm === true,
        },
      ],
    },
  ];
  return dynamicFormConfigHelper('x' as any, field).config(injector);
}

const translatePrefix = 'FINALIZATION_OF_ROUTE.CALENDAR.AVERAGE_FUEL_BOTTOM_SHEET.';
export const searchMissingFieldTranslate: { title: string; content: string } = {
  title: `${translatePrefix}TITLE`,
  content: `${translatePrefix}CONTENT`,
};
