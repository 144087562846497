import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ActivatedRoute } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { firstStepperFinish } from './first-stepper-finish.function';
import { WINDOW } from '@roadrecord/common/common';
import { Store } from '@ngxs/store';
import { AddAppOverlayAction, AppOverlayFinishedAndRemoveOverlayAction } from '@roadrecord/app-layout-state';
import { timer } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { CompanyContextState, ShowOnSaleEndDateNotificationAction } from '@roadrecord/company-context/common';
import { AppTypeEnum, environment } from '@roadrecord/environment';

@Component({
  selector: 'rr-finish-welcome',
  templateUrl: './finish-welcome.component.html',
  styleUrls: ['./finish-welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinishWelcomeComponent implements OnInit, OnDestroy {
  @Output()
  startApp = new EventEmitter<string>();

  disableButton = false;
  cardsWrapperWidth = 550;

  readonly translateKey = 'FIRST_STEPS.FINISH_WELCOME.LAYOUT';
  readonly isAmericanVersion = environment.appType === AppTypeEnum.US;

  constructor(
    private translocoService: TranslocoService,
    readonly route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private gtmService: GoogleTagManagerService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private store: Store
  ) {}

  ngOnInit() {
    const prefix = 'FOOTER.BUTTON2.';
    this.addAnimatedOverlayText();
    this.startApp.emit(firstStepperFinish(`${prefix}LINK`, `${prefix}LABEL`, this.translocoService, this.gtmService, 'SkipAll'));
  }

  onStartApp(link: string, buttonLabel: string): void {
    this.disableButton = true;
    if (link === 'FOOTER.BUTTON1.LINK') {
      // 3 napos teszt időszaknál az érvényesség lekérdezése, ha a timeline import lett kiválasztva.
      if (this.store.selectSnapshot(CompanyContextState.showOnSaleEndDate)) {
        this.store.dispatch(new ShowOnSaleEndDateNotificationAction());
      }
    }
    this.startApp.emit(firstStepperFinish(link, buttonLabel, this.translocoService, this.gtmService));
  }

  onChangeWidth($event: number) {
    this.cardsWrapperWidth = $event;
    this.cdr.detectChanges();
  }

  private addAnimatedOverlayText() {
    const appOverlayText: HTMLDivElement = (this.document.querySelector('#app-overlay > p') as any) as HTMLDivElement;
    let img = this.document.createElement('img') as HTMLImageElement;
    img.src = 'assets/images/slogan.svg';
    img.style.transition = 'all 450ms ease-in-out';
    img.style.maxWidth = '300px';
    img.style.marginBottom = '2rem';
    this.document.getElementById('app-overlay').insertBefore(img, appOverlayText);

    img = this.document.createElement('img') as HTMLImageElement;
    img.src = 'assets/images/start-anim/bars.svg';
    img.style.transition = 'all 450ms ease-in-out';
    img.style.maxWidth = '300px';
    img.style.marginBottom = '2rem';
    this.document.getElementById('app-overlay').insertBefore(img, appOverlayText);

    this.store.dispatch(new AddAppOverlayAction('', 'rgb(242, 86, 72)', 'background-color 450ms ease-in-out'));
    timer(14 * 350).subscribe(() => this.store.dispatch(new AppOverlayFinishedAndRemoveOverlayAction(450)));
  }

  ngOnDestroy() {}
}
