import { Pipe, PipeTransform } from '@angular/core';
import { CapitalizeDirective } from '@roadrecord/common/common';

@Pipe({
  name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    return CapitalizeDirective.capitalizeText(value);
  }
}
