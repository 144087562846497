import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MobileDetectGuard, MobileOutdateBrowserDetectGuard } from '@roadrecord/mobile-detect';
import {
  COMPANY_MEMBER,
  COMPANY_MEMBER_RESET_PASSWORD,
  USER_FORGOT_PASSWORD,
  USER_LOGIN_PATH,
  USER_PASSWORD_RESENT,
  USER_RESET_PASSWORD,
} from '@roadrecord/common/common';
import { NotNeedAuthGuard } from './authentication/guard/not-need-auth.guard';
import { ForgotPasswordComponent } from './authentication/layout/forgot-password/forgot-password/forgot-password.component';
import { PasswordResetSentComponent } from './authentication/layout/forgot-password/password-reset-sent/password-reset-sent.component';
import { LoginComponent } from './authentication/layout/login/login.component';
import { RegisterComponent } from './authentication/layout/register/register.component';
import { RegistrationSuccessfulComponent } from './authentication/layout/register/registration-successful/registration-successful.component';
import { ResendActivationSuccessfulComponent } from './authentication/layout/register/resend-activation/resend-activation-successful/resend-activation-successful.component';
import { ResendActivationComponent } from './authentication/layout/register/resend-activation/resend-activation.component';
import { ResetPasswordModeEnum } from './authentication/layout/reset-password/reset-password-mode.enum';
import { ResetPasswordComponent } from './authentication/layout/reset-password/reset-password.component';
import { StopDeactivateGuard } from './stop-deactivate.guard';
import { UnauthWrapperComponent } from './unauth-wrapper/unauth-wrapper.component';

const routes: Routes = [
  {
    path: '',
    component: UnauthWrapperComponent,
    children: [
      {
        path: USER_LOGIN_PATH,
        component: LoginComponent,
        canActivate: [MobileDetectGuard, NotNeedAuthGuard],
      },
      { path: USER_FORGOT_PASSWORD, component: ForgotPasswordComponent, canActivate: [MobileDetectGuard, NotNeedAuthGuard] },
      { path: USER_PASSWORD_RESENT, component: PasswordResetSentComponent, canActivate: [MobileDetectGuard, NotNeedAuthGuard] },
      { path: 'register', component: RegisterComponent, canActivate: [MobileOutdateBrowserDetectGuard, NotNeedAuthGuard] },

      { path: 'resendactivation', component: ResendActivationComponent },
      {
        path: 'resend-activation-successful',
        component: ResendActivationSuccessfulComponent,
        canDeactivate: [StopDeactivateGuard],
        canActivate: [MobileOutdateBrowserDetectGuard, NotNeedAuthGuard],
      },
      {
        path: USER_RESET_PASSWORD,
        component: ResetPasswordComponent,
        canActivate: [NotNeedAuthGuard],
        data: {
          userMode: ResetPasswordModeEnum.SIMPLE_USER,
        },
      },
      {
        path: `${COMPANY_MEMBER}/${COMPANY_MEMBER_RESET_PASSWORD}`,
        component: ResetPasswordComponent,
        canActivate: [NotNeedAuthGuard],
        data: {
          userMode: ResetPasswordModeEnum.COMPANY_MEMBER,
        },
      },
    ],
  },
  {
    path: 'registration-successful',
    component: RegistrationSuccessfulComponent,
    canDeactivate: [StopDeactivateGuard],
    canActivate: [MobileOutdateBrowserDetectGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
