import { FieldType } from '@ngx-formly/core';
import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: `rr-two-way-switch-field`,
  templateUrl: './rr-two-way-switch-field.component.html',
  styleUrls: ['./rr-two-way-switch-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RRTwoWaySwitchFieldComponent extends FieldType implements OnInit {
  @HostBinding('class')
  hostClass: string | string[];

  ngOnInit() {
    this.hostClass = Array.isArray(this.to.hostClass) ? this.to.hostClass.join(' ') : this.to.hostClass;
  }
}
