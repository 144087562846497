<mat-progress-bar color="warn" mode="indeterminate" *ngIf="loading" [ngStyle]="{ width: data.width }"></mat-progress-bar>
<ng-container *ngIf="data.translateKey !== undefined || data.title !== undefined">
  <h2 mat-dialog-title [ngClass]="{ 'has-buttons': data?.maximizeMode }">
    {{ data.translateKey !== undefined ? (data.translateKey | transloco) : data.title }}
    <ng-container *ngIf="data?.maximizeMode">
      <button
        type="button"
        *ngIf="maximized; else maximizedButton"
        mat-icon-button
        matTooltip="{{ 'COMMON.DIALOG.TOOLTIP.MINIMIZE' | transloco }}"
        matTooltipPosition="below"
        (click)="minimize()"
      >
        <mat-icon svgIcon="window-minimize"></mat-icon>
      </button>
      <ng-template #maximizedButton>
        <button
          type="button"
          mat-icon-button
          matTooltip="{{ 'COMMON.DIALOG.TOOLTIP.MAXIMIZE' | transloco }}"
          matTooltipPosition="below"
          (click)="maximize()"
        >
          <mat-icon svgIcon="window-maximize"></mat-icon>
        </button>
      </ng-template>
    </ng-container>
  </h2>
</ng-container>

<mat-dialog-content [ngClass]="{ 'close-button-mat-dialog-content': data?.showTopRightCloseButton === true }">
  <button
    *ngIf="data?.showTopRightCloseButton === true"
    color="primary"
    class="close-button"
    mat-mini-fab
    aria-label="close dialog"
    (click)="onClickClose()"
  >
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <!--
  <button color="primary" class="close-button" mat-mini-fab aria-label="close dialog" (click)="preventClick()">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
-->
  <ng-container *ngTemplateOutlet="data.contentTpl; context: { $implicit: data?.tplContext }"></ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <ng-template [ngIf]="data?.summaryTpl">
    <div class="rr-dialog-summary"><ng-container *ngTemplateOutlet="data.summaryTpl"></ng-container></div>
  </ng-template>
  <div class="actions-left-buttons" *ngIf="data?.hasActionLeftButtons !== false">
    <button
      type="button"
      *ngIf="data.preventCloseCb === undefined; else preventCloseTpl"
      mat-icon-button
      mat-dialog-close
      matTooltipPosition="right"
      matTooltip="{{ data.cancelButtonLabelTooltipKey | transloco }}"
    >
      <mat-icon svgIcon="arrow-left"></mat-icon>
    </button>
    <ng-template #preventCloseTpl>
      <button
        type="button"
        mat-icon-button
        matTooltipPosition="right"
        matTooltip="{{ data.cancelButtonLabelTooltipKey | transloco }}"
        (click)="preventClick()"
      >
        <mat-icon svgIcon="arrow-left"></mat-icon>
      </button>
    </ng-template>
  </div>
  <ng-container *ngTemplateOutlet="data.buttonsTpl"></ng-container>
</mat-dialog-actions>
