export class StepControl {
  public _isValid = false;
  private resetHandlers: any[] = [];

  addResetHandler(cb: any) {
    this.resetHandlers.push(cb);
  }

  reset() {
    this.resetHandlers.forEach(cb => cb());
  }

  get invalid() {
    return !this._isValid;
  }

  get valid() {
    return this._isValid;
  }
}
