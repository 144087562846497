<mat-progress-bar color="warn" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<div #priceDiv class="price-grid-layout p-2" [ngClass]="{ 'price-grid-layout-two-columns': priceList.length <= 2 }">
  <div *ngFor="let priceItem of priceList; index as priceItemIndex">
    <div class="price-card-header p-3" [ngClass]="{ is_popular: priceItem?.is_popular }">
      <div class="most-popular" *ngIf="priceItem?.is_popular"> MOST POPULAR</div>
      <span>{{ priceItem?.name }}</span>
    </div>

    <div class="price-card-gross-price p-3">
      <div class="d-flex justify-content-center" style="gap: 5px">
        <span class="dollar">$</span>
        <span>{{ priceItem?.gross_price }}</span>
      </div>
      <div class="d-flex justify-content-center p-0">
        <p
          class="price-card-gross-price-info"
          [matTooltip]="'PRICE.DATA_FORM.QUESTION_MARK_TOOLTIP' | transloco"
          [matTooltipDisabled]="priceItem?.product_category.is_lifetime === true"
        >
          <ng-container *ngIf="isBasicPrice === true && priceItem.product_category.is_lifetime === false">
            ({{ priceItem?.vehicle_count_gross_price }} /month /vehicle)
            <span *ngIf="priceItem?.product_category.is_lifetime === false" class="question-mark">?</span>
            <br />
          </ng-container>
          <span *ngIf="priceItem?.product_category.is_lifetime === false">Billed at once<br /></span>
          Sales tax included
        </p>
      </div>
    </div>

    <div class="price-subscription-length-label p-0">
      <span>{{ priceItem?.subscription_length_label }}</span>
    </div>

    <ng-container *ngFor="let item of [].constructor(maxFeatureListCount); index as itemIndex">
      <div
        class="p-2"
        [ngClass]="{
          'price-feature-list': priceItem?.product_category.is_lifetime === false,
          'price-feature-list-lifetime': priceItem?.product_category.is_lifetime === true
        }"
      >
        <ng-container *ngIf="priceItem?.feature_list.length > itemIndex">
          <span
            [ngClass]="{ 'italic-text': itemIndex | italicTextFormatPipe: priceItem }"
            [matTooltip]="'PRICE.DATA_FORM.QUESTION_MARK_TOOLTIP' | transloco"
            [matTooltipDisabled]="!(priceItem?.product_category.is_lifetime === true && itemIndex === 1)"
            [innerHTML]="priceItem?.feature_list[itemIndex] | featureTextFormatPipe | sanitizeHtml"
          ></span>
          <span
            class="question-mark"
            [matTooltip]="'PRICE.DATA_FORM.QUESTION_MARK_TOOLTIP' | transloco"
            *ngIf="priceItem?.product_category.is_lifetime === true && itemIndex === 1"
            >?
          </span>
        </ng-container>
      </div>
    </ng-container>

    <div class="price-card-order-button p-0">
      <button type="button" mat-flat-button class="order-button" (click)="onSelectedPackage(priceItemIndex)">
        {{ 'PRICE.DATA_FORM.LIST.ORDER_BUTTON' | transloco }}
      </button>
    </div>
  </div>
</div>

<div class="d-flex justify-content-center flex-column pt-4 pb-4">
  <span class="text-center">
    *Utilizing Stripe, you are automatically eligible to use installment payment options such as
    <a class="links" href="https://www.afterpay.com/" target="_blank">Afterpay</a> or
    <a class="links" href="https://www.affirm.com/" target="_blank">Affirm</a>.
  </span>
  <span class="text-center">
    <strong>Don’t forget, your MileageWise Subscription is tax deductible as a business expense!</strong>
  </span>
</div>

<div
  class="price-grid-layout p-2 d-flex"
  [ngClass]="{ 'price-grid-layout-two-columns': priceList.length <= 2 }"
  *ngIf="priceList.length > 0"
>
  <mat-accordion [style.grid-column]="metaGridColumnCount" class="faq-container">
    <h2 class="meta-expansion-panel-title"> Frequently Asked Questions </h2>
    <mat-expansion-panel *ngFor="let faqItem of priceList[0].product_category.meta.faq">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="meta-expansion-panel-header">
            {{ faqItem.question }}
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p class="meta-expansion-panel-body" [innerHTML]="faqItem.answer"> </p>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<a
  *ngIf="showInStepper || isStepperFinished === false"
  (click)="onBackToStepper()"
  mat-fab
  color="accent"
  matTooltip="{{ 'COMMON.ACTION.BACK' | transloco }}"
  class="back-to-stepper"
>
  <mat-icon style="color: white" svgIcon="arrow-left"></mat-icon>
</a>
