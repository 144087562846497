import { FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { BaseViewRemoteFieldErrorPlugin } from './base-view-remote-field-error.plugin';

/**
 * remote field hibakat koti ossze a form-val
 * abban az esetben hasznaljuk ha valamiert a presenter controller-t nem tudjuk hasznalni es manualisan kell megoldani
 *
 */
export class ManualViewRemoteFieldErrorPlugin<MODEL, IDTYPE> extends BaseViewRemoteFieldErrorPlugin<MODEL, IDTYPE> {
  private form: FormGroup;

  constructor(translocoService: TranslocoService, document: Document, messageDialogService: MessageDialogService) {
    super(translocoService, document, messageDialogService);
  }

  get formGroupLastValue(): FormGroup {
    return this.form;
  }

  set formGroupLastValue(form: FormGroup) {
    this.form = form;
    this.initValidators(form);
  }
}
