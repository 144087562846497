import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { produce } from '@ngxs-labs/immer-adapter';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GetUserStatesSuccessAction } from '@roadrecord/common/common';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { STATE_PREFIX_TOKEN } from '@roadrecord/company-context/common';
import { IS_WEBADMIN } from '@roadrecord/utils';
import { asapScheduler } from 'rxjs';
import { FirstStepsRefreshRemoteStateSuccessAction } from './action/first-steps-refresh-remote-state-success.action';
import { FirstStepsStateModel } from './model/first-steps-state.model';
import { isNil } from '@roadrecord/type-guard';

@State<FirstStepsStateModel>({
  name: 'firstSteps',
  defaults: {
    finished: undefined,
    step: undefined,
    version: 3,
  },
})
@Injectable()
export class FirstStepsState {
  private static isWebadmin = false;

  @Selector()
  static finished(state: FirstStepsStateModel): boolean {
    if (FirstStepsState.isWebadmin) {
      return true;
    }
    return state.finished;
  }

  @Selector()
  static step(state: FirstStepsStateModel): number {
    return state.step;
  }

  constructor(
    @Inject(STATE_PREFIX_TOKEN) private prefix: string,
    @Inject(IS_WEBADMIN) isWebadmin: boolean,
    private translocoService: TranslocoService,
    private messageDialogService: MessageDialogService
  ) {
    FirstStepsState.isWebadmin = isWebadmin;
  }

  @Action(GetUserStatesSuccessAction)
  getUserStatesSuccess({ dispatch }: StateContext<FirstStepsStateModel>, action: GetUserStatesSuccessAction): void {
    asapScheduler.schedule(() => dispatch(new FirstStepsRefreshRemoteStateSuccessAction(action.states[`${this.prefix}.first-steps`])));
  }

  @Action(FirstStepsRefreshRemoteStateSuccessAction)
  periodContextRefreshSuccess(ctx: StateContext<FirstStepsStateModel>, action: FirstStepsRefreshRemoteStateSuccessAction): any {
    produce<FirstStepsStateModel>(ctx, draft => {
      // Kezelni azt ha nincs state ?
      // if (action.state === undefined) {
      //   draft.finished = true;
      // } else {
      // TODO ideiglenes webadmin miatt
      if (!FirstStepsState.isWebadmin) {
        let textErrorCode: string;
        if (isNil(action.state)) {
          textErrorCode = 'FSSTATE1';
        } else if (isNil(action.state.finished)) {
          textErrorCode = 'FSSTATE2';
        }

        if (textErrorCode !== undefined) {
          this.messageDialogService.openError({
            id: null,
            title: this.translocoService.translate('COMMON.ERROR'),
            text: 'COMMON.UNKNOWN_ERROR_WITH_CODE',
            textParams: { errorCode: textErrorCode },
          });
          return;
        }

        draft.step = action.state.step;
        draft.finished = action.state.finished;
      }
      // }
    });
  }
}
