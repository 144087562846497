import { DOCUMENT } from '@angular/common';
import { hasWebGLSupportProviderFactory } from './has-webgl/has-webgl-support-provider-factory';
import { HAS_WEBGL_SUPPORT } from './has-webgl/has-webgl-support.provider';

export const mapBoxProviders = [
  {
    provide: HAS_WEBGL_SUPPORT,
    useFactory: hasWebGLSupportProviderFactory,
    deps: [DOCUMENT],
  },
];
