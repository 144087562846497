import { ChangeDetectorRef, Directive, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';

import { RRFormGroup } from '@roadrecord/common/common';
import { AuthState, UserModel } from '@roadrecord/user/common';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NextStepConfig } from '../../next-step.config';
import { CompanyContextState, goToOrderInANewTab } from '@roadrecord/company-context/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Router } from '@angular/router';

@Directive()
export abstract class StartWelcomeComponentCommon implements OnDestroy {
  readonly user$: Observable<UserModel>;
  readonly nextStep = new EventEmitter<NextStepConfig>();
  disableButton = false;
  readonly form: RRFormGroup;
  readonly aszfControl = new FormControl(false, Validators.requiredTrue);
  firstName: string;

  constructor(
    protected translocoService: TranslocoService,
    protected store: Store,
    protected router: Router,
    protected http: HttpClient,
    protected cdr: ChangeDetectorRef,
    protected document: Document,
    protected gtmService: GoogleTagManagerService
  ) {
    this.user$ = this.store.select(AuthState.user);

    this.firstName = this.store.selectSnapshot(states => states.auth.user.first_name);

    this.form = new RRFormGroup({
      aszf: this.aszfControl,
    });
  }

  ngOnDestroy(): void {}

  abstract startSteps(): void;

  goToPriceList() {
    this.gtmService.pushTag({
      event: `Click GO_TO_PRICE_LIST`,
      label: 'FirstWelcomeChoiceClick',
      category: 'FirstStepper',
    });
    goToOrderInANewTab(this.store.selectSnapshot(CompanyContextState.subscriptionState), this.http, this.translocoService);
  }
}
