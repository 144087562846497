import { HttpClient } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChargingStationService } from './charging-station.service';
import { HeadOfficeOrSiteService } from './head-office-or-site.service';
import { PartnerService } from './partner.service';
import { PartnerTypeEnum } from '@roadrecord/partner/model';
import { isNil } from '@roadrecord/type-guard';

export const PARTNER_SERVICE_TYPE_TOKEN = new InjectionToken('PARTNER_SERVICE_TYPE_TOKEN');

export const _partnerServiceFactory = (type: PartnerTypeEnum, http: HttpClient) => {
  switch (type) {
    case PartnerTypeEnum.PARTNER:
      return new PartnerService(http);
    case PartnerTypeEnum.CHARGING_STATION:
      return new ChargingStationService(http);
    case PartnerTypeEnum.HEAD_OFFICE_OR_SITE:
      return new HeadOfficeOrSiteService(http);
    default:
      return new PartnerService(http);
  }
};

export const partnerServiceFactory = (route: ActivatedRoute, http: HttpClient, partnerServiceType?: PartnerTypeEnum) => {
  let type = route.snapshot.data.type;
  if (!isNil(partnerServiceType)) {
    type = partnerServiceType;
  }
  return _partnerServiceFactory(type, http);
};
