import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { RemoteCommandAction, UserStateKeyRefreshAction } from '@roadrecord/common/common';
import { isNil } from '@roadrecord/type-guard';
import { forkJoin, Observable, of } from 'rxjs';
import { flatMap, switchMap } from 'rxjs/operators';

/**
 * Interceptor felelos a USER-STATE-REFRESH figyelesert, amennyiben talal barmelyik response-ban
 * akkor az abban vesszovel felsorolt kulcsokat frissiti es addig a hivot feltartja
 */
@Injectable({ providedIn: 'root' })
export class StateRefreshInterceptor implements HttpInterceptor {
  private translocoService: TranslocoService;

  constructor(private readonly injector: Injector, private readonly store: Store) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.initLazyLoadTranslateService();

    return next.handle(req).pipe(
      flatMap(evt => {
        if (evt instanceof HttpResponse) {
          const refreshHeader = evt.headers.get('USER-STATE-REFRESH');
          if (!isNil(refreshHeader)) {
            const streams = [];
            const keys = refreshHeader.split(',');
            keys.forEach(key => streams.push(this.store.dispatch(new UserStateKeyRefreshAction(key))));
            return forkJoin(streams).pipe(
              switchMap(() => {
                return of(evt);
              })
            );
          }
          const restCommand = evt.headers.get('REST-COMMAND');
          if (!isNil(restCommand)) {
            const streams = [];
            const keys = restCommand.split('||');
            keys.forEach(key => streams.push(this.store.dispatch(new RemoteCommandAction(key as any))));
            return forkJoin(streams).pipe(switchMap(() => of(evt)));
          }
        }
        return of(evt);
      })
    );
  }

  private initLazyLoadTranslateService(): void {
    if (isNil(this.translocoService)) {
      this.translocoService = this.injector.get(TranslocoService);
    }
  }
}
