import { Pipe, PipeTransform } from '@angular/core';
import { AbstractEntityService, HttpListResponseModel } from '@roadrecord/utils';

@Pipe({
  name: 'hasId',
})
export class HasIdPipe implements PipeTransform {
  transform(value: any, database: AbstractEntityService<HttpListResponseModel<any>, any>): any {
    return database.getModelIdValue(value);
  }
}
