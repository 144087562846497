import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

import { MobileDetectRoutingModule } from './mobile-detect-routing.module';
import { WarningComponent } from './warning/warning.component';
import { SanitizePipeModule } from '@roadrecord/common/common';

@NgModule({
  declarations: [WarningComponent],
  imports: [CommonModule, MobileDetectRoutingModule, TranslocoModule, SanitizePipeModule],
})
export class MobileDetectModule {}
