<ng-container *ngIf="context">
  <ng-template [ngIf]="menuOpened$ | async" [ngIfElse]="closed">
    <p *ngIf="routerMode === false">{{ 'APP_LAYOUT.PERIOD_CONTEXT_DISPLAY.MODAL.TITLE' | transloco }}</p>
    <rr-form [hideHeader]="hideHeader" [dataModel]="context" (formChange)="onFormChange($event)"></rr-form>
    <mat-divider *ngIf="!dialogMode"></mat-divider>
    <mat-progress-bar *ngIf="loading" color="warn" mode="indeterminate"></mat-progress-bar>
  </ng-template>
  <ng-template #closed>
    <div
      class="closed-wrapper"
      (click)="openMenu()"
      [matTooltip]="clickable ? ('APP_LAYOUT.PERIOD_CONTEXT_DISPLAY.PLATE_NUMBER_SELECTOR.MENU_TITLE' | transloco) : undefined"
    >
      <div class="closed-top-wrapper"><mat-icon svgIcon="car" class="light-color"></mat-icon></div>
      <table *ngIf="context">
        <tr>
          <td>{{ context.vehicle.plate_number }}</td>
        </tr>
        <tr>
          <td>{{ context.year }}</td>
        </tr>
        <tr>
          <td>{{ selectedMonthLocalText }}</td>
        </tr>
      </table>
    </div>
  </ng-template>
</ng-container>
