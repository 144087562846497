import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { DaySelectFormControlModule } from '@roadrecord/common-components';
import { FormModule } from '@roadrecord/utils';
import { ValidatingModule } from '@roadrecord/validating';
import { LiveAutoCompleteModule } from '@roadrecord/live-auto-complete';
import { LiveAutoCompleteModule as PartnerLiveAutoCompleteModule } from '@roadrecord/partner/live-auto-complete';
import { IMaskModule } from 'angular-imask';
import { DetailsComponent } from './details.component';
import { StatusCardComponent } from './status-card/status-card.component';
import { MomentModule } from 'ngx-moment';
import { DataFormSheetComponent } from './data-form-sheet/data-form-sheet.component';
import { SlidePanelModule } from './slide-panel/slide-panel.module';
import { CapitalizePipe } from './capitalize.pipe';
import { IsNilPipeModule } from '@roadrecord/common/common';
import { FragmentPresenterModule } from '@roadrecord/fragment-dialog';
import {
  ModalWrapperModule as PartnerModalWrapperModule,
  PARTNER_EDIT_FRAGMENT_PRESENTERS,
  PARTNER_NEW_FRAGMENT_PRESENTERS,
} from '@roadrecord/partner/common';
import { RouterModule } from '@angular/router';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { Ng2OdometerModule } from '@roadrecord/external-packages/ng2-odometer';

@NgModule({
  imports: [
    CommonModule,

    ReactiveFormsModule,

    FlexLayoutModule,

    MatCardModule,
    MatDatepickerModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatSnackBarModule,
    DaySelectFormControlModule,

    ValidatingModule,
    FormModule,

    TranslocoModule,
    LiveAutoCompleteModule,
    PartnerLiveAutoCompleteModule,
    FragmentPresenterModule.forFeature([...PARTNER_NEW_FRAGMENT_PRESENTERS, ...PARTNER_EDIT_FRAGMENT_PRESENTERS]),
    PartnerModalWrapperModule,
    IMaskModule,
    MomentModule,
    Ng2OdometerModule,
    SlidePanelModule,
    MatBottomSheetModule,
    IsNilPipeModule,
    RouterModule,
  ],
  declarations: [DetailsComponent, StatusCardComponent, DataFormSheetComponent, CapitalizePipe],
  entryComponents: [DataFormSheetComponent],
})
export class DetailsModule {}
