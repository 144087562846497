<span
  [ngClass]="{
    disabled: (value | toBoolean) === true,
    'right-side-wrapper': (icon_name_false | isNil) || (icon_name_false | isEmptyString)
  }"
  (click)="setCheck(false)"
  matTooltip="{{ (option_false_tooltip | isEmptyString) || disabled ? undefined : (option_false_tooltip | transloco) }}"
  [matTooltipClass]="name ? name : inputAttrName"
  class="left-side-wrapper"
>
  <mat-icon class="left-side" *ngIf="!(icon_name_false | isNil) && !(icon_name_false | isEmptyString)" [svgIcon]="icon_name_false">
  </mat-icon>
  {{ option_false | transloco }}
</span>
<mat-slide-toggle
  [color]="color"
  [disabled]="disabled"
  [ngClass]="{ disabled: (value | toBoolean) === false }"
  (change)="setCheckFromEvent($event)"
  [checked]="value | toBoolean"
>
  <span
    class="right-side-wrapper"
    matTooltip="{{ (option_true_tooltip | isEmptyString) || disabled ? undefined : (option_true_tooltip | transloco) }}"
    [matTooltipClass]="name ? name : inputAttrName"
  >
    <mat-icon *ngIf="!(icon_name_true | isNil) && !(icon_name_true | isEmptyString)" class="right-side" [svgIcon]="icon_name_true">
    </mat-icon>
    {{ option_true | transloco }}
  </span>
</mat-slide-toggle>
