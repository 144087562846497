import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ApplicationSettingsFormUserConfigForeverHide } from '@roadrecord/user/application-settings';
import { isNil } from '@roadrecord/type-guard';
import { deepEqual } from '@roadrecord/common/common';

@Component({
  selector: 'rr-dialog-list-control',
  templateUrl: './dialog-list-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DialogListControlComponent),
      multi: true,
    },
  ],
})
export class DialogListControlComponent implements ControlValueAccessor {
  @Input() field: FormlyFieldConfig;

  values$ = new BehaviorSubject<ApplicationSettingsFormUserConfigForeverHide[]>([]);
  private onChange: (values: ApplicationSettingsFormUserConfigForeverHide[]) => void;
  private onTouched: () => void;
  readonly formArray = new FormArray([]);

  constructor() {
    this.formArray.valueChanges
      .pipe(
        filter(values => !isNil(values) && Object.keys(values).length > 0),
        distinctUntilChanged((_old, _new) => deepEqual(_old, _new)),
        debounceTime(300)
      )
      .subscribe(values => {
        this.onChange(values);
        this.onTouched();
      });
    this.values$
      .pipe(
        filter(values => !isNil(values)),
        distinctUntilChanged((_old, _new) => deepEqual(_old, _new))
      )
      .subscribe(values => this.formArray.patchValue(values));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formArray.disable();
    } else {
      this.formArray.enable();
    }
  }

  writeValue(obj: any): void {
    this.values$.next(obj);
  }

  getValueFormControl(i: number, option: ApplicationSettingsFormUserConfigForeverHide) {
    if (this.formArray.at(i) === undefined) {
      this.formArray.push(
        new FormGroup({
          key: new FormControl(option.key),
          value: new FormControl(option.value),
        })
      );
    }
    return this.formArray.at(i).get('value') as AbstractControl;
  }
}
