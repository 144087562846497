import { AbstractPeriodContextModel } from '@roadrecord/period-context/common';
import { isCompanyVehicle } from './is-company-vehicle.function';

/**
 * A user jelenleg kivalasztott auto-ja alapjan(user contextbol) megmondja hogy
 * magan auto van-e kivalasztva es nem egyeni vallalkozo-e
 */
export function isPrivateVehicle(state: AbstractPeriodContextModel<any>): boolean {
  return !isCompanyVehicle(state);
}
