<ng-container
  *rrTransloco="let t; fromPath: 'UNIVERSAL_IMPORT.UPLOAD_STEPPER'; overridePath: importConfig.translatePrefix + '.UPLOAD_STEPPER'"
  ><ng-container *ngIf="(importWorkerService.live$ | async) === true"
    ><mat-card [ngClass]="{ 'is-first-step': step | isNil }">
      <mat-card-title>
        <div class="d-flex justify-content-between align-items-center">
          <span><mat-icon svgIcon="cloud-upload" class="light-color" fxFlex="34px"></mat-icon>{{ t('TITLE') }}</span>
          <button
            [attr.id]="importConfig.gtmPrefix + '_step_' + (horizontalStepper.selectedIndex + 1) + '_exit'"
            *ngIf="step !== 'DONE'"
            type="button"
            class="exit-button"
            mat-raised-button
            color="accent"
            (click)="onClickStopProcess()"
            >{{ t('EXIT_PROCESS_BUTTON_LABEL') }}
            <mat-icon svgIcon="stop-circle-outline" class="ml-2"></mat-icon>
          </button> </div
      ></mat-card-title>
      <mat-card-content>
        <mat-horizontal-stepper
          linear
          #horizontalStepper
          class="import-stepper"
          [ngClass]="'step-' + (horizontalStepper.selectedIndex + 1)"
        >
          <ng-template matStepperIcon="done">
            <mat-icon svgIcon="check" class="small"></mat-icon>
          </ng-template>
          <mat-step editable="false" [stepControl]="selectSourceForm">
            <ng-template matStepLabel>{{ t('STEPS_LABEL.SELECT_SOURCE') }}</ng-template>
            <rr-select-source (changeSourceType)="onChangeSourceType($event)" (nextStep)="onFinishSelectSource()"></rr-select-source>
          </mat-step>
          <mat-step editable="false" [stepControl]="uploadPanelForm">
            <ng-template matStepLabel>{{ t('STEPS_LABEL.UPLOAD') }}</ng-template>
            <rr-upload-panel-step
              *ngIf="horizontalStepper.selectedIndex === 1"
              (loading)="onLoading($event)"
              [form]="uploadPanelForm"
              [selectedSourceType]="selectedSourceType"
              (backToSelectSource)="onBackToSelectSource()"
            ></rr-upload-panel-step>
          </mat-step>
          <mat-step editable="false" [stepControl]="associatedStepControl">
            <ng-template matStepLabel>{{ t('STEPS_LABEL.ASSOCIATION') }}</ng-template>
            <rr-association-headers-step
              *ngIf="step === 'ASSOCIATION_HEADER'"
              [requireHeaders]="requireHeaders$ | async"
              [userHeaders]="userHeaders"
              [loading]="loading"
              [submitError]="submitHeaderError"
              (submitHeaders)="onSubmitHeaders($event)"
              (backToFileUpload)="onBackToFileUpload()"
            ></rr-association-headers-step>
          </mat-step>
          <mat-step editable="false">
            <ng-template matStepLabel>{{ t('STEPS_LABEL.DONE') }}</ng-template>
            <rr-finish-step *ngIf="step === 'DONE'"></rr-finish-step>
          </mat-step>
        </mat-horizontal-stepper>
      </mat-card-content>
      <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>
    </mat-card> </ng-container
></ng-container>
