<div class="filter-bar" [matTooltip]="run ? ('REPORT_PRINT.FILTER_BAR.TOOLTIP.RUN_GUARD' | transloco) : undefined">
  <button
    type="button"
    class="link-button"
    (click)="changeFilter(ReportPrintStatusEnum.WARNING)"
    *rrNgIfTyped="countWarning && countWarning > -999"
    [disabled]="run || currentFilter === ReportPrintStatusEnum.WARNING || countWarning === 0 || disabled"
    [ngClass]="{ active: currentFilter === ReportPrintStatusEnum.WARNING }"
    color="accent"
    mat-button
    [matTooltip]="'REPORT_PRINT.FILTER_BAR.TOOLTIP.WARNING' | transloco"
  >
    {{ 'REPORT_PRINT.FILTER_BAR.LABEL.WARNING' | transloco: { countWarning: countWarning } }}
  </button>
  <button
    type="button"
    class="link-button"
    (click)="changeFilter(ReportPrintStatusEnum.NOT_CHECKED)"
    *ngIf="countNotChecked && countNotChecked > -999"
    [disabled]="run || currentFilter === ReportPrintStatusEnum.NOT_CHECKED || countNotChecked === 0 || disabled"
    [ngClass]="{ active: currentFilter === ReportPrintStatusEnum.NOT_CHECKED }"
    color="accent"
    mat-button
    [matTooltip]="'REPORT_PRINT.FILTER_BAR.TOOLTIP.NOT_CHECKED' | transloco"
  >
    {{ 'REPORT_PRINT.FILTER_BAR.LABEL.NOT_CHECKED' | transloco: { countNotChecked: countNotChecked } }}
  </button>
  <button
    type="button"
    class="link-button"
    (click)="changeFilter(ReportPrintStatusEnum.ERROR)"
    *rrNgIfTyped="countError && countError > -999"
    [disabled]="run || currentFilter === ReportPrintStatusEnum.ERROR || countError === 0 || disabled"
    [ngClass]="{ active: currentFilter === ReportPrintStatusEnum.ERROR }"
    color="accent"
    mat-button
    [matTooltip]="'REPORT_PRINT.FILTER_BAR.TOOLTIP.ERROR' | transloco"
  >
    {{ 'REPORT_PRINT.FILTER_BAR.LABEL.ERROR' | transloco: { countError: countError } }}
  </button>
</div>
