import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Optional, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ENTITY_SERVICE_TOKEN } from '@roadrecord/utils';
import { FragmentModalWrapperComponentInterface } from '@roadrecord/fragment-dialog';
import { DetailsComponent } from '../../details/details.component';
import { PARTNER_SERVICE_TYPE_TOKEN, partnerServiceFactory } from '../../partner-service.factory';
import { PartnerService } from '../../partner.service';
import { PartnerModel, PartnerTypeEnum } from '@roadrecord/partner/model';

export const currentHeadOrOfficeModalWrapperType: { type: PartnerTypeEnum } = { type: undefined };

export const partnerStepService = () => {
  if (currentHeadOrOfficeModalWrapperType.type === PartnerTypeEnum.PARTNER) {
    return PartnerTypeEnum.PARTNER;
  } else if (currentHeadOrOfficeModalWrapperType.type === PartnerTypeEnum.HEAD_OFFICE_OR_SITE) {
    return PartnerTypeEnum.HEAD_OFFICE_OR_SITE;
  } else if (currentHeadOrOfficeModalWrapperType.type === PartnerTypeEnum.CHARGING_STATION) {
    return PartnerTypeEnum.CHARGING_STATION;
  }
};

@Component({
  selector: 'rr-partner-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss'],
  providers: [
    {
      provide: PARTNER_SERVICE_TYPE_TOKEN,
      useFactory: partnerStepService,
      deps: [],
    },
    {
      provide: PartnerService,
      useFactory: partnerServiceFactory,
      deps: [ActivatedRoute, HttpClient, [new Optional(), PARTNER_SERVICE_TYPE_TOKEN]],
    },
    {
      provide: ENTITY_SERVICE_TOKEN,
      useExisting: PartnerService,
    },
  ],
})
export class ModalWrapperComponent implements FragmentModalWrapperComponentInterface<PartnerModel> {
  @Input() windowEditModelId: number;
  @Output()
  readonly save = new EventEmitter<PartnerModel>();
  @Output()
  readonly cancel = new EventEmitter<void>();
  @ViewChild('cmpTemplate', { static: true })
  readonly cmpTemplate: TemplateRef<any>;
  /**
   * kulso template-k erhetik el
   */
  @ViewChildren('details')
  readonly details: QueryList<DetailsComponent>;

  uiSave(partner: PartnerModel): void {
    this.save.emit(partner);
  }

  beforeCloseDialog(): void {}
}
