import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { asapScheduler, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GetCompanyMeErrorAction } from './../state/action/company-me/get-company-me-error.action';
import { GetCompanyMeSuccessAction } from './../state/action/company-me/get-company-me-success.action';
import { GetCompanyMeAction } from './../state/action/company-me/get-company-me.action';
import { CompanyMeStateModel } from './model/company-me-state.model';
import { CompanyMeModel } from './model/company-me.model';
import { environment } from '@roadrecord/environment';

const version = 3;

@State<CompanyMeStateModel>({
  name: 'companyMe',
  defaults: { error: null, data: null, version: version },
})
@Injectable()
export class UserCompanyMeState {
  constructor(private http: HttpClient) {}

  @Selector()
  static get(state: CompanyMeStateModel): CompanyMeModel {
    return state.data;
  }

  @Selector()
  static error(state: CompanyMeStateModel): any {
    return state.error;
  }

  @Action(GetCompanyMeAction)
  getCompanyMe({ dispatch }: StateContext<CompanyMeStateModel>): any {
    return this.http.get<CompanyMeModel>(`${environment.apiUrl}company/me/`).pipe(
      tap(response => asapScheduler.schedule(() => dispatch(new GetCompanyMeSuccessAction(response)))),
      catchError(err => {
        asapScheduler.schedule(() => dispatch(new GetCompanyMeErrorAction(err)));
        return throwError(err);
      })
    );
  }

  @Action(GetCompanyMeSuccessAction)
  getCompanyMeSuccess(ctx: StateContext<CompanyMeStateModel>, action: GetCompanyMeSuccessAction) {
    ctx.setState({ data: action.companyMe, error: null, version: version });
  }

  @Action(GetCompanyMeErrorAction)
  getCompanyMeError(ctx: StateContext<CompanyMeStateModel>, action: GetCompanyMeErrorAction) {
    ctx.setState({ data: null, error: action.error, version: version });
  }
}
