import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { asapScheduler, Observable, throwError, timer } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApplicationSettingsService } from '../application-settings.service';
import { createApplicationSettingsServiceInjector } from '../create-application-settings-service-injector.function';
import { AppStartAction } from './action/app-start.action';
import { GetServerVersionErrorAction } from './action/get-server-version-error.action';
import { GetServerVersionSuccessAction } from './action/get-server-version-success.action';
import { GetServerVersionAction } from './action/get-server-version.action';
import { RemoveAppLoadingLayoutAction } from './action/remove-app-loading-layout.action';
import { RemoveClientSideVariableAction } from './action/remove-client-side-variable.action';
import { ApplicationSettingsStateModel } from './model/application-settings-state.model';
import { isNil } from '@roadrecord/type-guard';
import { AppReadyAction } from './action/app-ready.action';

@State<ApplicationSettingsStateModel>({
  name: 'applicationSettings',
  defaults: {
    appLoaded: false,
    version: 3,
  } as ApplicationSettingsStateModel,
})
@Injectable()
export class ApplicationSettingsState {
  constructor(private readonly injector: Injector, @Inject(DOCUMENT) document: Document, private router: Router, private store: Store) {}

  @Selector()
  static osmTileUrl(state: ApplicationSettingsStateModel): string {
    return state.osmTileUrl;
  }

  @Selector()
  static partnerImportRefreshTimeSecond(state: ApplicationSettingsStateModel): number {
    return state.partnerImportRefreshTimeSecond;
  }

  @Selector()
  static serverVersion(state: ApplicationSettingsStateModel): string {
    return state.serverVersion;
  }

  @Selector()
  static appLoaded(state: ApplicationSettingsStateModel): boolean {
    return !isNil(state.appLoaded) ? state.appLoaded : false;
  }

  @Action(GetServerVersionAction)
  getServerVersion({ dispatch }: StateContext<ApplicationSettingsStateModel>): Observable<string> {
    const applicationSettingsService: ApplicationSettingsService = createApplicationSettingsServiceInjector(this.injector).get(
      ApplicationSettingsService
    );

    return applicationSettingsService.getVersion().pipe(
      map(version => version.version),
      tap(version => asapScheduler.schedule(() => dispatch(new GetServerVersionSuccessAction(version)))),
      catchError(error => {
        asapScheduler.schedule(() => dispatch(new GetServerVersionErrorAction(error)));
        return throwError(error);
      })
    );
  }

  @Action(GetServerVersionSuccessAction)
  getServerVersionSuccess({ patchState }: StateContext<ApplicationSettingsStateModel>, action: GetServerVersionSuccessAction): void {
    patchState({ serverVersion: action.version });
  }

  @Action(RemoveClientSideVariableAction)
  removeClientSideVariable({ setState, getState }: StateContext<ApplicationSettingsStateModel>): void {
    setState({ serverVersion: getState().serverVersion } as ApplicationSettingsStateModel);
  }

  /* majd ha lesz kulon app state akkor ez oda valo */
  @Action(AppStartAction)
  appStart({ dispatch }: StateContext<ApplicationSettingsStateModel>, action: AppStartAction): void {
    if (action.time > 0) {
      timer(action.time).subscribe(() => dispatch(new RemoveAppLoadingLayoutAction()));
    } else {
      asapScheduler.schedule(() => dispatch(new RemoveAppLoadingLayoutAction()));
    }
  }

  @Action(RemoveAppLoadingLayoutAction)
  removeAppLoadingLayout(): void {
    /**
     * Toroljuk az app loading elemeket a dom-bol
     */
    const removeAppLayoutCallback = () => {
      if (document.getElementById('page-loader') !== null) {
        document.getElementById('page-loader').remove();
      }
      if (document.getElementById('page-loader-css') !== null) {
        document.getElementById('page-loader-css').remove();
      }
      this.store.dispatch(new AppReadyAction());
    };
    /**
     * Animactio miatt elobb hozza kell raknunk egy class-t a loader elemhez
     */
    if (document.getElementById('page-loader') !== null) {
      document.getElementById('page-loader').classList.add('finished');
    }

    timer(2000).subscribe(() => removeAppLayoutCallback());
  }

  @Action(AppStartAction)
  appLoaded({ patchState }: StateContext<ApplicationSettingsStateModel>): any {
    return patchState({ appLoaded: true });
  }
}
