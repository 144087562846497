<div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="1em" class="wid100 height100">
  <rr-first-stepper-help-box
    [layoutName]="type === 0 ? 'PARTNER' : 'HEAD_OFFICE_OR_SITE'"
    iconType="home-map-marker"
    [helpText]="helpText"
    (detailsHeight)="detailsHeight = $event"
    (skipStep)="onSkipStep()"
  ></rr-first-stepper-help-box>
  <rr-partner-details
    [ngStyle]="{ height: detailsHeight }"
    [hasSubmitAndNew]="false"
    [hasBackButton]="false"
    [hasSearchAddressBackButton]="false"
    [routingMode]="false"
    [dataFormShowAddTripReason]="true"
    [disableAllSelectNewOption]="false"
    searchOnMapSnackbarWeRefreshListDataPosition="bottom"
    (save)="onSavePartner($event)"
  ></rr-partner-details>
</div>
