import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { fadeInVeryRightFast } from '@roadrecord/animations';

@Component({
  selector: 'rr-menu-item-help',
  templateUrl: './menu-item-help.component.html',
  styleUrls: ['./menu-item-help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInVeryRightFast],
})
export class MenuItemHelpComponent {
  @Input() hover: boolean;
  @Input() helpTooltip: string;
}
