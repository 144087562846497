import { Pipe, PipeTransform } from '@angular/core';
import { isEditable } from '@roadrecord/common/common';
import { isNil } from '@roadrecord/type-guard';

@Pipe({
  name: 'isEditable',
})
export class IsEditablePipe implements PipeTransform {
  transform(value?: unknown): boolean {
    if (isNil(value)) {
      return false;
    }
    return isEditable(value);
  }
}
