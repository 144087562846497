import { SubscriptionInfoEnum } from '@roadrecord/company-context/common';

export function getSubscriptionCallbackUrl(subscription: SubscriptionInfoEnum) {
  switch (subscription) {
    case SubscriptionInfoEnum.EXPIRED_SUBSCRIPTION:
    case SubscriptionInfoEnum.EXPIRING_SUBSCRIPTION:
      return 'go-to-renew-subscription';
    case SubscriptionInfoEnum.NO_SUBSCRIPTION:
    case SubscriptionInfoEnum.VALID_SUBSCRIPTION:
    case SubscriptionInfoEnum.NOTIFY_SUBSCRIPTION:
      return 'go-to-ordering';
  }
  return null;
}
