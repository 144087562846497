import { isObject } from '@roadrecord/type-guard';

export function deepDelta(obj1, obj2) {
  const diffObj = Array.isArray(obj2) ? [] : {};
  Object.getOwnPropertyNames(obj2).forEach(prop => {
    if (isObject(obj2[prop])) {
      diffObj[prop] = deepDelta(obj1[prop], obj2[prop]);
      // if it's an array with only length property => empty array => delete
      // or if it's an object with no own properties => delete
      if (
        (Array.isArray(diffObj[prop]) && Object.getOwnPropertyNames(diffObj[prop]).length === 1) ||
        Object.getOwnPropertyNames(diffObj[prop]).length === 0
      ) {
        delete diffObj[prop];
      }
    } else if (obj1[prop] !== obj2[prop]) {
      diffObj[prop] = obj2[prop];
    }
  });
  return diffObj;
}
