import { TranslocoService } from '@ngneat/transloco';
import { FieldHttpError } from '../../../../http/exception/field-http-error';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { isFunction } from '@roadrecord/type-guard';
import { handleFormControlsServerError } from '../../../../form/function/handle-form-controls-server-error.function';
import { FinishType } from './presenter-save.plugin';

export function handleServerError(
  responseError,
  translocoService: TranslocoService,
  finish: FinishType,
  messageDialogService: MessageDialogService
): void {
  if (responseError instanceof FieldHttpError) {
    // responseError['error'] !== undefined && responseError['error']['errors'] !== undefined) {
    // form field validation error
    handleFormControlsServerError(responseError, finish);
  } else {
    // generic error
    messageDialogService
      .openError({ id: null, text: responseError.error.detail })
      .afterClosed()
      .subscribe(() => {
        if (isFunction(finish)) {
          finish();
        }
      });
  }
}
