import { isFunction } from '@roadrecord/type-guard';
import { isNotHandledError } from './is-not-handled-error.function';
import { showCommonError } from './show-common-error.function';

export class MaybeHandleHttpError {
  static maybeHandleHttpError(error: any, clickCallback?: (error: any) => void): void {
    if (isNotHandledError(error)) {
      showCommonError(clickCallback);
    } else if (isFunction(clickCallback)) {
      clickCallback(error);
    }
  }
}
