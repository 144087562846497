import { TranslocoService } from '@ngneat/transloco';
import { MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';

export function notFoundCoordinate(name: string, translocoService: TranslocoService, messageDialogService: MessageDialogService): void {
  if (name === undefined) {
    name = translocoService.translate('COMMON.UNKNOWN');
  }
  messageDialogService.open({
    id: null,
    type: MessageDialogTypeEnum.ERROR,
    title: 'COMMON.ERROR',
    text: 'PARTNER.DETAILS.NOT_FOUND_COORDINATE',
    textParams: { name },
  });
}
