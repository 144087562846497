import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractEntityService, HttpListResponseModel } from '@roadrecord/utils';
import { Observable } from 'rxjs';
import { AssignToPartnerAsDefaultTripReasonModel, TripReasonModel } from '@roadrecord/trip-reason/model';
import { isNil } from '@roadrecord/type-guard';
import { environment } from '@roadrecord/environment';

@Injectable()
export class TripReasonService extends AbstractEntityService<HttpListResponseModel<TripReasonModel>, TripReasonModel> {
  getToString(model: TripReasonModel): string {
    if (isNil(model)) {
      return null;
    }
    return model.trip_reason;
  }

  constructor(http: HttpClient) {
    super('tripreasons', 'trip_reason', http);
  }

  /**
   * ez csak azert kell mert szabalytalan a trip_reason endpoint POST eseten,
   * es van olyan eset a destination-ben amikor a partnerhez rendelve kell letrehozni az trip reason-t
   *
   * FIXME: atalakitani, peldaul POST /trip-reason/relation-partner/:partner_id | post body: trip_reason model
   */
  createAndAssignToPartnerAsDefault(object: AssignToPartnerAsDefaultTripReasonModel): Observable<any> {
    return this.http.post(`${environment.apiUrl}${this.entityUrl}/`, object);
  }

  getModelIdValue(model: TripReasonModel): any {
    if (!isNil(model) && model.hasOwnProperty('id')) {
      return model.id;
    }
    return undefined;
  }
}
