import { TranslocoService } from '@ngneat/transloco';
import { GridColumnModel } from '@roadrecord/grid';
import { TripReasonModel } from '@roadrecord/trip-reason/model';
import { listColumnConfigCommon } from './list-column.config-common';

export const listColumnConfig: (_this: { translocoService: TranslocoService }) => GridColumnModel<TripReasonModel>[] = (_this: {
  translocoService: TranslocoService;
}) => {
  return listColumnConfigCommon({ translocoService: _this.translocoService });
};
