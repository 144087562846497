<mat-card *transloco="let t; read: 'USER.TRIAL_TIME.AUTO_SUBSCRIBE'">
  <mat-card-content>
    <img src="assets/images/expired-trial-time/expired_trial_time_open.svg" />
    <h1 class="w-100 text-center">{{ t('TITLE') }}</h1>
    <p [innerHTML]="t('CONTENT')"></p>
  </mat-card-content>
  <mat-card-actions class="text-center"
    ><button type="button" mat-raised-button color="primary" (click)="onStartApp()" [disabled]="disableButton || currentNumber === 0">
      {{ buttonText ? buttonText : (translateKeyPrefix + 'BUTTON' | transloco) }}
    </button></mat-card-actions
  >
</mat-card>
