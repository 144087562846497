import { CookieService } from 'ngx-cookie-service';
import { _getRegisterOptimonkReferer, _getRegisterReferer } from './get-register-referer.function';

export function getRegisterReferer(cookieService: CookieService, document: Document) {
  return _getRegisterReferer(cookieService, document, 'mw');
}

export function getRegisterOptimonkReferer(cookieService: CookieService, document: Document) {
  return _getRegisterOptimonkReferer(cookieService, document, '');
}
