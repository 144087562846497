import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { AngularDraggableModule } from 'angular2-draggable';
import { DraggableDialogComponent } from './draggable-dialog.component';

@NgModule({
  imports: [CommonModule, AngularDraggableModule, MatButtonModule, MatIconModule, MatTooltipModule, TranslocoModule],
  declarations: [DraggableDialogComponent],
  exports: [DraggableDialogComponent],
})
export class DraggableDialogModule {}
