import { produce } from '@ngxs-labs/immer-adapter';
import { Action, createSelector, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { GridAddSelectionAction } from './action/grid-add-selection.action';
import { GridInitAction } from './../state/action/grid-init.action';
import { GridLoadNextPageAction } from './action/grid-load-next-page.action';
import { GridSaveLastScrollPositionAction } from './../state/action/grid-save-last-scroll-position.action';
import { GridSortChangeAction } from './../state/action/grid-sort-change.action';
import { GridChangeFilterValueAction } from './action/grid-change-filter-value.action';
import { GridRemoveStateAction } from './action/grid-remove-state.action';
import { GridRevertStateAction } from './action/grid-revert-state.action';
import { GridStateModel } from './model/grid-state.model';
import { GridsStateContainer } from './model/grids-state-container.interface';
import { GridRemoveSelectionAction } from './action/grid-remove-selection.action';
import { Injectable } from '@angular/core';
import { GridPagingModel } from './model/grid-paging.model';
import { isNumeric } from '@roadrecord/type-guard';

@State<GridsStateContainer>({
  name: 'grids',
  defaults: { container: {}, previousContainer: {}, version: 4 } as GridsStateContainer,
})
@Injectable()
export class GridState implements NgxsOnInit {
  static container(id: string): any {
    return createSelector([GridState], (state: GridsStateContainer) => {
      if (state !== undefined && state.container !== undefined) {
        return state.container[id];
      }
      return undefined;
    });
  }

  ngxsOnInit(ctx?: StateContext<GridsStateContainer>): void | any {
    // TODO ez kell ?
    // TODO check hogy erre itt szukseg van?
    if (ctx.getState().previousContainer === undefined) {
      ctx.patchState({ previousContainer: {} });
    }
    if (ctx.getState().container === undefined) {
      ctx.patchState({ container: {} });
    }
    if (ctx.getState().version === undefined) {
      ctx.patchState({ version: 3 });
    }
  }

  @Action(GridInitAction)
  initGrid(ctx: StateContext<GridsStateContainer>, action: GridInitAction): any {
    produce(ctx, draft => {
      this.fillInitStateFromAction(action, draft, ctx.getState());
    });
  }

  @Action(GridChangeFilterValueAction)
  gridChangeFilterValue(ctx: StateContext<GridsStateContainer>, action: GridChangeFilterValueAction): any {
    produce(ctx, draft => {
      this.storeLastState(draft, ctx.getState(), action);

      draft.container[action.id].lastAction = 'FILTER';
      draft.container[action.id].filter = action.filterValue;
      this.resetPageIndex(draft, action.id);
    });
  }

  @Action(GridSortChangeAction)
  gridSortChange(ctx: StateContext<GridsStateContainer>, action: GridSortChangeAction): any {
    produce<GridsStateContainer>(ctx, draft => {
      this.storeLastState(draft, ctx.getState(), action);

      draft.container[action.id].lastAction = 'SORT';
      draft.container[action.id].sort = action.sort;
    });
  }

  @Action(GridLoadNextPageAction)
  gridLoadNextPage(ctx: StateContext<GridsStateContainer>, action: GridLoadNextPageAction): any {
    produce<GridsStateContainer>(ctx, draft => {
      this.storeLastState(draft, ctx.getState(), action);

      const { id } = action;

      const { pageIndex } = draft.container[id].page;

      draft.container[id].lastAction = 'PAGE';
      draft.container[id].page = {
        ...draft.container[id].page,
        pageIndex: pageIndex + 1,
      } as GridPagingModel;
    });
  }

  @Action(GridAddSelectionAction)
  gridAddSelection(ctx: StateContext<GridsStateContainer>, action: GridAddSelectionAction): any {
    produce<GridsStateContainer>(ctx, draft => {
      this.storeLastState(draft, ctx.getState(), action);
      const selected = Array.isArray(action.added) ? action.added : [action.added];

      draft.container[action.id].selected = [...new Set([...draft.container[action.id].selected.concat(selected)])];
    });
  }

  @Action(GridRemoveSelectionAction)
  gridRemoveSelection(ctx: StateContext<GridsStateContainer>, action: GridRemoveSelectionAction): any {
    produce<GridsStateContainer>(ctx, draft => {
      this.storeLastState(draft, ctx.getState(), action);
      const selected = Array.isArray(action.removed) ? action.removed : [action.removed];

      draft.container[action.id].selected = [...draft.container[action.id].selected].filter(
        oldSelected => selected.findIndex(removeSelected => oldSelected === removeSelected) === -1
      );
    });
  }

  @Action(GridRemoveStateAction)
  gridRemoveState(ctx: StateContext<GridsStateContainer>, action: GridRemoveStateAction): any {
    produce<GridsStateContainer>(ctx, draft => {
      this.storeLastState(draft, ctx.getState(), action);

      delete draft.container[action.id];
      delete draft.previousContainer[action.id];
    });
  }

  @Action(GridRevertStateAction)
  gridRevertState(ctx: StateContext<GridsStateContainer>, action: GridRevertStateAction): any {
    produce<GridsStateContainer>(ctx, draft => {
      draft.container[action.id].lastAction = 'REVERT';
      draft.container[action.id] = draft.previousContainer[action.id];
    });
  }

  @Action(GridSaveLastScrollPositionAction)
  gridSaveLastScrollPosition(ctx: StateContext<GridsStateContainer>, action: GridSaveLastScrollPositionAction): any {
    produce<GridsStateContainer>(ctx, draft => {
      this.storeLastState(draft, ctx.getState(), action);

      draft.container[action.id].scrollTop = action.scrollTop;
    });
  }

  private resetPageIndex(draft: GridsStateContainer, gridId: string): void {
    draft.container[gridId].page.pageIndex = 0;
  }

  private fillInitStateFromAction(action: GridInitAction, draft: GridsStateContainer, currentState: GridsStateContainer): void {
    this.storeLastState(draft, currentState, action);

    const { id } = action;

    if (draft.container[id] === undefined) {
      draft.container[id] = {} as GridStateModel;
    }
    draft.container[id].filter = action.filter;
    draft.container[id].sort = {
      active: action.defaultSort,
      direction: action.defaultSortDirection,
    };
    draft.container[id].page = {
      // ...draft.container[id].page,
      pageSize: action.pageSize,
    } as GridPagingModel;
    if (!isNumeric(draft.container[id].page.pageIndex)) {
      draft.container[id].page.pageIndex = 0;
    }

    draft.container[id].selected = action.selected;
    draft.container[id].multiselect = action.multiselect;

    // return draft[action.id];
  }

  private storeLastState(draft: GridsStateContainer, currentState: GridsStateContainer, action: { id: string }): void {
    if (draft.previousContainer[action.id] === undefined) {
      draft.previousContainer[action.id] = {} as GridStateModel;
    } else {
      draft.previousContainer[action.id] = { ...currentState.container[action.id] };
    }
  }
}
