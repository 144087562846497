export enum SubscriptionInfoEnum {
  NO_SUBSCRIPTION,
  VALID_SUBSCRIPTION,
  EXPIRED_SUBSCRIPTION,
  EXPIRING_SUBSCRIPTION,
  NOTIFY_SUBSCRIPTION,
  HAS_AVAILABLE_SUBSCRIPTION,
}

export namespace SubscriptionInfoEnum {
  export function getObjectValues() {
    return Object.values(SubscriptionInfoEnum);
  }
}
