import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '@roadrecord/user/common';
import { environment } from '@roadrecord/environment';

@Injectable()
export class ApplicationSettingsService {
  constructor(private http: HttpClient, private store: Store) {}

  getVersion(): Observable<{ version: string }> {
    return this.http.get<{ version: string }>(
      `${environment.apiUrl}version/`,
      // ha be van lepve, akkor kuldeni kell a token-t is
      this.store.selectSnapshot<boolean>(AuthState.isLoggedIn)
        ? {
            headers: { Authorization: `JWT ${this.store.selectSnapshot(AuthState.token)}` },
          }
        : undefined
    );
  }
}
