import { InjectionToken } from '@angular/core';
import { Options } from './introjs.service';

export const introjsDefaultOptions: Partial<Options> = {
  showBullets: false,
  exitOnEsc: false,
  exitOnOverlayClick: false,
  abortLabel: 'TOUR.COMMON.LABEL.ABORT',
  doneLabel: 'TOUR.COMMON.LABEL.DONE',
  nextLabel: 'TOUR.COMMON.LABEL.NEXT',
  dontShowAgainLabel: 'TOUR.COMMON.LABEL.DONT_SHOW_AGAIN',
  hidePrev: true,
  showProgress: true,
  showStepNumbers: true,
  keyboardNavigation: false,
  helperElementPadding: 0,
  disableInteraction: true,
  stepOfLabel: 'TOUR.COMMON.LABEL.STEP_OF',
};

export const INTROJS_DEFAULT_OPTIONS = new InjectionToken('INTROJS_DEFAULT_OPTIONS', {
  factory: () => introjsDefaultOptions,
});

export const DONT_SHOW_AGAIN_CHECKBOX_ELEMENT_ID = 'introjs-hide-forever-checkbox';
