<rr-progress-when-first-loading type="spinner" [loading]="(loading$ | async) && firstLoading">
  <ng-container *ngIf="currentStep" [ngSwitch]="currentStep.step">
    <div class="welcome-container" *ngSwitchCase="-1" @simpleFadeIn
      ><rr-start-welcome (nextStep)="onNextStep($event)"></rr-start-welcome
    ></div>
    <div class="welcome-container" *ngSwitchCase="-2" @simpleFadeIn>
      <rr-finish-mobile-layout (nextStep)="onNextStep($event)"></rr-finish-mobile-layout>
    </div>
    <div class="welcome-container" *ngSwitchCase="-3" @simpleFadeIn
      ><rr-finish-welcome (startApp)="onStart($event)"></rr-finish-welcome
    ></div>
    <ng-container *ngSwitchDefault>
      <rr-stepping
        *ngIf="currentStep.step > -1"
        [config]="stepperConfig"
        [currentStep]="currentStep"
        (nextStep)="onNextStep($event)"
      ></rr-stepping>
    </ng-container>
  </ng-container>
</rr-progress-when-first-loading>
