import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractEntityService, HttpListResponseModel } from '@roadrecord/utils';
import { Observable } from 'rxjs';
import { AbstractPeriodContextModel } from './state/model/abstract-period-context.model';
import { AbstractPeriodContextStateModel } from './state/model/abstract-period-context-state.model';
import { environment } from '@roadrecord/environment';

@Injectable()
export abstract class PeriodContextService<
  MODEL extends AbstractPeriodContextModel<any>,
  STATEMODEL extends AbstractPeriodContextStateModel<MODEL>
> extends AbstractEntityService<HttpListResponseModel<MODEL>, MODEL> {
  getToString(model: MODEL): string {
    return undefined;
  }

  constructor(http: HttpClient) {
    super('period-contexts', 'plate_number', http);
  }

  get(id: number, params?: string): Observable<MODEL> {
    return this.http.get<MODEL>(`${environment.apiUrl}${this.entityUrl}/`);
  }

  update(id: any, object: any): Observable<MODEL> {
    return this.http.put<MODEL>(`${environment.apiUrl}${this.entityUrl}/`, object);
  }

  getModelIdValue(model: MODEL): any {
    return undefined;
  }

  refreshFromUserState(): Observable<STATEMODEL> {
    return this.http.get<STATEMODEL>(`${environment.apiUrl}state/rr.period-context`);
  }
}
