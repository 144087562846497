import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import {
  AppTypePipeModule,
  FocusInputModule,
  IsNilPipeModule,
  IsPresentPipeModule,
  PreventClickModule,
  SanitizePipeModule,
} from '@roadrecord/common/common';
import { DaySelectFormControlModule } from '@roadrecord/common-components';
import { FragmentPresenterModule } from '@roadrecord/fragment-dialog';
import { LiveAutoCompleteModule } from '@roadrecord/live-auto-complete';
import { NgOverrideNumberPipeModule } from '@roadrecord/ng-override';
import { PARTNER_EDIT_FRAGMENT_PRESENTERS, PARTNER_NEW_FRAGMENT_PRESENTERS } from '@roadrecord/partner/common';
import { ProgressWhenFirstLoadingModule } from '@roadrecord/progress-when-first-loading';
import { TRIP_REASON_EDIT_FRAGMENT_PRESENTERS, TRIP_REASON_NEW_FRAGMENT_PRESENTERS } from '@roadrecord/trip-reason/common';
import { FormModule } from '@roadrecord/utils';
import { ValidatingModule } from '@roadrecord/validating';
import { DataFormComponent } from './data-form/data-form.component';
import { DetailsComponent } from './details/details.component';
import { LiveAutoCompleteModule as PartnerLiveAutoCompleteModule } from '@roadrecord/partner/live-auto-complete';
import { NgxMatDatetimePickerModule } from '@roadrecord/external-packages/angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMomentDateModule } from '@roadrecord/external-packages/angular-material-components/moment-adapter';
import { RealFuelBottomSheetComponent } from './real-fuel-bottom-sheet/real-fuel-bottom-sheet.component';
import { DynamicFormModule } from '@roadrecord/dynamic-form';
import { IMaskModule } from 'angular-imask';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    FlexLayoutModule,

    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatSelectModule,

    ValidatingModule,
    FormModule,
    DaySelectFormControlModule,
    FocusInputModule,
    ProgressWhenFirstLoadingModule,

    TranslocoModule,
    MatProgressSpinnerModule,
    FragmentPresenterModule.forFeature([
      ...PARTNER_NEW_FRAGMENT_PRESENTERS,
      ...PARTNER_EDIT_FRAGMENT_PRESENTERS,
      ...TRIP_REASON_NEW_FRAGMENT_PRESENTERS,
      ...TRIP_REASON_EDIT_FRAGMENT_PRESENTERS,
    ]),
    LiveAutoCompleteModule,
    NgOverrideNumberPipeModule,
    MatSlideToggleModule,
    IsPresentPipeModule,
    PartnerLiveAutoCompleteModule,
    NgxMatDatetimePickerModule,
    MatDatepickerModule,
    NgxMomentDateModule,
    AppTypePipeModule,
    SanitizePipeModule,
    DynamicFormModule,
    IsNilPipeModule,
    PreventClickModule,
    IMaskModule,
  ],
  declarations: [DataFormComponent, DetailsComponent, RealFuelBottomSheetComponent],
  entryComponents: [RealFuelBottomSheetComponent],
  exports: [DataFormComponent, DetailsComponent],
})
export class DetailsModule {}
