import { SortDirection } from '@angular/material/sort';

export class GridInitAction {
  static readonly type = '[Grid] Init';

  constructor(
    readonly id: string,
    readonly defaultSort: string,
    readonly defaultSortDirection: SortDirection,
    readonly pageSize: number,
    // readonly pageIndex = 0,
    readonly filter,
    readonly selected?: any[],
    readonly multiselect = false
  ) {}
}
