import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { isMobile, isTablet } from './is-mobile-or-tablet.function';
import { userBrowserIsOutdated } from '@roadrecord/main-helper';
import { AppTypeEnum, environment } from '@roadrecord/environment';

@Injectable({
  providedIn: 'root',
})
export class MobileOutdateBrowserDetectGuard implements CanActivate {
  readonly isUsAppType = environment.appType === AppTypeEnum.US;

  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check();
  }

  private check(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const shouldRedirectToMobile = (isMobile || isTablet) && (this.isUsAppType || userBrowserIsOutdated);
    return shouldRedirectToMobile ? this.router.parseUrl('/mobile') : true;
  }
}
