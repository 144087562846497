<form [formGroup]="form" [matTooltip]="(tooltip | isNil) ? undefined : (tooltip | transloco)">
  <table class="table">
    <tbody class="tbody">
      <tr *ngIf="showSpinners">
        <td>
          <button
            type="button"
            mat-icon-button
            aria-label="expand_less icon"
            (click)="change('hour', true)"
            (mousedown)="holdChange('hour', true)"
            (mouseup)="stopHoldChange()"
            (mouseout)="stopHoldChange()"
            [disabled]="disabled"
          >
            <mat-icon class="little-big" svgIcon="chevron-up"></mat-icon>
          </button>
        </td>
        <td class="spacer"></td>
        <td>
          <button
            type="button"
            mat-icon-button
            aria-label="expand_less icon"
            (click)="change('minute', true)"
            (mousedown)="holdChange('minute', true)"
            (mouseup)="stopHoldChange()"
            (mouseout)="stopHoldChange()"
            [disabled]="disabled || disableMinute"
          >
            <mat-icon class="little-big" svgIcon="chevron-up"></mat-icon>
          </button>
        </td>
        <td *ngIf="showSeconds || enableMeridian"></td>
        <td *ngIf="showSeconds">
          <button
            type="button"
            mat-icon-button
            aria-label="expand_less icon"
            (click)="change('second', true)"
            (mousedown)="holdChange('second', true)"
            (mouseup)="stopHoldChange()"
            (mouseout)="stopHoldChange()"
            [disabled]="disabled"
          >
            <mat-icon class="little-big" svgIcon="chevron-up"></mat-icon>
          </button>
        </td>
        <td *ngIf="enableMeridian" class="spacer"></td>
        <td *ngIf="enableMeridian"></td>
      </tr>

      <tr>
        <td>
          <mat-form-field>
            <input
              type="text"
              matInput
              (input)="formatInput($any($event).target)"
              maxlength="2"
              [formControl]="hourControl"
              (keydown.ArrowUp)="change('hour', true); $event.preventDefault()"
              (keydown.ArrowDown)="change('hour', false); $event.preventDefault()"
              (blur)="change('hour')"
              class="hour"
              [errorStateMatcher]="errorStateMatcher"
            />
          </mat-form-field>
        </td>
        <td class="spacer"><span>&#58;</span></td>
        <td>
          <mat-form-field>
            <input
              type="text"
              matInput
              (input)="formatInput($any($event).target)"
              maxlength="2"
              [formControl]="minuteControl"
              (keydown.ArrowUp)="change('minute', true); $event.preventDefault()"
              (keydown.ArrowDown)="change('minute', false); $event.preventDefault()"
              (blur)="change('minute')"
              class="minute"
              [errorStateMatcher]="errorStateMatcher"
            />
          </mat-form-field>
        </td>
        <td *ngIf="showSeconds" class="spacer">&#58;</td>
        <td *ngIf="showSeconds">
          <mat-form-field>
            <input
              type="text"
              matInput
              (input)="formatInput($any($event).target)"
              maxlength="2"
              [formControl]="secondControl"
              (keydown.ArrowUp)="change('second', true); $event.preventDefault()"
              (keydown.ArrowDown)="change('second', false); $event.preventDefault()"
              (blur)="change('second')"
              class="second"
              [errorStateMatcher]="errorStateMatcher"
            />
          </mat-form-field>
        </td>

        <td *ngIf="enableMeridian" class="spacer"></td>
        <td *ngIf="enableMeridian" class="meridian">
          <button mat-button (click)="toggleMeridian()" mat-stroked-button [color]="color" [disabled]="disabled">
            {{ meridian }}
          </button>
        </td>
      </tr>

      <tr *ngIf="showSpinners">
        <td>
          <button
            type="button"
            mat-icon-button
            aria-label="expand_more icon"
            (click)="change('hour', false)"
            (mousedown)="holdChange('hour', false)"
            (mouseup)="stopHoldChange()"
            (mouseout)="stopHoldChange()"
            [disabled]="disabled"
          >
            <mat-icon class="little-big" svgIcon="chevron-down"></mat-icon>
          </button>
        </td>
        <td></td>
        <td>
          <button
            type="button"
            mat-icon-button
            aria-label="expand_more icon"
            (click)="change('minute', false)"
            (mousedown)="holdChange('minute', false)"
            (mouseup)="stopHoldChange()"
            (mouseout)="stopHoldChange()"
            [disabled]="disabled || disableMinute"
          >
            <mat-icon class="little-big" svgIcon="chevron-down"></mat-icon>
          </button>
        </td>
        <td *ngIf="showSeconds"></td>
        <td *ngIf="showSeconds">
          <button
            type="button"
            mat-icon-button
            aria-label="expand_more icon"
            (click)="change('second', false)"
            (mousedown)="holdChange('second', false)"
            (mouseup)="stopHoldChange()"
            (mouseout)="stopHoldChange()"
            [disabled]="disabled"
          >
            <mat-icon class="little-big" svgIcon="chevron-down"></mat-icon>
          </button>
        </td>
        <td *ngIf="enableMeridian" class="spacer"></td>
        <td *ngIf="enableMeridian"></td>
      </tr>
    </tbody>
  </table>
</form>
