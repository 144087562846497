import { InjectionToken } from '@angular/core';
import { LiveAutoCompleteOptionsConfigModel } from '@roadrecord/live-auto-complete';
import { PartnerListModel } from '@roadrecord/partner/model';

export const PARTNER_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG = new InjectionToken<LiveAutoCompleteOptionsConfigModel<PartnerListModel>>(
  'PARTNER_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG'
);
export const CHARGING_STATION_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG = new InjectionToken<LiveAutoCompleteOptionsConfigModel<PartnerListModel>>(
  'CHARGING_STATION_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG'
);
export const HEAD_OFFICE_OR_SITE_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG = new InjectionToken<
  LiveAutoCompleteOptionsConfigModel<PartnerListModel>
>('HEAD_OFFICE_OR_SITE_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG');
