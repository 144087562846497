<rr-secure-delete-dialog
  (remove)="onSecureRemoveUser()"
  *ngIf="showSecureRemoveDialog"
  [fullName]="secureDialogFullName"
  [translateDialogTitle]="secureRemoveDialogTranslateDialogTitle"
  [translateLastAndFirstNameLabel]="secureRemoveDialogTranslateLastAndFirstNameLabel"
  [translateFullNameLabel]="secureRemoveDialogTranslateFullNameLabel"
  [translateInputLabel]="secureRemoveDialogTranslateInputLabel"
  [translateInputPlaceholder]="secureRemoveDialogTranslateInputPlaceholder"
  [translateInputErrorMismatch]="secureRemoveDialogTranslateInputErrorMismatch"
  [translateButtonTooltip]="secureRemoveDialogTranslateButtonTooltip"
></rr-secure-delete-dialog>
<context-menu [menuClass]="rightClickContextMenuClass" [disabled]="rightClickContextMenuDisabled">
  <ng-container *ngFor="let menuItem of rightClickContextMenuOption?.grid">
    <ng-template
      contextMenuItem
      let-item
      [divider]="menuItem?.divider !== undefined ? menuItem.divider : false"
      [visible]="menuItem?.visible !== undefined ? menuItem.visible : true"
      [enabled]="menuItem?.enabled !== undefined ? menuItem.enabled : true"
      (execute)="menuItem?.click($event)"
    >
      <div
        matRipple
        [matRippleDisabled]="!(menuItem?.enabled !== undefined ? menuItem.enabled(item) : true)"
        [matTooltip]="
          !(menuItem?.enabled !== undefined ? menuItem.enabled(item) : true)
            ? (menuItem?.disabledText | isFunction)
              ? menuItem?.disabledText(item)
              : (menuItem?.disabledText | transloco)
            : undefined
        "
      >
        <mat-icon *ngIf="menuItem?.icon" [svgIcon]="menuItem?.icon"></mat-icon>
        <span>{{ menuItem.label | transloco }}</span>
      </div>
    </ng-template>
  </ng-container>
</context-menu>
<context-menu [menuClass]="rightClickContextMenuClass" [disabled]="rightClickContextMenuDisabled">
  <ng-container *ngFor="let menuItem of rightClickContextMenuOption?.rows">
    <ng-template
      contextMenuItem
      let-item
      [divider]="menuItem?.divider !== undefined ? menuItem.divider : false"
      [visible]="menuItem?.visible !== undefined ? menuItem.visible : true"
      [enabled]="menuItem?.enabled !== undefined ? menuItem.enabled : true"
      (execute)="menuItem?.click($event)"
    >
      <div
        matRipple
        [matRippleDisabled]="!(menuItem?.enabled !== undefined ? menuItem.enabled(item) : true)"
        [matTooltip]="
          !(menuItem?.enabled !== undefined ? menuItem.enabled(item) : true)
            ? (menuItem?.disabledText | isFunction)
              ? menuItem?.disabledText(item)
              : (menuItem?.disabledText | transloco)
            : undefined
        "
      >
        <mat-icon *ngIf="menuItem?.icon" [svgIcon]="menuItem?.icon"></mat-icon>
        <span>{{ menuItem.label | transloco }}</span>
      </div>
    </ng-template>
  </ng-container>
</context-menu>
<ng-template #filterAreaTpl>
  <mat-form-field floatPlaceholder="never" class="filter-field wid100">
    <mat-label>{{ gridFilterLabel | transloco }}</mat-label>
    <mat-icon class="search-icon" matPrefix svgIcon="magnify"></mat-icon>
    <input
      matInput
      [formControl]="filterFormControl"
      [errorStateMatcher]="searchFieldErrorMatcher"
      [placeholder]="gridFilterPlaceholder | transloco"
      autocomplete="off"
      class="disable-right-click"
    />
    <button
      type="button"
      matSuffix
      mat-icon-button
      class="small light-color"
      tabindex="-1"
      *ngIf="filterFormControl.value?.length > 0"
      [matTooltip]="'GRID.CLEAR_SEARCH' | transloco"
      matTooltipPosition="left"
      (click)="resetFilterForm()"
    >
      <mat-icon svgIcon="close" class="small"></mat-icon>
    </button>
    <mat-hint *ngIf="gridFilterHint">{{ gridFilterHint | transloco }}</mat-hint>
    <mat-error> <rr-validation-messages [errors]="filterFormControl?.errors"></rr-validation-messages> </mat-error>
  </mat-form-field>
</ng-template>
<mat-card [rrContextMenu]="rightClickMenuComponent?.first">
  <mat-card-title *ngIf="titleText?.length > 0"
    ><mat-icon *ngIf="titleIcon" class="light-color" [svgIcon]="titleIcon" fxFlex="34px"></mat-icon
    ><span
      ><ng-container *ngIf="titleText['map'] === undefined; else translateContainer">{{ titleText }}</ng-container
      ><ng-template #translateContainer
        ><ng-container *ngIf="disableSelection || selection.isEmpty(); else notEmpty">{{
          disableTitleTranslate ? titleText[0] : (titleText[0] | transloco)
        }}</ng-container
        ><ng-template #notEmpty>{{
          disableTitleTranslate ? titleText[1] : (titleText[1] | transloco: { selected: selection.selected.length })
        }}</ng-template></ng-template
      ></span
    ><span class="flex-spacer" *ngIf="headerGroupActions?.length > 0 || headerOtherActions?.length > 0"></span
    ><ng-container *ngIf="headerGroupActions?.length > 0 && fullDisableSelection === false">
      <rr-split-button
        stroked="true"
        [actions]="$any(headerGroupActions)"
        [label]="'GRID.HEADER_GROUP_ACTIONS' | transloco"
        [ngClass]="{ 'mr-3': headerOtherActions.length > 0 }"
        [disabled]="isLoadingResults || selection.selected.length <= headerGroupActionDisabledLimit || disableSelection"
        color="accent"
        class="header-group-actions"
      ></rr-split-button>
    </ng-container>
    <ng-container *ngIf="headerSingleButtonAction">
      <button
        type="button"
        color="primary"
        mat-raised-button
        class="single-header-button"
        (click)="$any(headerSingleButtonAction).action()"
      >
        {{ $any(headerSingleButtonAction).label.translate | transloco }}
      </button>
    </ng-container>
    <ng-container *ngIf="headerOtherActions?.length > 0">
      <rr-split-button
        [disabled]="
          isLoadingResults || (disableSelection && fullDisableSelection === false) || (null | isDisabled: $any(headerOtherActions[0]))
        "
        [actions]="$any(headerOtherActions)"
        [data]="selection?.selected"
        startWithFirstElement="true"
        class="header-other-actions"
        color="accent"
      ></rr-split-button></ng-container
  ></mat-card-title>
  <mat-card-content>
    <div class="custom-template-area" *ngIf="customAreaTemplateRef" #customTemplateAreaElementRef>
      <ng-template *ngTemplateOutlet="customAreaTemplateRef"> </ng-template>
    </div>
    <div class="filter-area" fxLayout="row" fxLayoutAlign=" flex-start" *ngIf="(hasFilter && showFilter) || customRightAreaTpl">
      <div class="filter-wrapper" *ngIf="hasFilter && showFilter"><ng-container *ngTemplateOutlet="filterAreaTpl"></ng-container></div>
      <div class="custom-right-area wid100" *ngIf="customRightAreaTpl">
        <ng-container *ngTemplateOutlet="customRightAreaTpl"></ng-container>
      </div>
    </div>
    <div
      class="table-wrapper"
      #tableWrapperElement
      infiniteScroll
      [infiniteScrollDisabled]="isLoadingResults || !hasNexPage"
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      [fromRoot]="true"
      (scrolled)="getNextPage()"
    >
      <div class="loading-shade" *ngIf="isLoadingResults"><mat-spinner color="primary"></mat-spinner></div>
      <table
        #table
        mat-table
        [dataSource]="dataSource"
        [trackBy]="trackByModel"
        matSort
        #sortForDataSource="matSort"
        [matSortActive]="defaultSort"
        [matSortDirection]="defaultSortDirection"
        matSortDisableClear
        [multiTemplateDataRows]="hasExpandGrid"
        [ngClass]="{ 'has-checkbox-column': hasCheckboxColumn, 'has-icon-column': hasIconColumn }"
      >
        <!-- Checkbox Column -->
        <ng-container *ngIf="hasCheckboxColumn" matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              *ngIf="multiSelection === true"
              (click)="$event.stopPropagation()"
              (change)="$event ? masterToggle() : null"
              disableRipple="true"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="
                selection.hasValue() && !isAllSelected() && multiSelection && !isAllSelectedNotIsNotEditableAndNotDeletables()
              "
              [disabled]="isLoadingResults || disableCheckboxColumn"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(element) : null"
              [checked]="selection.isSelected(element)"
              [disabled]="disableCheckboxColumn || isLoadingResults || (element | isNotDeletableAndEditable)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- ICON COLUMN -->
        <ng-container matColumnDef="icon" *ngIf="hasIconColumn">
          <th mat-header-cell *matHeaderCellDef>{{ iconDisplayColumnHeaderName }}</th>
          <td mat-cell *matCellDef="let element; let i = index" [ngClass]="iconColumnDatas[i].cssClassName">
            <ng-container *ngIf="iconColumnDatas !== undefined && iconColumnDatas.length > 0">
              <mat-icon
                [svgIcon]="iconColumnDatas[i].value"
                [matTooltip]="iconColumnDatas[i]?.tooltipTextTranslateKey | transloco"
                class="light-color"
              ></mat-icon>
            </ng-container>
          </td>
        </ng-container>

        <!-- Radio Column -->
        <ng-container *ngIf="hasRadioButton">
          <ng-container *ngFor="let column of radioColumns" [matColumnDef]="column.name">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="radio-column-head"
              [matTooltip]="
                (column.tooltipRadioToggleAllFn
                  ? column.tooltipRadioToggleAllFn(column)
                  : column.tooltipRadioToggleAll
                  ? column.tooltipRadioToggleAll
                  : undefined
                ) | transloco
              "
              [matTooltipPosition]="column.tooltipRadioToggleAllPosition ? column.tooltipRadioToggleAllPosition : 'before'"
            >
              <mat-checkbox
                *ngIf="column.hasRadioToggleAll"
                (click)="toggleRadioColumn(column)"
                disableRipple="true"
                [checked]="radioColumnsState[column.radioValue].all"
                [indeterminate]="radioColumnsState[column.radioValue].intermidate"
                [disabled]="
                  dataSource.data?.length === 0 ||
                  isLoadingResults ||
                  radioColumnsState[column.radioValue].all ||
                  radioColumnsState[column.radioValue].hasDisabledColumn
                "
              >
              </mat-checkbox>
              <div>
                <span>
                  <ng-container *ngIf="column.cellHeaderTemplateCallback !== undefined; else autoGenerate">
                    {{ column.cellHeaderTemplateCallback(column) }}
                  </ng-container>
                  <ng-template #autoGenerate>
                    <ng-container *ngIf="column.displayName !== undefined; else translateDisplayName">
                      {{ column.displayName }}
                    </ng-container>
                    <ng-template #translateDisplayName> {{ column.displayNameTranslateKey | transloco }} </ng-template>
                  </ng-template>
                </span>
              </div>
            </th>
            <td
              class="radio-column"
              mat-cell
              *matCellDef="let element"
              [matTooltip]="
                (column.cellTooltipFn ? column.cellTooltipFn(element) : column.cellTooltip ? column.cellTooltip : undefined) | transloco
              "
              [matTooltipPosition]="column.cellTooltipPosition ? column.cellTooltipPosition : 'before'"
              [ngClass]="column.cellCssClassFn !== undefined ? column.cellCssClassFn(element) : column.cellCssClass"
            >
              <mat-radio-group
                [ngModel]="element[column.radioName]"
                [disabled]="
                  column.disableRadio === undefined
                    ? false
                    : (column.disableRadio | isBoolean)
                    ? column.disableRadio
                    : column.disableRadio(element)
                "
              >
                <mat-radio-button
                  [value]="column.radioValue"
                  (click)="clickRadioButton(column, element); $event.stopPropagation()"
                ></mat-radio-button>
              </mat-radio-group>
            </td>
          </ng-container>
        </ng-container>

        <!-- Expand row column -->
        <ng-container *ngIf="hasExpandGrid === true">
          <ng-container matColumnDef="expandedDetail">
            <td class="custom-expand-detail-row" mat-cell *matCellDef="let element" [attr.colspan]="columnDefinitions.length">
              <div
                class="expand-panel"
                *ngIf="expandedRowElements.indexOf(element) > -1"
                [@detailExpand]="expandedRowElements.indexOf(element) > -1 ? 'expanded' : 'collapsed'"
              >
                <ng-template
                  #expandRowTpl
                  [ngTemplateOutlet]="expandRowTemplateRef"
                  [ngTemplateOutletContext]="{ $implicit: element }"
                ></ng-template>
              </div>
            </td>
          </ng-container>
        </ng-container>

        <!-- DYNAMIC COLUMNS -->
        <ng-container *ngFor="let column of columnDefinitions" [matColumnDef]="column.name">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [ngClass]="column.cellHeaderCssClass"
            [start]="column?.startSortDirection ? column.startSortDirection : 'asc'"
            [disabled]="isLoadingResults || enabledSortColumns?.indexOf(column.name) === -1"
            [matTooltip]="column.headerTooltip"
          >
            <ng-container *ngIf="column.cellHeaderTemplateCallback !== undefined; else autoGenerate">
              {{ column.cellHeaderTemplateCallback(column) }}
            </ng-container>
            <ng-template #autoGenerate>
              <ng-container *ngIf="column.displayName !== undefined; else translateDisplayName"> {{ column.displayName }} </ng-container>
              <ng-template #translateDisplayName> {{ column.displayNameTranslateKey | transloco }} </ng-template>
            </ng-template>
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            [matTooltip]="
              translateIfNeeded(
                column.cellTooltipFnUseTranslation,
                column.cellTooltipFn ? column.cellTooltipFn(element) : column.cellTooltip ? column.cellTooltip : undefined
              )
            "
            [matTooltipPosition]="column.cellTooltipPosition ? column.cellTooltipPosition : 'before'"
            [ngClass]="column.cellCssClassFn !== undefined ? column.cellCssClassFn(element) : column.cellCssClass"
          >
            <rr-edit-cell
              *ngIf="editColumns !== undefined && editColumns.indexOf(column.name) > -1; else normalTpl"
              [rowIndex]="i"
              [parentFormGroup]="editFormGroup"
              [editModel]="element"
              [editMode]="editRowIndex === i || openAllEditInput"
              [config]="editColumnsConfig[column.name]"
              [editCellsTemplateRef]="editCellsTemplateRef"
              [uploadFileCellsTemplateRef]="uploadFileCellsTemplateRef"
              [text]="column.cellContentTemplateCallback !== undefined ? column.cellContentTemplateCallback(element) : element[column.name]"
              [remoteControlsErrors]="remoteControlsErrors"
              (initControl)="onInitEditControl(column.name, $event, element)"
              (initMultiLineControl)="onMultiLineEditControls(i, column.name, $event)"
            ></rr-edit-cell>
            <ng-template #normalTpl>
              <ng-container *ngIf="column.cellContentTemplateCallback !== undefined">
                {{ column.cellContentTemplateCallback(element) }}
              </ng-container>
              <ng-container *ngIf="column.cellMatIconCallback !== undefined">
                <ng-container *ngIf="column.cellMatIconBeforeTextCallback !== undefined">
                  {{ column.cellMatIconBeforeTextCallback(element) }}
                </ng-container>
                <ng-container *ngIf="column.cellMatIconCallback(element) as cellMatIcon">
                  <ng-container *ngIf="cellMatIcon.tooltipTextTranslateKey !== undefined; else withoutTooltipCellMatIcon">
                    <mat-icon
                      [svgIcon]="cellMatIcon.value"
                      [ngClass]="cellMatIcon.cssClassName"
                      class="content-icon"
                      [matTooltip]="cellMatIcon!.tooltipTextTranslateKey | transloco"
                    ></mat-icon></ng-container
                  ><ng-template #withoutTooltipCellMatIcon
                    ><mat-icon [svgIcon]="cellMatIcon.value" [ngClass]="cellMatIcon.cssClassName" class="content-icon"></mat-icon
                  ></ng-template>
                </ng-container>
                <ng-container *ngIf="column.cellMatIconAfterTextCallback !== undefined">
                  {{ column.cellMatIconAfterTextCallback(element) | transloco }}
                </ng-container>
              </ng-container>
              <ng-container *ngIf="column.contentTemplateRefIndex !== undefined">
                <ng-container
                  [ngTemplateOutlet]="cellsTemplateRef[column.contentTemplateRefIndex]"
                  [ngTemplateOutletContext]="{ $implicit: element, rowIndex: i }"
                ></ng-container>
              </ng-container>
              <ng-container *ngIf="column.cellContentTemplateCallback === undefined && column.cellMatIconCallback === undefined">
                {{ element[column.name] }}
              </ng-container>
            </ng-template>
          </td>
          <ng-container *ngIf="hasSummaryRow === true && column.columnSummaryContentTemplateCallback | isFunction">
            <td mat-footer-cell *matFooterCellDef="let element" [ngClass]="column.cellSummaryCssClass">
              <ng-container *ngIf="column.contentSummaryTemplateRefIndex === undefined; else summaryCellTpl">
                {{ column.columnSummaryContentTemplateCallback(element) }}
              </ng-container>
              <ng-template #summaryCellTpl>
                <ng-container [ngTemplateOutlet]="summaryCellsTemplateRef[column.contentSummaryTemplateRefIndex]"></ng-container>
              </ng-template>
            </td>
          </ng-container>
        </ng-container>

        <!-- END OF ROW COMMON ACTIONS COLUMN-->
        <ng-container [matColumnDef]="actionsDisplayColumnName" *ngIf="hasCommonActionsColumn === true && hasActionsColumn === false">
          <th mat-header-cell *matHeaderCellDef>{{ 'GRID.ACTIONS_COLUMN_NAME' | transloco }}</th>
          <td mat-cell *matCellDef="let row; let i = index" style="white-space: nowrap">
            <button
              *ngIf="editRowIndex !== i"
              mat-icon-button
              rrPreventClick
              [disabled]="
                (editRowIndex !== undefined && editRowIndex !== i) ||
                (hasCommonEditActionDisableCb !== null ? hasCommonEditActionDisableCb(row) : false)
              "
              (click)="onClickCommonEditRow(row)"
              [matTooltip]="'COMMON.ACTION.EDIT' | transloco"
            >
              <mat-icon svgIcon="pencil-outline" class="end-of-row-action-icon"></mat-icon>
            </button>
            <button
              *ngIf="database.getModelIdValue(row) !== undefined"
              mat-icon-button
              rrPreventClick
              (click)="onClickRowRemove(i, row)"
              [disabled]="
                (editRowIndex !== undefined && editRowIndex !== i) ||
                (hasActionRemoveDisableCb !== null ? hasActionRemoveDisableCb(row) : false)
              "
              [matTooltip]="'COMMON.ACTION.DELETE' | transloco"
            >
              <mat-icon svgIcon="delete-outline" class="end-of-row-action-icon"></mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- INLINE EDIT ACTIONS COLUMN -->
        <ng-container
          [matColumnDef]="actionsDisplayColumnName"
          *ngIf="hasActionsColumn === true && openAllEditInput === false && hasCommonActionsColumn === false"
        >
          <th mat-header-cell *matHeaderCellDef>{{ 'GRID.ACTIONS_COLUMN_NAME' | transloco }}</th>
          <td mat-cell *matCellDef="let row; let i = index">
            <button
              *ngIf="editRowIndex !== undefined && editRowIndex === i"
              mat-icon-button
              rrPreventClick
              type="button"
              (click)="onClickRowEditSave(i, row)"
              [matTooltip]="((row | hasId: database) ? 'COMMON.ACTION.EDIT' : 'COMMON.ACTION.SAVE') | transloco"
            >
              <mat-icon svgIcon="check" class="end-of-row-action-icon"></mat-icon>
            </button>
            <button
              *ngIf="editRowIndex === i"
              mat-icon-button
              rrPreventClick
              (click)="onClickRowEditCancel(i, row)"
              [matTooltip]="((row | hasId: database) ? 'COMMON.ACTION.TOOLTIP.CANCEL' : 'COMMON.ACTION.CANCEL') | transloco"
            >
              <mat-icon svgIcon="close"></mat-icon>
            </button>
            <button
              *ngIf="editRowIndex !== i"
              mat-icon-button
              rrPreventClick
              (click)="onClickRowEdit(i, row)"
              [disabled]="editRowIndex !== undefined && editRowIndex !== i"
              [matTooltip]="'COMMON.ACTION.EDIT' | transloco"
            >
              <mat-icon svgIcon="pencil-outline" class="end-of-row-action-icon"></mat-icon>
            </button>
            <button
              *ngIf="hasActionRemove && database.getModelIdValue(row) !== undefined"
              mat-icon-button
              rrPreventClick
              (click)="onClickRowRemove(i, row)"
              [disabled]="
                (editRowIndex !== undefined && editRowIndex !== i) ||
                (hasActionRemoveDisableCb !== null ? hasActionRemoveDisableCb(row) : false)
              "
              [matTooltip]="'COMMON.ACTION.DELETE' | transloco"
            >
              <mat-icon svgIcon="delete-outline" class="end-of-row-action-icon"></mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- TABLE OTHER DEFINITIONS -->
        <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true" [rrContextMenu]="rightClickMenuComponent?.first"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayColumns; let i = index"
          [class.selected]="!disableSelection && selection.isSelected(row)"
          [class.disabled]="row | isNotDeletableAndEditable"
          (click)="onClickRow($event, row, i)"
          (dblclick)="handleRowClick({ element: row, index: i }, false)"
          [rrContextMenu]="rightClickMenuComponent?.last"
          [contextMenuSubject]="row"
          [matTooltip]="(row | isNotDeletableAndEditable) ? (rowSelectIsNotDeletableAndEditableTranslateKey | transloco) : undefined"
          matTooltipPosition="before"
          [ngClass]="{
            editing: i === editRowIndex,
            pointer: rowCursorIsPointer === true,
            'expand-element-row': expandedRowElements.indexOf(row) > -1
          }"
        ></tr>

        <ng-container *ngIf="hasExpandGrid === true">
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="custom-detail-row"></tr>
        </ng-container>

        <!-- Footer section -->
        <ng-container *ngIf="hasSummaryRow === true">
          <tr mat-footer-row *matFooterRowDef="footerDisplayColumns" class="footer-section"></tr>
        </ng-container>
      </table>

      <div class="d-flex flex-column not-found-wrapper" *ngIf="!isLoadingResults && resultsLength === 0">
        <ng-template #imageTpl
          ><div class="d-flex flex-wrap justify-content-center align-content-center h-100 overflow-auto" @zoomIn2XEnter>
            <img
              class="not-found-image"
              *ngIf="notEntitiesBackgroundImage"
              [src]="'/assets/images/grid-background/' + notEntitiesBackgroundImage + '.svg' | sanitizeResourceUrl" /></div
        ></ng-template>

        <ng-container *ngIf="!isLoadingResults && firstResponseFlag === false && resultsLength === 0">
          <h3 class="not-found">
            <ng-container *ngIf="filterFormControl?.value?.length > 0; else notEntitiesData">
              {{ 'GRID.NOT_FOUND_DATA' | transloco }}
            </ng-container>
            <ng-template #notEntitiesData>
              <ng-container *ngIf="hasNotEntitiesDataLabel">
                <span [innerHTML]="notEntitiesDataLabel | transloco | sanitizeHtml"></span>
              </ng-container>
              <ng-container *ngIf="hasNotEntitiesDataCustomArea">
                <ng-container *ngTemplateOutlet="hasNotEntitiesDataCustomArea"></ng-container>
              </ng-container>
            </ng-template>
          </h3>

          <ng-container *ngIf="!hasNotEntitiesDataLabelLinkHide">
            <button
              *ngIf="hasNotEntitiesDataLabelLinkCallback; else autoRouteOnNewPath"
              rrPreventClick
              type="button"
              mat-raised-button
              color="accent"
              data-cy="not-found"
              (click)="onHasNotEntitiesDataLabelLinkCallback()"
              >{{ notEntitiesDataLinkLabel | transloco }}</button
            >
            <ng-template #autoRouteOnNewPath>
              <button data-cy="not-found" routerLink="./new" type="button" mat-raised-button color="accent">{{
                notEntitiesDataLinkLabel | transloco
              }}</button>
            </ng-template>
          </ng-container>
          <ng-container *ngTemplateOutlet="imageTpl"></ng-container>
        </ng-container>
        <ng-container *ngIf="!isLoadingResults && firstResponseFlag === true && resultsLength === 0">
          <ng-container *ngTemplateOutlet="imageTpl"></ng-container>
        </ng-container>
      </div>
    </div>
  </mat-card-content>
  <mat-card-footer [ngClass]="{ 'has-actions-row': actionsRowsTemplateRef !== null }">
    <div class="paginator-wrapper">
      <div
        >{{
          firstResponseFlag === true
            ? dataSource.data.length === 0
              ? ''
              : ('GRID.FOOTER.LOADING' | transloco)
            : isLoadingResults
            ? ('GRID.FOOTER.STATUS_WITH_LOADING' | transloco: { dataLength: dataSource.data.length, resultsLength: resultsLength })
            : ('GRID.FOOTER.STATUS' | transloco: { dataLength: dataSource.data.length, resultsLength: resultsLength })
        }}<mat-progress-spinner strokeWidth="10" mode="indeterminate" color="accent" *ngIf="isLoadingResults"></mat-progress-spinner
      ></div>
    </div>
    <div *ngIf="actionsRowsTemplateRef !== null" class="actions-row"
      ><ng-template *ngTemplateOutlet="actionsRowsTemplateRef"></ng-template
    ></div>
  </mat-card-footer>
</mat-card>
