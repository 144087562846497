import { RRFormWithDynamicFormBottomSheetConfig } from '@roadrecord/utils';
import { Injector } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FuelTypeEnum, VehicleModel } from '@roadrecord/vehicle/model/common';
import { dynamicFormConfigHelper } from '@roadrecord/dynamic-form';
import { globalImaskNumberConfigGenerator } from '@roadrecord/imask/common';

export function searchMissingFieldConfig(bottomSheetData: RRFormWithDynamicFormBottomSheetConfig<VehicleModel>, injector: Injector) {
  const field: FormlyFieldConfig[] = [
    {
      type: 'flex-layout',
      templateOptions: {
        cssClass: 'w-100',
        flex: {
          fxLayout: 'column',
          fxLayout__gt_sm: 'row',
          fxLayoutGap: '1em',
          fxFlex: '100%',
        },
      },
      fieldGroup: [
        {
          type: 'input',
          key: 'real_fuel',
          templateOptions: {
            required: true,
            transloco: true,
            label: 'VEHICLE.DETAILS.DATA_FORM.LABEL.ACTUAL_FUEL_CONSUMPTION',
            placeholder: 'VEHICLE.DETAILS.DATA_FORM.LABEL.ACTUAL_FUEL_CONSUMPTION',
            flex: {
              fxFlex: '100%',
            },
            numberMaskOptions: {
              mask: globalImaskNumberConfigGenerator({ max: 99 }),
              __disable_deep_copy__: true,
            },
            suffix: bottomSheetData.data.fuel_type === FuelTypeEnum.ELECTRIC ? 'COMMON.UNIT.KWH_PER_100KM' : 'COMMON.UNIT.LITER_PER_100KM',
          },
        },
      ],
    },
  ];
  return dynamicFormConfigHelper('x' as any, field).config(injector);
}

const translatePrefix = 'FUELING.DETAILS.REAL_FUEL_BOTTOM_SHEET.';
export const searchMissingFieldTranslate: { title: string; content: string } = {
  title: `${translatePrefix}TITLE`,
  content: `${translatePrefix}CONTENT`,
};
