import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgxMatCalendar, NgxMatCalendarHeader } from './calendar';
import { NgxMatDatetimeInput } from './datetime-input';
import { NgxMatDatetimePicker } from './datetime-picker.component';
import { NgxMatMonthView } from './month-view';
import { NgxMatMultiYearView } from './multi-year-view';
import { NgxMatTimepickerModule } from './timepicker.module';
import { NgxMatYearView } from './year-view';
import { A11yModule } from '@angular/cdk/a11y';
import { NgxMatDatetimeContent } from './datetime-content.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatDialogModule,
    PortalModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    NgxMatTimepickerModule,
    A11yModule,
    MatTooltipModule,
    TranslocoModule,
  ],
  exports: [
    NgxMatDatetimePicker,
    NgxMatDatetimeInput,
    NgxMatCalendar,
    NgxMatMonthView,
    NgxMatYearView,
    NgxMatMultiYearView,
    NgxMatCalendarHeader,
  ],
  declarations: [
    NgxMatDatetimePicker,
    NgxMatDatetimeContent,
    NgxMatDatetimeInput,
    NgxMatCalendar,
    NgxMatMonthView,
    NgxMatYearView,
    NgxMatMultiYearView,
    NgxMatCalendarHeader,
  ],
  entryComponents: [NgxMatDatetimeContent, NgxMatCalendarHeader],
  providers: [MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER],
})
export class NgxMatDatetimePickerModule {}
