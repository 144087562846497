import { Inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { produce } from '@ngxs-labs/immer-adapter';
import { Action, Actions, ofActionSuccessful, Selector, State, StateContext, Store } from '@ngxs/store';
import { GetUserStatesSuccessAction, UserStateKeyRefreshAction } from '@roadrecord/common/common';
import { AuthState, AuthStateModel, LogoutAction, LogoutWithoutCallEndpointSuccessAction } from '@roadrecord/user/common';
import { asapScheduler, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MenuService } from '../menu.service';
import { MenuCloseAction } from './action/menu-close.action';
import { MenuHideAction } from './action/menu-hide.action';
import { MenuOpenAction } from './action/menu-open.action';
import { MenuRefreshRemoteStateErrorAction } from './action/menu-refresh-remote-state-error.action';
import { MenuRefreshRemoteStateSuccessAction } from './action/menu-refresh-remote-state-success.action';
import { MenuRefreshRemoteStateAction } from './action/menu-refresh-remote-state.action';
import { MenuShowAction } from './action/menu-show.action';
import { MenuToggleAction } from './action/menu-toggle.action';
import { MenuStateModel } from './model/menu-state.model';
import { MenuSetInvalidPeriodContextAction } from './action/menu-set-invalid-period-context.action';
import { InvalidPeriodContextEnum } from '@roadrecord/period-context/common';
import { STATE_PREFIX_TOKEN } from '@roadrecord/company-context/common';

@State<MenuStateModel>({
  name: 'menu',
  defaults: {
    open: true,
    show: false,
    excluded: [],
    disabled_menu_page_group: [],
    disabled_menu_items: [],
    disabled_menu_message: undefined,
    invalid_period_context: InvalidPeriodContextEnum.DISABLED,
    version: 3,
  } as MenuStateModel,
})
@Injectable()
export class MenuState {
  @Selector()
  static invalidPeriodContext(state: MenuStateModel): InvalidPeriodContextEnum {
    return state.invalid_period_context === undefined ? InvalidPeriodContextEnum.DISABLED : state.invalid_period_context;
  }

  @Selector([AuthState /*, FirstStepsState*/])
  static show(state: MenuStateModel, authState: AuthStateModel /*, firstStepsState: FirstStepsStateModel*/): boolean {
    // if (firstStepsState.finished === false) {
    //   return false;
    // }
    if (state.show === false) {
      return AuthState.isLoggedIn(authState);
    }
    return state.show;
  }

  @Selector()
  static open(state: MenuStateModel): boolean {
    return state.open;
  }

  @Selector()
  static disabled_menu_page_group(state: MenuStateModel): string[] {
    return state.disabled_menu_page_group;
  }

  @Selector()
  static disabled_menu_message(state: MenuStateModel): string {
    return state.disabled_menu_message;
  }

  @Selector()
  static disabled_menu_items(state: MenuStateModel): string[] {
    return state.disabled_menu_items;
  }

  @Selector()
  static disable_menu_action(state: MenuStateModel): MenuStateModel['disabled_menu_action'] | undefined {
    return state.disabled_menu_action;
  }

  @Selector()
  static excluded(state: MenuStateModel): string[] {
    return state.excluded;
  }

  constructor(
    @Inject(STATE_PREFIX_TOKEN) private prefix: string,
    private actions: Actions,
    private store: Store,
    private menuService: MenuService,
    private router: Router,
    private ngZone: NgZone
  ) {
    actions
      .pipe(ofActionSuccessful(GetUserStatesSuccessAction))
      .subscribe(() => asapScheduler.schedule(() => this.store.dispatch(new MenuShowAction())));
    actions
      .pipe(ofActionSuccessful(LogoutAction, LogoutWithoutCallEndpointSuccessAction))
      .subscribe(() => this.store.dispatch(new MenuHideAction()));

    actions
      .pipe(ofActionSuccessful(MenuRefreshRemoteStateSuccessAction))
      .subscribe(() =>
        asapScheduler.schedule(() =>
          this.menuService
            .hasMenuItemByUrl()
            .subscribe(hasAccess =>
              !hasAccess
                ? NgZone.isInAngularZone()
                  ? this.router.navigate(['/'])
                  : this.ngZone.run(() => this.router.navigate(['/']))
                : undefined
            )
        )
      );
  }

  @Action(MenuShowAction)
  menuShow(ctx: StateContext<MenuStateModel>): any {
    produce<MenuStateModel>(ctx, draft => {
      draft.show = true;
    });
  }

  @Action(MenuHideAction)
  menuHide(ctx: StateContext<MenuStateModel>): any {
    produce<MenuStateModel>(ctx, draft => {
      draft.show = false;
    });
  }

  @Action(MenuCloseAction)
  menuClose(ctx: StateContext<MenuStateModel>): any {
    produce<MenuStateModel>(ctx, draft => {
      draft.open = false;
    });
  }

  @Action(MenuOpenAction)
  menuOpen(ctx: StateContext<MenuStateModel>): any {
    produce<MenuStateModel>(ctx, draft => {
      draft.open = true;
    });
  }

  @Action(MenuToggleAction)
  menuToggle(ctx: StateContext<MenuStateModel>): any {
    produce<MenuStateModel>(ctx, draft => {
      draft.open = !draft.open;
    });
  }

  @Action(GetUserStatesSuccessAction)
  getUserStatesSuccess(ctx: StateContext<MenuStateModel>, action: GetUserStatesSuccessAction): void {
    produce<MenuStateModel>(ctx, draft => {
      const menuKey = `${this.prefix}.menu`;
      if (action.states[menuKey] !== undefined) {
        const remoteMenuState = action.states[menuKey] as MenuStateModel;
        this.setFullState(draft, remoteMenuState);
        if (draft.open === undefined) {
          draft.open = true;
        }
      }
    });
  }

  private setFullState(draft, remoteMenuState: MenuStateModel): void {
    draft.excluded = remoteMenuState.excluded ? remoteMenuState.excluded : [];
    draft.disabled_menu_message = remoteMenuState.disabled_menu_message;
    draft.disabled_menu_page_group = remoteMenuState.disabled_menu_page_group ? remoteMenuState.disabled_menu_page_group : [];
    draft.disabled_menu_items = remoteMenuState.disabled_menu_items ? remoteMenuState.disabled_menu_items : [];
    draft.disabled_menu_action = remoteMenuState.disabled_menu_action;
    draft.invalid_period_context = remoteMenuState.invalid_period_context
      ? remoteMenuState.invalid_period_context
      : InvalidPeriodContextEnum.DISABLED;
  }

  @Action(UserStateKeyRefreshAction)
  userStateKeyRefresh({ setState, dispatch }: StateContext<MenuStateModel>, action: UserStateKeyRefreshAction): any {
    if (
      action.keyPath === `${this.prefix}.menu` ||
      action.keyPath === `${this.prefix}.menu.disabled_menu_page_group` ||
      action.keyPath === `${this.prefix}.menu.disabled_menu_message` ||
      action.keyPath === `${this.prefix}.menu.excluded` ||
      action.keyPath === `${this.prefix}.menu.disabled_menu_items` ||
      action.keyPath === `${this.prefix}.menu.invalid_period_context`
    ) {
      asapScheduler.schedule(() => dispatch(new MenuRefreshRemoteStateAction(action.keyPath)));
    }
  }

  @Action(MenuRefreshRemoteStateAction)
  menuRefreshRemoteState({ dispatch }: StateContext<MenuStateModel>, action: MenuRefreshRemoteStateAction): Observable<MenuStateModel> {
    return this.menuService.refreshFromUserState(action.keyPath).pipe(
      tap(menuState => asapScheduler.schedule(() => dispatch(new MenuRefreshRemoteStateSuccessAction(menuState, action.keyPath)))),
      catchError(err => {
        asapScheduler.schedule(() => dispatch(new MenuRefreshRemoteStateErrorAction(err)));
        return throwError(err);
      })
    );
  }

  @Action(MenuRefreshRemoteStateSuccessAction)
  menuRefreshRemoteStateSuccess(ctx: StateContext<MenuStateModel>, action: MenuRefreshRemoteStateSuccessAction): any {
    produce<MenuStateModel>(ctx, draft => {
      if (action.keyPath === `${this.prefix}.menu`) {
        this.setFullState(draft, action.state);
      } else if (action.keyPath === `${this.prefix}.menu.disabled_menu_page_group`) {
        draft.disabled_menu_page_group = action.state;
      } else if (action.keyPath === `${this.prefix}.menu.disabled_menu_message`) {
        draft.disabled_menu_message = action.state;
      } else if (action.keyPath === `${this.prefix}.menu.excluded`) {
        draft.excluded = action.state;
      } else if (action.keyPath === `${this.prefix}.menu.disabled_menu_items`) {
        draft.disabled_menu_items = action.state;
      } else if (action.keyPath === `${this.prefix}.menu.invalid_period_context`) {
        draft.invalid_period_context = action.state;
      }
    });
  }

  @Action(MenuSetInvalidPeriodContextAction)
  menuSetInvalidPeriodContext(ctx: StateContext<MenuStateModel>, action: MenuSetInvalidPeriodContextAction) {
    produce<MenuStateModel>(ctx, draft => {
      draft.invalid_period_context = action.newContext;
    });
  }

  // menu disable mockolasi lehetoseg
  // @Action(MenuMockDisableItemsAction)
  // menuMockDisableItems({ setState, getState }: StateContext<MenuStateModel>): any {
  //   return setState(
  //     produce<MenuStateModel>(getState(), draft => {
  //       draft.disabled_menu_items = ['VEHICLE_PARTNER_JOIN'];
  //     })
  //   );
  // }
  // @Action(MenuMockDisableItemsRemoveAction)
  // menuMockDisableRemoveItems({ setState, getState }: StateContext<MenuStateModel>): any {
  //   return setState(
  //     produce<MenuStateModel>(getState(), draft => {
  //       draft.disabled_menu_items = [];
  //     })
  //   );
  // }
}
