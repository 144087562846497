import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractEntityService, BatchRequestModel, HttpListResponseModel } from '@roadrecord/utils';
import { isNil, isString } from '@roadrecord/type-guard';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FuelingModel } from './model/fueling.model';
import { FuelingDayListModel } from './model/fueling-day-list.model';
import { environment } from '@roadrecord/environment';

@Injectable()
export class FuelingService extends AbstractEntityService<HttpListResponseModel<FuelingModel>, FuelingModel> {
  constructor(http: HttpClient) {
    super('fueling', 'fueling_datetime', http);
  }

  getToString(model: FuelingModel): string {
    return model.refueling_location.name;
  }

  getModelIdValue(model: FuelingModel): any {
    if (model !== undefined && model.hasOwnProperty('id')) {
      return model.id;
    }
    return undefined;
  }

  getNewFuelingParams(
    date: string,
    userId = null
  ): Observable<{
    consumption: number;
    previous_mileage: number;
    mileage_calculation?: boolean;
    calculated_distance: number | null;
    calculated_fueling_mileage: number | null;
  }> {
    return this.http
      .get<{
        consumption: number;
        previous_mileage: number;
        mustCalculation: boolean;
        calculated_distance: number | null;
        calculated_fueling_mileage: number | null;
      }>(`${environment.apiUrl}fueling-mileage-consumption-data${userId === null ? '/' : `/${userId}/`}?date=${date}`)
      .pipe(
        map(v => {
          // ha nincs previous mileage vagyis meg nincs kilometeroraallasa
          // akkor nem szamolunk indulasnal, ezert 0 -ra kell allitani az erteket bar kuldhetne a server is jol
          if (isNil(v.previous_mileage)) {
            v.previous_mileage = 0;
          }
          return v;
        })
      );
  }

  get(id: number, params?: string): Observable<FuelingModel> {
    if (!isString(params)) {
      params = 'expand=refueling_location';
    } else {
      params += '&expand=refueling_location';
    }
    return super.get(id, params);
  }

  getCalendarList() {
    return this.http.get<any>(`${environment.apiUrl}${this._entityUrl}/`, { params: { view_type: 'list' } });
  }

  remove(id: string | number): Observable<any> {
    throw new Error('Removed method');
  }

  removeV2(id: string | number, mileageCalculation = false): Observable<any> {
    return this.http.delete<any>(`${this.getEntityApiUrl2()}/${id}/`, { params: { mileage_calculation: `${mileageCalculation}` } });
  }

  removeBatch(id: string | number): BatchRequestModel {
    throw new Error('Removed method');
  }

  removeBatchV2(id: string | number, mileageCalculation = false): BatchRequestModel {
    return {
      method: 'delete',
      relative_url: `/${environment.baseApi2Endpoint}/${this._entityUrl}/${id}/?mileage_calculation=${mileageCalculation}`,
    };
  }

  removeStreamResponse(id: string | number): Observable<HttpResponse<any>> {
    throw new Error('Removed method');
  }
  removeStreamResponseV2(id: string | number, mileageCalculation = false): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.getEntityApiUrl2()}/${id}/?mileage_calculation=${mileageCalculation}`, { observe: 'response' });
  }

  getDayList(date: string): Observable<HttpListResponseModel<FuelingDayListModel>> {
    return this.http.get<HttpListResponseModel<FuelingDayListModel>>(`${environment.apiUrl}${this._entityUrl}/${date}/`);
  }
}
