import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractEntityService, HttpListResponseModel } from '@roadrecord/utils';
import { isNil, isString } from '@roadrecord/type-guard';
import { Observable } from 'rxjs';
import { LiveAutoCompleteOptionsConfigModel } from '@roadrecord/live-auto-complete';
import { VehicleModel } from '@roadrecord/vehicle/model/common';

@Injectable()
export abstract class VehicleService<MODEL extends VehicleModel> extends AbstractEntityService<HttpListResponseModel<MODEL>, MODEL> {
  protected _liveAutocompleteConfig: LiveAutoCompleteOptionsConfigModel<MODEL>;

  getToString(model: MODEL): string {
    return model.plate_number;
  }

  constructor(http: HttpClient) {
    super('vehicles', 'plate_number', http);
    this.loadLiveAutocompleteConfig();
  }

  getModelIdValue(model: MODEL): any {
    if (!isNil(model) && model.hasOwnProperty('id')) {
      return model.id;
    }
    return undefined;
  }

  get(id: number, params?: string, expand = 'departure,departure.trip_reason,vehicle_owner,vehicle_owner.trip_reason'): Observable<MODEL> {
    if (!isString(params)) {
      params = `expand=${expand}`;
    } else {
      params += `&expand=${expand}`;
    }
    return super.get(id, params);
  }

  protected abstract loadLiveAutocompleteConfig(): void;

  get liveAutocompleteConfig(): LiveAutoCompleteOptionsConfigModel<MODEL> {
    return this._liveAutocompleteConfig;
  }
}
