<table class="mat-calendar-table" role="presentation">
  <thead class="mat-calendar-table-header">
    <tr>
      <th scope="col" *ngFor="let day of _weekdays" [attr.aria-label]="day.long">{{day.narrow}}</th>
    </tr>
    <tr>
      <th class="mat-calendar-table-header-divider" colspan="7" aria-hidden="true"></th>
    </tr>
  </thead>
  <tbody
    mat-calendar-body
    [label]="_monthLabel"
    [rows]="_weeks"
    [todayValue]="_todayDate!"
    [startValue]="_selectedDate!"
    [labelMinRequiredCells]="3"
    [activeCell]="_dateAdapter.getDate(activeDate) - 1"
    (selectedValueChange)="_dateSelected($event)"
    (keydown)="_handleCalendarBodyKeydown($event)"
  ></tbody>
</table>
