import { Pipe, PipeTransform } from '@angular/core';
import { isDeletable, isEditable } from '@roadrecord/common/common';

@Pipe({
  name: 'isNotDeletableAndEditable',
})
export class IsNotDeletableAndEditablePipe implements PipeTransform {
  transform(value: any): boolean {
    return !isDeletable(value) && !isEditable(value);
  }
}
