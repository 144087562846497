import { UniversalUploadSourceTypes } from '@roadrecord/universal-import/common';

export enum PartnerImportUploadSourceTypesEnum {
  UNIVERSAL = 'universal',
  CASHMAN = 'cashman',
}

export const partnerImportUploadSourceTypes: UniversalUploadSourceTypes<PartnerImportUploadSourceTypesEnum>[] = [
  {
    translateKey: 'UNIVERSAL',
    value: PartnerImportUploadSourceTypesEnum.UNIVERSAL,
  },
  {
    translateKey: 'CASHMAN',
    value: PartnerImportUploadSourceTypesEnum.CASHMAN,
  },
];
