export enum AreaOfInterestUSEnum {
  SELF_EMPLOYED = 9,
  ACCOUNTANT = 2,
  SMALL_BUSINESS_OWNER = 0,
  DELIVERY = 10,
  EMPLOYEE_WITH_COMPANY_CAR = 4,
  EMPLOYEE_WITH_PRIVATE_CAR = 3,
  REAL_ESTATE = 8,
  TRUCK = 6,
  RIDESHARE_DRIVER = 1,
  NOT_FOR_TAX_PURPOSES = 7,
  EMPLOYER = 5,
}
