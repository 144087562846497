import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailsModule as PartnerDetailsModule } from '../details/details.module';
import { ModalWrapperComponent } from './modal-wrapper/modal-wrapper.component';

@NgModule({
  imports: [CommonModule, PartnerDetailsModule],
  declarations: [ModalWrapperComponent],
  exports: [ModalWrapperComponent],
  entryComponents: [ModalWrapperComponent],
})
export class ModalWrapperModule {}
