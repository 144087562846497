import { FragmentDialogTypeEnum } from '../model/fragment-dialog-type.enum';

export class FragmentHideDialogAction {
  static readonly type = '[Fragment] Hide dialog';

  constructor(
    readonly dialogType: FragmentDialogTypeEnum | string,
    readonly data?: any,
    readonly locationBack = false,
    readonly cancel = false,
    readonly deleteMode = false
  ) {}
}
