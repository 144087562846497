import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { DetailsButtonRowComponent } from './details-button-row/details-button-row.component';
import { SubmitButtonWithErrorComponent } from './submit-button-with-error/submit-button-with-error.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, TranslocoModule, MatButtonModule, MatTooltipModule, MatIconModule],
  declarations: [DetailsButtonRowComponent, SubmitButtonWithErrorComponent],
  exports: [DetailsButtonRowComponent, SubmitButtonWithErrorComponent],
})
export class FormModule {}
