import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DisableDeactivateWhenOpenBottomSheetGuard, PARTNER_IMPORT_ROUTE_PATH } from '@roadrecord/common/common';
import { CheckModifiedMVPFormGuard, ID_NUMBER_CHECK_PROVIDERS, ParamTypeCheckGuard } from '@roadrecord/utils';
import { DetailsComponent, ListComponent } from '@roadrecord/partner/common';
import { InvalidPeriodContextGuard } from '@roadrecord/period-context/common';

const routes: Routes = [
  {
    path: PARTNER_IMPORT_ROUTE_PATH,
    loadChildren: () => import('libs/partner/hu/src/lib/import/partner-import.module').then(m => m.PartnerImportModule),
  },
  {
    path: '',
    component: ListComponent,
    canDeactivate: [InvalidPeriodContextGuard, DisableDeactivateWhenOpenBottomSheetGuard],
  },
  {
    path: 'rename-anonyms',
    loadChildren: () => import('libs/partner/common/src/lib/anonym-partners/anonym-partners.module').then(m => m.AnonymPartnersModule),
  },
  { path: 'map', loadChildren: () => import('libs/partner/common/src/lib/details/map/map.module').then(m => m.MapModule) },
  { path: 'new', component: DetailsComponent, canDeactivate: [InvalidPeriodContextGuard, CheckModifiedMVPFormGuard] },
  {
    path: ':id',
    component: DetailsComponent,
    canActivate: [ParamTypeCheckGuard],
    canDeactivate: [InvalidPeriodContextGuard, CheckModifiedMVPFormGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [ID_NUMBER_CHECK_PROVIDERS, DisableDeactivateWhenOpenBottomSheetGuard],
})
export class PartnerRoutingModule {}
