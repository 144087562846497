import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { slideBottomInAnimation } from '@roadrecord/common/common';
import { SpecialDayEventTypeEnum } from '../../../model/special-day-event-type.enum';
import { RRCalendarMonthViewDay } from '../../../model/rr-calendar-month-view-day';
import { RRSpecialDayEventModel } from '../../../model/rr-special-day-event.model';
import { RRCalendarEvent } from '../../../model/rr-calendar-event';
import { CalendarMonthViewBeforeRenderEvent, DAYS_OF_WEEK } from 'angular-calendar';
import moment from 'moment';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { RightClickMenuItemModel } from '@roadrecord/right-click-menu';

@Component({
  selector: 'rr-calendar-calendar-view',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideBottomInAnimation],
})
export class CalendarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() readonly calendarId: string;
  @Input() readonly hasListViewButton: boolean;
  @Input() readonly viewDate: Date;
  @Input() readonly weekStartsOn: DAYS_OF_WEEK;
  @Input() readonly weekendDays: DAYS_OF_WEEK[];
  @Input() readonly locale: string;
  @Input() readonly cellHeaderTemplate: TemplateRef<any>;
  @Input() readonly cellContainerTemplate: TemplateRef<any>;
  @Input() readonly setHeaderDayNumberCssClassFn: (day: RRCalendarMonthViewDay) => string;
  @Input() readonly isLoading: boolean;
  @Input() readonly hasSpecialDaysLayer: boolean;
  @Input() readonly calendarOptions: { days: RRSpecialDayEventModel[]; start_date: string };
  @Input() readonly rightClickContextMenuOption: RightClickMenuItemModel<any>[];
  @Input() readonly enabledRightClickContextMenu: boolean;
  @Output() readonly beforeViewRender = new EventEmitter<CalendarMonthViewBeforeRenderEvent>();
  @Output() readonly contextMenuOnDayCell = new EventEmitter<any>();
  @Output() readonly clickCell = new EventEmitter<RRCalendarMonthViewDay>();
  @ViewChildren(ContextMenuComponent) readonly rightClickMenuComponent: QueryList<ContextMenuComponent>;
  rightClickContextMenuClass: string;
  selectedDay: RRCalendarMonthViewDay;
  private cellCssClassesCache: { day: RRCalendarMonthViewDay; cssClasses: string }[] = [];

  constructor(private readonly contextMenuService: ContextMenuService) {}

  private _events: { events: RRCalendarEvent[]; calendarRowCount: number };

  get events(): { events: RRCalendarEvent[]; calendarRowCount: number } {
    return this._events;
  }

  @Input()
  set events(value: { events: RRCalendarEvent[]; calendarRowCount: number }) {
    this._events = value;
  }

  ngOnInit() {
    this.rightClickContextMenuClass = `calendar${this.calendarId.replace(new RegExp('/', 'g'), '-')}`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.events !== undefined &&
      (changes.events.isFirstChange() || changes.events.previousValue !== changes.events.currentValue) &&
      changes.events.currentValue !== null
    ) {
      this.cellCssClassesCache = [];
    }
  }

  onClickDay(day: RRCalendarMonthViewDay): void {
    if (this.isLoading) {
      return;
    }
    if (day.inMonth) {
      // TODO kiszervezni a class nevet!
      const selectedDayClassName = 'cal-day-selected';
      const selectedDay = { ...day } as RRCalendarMonthViewDay;
      selectedDay.previewCssClass = day.cssClass || '';
      selectedDay.cssClass = selectedDayClassName.concat(` ${selectedDay.previewCssClass}`);
      selectedDay.selectedDay = true;
      this.selectedDay = selectedDay;
    }
    this.clickCell.emit(day);
  }

  onBeforeViewRender($event: CalendarMonthViewBeforeRenderEvent): void {
    if (this.beforeViewRender.closed === false) {
      this.beforeViewRender.emit($event);
    }
  }

  setCellCss(day: RRCalendarMonthViewDay): string {
    const index = this.cellCssClassesCache.findIndex(cache => cache.day === day);
    if (index > -1) {
      return this.cellCssClassesCache[index].cssClasses;
    }
    const cssClasses = [];
    if (day.events.length > 0 && day.events[0].cssClass !== undefined) {
      cssClasses.push(day.events[0].cssClass);
    }
    if (this.hasSpecialDaysLayer === true && this.calendarOptions !== undefined) {
      const specialDayEvent = this.calendarOptions.days.find(event => event.date === moment(day.date).format('YYYY-MM-DD'));
      if (specialDayEvent !== undefined) {
        switch (specialDayEvent.day_type) {
          case SpecialDayEventTypeEnum.HOLIDAY:
            cssClasses.push('holiday-background-icon');
            break;
          case SpecialDayEventTypeEnum.HOLIDAY_INSTEAD_OF_WORKDAY:
            cssClasses.push('holiday-background-icon');
            break;
          /*
                                        case SpecialDayEventTypeEnum.WORKDAY:
                                            cssClasses.push('work-background-icon');
                                            break;
                    */
          case SpecialDayEventTypeEnum.WORKDAY_INSTEAD_OF_HOLIDAY:
            cssClasses.push('work-background-icon');
            break;
        }
      }
    }

    if (day.events !== undefined && day.events.length > 0) {
      cssClasses.push('has-content');
    } else {
      cssClasses.push('has-not-content');
    }
    const cssClassesStr = cssClasses.join(' ');
    this.cellCssClassesCache.push({
      day,
      cssClasses: cssClassesStr,
    });
    return cssClassesStr;
  }

  onContextMenuOnDayCell($event: MouseEvent, day: any): void {
    this.contextMenuOnDayCell.emit(day);
    this.contextMenuService.show.next({
      contextMenu: this.rightClickMenuComponent.first,
      event: $event,
      item: day.events,
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

  private maybeCloseRightClickContextMenu(): void {
    const rightClickContextMenuOverlayRef = this.contextMenuService.getLastAttachedOverlay();
    if (
      rightClickContextMenuOverlayRef !== undefined &&
      rightClickContextMenuOverlayRef.contextMenu.menuClass === this.rightClickContextMenuClass
    ) {
      rightClickContextMenuOverlayRef.detach();
      rightClickContextMenuOverlayRef.dispose();
    }
  }

  ngOnDestroy() {
    this.maybeCloseRightClickContextMenu();
    // hogyha nyitva van a submenu is
    this.maybeCloseRightClickContextMenu();
  }
}
