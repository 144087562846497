import {
  DocumentDataCheckerActionConfig,
  DocumentDataCheckerActionTypeEnum,
  DocumentDataCheckerActionUiConfig,
  DocumentDataCheckerUiActionType,
} from '@roadrecord/document-data-checker-action/model';

export function generateDocumentDataCheckerConfig(config: DocumentDataCheckerActionConfig): DocumentDataCheckerActionUiConfig[] {
  const result: DocumentDataCheckerActionUiConfig[] = [];
  config.actions.forEach(rootAction => {
    const newObj: any = { id: rootAction.id, label: rootAction.label, actions: [] };
    rootAction.actions.forEach(action => {
      const insideAction: DocumentDataCheckerUiActionType = {
        id: action.id,
        label: action.label,
        type: action.type,
        actions: action.actions.map(insideActionId => config.actionTypes.find(configActionType => configActionType.id === insideActionId)),
      };
      if (action.type === DocumentDataCheckerActionTypeEnum.ROUTE) {
        insideAction.route = action.route;
      } else {
        insideAction.label = insideAction.actions[0].label;
      }
      newObj.actions.push(insideAction);
    });
    result.push(newObj);
  });
  // console.log(JSON.stringify(result, null, 2));
  return result;
}
