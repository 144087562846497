import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridModule } from '@roadrecord/grid';

import { ListRoutingModule } from './list-routing.module';
import { ListComponent } from './list.component';

@NgModule({
  imports: [CommonModule, ListRoutingModule, GridModule],
  declarations: [ListComponent],
})
export class ListModule {}
