import { Component } from '@angular/core';
import { FieldWrapper, FormlyFieldConfig } from '@ngx-formly/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn, fadeOut } from 'ng-animate';
import { fadeInEnter } from '@roadrecord/animations';
import { fadeInOutAnimation } from '@roadrecord/common/common';
import { isBoolean, isNil } from '@roadrecord/type-guard';

const SlideInOutAnimation = [
  fadeInEnter,
  fadeInOutAnimation,
  trigger('slideInOut', [transition('in => out', useAnimation(fadeOut)), transition('out => in', useAnimation(fadeIn))]),
];

@Component({
  selector: 'rr-formly-wrapper-animation',
  template: `
    <div [@slideInOut]="field.hide ? 'out' : 'in'">
      <ng-container #fieldComponent></ng-container>
    </div>
  `,
  animations: [SlideInOutAnimation],
})
export class AnimationWrapperComponent extends FieldWrapper {}

export function animationExtension(field: FormlyFieldConfig) {
  if (
    (field.wrappers && field.wrappers.includes('animation')) ||
    (!isNil(field.templateOptions) &&
      isBoolean(field.templateOptions.disableAnimationWrapper) &&
      field.templateOptions.disableAnimationWrapper === true)
  ) {
    return;
  }
  // hogy mindig az animation legyen a legkulso
  field.wrappers = ['animation', ...(field.wrappers || [])];
}
