import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { fadeInEnter, zoomInEnter } from '@roadrecord/animations';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { RecommendationContinueAction } from '../state/action/recommendation-continue.action';
import { RecommendationFinishAction } from '../state/action/recommendation-finish.action';
import { RecommendationState } from '../state/recommendation.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'rr-recommendation-continue',
  templateUrl: './recommendation-continue.component.html',
  styleUrls: ['./recommendation-continue.component.scss'],
  animations: [fadeInEnter, zoomInEnter],
})
export class RecommendationContinueComponent implements OnInit {
  @Select(RecommendationState.run)
  run$: Observable<boolean>;
  @Select(RecommendationState.continueProgress)
  continueProgress$: Observable<{ progress: number }>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    const continueProgressSubscription = this.continueProgress$.pipe(delay(300), untilDestroyed(this)).subscribe(progress => {
      if (progress.progress === -1) {
        return;
      }
      if (progress.progress < 100 && progress.progress >= 0) {
        this.store.dispatch(new RecommendationContinueAction());
      } else {
        this.store.dispatch(new RecommendationFinishAction());
        continueProgressSubscription.unsubscribe();
      }
    });
  }
}
