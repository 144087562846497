import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { EditColumnsConfig } from './model/edit-columns.config';
import { isFalse, isFunction, isNil } from '@roadrecord/type-guard';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Masked } from 'imask';

@UntilDestroy()
@Component({
  selector: 'rr-edit-cell',
  templateUrl: './edit-cell.component.html',
  styleUrls: ['./edit-cell.component.scss'],
})
export class EditCellComponent implements OnChanges {
  @Input() editMode = false;
  @Input() text: string;
  control: FormControl;
  @Output() initControl = new EventEmitter<FormControl>();
  @Output() initMultiLineControl = new EventEmitter<FormControl>();
  @Input() editModel: any;
  @Input() parentFormGroup: FormGroup;
  disableEdit = false;
  private formChangesSubscription: Subscription;
  @Input() remoteControlsErrors: { [key: string]: { error: string; lastValue: string } };
  private textMaskConfig: Masked<any>;
  @Input() editCellsTemplateRef?: TemplateRef<any>[];
  @Input() uploadFileCellsTemplateRef?: TemplateRef<any>[];
  @Input() rowIndex: number;

  constructor(private cdr: ChangeDetectorRef) {}

  private _config: EditColumnsConfig;

  get config(): EditColumnsConfig {
    return this._config;
  }

  @Input()
  set config(value: EditColumnsConfig) {
    this._config = value;
  }

  markAsTouched() {
    this.control.markAsTouched();
    this.cdr.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!isNil(changes.editMode)) {
      if (!(this.editModel[this._config.name] !== null && isFalse(this._config.disableEdit))) {
        this.disableEdit = false;
        if (changes.editMode.currentValue === true) {
          if (!isNil(this._config.validators)) {
            const validators = [];
            if (Array.isArray(this._config.validators)) {
              validators.push(...this._config.validators);
            } else {
              validators.push(this._config.validators);
            }
            this.control = new FormControl(
              this.editModel[this._config.name],
              Validators.compose([...validators, this.customRemoteValidatorMessage(this._config.name)])
            );
          } else {
            this.control = new FormControl(this.editModel[this._config.name], this.customRemoteValidatorMessage(this._config.name));
          }

          this.initControl.emit(this.control);
          this.initMultiLineControl.emit(this.control);
        } else if (changes.editMode.currentValue === false) {
          delete this.control;
        }
      } else {
        this.disableEdit = true;
      }
    }
    if (!isNil(changes.parentFormGroup) && !isNil(changes.parentFormGroup.currentValue)) {
      if (!isNil(this.formChangesSubscription)) {
        this.formChangesSubscription.unsubscribe();
      }
      this.formChangesSubscription = changes.parentFormGroup.currentValue.valueChanges
        .pipe(
          untilDestroyed(this),
          filter(() => !isNil(this.control))
        )
        .subscribe(() => {
          this.control.updateValueAndValidity({ onlySelf: true });
        });
    }

    // Edit control tiltása - egedélyezése
    if (this._config.disabled !== undefined && isFunction(this._config.disabled)) {
      const isDisabled = this._config.disabled(this.editModel);
      if (isDisabled === true) {
        this.control.disable();
      }
    }
  }

  /**
   * validator
   * megnezi hogy a tarolt hibak-ban a control nevevel talal-e megfelelo kulcsot, ha igen akkor hibat jelez
   * a customError kulcsot az rr-validation component ismeri! es tudja hogy a hiba erteket irja ki
   */
  private customRemoteValidatorMessage(name: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } =>
      this.remoteControlsErrors !== undefined &&
      this.remoteControlsErrors[name] !== undefined &&
      (this.remoteControlsErrors[name].lastValue === undefined || this.remoteControlsErrors[name].lastValue === control.value)
        ? this.getErrorMessage(name)
        : undefined;
  }

  private getErrorMessage(name: string): { customError: any } {
    return { customError: this.remoteControlsErrors[name].error };
  }

  getTextMask(config: EditColumnsConfig) {
    if (isNil(this.textMaskConfig)) {
      this.textMaskConfig = config.textMask();
    }
    return this.textMaskConfig;
  }
}
