/** @docs-private */
import { Constructor, throwNotOverrideError } from '@roadrecord/common/common';
import { Notification, NotificationsService } from '@roadrecord/external-packages/angular2-notifications';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { OnDestroy, OnInit, Type } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from '@ngneat/until-destroy';
import { isNil, isObject } from '@roadrecord/type-guard';
import { Observable } from 'rxjs';
import { RRFormWithDynamicFormBottomSheetConfig } from './bottom-sheet-rr-form-with-dynamic-form.mixin';

// TODO abstractra atirni a metodusokat!

/**
 * mixin-elt osztalyban kell hasznalni
 * tovabba ezeknek leteznie kell a mixinelt osztalyban
 *
 * Kotelezo: OnInit, OnDestroy
 *
 */
export interface SearchMissingFieldAndShowNotificationFields<MODEL> extends OnInit, OnDestroy {
  /**
   * igy tudjuk jelezni hogy kapcsoljon ki minden automatikus felnyilo mezo
   */
  disableAutoOpenFields: boolean;
  searchMissingFieldAndShowNotification__OriginalData: { data: MODEL } & any;
  searchMissingFieldAndShowNotification__NotificationRef: Notification;
  /**
   * Bottom sheet-et nyitja fel
   * @param model
   */
  searchMissingFieldAndShowNotification__OpenBottomSheet(model: { data: MODEL } & any): void;
  /**
   * Ez hivodik vissza amikor bottom sheet-ben megnyomjak a submitot es valid a form
   * Ha hiba nelkul ter vissza a vissza adott stream akkor bezarodik a bottom sheet
   *
   * Abstract - kotelezo felulirni!
   *
   * @param formValue
   */
  searchMissingFieldAndShowNotification__BottomSheetOnSubmit(formValue: unknown): Observable<unknown>;
  /**
   * Bottom sheet bezarasa utan hivjuk vissza
   */
  searchMissingFieldAndShowNotification__AfterDismissedBottomSheet(): void;
  /**
   * Ha adatok betoltese, ha itt null jon vissza akkor nem fog megjelenni!
   *
   * Abstract - kotelezo felulirni!
   */
  searchMissingFieldAndShowNotification__GetData(): Observable<({ data: MODEL } & any) | null>;
  /**
   * Notification bezaras
   */
  searchMissingFieldAndShowNotification__DismissNotification(): void;
}

export type SearchMissingFieldAndShowNotificationCtor<MODEL> = Constructor<SearchMissingFieldAndShowNotificationFields<MODEL>>;

/**
 * aki extendalja majd a mixin-t annak ezeket super-ben at kell adnia
 */
export interface SearchMissingFieldAndShowNotificationConstructorParams {
  readonly searchMissingFieldAndShowNotification__BottomSheetClassRef: Type<any>;
  readonly notificationSettingsOverride: Partial<Notification>;
  readonly route: ActivatedRoute;
  readonly notificationsService: NotificationsService;
  readonly bottomSheet: MatBottomSheet;
}

/**
 * Ha valamilyen mezo hianyara barmilyen entitasra, fel akarunk dobni egy notifit
 * es utana bottom sheet-ben lehet modositani
 */
export function mixinSearchMissingFieldAndShowNotificationWithGenericType<MODEL>(
  _base: any,
  translate: { title: string; content: string }
) {
  return (function mixinSearchMissingFieldAndShowNotification<
    T extends Constructor<SearchMissingFieldAndShowNotificationConstructorParams>
  >(base: T): SearchMissingFieldAndShowNotificationCtor<MODEL> & T {
    return class extends base implements OnInit, OnDestroy {
      constructor(...args: any[]) {
        super(...args);
      }

      private _disableAutoOpenFields = false;

      get disableAutoOpenFields() {
        return this._disableAutoOpenFields;
      }

      set disableAutoOpenFields(v: boolean) {
        this._disableAutoOpenFields = v;
      }

      private _searchMissingFieldAndShowNotification__NotificationRef: Notification;

      get searchMissingFieldAndShowNotification__NotificationRef(): Notification {
        return this._searchMissingFieldAndShowNotification__NotificationRef;
      }

      _searchMissingFieldAndShowNotification__OriginalData: { data: MODEL } & any;

      get searchMissingFieldAndShowNotification__OriginalData() {
        return this._searchMissingFieldAndShowNotification__OriginalData;
      }

      searchMissingFieldAndShowNotification__OpenBottomSheet(data: { data: MODEL } & any): void {
        if (isNil(data)) {
          data = { data: null } as any;
        }
        this.bottomSheet
          .open(this.searchMissingFieldAndShowNotification__BottomSheetClassRef, {
            data: {
              ...(data as any),
              onSubmit: this.searchMissingFieldAndShowNotification__BottomSheetOnSubmit.bind(this),
            } as RRFormWithDynamicFormBottomSheetConfig<MODEL>,
            autoFocus: true,
            restoreFocus: true,
            closeOnNavigation: true,
            disableClose: true,
          })
          .afterDismissed()
          .subscribe(() => {
            this._disableAutoOpenFields = false;
            this.searchMissingFieldAndShowNotification__AfterDismissedBottomSheet();
          });
      }

      /**
       * Amikor a form-t submitaljak, itt kell nekunk elintezni a remote hivast
       * es a http valaszt kell vissza adni(hibat a hivo kezeli!)
       * @param formValue
       */
      searchMissingFieldAndShowNotification__BottomSheetOnSubmit(formValue: MODEL): Observable<MODEL> {
        throw throwNotOverrideError('searchMissingFieldNotificationOnSubmit');
      }

      /**
       * miutan rakattintottak a notifi-re
       */
      searchMissingFieldAndShowNotification__AfterDismissedBottomSheet() {}

      /**
       * ActivatedRoute -bol nekunk kell kell, vagy mashonnan kiolvasni az adatoka,
       * ha nincs adat vagy nem felel meg a felteteleknek akkor null-t kell vissza adni!
       * Ha null-t adunk vissza, akkor nem jelenik meg a notify!
       */
      searchMissingFieldAndShowNotification__GetData(): Observable<{ data: MODEL } & any> {
        throw throwNotOverrideError('searchMissingFieldNotificationAfterDismissed');
      }

      ngOnInit() {
        this.searchMissingFieldAndShowNotification__GetData().subscribe(data => {
          this._searchMissingFieldAndShowNotification__OriginalData = data;
          if (!isNil(data)) {
            this.openSearchMissingFieldAndNotification();
            // handle click notification
            this._searchMissingFieldAndShowNotification__NotificationRef.click.pipe(untilDestroyed(this)).subscribe(() => {
              this._searchMissingFieldAndShowNotification__NotificationRef = undefined;
              this._disableAutoOpenFields = true;
              this.searchMissingFieldAndShowNotification__OpenBottomSheet(data);
            });
          }
        });
      }

      ngOnDestroy() {
        this.searchMissingFieldAndShowNotification__DismissNotification();
      }

      searchMissingFieldAndShowNotification__DismissNotification() {
        if (!isNil(this._searchMissingFieldAndShowNotification__NotificationRef)) {
          this.notificationsService.searchNotificationComponentByAutoIdAndRemove(
            this._searchMissingFieldAndShowNotification__NotificationRef.autoId
          );
        }
      }

      protected openSearchMissingFieldAndNotification() {
        this._searchMissingFieldAndShowNotification__NotificationRef = this.notificationsService.info(translate.title, translate.content, {
          ...(isObject(this.notificationSettingsOverride) ? this.notificationSettingsOverride : {}),
          clickIconToClose: false,
          clickToClose: true,
        });
      }
    };
  })(_base);
}

export class SearchMissingFieldAndShowNotificationBase {
  constructor(
    readonly searchMissingFieldAndShowNotification__BottomSheetClassRef: Type<any>,
    readonly notificationSettingsOverride: Partial<Notification>,
    readonly route: ActivatedRoute,
    readonly notificationsService: NotificationsService,
    readonly bottomSheet: MatBottomSheet
  ) {}
}

export function getSearchMissingFieldAndShowNotificationBase<MODEL>(translate: { title: string; content: string }) {
  return mixinSearchMissingFieldAndShowNotificationWithGenericType<MODEL>(
    SearchMissingFieldAndShowNotificationBase,
    translate
  ) as SearchMissingFieldAndShowNotificationCtor<MODEL> & typeof SearchMissingFieldAndShowNotificationBase;
}

/**
 * ebben az esetben szukseg van az eredmeny ujra castolasara,
 * pl:
 * `export class CommonDetailsComponent
 extends (getSearchMissingFieldAndShowNotificationWithCustomBase<VehicleModel>(
 SearchMissingFieldAndShowNotificationBase,
 translate
 ) as SearchMissingFieldAndShowNotificationCtor<VehicleModel> & typeof SearchMissingFieldAndShowNotificationBase)
 `
 *
 * @param base
 * @param translate
 */
export function getSearchMissingFieldAndShowNotificationWithCustomBase<MODEL>(base: any, translate: { title: string; content: string }) {
  return mixinSearchMissingFieldAndShowNotificationWithGenericType<MODEL>(base, translate) as SearchMissingFieldAndShowNotificationCtor<
    MODEL
  > &
    typeof base;
}
