import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import {
  AppTypePipeModule,
  CardContentLoadingBarModule,
  DataCyDirectiveModule,
  FocusInputModule,
  PreventClickModule,
  SanitizePipeModule,
  TwoWaySwitchFormControlModule,
} from '@roadrecord/common/common';
import { DialogModule } from '@roadrecord/dialog';
import { FragmentPresenterModule } from '@roadrecord/fragment-dialog';
import { LiveAutoCompleteModule } from '@roadrecord/live-auto-complete';
import { MapBoxModule } from '@roadrecord/map-box';
import { ProgressWhenFirstLoadingModule } from '@roadrecord/progress-when-first-loading';
import {
  DetailsModule as TripReasonDetailsModule,
  ModalWrapperModule as TripReasonModalWrapperModule,
  TRIP_REASON_EDIT_FRAGMENT_PRESENTERS,
  TRIP_REASON_NEW_FRAGMENT_PRESENTERS,
} from '@roadrecord/trip-reason/common';
import { FormModule } from '@roadrecord/utils';
import { ValidatingModule } from '@roadrecord/validating';
import { DataFormComponent } from './data-form/data-form.component';

import { DetailsComponent } from './details.component';
import { FilterLocationsPipe } from './pipes/filter-locations.pipe';
import { PoimoveDialogComponent } from './poimove-dialog/poimove-dialog.component';
import { SearchOnMapComponent } from './search-on-map/search-on-map.component';
import { DynamicFormModule } from '@roadrecord/dynamic-form';
import { SearchOnMapListComponent } from './search-on-map-list/search-on-map-list.component';
import { GenerateIdFromTypeEnumPipe } from './generate-id-from-type-enum.pipe';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    FlexLayoutModule,

    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatRippleModule,
    MatTabsModule,

    ValidatingModule,
    FormModule,
    CardContentLoadingBarModule,
    TwoWaySwitchFormControlModule,
    DialogModule,
    TripReasonDetailsModule,
    ProgressWhenFirstLoadingModule,
    FocusInputModule,

    TranslocoModule,
    TripReasonModalWrapperModule,
    FragmentPresenterModule.forFeature([...TRIP_REASON_NEW_FRAGMENT_PRESENTERS, ...TRIP_REASON_EDIT_FRAGMENT_PRESENTERS]),
    MapBoxModule,
    LiveAutoCompleteModule,
    AppTypePipeModule,
    DynamicFormModule,
    SanitizePipeModule,
    PreventClickModule,
    DataCyDirectiveModule,
  ],
  declarations: [
    DetailsComponent,
    DataFormComponent,
    SearchOnMapComponent,
    SearchOnMapListComponent,
    PoimoveDialogComponent,
    FilterLocationsPipe,
    GenerateIdFromTypeEnumPipe,
  ],
  exports: [DataFormComponent, DetailsComponent],
})
export class DetailsModule {}
