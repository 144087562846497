export const globalDatePickerValidationMessages /*: ValidationMessageModel[]*/ = [
  {
    errorKey: 'matDatepickerMin',
    translateKey: 'COMMON.VALIDATION.DATEPICKER_MIN',
  },
  {
    errorKey: 'matDatepickerMax',
    translateKey: 'COMMON.VALIDATION.DATEPICKER_MAX',
  },
];
