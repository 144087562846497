export class UserModel {
  username: string;
  first_name: string;
  last_name: string;
  orig_iat: number;
  rememberMe: boolean;
  exp: number;
  email: string;
  session: string;
  staff_id?: number;
}
