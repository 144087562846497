import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { fadeInEnter } from '@roadrecord/animations';
import { Observable, of } from 'rxjs';
import { RecommendationCheckerResult } from '../recommendation/state/model/recommendation-checker-result';
import { RecommendationStatusEnum } from '../recommendation/state/model/recommendation-status.enum';
import { RecommendationState } from '../recommendation/state/recommendation.state';
import { DocumentDataCheckerActionsEnum, DocumentDataCheckerActionUiConfig } from '@roadrecord/document-data-checker-action/model';
import { LoadableService } from '@roadrecord/external-packages/ngx-loadable';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { handleDocumentDataCheckerAction } from '@roadrecord/document-data-checker-action/helper';
import { isNil } from '@roadrecord/type-guard';
import { RecommendationChangeFilterAction } from '../recommendation/state/action/recommendation-change-filter.action';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DOCUMENT_DATA_CHECKER_ACTION_CONFIG, generateDocumentDataCheckerConfig } from '@roadrecord/document-data-checker-action/ui';

@UntilDestroy()
@Component({
  selector: 'rr-recommendation-check',
  templateUrl: './recommendation-check.component.html',
  styleUrls: ['./recommendation-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInEnter],
})
export class RecommendationCheckComponent implements OnChanges {
  @Input() windowMode: boolean;
  checkResults$: Observable<RecommendationCheckerResult[]>;
  countAll$: Observable<number>;
  countAllWithoutError$: Observable<number>;
  countOK$: Observable<number>;
  countWarning$: Observable<number>;
  countError$: Observable<number>;
  currentFilter$: Observable<RecommendationStatusEnum | 'ALL'>;
  RecommendationStatusEnum = RecommendationStatusEnum;
  @Output()
  continueProcess = new EventEmitter<void>();
  private actionsConfig: DocumentDataCheckerActionUiConfig[];

  constructor(
    private store: Store,
    private matSnackBar: MatSnackBar,
    private translocoService: TranslocoService,
    private router: Router,
    private injector: Injector,
    private loadableService: LoadableService,
    private actions$: Actions
  ) {
    this.actionsConfig = generateDocumentDataCheckerConfig(DOCUMENT_DATA_CHECKER_ACTION_CONFIG);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!isNil(changes.windowMode) && changes.windowMode.currentValue === true) {
      // replace state selectors to snapshot version
      this.checkResults$ = this.store.selectOnce(RecommendationState.checkResults);
      this.countAll$ = this.store.selectOnce(RecommendationState.countAll);
      this.countAllWithoutError$ = this.store.selectOnce(RecommendationState.countAllWithoutError);
      this.countOK$ = this.store.selectOnce(RecommendationState.countOK);
      this.countWarning$ = this.store.selectOnce(RecommendationState.countWarning);
      this.countError$ = this.store.selectOnce(RecommendationState.countError);
      this.currentFilter$ = this.store.selectOnce(RecommendationState.currentFilter);
      // azert van szukseg mert a filter is state-bol jon ...
      this.actions$
        .pipe(ofActionSuccessful(RecommendationChangeFilterAction), untilDestroyed(this))
        .subscribe(action => (this.currentFilter$ = of(action.status)));
    } else {
      this.checkResults$ = this.store.select(RecommendationState.checkResults);
      this.countAll$ = this.store.select(RecommendationState.countAll);
      this.countAllWithoutError$ = this.store.select(RecommendationState.countAllWithoutError);
      this.countOK$ = this.store.select(RecommendationState.countOK);
      this.countWarning$ = this.store.select(RecommendationState.countWarning);
      this.countError$ = this.store.select(RecommendationState.countError);
      this.currentFilter$ = this.store.select(RecommendationState.currentFilter);
    }
  }

  onClickRecommendation(): void {
    this.continueProcess.emit();
  }

  onClickAction(actions: { action: DocumentDataCheckerActionsEnum }[]) {
    handleDocumentDataCheckerAction(
      actions,
      this.actionsConfig,
      this.router,
      this.store,
      this.loadableService,
      this.injector,
      this.matSnackBar,
      this.translocoService
    );
  }
}
