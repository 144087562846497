import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { ngfModule } from 'angular-file';
import { AssociationHeadersStepComponent } from './step/association-headers-step/association-headers-step.component';
import { FinishStepComponent } from './step/finish-step/finish-step.component';
import { UploadPanelStepComponent } from './step/upload-panel-step/upload-panel-step.component';
import { UploadStepperComponent } from './upload-stepper/upload-stepper.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ValidatingModule } from '@roadrecord/validating';
import {
  IsEmptyStringPipeModule,
  IsNilPipeModule,
  IsPresentPipeModule,
  PreventClickModule,
  SanitizePipeModule,
} from '@roadrecord/common/common';
import { TranslocoUtilsDirectiveModule } from '@roadrecord/transloco-utils';
import { SelectSourceComponent } from './step/select-source/select-source.component';
import { AcceptedFileTypePipe } from './step/upload-panel-step/accepted-file-type.pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatGridListModule } from '@angular/material/grid-list';

@NgModule({
  declarations: [
    UploadStepperComponent,
    UploadPanelStepComponent,
    AssociationHeadersStepComponent,
    FinishStepComponent,
    SelectSourceComponent,
    AcceptedFileTypePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatStepperModule,
    MatCardModule,
    TranslocoModule,
    MatIconModule,
    ReactiveFormsModule,
    ngfModule,
    MatButtonModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSnackBarModule,
    FlexLayoutModule,
    ValidatingModule,
    MatButtonToggleModule,
    MatGridListModule,
    IsPresentPipeModule,
    IsEmptyStringPipeModule,
    PreventClickModule,
    IsNilPipeModule,
    SanitizePipeModule,
    TranslocoUtilsDirectiveModule,
  ],
  exports: [UploadStepperComponent],
})
export class UploadStepperModule {}
