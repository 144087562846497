import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';
import { isBoolean, isString } from '@roadrecord/type-guard';
import { timer } from 'rxjs';
import { NoopFunctionType } from '../noop/noop-function.type';

const logScopeName = 'FocusInputDirective';
/**
 * # Ways to turn off autofocus: any js-falsely value, except empty string
 *
 *     <!-- with data binding -->
 *     <input [autofocus]=""> <!-- undefined value -->
 *     <input [autofocus]="undefined">
 *     <input [autofocus]="false">
 *     <input [autofocus]="null">
 *     <input [autofocus]="0">
 *
 *     <!-- without data binding -->
 *     <input autofocus="undefined">
 *     <input autofocus="false">
 *     <input autofocus="null">
 *     <input autofocus="0">
 *
 *     <input> <!-- disabled by default -->
 *
 *
 * # Ways to enable autofocus: any js-true value and empty string
 *
 *
 *     <!-- empty string will enable autofocus, this is default html behavior -->
 *     <input [autofocus]="''">
 *     <input autofocus="">
 *     <input autofocus>
 *
 *     <input [autofocus]="true">
 *     <input autofocus="true">
 *
 *     <input [autofocus]="'any other values'">
 *
 */
@Directive({
  selector: '[rrFocusInput]',
  exportAs: 'rrFocusInput',
})
export class FocusInputDirective implements AfterContentInit {
  @Input() rrFocusDelay = 0;
  private _autofocus;

  constructor(private readonly el: ElementRef) {}

  @Input()
  set rrFocusInput(value: any) {
    if (isBoolean(value)) {
      this._autofocus = isString(value) ? value === 'true' : value;
    } else {
      this._autofocus = false;
    }
    /*this._autofocus = value !== false
            && value !== null
            && value !== undefined
            && value !== 0
            && value !== 'false'
            && value !== 'null'
            && value !== 'undefined'
            && value !== '0'*/
  }

  ngAfterContentInit(): void {
    if (this._autofocus) {
      const el: HTMLInputElement = this.el.nativeElement;

      if (el.focus) {
        // ha van focus metodusa
        this.focusElement(this.rrFocusDelay);
      } else {
        console.warn(logScopeName, 'AutofocusDirective: There is no .focus() method on the element:', this.el.nativeElement);
      }
    }
  }

  focusElement(delay = 0, callback?: NoopFunctionType): void {
    const el: HTMLInputElement = this.el.nativeElement;

    if (delay > 0) {
      timer(delay).subscribe(() => {
        el.focus();
        if (callback !== undefined) {
          callback();
        }
      });
    } else {
      el.focus();
    }
  }

  blurElement(delay = 0, callback?: NoopFunctionType): void {
    const el: HTMLInputElement = this.el.nativeElement;

    if (delay > 0) {
      timer(delay).subscribe(() => {
        el.blur();
        if (callback !== undefined) {
          callback();
        }
      });
    } else {
      el.blur();
    }
  }
}
