import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { RRCalendarModule } from '@roadrecord/calendar';
import { SumPipeModule } from '@roadrecord/common/common';
import { FragmentPresenterModule } from '@roadrecord/fragment-dialog';
import {
  ModalWrapperModule as PartnerModalWrapperModule,
  PARTNER_EDIT_FRAGMENT_PRESENTERS,
  PARTNER_NEW_FRAGMENT_PRESENTERS,
} from '@roadrecord/partner/common';
import { DetailsModule } from '../details/details.module';
import { CalendarComponent } from './calendar.component';
import { AgendaComponent } from './agenda/agenda.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    RRCalendarModule,
    DetailsModule,
    SumPipeModule,
    TranslocoModule,
    FlexLayoutModule,
    PartnerModalWrapperModule, // details module-ba tartozik csak onnan a forFeature nem indult el
    FragmentPresenterModule.forFeature([...PARTNER_NEW_FRAGMENT_PRESENTERS, ...PARTNER_EDIT_FRAGMENT_PRESENTERS]),
    MatTooltipModule,
  ],
  declarations: [CalendarComponent, AgendaComponent],
  exports: [CalendarComponent],
})
export class CalendarModule {}
