import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'featureTextFormatPipe',
})
export class FeatureTextFormatPipe implements PipeTransform {
  transform(text: string): string {
    return text
      .replace('<span class="plus">+</span>', '<span style="color:#ff645f !important;padding:1px;">+</span>')
      .replace('+', '<span style="color:#ff645f !important;padding:1px;">+</span>');
  }
}
