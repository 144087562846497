import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FocusInputDirective } from './focus-input.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [FocusInputDirective],
  exports: [FocusInputDirective],
})
export class FocusInputModule {}
