import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Injector, PLATFORM_ID, Renderer2 } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, Store } from '@ngxs/store';
import { BaseAppComponent } from '@roadrecord/app-base';
import { WINDOW } from '@roadrecord/common/common';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { SwUpdatesService } from '@roadrecord/service-worker';
import { AddSystemMessageAction, SystemMessagesWorkerBridgeService } from '@roadrecord/system-messages';
import { AuthState } from '@roadrecord/user/common';
import { delay, filter } from 'rxjs/operators';
import { isNil } from '@roadrecord/type-guard';
import { environment } from '@roadrecord/environment';

@Component({
  selector: 'rr-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends BaseAppComponent {
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    translocoService: TranslocoService,
    cdr: ChangeDetectorRef,
    store: Store,
    actions$: Actions,
    renderer: Renderer2,
    @Inject(DOCUMENT) document: Document,
    matSnackBar: MatSnackBar,
    @Inject(WINDOW) window: Window & { Cypress: any },
    swUpdatesService: SwUpdatesService,
    messageDialogService: MessageDialogService,
    router: Router,
    injector: Injector
  ) {
    super(
      'HuAppComponent',
      platformId,
      translocoService,
      cdr,
      store,
      actions$,
      renderer,
      document,
      matSnackBar,
      window,
      swUpdatesService,
      messageDialogService,
      router
    );

    if (!isNil(environment.fbConfig)) {
      store
        .select(AuthState.isLoggedIn)
        .pipe(
          filter(v => v === true),
          delay(3000)
        )
        .subscribe(async () => {
          await injector.get(SystemMessagesWorkerBridgeService).subscribeSystemMessages(msg => {
            if (Array.isArray(msg)) {
              msg.forEach(_msg => this.store.dispatch(new AddSystemMessageAction(_msg)));
            } else {
              this.store.dispatch(new AddSystemMessageAction(msg));
            }
          });

          // await systemMessagesWorkerBridgeService.subscribePrivateMessages(msg => {
          //   console.log(msg);
          // });
        });
    }

    // this.subscribeWSContinuesEvents();
  }

  // private subscribeWSContinuesEvents() {
  //   this.actions$
  //     .pipe(
  //       ofActionSuccessful(FixedDestinationImportStatusWSAction, FixedDestinationImportOkWSAction, FixedDestinationImportErrorWSAction),
  //       filter(() => this.store.selectSnapshot(AuthState.isLoggedIn))
  //     )
  //     .subscribe((action: WSEvent<any>) => {
  //       switch (action.type) {
  //         case WSEventEnum.FixedDestinationImportStatus:
  //           this.matSnackBar.open(
  //             this.translateService.translate('FIXED_DESTINATION_IMPORT.SOCKET_EVENTS.STATUS_SNACKBAR', {
  //               status: JSON.stringify(action.data)
  //             })
  //           );
  //           break;
  //         case WSEventEnum.FixedDestinationImport_Ok:
  //           this.messageDialogService.openInformation({
  //             id: null,
  //             title: this.translateService.translate('FIXED_DESTINATION_IMPORT.SOCKET_EVENTS.OK_DIALOG.TITLE'),
  //             text: this.translateService.translate('FIXED_DESTINATION_IMPORT.SOCKET_EVENTS.OK_DIALOG.TEXT')
  //           });
  //           break;
  //         case WSEventEnum.FixedDestinationImport_Error:
  //           handleErrorObject(action.data);
  //           break;
  //       }
  //     });
  // }
}
