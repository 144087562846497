import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, forwardRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { mismatchedPassword, mismatchedRePassword } from '@roadrecord/common/common';
import {
  commonHttpStreamErrorHandler,
  ENTITY_SERVICE_TOKEN,
  focusElement2,
  PRESENTER_PLUGINS,
  PRESENTER_STATE_CONTROLLER_PROVIDER,
  PresenterStateController,
  VIEW_COMPONENT,
  VIEW_REMOTE_FIELD_ERROR_PLUGIN_PROVIDER,
  ViewRemoteErrorsModel,
  ViewRemoteFieldErrorPlugin,
} from '@roadrecord/utils';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { rrFormErrorStateMatcher, ValidationMessageModel } from '@roadrecord/validating';
import { PasswordValidators, UniversalValidators } from 'ngx-validators';
import { AuthService } from '../../auth.service';
import { UserModel } from '../../model/user.model';

@Component({
  selector: 'rr-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [
    rrFormErrorStateMatcher,
    { provide: ENTITY_SERVICE_TOKEN, useExisting: AuthService },
    { provide: VIEW_COMPONENT, useValue: forwardRef(() => ChangePasswordComponent) },
    {
      provide: PRESENTER_PLUGINS,
      useValue: [VIEW_REMOTE_FIELD_ERROR_PLUGIN_PROVIDER],
    },
    PRESENTER_STATE_CONTROLLER_PROVIDER,
  ],
})
export class ChangePasswordComponent implements OnInit {
  oldPasswordControl: FormControl;
  newPasswordControl: FormControl;
  confirmNewPasswordControl: FormControl;
  form: FormGroup & { submitted?: boolean };
  visibilityTypePassword = 'password';
  visiblePassword = false;
  errorMessages: ValidationMessageModel[];
  private readonly viewRemoteFieldErrorPlugin: ViewRemoteFieldErrorPlugin<any, any>;

  constructor(
    private router: Router,
    private authService: AuthService<UserModel>,
    private translocoService: TranslocoService,
    private matSnackBar: MatSnackBar,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    presenterStateController: PresenterStateController<any, any>,
    @Inject(DOCUMENT) private document: Document,
    private messageDialogService: MessageDialogService
  ) {
    this.viewRemoteFieldErrorPlugin = presenterStateController.get(ViewRemoteFieldErrorPlugin);
    this.createErrorMessages();
  }

  set remoteErrors(errors: ViewRemoteErrorsModel) {
    if (this.viewRemoteFieldErrorPlugin !== undefined) {
      this.viewRemoteFieldErrorPlugin.setRemoteErrors(errors);
    }
  }

  ngOnInit(): void {
    // FIXME: javítani, hogy a focus az első mezőben megjelenjen init után
    this.createForm();
  }

  changeVisibilityPassword(): void {
    this.visiblePassword = !this.visiblePassword;
    !this.visiblePassword ? (this.visibilityTypePassword = 'password') : (this.visibilityTypePassword = 'text');
  }

  save(): void {
    this.form.submitted = true;
    if (this.form.valid) {
      this.form.disable();
      this.authService
        .changePassword(this.oldPasswordControl.value, this.newPasswordControl.value, this.confirmNewPasswordControl.value)
        // tslint:disable-next-line:max-line-length
        .subscribe(
          this.changePasswordSucceed.bind(this),
          commonHttpStreamErrorHandler(() => this.form.enable())
        );
    } else {
      this.cdr.markForCheck();
    }
  }

  private createErrorMessages(): void {
    this.errorMessages = [
      {
        errorKey: 'uppercaseCharacterRule',
        translateKey: 'COMMON.VALIDATION.PASSWORD_PATTERN',
      },
      {
        errorKey: 'digitCharacterRule',
        translateKey: 'COMMON.VALIDATION.PASSWORD_PATTERN',
      },
      {
        errorKey: 'lowercaseCharacterRule',
        translateKey: 'COMMON.VALIDATION.PASSWORD_PATTERN',
      },
      {
        errorKey: 'noWhitespaceRequired',
        translateKey: 'COMMON.VALIDATION.NO_WHITESPACE',
      },
      {
        errorKey: 'mismatchedPasswords',
        translateKey: 'COMMON.VALIDATION.MISMATCHED_PASSWORDS',
      },
    ];
  }

  private changePasswordSucceed(): void {
    this.matSnackBar.open(this.translocoService.translate('COMMON.GENERAL_UPDATE_SUCCESS'));
    this.form.submitted = false;
    this.form.reset({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
    this.form.enable();
    this.messageDialogService
      .open({
        id: null,
        text: 'COMMON.GENERAL_UPDATE_SUCCESS',
        confirmLabel: 'COMMON.ACTION.CLOSE',
      })
      .afterClosed()
      .subscribe(() => focusElement2(this.document, 'oldPassword'));
  }

  private createForm(): void {
    this.oldPasswordControl = new FormControl('');
    this.newPasswordControl = new FormControl('');
    this.confirmNewPasswordControl = new FormControl('');

    const passwordFieldValidator = Validators.compose([
      Validators.required,
      PasswordValidators.digitCharacterRule(1),
      PasswordValidators.lowercaseCharacterRule(1),
      PasswordValidators.uppercaseCharacterRule(1),
      UniversalValidators.noWhitespace,
      Validators.minLength(8),
      Validators.maxLength(20),
      mismatchedRePassword(this.confirmNewPasswordControl),
    ]);
    this.newPasswordControl.setValidators(passwordFieldValidator);
    this.confirmNewPasswordControl.setValidators(Validators.compose([Validators.required, mismatchedPassword(this.newPasswordControl)]));

    this.form = this.fb.group({
      oldPassword: this.oldPasswordControl,
      newPassword: this.newPasswordControl,
      confirmNewPassword: this.confirmNewPasswordControl,
    });
  }
}
