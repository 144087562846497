import { Injectable, Injector, NgZone } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { GetUserStatesSuccessAction, UserStateKeyRefreshAction } from '@roadrecord/common/common';
import { STATE_PREFIX_TOKEN } from '@roadrecord/company-context/common';
import { Router } from '@angular/router';
import { PeriodContextStateHuModel } from './model/period-context-state-hu.model';
import {
  detectMenuRefreshRemoteSuccessActionFn,
  detectUserStateKeyRefreshActionFn,
  detectUserStateSuccessActionFn,
  PERIOD_CONTEXT_STATE_NAME,
  periodContextRefreshActionFn,
  PeriodContextRefreshRemoteStateAction,
  PeriodContextRefreshRemoteStateErrorAction,
  periodContextRefreshRemoteStateErrorActionFn,
  PeriodContextRefreshRemoteStateSuccessAction,
  periodContextRefreshSuccessActionFn,
  PeriodContextRemoveVehicleAction,
  periodContextRemoveVehicleActionFn,
  PeriodContextService,
  PeriodContextStartVehiclePullAction,
  periodContextStartVehiclePullActionFn,
  PeriodContextStopVehiclePullAction,
  periodContextStopVehiclePullActionFn,
} from '@roadrecord/period-context/common';
import { PeriodContextHuModel } from './model/period-context-hu.model';
import { attachAction } from '@ngxs-labs/attach-action';

/**
 * Kotelezo a menu state elott betoltodnie, az invalid_period_context kiertekelese miatt!
 */
@State<PeriodContextStateHuModel>({
  name: PERIOD_CONTEXT_STATE_NAME,
  defaults: { runVehiclePull: false, notInited: true, version: 3 } as any,
})
@Injectable()
export class PeriodContextHuState {
  constructor(injector: Injector, ngZone: NgZone, router: Router, store: Store) {
    const prefix = injector.get<string>(STATE_PREFIX_TOKEN);
    const periodContextService = injector.get(PeriodContextService);

    attachAction(
      PeriodContextHuState,
      PeriodContextRefreshRemoteStateAction,
      periodContextRefreshActionFn<PeriodContextHuModel, PeriodContextStateHuModel>(periodContextService)
    );

    attachAction<PeriodContextStateHuModel, PeriodContextRefreshRemoteStateSuccessAction>(
      PeriodContextHuState,
      PeriodContextRefreshRemoteStateSuccessAction,
      periodContextRefreshSuccessActionFn
    );

    attachAction<PeriodContextStateHuModel, PeriodContextRefreshRemoteStateErrorAction>(
      PeriodContextHuState,
      PeriodContextRefreshRemoteStateErrorAction,
      periodContextRefreshRemoteStateErrorActionFn
    );

    /**
     * a menu -bol szuksegunk van az invalid period context allapot detect-re
     */
    attachAction(
      PeriodContextHuState,
      // hack circular dep miatt
      { type: '[Menu] Refresh remote state Success' },
      detectMenuRefreshRemoteSuccessActionFn(ngZone, router, store, prefix)
    );

    attachAction(PeriodContextHuState, GetUserStatesSuccessAction, detectUserStateSuccessActionFn(ngZone, router, store, prefix));

    attachAction(PeriodContextHuState, UserStateKeyRefreshAction, detectUserStateKeyRefreshActionFn(prefix));

    attachAction<PeriodContextStateHuModel, PeriodContextRemoveVehicleAction>(
      PeriodContextHuState,
      PeriodContextRemoveVehicleAction,
      periodContextRemoveVehicleActionFn
    );
    attachAction<PeriodContextStateHuModel, PeriodContextStartVehiclePullAction>(
      PeriodContextHuState,
      PeriodContextStartVehiclePullAction,
      periodContextStartVehiclePullActionFn
    );
    attachAction<PeriodContextStateHuModel, PeriodContextStopVehiclePullAction>(
      PeriodContextHuState,
      PeriodContextStopVehiclePullAction,
      periodContextStopVehiclePullActionFn
    );
  }
}
