/**
 * vehicle acocunt -nal hasznaljuk hogy ideiglenesen eltaroljuk a form allapotat
 */
import { VehicleModel } from '@roadrecord/vehicle/model/common';

export class VehicleDataStore<MODEL extends VehicleModel> {
  private static _INSTANCE: VehicleDataStore<any>;
  private _model: MODEL;
  private _modifyAccounts: { account_id: number; status: number }[];

  private constructor() {}

  static INSTANCE() {
    if (VehicleDataStore._INSTANCE === undefined) {
      VehicleDataStore._INSTANCE = new VehicleDataStore();
    }
    return VehicleDataStore._INSTANCE;
  }

  storeModel(model: MODEL) {
    this._model = model;
  }

  storeModifyAccounts(modifyAccounts: { account_id: number; status: number }[]) {
    this._modifyAccounts = modifyAccounts;
  }

  get model() {
    return this._model;
  }

  reset() {
    delete this._model;
    delete this._modifyAccounts;
  }

  get modifyAccounts(): { account_id: number; status: number }[] {
    return this._modifyAccounts;
  }
}
