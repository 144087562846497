import { DecimalPipe, Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { proxy } from '@roadrecord/comlink';
import { WINDOW } from '@roadrecord/common/common';
import { HandleErrorObject, handleHttpError, MaybeHandleHttpError, RRHttpErrorResponse, throwHttpError } from '@roadrecord/utils';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { AuthState, LogoutWithoutCallEndpointAction } from '@roadrecord/user/common';
import { UniversalImportAssociatedHeadersHeaderModel, UniversalImportSendDataEnum } from '@roadrecord/worker/universal-import';
import {
  DataValidationError,
  FileParseError,
  FileSizeError,
  FileTypeError,
  PeriodChangeMayChangeError,
  ServerError,
  ZipContentError,
} from '@roadrecord/worker/shared';
import moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subscription } from 'rxjs';
import { filter, shareReplay, skip, switchMap } from 'rxjs/operators';
import { StepControl } from './step-control';
import { createForm } from './upload-stepper.form';
import { UniversalImportHeadersModel } from '../../model/universal-import-headers.model';
import {
  PeriodContextRefreshRemoteStateAction,
  PeriodContextService,
  PeriodContextStateSelectorsService,
} from '@roadrecord/period-context/common';
import { isNil, isNumeric } from '@roadrecord/type-guard';
import { UniversalImportWorkerBridgeService } from '../../universal-import-worker-bridge.service';
import { UNIVERSAL_IMPORT_CONFIG, UniversalImportButtonConfig, UniversalImportConfig } from '../../model/universal-import-config';
import { UniversalImportService } from '../../universal-import.service';
import { translateFn, TranslocoDirective } from '@roadrecord/transloco-utils';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AppTypeEnum, environment } from '@roadrecord/environment';

const logScopeName = 'UploadStepperComponent';

@UntilDestroy()
@Component({
  selector: 'rr-upload-stepper',
  templateUrl: './upload-stepper.component.html',
  styleUrls: ['./upload-stepper.component.scss'],
})
export class UploadStepperComponent implements OnInit, OnDestroy {
  loading = false;
  uploadPanelForm = createForm();
  uploadPanelFileControl = this.uploadPanelForm.get('file');
  uploadPanelStartedControl = this.uploadPanelForm.get('started');
  @ViewChildren('horizontalStepper') private horizontalStepper: QueryList<MatStepper>;
  step: 'ASSOCIATION_HEADER' | 'DONE';
  userHeaders: string[];
  requireHeaders$: Observable<UniversalImportHeadersModel>;
  associatedStepControl = new StepControl();
  selectSourceForm = new StepControl();
  private uploadPanelStartedControlValueChangesSubscription: Subscription;
  submitHeaderError: { hasError: boolean };
  selectedSourceType: UniversalImportButtonConfig<any>;
  private skipAssociatedHeadersStep = false;
  private fileUploadTranslatePrefix = 'FILE_UPLOAD.';
  private associatedHeadersSubmitTranslatePrefix = 'ASSOCIATION_HEADERS.SUBMIT.';
  @ViewChild(TranslocoDirective, { static: true }) private translocoDirective: TranslocoDirective;
  private translateFn: translateFn;

  constructor(
    readonly importWorkerService: UniversalImportWorkerBridgeService,
    private messageDialogService: MessageDialogService,
    private importService: UniversalImportService<any>,
    private matSnackBar: MatSnackBar,
    @Inject(WINDOW) private window: Window,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private gtmService: GoogleTagManagerService,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    @Inject(UNIVERSAL_IMPORT_CONFIG) readonly importConfig: UniversalImportConfig<any>,
    private translocoService: TranslocoService,
    private periodContextService: PeriodContextService<any, any>,
    private location: Location
  ) {
    this.importWorkerService.initWorker().then(() => {});
  }

  ngOnInit() {
    this.translateFn = this.translocoDirective.getTranslateFn();

    this.gtmService.pushTag({
      event: 'Open upload',
      category: this.importConfig.gaCategory,
    });
    this.subscribeUploadPanelStart();
    this.detectPeriodContextChangeAndRefreshWorkerData();
  }

  private detectPeriodContextChangeAndRefreshWorkerData() {
    this.store
      .select(this.periodContextStateSelectorsService.viewDate)
      .pipe(untilDestroyed(this), skip(1))
      .subscribe(async viewDate => {
        await this.importWorkerService.setCurrentYearMonth(viewDate);
        if (this.step === 'ASSOCIATION_HEADER') {
          await this.importWorkerService.setFile(
            this.uploadPanelFileControl.value,
            this.importConfig.getHeaderRowIndex(this.selectedSourceType.type)
          );
        }
      });
  }

  async onBackToFileUpload() {
    this.step = undefined;
    this.userHeaders = undefined;
    this.associatedStepControl._isValid = false;
    this.uploadPanelFileControl.patchValue(undefined);
    this.uploadPanelStartedControl.patchValue(false);
    (this.horizontalStepper.first as any)._updateSelectedItemIndex(1);
    this.gtmService.pushTag({
      event: 'Back to upload panel',
      category: this.importConfig.gaCategory,
    });
    await this.importWorkerService.reset();
    if (
      !isNil(this.uploadPanelStartedControlValueChangesSubscription) &&
      this.uploadPanelStartedControlValueChangesSubscription.closed === false
    ) {
      this.uploadPanelStartedControlValueChangesSubscription.unsubscribe();
    }
    this.subscribeUploadPanelStart();
  }

  /**
   * feltoltes panel submit detect
   */
  private subscribeUploadPanelStart() {
    this.uploadPanelStartedControlValueChangesSubscription = this.uploadPanelStartedControl.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(v => v === true),
        switchMap(() => {
          this.requireHeaders$ = this.importService.getRequireHeaders(this.selectedSourceType.type).pipe(shareReplay(1));
          return this.requireHeaders$;
        })
      )
      .subscribe(
        async () => {
          // feltoltes panel submit
          try {
            await this.importWorkerService.setMaxFileSize(this.selectedSourceType.maxFileSize);

            await this.importWorkerService.setFile(
              this.uploadPanelFileControl.value,
              this.importConfig.getHeaderRowIndex(this.selectedSourceType.type)
            );

            // Timeline esetén a JSON fájl tömörítése és a tömörített fájl feltöltése
            if (this.selectedSourceType.controllerType === 'GOOGLE_TIMELINE_JSON') {
              const compressedUploadUrl = `${environment.apiUrl}timeline-file/upload/`;
              const token = this.store.selectSnapshot(AuthState.token);
              this.importWorkerService.uploadCompressFile(compressedUploadUrl, token).then(response => {
                console.log(response);
              });
            }

            this.userHeaders = await this.importWorkerService.userFileHeaders;

            const requireHeaders = await this.requireHeaders$.toPromise();
            const isJSON = await this.importWorkerService.isJSON;
            const countNotEqualHeaders = isJSON
              ? []
              : requireHeaders.headers.filter(requireHeader =>
                  // egyezo kivalasztasa
                  isNil(this.userHeaders.find(userHeader => requireHeader.name === userHeader))
                );

            if (countNotEqualHeaders.length === 0) {
              this.skipAssociatedHeadersStep = true;
              // megvan minden oszlop egyezoseg, tovabb ugrunk
              const importAssociatedHeadersHeaderModels = requireHeaders.headers.map(
                requireHeader =>
                  ({ userHeader: requireHeader.name, requiredHeader: requireHeader } as UniversalImportAssociatedHeadersHeaderModel)
              );
              this.horizontalStepper.first.next();
              await this.onSubmitHeaders(importAssociatedHeadersHeaderModels);
              return;
            }

            this.step = 'ASSOCIATION_HEADER';
            this.horizontalStepper.first.next();
            this.uploadPanelStartedControlValueChangesSubscription.unsubscribe();

            this.gtmService.pushTag({
              event: `Added file, type is csv: ${await this.importWorkerService.isCSV}, rows count: ${
                (await this.importWorkerService.rowsLength).length
              }, file size: ${(this.uploadPanelFileControl.value as File).size / 1024} kbyte`,
              category: this.importConfig.gaCategory,
            });
          } catch (e) {
            const locale = environment.languages.defaultLang.split('-')[0];

            if (e instanceof FileSizeError) {
              this.gtmService.pushTag({
                event: `File size error`,
                category: this.importConfig.gaCategory,
              });
              this.messageDialogService.openWarning({
                id: null,
                title: this.translateFn(`${this.fileUploadTranslatePrefix}SUBMIT.ERROR_DIALOGS.SIZE.TITLE`),
                text: this.translateFn(`${this.fileUploadTranslatePrefix}SUBMIT.ERROR_DIALOGS.SIZE.TEXT`, {
                  allowedSize: new DecimalPipe(locale).transform(this.importWorkerService.allowedSize),
                  userFileSize: new DecimalPipe(locale).transform((this.uploadPanelFileControl.value as File).size / 1024),
                }),
                translateText: false,
              });
            } else if (e instanceof FileTypeError) {
              this.gtmService.pushTag({
                event: `File type error`,
                category: this.importConfig.gaCategory,
              });
              this.messageDialogService.openWarning({
                id: null,
                title: this.translateFn(`${this.fileUploadTranslatePrefix}SUBMIT.ERROR_DIALOGS.TYPE.TITLE`),
                text: this.translateFn(`${this.fileUploadTranslatePrefix}SUBMIT.ERROR_DIALOGS.TYPE.TEXT`, {
                  allowedSize: new DecimalPipe(locale).transform(this.importWorkerService.allowedSize),
                  userFileSize: new DecimalPipe(locale).transform((this.uploadPanelFileControl.value as File).size / 1024),
                }),
                translateText: false,
              });
            } else if (e instanceof FileParseError) {
              this.gtmService.pushTag({
                event: `File parse error`,
                category: this.importConfig.gaCategory,
              });
              const parseErrorTag = this.getParseErrorTagByFileType();
              this.messageDialogService.openWarning({
                id: null,
                title: this.translateFn(`${this.fileUploadTranslatePrefix}SUBMIT.ERROR_DIALOGS.PARSE.TITLE`),
                text: this.translateFn(`${this.fileUploadTranslatePrefix}SUBMIT.ERROR_DIALOGS.PARSE.${parseErrorTag}.TEXT`),
                translateText: false,
              });
            } else if (e instanceof ZipContentError) {
              this.messageDialogService.openWarning({
                id: null,
                title: this.translateFn(`${this.fileUploadTranslatePrefix}SUBMIT.ERROR_DIALOGS.ZIP.TITLE`),
                text: this.translateFn(`${this.fileUploadTranslatePrefix}SUBMIT.ERROR_DIALOGS.ZIP.TEXT`),
                translateText: false,
              });
            } else {
              this.gtmService.pushTag({
                event: `Unknown error: ${e}`,
                category: this.importConfig.gaCategory,
              });

              console.error(logScopeName, e);
              this.messageDialogService.openError({
                id: null,
                title: this.translateFn(`${this.fileUploadTranslatePrefix}SUBMIT.ERROR_DIALOGS.UNKNOWN.TITLE`),
                text: this.translateFn(`${this.fileUploadTranslatePrefix}SUBMIT.ERROR_DIALOGS.UNKNOWN.TEXT`),
                htmlMode: true,
                translateText: false,
              });
            }
            this.uploadPanelForm.reset({ file: null, started: false });
          } finally {
            this.loading = false;
          }
        },
        error => {
          this.loading = false;
          this.uploadPanelStartedControl.patchValue(false);
          MaybeHandleHttpError.maybeHandleHttpError(error);
          Promise.resolve().then(() => this.subscribeUploadPanelStart());
        }
      );
  }

  onLoading($event: boolean) {
    this.loading = $event;
  }

  private importSendDataCb(x: UniversalImportSendDataEnum) {
    if (x === UniversalImportSendDataEnum.START_VALIDATION) {
      this.matSnackBar.open(this.translateFn(`${this.associatedHeadersSubmitTranslatePrefix}SNACKBAR.START_VALIDATION`), undefined, {
        duration: 999999999,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    } else if (x === UniversalImportSendDataEnum.START_SEND_DATA) {
      this.matSnackBar.open(this.translateFn(`${this.associatedHeadersSubmitTranslatePrefix}SNACKBAR.START_SEND_DATA`), undefined, {
        duration: 999999999,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    }
  }

  /**
   * associate header submit
   */
  async onSubmitHeaders(headers: UniversalImportAssociatedHeadersHeaderModel[]) {
    this.gtmService.pushTag({
      event: `Set associated headers`,
      category: this.importConfig.gaCategory,
    });

    this.loading = true;
    const requireHeaders = await this.requireHeaders$.toPromise();

    try {
      await this.importService.sendData(
        { headers, options: requireHeaders.options },
        proxy(this.importSendDataCb.bind(this)),
        this.selectedSourceType.type,
        {
          removeFirstRowsNumber: this.importConfig.removeFirstRowsNumber(this.selectedSourceType.type),
        }
      );

      this.gtmService.pushTag({
        event: `Sent datas without error`,
        category: this.importConfig.gaCategory,
      });

      // Sikeres ha itt vagyunk
      this.matSnackBar.dismiss();
      this.loading = false;
      this.step = 'DONE';
      this.associatedStepControl._isValid = true;
      // Ugras az utolso oldalra
      this.horizontalStepper.first.next();
    } catch (e) {
      this.submitHeaderError = { hasError: true };
      this.loading = false;
      this.matSnackBar.dismiss();

      if (e instanceof DataValidationError) {
        let message = e.message;
        let messageParams: { [key: string]: any };
        this.gtmService.pushTag({
          event: `Validation error: ${message}`,
          category: this.importConfig.gaCategory,
        });

        if (['REQUIRED', 'IS_NUMERIC', 'MIN_LENGTH', 'MAX_LENGTH', 'MIN_VALUE', 'MAX_VALUE', 'DATE'].indexOf(message) > -1) {
          message = `${this.associatedHeadersSubmitTranslatePrefix}ERROR_DIALOGS.VALIDATION.MESSAGE.${message}`;
          if (!isNil(e.extraMessageParam)) {
            const decimalPipe = new DecimalPipe(this.translocoService.getActiveLang());
            messageParams = Object.entries(e.extraMessageParam).reduce((curr, next) => {
              curr[next[0]] = isNumeric(next[1]) ? decimalPipe.transform(next[1]) : next[1];
              return curr;
            }, {});
          }
        }
        this.messageDialogService.openWarning({
          id: null,
          title: this.translateFn(`${this.associatedHeadersSubmitTranslatePrefix}ERROR_DIALOGS.VALIDATION.DIALOG.TITLE`),
          text: this.translateFn(`${this.associatedHeadersSubmitTranslatePrefix}ERROR_DIALOGS.VALIDATION.DIALOG.TEXT`, {
            columnName: e.columnName,
            rowIndex: e.rowIndex + 1,
            message: this.translateFn(message, messageParams),
          }),
          translateText: false,
          htmlMode: true,
        });
      } else if (e instanceof PeriodChangeMayChangeError) {
        const foundPeriodContext = e.rawResponse.foundPeriodContext;

        const getPeriodContextYearMonthDate = () =>
          moment({ year: foundPeriodContext.year, month: foundPeriodContext.month }).format(
            environment.appType === AppTypeEnum.HU ? 'YYYY.MMMM' : 'MMMM, YYYY'
          );

        const selectedFiled = this.uploadPanelFileControl.value;

        this.messageDialogService
          .openInformation({
            id: null,
            title: this.translateFn(`${this.associatedHeadersSubmitTranslatePrefix}ERROR_DIALOGS.MAY_PERIOD_CHANGE.TITLE`),
            text: this.translateFn(`${this.associatedHeadersSubmitTranslatePrefix}ERROR_DIALOGS.MAY_PERIOD_CHANGE.TEXT`, {
              period: getPeriodContextYearMonthDate(),
            }),
            htmlMode: true,
            enableOk: true,
            enableCancel: true,
            translateText: false,
            confirmLabel: this.translateFn(`${this.associatedHeadersSubmitTranslatePrefix}ERROR_DIALOGS.MAY_PERIOD_CHANGE.BUTTON`, {
              period: getPeriodContextYearMonthDate(),
            }),
          })
          .afterClosed()
          .subscribe(result => {
            if (result.result) {
              const periodContext = this.store.selectSnapshot(this.periodContextStateSelectorsService.context);

              this.periodContextService
                .update(undefined, {
                  year: foundPeriodContext.year,
                  month: foundPeriodContext.month + 1,
                  vehicle: periodContext.vehicle.id,
                })
                .subscribe(() => {
                  this.store.dispatch(new PeriodContextRefreshRemoteStateAction()).subscribe(async () => {
                    this.uploadPanelStartedControl.patchValue(true);
                    this.uploadPanelFileControl.patchValue(selectedFiled);
                  });
                });
            }
          });
      } else if (e instanceof ServerError) {
        const getPeriodContextYearMonthDate = () =>
          moment(this.store.selectSnapshot(this.periodContextStateSelectorsService.viewDate)).format(
            environment.appType === AppTypeEnum.HU ? 'YYYY.MM' : 'MMMM, YYYY'
          );

        const extraErrorTypes = {
          TO_MANY_ROWS: {
            lazyParams: () => ({
              title: this.translateFn(`${this.associatedHeadersSubmitTranslatePrefix}ERROR_DIALOGS.TO_MANY_ROWS.TITLE`),
              text: this.translateFn(`${this.associatedHeadersSubmitTranslatePrefix}ERROR_DIALOGS.TO_MANY_ROWS.TEXT`, {
                allowRowCount: this.importWorkerService.allowedRowCount,
                selectedPeriodYearMonth: getPeriodContextYearMonthDate(),
              }),
              translateText: false,
              htmlMode: true,
            }),
          },
          NOT_FOUND_ROWS: {
            lazyParams: () => ({
              title: this.translateFn(`${this.associatedHeadersSubmitTranslatePrefix}ERROR_DIALOGS.NOT_FOUND_ROWS.TITLE`),
              text: this.translateFn(`${this.associatedHeadersSubmitTranslatePrefix}ERROR_DIALOGS.NOT_FOUND_ROWS.TEXT`, {
                selectedPeriodYearMonth: getPeriodContextYearMonthDate(),
              }),
              translateText: false,
              htmlMode: true,
            }),
          },
          GOOGLE_TIMELINE_JSON_EMPTY_ACTIVITY_SEGMENTS: {
            lazyParams: () => ({
              title: this.translateFn(
                `${this.associatedHeadersSubmitTranslatePrefix}ERROR_DIALOGS.GOOGLE_TIMELINE_JSON_EMPTY_ACTIVITY_SEGMENTS.TITLE`
              ),
              text: this.translateFn(
                `${this.associatedHeadersSubmitTranslatePrefix}ERROR_DIALOGS.GOOGLE_TIMELINE_JSON_EMPTY_ACTIVITY_SEGMENTS.TEXT`,
                {
                  selectedPeriodYearMonth: getPeriodContextYearMonthDate(),
                }
              ),
              translateText: false,
            }),
          },
        };

        if (Object.keys(extraErrorTypes).indexOf(e.error) > -1) {
          this.messageDialogService.openError({
            id: null,
            ...extraErrorTypes[e.error as string].lazyParams(),
          });

          this.gtmService.pushTag({
            event: `Invalid file: ${e.error}`,
            category: this.importConfig.gaCategory,
          });
        } else {
          const error = throwHttpError(e.rawResponse);
          this.gtmService.pushTag({
            event: `Sent datas with error: ${
              (error as any).error.title !== undefined
                ? (error as any).title
                : `UNKNOWN ERROR, STATUS CODE : ${(error as any).status ? (error as any).status : 'NOT FOUND STATUS CODE'}`
            }`,
            category: this.importConfig.gaCategory,
          });
          handleHttpError(
            error,
            this.window,
            this.translocoService,
            this.messageDialogService,
            this.router,
            (__error: RRHttpErrorResponse, translocoService: TranslocoService) =>
              HandleErrorObject.handleErrorObject(__error).subscribe(() => {
                if (__error.status === 405) {
                  return this.store.dispatch(new LogoutWithoutCallEndpointAction());
                }
              })
          );
        }
      } else {
        this.gtmService.pushTag({
          event: `Unknow program error: ${e.message}`,
          category: this.importConfig.gaCategory,
        });
        // Ismeretlen programhiba
        console.error(logScopeName, e);
        this.messageDialogService.openError({
          id: null,
          title: this.translateFn(`${this.associatedHeadersSubmitTranslatePrefix}ERROR_DIALOGS.UNKNOWN.TITLE`),
          text: this.translateFn(`${this.associatedHeadersSubmitTranslatePrefix}ERROR_DIALOGS.UNKNOWN.TEXT`),
          translateText: false,
          htmlMode: true,
        });
      }

      if (this.skipAssociatedHeadersStep === true) {
        this.skipAssociatedHeadersStep = false;
        await this.onBackToFileUpload();
      }
    }
  }

  ngOnDestroy(): void {
    this.importWorkerService.ngOnDestroy();
  }

  async onChangeSourceType($event: UniversalImportButtonConfig<any>) {
    this.selectedSourceType = $event;
    await this.importWorkerService.setControllerType($event.controllerType);
  }

  onClickStopProcess() {
    this.location.back();
  }

  onFinishSelectSource() {
    this.selectSourceForm._isValid = true;
    this.horizontalStepper.first.next();
  }

  onBackToSelectSource() {
    this.selectSourceForm._isValid = false;
    (this.horizontalStepper.first as any)._updateSelectedItemIndex(0);
    this.gtmService.pushTag({
      event: 'Back to select source panel',
      category: this.importConfig.gaCategory,
    });
  }
  private getParseErrorTagByFileType(): string {
    const fileType = this.selectedSourceType.acceptedFileTypes;
    if (fileType.length === 1) {
      return fileType[0];
    } else {
      return fileType.indexOf('CSV') > -1 ? 'EXCEL_OR_CSV' : 'JSON';
    }
  }
}
