import { handleFormControlsServerError } from '../../../../function/handle-form-controls-server-error.function';
import { BaseViewRemoteFieldErrorPlugin } from '../base-view-remote-field-error.plugin';

/**
 * Ezt specialis esetekben hasznaljuk
 * Altalaban olyankor amikor valamiert a presenter state controller-t nem lehet hasznalni
 * es kezzel kezeljuk a ViewRemoteError -okat
 *
 * @param responseError
 * @param viewRemoteFieldErrorController
 */
export function manualHandleFormControlsSetServerError(
  responseError: any,
  viewRemoteFieldErrorController: BaseViewRemoteFieldErrorPlugin<any, any>
) {
  handleFormControlsServerError(responseError, errors => viewRemoteFieldErrorController.setRemoteErrors(errors));
}

export function manualHandleFormControlsResetServerError(viewRemoteFieldErrorController: BaseViewRemoteFieldErrorPlugin<any, any>) {
  manualHandleFormControlsSetServerError({ error: { errors: [] } }, viewRemoteFieldErrorController);
}
