import { HasPresenterSavePluginController } from '../has-presenter-save-plugin.interface';
import { PresenterStateController } from '../../../presenter-state/presenter-state.controller';
import { FormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';

export function isImplementedPresenterSavePluginController(obj: any): obj is HasPresenterSavePluginController<any, any> {
  return (
    obj.hasOwnProperty('dataForm') &&
    obj.hasOwnProperty('presenterStateController') &&
    obj['presenterStateController'] instanceof PresenterStateController &&
    obj.hasOwnProperty('form') &&
    obj['form'] instanceof FormGroup &&
    obj.hasOwnProperty('submitForm') &&
    obj['submitForm'] instanceof EventEmitter
  );
}
