import { UserModel } from '../../authentication/model/user.model';

export class AuthStateModel {
  token: string;
  user: UserModel;
  version: number;
  /**
   * Akkor hasznaljuk, amikor egy user social reg utan elso belepesenel van
   */
  socialRegistrationSuccessfulMode: boolean;
}
