import { INTROJS_TYPES_ENUM } from '../../introjs-config.interface';

export class StartIntroAction {
  static readonly type = '[Intro] Start';

  /**
   *
   * @param id
   * @param options {skipShowedCheck: kikapcsolja az ellenorzest, hogy latta-e mar,withPeriodContext: ebben az esetben a period context alapjan fogja az i18n utvonalat felallitani}
   */
  constructor(
    readonly id: INTROJS_TYPES_ENUM,
    readonly options: { skipShowedCheck: boolean; withPeriodContext: boolean } = { skipShowedCheck: false, withPeriodContext: false }
  ) {}
}
