import { CustomError } from '@roadrecord/comlink';

export class DataValidationError extends CustomError {
  constructor(
    readonly columnName: string,
    /* indexeles 0-tol van */
    readonly rowIndex: number,
    readonly message: 'REQUIRED' | 'IS_NUMERIC' | 'MIN_LENGTH' | 'MAX_LENGTH' | 'MIN_VALUE' | 'MAX_VALUE' | 'DATE' | string,
    /**
     * ha translate-hez vagy mashoz szeretnenk metaadatot fuzni
     */
    readonly extraMessageParam?: { [key: string]: string | number }
  ) {
    super();
    this.name = 'DataValidationError';
  }
}
