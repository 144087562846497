<ng-container *ngIf="isShow">
  <div [ngClass]="{ disabled: isDisabled }" class="d-flex position-fixed floating-action-container flex-column">
    <mat-progress-bar color="warn" mode="indeterminate" *ngIf="isDisabled"></mat-progress-bar>
    <div class="d-flex flex-row w-100 justify-content-sm-between" style="border-bottom: 1px dotted #cecece">
      <div class="d-flex justify-content-start align-items-center">
        <span class="trip-selected-text">
          {{
            'FLOATING_ACTION.LABEL'
              | transloco
                : {
                    tripCount: selectedTrips['selectionTripForm'].controls['selectedCount'].value
                  }
          }}
        </span>
      </div>
      <div class="d-flex align-items-center" style="gap: 4px">
        <button
          mat-icon-button
          (click)="onSelectAction(FloatingActionEnum.TRIP_REASON)"
          [matTooltip]="'FLOATING_ACTION.ACTIONS.TRIP_REASON.TOOLTIP' | transloco"
        >
          <mat-icon
            svgIcon="book-open-page-variant"
            [ngClass]="{ 'icon-color-selected': floatingActionControl.value === FloatingActionEnum.TRIP_REASON }"
            class="icon-color"
          >
          </mat-icon>
        </button>

        <button
          mat-icon-button
          (click)="onSelectAction(FloatingActionEnum.PARTNER)"
          [matTooltip]="'FLOATING_ACTION.ACTIONS.PARTNER.TOOLTIP' | transloco"
        >
          <mat-icon
            class="icon-color"
            svgIcon="account-group"
            [ngClass]="{ 'icon-color-selected': floatingActionControl.value === FloatingActionEnum.PARTNER }"
          >
          </mat-icon>
        </button>

        <button mat-icon-button (click)="onDeleteTrips()" [matTooltip]="'FLOATING_ACTION.ACTIONS.DELETE.TOOLTIP' | transloco">
          <mat-icon class="icon-color" svgIcon="delete"></mat-icon>
        </button>

        <button mat-icon-button (click)="closeFloatingWindow()" [matTooltip]="'FLOATING_ACTION.ACTIONS.CLOSE.TOOLTIP' | transloco">
          <mat-icon class="icon-color" svgIcon="close"></mat-icon>
        </button>
      </div>
    </div>

    <div class="d-flex w-100">
      <form [formGroup]="form" class="w-100" *ngIf="floatingActionControl.value !== null">
        <ng-container [ngSwitch]="floatingActionControl.value">
          <div class="w-100 d-flex" *ngSwitchCase="FloatingActionEnum.TRIP_REASON">
            <rr-live-auto-complete
              #tripReasonLiveAutoComplete
              name="trip_reason"
              required
              hasPrefix="true"
              hasNewButton="true"
              [hasActionsMenu]="false"
              [database]="tripReasonService"
              [formControlRef]="tripReasonControl"
              [optionsConfig]="laTripReasonOptionsConfig"
              placeholder="PARTNER.DETAILS.DATA_FORM.LABEL.TRIP_REASON"
              (openNew)="onOpenNewTripReason($event)"
              [validationMessages]="laTripReasonOptionsConfig.validatorMessages"
            >
            </rr-live-auto-complete>
          </div>
          <div class="w-100 d-flex" *ngSwitchCase="FloatingActionEnum.PARTNER">
            <rr-live-auto-complete
              #partnerLiveAutoComplete
              required
              name="partner"
              hasPrefix="true"
              floatLabel="never"
              hasNewButton="true"
              [hasActionsMenu]="false"
              [entityService]="partnerService"
              [formControlRef]="partnerControl"
              [databaseCallback]="getPartnerLiveAutocompleteCallback()"
              [optionsConfig]="$any(laPartnerOptionsConfig)"
              [placeholder]="laPartnerOptionsConfig.placeholder"
              (openNew)="onOpenNewPartner($event)"
              [validationMessages]="laPartnerOptionsConfig.validatorMessages"
            ></rr-live-auto-complete>
          </div>
        </ng-container>

        <div class="w-100 d-flex justify-content-end">
          <button mat-raised-button color="accent" (click)="onSaveSelectedTrips()"> Save</button>
        </div>
      </form>
    </div>
  </div>
</ng-container>
