import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntrojsComponent } from './introjs/introjs.component';
import { TranslocoModule } from '@ngneat/transloco';
import { HttpHandler } from '@angular/common/http';
import { HttpInterceptingHandler } from './http-intercepting-handler';

@NgModule({
  imports: [CommonModule, TranslocoModule],
  declarations: [IntrojsComponent],
  exports: [IntrojsComponent],
  providers: [{ provide: HttpHandler, useClass: HttpInterceptingHandler }],
})
export class IntrojsModule {}
