import { MaskedNumber } from 'imask';
import { globalImaskNumberConfigGenerator } from '@roadrecord/imask/common';

const commonNumberMaskConfig = { scale: 0, max: 999999 };

export function getEditCellNumberMaskConfig(): { cost: MaskedNumber; quantity: MaskedNumber; mileage: MaskedNumber } {
  return {
    cost: globalImaskNumberConfigGenerator(commonNumberMaskConfig),
    quantity: globalImaskNumberConfigGenerator({ max: 999 }),
    mileage: globalImaskNumberConfigGenerator(commonNumberMaskConfig),
  };
}
