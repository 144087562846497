export const globalPasswordControlValidationMessages = [
  {
    errorKey: 'minlength',
    translateKey: 'COMMON.VALIDATION.PASSWORD_PATTERN_SHORT',
  },
  {
    errorKey: 'uppercaseCharacterRule',
    translateKey: 'COMMON.VALIDATION.PASSWORD_PATTERN_SHORT',
  },
  {
    errorKey: 'digitCharacterRule',
    translateKey: 'COMMON.VALIDATION.PASSWORD_PATTERN_SHORT',
  },
  {
    errorKey: 'lowercaseCharacterRule',
    translateKey: 'COMMON.VALIDATION.PASSWORD_PATTERN_SHORT',
  },
  {
    errorKey: 'noWhitespaceRequired',
    translateKey: 'COMMON.VALIDATION.PASSWORD_PATTERN_SHORT',
  },
  {
    errorKey: 'mismatchedPasswords',
    translateKey: 'COMMON.VALIDATION.MISMATCHED_PASSWORDS',
  },
];
