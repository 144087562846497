import { CustomError } from '@roadrecord/comlink';

/**
 * Belso kivetel!
 */
export class FieldValidationError extends CustomError {
  constructor(
    readonly columnIndex: number,
    readonly message:
      | 'REQUIRED'
      | 'IS_NUMERIC'
      | 'MIN_LENGTH'
      | 'MAX_LENGTH'
      | 'MIN_VALUE'
      | 'MAX_VALUE'
      | 'DATE'
      | 'REQUIRED_WITH_EMPTY'
      | string,
    readonly extraMessageParam?: { [key: string]: string | number }
  ) {
    super();
  }
}
