import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FirstStepsHelpModel } from '../../../../model/first-steps-next-step.model';

/**
 * @deprecated
 */
@Component({
  selector: 'rr-register-wrapper-step',
  templateUrl: './register-wrapper-step.component.html',
  styleUrls: ['./register-wrapper-step.component.scss'],
})
export class RegisterWrapperStepComponent {
  @Input()
  helpText: FirstStepsHelpModel;
  @Output()
  save = new EventEmitter<void>();
  detailsHeight = '100%';
  cmpOptions = {
    showEmail: true,
    showEmailHint: false,
    showPassword: true,
    showPrivacyCheckBox: false,
    uiDisableCaptcha: true,
    loadCompanyRegistrationComplete: true,
    saveCompanyRegistrationComplete: true,
    submitLabel: 'FIRST_STEPS.COMPANY_DATA.REGISTER_BUTTON',
  };

  private onSave(): void {
    this.save.emit();
  }

  onEvents($event: { eventName: string; value: any }) {
    if ($event.eventName === 'completeCompanyRegistrationSave') {
      this.onSave();
    }
  }
}
