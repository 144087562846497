import { Pipe, PipeTransform } from '@angular/core';
import { ReportPrintCheckDataModel } from '../state/model/report-print-check-data.model';
import { ReportPrintStatusEnum } from '../state/model/report-print-status.enum';

@Pipe({
  name: 'reportPrintCheckListFilter',
})
export class ReportPrintCheckListFilterPipe implements PipeTransform {
  transform(value: ReportPrintCheckDataModel[], status: ReportPrintStatusEnum | 'ALL'): ReportPrintCheckDataModel[] {
    if (!Array.isArray(value) || value.length === 0) {
      return [];
    }
    if (status === 'ALL') {
      return value;
    }
    return value.filter(check => check.status === status);
  }
}
