import { CHECK_NEW_OR_LOAD_CONTROLLER_PROVIDER } from '../../../router/controller/check-new-or-load/check-new-or-load.provider';
import { PRESENTER_DELETE_PLUGIN_PROVIDER } from '../plugin/presenter-delete/presenter-delete-plugin.provider';
import { PRESENTER_SAVE_PLUGIN_PROVIDER } from '../plugin/presenter-save/presenter-save-plugin.provider';
import { VIEW_MODEL_PLUGIN_PROVIDER } from '../plugin/view-model/view-model-plugin.provider';
import { VIEW_REMOTE_FIELD_ERROR_PLUGIN_PROVIDER } from '../plugin/view-remote-field-error/view-remote-field-error-plugin.provider';
import { VIEW_SUBMIT_PLUGIN_PROVIDER } from '../plugin/view-submit/view-submit-plugin.provider';
import { ConstructorProvider } from '@angular/core';

export const PRESENTER_STATE_DEFAULT_PROVIDERS: ConstructorProvider[] = [
  CHECK_NEW_OR_LOAD_CONTROLLER_PROVIDER,
  PRESENTER_SAVE_PLUGIN_PROVIDER,
  PRESENTER_DELETE_PLUGIN_PROVIDER,
  VIEW_REMOTE_FIELD_ERROR_PLUGIN_PROVIDER,
  VIEW_SUBMIT_PLUGIN_PROVIDER,
  VIEW_MODEL_PLUGIN_PROVIDER,
];
