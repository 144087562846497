import { StateContext, Store } from '@ngxs/store';
import { DEFAULT_ROUTE_PATH } from '@roadrecord/common/common';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractPeriodContextStateModel } from '../model/abstract-period-context-state.model';
import { InvalidPeriodContextEnum } from '../model/invalid-period-context.enum';
import { PeriodContextStopVehiclePullAction } from '../action/period-context-stop-vehicle-pull.action';
import { PeriodContextStartVehiclePullAction } from '../action/period-context-start-vehicle-pull.action';

export function detectMenuRefreshRemoteSuccessActionFn<STATEMODEL extends AbstractPeriodContextStateModel<any>>(
  ngZone: NgZone,
  router: Router,
  store: Store,
  prefix: string
) {
  return (ctx: StateContext<STATEMODEL>, action: { readonly state: any; readonly keyPath: string }) => {
    let newInvalidPeriodContext: InvalidPeriodContextEnum;
    if (action.keyPath === `${prefix}.menu.invalid_period_context`) {
      newInvalidPeriodContext = action.state;
    } else if (action.keyPath === `${prefix}.menu`) {
      newInvalidPeriodContext = action.state.invalid_period_context;
    }

    if (newInvalidPeriodContext !== undefined) {
      const oldInvalidPeriodContext = store.selectSnapshot(states => states.menu.invalid_period_context);

      if (oldInvalidPeriodContext !== newInvalidPeriodContext) {
        if (newInvalidPeriodContext === InvalidPeriodContextEnum.PULL_VEHICLE_LIST) {
          // ha nincs mar kocsija akkor vissza kell kuldeni a default route-ra
          ngZone.run(() => router.navigate([DEFAULT_ROUTE_PATH]));
          // pull -t el kell inditani
          return ctx.dispatch(new PeriodContextStartVehiclePullAction());
        } else if (oldInvalidPeriodContext === InvalidPeriodContextEnum.PULL_VEHICLE_LIST) {
          // le kell allitani a pull-t
          return ctx.dispatch(new PeriodContextStopVehiclePullAction());
        }
      }
    }
  };
}
