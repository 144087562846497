import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment';
import { RecommendationFinishTableRowModel } from '../state/model/recommendation-finish-table-row.model';

export function getRowTpl(
  translocoService: TranslocoService,
  row: RecommendationFinishTableRowModel /* TODO circular dep | FinalizationOfRouteRouteCorrectionTableModel*/,
  withFirstColumn = true,
  withLastColumn = true,
  translatePrefix = 'RECOMMENDATION.FINISH_RESPONSE'
): string {
  return `<tr>
  ${
    withFirstColumn
      ? `<td>${translocoService.translate(`${translatePrefix}.TABLE.CELL.FUELING_RANGE_INDEX`, {
          index: row.range_index,
        })}</td>`
      : ''
  }
  <td>${moment.unix(row.date_range.start).format('L')} - ${moment.unix(row.date_range.end).format('L')}</td>
  <td>${row.remaining_distance_in_range}</td>
  ${
    row.hasOwnProperty('is_fueling_range_correctable')
      ? `<td style="display: flex; justify-content: center;">
        <span class="${row.is_fueling_range_correctable ? 'material-icons check-success' : ' material-icons check-error'}">
        ${row.is_fueling_range_correctable ? 'verified' : 'new_releases'}</mat-icon></td>`
      : ''
  }
  ${
    withLastColumn
      ? `<td>${translocoService.translate(`${translatePrefix}.TABLE.CELL.UNIT_PIECES`, {
          pieces: row.number_of_routes_in_range,
        })}</td>`
      : ''
  }
</tr>
`;
}

export function baseTable(
  translocoService: TranslocoService,
  withFirstColumn = true,
  withLastColumn = true,
  withFuelingRangeCorrection: boolean = true,
  translatePrefix = 'RECOMMENDATION.FINISH_RESPONSE'
): string {
  return `<div class="scroll-wrapper"><table>
  <thead>
    <tr>
      ${withFirstColumn ? `<th>${translocoService.translate(`${translatePrefix}.TABLE.HEADER.REFUELING_SECTION_SERIAL_NUMBER`)}</th>` : ''}
      <th>${translocoService.translate(`${translatePrefix}.TABLE.HEADER.PERIOD`)}</th>
      <th>${translocoService.translate(`${translatePrefix}.TABLE.HEADER.REMAINING_DISTANCE`)}</th>
      ${
        withFuelingRangeCorrection
          ? `<th>${translocoService.translate(`${translatePrefix}.TABLE.HEADER.FUELING_RANGE_CORRECTABLE`)}</th>`
          : ''
      }
      ${
        withLastColumn
          ? `<th>${translocoService.translate(`${translatePrefix}.TABLE.HEADER.NUMBER_OF_CORRECTED_ROADS`)}
        </th>`
          : ''
      }
    </tr>
  </thead>
  <tbody>
%%ROWS%%
  </tbody>
</table>
</div>`;
}
