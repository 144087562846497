import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { WINDOW } from '@roadrecord/common/common';
import { MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';
import { BehaviorSubject, NEVER, Observable, Subject, timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

const logScopeName = 'SwUpdatesService';

/**
 * SwUpdatesService
 *
 * @description
 * 1. Checks for available ServiceWorker updates once instantiated.
 * 2. Re-checks every 6 hours.
 * 3. Whenever an update is available, it activates the update.
 * 3. Whenever an update is available, it activates the update.
 *
 * `updateActivated` {Observable<string>} - Emit the version hash whenever an update is activated.
 */
@Injectable({ providedIn: 'root' })
export class SwUpdatesService implements OnDestroy {
  // private checkInterval = 1000 * 60 * 60 * 6; // 6 hours
  private onDestroy = new Subject<void>();
  updateActivated: Observable<string>;
  readonly hasNewVersion$ = new BehaviorSubject(false);
  disableNextAvaibleCheck = false;

  constructor(
    @Optional() readonly swu: SwUpdate,
    private store: Store,
    private translocoService: TranslocoService,
    @Inject(WINDOW) private window: Window,
    private messageDialogService: MessageDialogService // private appRef: ApplicationRef
  ) {
    if (swu !== null && swu.isEnabled) {
      // tslint:disable-next-line
      const statusSubscription = this.swu['sw']['eventsOfType']('STATUS').subscribe(s => {
        statusSubscription.unsubscribe();
        timer(300).subscribe(() => {
          this.hasNewVersion$.next(false);
        });
      });
    }

    console.warn(logScopeName, 'SW is enabled: ', swu !== null && swu.isEnabled);
    if (swu === null || !swu.isEnabled) {
      this.updateActivated = NEVER.pipe(takeUntil(this.onDestroy));
      this.hasNewVersion$.subscribe(v => console.warn(logScopeName, 'hasNewVersion$: ', v));
      this.hasNewVersion$.next(false);
      return;
    }

    this.checkUpdate();
  }

  private checkUpdate() {
    // szukseg van ra, mert amikor nincs update akkor ha ezt nem csinaljuk, STATUS-t nem fogunk kapni!
    timer(1000).subscribe(() => this.swu.checkForUpdate());

    // Activate available updates.
    this.swu.available
      .pipe(
        tap(evt => this.log(`Update available: ${JSON.stringify(evt)}`)),
        takeUntil(this.onDestroy)
      )
      .subscribe(() => {
        if (this.disableNextAvaibleCheck === true) {
          this.disableNextAvaibleCheck = false;
          return;
        }

        this.swu.activateUpdate().then(() => {
          this.hasNewVersion$.next(true);
          this.messageDialogService
            .open({
              id: null,
              type: MessageDialogTypeEnum.INFORMATION,
              text: this.translocoService.translate('COMMON.APP_UPDATE.DIALOG.TEXT'),
              translateText: false,
              htmlMode: true,
              confirmLabel: 'COMMON.APP_UPDATE.DIALOG.ACTION',
            })
            .afterClosed()
            .subscribe(() => this.window.location.reload());
        });
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  private log(message: string): void {
    const timestamp = new Date().toISOString();
    console.warn(logScopeName, `[SwUpdates - ${timestamp}]: ${message}`);
  }
}
