<div class="content" *ngTemplateOutlet="config.panelRef"></div>
<div class="d-flex flex-row justify-content-between footer w-100 button-row">
  <ng-container *ngFor="let buttonConfig of config.buttons" [ngSwitch]="buttonConfig.type">
    <ng-container *ngSwitchCase="ButtonTypeEnum.SIMPLE">
      <button
        mat-button
        *ngIf="(buttonConfig.show | isBoolean) ? buttonConfig.show : true"
        [color]="buttonConfig.color"
        (click)="buttonConfig.click()"
        [disabled]="
          (buttonConfig.disabled | isFunction)
            ? buttonConfig.disabled()
            : buttonConfig.disabled$ !== undefined
            ? (buttonConfig.disabled$ | async)
            : buttonConfig.disabled
        "
      >
        {{ buttonConfig.label | transloco }}
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="ButtonTypeEnum.RAISED">
      <button
        mat-raised-button
        *ngIf="(buttonConfig.show | isBoolean) ? buttonConfig.show : true"
        [color]="buttonConfig.color"
        (click)="buttonConfig.click()"
        [disabled]="
          (buttonConfig.disabled | isFunction)
            ? buttonConfig.disabled()
            : buttonConfig.disabled$ !== undefined
            ? (buttonConfig.disabled$ | async)
            : buttonConfig.disabled
        "
      >
        {{ buttonConfig.label | transloco }}
      </button>
    </ng-container>
  </ng-container>
</div>
<mat-progress-bar *ngIf="config.loading$ | async" color="primary" mode="indeterminate"></mat-progress-bar>
