<ng-template #bulkEditTripReasonBottomSheetRef>
  <rr-bulk-edit-trip-reason-bottom-sheet></rr-bulk-edit-trip-reason-bottom-sheet>
</ng-template>

<ng-template #bulkEditTypeBottomSheetRef>
  <rr-bulk-edit-type-bottom-sheet [type]="partnerType"></rr-bulk-edit-type-bottom-sheet>
</ng-template>
<rr-grid
  [multiSelection]="true"
  [hasCheckboxColumn]="true"
  [columnDefinitions]="displayedColumns"
  [hasIconColumn]="true"
  [titleText]="layoutTitle"
  [titleIcon]="titleIcon"
  [hasCommonActionsColumn]="true"
  [hasFilter]="true"
  [notEntitiesDataLabel]="gridNotEntitiesDataLabel"
  [notEntitiesDataLinkLabel]="gridNotEntitiesDataLinkLabel"
  [rightClickContextMenuOption]="rightClickContextMenuOption"
  [setIconFn]="setIconFn"
  [gridFilterLabel]="gridFilterLabel"
  [gridFilterPlaceholder]="gridFilterPlaceholder"
  [gridFilterHint]="gridFilterHint"
  [notEntitiesBackgroundImage]="notEntitiesBackgroundImage"
  [headerGroupActionDisabledLimit]="-1"
  [headerGroupActions]="headerGroupActions"
  [headerOtherActions]="headerOtherActions"
  #rrGrid
></rr-grid>
