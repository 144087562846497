export enum PriceCategoryURLStatus {
  BASIC = 'basic',
  ON_SALE = 'on-sale',
}

/**
 * A termék
 */
export enum PriceCategoryStatus {
  BASIC = 2,
  ON_SALE = 3,
}
