import { Inject, Injectable, Optional } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { deletePopup, DeletePopupFnParameters, MessageDialogService } from '@roadrecord/message-dialog';
import { ENTITY_SERVICE_TOKEN } from '../../../../entity/service/entity-service.token';
import { AbstractEntityService } from '../../../../entity/service/abstract-entity.service';
import { HttpListResponseModel } from '../../../../http/model/http-list-response.model';
import { MaybeHandleHttpError } from '../../../../http/function/maybe-handle-http-error.function';
import { isFunction, isNil, isNotEmptyString } from '@roadrecord/type-guard';
import { PRESENTER_COMPONENT, PresenterStateController } from '../../presenter-state/presenter-state.controller';
import { PRESENTER_DELETE_OPTIONS, PresenterDeleteOptions } from './presenter-delete-options.token';
import { CheckModifiedMVPFormGuard } from '../../../guard/check-modified-mvp-form-guard.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '@roadrecord/environment';

const logScopeName = 'PresenterDeletePlugin';

@Injectable()
export class PresenterDeletePlugin<MODEL, IDTYPE> {
  constructor(
    private presenterStateController: PresenterStateController<MODEL, IDTYPE>,
    @Inject(ENTITY_SERVICE_TOKEN) private service: AbstractEntityService<HttpListResponseModel<MODEL>, MODEL>,
    private translocoService: TranslocoService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    @Optional() private gtmService: GoogleTagManagerService,
    private messageDialogService: MessageDialogService,
    @Optional() @Inject(PRESENTER_DELETE_OPTIONS) private options: PresenterDeleteOptions<any>,
    @Optional() private checkModifiedMVPFormGuard: CheckModifiedMVPFormGuard
  ) {
    if (this.options === null) {
      this.options = {};
    }
    this.registerPresenter();
  }

  deleteModel(dataModel?: MODEL, ok?: (response: MODEL) => void, error?: (error) => void): void {
    if (dataModel === undefined) {
      dataModel = this.presenterStateController.editModelLastValue;
    }
    // this.presenterStateController.formGroupLastValue.disable();
    const deletePopupParameters: DeletePopupFnParameters = {
      matSnackBar: this.matSnackBar,
      translocoService: this.translocoService,
      messageDialogService: this.messageDialogService,
      yesCallback: (finishCallback: () => void, result) => {
        this.presenterStateController.formGroupLastValue.disable();

        const matSnackBar: MatSnackBar = this.presenterStateController.get(MatSnackBar);
        if (!isNil(matSnackBar)) {
          matSnackBar.open(this.translocoService.translate('COMMON.SNACKBAR.DELETE_START'));
        }
        this.presenterStateController.loading$.next(true);

        const okCb = responseOk => {
          if (!isNil(this.checkModifiedMVPFormGuard)) {
            this.checkModifiedMVPFormGuard.disableNextCheck = true;
          }
          if (!isNil(matSnackBar)) {
            matSnackBar.open(
              this.translocoService.translate('COMMON.SNACKBAR.DELETE_SUCCESS'),
              this.translocoService.translate('COMMON.ACTION.CLOSE_LOWER')
            );
          }
          // TODO disable check
          finishCallback();
          ok !== undefined
            ? ok(responseOk)
            : this.router.navigate(['../'], {
                relativeTo: this.route,
                // replaceUrl: true
              });
        };
        const errorCb = responseError => {
          this.presenterStateController.formGroupLastValue.enable();
          if (error !== undefined) {
            error(responseError);
          } else if (environment.production === false) {
            console.error(logScopeName, responseError);
          }
          this.presenterStateController.hasDeleteButton$.next(false);
          MaybeHandleHttpError.maybeHandleHttpError(responseError, () => {
            this.presenterStateController.loading$.next(false);
          });
        };
        const entityId = this.service.getModelIdValue(dataModel);
        if (isFunction(this.options.serviceRemoveCb)) {
          this.options.serviceRemoveCb(this.service, result, entityId, okCb, errorCb);
        } else {
          this.service.remove(entityId).subscribe(okCb, errorCb);
        }
      },
      // noCallback: () => this.presenterStateController.formGroupLastValue.enable(),
    };
    if (!isNil(this.options.slideToggleText) && isNotEmptyString(this.options.slideToggleText)) {
      deletePopupParameters.slideToggleLabel = this.options.slideToggleText;
    }

    if (!isNil(this.options.text) && isNotEmptyString(this.options.text)) {
      deletePopupParameters.text = this.options.text;
    }

    deletePopup(deletePopupParameters);
  }

  private registerPresenter(): void {
    // tslint:disable:no-invalid-this
    const presenter: any = this.presenterStateController.get(PRESENTER_COMPONENT);

    const onDeleteOriginalMethod = !isNil(presenter.prototype.ORIGINAL_ONDELETE)
      ? presenter.prototype.ORIGINAL_ONDELETE
      : presenter.prototype.onDelete;
    if (onDeleteOriginalMethod === undefined) {
      throw new Error('Not found onDelete');
    }
    const __this = this;
    if (presenter.prototype.ORIGINAL_ONDELETE === undefined) {
      presenter.prototype.ORIGINAL_ONDELETE = onDeleteOriginalMethod;
    }
    presenter.prototype.onDelete = function (): void {
      onDeleteOriginalMethod.call(this, arguments);
      // TODO model check?
      __this.deleteModel(
        __this.presenterStateController.editModelLastValue,
        presenter.prototype.deleteOkCallback ? presenter.prototype.deleteOkCallback.bind(this) : undefined
      );
    };
  }
}
