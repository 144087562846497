import { HotkeyOptionsModel } from '@roadrecord/app-layout/common';
import { monthStatusListOpenWindowAction } from '@roadrecord/mileage/common';

export const appHotkeys: HotkeyOptionsModel[] = [
  // {
  //   hotKey: 'alt+shift+t',
  //   callback: (
  //     store,
  //     messageDialogService,
  //     loadableService,
  //     injector,
  //     translateService,
  //     matSnackBar,
  //     periodContextSelectors
  //   ) => (/*event: KeyboardEvent, combo: string*/) => {
  //     if (store.selectSnapshot<boolean>(periodContextSelectors.isPrivate)) {
  //       messageDialogService.openWarning({
  //         id: null,
  //         text: 'APP_LAYOUT.HOTKEYS.FUELING_RANGE.JUST_COMPANY_CAR_WARNING',
  //       });
  //       return false;
  //     }
  //     fuelingRangeOpenWindowAction(store, loadableService, injector, translateService as any, matSnackBar);
  //     return false;
  //   },
  //   title: 'APP_LAYOUT.HOTKEYS.FUELING_RANGE.TITLE',
  // },
  {
    hotKey: 'alt+shift+g',
    callback: (store, messageDialogService, loadableService, injector, translateService, matSnackBar) => () => {
      monthStatusListOpenWindowAction(store, loadableService, injector, translateService as any, matSnackBar);
      return false;
    },
    title: 'APP_LAYOUT.HOTKEYS.MILEAGE.TITLE',
  },
];
