<ng-template #contentTpl>
  <form [formGroup]="form" autocomplete="off" fxLayout="column" fxLayoutAlign="start start">
    <label
      *ngIf="lastName && firstName; else fullNameLabelTpl"
      [innerHtml]="translateLastAndFirstNameLabel | transloco: { lastName: lastName, firstName: firstName }"
    >
    </label>
    <ng-template #fullNameLabelTpl>
      <label [innerHtml]="translateFullNameLabel | transloco: { fullName: fullName }"></label>
    </ng-template>
    <mat-form-field class="wid100">
      <mat-label>{{ translateInputLabel | transloco }}</mat-label>
      <input [formControl]="confirmControl" name="confirm" matInput [placeholder]="translateInputPlaceholder | transloco" required />
      <mat-error> <rr-validation-messages [errors]="confirmControl.errors"></rr-validation-messages> </mat-error>
    </mat-form-field>
    <mat-error *ngIf="form.submitted && form.invalid">{{ translateInputErrorMismatch | transloco }} </mat-error>
  </form>
</ng-template>
<ng-template #buttonsTpl>
  <button
    mat-raised-button
    type="button"
    color="warn"
    (click)="onSubmit()"
    matTooltip="{{ translateButtonTooltip | transloco }}"
    matTooltipPosition="left"
  >
    {{ 'COMMON.ACTION.DELETE' | transloco }}
  </button>
</ng-template>
