import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { CapitalizeDirective, MONTH_ACTIVITIES_PAGE_PATH, ViewportService } from '@roadrecord/common/common';
import { MESSAGE_DIALOG_ELEMENT_REF_DATA, MessageDialogService } from '@roadrecord/message-dialog';
import moment from 'moment';
import { forkJoin, Observable, of, race, Subscription } from 'rxjs';
import { debounceTime, take, tap } from 'rxjs/operators';
import { MenuService } from '../menu/menu.service';
import { MenuToggleAction } from '../menu/state/action/menu-toggle.action';
import { MenuState } from '../menu/state/menu.state';
import { isNil, isObject } from '@roadrecord/type-guard';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AbstractPeriodContextModel,
  AbstractPeriodContextStateModel,
  InvalidPeriodContextEnum,
  PeriodContextRefreshRemoteStateAction,
  PeriodContextRefreshRemoteStateErrorAction,
  PeriodContextRefreshRemoteStateSuccessAction,
  PeriodContextService,
  PeriodContextStateSelectorsService,
} from '@roadrecord/period-context/common';
import { tsDeepcopy } from '@roadrecord/ts-deepcopy';
import { MenuSetInvalidPeriodContextAction } from '../menu/state/action/menu-set-invalid-period-context.action';
import { isNotHandledError } from '@roadrecord/utils';

export const getLocaleMonthsText = (): { id: number; name: string }[] => {
  const localeMonthsText = [];
  // TODO @eggp ha tudod akkor Angularbol kiolvasni
  moment.months().forEach((item, index) => {
    localeMonthsText.push({ id: index + 1, name: CapitalizeDirective.capitalizeText(item) });
  });

  return localeMonthsText;
};

@UntilDestroy()
@Component({
  selector: 'rr-plate-period-context',
  templateUrl: './period-context-display.component.html',
  styleUrls: ['./period-context-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // animations: [
  // // TODO animacio: be kell varni az anim veget es ugy lehet szepen valtani
  // // mert a boxok nem lebegnek igy egymas ala kerulnek egy pillanatra amitol ugral a kep
  // fadeInAnimation,
  // fadeOutAnimation
  // ]
})
export class PeriodContextDisplayComponent implements OnInit {
  selectedMonthLocalText: string;
  context: AbstractPeriodContextModel<any> | null = null;
  readonly contextLoading$: Observable<boolean>;
  readonly menuOpened$: Observable<boolean>;
  @HostBinding('class.clickable')
  clickable = true;
  dataModel: AbstractPeriodContextStateModel<any>;
  loading = false;
  private menuOpened: boolean;
  private menuChangeTabActionSubscription: Subscription;
  hideHeader = false;
  @HostBinding('class.dialog-mode') dialogMode = false;
  readonly routerMode: boolean = true;

  constructor(
    private cdr: ChangeDetectorRef,
    viewportService: ViewportService,
    private router: Router,
    private store: Store,
    private actions: Actions,
    private periodContextService: PeriodContextService<any, any>,

    private translocoService: TranslocoService,
    private menuService: MenuService,
    private messageDialogService: MessageDialogService,
    @Optional()
    @Inject(MESSAGE_DIALOG_ELEMENT_REF_DATA)
    private dialogOptions: {
      menuOpened$: Observable<boolean>;
      contextLoading$: Observable<boolean>;
      disablePeriodContextLoad: boolean;
      periodContext: AbstractPeriodContextModel<any>;
      editMode: boolean;
      hideHeader: boolean;
    },
    private matSnackBar: MatSnackBar,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>
  ) {
    this.watchContextChange();

    if (!isNil(dialogOptions)) {
      this.routerMode = false;
      this.dialogMode = true;
      this.menuOpened$ = dialogOptions.menuOpened$;
      this.contextLoading$ = dialogOptions.contextLoading$;
      this.hideHeader = dialogOptions.hideHeader;
    } else {
      this.menuOpened$ = this.store.select(MenuState.open).pipe(untilDestroyed(this));
      this.contextLoading$ = this.store.select(this.periodContextStateSelectorsService.loading).pipe(untilDestroyed(this));
    }

    this.watchMenuToggle();
  }

  openMenu(): void {
    this.store.dispatch(new MenuToggleAction());
  }

  private watchContextChange() {
    this.store
      .select(this.periodContextStateSelectorsService.context)
      .pipe(untilDestroyed(this))
      .subscribe(context => {
        this.context = context;
        this.cdr.markForCheck();
      });
  }

  ngOnInit(): void {
    this.load();
  }

  private load(): boolean {
    if (this.menuOpened === false) {
      this.store.dispatch(new MenuToggleAction());
    }
    this.loading = true;

    const forkJoinStreams: Observable<any>[] = [];
    if (!isNil(this.dialogOptions) && this.dialogOptions.disablePeriodContextLoad === true) {
      forkJoinStreams.push(of(this.dialogOptions.periodContext));
    } else {
      forkJoinStreams.push(this.periodContextService.get(undefined));
    }
    forkJoin(forkJoinStreams).subscribe(responses => {
      this.dataModel = responses[0];
      this.loading = false;
      this.cdr.markForCheck();
    });
    this.cdr.markForCheck();

    return;
  }

  private watchMenuToggle(): void {
    this.menuOpened$.pipe(debounceTime(50), untilDestroyed(this)).subscribe(opened => {
      this.menuOpened = opened;
      this.cdr.markForCheck();
    });
  }

  onFormChange($event: { vehicle: any; yearMonth: moment.Moment }) {
    const { month, year, vehicle } = !isNil(this.dataModel.model) ? this.dataModel.model : this.dataModel;

    if (
      isNil(vehicle) ||
      vehicle.id !== $event.vehicle ||
      +year !== +$event.yearMonth.format('YYYY') ||
      +month !== +$event.yearMonth.format('MM')
    ) {
      this.loading = true;
      this.cdr.markForCheck();
      const vehicle_id = isObject($event.vehicle) ? $event.vehicle.id : $event.vehicle;
      this.periodContextService
        .update(undefined, { vehicle: vehicle_id, year: $event.yearMonth.format('YYYY'), month: $event.yearMonth.format('MM') })
        .subscribe(
          () => {
            race([
              this.actions.pipe(ofActionSuccessful(PeriodContextRefreshRemoteStateSuccessAction)),
              this.actions.pipe(ofActionSuccessful(PeriodContextRefreshRemoteStateErrorAction)),
            ])
              .pipe(take(1))
              .subscribe(action => {
                this.loading = false;
                this.cdr.markForCheck();

                if (action instanceof PeriodContextRefreshRemoteStateErrorAction && isNotHandledError(action.error)) {
                  this.messageDialogService.openError({
                    id: null,
                    text: 'APP_LAYOUT.PERIOD_CONTEXT_DISPLAY.UNKNOWN_ERROR',
                    textParams: { errorCode: action.error.message },
                  });
                } else if (action instanceof PeriodContextRefreshRemoteStateSuccessAction) {
                  this.dataModel = action.state;

                  if (this.dialogMode === true) {
                    const dialog = this.messageDialogService.matDialog.openDialogs[0];
                    this.messageDialogService.matDialog.openDialogs[0]
                      .beforeClosed()
                      .pipe(tap(() => this.store.dispatch(new MenuSetInvalidPeriodContextAction(InvalidPeriodContextEnum.DISABLED))))
                      .subscribe(() =>
                        this.matSnackBar.open(
                          this.translocoService.translate(
                            'APP_LAYOUT.PERIOD_CONTEXT_DISPLAY.SNACKBAR.SUCCESS_CHANGE_VEHICLE_ON_REQUIRED_LAYOUT'
                          )
                        )
                      );
                    dialog.close();
                    // Ha a kiválasztott menuPage - havi utak - akkor mivel RR és MW oldalon is gépjármű típustól
                    // függetlenül a szabadnapok menüpont elérhető, kiválasztjuk.
                  } else if (this.router.url.startsWith(`/${MONTH_ACTIVITIES_PAGE_PATH}`)) {
                    /*
                    this.menuService
                      .hasMenuItemByUrl(`/${MONTH_ACTIVITIES_PAGE_PATH}/${SPECIAL_DAYS_ROUTE_PATH}`)
                      .subscribe(v =>
                        this.router.navigate([v ? `/${MONTH_ACTIVITIES_PAGE_PATH}/${SPECIAL_DAYS_ROUTE_PATH}` : baseNotAccessUrl])
                      );
*/
                  }
                }
              });
            this.store.dispatch(new PeriodContextRefreshRemoteStateAction());
          },
          () => {
            this.context = tsDeepcopy(this.context);
            this.loading = false;
            this.cdr.markForCheck();
          }
        );
    }
  }
}
