export enum DocumentDataCheckerActionsEnum {
  MILEAGE_START__ADD_OR_MODIFY = 0,
  MILEAGE_END__ADD_OR_MODIFY = 2,
  FINALIZATION_OF_ROUTE__RECOMMENDATION = 3,
  FINALIZATION_OF_ROUTE__FUELING_RANGE_WINDOW = 4,
  FINALIZATION_OF_ROUTE__MILEAGE_LIST_WINDOW = 5,
  FINALIZATION_OF_ROUTE__MONTHLY_DISTANCE_CORRECTION = 6,
  FINALIZATION_OF_ROUTE__JUST_FIX_ROAD = 7,
  FLOATING_WINDOW__FUELING_RANGE = 8,
  FLOATING_WINDOW__MILEAGE_LIST = 9,
  VEHICLE__LIST,
}
