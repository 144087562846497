import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNil } from '@roadrecord/type-guard';

@Component({
  selector: 'rr-submit-button-with-error',
  templateUrl: './submit-button-with-error.component.html',
  styleUrls: ['./submit-button-with-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitButtonWithErrorComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() formSubmitted: boolean;
  @Input() formInvalid: boolean;
  @Output() clickButton: EventEmitter<void> = new EventEmitter<void>();
  @Input() invalidTooltip = 'COMMON.VALIDATION.BUTTON';
  @Input() buttonLabel: string;
  @Input() gaPrefix?: string;
  @Input() buttonIcon?: string;

  ngOnInit() {
    if (isNil(this.gaPrefix)) {
      this.gaPrefix = '';
    }
  }

  onClickButton() {
    this.clickButton.emit();
  }
}
