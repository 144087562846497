export enum LOCALES {
  EN_US = 'en-us',
  HU_HU = 'hu-hu',
}

// csak a nyelvi kod, pl: hu
// export const DEFAULT_LOCALE = LOCALES.HU_HU;

export function LOCALESgetValues(): string[] {
  return Object.values(LOCALES);
}
