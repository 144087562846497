import { Injector } from '@angular/core';
import { isNil } from '@roadrecord/type-guard';
import { UniversalImportService } from '@roadrecord/universal-import/common';
import { EMPTY } from 'rxjs';

export function getUrlParameterListFn(injector: Injector, rowId: number, partnerId: number) {
  if (!isNil(rowId) && !isNil(partnerId)) {
    return injector.get(UniversalImportService).update(rowId, {
      id: rowId,
      partner_id: partnerId,
    });
  }
  return EMPTY;
}
