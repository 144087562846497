import { UniversalUploadSourceTypes } from '@roadrecord/universal-import/common';

export enum FuelingImportUploadSourceTypesEnum {
  UNIVERSAL = 'fueling',
}

export const fuelingImportUploadSourceTypes: UniversalUploadSourceTypes<FuelingImportUploadSourceTypesEnum>[] = [
  {
    translateKey: 'UNIVERSAL',
    value: FuelingImportUploadSourceTypesEnum.UNIVERSAL,
  },
];
