import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { periodContextChangeFunction } from '../period-context-change.function';
import { ActivatedRoute, Router } from '@angular/router';
import { FuelingService } from '../../fueling.service';
import { ENTITY_SERVICE_TOKEN } from '@roadrecord/utils';
import { Observable } from 'rxjs';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { RRCalendarComponent, RRCalendarViewType } from '@roadrecord/calendar';
import moment from 'moment';
import { FuelingCalendarMonthViewDay } from '../model/fueling-calendar-month-view-day';
import { dblClickCellFunction } from '../dbl-click-cell.function';
import { UpdatedMileageListAction } from '@roadrecord/mileage/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SplitButtonAction } from '@roadrecord/split-button';
import { PreferencesContentWindowTypeEnum, PreferencesState } from '@roadrecord/preferences-state/common';
import { monthStatusListOpenWindowAction } from '@roadrecord/mileage/common';
import { TranslocoService } from '@ngneat/transloco';
import { LoadableService } from '@roadrecord/external-packages/ngx-loadable';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppTypeEnum, environment } from '@roadrecord/environment';
import { clickFuelingImportRightMenuButton } from '../click-fix-import.function';
import { fuelingImportEntityDefaultOrder, FuelingUniversalImportService } from '../../import/fueling-universal-import.service';
import { fuelingImportAction } from '../../import/window-content/window-content.action';
import { UniversalImportService } from '@roadrecord/universal-import/common';

@UntilDestroy()
@Component({
  selector: 'rr-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
  providers: [
    FuelingService,
    { provide: ENTITY_SERVICE_TOKEN, useExisting: FuelingService },
    { provide: UniversalImportService, useClass: FuelingUniversalImportService },
  ],
})
export class AgendaComponent implements OnInit {
  readonly viewDate$: Observable<Date>;
  RRCalendarViewType = RRCalendarViewType;
  @ViewChild(RRCalendarComponent) agendaComponent: RRCalendarComponent;
  readonly topRightMenuAfterButtons: SplitButtonAction[] = [
    {
      type: 'ITEM',
      label: { translate: 'MILEAGE.LIST_WINDOW_CONTENT.HEADER_TITLE' },
      icon: 'clock-end',
      action: () => this.openMonthStatusListWindow(),
      disabled: () =>
        this.store.selectSnapshot(PreferencesState.hasOpenedContentWindow)(PreferencesContentWindowTypeEnum.MONTH_STATUS_LIST),
    },
    { type: 'DIVIDER' },
    {
      id: 'fueling_import_menu_item',
      type: 'ITEM',
      label: { translate: 'FUELING.CALENDAR.HEADER.TOP_RIGHT_MENU.IMPORT.LABEL' },
      icon: 'cloud-upload',
      action: () => {
        this.agendaComponent.showLoading();
        clickFuelingImportRightMenuButton(this.universalImportService, fuelingImportEntityDefaultOrder, '..', this.router, this.route);
      },
    },
    {
      type: 'ITEM',
      label: { translate: 'FUELING.CALENDAR.HEADER.TOP_RIGHT_MENU.IMPORT_LIST_WINDOW.LABEL' },
      icon: 'format-list-checks',
      action: () => fuelingImportAction(this.store, this.loadableService, this.injector, this.translocoService, this.matSnackBar),
      disabled: () =>
        this.store.selectSnapshot(PreferencesState.hasOpenedContentWindow)(PreferencesContentWindowTypeEnum.FUELING_IMPORT_LIST) ||
        this.agendaComponent.isLoading,
    },
  ];

  constructor(
    private router: Router,
    private store: Store,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    private fuelingService: FuelingService,
    private route: ActivatedRoute,
    private actions$: Actions,
    private injector: Injector,
    private translocoService: TranslocoService,
    private loadableService: LoadableService,
    private matSnackBar: MatSnackBar,
    readonly universalImportService: UniversalImportService<any>
  ) {
    this.viewDate$ = this.store.select(this.periodContextStateSelectorsService.viewDate);

    this.detectAutoRefreshFromPopup();
  }

  private detectAutoRefreshFromPopup() {
    this.actions$.pipe(ofActionSuccessful(UpdatedMileageListAction), untilDestroyed(this)).subscribe(() => this.agendaComponent.refresh());
  }

  ngOnInit() {}

  onPeriodContextChange($event: boolean) {
    periodContextChangeFunction($event, this.router);
  }

  private fillCalendar(data: any[]): unknown[] {
    if (environment.appType === AppTypeEnum.US) {
      data = data.map(_data => {
        _data.events = _data.events.map(event => {
          event.fueling_datetime = moment(`${_data.date} ${event.fueling_datetime}`, 'YYYY-mm-dd HH:mm').format('h:mm A');
          return event;
        });
        return _data;
      });
    }
    return data;
  }

  getFillCalendar() {
    return this.fillCalendar.bind(this);
  }

  getDatabaseCallback() {
    return this.databaseCallback.bind(this);
  }

  private databaseCallback() {
    return this.fuelingService.getCalendarList();
  }

  onClickCell($event: Partial<FuelingCalendarMonthViewDay>): any {
    dblClickCellFunction(this.router, this.route, $event, ['./../']).then();
  }

  private openMonthStatusListWindow() {
    monthStatusListOpenWindowAction(this.store, this.loadableService, this.injector, this.translocoService as any, this.matSnackBar).then();
  }
}
