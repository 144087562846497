import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { attachFeatureFlagsInterface, getFeatureFlagsFromStore, storeFeatureFlags } from '@roadrecord/feature-flag';
import { isMobileOrTablet, isTablet } from '@roadrecord/mobile-detect';
import 'hammerjs';
import { RecaptchaComponent } from 'ng-recaptcha';
import { AppModule } from './app/app.module';
import {
  detectSocialLogin,
  detectSwitchUser,
  rrAngularAppBootstrap,
  startDOMChangeDetect,
  uaParserBotBrowser,
  userBrowserIsOutdated,
  userBrowserName,
} from '@roadrecord/main-helper';
import { getContentSecurityPolicyNonceFromDom, loadJsScript, loadHelpCrunch } from '@roadrecord/common/common';
import { environment } from '@roadrecord/environment';

(window as any).IS_E2E = environment.mode === 'e2e';

if (detectSocialLogin('user-jwt-token') === false) {
  // Ha nincs social login token, akkor megnezzuk hogy van-e webadmin switch user token
  detectSwitchUser({ tokenKey: 'wesfacasefrasdasd' });
}

const environmentFeatureFlags = environment.featureFlags;
if (environmentFeatureFlags.length > 0) {
  const enableFeatures = getFeatureFlagsFromStore();
  const featureNames = Object.keys(enableFeatures);
  if (featureNames.length === 0) {
    // ha nincs meg semmi tarolva
    environmentFeatureFlags.forEach(featureFlag => (enableFeatures[featureFlag.name] = featureFlag.enable));
  } else {
    environmentFeatureFlags.forEach(featureFlag => {
      if (enableFeatures[featureFlag.name] === undefined) {
        enableFeatures[featureFlag.name] = featureFlag.enable;
      }
    });
  }
  // kitoroljuk az osszes olyat a store-bol ami nincs environmentben
  const deleteFeatureFlagsFromStore = featureNames.filter(
    featureName => environmentFeatureFlags.findIndex(feature => featureName === feature.name) === -1
  );
  deleteFeatureFlagsFromStore.forEach(deleteFeatureFlag => delete enableFeatures[deleteFeatureFlag]);
  storeFeatureFlags(enableFeatures);

  attachFeatureFlagsInterface(window);
}

// Fix: recaptcha unhandled promise error
RecaptchaComponent.prototype.ngOnDestroy = function () {
  if (this.subscription) {
    this.subscription.unsubscribe();
  }
};

// preload recaptcha
const recaptchaScript = loadJsScript('https://www.google.com/recaptcha/api.js?render=explicit&hl=hu');
recaptchaScript.onload = () => {
  console.log('Recaptcha script loaded.');
};

recaptchaScript.onerror = () => {
  console.error('Could not load the Recaptcha script!');
};

document.head.appendChild(recaptchaScript);

/* tslint:enable */
if (isMobileOrTablet()) {
  window.document.body.classList.add(isTablet ? 'tablet' : 'mobile');
}

if (environment.production) {
  enableProdMode();

  if (environment.mode === 'prod') {
    const bestNonce = getContentSecurityPolicyNonceFromDom();
    const optimonkScript = loadJsScript('https://onsite.optimonk.com/script.js?account=199139', bestNonce);

    optimonkScript.onload = () => {
      console.log('Optimonk script loaded.');
    };

    optimonkScript.onerror = () => {
      console.error('Could not load the Optimonk script!');
    };

    document.head.appendChild(optimonkScript);

    // HelpCrunch
    loadHelpCrunch('roadrecord', '21db87e9-33f2-4bf5-aa83-8057eefce63b');
  }
}

if (userBrowserName === 'chrome') {
  startDOMChangeDetect();
}

const userBrowserIsBot = (uaParserBotBrowser as any).type === 'bot';
console.info('MAIN', 'is outdated browser:', userBrowserIsOutdated);

rrAngularAppBootstrap(environment.mode as any, userBrowserIsBot, () =>
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err))
);
