import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FieldType } from '@ngx-formly/material/form-field';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'rr-mat-password-field',
  template: `
    <input
      matInput
      [id]="id"
      [readonly]="to.readonly"
      [required]="to.required"
      [errorStateMatcher]="errorStateMatcher"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [tabindex]="to.tabindex"
      [placeholder]="to.placeholder"
      [name]="field.key"
      [type]="passwordType"
      #inputElement
    />
  `,
})
export class MaterialPasswordField extends FieldType {
  @ViewChild(MatInput, { static: false }) formFieldControl!: MatInput;
  @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;

  get passwordType() {
    return this.to.passwordType;
  }

  constructor(errorStateMatcher: ErrorStateMatcher) {
    super();
    this.errorStateMatcher = errorStateMatcher as any;
  }
}
