<mat-form-field class="w-100" [formGroup]="parentForm" (click)="dp.open()" rrPreventClick>
  <mat-label>{{ labelTranslateKey | transloco }}</mat-label>
  <input matInput [matDatepicker]="dp" [formControl]="yearMonthControl" [min]="minDate" [max]="maxDate" readonly class="readonly-mode" />
  <mat-icon matPrefix class="light-color calendar-icon" svgIcon="calendar"></mat-icon>
  <mat-icon matSuffix class="light-color" svgIcon="menu-down"></mat-icon>
  <mat-datepicker
    #dp
    startView="year"
    (monthSelected)="chosenMonthHandler($event, $any(dp))"
    panelClass="period-context-year-month-picker"
    [calendarHeaderComponent]="headerCmp"
  >
  </mat-datepicker>
  <mat-error><rr-validation-messages [errors]="yearMonthControl?.errors"></rr-validation-messages></mat-error>
</mat-form-field>
