import { Pipe, PipeTransform } from '@angular/core';
import { PartnerTypeEnum } from '@roadrecord/partner/model';

@Pipe({
  name: 'generateIdFromTypeEnum',
})
export class GenerateIdFromTypeEnumPipe implements PipeTransform {
  transform(value: PartnerTypeEnum, suffix: string): string {
    return `${PartnerTypeEnum.toString(value)}-${suffix}`;
  }
}
