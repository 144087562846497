import { isString } from '@roadrecord/type-guard';

export enum ForeverHideTypesEnum {
  FINALIZATION_OF_ROUTE__JUST_FIX_ROAD = 'finalization-of-route/JUST_FIX_ROAD',
}

export namespace ForeverHideTypesEnum {
  export function getValues(): (keyof typeof ForeverHideTypesEnum)[] {
    const values = [];
    Object.keys(ForeverHideTypesEnum)
      .filter((enumMember: string) => isString(ForeverHideTypesEnum[enumMember]))
      .forEach((enumMember: string) => values.push(ForeverHideTypesEnum[enumMember]));
    return values;
  }
}
