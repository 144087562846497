import { isNil } from '@roadrecord/type-guard';

let menuItemCounter = 0;

interface MenuItemModelInterface {
  active?: boolean;
  readonly uniqId?: string;
  readonly label: string;
  readonly helpTooltip?: string;
  readonly route?: string;
  readonly icon: string;
  readonly disabled?: boolean;
  /**
   * elmentsuk-e a menu select state-be ?
   */
  readonly saveSelectState?: boolean;
  readonly outsideLink?: string;
  readonly subRoute?: string;
  readonly saveSubRoutes?: string[];
  /**
   * amennyiben fallback route-t szeretnenk elmenteni a menupont eseten
   */
  readonly fallbackSaveRoute?: { parentRoute: string; itemRoute: string };
  readonly hide?: boolean;
  readonly hidden?: boolean;
}

export class MenuItemModel implements MenuItemModelInterface {
  active = false;
  readonly uniqId: string;
  readonly label: string;
  readonly helpTooltip?: string;
  readonly route: string;
  readonly icon: string;
  readonly disabled: boolean;
  /**
   * elmentsuk-e a menu select state-be ?
   */
  readonly saveSelectState: boolean = true;
  readonly outsideLink: string;
  readonly subRoute: string;
  readonly saveSubRoutes: string[];
  readonly fallbackSaveRoute: { parentRoute: string; itemRoute: string } = undefined;
  readonly hide = false;
  readonly hidden = false;

  constructor(data?: MenuItemModelInterface) {
    this.disabled = false;
    if (!isNil(data)) {
      Object.assign(this, data);
      if (isNil(this.uniqId)) {
        this.uniqId = `menu-item--${menuItemCounter++}`;
      }
    }
  }
}
