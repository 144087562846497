import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { RRErrorType } from './rr-error-type';
import { isNil } from '@roadrecord/type-guard';

export class RRHttpErrorResponse extends HttpErrorResponse {
  error: RRErrorType;

  constructor(init: { error?: any; headers?: HttpHeaders; status?: number; statusText?: string; url?: string }) {
    super(init);
  }

  hasError() {
    return !isNil(this.error);
  }

  hasMetas() {
    return !isNil(this.error.meta) && Object.keys(this.error.meta).length > 0;
  }

  hasMeta(meta: string) {
    return this.hasMetas() && this.error.meta[meta] !== undefined;
  }

  getMetaValue(meta: string) {
    return this.error.meta[meta];
  }
}
