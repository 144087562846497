<span *ngIf="spriteClasses; else iconTpl" class="sprite-image mb-3" [ngClass]="spriteClasses"></span>
<ng-template #iconTpl>
  <img class="header-icon mb-1" *ngIf="icon" [src]="icon" />
</ng-template>
<h1 mat-dialog-title *ngIf="data?.title">{{ data.title | transloco: data.titleParams }}</h1>
<div mat-dialog-content>
  <ng-container *ngIf="data?.elementRef; else simpleMode">
    <ng-template [cdkPortalOutlet]="data.elementRef | transformNativeRefToPortalRef: data?.elementRefInjector"></ng-template>
  </ng-container>
  <ng-template #simpleMode>
    <ng-container *ngIf="!data.htmlMode; else htmlModeTpl">
      <p class="mt-1" *ngIf="data?.text">
        <ng-container *ngIf="data.translateText === true; else noTranslateTpl">{{ data.text | transloco: data.textParams }}</ng-container>
        <ng-template #noTranslateTpl>{{ data.text }}</ng-template>
      </p>
    </ng-container>
    <ng-template #htmlModeTpl>
      <ng-container *ngIf="data.translateText === true; else noTranslateTpl">
        <div [innerHTML]="data.text | transloco: data.textParams | sanitizeHtml"></div>
      </ng-container>
      <ng-template #noTranslateTpl><div [innerHTML]="data.text | sanitizeHtml"></div></ng-template>
    </ng-template>
  </ng-template>
  <div
    class="text-left mt-3 mb-2 mx-auto"
    [ngClass]="{ 'w-100': data.showSlideToggle?.length > 0 }"
    *ngIf="data.showSlideToggle?.length > 0"
  >
    <mat-slide-toggle color="accent" [disabled]="data.showProgressBar" (change)="onSlideToggleChange($event)">
      <span [innerHTML]="data.showSlideToggle | transloco | sanitizeHtml"></span>
    </mat-slide-toggle>
  </div>
</div>
<div mat-dialog-actions *ngIf="data.enableOk || data.enableCancel || data.enableForeverHideFeature">
  <div
    class="w-100 d-flex"
    [ngStyle]="(data.enableOk && !data.enableCancel) || (!data.enableOk && data.enableCancel) ? { 'justify-content': 'center' } : undefined"
  >
    <button mat-button type="button" [disabled]="data.showProgressBar" (click)="onClickCancel()" *ngIf="data.enableCancel">{{
      data.cancelLabel | transloco
    }}</button>
    <span class="flex-spacer" *ngIf="data.enableCancel && data.enableOk"></span>
    <button
      mat-flat-button
      color="primary"
      [disabled]="data.showProgressBar"
      (click)="onClickOk()"
      cdkFocusInitial
      class="mr-1"
      type="button"
      *ngIf="data.enableOk"
      >{{ data.confirmLabel | transloco }}</button
    >
    <button
      mat-flat-button
      color="accent"
      type="button"
      [disabled]="data.showProgressBar"
      class="mr-1"
      *ngIf="data.afterSaveFirstButton?.show === true"
      (click)="onClickAfterOkFirst()"
      >{{ data.afterSaveFirstButton.label | transloco }}</button
    >
  </div>
  <div class="text-left mt-3 mb-2 mx-auto" *ngIf="data.enableForeverHideFeature">
    <mat-checkbox [formControl]="foreverHideDialogControl" class="forever-hide">
      {{ 'COMMON.DIALOG.FOREVER_HIDE.CHECKBOX.LABEL' | transloco }}
    </mat-checkbox>
  </div>
</div>
<mat-progress-bar color="warn" mode="indeterminate" *ngIf="data.showProgressBar"></mat-progress-bar>
