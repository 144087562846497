import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckModifiedMVPFormGuard, ID_NUMBER_CHECK_PROVIDERS, ParamTypeCheckGuard } from '@roadrecord/utils';
import { CalendarComponent } from './calendar/calendar.component';
import { DayListComponent } from './day-list/day-list.component';
import { DetailsComponent } from './details/details/details.component';
import { OpenNewOrModifyQueryParamCheckGuard } from './open-new-or-modify-query-param-check.guard';
import { InvalidPeriodContextGuard } from '@roadrecord/period-context/common';
import { GetVehicleByPeriodContextResolver, GetVehicleByPeriodContextResolverModule } from '@roadrecord/vehicle/common';
import { isCompanyVehicle } from '@roadrecord/preferences-state/common';
import { AgendaComponent } from './calendar/agenda/agenda.component';
import { CalendarRedirectToListGuard } from '@roadrecord/calendar';

const routes: Routes = [
  { path: '', component: CalendarComponent, canActivate: [CalendarRedirectToListGuard] },
  {
    path: 'import',
    loadChildren: () => import('libs/fueling/common/src/lib/import/fueling-import.module').then(m => m.FuelingImportModule),
    canDeactivate: [InvalidPeriodContextGuard],
  },
  { path: 'list', component: AgendaComponent },
  {
    path: 'day-list',
    component: DayListComponent,
    canActivate: [OpenNewOrModifyQueryParamCheckGuard],
    canDeactivate: [InvalidPeriodContextGuard],
  },
  {
    path: 'new',
    component: DetailsComponent,
    canActivate: [OpenNewOrModifyQueryParamCheckGuard],
    canDeactivate: [InvalidPeriodContextGuard, CheckModifiedMVPFormGuard],
    data: {
      getVehicletByPeriodContextCheckerFn: isCompanyVehicle,
    },
    resolve: {
      periodContextVehicle: GetVehicleByPeriodContextResolver,
    },
  },
  {
    path: 'day-list/:id',
    component: DetailsComponent,
    canActivate: [ParamTypeCheckGuard],
    canDeactivate: [InvalidPeriodContextGuard, CheckModifiedMVPFormGuard],
    data: {
      getVehicletByPeriodContextCheckerFn: isCompanyVehicle,
    },
    resolve: {
      periodContextVehicle: GetVehicleByPeriodContextResolver,
    },
  },
];

@NgModule({
  imports: [GetVehicleByPeriodContextResolverModule.forChild(), RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [ID_NUMBER_CHECK_PROVIDERS, OpenNewOrModifyQueryParamCheckGuard, CalendarRedirectToListGuard],
})
export class FuelingRoutingModule {}
