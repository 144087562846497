import { Provider } from '@angular/core';
import { FORM_BUILDER_CONTROLLER_PROVIDER } from '../form-builder/form-builder.provider';
import { PresenterStateController } from './presenter-state.controller';

export const PRESENTER_STATE_CONTROLLER_PROVIDER: Provider[] = [
  {
    provide: PresenterStateController,
    useClass: PresenterStateController,
  },
  FORM_BUILDER_CONTROLLER_PROVIDER,
];
