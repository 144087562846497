import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutTypeComponent } from './flex-layout-type/flex-layout-type.component';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { registerTranslocoExtension } from './extension/transloco/transloco.extension';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MaterialWrapperFormField } from './ui/material/material-wrapper-form.field';
import { ValidatingModule } from '@roadrecord/validating';
import { MaterialInputField } from './ui/material/material-input.field';
import { MatInputModule } from '@angular/material/input';
import { IsStringPipeModule, PreventClickModule, RRFormGroup } from '@roadrecord/common/common';
import { registerViewRemoteFieldErrorPlugin } from './extension/view-remote-field-error-formly/view-remote-field-error-formly.extension';
import { MaterialPasswordField } from './ui/material/material-password.field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { PasswordSuffixComponent } from './others/password-suffix/password-suffix.component';
import { animationExtension, AnimationWrapperComponent } from './extension/animation/animation-wrapper.component';
import { FieldType } from '@ngx-formly/core/lib/templates/field.type';
import { FormlyFieldCheckbox } from '@ngx-formly/material/checkbox';
import { IMaskModule } from 'angular-imask';
import { RRFormlyForm } from './override/rr-formly-form';

export function _showError(field: FieldType): boolean {
  return (
    field.formControl &&
    field.formControl.invalid &&
    (field.formControl.touched ||
      (field.options.parentForm && field.options.parentForm.submitted) ||
      (field.form && (field.form as RRFormGroup).submitted) ||
      !!(field.field.validation && field.field.validation.show))
  );
}

@NgModule({
  imports: [
    CommonModule,
    FormlyMaterialModule,
    FormlyModule.forRoot({
      types: [
        { name: 'flex-layout', component: FlexLayoutTypeComponent, defaultOptions: { templateOptions: { disableAnimationWrapper: true } } },
        {
          name: 'input',
          component: MaterialInputField,
          wrappers: ['form-field'],
        },
        {
          name: 'password',
          component: MaterialPasswordField,
          wrappers: ['form-field'],
        },
        // TODO: ez miert nem overridolodik?
        {
          name: 'checkbox',
          component: FormlyFieldCheckbox,
          wrappers: [],
        },
      ],
      wrappers: [
        { name: 'form-field', component: MaterialWrapperFormField },
        { name: 'animation', component: AnimationWrapperComponent },
      ],
      extensions: [{ name: 'animation', extension: { onPopulate: animationExtension } }],
      extras: {
        // add checking RRFormGroup submitted flag
        showError: _showError,
      },
    }),
    TranslocoModule,
    /* mat form field wrapper-hez kell */
    ReactiveFormsModule,
    MatFormFieldModule,
    ValidatingModule,
    MatInputModule,
    IsStringPipeModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    PreventClickModule,
    FlexLayoutModule,
    IMaskModule,
  ],
  declarations: [
    FlexLayoutTypeComponent,
    MaterialInputField,
    MaterialWrapperFormField,
    MaterialPasswordField,
    PasswordSuffixComponent,
    AnimationWrapperComponent,
    RRFormlyForm,
  ],
  exports: [FormlyModule, PasswordSuffixComponent, RRFormlyForm],
  providers: [
    { provide: FORMLY_CONFIG, multi: true, useFactory: registerTranslocoExtension, deps: [TranslocoService] },
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: registerViewRemoteFieldErrorPlugin,
    },
  ],
})
export class DynamicFormModule {}
