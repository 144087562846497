import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { MatTabGroup, MatTabHeader } from '@angular/material/tabs';
import { TranslocoService } from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import {
  FINALIZATION_OF_ROUTE_IMPORT_ROUTE_PATH,
  FINALIZATION_OF_ROUTE_ROUTE_PATH,
  MatchesViewportSizes,
  MONTH_ACTIVITIES_PAGE_PATH,
  simpleFadeInAnimation,
  ViewportService,
} from '@roadrecord/common/common';
import { isNil, isNotEmptyString, isNotNilAndString, isString } from '@roadrecord/type-guard';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, skip, switchMap, take, timeout } from 'rxjs/operators';
import { MenuService } from '../menu.service';
import { MenuItemModel } from '../model/menu-item.model';
import { MenuLastRouteStateModel } from '../model/menu-last-route-state.model';
import { MenuPageModel } from '../model/menu-page.model';
import { MenuChangeTabAction } from '../state/action/menu-change-tab.action';
import { MenuState } from '../state/menu.state';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { CompanyContextState, SubscriptionInfoEnum } from '@roadrecord/company-context/common';
import { IS_WEBADMIN } from '@roadrecord/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { INTROJS_TYPES_ENUM, IntrojsState, StartIntroAction } from '@roadrecord/introjs/common';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { AppTypeEnum, environment } from '@roadrecord/environment';

export const trackByFn = (index, item: MenuPageModel) => {
  return item.uniqId;
};

@UntilDestroy()
@Component({
  selector: 'rr-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [simpleFadeInAnimation],
})
export class MenuComponent implements OnInit {
  readonly trackByFn = trackByFn;
  readonly menuPages$: Observable<MenuPageModel[]>;
  loading = true;
  disabledMenuPageGroup: string[];
  @Select(MenuState.open)
  readonly open$: Observable<boolean>;
  @Output()
  readonly closeMenu = new EventEmitter<void>();
  @ViewChild('tabGroup', { static: true })
  tabGroup: MatTabGroup;
  @ContentChildren(MatTabHeader)
  mm: QueryList<MatTabHeader>;
  @Input()
  selectedTabIndex = 0;
  @Input()
  currentBreakpoint: MatchesViewportSizes;
  @Select(MenuState.excluded)
  readonly excludedItems$: Observable<string[]>;
  @Select(MenuState.disabled_menu_page_group)
  readonly disabledMenuPageGroup$: Observable<string[]>;

  @Select(MenuState.disabled_menu_items)
  readonly disabledMenuItems$: Observable<string[]>;
  private changedPeriodContext = false;
  @ViewChild('beforeMenuPanel')
  beforeMenuPanel: ElementRef<HTMLDivElement>;

  @SelectSnapshot(CompanyContextState.subscriptionState)
  readonly subscriptionInfo: SubscriptionInfoEnum;
  @SelectSnapshot(CompanyContextState.subscriptionNumberOfDaysElapsed)
  readonly subscriptionNumberOfDaysElapsed: number;
  SubscriptionInfoEnum = SubscriptionInfoEnum;
  @ViewChild('beforeMenuPanelCardRef', { read: ElementRef }) beforeMenuPanelCardRef: ElementRef<any>;

  readonly isUsAppType = environment.appType === AppTypeEnum.US;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService,
    readonly viewportService: ViewportService,
    private cdr: ChangeDetectorRef,
    private translocoService: TranslocoService,
    private store: Store,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    @Inject(IS_WEBADMIN) readonly isWebadmin: boolean
  ) {
    this.detectPeriodContextChange();
    this.menuPages$ = this.menuService.currentVisibleMenuPages$.pipe(map(pages => pages.filter(page => page.visible)));
    this.watchMenuStateChange(menuService);

    this.disabledMenuPageGroup$
      .pipe(untilDestroyed(this))
      .subscribe(disabledMenuPageGroup => (this.disabledMenuPageGroup = disabledMenuPageGroup ? disabledMenuPageGroup : []));
  }

  private detectPeriodContextChange(): void {
    this.store
      .select(states => (!isNil(states.periodContext.loading) ? states.periodContext.loading : undefined))
      .pipe(
        skip(1),
        distinctUntilChanged(),
        filter(v => v === true),
        untilDestroyed(this)
      )
      .subscribe(() => (this.changedPeriodContext = true));
  }

  // private checkHasAccessCurrentRouteWhenReloadMenu():void {
  //   this.menuService.currentVisibleMenuPages$
  //     .pipe(
  //       takeWhileAlive(this),
  //       switchMap(() => this.menuService.hasMenuItemByUrl()),
  //       filter(v => v === false)
  //     )
  //     .subscribe(() => this.router.navigate(['/']));
  // }

  onCloseMenu(closeCallback?: (menuService: MenuService) => void): void {
    if (closeCallback !== undefined) {
      return closeCallback(this.menuService);
    }
    this.closeMenu.emit();
  }

  onSelectWithoutRoute(item: MenuItemModel): void {
    this.closeSidenavWhenIsMobile();
    this.menuService.clickWithoutRouteNext(item);
  }

  private startFinalizationOfRouteIntro(): void {
    if (this.store.selectSnapshot(CompanyContextState.isTrialTimeExpired)) {
      return;
    }

    let introFilter = INTROJS_TYPES_ENUM.FINALIZATION_OF_ROUTE_DETAILS_COMMON;

    if (this.store.selectSnapshot(this.periodContextStateSelectorsService.isSelfEmployed)) {
      introFilter = INTROJS_TYPES_ENUM.FINALIZATION_OF_ROUTE_DETAILS_SELF_EMPLOYED;
    } else if (this.store.selectSnapshot(this.periodContextStateSelectorsService.isPrivate)) {
      introFilter = INTROJS_TYPES_ENUM.FINALIZATION_OF_ROUTE_DETAILS_PRIVATE;

      if (this.store.selectSnapshot(IntrojsState.notShowedFilter(introFilter))) {
        this.store.dispatch(
          new StartIntroAction(INTROJS_TYPES_ENUM.FINALIZATION_OF_ROUTE_DETAILS, { skipShowedCheck: false, withPeriodContext: true })
        );
      }
    }
  }
  private startMonthActivitiesIntro(): void {
    let introFilter = INTROJS_TYPES_ENUM.MONTH_ACTIVITIES_COMPANY;

    if (this.store.selectSnapshot(this.periodContextStateSelectorsService.isSelfEmployed)) {
      introFilter = INTROJS_TYPES_ENUM.MONTH_ACTIVITIES_SELF_EMPLOYED;
    } else if (this.store.selectSnapshot(this.periodContextStateSelectorsService.isPrivate)) {
      introFilter = INTROJS_TYPES_ENUM.MONTH_ACTIVITIES_PRIVATE;
    }

    if (this.store.selectSnapshot(IntrojsState.notShowedFilter(introFilter))) {
      this.store.dispatch(new StartIntroAction(INTROJS_TYPES_ENUM.MONTH_ACTIVITIES, { skipShowedCheck: false, withPeriodContext: true }));
    }
  }

  onSelectedTabIndexChange(index: number): void {
    this.menuPages$.pipe(untilDestroyed(this), take(1)).subscribe(menuPages => {
      const startIntroQueryParam = this.route.snapshot.queryParamMap.get('startIntro');
      const skipDefaultSelected = this.route.snapshot.queryParamMap.get('skip-default-selected');

      const afterFirstStepper = this.store
        .selectSnapshot(IntrojsState.showed)
        .find(intro => intro === INTROJS_TYPES_ENUM.AFTER_FIRST_STEPPER);

      if (startIntroQueryParam !== 'EXTRA_FINALIZATION_ROUTES_FLOW' && isNil(skipDefaultSelected)) {
        const { defaultRoute, route } = menuPages[index];

        if (isNotEmptyString(defaultRoute)) {
          this.router.navigate(['/', route, defaultRoute]).then(() => {});
        }
      }

      if (this.router.url.startsWith(`/${MONTH_ACTIVITIES_PAGE_PATH}/${FINALIZATION_OF_ROUTE_IMPORT_ROUTE_PATH}`)) {
        return;
      }
    });

    this.selectedTabIndex = index;
    this.store.dispatch(new MenuChangeTabAction(index));

    this.cdr.markForCheck();
  }

  onSelectMenuItem(/*$event: MenuItemModel*/): void {
    this.closeSidenavWhenIsMobile();
  }

  ngOnInit(): void {
    this.watchMenuLoadingIndicator();

    this.menuService.lastSelectedMenuPageUniqId
      .pipe(
        filter(lastSelectedMenuPageUniqId => isString(lastSelectedMenuPageUniqId)),
        switchMap(lastSelectedMenuPageUniqId =>
          this.menuPages$.pipe(
            map(menuPages => menuPages.findIndex(menuPage => menuPage.uniqId === lastSelectedMenuPageUniqId)),
            take(1)
          )
        ),
        untilDestroyed(this)
      )
      .subscribe(lastSelectedMenuPageId => {
        if (this.changedPeriodContext === true) {
          /*
          // ha period context valtas volt akkor direktbe mindig az 1-es page-t allitjuk be
          this.changedPeriodContext = false;
          this.selectedTabIndex = 1;
*/
        } else {
          this.selectedTabIndex = lastSelectedMenuPageId;
        }
        this.cdr.markForCheck();
      });
  }

  private watchMenuLoadingIndicator(): void {
    this.menuPages$.subscribe(menuPages => {
      this.loading = isNil(menuPages) || menuPages.length === 0;
    });
  }

  private closeSidenavWhenIsMobile(): void {
    if (this.currentBreakpoint.mobile === true) {
      this.viewportService.sidenavToggle.emit(false);
    }
  }

  private watchMenuStateChange(menuService: MenuService): void {
    menuService.currentStateChange$.pipe(timeout(1000), first()).subscribe(
      (value: MenuLastRouteStateModel) => {
        if (!isNil(value) && !isNil(value.item) && !isNil(value.parent)) {
          if (value.parent.visible === false) {
            // TODO ez melyik eset?
            this.menuService.visibleOneMenuPage(
              value.parent.uniqId,
              this.translocoService.translate('APP_LAYOUT.NAV_TOOLBAR.BACK_TO_MENU'),
              () => this.menuService.restoreLastState()
            );
          }
          this.selectedTabIndex = this.menuService.menuConfig
            .filter(menuPage => menuPage.visible)
            .findIndex(menuPage => menuPage === value.parent);
        }
      },
      error => () => {
        console.error(error);
      }
    );
  }

  getWrapperHeight() {
    return !isNil(this.beforeMenuPanelCardRef) && !isNil(this.beforeMenuPanelCardRef.nativeElement)
      ? `calc(100% - ${+this.beforeMenuPanelCardRef.nativeElement.offsetHeight + /*margin*/ 2 * 8}px)`
      : '100%';
  }
}
