import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LiveAutoCompleteComponent } from './live-auto-complete/live-auto-complete.component';
import { RawValueDirective } from './raw-value.directive';
import { LoadableModule } from '@roadrecord/external-packages/ngx-loadable';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReactiveFormsModule } from '@angular/forms';
import { HoverStateModule, IsFunctionPipeModule, IsNilPipeModule, PreventClickModule, SanitizePipeModule } from '@roadrecord/common/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HasPrefixPipe } from './live-auto-complete/has-prefix.pipe';
import { DisplaySelectedValuePipe } from './live-auto-complete/display-selected-value.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ValidatingModule } from '@roadrecord/validating';
import { IsEditablePipe } from './is-editable.pipe';
import { HoverEditComponent } from './live-auto-complete/hover-edit.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [LiveAutoCompleteComponent, RawValueDirective, HasPrefixPipe, DisplaySelectedValuePipe, IsEditablePipe, HoverEditComponent],
  imports: [
    CommonModule,
    InfiniteScrollModule,
    LoadableModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    IsFunctionPipeModule,
    IsNilPipeModule,
    MatIconModule,
    TranslocoModule,
    SanitizePipeModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    ValidatingModule,
    PreventClickModule,
    MatButtonModule,
    MatMenuModule,
    HoverStateModule,
  ],
  exports: [LiveAutoCompleteComponent],
})
export class LiveAutoCompleteModule {}
