import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { isEmptyString, isNil } from '@roadrecord/type-guard';
import { IsLazyDetailsInterface } from '../../is-lazy-details.interface';
import { HAS_NOT_INPUT_CONFIG, HAS_NOT_OUTPUT_CONFIG, isLazyDetailsViewCmp } from '../../is-lazy-details-view.interface';
import { LAZY_DETAILS_MODULE_NAME_TOKEN } from '../../lazy-details-module-name.token';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'rr-lazy-details',
  templateUrl: './lazy-details.component.html',
})
export class LazyDetailsComponent implements OnInit, OnChanges, IsLazyDetailsInterface {
  @Input() moduleName: string;
  isLazy = true;
  lazyCmp: any;
  @Input() options: { [p: string]: any };
  @Output() events = new EventEmitter<{ eventName: string; value: any }>();
  private inputConfig: string[] | Symbol;
  private outputConfig: string[] | Symbol;
  private initedOutputs = false;

  constructor(@Optional() @Inject(LAZY_DETAILS_MODULE_NAME_TOKEN) moduleNameToken: string, private route: ActivatedRoute) {
    // ha route-kent van definialva a cmp akkor provide-va jon a moduleName
    if (!isEmptyString(moduleNameToken)) {
      this.moduleName = moduleNameToken;
    }
  }

  lazyInit($event) {
    //{instance: lazyComponent}: ComponentRef</*TODO ide jo lenne tipust megadni de nem tudunk 2-t :(*/any>) {
    this.lazyCmp = $event.instance;
    if (isLazyDetailsViewCmp(this.lazyCmp)) {
      this.inputConfig = this.lazyCmp.inputConfig;
      if (this.inputConfig !== HAS_NOT_INPUT_CONFIG) {
        // ha mar megkaptuk az options-t akkor feldolgozzuk
        if (!isNil(this.options)) {
          this.bindInputsValues();
        }
      }

      this.outputConfig = this.lazyCmp.outputConfig;
      // ha mar megkaptuk az options-t akkor feldolgozzuk
      if (!isNil(this.options)) {
        this.initOutputs();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!isNil(changes['options'])) {
      this.bindInputsValues();
      this.initOutputs();
    }
  }

  ngOnInit(): void {
    if (!isNil(this.route)) {
      if (!isNil(this.route.snapshot.data.lazyModuleName)) {
        this.moduleName = this.route.snapshot.data.lazyModuleName;
      }
    }
  }

  private initOutputs() {
    if (!isNil(this.lazyCmp) && this.initedOutputs === false) {
      this.initedOutputs = true;
      if (this.outputConfig === HAS_NOT_OUTPUT_CONFIG) {
        return;
      }
      if (Array.isArray(this.outputConfig)) {
        this.outputConfig.forEach(outputConfigName => {
          (this.lazyCmp[outputConfigName] as EventEmitter<any>).pipe(untilDestroyed(this)).subscribe(value =>
            this.events.emit({
              eventName: outputConfigName,
              value,
            })
          );
        });
      }
      // auto detect event emitters
      Object.keys(this.lazyCmp).forEach(key => {
        if (this.lazyCmp[key] instanceof EventEmitter) {
          if (Array.isArray(this.outputConfig) && this.outputConfig.indexOf(key) > -1) {
            // Ha letezik a configban, akkor nem kell feliratkozni
            return;
          }
          this.lazyCmp[key].pipe(untilDestroyed(this)).subscribe(value =>
            this.events.emit({
              eventName: key,
              value,
            })
          );
        }
      });
    }
  }

  private bindInputsValues() {
    if (!isNil(this.lazyCmp) && !isNil(this.inputConfig) && this.inputConfig !== HAS_NOT_INPUT_CONFIG && Array.isArray(this.inputConfig)) {
      this.inputConfig.forEach(inputConfigName => {
        const foundOption = Object.entries(this.options).find(optionEntry => optionEntry[0] === inputConfigName);
        if (!isNil(foundOption)) {
          this.lazyCmp[inputConfigName] = foundOption[1];
          // console.info('bind input value:', inputConfigName, '=>', foundOption[1]);
        } else {
          console.warn('missing input config:', inputConfigName);
        }
      });
    }
  }
}
