import { TripReasonModel } from '@roadrecord/trip-reason/model';
import { CompanyModel } from './company.model';
import { LocationModel } from './location.model';
import { PartnerTypeEnum } from './partner-type.enum';

export function partnerFromGeocodingModel(
  geocodingModel: LocationModel,
  fields: string[],
  partnerModel?: Partial<PartnerModel>
): PartnerModel {
  if (partnerModel === undefined) {
    partnerModel = {};
  }
  const newPartner: any = {};
  fields.forEach((propertyName: string) => {
    newPartner[propertyName] = (geocodingModel as any)[propertyName];
  });

  return { ...partnerModel, ...newPartner };
}

export interface PartnerModel {
  id: number;
  address: string;
  city: string;
  company: CompanyModel;
  country: string;
  house_number: string;
  icon: string;
  is_gas_station: boolean;
  is_private: string;
  latitude: number;
  longitude: number;
  local_country: string;
  name: string;
  postcode: string;
  street: string;
  trip_reason: TripReasonModel;
  type: PartnerTypeEnum;
}
