export const HAS_NOT_INPUT_CONFIG = Symbol('HAS_NOT_INPUT_CONFIG');
export const HAS_NOT_OUTPUT_CONFIG = Symbol('HAS_NOT_OUTPUT_CONFIG');

export interface IsLazyDetailsViewInterface {
  inputConfig: string[] | Symbol;
  outputConfig: string[] | Symbol;
}

export function isLazyDetailsViewCmp(obj: any): obj is IsLazyDetailsViewInterface {
  return (
    (Array.isArray(obj['inputConfig']) || obj['inputConfig'] === HAS_NOT_INPUT_CONFIG) &&
    (Array.isArray(obj['outputConfig']) || obj['outputConfig'] === HAS_NOT_OUTPUT_CONFIG)
  );
}
