<div [ngSwitch]="stepperPriceLayout" class="w-100">
  <ng-container *ngSwitchCase="TrialTimeExpiredLayoutEnum.EXPIRED">
    <mat-card *transloco="let t; read: 'USER.TRIAL_TIME.EXPIRED'">
      <mat-card-content>
        <img src="assets/images/expired-trial-time/expired_trial_time.svg" alt="image" />
        <h1 class="w-100 text-center">{{ t('TITLE') }}</h1>
        <p>{{ t('CONTENT') }}</p>
      </mat-card-content>
      <mat-card-actions class="text-center"
        ><a [attr.href]="t('BUTTON.SUPPORT.LINK')" target="_blank" mat-button>{{ t('BUTTON.SUPPORT.LABEL') }}</a
        ><button type="button" mat-raised-button color="accent" (click)="goToPriceList()">{{
          t('BUTTON.SELECT_PACKAGE.LABEL')
        }}</button></mat-card-actions
      >
    </mat-card>
  </ng-container>

  <ng-container *ngSwitchCase="TrialTimeExpiredLayoutEnum.PRICE_LIST">
    <div class="fix-content-area text-center" @fadeIn>
      <rr-order-layout [showInStepper]="true" (backToStepper)="onBackToExpiredForm()"> </rr-order-layout>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="TrialTimeExpiredLayoutEnum.PAYMENT">
    <div class="fix-content-area text-center" @fadeIn>
      <rr-payment [showInStepper]="true" (backToStepper)="onBackToPayment($event)"> </rr-payment>
    </div>
  </ng-container>
</div>
