import { Inject, Injectable, Injector, NgZone, OnDestroy, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import introJs, { Options as IntrojsOptions, Step as IntrojsStep } from 'intro.js';
import { DONT_SHOW_AGAIN_CHECKBOX_ELEMENT_ID, INTROJS_DEFAULT_OPTIONS } from './introjs-default.options';
import { isFunction, isNil, isNotEmptyString, isString } from '@roadrecord/type-guard';
import { TranslocoService } from '@ngneat/transloco';
import { DOCUMENT } from '@angular/common';
import { Element } from '@angular/compiler';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay, take } from 'rxjs/operators';
import { WINDOW } from '@roadrecord/common/common';
import { IntrojsState } from './state/introjs.state';
import { Store } from '@ngxs/store';

export const IsIntrojsElementProxyKey = Symbol('__is_introjs_element_proxy');
export const HasPatchIntrojsGetComputedStyle = Symbol('__has_patch_introjs_get_computed_style');
export type IntrojsElementProxyType = ProxyHandler<HTMLElement> & { nativeElement: HTMLElement; lazyLoadElement: () => void };

/**
 * Waits for an element satisfying selector to exist, then resolves promise with the element.
 * Useful for resolving race conditions.
 *
 * source: https://gist.github.com/jwilson8767/db379026efcbd932f64382db4b02853e
 *
 * @param selector
 * @returns {Promise}
 */
export function elementReady(selector: string | HTMLElement | Element) {
  return new Promise<HTMLElement>((resolve, reject) => {
    // mar letezik?
    if (isString(selector)) {
      const el = document.querySelector(selector);
      if (el) {
        resolve(el as HTMLElement);
      }
    } else if (!isNil((selector as any).nativeElement)) {
      resolve((selector as any).nativeElement);
    }
    // figyeljuk a dom valtozast
    new MutationObserver((mutationRecords, observer) => {
      if (isString(selector)) {
        // Query for elements matching the specified selector
        Array.from(document.querySelectorAll(selector)).forEach(element => {
          resolve(element as HTMLElement);
          //Once we have resolved we don't need the observer anymore.
          observer.disconnect();
        });
      } else {
        if (!isNil((selector as any).nativeElement)) {
          resolve((selector as any).nativeElement);
          //Once we have resolved we don't need the observer anymore.
          observer.disconnect();
        }
      }
    }).observe(document.documentElement, {
      childList: true,
      subtree: true,
      characterData: true,
      // ng animacio bevarasa miatt kell
      attributes: true,
      // ng animacio bevarasa miatt kell
      attributeFilter: ['class'],
    });
  });
}

function getProxyHandler(selector: string): IntrojsElementProxyType {
  return {
    nativeElement: null,

    get(target: HTMLElement, p: PropertyKey, receiver: any): any {
      this.lazyLoadElement();
      if (p === 'nativeElement') {
        return this.nativeElement;
      }

      if (this.nativeElement === null) {
        if (p === 'nodeType') {
          return 'undefined';
        }
      }
      if (isFunction(this.nativeElement[p])) {
        return this.nativeElement[p].bind(this.nativeElement);
      }
      if (p === IsIntrojsElementProxyKey) {
        return true;
      }
      return this.nativeElement[p];
    },

    set(target: HTMLElement, p: PropertyKey, value: any, receiver: any): boolean {
      this.lazyLoadElement();
      return (this.nativeElement[p] = value);
    },

    lazyLoadElement() {
      const nativeElement = document.querySelector(selector);
      // animacio alatt varunk
      if (!isNil(nativeElement) && !nativeElement.classList.contains('ng-animating')) {
        this.nativeElement = nativeElement;
      }
    },
  };
}

export const onBeforeStartCallbackName = 'onBeforeStart';

export interface CallbackOptions {
  onExit?: (document: Document, injector: Injector) => void;
  onComplete?: () => void;
  [onBeforeStartCallbackName]?: (injector: Injector) => Promise<unknown> | void;
}

// fix missing title in type
export type Step = IntrojsStep & { title: string } & {
  /**
   * Ha az elem meg nincs jelen a betolteskor, de tudjuk elore a selectort
   */
  lazyAttachElement?: boolean;
  /**
   * Ha teljesen dinamikusan akarjuk osszerakni a selector-t,
   * viszont ebben az esetben az `element` kulcsot nem kell megadni
   */
  dynamicSelector?: (step: Step, document: Document) => string | HTMLElement | Element;
  prevLabel?: string;
  nextLabel?: string;
  doneLabel?: string;
  onBeforeChange?: (element: HTMLElement, step: Step, document: Document) => void;
  onAfterChange?: (element: HTMLElement, step: Step, document: Document) => void;
  onAfterClick?: (nextStep: Step, document: Document, injector: Injector) => void;
  waitAfterClick?: number;
  excludeCb?: (injector: Injector) => boolean;
};

export type Options = Omit<IntrojsOptions, 'steps'> & {
  steps: Step[];
  helperElementPadding: number;
  abortLabel?: string;
  dontShowAgainLabel?: string;
  stepOfLabel?: string;
};

@UntilDestroy()
@Injectable()
export class IntrojsService implements OnDestroy {
  private static _INSTANCE: IntrojsService | null = null;

  private enable$ = new BehaviorSubject(false);
  private introJS: introJs.IntroJs;
  private readonly dontShowCheckboxId = DONT_SHOW_AGAIN_CHECKBOX_ELEMENT_ID;
  private _dontShowAgainCheckboxValue: boolean;
  private _run = false;

  static get INSTANCE(): IntrojsService | null {
    return this._INSTANCE;
  }

  get run(): boolean {
    return this._run;
  }

  constructor(
    @Inject(INTROJS_DEFAULT_OPTIONS) private _defaultOptions: Options,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private ngZone: NgZone,
    private injector: Injector,
    private translocoService: TranslocoService,
    private renderer: Renderer2,
    store: Store
  ) {
    this._dontShowAgainCheckboxValue = store.selectSnapshot(IntrojsState.showedCurrentId);
    IntrojsService._INSTANCE = this;
  }

  /**
   * azert van engedelyezve, mert igy pl egy olyan rendszerben ahol on the fly lehet nyelvet valtani,
   * at tudjak irni a labeleket
   * @param value
   */
  set defaultOptions(value: Options) {
    this._defaultOptions = value;
  }

  get enable(): Observable<boolean> {
    return this.enable$.asObservable();
  }

  get enableCurrent(): boolean {
    return this.enable$.getValue();
  }

  get dontShowAgainCheckboxValue(): boolean {
    return this._dontShowAgainCheckboxValue;
  }

  start(steps?: Step[], callbacksOptions?: CallbackOptions, globalOptions?: IntrojsOptions) {
    this._run = true;
    this.patchWindow();

    if (this.enableCurrent === false) {
      this.enable$.next(true);
      this.ngZone.runOutsideAngular(async () => {
        await this.initIntroJS(steps, callbacksOptions, globalOptions);
        if (isFunction(callbacksOptions[onBeforeStartCallbackName])) {
          const maybePromise = callbacksOptions[onBeforeStartCallbackName](this.injector);
          if (maybePromise instanceof Promise) {
            await maybePromise;
          }
        }
        this.introJS.start();
      });
    }
  }

  private async initIntroJS(steps?: Step[], callbacksOptions?: CallbackOptions, globalOptions?: IntrojsOptions) {
    await this.initNativeIntroJS();
    if (!isNil(globalOptions)) {
      this.introJS.setOptions(globalOptions);
    }
    const options = this.initIntroJSOptions(steps);
    this.initIntroJSCallbacks(options, callbacksOptions);
  }

  private initIntroJSCallbacks(options: Options, callbacksOptions?: CallbackOptions) {
    this.handleOptionCallback(options, 'onbeforechange', 'onBeforeChange');
    this.handleOptionCallback(options, 'onafterchange', 'onAfterChange', (element, currentStep, document) => {
      /**
       * TODD on the fly exclude
       *
       * elore tekinteni az idoben, vagyis megnezni a kovi currentStep-et hogy van-e
       * es ha igen akkor van-e benne exclude metodus, ha van akkor el kell futtatni
       * es ha true akkor a kovi steppet at kell ugrani, viszont ebben az esetben az azutanit is meg kell vizsgalni
       * mindaddig amig false az eredmeny vagy nincs exclude vagy elfogytak a steppek.
       *
       */

      const originalOptions = this.introJS['_options'];
      let currentStepNumber = this.introJS['_currentStep'];
      // add abort button
      if (currentStepNumber === 0) {
        const button = this.document.createElement('A');
        button.setAttribute('role', 'button');
        button.tabIndex = 0;
        button.appendChild(this.document.createTextNode(originalOptions.abortLabel));
        button.classList.add('introjs-abortbutton', 'introjs-button');

        this.renderer.listen(button, 'click', event => {
          event.stopPropagation();
          event.preventDefault();
          this._dontShowAgainCheckboxValue = true;
          this.introJS.exit(true);
        });

        const tooltipButtons = this.document.querySelector('.introjs-tooltipbuttons');
        tooltipButtons.prepend(button);
        //this.insertDontShowAgainCheckbox(originalOptions, button);
      } else if (currentStepNumber === this.introJS['_introItems'].length - 1) {
        this.document.querySelector('.introjs-abortbutton').remove();
      }

      // set translated labels
      let nextButtonElementRef: HTMLAnchorElement;
      let _element: HTMLElement = document.querySelector('.introjs-prevbutton');
      if (_element !== null) {
        _element.innerHTML = isString(currentStep.prevLabel) ? currentStep.prevLabel : originalOptions.prevLabel;
      }
      nextButtonElementRef = document.querySelector('.introjs-nextbutton');
      if (nextButtonElementRef !== null) {
        nextButtonElementRef.innerHTML = isString(currentStep.nextLabel) ? currentStep.nextLabel : originalOptions.nextLabel;
      }
      _element = document.querySelector('.introjs-donebutton');
      if (_element !== null) {
        _element.innerHTML = isString(currentStep.doneLabel) ? currentStep.doneLabel : originalOptions.doneLabel;
      }
      _element = document.querySelector('.introjs-helperNumberLayer');
      if (_element !== null) {
        const html = this.translocoService.translate(originalOptions.stepOfLabel, {
          current: currentStepNumber + 1,
          max: this.introJS['_introItems'].length,
        });
        _element.innerHTML = html;
        /**
         * Erre itt azert van szukseg, mert masodik lepestol 350ms-es eltolassal csereli ki a szoveget :(
         * es nincs ra hook :(
         */
        timer(350).subscribe(() => (_element.innerText = html));
      }

      // attach progress spinner loader
      const introjsTooltipElement = document.querySelector('.introjs-tooltip');
      if (isNil(introjsTooltipElement['__added_loader_']) || introjsTooltipElement['__added_loader_'] === false) {
        introjsTooltipElement['__added_loader_'] = true;

        const wrapperElement = document.createElement('div') as HTMLDivElement;
        wrapperElement.classList.add('introjs-loader');

        const lineElement = document.createElement('div') as HTMLDivElement;
        lineElement.classList.add('line');
        wrapperElement.appendChild(lineElement);

        const sublineIncElement = document.createElement('div') as HTMLDivElement;
        sublineIncElement.classList.add('subline');
        sublineIncElement.classList.add('inc');
        wrapperElement.appendChild(sublineIncElement);

        const sublineDecElement = document.createElement('div') as HTMLDivElement;
        sublineDecElement.classList.add('subline');
        sublineDecElement.classList.add('dec');
        wrapperElement.appendChild(sublineDecElement);

        introjsTooltipElement.appendChild(wrapperElement);
      }

      const loaderElement = document.querySelector('.introjs-loader') as HTMLDivElement;
      if (nextButtonElementRef !== null) {
        if (
          isNil(nextButtonElementRef['__patch_repleced_click_to_async__']) ||
          nextButtonElementRef['__patch_replaced_click_to_async__'] === false
        ) {
          this.ngZone.runOutsideAngular(() => {
            nextButtonElementRef['__patch_repleced_click_to_async__'] = true;
            // zonejs miatt :(
            const originalClick = (nextButtonElementRef as any)['__zone_symbol__ON_PROPERTYclick'];
            let run = false;

            const finishCb = event => {
              const cb = () => {
                run = false;
                nextButtonElementRef.classList.remove('introjs-disabled');
                loaderElement.style.display = 'none';
                originalClick.call(nextButtonElementRef, event);
                setTimeout(() => window.dispatchEvent(new Event('resize')), 300);
              };
              this.ngZone.run(() => {
                if (this.ngZone.isStable) {
                  cb();
                } else {
                  this.ngZone.onStable.pipe(take(1), /* wait animation rendering */ delay(0), untilDestroyed(this)).subscribe(() => cb());
                }
              });
            };
            // vanilin js: const originalClick = (nextButtonElementRef as any)('click')[0];
            /**
             * kivesszuk a regi eventet, es egy sajatot rakunk be amiben wrappoljuk es lehetoseget adunk async muveletre
             * tovabba kepesek vagyunk bevarni nem letezo elem megjeleneset
             */
            // console.log(
            //   'eventListeners before remove original click',
            //   (nextButtonElementRef as any).eventListeners.length,
            //   (nextButtonElementRef as any).eventListeners('click')
            // );
            nextButtonElementRef.removeEventListener('click', (nextButtonElementRef as any).eventListeners('click')[0]);
            //sentry fix
            if ((nextButtonElementRef as any).eventListeners('click').length === 1) {
              nextButtonElementRef.removeEventListener('click', (nextButtonElementRef as any).eventListeners('click')[0]);
            }
            // console.log(
            //   'eventListeners before add my click',
            //   (nextButtonElementRef as any).eventListeners.length,
            //   (nextButtonElementRef as any).eventListeners('click')
            // );
            nextButtonElementRef.addEventListener('click', event => {
              if (run) {
                return;
              }
              // update state variables
              currentStepNumber = this.introJS['_currentStep'];
              const _currentStep = this.introJS['_introItems'][currentStepNumber];
              if (/* nem az utolso elem*/ originalOptions.steps.length !== currentStepNumber + 1) {
                const nextStep = this.introJS['_introItems'][currentStepNumber + 1];
                // dynamic selector kezelese
                if (isFunction(nextStep.dynamicSelector)) {
                  const result = nextStep.dynamicSelector(nextStep, document);
                  if (isString(result)) {
                    nextStep.element = this.createHTMLProxyElement(result);
                  } else {
                    nextStep.element = result;
                  }
                }

                if (isFunction(_currentStep.onAfterClick)) {
                  _currentStep.onAfterClick(nextStep, document, this.injector);
                }
                // lazy eleme kezelese
                if (nextStep.lazyAttachElement) {
                  // show loader
                  run = true;
                  nextButtonElementRef.classList.add('introjs-disabled');
                  loaderElement.style.display = 'block';

                  return elementReady(nextStep.element)
                    .then(nativeElement =>
                      isNil(_currentStep.waitAfterClick)
                        ? nativeElement
                        : new Promise(resolve => setTimeout(() => resolve(nativeElement), _currentStep.waitAfterClick))
                    )
                    .then(nativeElement => {
                      // mivel megvan a native element, ezert kicsereljuk
                      nextStep.element = nativeElement;
                      finishCb(event);
                    });
                }
              }
              finishCb(event);
            });
          });
        }
      }
    });

    this.introJS.oncomplete(() =>
      this.ngZone.run(() => {
        this._run = false;
        this._dontShowAgainCheckboxValue = true;
        if (!isNil(callbacksOptions) && isFunction(callbacksOptions.onComplete)) {
          callbacksOptions.onComplete();
        }
      })
    );
    this.introJS.onexit(() =>
      this.ngZone.run(() => {
        this._run = false;
        if (!isNil(callbacksOptions) && isFunction(callbacksOptions.onExit)) {
          callbacksOptions.onExit(this.document, this.injector);
        }
        this.enable$.next(false);
        delete this.introJS;
      })
    );
  }

  private insertDontShowAgainCheckbox(originalOptions, button: HTMLElement) {
    if (isNotEmptyString(originalOptions.dontShowAgainLabel)) {
      const checkboxElement = this.document.createElement('input') as HTMLInputElement;
      const labelElement = this.document.createElement('label') as HTMLLabelElement;
      const wrapperElement = this.document.createElement('div') as HTMLDivElement;
      checkboxElement.type = 'checkbox';
      checkboxElement.style.width = '16px';
      checkboxElement.style.height = '16px';
      checkboxElement.style.verticalAlign = 'middle';
      checkboxElement.style.marginRight = '0.6em';
      checkboxElement.id = this.dontShowCheckboxId;
      checkboxElement.checked = this._dontShowAgainCheckboxValue;
      (labelElement as any).for = 'introjs-hide-forever-checkbox';
      labelElement.style.verticalAlign = 'middle';
      labelElement.appendChild(this.document.createTextNode(originalOptions.dontShowAgainLabel));
      wrapperElement.appendChild(checkboxElement);
      wrapperElement.appendChild(labelElement);
      button.after(wrapperElement);

      this.renderer.listen(checkboxElement, 'change', event => (this._dontShowAgainCheckboxValue = event.target.checked));
    }
  }

  private handleOptionCallback(
    options: Options,
    introJSCallbackName: string,
    libCallbackName: string,
    beforeCb?: (element: HTMLElement, step: Step, document: Document) => void
  ) {
    this.introJS[introJSCallbackName](element => {
      const step: Step = options.steps[this.introJS.currentStep()] as Step;
      if (isFunction(beforeCb)) {
        beforeCb(element, step, this.injector.get(DOCUMENT));
      }
      if (isFunction(step[libCallbackName])) {
        this.ngZone.run(() => step[libCallbackName](element, step, this.document));
      }
    });
  }

  private initIntroJSOptions(steps: Step[]) {
    let options: Options;
    // Global exclude elem szures
    steps = steps.filter(step => (isFunction(step.excludeCb) ? step.excludeCb(this.injector) === false : true));

    // Lazy element attach proxy
    if (Array.isArray(steps)) {
      options = {
        ...this._defaultOptions,
        steps: steps.map(step => {
          if (step.lazyAttachElement === true && !isFunction(step.dynamicSelector)) {
            step.element = this.createHTMLProxyElement(step.element as string);
          } else if (isFunction(step.dynamicSelector)) {
            step.element = /*placeholder*/ {} as any; //this.createHTMLProxyElement('');
          }
          return step;
        }),
      };
    } /* else {
      // auto by directives
      options = { ...this._defaultOptions };
    }*/
    this.translateOptions(options);

    this.introJS.setOptions(options);
    return options;
  }

  private createHTMLProxyElement(selector: string) {
    return new Proxy<HTMLElement>({} as any, getProxyHandler(selector));
  }

  private translateOptions(options: Options) {
    options.steps = options.steps.map(step => {
      this.translateLabel(step, 'title');
      this.translateLabel(step, 'intro');

      this.translateLabel(step, 'prevLabel');
      this.translateLabel(step, 'nextLabel');
      this.translateLabel(step, 'doneLabel');

      return step;
    });
    this.translateLabel(options, 'prevLabel');
    this.translateLabel(options, 'nextLabel');
    this.translateLabel(options, 'dontShowAgainLabel');
    this.translateLabel(options, 'doneLabel');
    this.translateLabel(options, 'abortLabel');
  }

  private translateLabel<T, K extends keyof T>(obj: T, key: K) {
    if (isString(obj[key])) {
      this.translocoService.selectTranslation(this.translocoService.getActiveLang()).subscribe(translates => {
        if (!isNil(translates[(obj[key] as unknown) as string])) {
          // Azert igy forditjuk, mert translate fajlban lehetnek mutatok mas forditasokra!
          obj[key] = this.translocoService.translate((obj[key] as unknown) as string);
        } else {
          delete obj[key];
        }
      });
    }
  }

  private async initNativeIntroJS() {
    const _introJS: { default: typeof introJs } = await import('intro.js');
    this.introJS = _introJS.default('#introjs');
  }

  private patchWindow() {
    if (window[HasPatchIntrojsGetComputedStyle] === undefined) {
      const oldFn = window.getComputedStyle;
      window.getComputedStyle = function (element: HTMLElement) {
        if (element[IsIntrojsElementProxyKey] === true) {
          return oldFn(((element as unknown) as IntrojsElementProxyType).nativeElement);
        }
        return oldFn(element);
      };
      window[HasPatchIntrojsGetComputedStyle] = true;
    }
  }

  ngOnDestroy() {
    if (!isNil(this.introJS)) {
      delete this.introJS;
    }
    IntrojsService._INSTANCE = null;
  }
}
