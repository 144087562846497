import moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { isNil } from '@roadrecord/type-guard';

export function updateFirstDayOfWeek(day: number, dateAdapter?: DateAdapter<unknown>) {
  moment.updateLocale('hu', {
    week: {
      dow: day + 1,
    },
  } as any);
  if (!isNil(dateAdapter)) {
    dateAdapter.setLocale((dateAdapter as any).locale);
  }
}
