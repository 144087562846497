import { AbstractControl } from '@angular/forms';
import { isFunction, isString } from '@roadrecord/type-guard';
import { Moment } from 'moment';

export function addControlError(control: AbstractControl, errorId: string, value: any): void {
  if (!control.errors) {
    control.setErrors({ [errorId]: value });
    control.markAsTouched();
  } else if (!control.hasError(errorId)) {
    control.setErrors({ ...control.errors, [errorId]: value });
    control.markAsTouched();
  }
}

export function removeControlError(control: AbstractControl, errorId: string): void {
  if (control.errors && control.hasError(errorId)) {
    delete control.errors[errorId];
    if (Object.keys(control.errors).length === 0) {
      control.setErrors(undefined);
    }
  }
}

function checkControlIsValid(startControl: AbstractControl, endControl: AbstractControl): any {
  const startControlValue: string | Moment | null = startControl.value;
  const endControlValue: string | Moment | null = endControl.value;

  const endControlParsedValue = !isString(endControlValue) ? null : parseInt(endControlValue.replace(':', ''), 0);
  if (
    startControlValue !== null &&
    endControlValue !== null &&
    isString(startControlValue) &&
    isString(endControlValue) &&
    (startControlValue.length === 5 || startControlValue.length === 8) &&
    (endControlValue.length === 5 || endControlValue.length === 8) &&
    endControlParsedValue !== 0 &&
    parseInt(startControlValue.replace(':', ''), 0) > endControlParsedValue
  ) {
    addControlError(startControl, 'notEqualTimes', true);
    addControlError(endControl, 'notEqualTimes', true);

    return { notEqualTimes: true };
  } else {
    removeControlError(startControl, 'notEqualTimes');
    removeControlError(endControl, 'notEqualTimes');

    return undefined;
  }
}

/**
 *
 * @param startControl - start time form control
 * @param endControl - end time form control
 * @param [hasCheckCb] - (optional) callback megadasi lehetoseg amivel meg tudjuk akadalyozni hogy
 * lefusson a validator amig nem akarjuk
 */
export function startAndEndTimeValidator(startControl: AbstractControl, endControl: AbstractControl, hasCheckCb?: () => boolean): any {
  return (group: AbstractControl): { [key: string]: any } => {
    if (isFunction(hasCheckCb)) {
      if (hasCheckCb()) {
        return checkControlIsValid(startControl, endControl);
      }
    } else {
      return checkControlIsValid(startControl, endControl);
    }
    return undefined;
  };
}
