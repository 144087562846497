import { Pipe, PipeTransform } from '@angular/core';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

@Pipe({
  name: 'transformNativeRefToPortalRef',
})
export class TransformNativeRefToPortalRefPipe implements PipeTransform {
  transform(cmp: any, portalInjector?: PortalInjector): any {
    return new ComponentPortal<any>(cmp, null, portalInjector);
  }
}
