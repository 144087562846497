import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  CHECK_MODIFIED_MANUAL_FORM_GUARD_OPTIONS,
  CheckModifiedManualFormGuard,
  CheckModifiedManualFormGuardOptions,
} from '@roadrecord/utils';
import { CheckIsRunningProcessGuard } from './guard/check-is-running-process.guard';
import { LeaveCancelGuard } from './guard/leave-cancel.guard';
import { RecommendationComponent } from './recommendation/recommendation/recommendation.component';
import { SettingsComponent } from './settings/settings.component';
import { InvalidPeriodContextGuard } from '@roadrecord/period-context/common';
import { CloseDialogsBeforeRouteGuard, PreferencesContentWindowTypeEnum } from '@roadrecord/preferences-state/common';

const routes: Routes = [
  { path: 'settings', component: SettingsComponent, canDeactivate: [InvalidPeriodContextGuard, CheckModifiedManualFormGuard] },
  {
    path: 'process',
    component: RecommendationComponent,
    canActivate: [CheckIsRunningProcessGuard, CloseDialogsBeforeRouteGuard],
    canDeactivate: [InvalidPeriodContextGuard, LeaveCancelGuard],
    data: {
      closeWindowKeys: [PreferencesContentWindowTypeEnum.ALL],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    CheckIsRunningProcessGuard,
    LeaveCancelGuard,
    CheckModifiedManualFormGuard,
    CloseDialogsBeforeRouteGuard,
    {
      provide: CHECK_MODIFIED_MANUAL_FORM_GUARD_OPTIONS,
      useValue: {
        hasCustomSaveImplementation: true,
      } as CheckModifiedManualFormGuardOptions,
    },
  ],
})
export class RecommendationRoutingModule {}
