<ng-template #poimoveFormTpl>
  <ng-template [ngIf]="pois?.length > 1" [ngIfElse]="onePoi">
    <form [formGroup]="form">
      <div class="d-flex flex-row flex-spacer" style="min-height: 35px">
        <rr-two-way-switch-form-control
          [formControl]="twoWaySwitchControl"
          option_true="PARTNER.DETAILS.POI_MOVE_DIALOG.BUTTON.SUBMIT"
          option_false="PARTNER.DETAILS.POI_MOVE_DIALOG.BUTTON.SUBMIT_WITHOUT_ADDRESS_CHANGE"
          fxFlex="100%"
          fxFlex.gt-md="100%"
        >
        </rr-two-way-switch-form-control>
      </div>

      <ng-container *ngIf="visibleAddressList === true">
        <mat-form-field class="wid100">
          <mat-label>
            {{
              (addressControl.value
                ? 'PARTNER.DETAILS.POI_MOVE_DIALOG.TO_MANY_ADDRESS.LABEL.SELECTED_ADDRESS'
                : 'PARTNER.DETAILS.POI_MOVE_DIALOG.TO_MANY_ADDRESS.LABEL.SELECT_FROM_ADDRESS'
              ) | transloco
            }}
          </mat-label>
          <mat-select
            #addressMatSelect
            [placeholder]="
              (addressControl.value
                ? 'PARTNER.DETAILS.POI_MOVE_DIALOG.TO_MANY_ADDRESS.LABEL.SELECTED_ADDRESS'
                : 'PARTNER.DETAILS.POI_MOVE_DIALOG.TO_MANY_ADDRESS.LABEL.SELECT_FROM_ADDRESS'
              ) | transloco
            "
            name="address"
            panelClass="address"
            formControlName="address"
          >
            <mat-option *ngFor="let poi of pois" [value]="poi.hash"> {{ poi.address }} </mat-option>
          </mat-select>
          <!--
          <mat-hint *ngIf="!addressControl.value">
            {{ 'PARTNER.DETAILS.POI_MOVE_DIALOG.TO_MANY_ADDRESS.HINT.SELECT_FROM_ADDRESS' | transloco }}
          </mat-hint>
-->
        </mat-form-field>
      </ng-container>
    </form>
  </ng-template>
  <ng-template #onePoi>
    {{ 'PARTNER.DETAILS.POI_MOVE_DIALOG.ONE_ADDRESS.CONTENT' | transloco }}
    <strong *ngIf="pois?.length > 0">{{ pois[0]?.address }}</strong>
  </ng-template>
</ng-template>
<ng-template #poimoveButtonsTpl>
  <button type="button" mat-raised-button color="primary" [disabled]="form.disabled" matTooltipPosition="above" (click)="onSubmit()">
    {{ 'COMMON.ACTION.SAVE' | transloco }}
  </button>
</ng-template>
