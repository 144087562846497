import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NumberPipe } from './number.pipe';

@NgModule({
  declarations: [NumberPipe],
  exports: [NumberPipe],
  imports: [CommonModule],
})
export class NgOverrideNumberPipeModule {}
