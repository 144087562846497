import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormArray, FormControl, Validators } from '@angular/forms';
import { isNil } from '@roadrecord/type-guard';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UniversalImportHeadersModel } from '../../../model/universal-import-headers.model';
import { UNIVERSAL_IMPORT_CONFIG, UniversalImportConfig } from '../../../model/universal-import-config';
import { UniversalImportAssociatedHeadersHeaderModel } from '@roadrecord/worker/universal-import';

@Component({
  selector: 'rr-association-headers-step',
  templateUrl: './association-headers-step.component.html',
  styleUrls: ['./association-headers-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociationHeadersStepComponent implements OnChanges {
  @Input() userHeaders: string[];
  @Input() requireHeaders: UniversalImportHeadersModel;
  @Input() loading: boolean;
  @Input() submitError: any;
  @Output() submitHeaders = new EventEmitter<UniversalImportAssociatedHeadersHeaderModel[]>();
  @Output() backToFileUpload = new EventEmitter<void>();

  formArray: FormArray & { submitted: boolean } = new FormArray([]) as any;
  checkboxControls: FormControl[] = [];

  private disabledCheckboxes: AbstractControl[];

  constructor(private cdr: ChangeDetectorRef, @Inject(UNIVERSAL_IMPORT_CONFIG) readonly importConfig: UniversalImportConfig<any>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loading !== undefined) {
      if (changes.loading.currentValue === false) {
        this.formArray.enable();
      } else {
        this.formArray.disable();
      }
    }
    if (changes.submitError !== undefined && this.disabledCheckboxes !== undefined && this.disabledCheckboxes.length > 0) {
      for (const control of this.formArray.controls) {
        if (this.disabledCheckboxes.indexOf(control) > -1) {
          control.disable();
        }
      }
    }
    if (changes.userHeaders !== undefined) {
      const maybeNewUserHeaders: string[] = changes.userHeaders.currentValue;
      if (Array.isArray(maybeNewUserHeaders)) {
        // clear form array
        while (0 !== this.formArray.length) {
          this.formArray.removeAt(0);
        }
        this.requireHeaders.headers.forEach((requireHeader, requireHeaderIndex) => {
          const formControl = new FormControl(
            // egyezo kivalasztasa
            maybeNewUserHeaders.find(userHeader => requireHeader.name === userHeader),
            requireHeader.validators.req ? Validators.required : []
          );
          this.formArray.push(formControl);
          if (requireHeader.validators.req === false) {
            const checkboxControl = new FormControl(true);
            checkboxControl.valueChanges.pipe(distinctUntilChanged(), debounceTime(100)).subscribe(checked => {
              const _formControl = this.formArray.at(requireHeaderIndex);
              checked ? _formControl.enable() : _formControl.disable();
              this.cdr.detectChanges();
            });
            this.checkboxControls[requireHeaderIndex] = checkboxControl;
          }
        });
        this.userHeaders = maybeNewUserHeaders.filter(header => !isNil(header));
      }
    }
  }

  onSubmit() {
    this.formArray.submitted = true;
    if (!this.formArrayInvalid) {
      // store disabled checkboxes
      this.disabledCheckboxes = [];
      for (const control of this.formArray.controls) {
        if (control.disabled) {
          this.disabledCheckboxes.push(control);
        }
      }
      // submit flow
      const headers: UniversalImportAssociatedHeadersHeaderModel[] = [];
      const arrayLength = this.formArray.length;
      for (let i = 0; i < arrayLength; i++) {
        const control: AbstractControl = this.formArray.at(i);
        if (control.enabled) {
          headers.push({
            requiredHeader: this.requireHeaders.headers[i],
            userHeader: control.value,
          });
        }
      }
      this.submitHeaders.emit(headers);
    }
  }

  get formArrayInvalid() {
    return this.formArray.controls.find(control => control.invalid) !== undefined;
  }

  onBack() {
    this.backToFileUpload.emit();
  }
}
