import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { DetailsModule as TripReasonDetailsModule } from '../details/details.module';
import { ModalWrapperComponent } from './modal-wrapper/modal-wrapper.component';

@NgModule({
  imports: [CommonModule, TripReasonDetailsModule, TranslocoModule],
  declarations: [ModalWrapperComponent],
  exports: [ModalWrapperComponent],
  entryComponents: [ModalWrapperComponent],
})
export class ModalWrapperModule {}
