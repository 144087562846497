import { Inject, NgModule } from '@angular/core';
import { TRANSLOCO_TRANSPILER, TranslocoModule, TranslocoService, TranslocoTranspiler } from '@ngneat/transloco';
import { translocoServiceLocator } from './transloco-service.locator';
import { CommonModule } from '@angular/common';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';

@NgModule({
  imports: [CommonModule, TranslocoModule, TranslocoMessageFormatModule.init()],
})
export class TranslocoUtilsModule {
  constructor(_translocoService: TranslocoService, @Inject(TRANSLOCO_TRANSPILER) parser: TranslocoTranspiler) {
    translocoServiceLocator.service = _translocoService;
    translocoServiceLocator.parser = parser;
  }
}
