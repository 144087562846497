<div class="scroll">
  <div class="wrapper-component">
    <div fxLayout="row" fxLayoutAlign="center center" fxFlex.gt-sm>
      <div fxLayout="column" fxLayoutAlign="center center" class="wid100">
        <mat-card class="rr-card2 fix-actions-row-height login" data-cy="login-card">
          <mat-progress-bar color="warn" mode="indeterminate" *ngIf="form.disabled"></mat-progress-bar>
          <mat-card-header>
            <mat-card-title data-cy="login-card--title">
              <mat-icon svgIcon="key"></mat-icon>
              <span>{{ 'USER.LOGIN.LOGIN' | transloco }}</span>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <form
              data-cy="login-card--form"
              [formGroup]="form"
              novalidate
              #formElem
              (keyup.enter)="onKeyupForm($event)"
              fxLayout="column"
              fxLayoutAlign="center stretch"
            >
              <mat-form-field class="wid100 x--email" data-cy="login-card--form-control-email">
                <mat-label>{{ 'USER.LOGIN.EMAIL' | transloco }}</mat-label>
                <input
                  type="email"
                  formControlName="email"
                  rrFocusInput
                  #focusEmail="rrFocusInput"
                  matInput
                  name="email"
                  [placeholder]="'USER.LOGIN.EMAIL' | transloco"
                  data-with-last-pass
                  data-with-browser-autocomplete
                  #emailInput
                />
                <mat-error> <rr-validation-messages [errors]="emailControl?.errors"></rr-validation-messages> </mat-error>
              </mat-form-field>
              <mat-form-field class="wid100 x--password" data-cy="login-card--form-control-password">
                <mat-label>{{ 'USER.LOGIN.PASSWORD' | transloco }}</mat-label>
                <input
                  formControlName="password"
                  matInput
                  name="password"
                  [type]="passwordType"
                  [placeholder]="'USER.LOGIN.PASSWORD' | transloco"
                  rrFocusInput
                  #focusPassword="rrFocusInput"
                  data-with-last-pass
                  data-with-browser-autocomplete
                  #passwordInput
                />
                <button tabindex="-1" type="button" mat-icon-button matSuffix (click)="changeVisibility($event)" rrPreventClick>
                  <mat-icon
                    data-cy="login-card--form-control-password-visible-icon"
                    [matTooltip]="(!visible ? 'COMMON.TOOLTIP.PASSWORD_SHOW' : 'COMMON.TOOLTIP.PASSWORD_HIDE') | transloco"
                    matTooltipPosition="left"
                    class="visible-icon"
                    [svgIcon]="!visible ? 'eye' : 'eye-off'"
                  ></mat-icon>
                </button>
                <mat-error> <rr-validation-messages [errors]="passwordControl?.errors"></rr-validation-messages> </mat-error>
              </mat-form-field>
            </form>
          </mat-card-content>
          <mat-card-actions data-cy="login-card--actions">
            <button
              type="button"
              *ngIf="!isWebadmin"
              mat-button
              [routerLink]="['/', forgotPasswordRoutePath]"
              [queryParams]="{ email: emailControl?.valid ? emailControl?.value : undefined }"
            >
              {{ 'USER.LOGIN.FORGOT_PASSWORD' | transloco }}
            </button>
            <span class="flex-spacer"></span>
            <button
              [disabled]="form['submitted'] && form.invalid && form.disabled"
              [ngClass]="{ 'invalid-form': form['submitted'] && form.invalid }"
              mat-raised-button
              type="submit"
              (click)="onSubmit()"
              color="primary"
              class="submit-button"
            >
              <mat-icon style="margin-left: -10px" svgIcon="login-variant" *ngIf="!(form['submitted'] && form.invalid)"></mat-icon>
              <div
                class="invalid-form-button-overlay"
                *ngIf="form['submitted'] && form.invalid"
                matTooltipPosition="left"
                matTooltip="{{ form['submitted'] === true && form.invalid ? ('COMMON.VALIDATION.BUTTON' | transloco) : undefined }}"
              >
                <mat-icon svgIcon="alert"></mat-icon>
              </div>
              <span style="display: inline-block; width: calc(100% - 24px)">{{ 'USER.LOGIN.LOGIN' | transloco }}</span>
            </button>
          </mat-card-actions>
        </mat-card>

        <rr-social-buttons-card
          [form]="form"
          *ngIf="!isWebadmin"
          withEmailButtonTranslateKey="USER.SOCIAL.REGISTRATION_WITH_EMAIL"
          (clickWithEmail)="onClickRegistrationWithEmail()"
        >
        </rr-social-buttons-card>
        <rr-unauthenticated-footer></rr-unauthenticated-footer>
      </div>
    </div>
  </div>
</div>
<re-captcha
  *ngIf="useCaptcha"
  [siteKey]="reCaptchaKey"
  size="invisible"
  (resolved)="onResolveCaptcha($event)"
  (error)="onCaptchaError($event)"
  #captchaRef="reCaptcha"
  name="recaptcha"
></re-captcha>
