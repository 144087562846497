import { produce } from '@ngxs-labs/immer-adapter';
import { Action, createSelector, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { CalendarChangeViewTypeAction } from './action/calendar-change-view-type.action';
import { CalendarSaveLastScrollPositionAction } from './action/calendar-save-last-scroll-position.action';
import { CalendarsState } from './model/calendars-state.interface';
import { Injectable } from '@angular/core';

@State<CalendarsState>({
  name: 'calendars',
  defaults: { container: {}, version: 3 } as CalendarsState,
})
@Injectable()
export class CalendarState implements NgxsOnInit {
  static container(id: string): any {
    const selector = createSelector([CalendarState], (state: CalendarsState) => {
      if (state !== undefined && state.container !== undefined) {
        return state.container[id];
      }
      return undefined;
    });
    return selector;
  }

  ngxsOnInit(ctx?: StateContext<CalendarsState>): void | any {
    if (ctx.getState().container === undefined) {
      return ctx.patchState({ container: {}, version: 1 });
    }
  }

  @Action(CalendarChangeViewTypeAction)
  calendarChangeViewType(ctx: StateContext<CalendarsState>, action: CalendarChangeViewTypeAction): any {
    produce<CalendarsState>(ctx, draft => {
      if (draft.container === undefined) {
        draft.container = {};
      }
      if (draft.container[action.id] === undefined) {
        draft.container[action.id] = {
          viewType: action.newViewType,
          scrollTop: null,
        };
      } else {
        draft.container[action.id].viewType = action.newViewType;
      }
    });
  }

  @Action(CalendarSaveLastScrollPositionAction)
  calendarSaveLastScrollPosition(ctx: StateContext<CalendarsState>, action: CalendarSaveLastScrollPositionAction): any {
    produce<CalendarsState>(ctx, draft => {
      draft.container[action.id].scrollTop = action.scrollTop;
    });
  }
}
