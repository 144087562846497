import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { isEmptyString, isNil, isString } from '@roadrecord/type-guard';
import mapboxgl, { GeolocateControl, LngLat, LngLatBounds, MapMouseEvent, Popup } from 'mapbox-gl';
import { ControlComponent, GeoJSONSourceComponent, MapComponent } from 'ngx-mapbox-gl';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { ClusterFeature } from 'supercluster';
import { HAS_WEBGL_SUPPORT } from './has-webgl/has-webgl-support.provider';
import { PitchToggle } from './pitch.control';
import { take } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { PARTNER_ADRESS_FORMATTER, PartnerAddressFormatterReturnType } from '@roadrecord/partner/model';
import { MarketPopUpInfo } from './models/market-popup-models';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '@roadrecord/environment';
import { MatTooltip } from '@angular/material/tooltip';

/*
 * drag and drop
 * +++all poi map layer markers fix (click)
 * ha nincs bekapcsolva a geolocation es meg nincs marker
 * webgl support check
 * */

const logScopeName = 'MapBoxComponent';
let translatedMapBoxLocale: unknown;

@UntilDestroy()
@Component({
  selector: 'rr-map-box',
  templateUrl: './map-box.component.html',
  styleUrls: ['./map-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapBoxComponent implements OnInit, OnChanges, AfterViewInit {
  readonly environment = environment;
  // locale cache
  readonly locale: unknown;
  @ViewChild('geoJSONSourceComponent') geoJSONSourceComponent: GeoJSONSourceComponent;
  @ViewChildren('geoLocate') geoLocateControlComponent: QueryList<ControlComponent>;
  @ViewChild(MapComponent) mapComponent: MapComponent;
  @ViewChildren('showRefreshLayerTooltip') showRefreshLayerTooltip: QueryList<MatTooltip>;
  markers$ = new BehaviorSubject<GeoJSON.FeatureCollection<GeoJSON.Point, GeoJSON.GeoJsonProperties>>({
    type: 'FeatureCollection',
    features: [],
  });
  /**
   * minden mas alap zoom-ja
   */
  defaultZoom = 16;
  /**
   * auto megprobaljuk betolteni a user helyzetet
   */
  @Input() geolocateUser = true;
  showUserLocation = true;
  @Input() customButtonArea: TemplateRef<any>;
  @Output() clickMarker = new EventEmitter<ClusterFeature<any>>();
  @Input() visibleMarkerTooltip = true;
  @Output() newPoi = new EventEmitter<[number, number]>();
  @Input() mapboxMode = false;
  @Input() enableFullScreen = true;
  @Input() showMarketRouteNumber = false;
  @Input() showMarkerInCluster = true;
  @Input() showRefreshLayer = false;
  @Output() clickRefreshButton = new EventEmitter();
  readonly osmMapUrl: string;
  mapCenter: [number, number];
  customButtons: any[];
  openedInFullScreenMode = false;
  private markerPopups: Popup[] = [];
  private loadCurrentBrowserLocationWaitMapSubscription: Subscription;
  private jumpToToWaitMapSubscription: Subscription;
  private showUserLocationWaitMapSubscription: Subscription;
  private fitMapToMarkersWaitMapSubscription: Subscription;
  private runDragMarker = false;
  private firstJumpTo = true;
  selectedPoint: MarketPopUpInfo;
  private _selectedFeature: ClusterFeature<any> = null;

  constructor(
    @Inject(HAS_WEBGL_SUPPORT) readonly browserHasWebGLSupport: boolean,
    private translocoService: TranslocoService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    @Inject(PARTNER_ADRESS_FORMATTER) readonly partnerAddressFormatter: PartnerAddressFormatterReturnType,
    private gtmService: GoogleTagManagerService
  ) {
    this.locale = this.getMapboxLocalTranslation();

    this.osmMapUrl = environment.osmMapUrl;
    this.mapCenter = environment.map.center;
  }

  private _disableAutoTooltip = false;

  get disableAutoTooltip(): boolean {
    return this._disableAutoTooltip;
  }

  @Input()
  set disableAutoTooltip(value: boolean) {
    this._disableAutoTooltip = coerceBooleanProperty(value);
  }

  /**
   * Sajat full screen implementacio
   */
  private _enableOverlayFullScreenMode = false;

  get enableOverlayFullScreenMode(): boolean {
    return this._enableOverlayFullScreenMode;
  }

  @Input()
  set enableOverlayFullScreenMode(value: boolean) {
    this._enableOverlayFullScreenMode = coerceBooleanProperty(value);
    if (this._enableOverlayFullScreenMode) {
      if (!Array.isArray(this.customButtons)) {
        this.customButtons = [];
      }
      this.customButtons.push({
        type: 'fullscreen',
        tooltip: this.translocoService.translate('PARTNER.DETAILS.POI_MAP.FULLSCREEN'),
        icon: 'fullscreen',
        click: (() => {
          this.openedInFullScreenMode ? this.closeInFullScreenMode() : this.openInFullScreenMode();
          if (this.ngZone.isStable) {
            this.mapResize();
          } else {
            this.ngZone.onStable.pipe(take(1), untilDestroyed(this)).subscribe(() => this.mapResize());
          }
        }).bind(this),
      });
    } else {
      if (Array.isArray(this.customButtons)) {
        const foundIndex = this.customButtons.findIndex(conf => conf === 'fullscreen');
        if (foundIndex > -1) {
          this.customButtons.splice(foundIndex, 1);
        }
      }
    }
  }

  /**
   * map alap zoom
   */
  private _defaultCenterZoom;

  get defaultCenterZoom() {
    return this._defaultCenterZoom;
  }

  @Input()
  set defaultCenterZoom(zoom: number) {
    this._defaultCenterZoom = [zoom];
  }

  @Input()
  set markers(markers: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[]) {
    if (this.browserHasWebGLSupport === false) {
      return;
    }
    if (!Array.isArray(markers)) {
      markers = [];
    }
    this.markers$.next({ ...this.markers$.getValue(), features: [...markers] });
    if (markers.length === 1) {
      // ha 1 marker van akkor oda visszuk a user-t
      this.mapJumpTo(markers);
    }
    if (markers.length >= 1) {
      // biztonsagi popup bezaras ha nyitva lenne
      this.removeMarkerPopup();
      // kikapcsoljuk az user geo location megjeleniteset
      this.showUserLocation = false;
      if (markers.length > 1) {
        this.fitMapToMarkers(markers);
      }

      // add tooltip
      const cb = () => {
        if (isNil(this.mapInstance)) {
          timer(500).subscribe(() => cb());
        } else {
          markers.forEach(marker => this.onMouseEnterMarker(marker));
        }
      };
      if (this.ngZone.isStable) {
        cb();
      } else {
        this.ngZone.onStable.pipe(untilDestroyed(this), take(1)).subscribe(() => cb());
      }
    } else {
      if (this.geolocateUser) {
        // bekapcsoljuk az user geo location megjeleniteset
        this.showUserLocation = true;
        timer(0)
          .pipe(untilDestroyed(this))
          .subscribe(() => this.showUserLocationPopup());
      }
    }
  }

  @Input()
  set loadCurrentBrowserLocation(value: boolean) {
    if (this.browserHasWebGLSupport === false) {
      return;
    }
    if (value) {
      if (this.loadCurrentBrowserLocationWaitMapSubscription !== undefined && !this.loadCurrentBrowserLocationWaitMapSubscription.closed) {
        this.loadCurrentBrowserLocationWaitMapSubscription.unsubscribe();
        delete this.loadCurrentBrowserLocationWaitMapSubscription;
      }
      if (this.mapInstance === undefined) {
        this.loadCurrentBrowserLocationWaitMapSubscription = timer(500)
          .pipe(untilDestroyed(this))
          .subscribe(() => (this.loadCurrentBrowserLocation = value));
      } else {
        timer(3000)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            if (this.geoLocateControlComponent === undefined || this.geoLocateControlComponent.length === 0) {
              timer(3000)
                .pipe(untilDestroyed(this))
                .subscribe(() =>
                  !isNil(this.geoLocateControlComponent.first) ? (this.geoLocateControlComponent.first.control as any).trigger() : undefined
                );
            } else {
              (this.geoLocateControlComponent.first.control as any).trigger();
            }
          });
      }
    }
  }

  get mapService() {
    return this.mapComponent['MapService'];
  }

  get mapInstance() {
    return !isNil(this.mapComponent) ? this.mapComponent.mapInstance : undefined;
  }

  private mapResize() {
    const mapInstance = this.mapInstance;
    if (!isNil(mapInstance)) {
      mapInstance.resize();
    }
  }

  @HostListener('window:resize')
  onResizeWindow(): void {
    this.onLoadedMap();
  }

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => this.addPitchToggleControl());
    // TODO ezt itt atalakitani! (animacio miatt)
    timer(1200).subscribe(() => {
      if (this.ngZone.isStable) {
        this.mapResize();
      } else {
        this.ngZone.onStable.pipe(take(1), untilDestroyed(this)).subscribe(() => this.mapResize());
      }

      this.mapInstance.on('moveend', () => {
        if (this._selectedFeature !== null) {
          if (!isNil(this._selectedFeature.properties.partner)) {
            const partnerInfo = JSON.parse(this._selectedFeature.properties.partner);
            this.selectedPoint = {
              partner: partnerInfo,
              feature: this._selectedFeature,
              rawPartnerInfo: this._selectedFeature.properties.partner,
              formatAddress: this.partnerAddressFormatter(partnerInfo, true),
            };
            this.cdr.detectChanges();
          }
        }
        this._selectedFeature = null;
      });
    });
  }

  async onClickClusterPoint(feature: ClusterFeature<any>): Promise<void> {
    const zoom = await this.geoJSONSourceComponent.getClusterExpansionZoom(feature.id as number);
    this.mapService.move('easeTo', undefined, zoom + 1, feature.geometry.coordinates as [number, number]);
  }

  onClickEdit(): void {
    this.clickMarker.emit(this.selectedPoint.feature);
  }

  onClickMarker(feature: ClusterFeature<any>): void {
    this._selectedFeature = feature;
    //this.mapService.move('easeTo', undefined, this.defaultZoom, feature.geometry.coordinates as [number, number]);
    this.mapService.move('easeTo', undefined, undefined, feature.geometry.coordinates as [number, number]);
    //this.clickMarker.emit(feature);
  }

  onMouseEnterMarker(feature: any): void {
    if (isEmptyString(feature.properties.title) && isEmptyString(feature.properties.content)) {
      return;
    }
    if (this.disableAutoTooltip) {
      //this.mapInstance.getCanvas().style.cursor = 'pointer';
      this.removeMarkerPopup();
    }
    if (this.visibleMarkerTooltip) {
      if (this.runDragMarker === true) {
        return;
      }

      const htmlDescription = [];
      const text = [];
      if (!isNil(feature.properties) && (!isNil(feature.properties.title) || !isNil(feature.properties.content))) {
        if (!isNil(feature.properties.title)) {
          htmlDescription.push(`<h3 style="margin-bottom: 0;font-weight: 500">${feature.properties.title}</h3>`);
          text.push(feature.properties.title);
        }
        if (!isNil(feature.properties.content)) {
          htmlDescription.push(`<p style="margin-bottom: 0">${feature.properties.content}</p>`);
          text.push(feature.properties.content);
        }
      }
      const searchText = `${text.join('')}_${Object.entries(feature.properties).reduce((acc, next) => `${acc}_${next[1]}`, '')}`;
      if (
        htmlDescription.length === 0 ||
        /* megvedjuk hogy 2 ugyan olyan ne jelenhessen meg */ this.markerPopups.find(
          popup => !isNil(popup) && isString(popup['text']) && popup['text'] === searchText
        ) !== undefined
      ) {
        return;
      }

      this.markerPopups.push(
        new Popup({
          focusAfterOpen: false,
          closeButton: true,
          closeOnClick: false,
          offset: [0, -10],
        })
      );
      const index = this.markerPopups.length - 1;
      this.markerPopups[index]['text'] = searchText;
      this.markerPopups[index].on('close', () => {
        delete this.markerPopups[index];
      });

      const coordinates = feature.geometry.coordinates.slice();

      this.markerPopups[index].setLngLat(coordinates).setHTML(htmlDescription.join('')).addTo(this.mapInstance);
    }
  }

  onStartDragMarker(): void {
    this.removeMarkerPopup();
    this.runDragMarker = true;
  }

  onStopDragMarker(
    feature: GeoJSON.Feature,
    markers: GeoJSON.FeatureCollection<GeoJSON.Point, GeoJSON.GeoJsonProperties>,
    $event: mapboxgl.Marker
  ): void {
    this.runDragMarker = false;
    const searchId = feature.properties.id;
    markers.features.find(_feature => _feature.properties.id === searchId).properties.onDragEnd($event);
  }

  onClickMap($event: MapMouseEvent): void {
    if ($event.lngLat !== undefined) {
      $event.preventDefault();
      this.newPoi.emit([$event.lngLat.lng, $event.lngLat.lat]);
      this.gtmService.pushTag({
        event: 'pin_marker',
        search_term: `${$event.lngLat.lng}:${$event.lngLat.lat}`,
      });
    }
  }

  ngOnInit(): void {
    if (isNil(this.defaultCenterZoom)) {
      this.defaultCenterZoom = environment.map.defaultZoom;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!isNil(changes['showRefreshLayer'])) {
      setTimeout(() => {
        if (this.showRefreshLayerTooltip !== undefined && this.showRefreshLayerTooltip.first !== undefined) {
          this.showRefreshLayerTooltip.first.hide = (delay: number) => {};
          if (this.showRefreshLayer === true) {
            this.showRefreshLayerTooltip.first.show(0);
            this.showRefreshLayerTooltip.first.show = (delay: number) => {};
          }
        }
      }, 0);
    }
  }

  /**
   * reseteljuk a map beallitasokat
   */
  resetDefault() {
    this.defaultCenterZoom = environment.map.defaultZoom;
    this.mapCenter = [environment.map.center[0], environment.map.center[1]];
  }

  onLoadedMap() {
    if (this.ngZone.isStable) {
      timer(500).subscribe(() => this.mapResize());
    } else {
      this.ngZone.onStable.pipe(untilDestroyed(this), take(1)).subscribe(() => this.mapResize());
    }
  }

  onLoadMarkupImg(feature: any) {
    if (this._disableAutoTooltip === false) {
      this.onMouseEnterMarker(feature);
    }
  }

  openInFullScreenMode() {
    this.openedInFullScreenMode = true;
    this.cdr.markForCheck();
  }

  closeInFullScreenMode() {
    this.openedInFullScreenMode = false;
    this.cdr.markForCheck();
  }

  /**
   * 3D control
   */
  private addPitchToggleControl() {
    if (isNil(this.mapInstance)) {
      return timer(500)
        .pipe(untilDestroyed(this))
        .subscribe(() => this.addPitchToggleControl());
    }
    this.mapInstance.addControl(new PitchToggle(this.locale['PitchControl.2DLabel'], this.locale['PitchControl.3DLabel']), 'top-left');
  }

  private getMapboxLocalTranslation() {
    if (!isNil(translatedMapBoxLocale)) {
      return translatedMapBoxLocale;
    }
    const translationMap = this.translocoService.getTranslation();
    const key = translationMap.keys().next().value;
    const mapBoxLocale = Object.entries(translationMap.get(key))
      .filter(entry => entry[0].startsWith('MAPBOX.LOCALE'))
      .map(entry => {
        entry[0] = entry[0].split('MAPBOX.LOCALE.')[1];
        return entry;
      })
      .reduce((acc, next) => {
        acc[next[0]] = next[1];
        return acc;
      }, {});
    translatedMapBoxLocale = mapBoxLocale;
    return mapBoxLocale;
  }

  private fitMapToMarkers(markers: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[]): void {
    if (this.fitMapToMarkersWaitMapSubscription !== undefined && !this.fitMapToMarkersWaitMapSubscription.closed) {
      this.fitMapToMarkersWaitMapSubscription.unsubscribe();
      delete this.fitMapToMarkersWaitMapSubscription;
    }
    if (this.mapInstance === undefined) {
      this.fitMapToMarkersWaitMapSubscription = timer(500)
        .pipe(untilDestroyed(this))
        .subscribe(() => this.fitMapToMarkers(markers));
      return;
    }
    const bounds = markers.reduce(
      (_bounds, marker) => _bounds.extend(new LngLat(marker.geometry.coordinates[0], marker.geometry.coordinates[1])),
      new LngLatBounds(markers[0].geometry.coordinates as [number, number], markers[0].geometry.coordinates as [number, number])
    );

    this.mapInstance.fitBounds(bounds, {
      padding: 50,
      maxZoom: 15,
      duration: 2000,
    });
  }

  private removeMarkerPopup(): void {
    if (this.markerPopups.length > 0) {
      this.markerPopups.forEach(popup => popup.remove());
      this.markerPopups = [];
    }
  }

  private mapJumpTo(markers: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[]): void {
    if (this.jumpToToWaitMapSubscription !== undefined && !this.jumpToToWaitMapSubscription.closed) {
      this.jumpToToWaitMapSubscription.unsubscribe();
      delete this.jumpToToWaitMapSubscription;
    }
    if (this.mapInstance === undefined) {
      this.jumpToToWaitMapSubscription = timer(500)
        .pipe(untilDestroyed(this))
        .subscribe(() => this.mapJumpTo(markers));
    } else {
      this.mapService.move(
        'flyTo',
        undefined,
        this.firstJumpTo ? this.defaultZoom : this.mapComponent.mapInstance.getZoom(),
        markers[0].geometry.coordinates as [number, number]
      );
      this.firstJumpTo = false;
      this.onLoadedMap();
    }
  }

  private showUserLocationPopup(): void {
    if (this.showUserLocationWaitMapSubscription !== undefined && !this.showUserLocationWaitMapSubscription.closed) {
      this.showUserLocationWaitMapSubscription.unsubscribe();
      delete this.showUserLocationWaitMapSubscription;
    }
    if (this.showUserLocation && this.runDragMarker === false) {
      if (this.mapInstance === undefined) {
        this.showUserLocationWaitMapSubscription = timer(1000)
          .pipe(untilDestroyed(this))
          .subscribe(() => this.showUserLocationPopup());
      } else {
        const foundGeolocationControl: GeolocateControl = (this.mapInstance as any)._controls.find(
          control => !isNil(control._geolocateButton)
        );
        this.markerPopups.push(
          new Popup({
            focusAfterOpen: false,
            closeButton: false,
            closeOnClick: false,
            offset: [0, -10],
          })
        );
        const index = this.markerPopups.length - 1;
        this.markerPopups[index].on('close', () => delete this.markerPopups[index]);

        const recallFn = () =>
          (this.showUserLocationWaitMapSubscription = timer(2000)
            .pipe(untilDestroyed(this))
            .subscribe(() => this.showUserLocationPopup()));
        if (foundGeolocationControl === undefined) {
          recallFn();
          return;
        } else {
          // FIX: ngx-mapbox lib-be nincs benne a kapcsolo, de nekunk nem kell ez
          (foundGeolocationControl as any).options.showAccuracyCircle = false;
          const lngLat = (foundGeolocationControl as any)._userLocationDotMarker._lngLat;
          if (lngLat === undefined) {
            recallFn();
            return;
          }

          this.markerPopups[index]
            .setLngLat([lngLat.lng, lngLat.lat])
            .setHTML(this.translocoService.translate('PARTNER.DETAILS.POI_MAP.CURRENT_POSITION'))
            .addTo(this.mapInstance);
        }
      }
    }
  }
  onRefreshButtonClick(): void {
    this.clickRefreshButton.emit();
  }
}
