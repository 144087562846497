import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppTypeIsUsPipe } from './app-type-is-us.pipe';
import { AppTypeIsHuPipe } from './app-type-is-hu.pipe';

@NgModule({
  declarations: [AppTypeIsUsPipe, AppTypeIsHuPipe],
  imports: [CommonModule],
  exports: [AppTypeIsUsPipe, AppTypeIsHuPipe],
})
export class AppTypePipeModule {}
