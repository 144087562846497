import { ConstructorProvider, Optional } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ENTITY_SERVICE_TOKEN } from '../../../../entity/service/entity-service.token';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { PresenterStateController } from '../../presenter-state/presenter-state.controller';
import { PresenterDeletePlugin } from './presenter-delete.plugin';
import { PRESENTER_DELETE_OPTIONS } from './presenter-delete-options.token';
import { CheckModifiedMVPFormGuard } from '../../../guard/check-modified-mvp-form-guard.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

export const PRESENTER_DELETE_PLUGIN_PROVIDER: ConstructorProvider = {
  provide: PresenterDeletePlugin,
  deps: [
    PresenterStateController,
    ENTITY_SERVICE_TOKEN,
    TranslocoService,
    MatSnackBar,
    Router,
    ActivatedRoute,
    [new Optional(), GoogleTagManagerService],
    MessageDialogService,
    [new Optional(), PRESENTER_DELETE_OPTIONS],
    [new Optional(), CheckModifiedMVPFormGuard],
  ],
};
