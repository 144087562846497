import { Inject, Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { IS_WEBADMIN } from '@roadrecord/utils';
import { IdleStartDetectAction } from './action/idle-start-detect.action';
import { IdleStopDetectAction } from './action/idle-stop-detect.action';
import { IdleTimeoutAction } from './action/idle-timeout.action';
import { IdleStateModel } from './model/idle-state.model';
import { environment } from '@roadrecord/environment';

@State<IdleStateModel>({
  name: 'idle',
  defaults: { version: 3 } as IdleStateModel,
})
@Injectable()
export class IdleState {
  constructor(
    private readonly idle: Idle,
    private readonly keepalive: Keepalive,
    private readonly store: Store,
    @Inject(IS_WEBADMIN) private readonly isWebadmin: boolean
  ) {}

  @Action(IdleStartDetectAction)
  startIdleDetect({ patchState }: StateContext<IdleStateModel>): void {
    const timeoutTime = environment.idleTimeoutTime;
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(timeoutTime);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    // amikor idle lesz a user
    this.idle.onTimeout.subscribe(() => {
      // force token delete (mert a logout action csak a modal ablak elfogadasa utan torli a token es igy refresh-vel atverheto!)
      // modal ablak + kileptetes
      this.store.dispatch(new IdleTimeoutAction());
      // email cim ki van toltve
      // jelszo-t meg kell adni
      // beleptetni es a modal-t eltuntetni
      // state frissites kell?
    });
    // mennyi idonkent pingeljuk a user interakciokat
    this.keepalive.interval(60);
    // start idle watch
    this.idle.watch();
    patchState({
      idle: this.idle.getIdle(),
      timeout: this.idle.getTimeout(),
    });
  }

  @Action(IdleStopDetectAction)
  stopIdleDetect({ patchState }: StateContext<IdleStateModel>): void {
    patchState({
      idle: null,
      timeout: null,
    });
    this.idle.stop();
  }
}
