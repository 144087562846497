<ng-container
  *rrTransloco="
    let t;
    fromPath: 'UNIVERSAL_IMPORT.UPLOAD_STEPPER.ASSOCIATION_HEADERS';
    overridePath: importConfig.translatePrefix + '.UPLOAD_STEPPER.ASSOCIATION_HEADERS'
  "
>
  <mat-card>
    <mat-card-title>{{ t('CARD.TITLE') }}</mat-card-title>
    <mat-card-content>
      <p>{{ t('CARD.CONTENT') }}</p>
      <table class="table table-striped table-hover mb-0">
        <thead>
          <tr>
            <th scope="col" [matTooltip]="t('HEADERS.CHECK.TOOLTIP')" matTooltipClass="mat-tooltip-white-space-break-spaces"
              ><mat-icon svgIcon="help-circle-outline"></mat-icon
            ></th>
            <th scope="col" [matTooltip]="t('HEADERS.REQUIRED.TOOLTIP')" matTooltipClass="mat-tooltip-white-space-break-spaces"
              ><span class="table-header-cell-content">{{ t('HEADERS.REQUIRED.TEXT') }}</span
              >&nbsp;<mat-icon svgIcon="help-circle-outline"></mat-icon
            ></th>
            <th scope="col" [matTooltip]="t('HEADERS.USER_FILE_HEADERS.TOOLTIP')" matTooltipClass="mat-tooltip-white-space-break-spaces"
              ><span class="table-header-cell-content">{{ t('HEADERS.USER_FILE_HEADERS.TEXT') }}</span
              >&nbsp;<mat-icon svgIcon="help-circle-outline"></mat-icon
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let header of requireHeaders.headers; let i = index"
            [ngClass]="{
              'table-danger':
                (header.validators.req === true && !formArray.at(i)?.value) ||
                (formArray.at(i).invalid && (formArray.at(i).touched || formArray['submitted']))
            }"
            [matTooltip]="
              (formArray.at(i).invalid && formArray?.submitted) || (formArray.at(i).invalid && formArray.at(i).touched)
                ? ('COMMON.VALIDATION.REQUIRED' | transloco)
                : ''
            "
            [matTooltipClass]="['live-auto-complete-not-found', 'error']"
            matTooltipPosition="after"
          >
            <td scope="row" class="align-middle">
              <mat-checkbox
                *ngIf="checkboxControls[i] !== undefined"
                [formControl]="checkboxControls[i]"
                class="ml-2"
                [matTooltip]="t('HEADERS.USER_FILE_HEADERS.CHECKBOX_TOOLTIP.' + (checkboxControls[i]?.value ? 'DISABLE' : 'ENABLE'))"
              ></mat-checkbox>
            </td>
            <td class="align-middle">{{ header.name }}<span *ngIf="header.validators.req === true" class="warn">*</span></td>
            <td>
              <mat-form-field>
                <mat-select [formControl]="formArray.at(i)">
                  <mat-option *ngFor="let _header of userHeaders" [value]="_header">
                    {{ _header }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card-content>
    <mat-card-actions class="d-flex"
      ><button
        type="button"
        mat-icon-button
        (click)="onBack()"
        matTooltipPosition="right"
        matTooltip="{{ 'COMMON.ACTION.TOOLTIP.CANCEL' | transloco }}"
      >
        <mat-icon svgIcon="arrow-left"></mat-icon> </button
      ><span class="flex-spacer"></span
      ><button
        mat-raised-button
        color="primary"
        [ngClass]="{ 'invalid-form': formArray['submitted'] && formArrayInvalid }"
        class="submit-button"
        (click)="onSubmit()"
        [disabled]="formArray.disabled"
      >
        <div
          class="invalid-form-button-overlay"
          *ngIf="formArray['submitted'] && formArrayInvalid"
          matTooltipPosition="left"
          matTooltip="{{ formArray['submitted'] === true && formArrayInvalid ? ('COMMON.VALIDATION.BUTTON' | transloco) : undefined }}"
        >
          <mat-icon svgIcon="alert"></mat-icon>
        </div>
        {{ t('SUBMIT.LABEL') }}
      </button></mat-card-actions
    >
  </mat-card>
</ng-container>
