<div class="inside-scroll background-image-1">
  <div class="static-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex.gt-md="36" fxFlex="90">
      <div class="static-content" fxLayout="column" fxLayoutAlign="center center">
        <img class="static-icon" fxFlexOffset="15px" [src]="'assets/images/registration/emaily.png'" alt="image" />
        <mat-card>
          <mat-card-title>{{ 'USER.REGISTER.RESEND_ACTIVATION.SUCCESSFUL.TITLE' | transloco }}</mat-card-title>
          <mat-card-content>
            <p class="static-description" [innerHTML]="'USER.REGISTER.RESEND_ACTIVATION.SUCCESSFUL.BODY' | transloco: { email: email }"></p>
            <span class="static-description"><ul [innerHTML]="'USER.REGISTER.RESEND_ACTIVATION.SUCCESSFUL.BODY_2' | transloco"></ul></span>
            <p class="static-description" [innerHTML]="'USER.REGISTER.RESEND_ACTIVATION.SUCCESSFUL.BODY_3' | transloco"></p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  <rr-unauthenticated-footer></rr-unauthenticated-footer>
</div>
