import { SplitButtonAction } from '@roadrecord/split-button';
import { clickFuelingImportRightMenuButton } from './click-fix-import.function';
import { fuelingImportEntityDefaultOrder } from '../import/fueling-universal-import.service';
import { fuelingImportAction } from '../import/window-content/window-content.action';
import { PreferencesContentWindowTypeEnum, PreferencesState } from '@roadrecord/preferences-state/common';
import { ActivatedRoute, Router } from '@angular/router';
import { RRCalendarComponent } from '@roadrecord/calendar';
import { UniversalImportService } from '@roadrecord/universal-import/common';
import { Store } from '@ngxs/store';
import { Injector } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { LoadableService } from '@roadrecord/external-packages/ngx-loadable';
import { MatSnackBar } from '@angular/material/snack-bar';

export const calendarExtraTopRightButtons: (_this: {
  calendarComponent: RRCalendarComponent;
  universalImportService: UniversalImportService<any>;
  router: Router;
  route: ActivatedRoute;
  store: Store;
  injector: Injector;
  translocoService: TranslocoService;
  messageDialogService: MessageDialogService;
  loadableService: LoadableService;
  matSnackBar: MatSnackBar;
}) => SplitButtonAction[] = (_this: {
  calendarComponent: RRCalendarComponent;
  universalImportService: UniversalImportService<any>;
  router: Router;
  route: ActivatedRoute;
  store: Store;
  injector: Injector;
  translocoService: TranslocoService;
  messageDialogService: MessageDialogService;
  loadableService: LoadableService;
  matSnackBar: MatSnackBar;
}) => [
  { type: 'DIVIDER' },
  {
    id: 'fueling_import_menu_item',
    type: 'ITEM',
    label: { translate: 'FUELING.CALENDAR.HEADER.TOP_RIGHT_MENU.IMPORT.LABEL' },
    icon: 'cloud-upload',
    action: () => {
      _this.calendarComponent.showLoading();
      clickFuelingImportRightMenuButton(_this.universalImportService, fuelingImportEntityDefaultOrder, '', _this.router, _this.route);
    },
  },
  {
    type: 'ITEM',
    label: { translate: 'FUELING.CALENDAR.HEADER.TOP_RIGHT_MENU.IMPORT_LIST_WINDOW.LABEL' },
    icon: 'format-list-checks',
    action: () => fuelingImportAction(_this.store, _this.loadableService, _this.injector, _this.translocoService, _this.matSnackBar),
    disabled: () =>
      _this.store.selectSnapshot(PreferencesState.hasOpenedContentWindow)(PreferencesContentWindowTypeEnum.FUELING_IMPORT_LIST) ||
      _this.calendarComponent.isLoading,
  },
];
