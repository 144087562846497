<div class="scroll">
  <div class="wrapper-component">
    <div fxFlex.gt-md fxLayout="row" fxLayoutAlign="center center">
      <div class="wid100" fxLayout="column" fxLayoutAlign="center center">
        <mat-card class="rr-card2 fix-actions-row-height">
          <mat-progress-bar *ngIf="form.disabled" color="warn" mode="indeterminate"></mat-progress-bar>
          <mat-card-header>
            <mat-card-title>
              <mat-icon class="big" svgIcon="key"></mat-icon>
              <span>{{ 'USER.REGISTER.RESEND_ACTIVATION.TITLE' | transloco }}</span>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p class="help-text">{{ 'USER.REGISTER.RESEND_ACTIVATION.HELP_TEXT' | transloco }}</p>
            <p class="help-text">{{ 'USER.REGISTER.RESEND_ACTIVATION.HELP_TEXT_2' | transloco }}</p>
            <form
              #formElem
              (keyup.enter)="onKeyupForm($event, formElem)"
              [formGroup]="form"
              fxLayout="column"
              fxLayoutAlign="center stretch"
              novalidate
            >
              <mat-form-field fxFlex="100" class="x--email">
                <mat-label>{{ 'USER.REGISTER.RESEND_ACTIVATION.EMAIL' | transloco }}</mat-label>
                <input
                  #focusEmail="rrFocusInput"
                  [placeholder]="'USER.REGISTER.RESEND_ACTIVATION.EMAIL' | transloco"
                  formControlName="email"
                  matInput
                  name="email"
                  required
                  rrFocusDelay="300"
                  rrFocusInput="true"
                  type="email"
                />
                <mat-error>
                  <rr-validation-messages
                    [errors]="emailControl?.errors"
                    [messages]="emailControlValidationMessages"
                    id="emailControl"
                  ></rr-validation-messages>
                </mat-error>
              </mat-form-field>
            </form>
          </mat-card-content>
          <mat-card-actions>
            <span class="flex-spacer"></span>
            <button
              type="button"
              (click)="resendActivation()"
              [disabled]="(form['submitted'] && form.invalid) || form.disabled"
              [ngClass]="{ 'invalid-form': form['submitted'] && form.invalid }"
              class="submit-button"
              color="primary"
              mat-raised-button
              matTooltipPosition="left"
            >
              <div
                *ngIf="form['submitted'] && form.invalid"
                class="invalid-form-button-overlay"
                matTooltip="{{ form['submitted'] === true && form.invalid ? ('COMMON.VALIDATION.BUTTON' | transloco) : undefined }}"
                matTooltipPosition="left"
              >
                <mat-icon svgIcon="alert"></mat-icon>
              </div>
              {{ 'USER.REGISTER.RESEND_ACTIVATION.RESEND' | transloco }}
            </button>
          </mat-card-actions>
        </mat-card>
        <rr-unauthenticated-footer></rr-unauthenticated-footer>
      </div>
    </div>
  </div>
</div>
