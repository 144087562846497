import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { isNil } from '@roadrecord/type-guard';
import { BehaviorSubject } from 'rxjs';
import { ValidationMessageModel } from '../model/validation-message.model';
import { ValidationTemplateMessageModel } from '../model/validation-template-message.model';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VALIDATION_MESSAGES_CONFIG } from '../validation.provider.token';

// circular dep miatt kerult ide
function checkPropertyChange(key: string, changes: SimpleChanges): boolean {
  return (
    !isNil(changes[key]) &&
    ((changes[key].isFirstChange() && !isNil(changes[key].currentValue)) || changes[key].previousValue !== changes[key].currentValue)
  );
}

let id = 0;
const logScopeName = 'ValidationMessagesComponent';

// tslint:disable-next-line:no-conflicting-lifecycle
@UntilDestroy()
@Component({
  selector: 'rr-validation-messages',
  templateUrl: './validation-messages.component.html',
  styleUrls: ['./validation-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationMessagesComponent implements /*OnInit, */ AfterViewChecked, OnChanges, DoCheck {
  @ViewChildren('errorElement') errorElement: QueryList<ElementRef<HTMLDivElement>>;
  /**
   * csak az elso uzenetet jeleniti meg
   * (szamit a validatorok sorrendje!)
   *
   */
  @Input() firstMessageVisible = true;
  @Input() id = `validationComponent_${id++}`;
  @Input() messages: ValidationMessageModel[];
  @Input() errors: any;
  @Input()
  @HostBinding('class.text-overflow-hidden')
  textOverflowHidden = true;
  // @ViewChildren(MatError, { read: ElementRef }) matErrors: QueryList<ElementRef>;
  errorMessages$: BehaviorSubject<ValidationTemplateMessageModel[]> = new BehaviorSubject([]);
  checkedVisibleTooltip = false;
  // }
  hasEllipsis = false;
  private currentValidatorErrors: ValidationErrors;

  constructor(
    @Inject(VALIDATION_MESSAGES_CONFIG) private validationMessages: ValidationMessageModel[],
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private ngZone: NgZone
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    let changeErrorMessages = false;
    if (checkPropertyChange('messages', changes)) {
      const value: ValidationMessageModel[] = changes.messages.currentValue;
      this.validationMessages = this.validationMessages
        .filter(validationMessage => value.findIndex(val => validationMessage.errorKey === val.errorKey) === -1)
        .concat(value);

      if (!checkPropertyChange('errors', changes)) {
        changeErrorMessages = true;
        this.errorMessages$.next(this.searchInMessageDictornary(this.currentValidatorErrors));
      }
    }
    if (checkPropertyChange('errors', changes)) {
      const value: any = changes.errors.currentValue;
      this.checkedVisibleTooltip = false;
      changeErrorMessages = true;
      this.errorMessages$.next(this.searchInMessageDictornary(value));
    }

    if (changeErrorMessages) {
      if (this.ngZone.isStable) {
        this.cdr.markForCheck();
      } else {
        this.ngZone.onStable.pipe(untilDestroyed(this), take(1)).subscribe(() => this.cdr.markForCheck());
      }
    }
  }

  ngAfterViewChecked(): void {
    if (this.elementRef.nativeElement.offsetParent !== null && this.checkedVisibleTooltip === false) {
      this.checkedVisibleTooltip = true;
      this.cdr.markForCheck();
    }
  }

  ngDoCheck(): void {
    const lastValueHasEllipsis = this.hasEllipsis;
    if (!isNil(this.errorElement) && this.errorElement.length > 0) {
      const errorElement = this.errorElement.first.nativeElement;
      this.hasEllipsis = this.textOverflowHidden && errorElement.offsetWidth < errorElement.scrollWidth;
    } else {
      this.hasEllipsis = false;
    }
    if (lastValueHasEllipsis !== this.hasEllipsis) {
      this.cdr.markForCheck();
    }
  }

  private searchInMessageDictornary(validatorErrors: ValidationErrors): ValidationTemplateMessageModel[] {
    this.currentValidatorErrors = validatorErrors;
    const msg: ValidationTemplateMessageModel[] = [];
    if (isNil(validatorErrors)) {
      return msg;
    }
    Object.keys(validatorErrors).forEach((key: string) => {
      const validationMessage = this.validationMessages.find(error => error.errorKey === key);
      if (validationMessage !== undefined) {
        msg.push({
          validationMessageModel: validationMessage,
          formError: validatorErrors[key],
        });
      } else if (key === 'customError') {
        msg.push({
          validationMessageModel: { translateKey: 'CUSTOMERROR', errorKey: 'CUSTOMERROR' },
          formError: validatorErrors[key],
        });
      } else {
        console.warn(logScopeName, `${this.id} => Not found error message: ${key}`);
      }
    });
    return msg;
  }
}
