import { NotificationsService } from '@roadrecord/external-packages/angular2-notifications';
import { SystemMessageModel } from '../state/model/system-message.model';
import { TextPlaceholderHtmlTagReplacerPipe } from '@roadrecord/common/common';
import { isNil } from '@roadrecord/type-guard';

export function notifyMessage(notificationsService: NotificationsService, msg: SystemMessageModel) {
  notificationsService[msg.type](
    msg.title,
    `${new TextPlaceholderHtmlTagReplacerPipe().transform(msg.content)}<br/><br/><small>${msg.close_text}</small>`,
    {
      timeOut: !isNil(msg.time_out) ? msg.time_out : 0,
      // progressBar: !isNil(msg.time_out) && msg.time_out > 0,
      pauseOnHover: !isNil(msg.time_out) ? msg.time_out > 0 : false,
    }
  );
}
