import { Pipe, PipeTransform } from '@angular/core';
import { RecommendationCheckerResult } from '../recommendation/state/model/recommendation-checker-result';
import { RecommendationStatusEnum } from '../recommendation/state/model/recommendation-status.enum';

@Pipe({
  name: 'recommendationCheckListFilter',
})
export class RecommendationCheckListFilterPipe implements PipeTransform {
  transform(value: RecommendationCheckerResult[], status: RecommendationStatusEnum | 'ALL'): RecommendationCheckerResult[] {
    if (!Array.isArray(value) || value.length === 0) {
      return [];
    }
    if (status === 'ALL') {
      return value;
    }
    return value.filter(check => check.status === status);
  }
}
