<rr-poimove-dialog #poimoveDialog [pois]="poimoveDialogPois" (modify)="onModifyPoimoveDialog($event)"> </rr-poimove-dialog>

<rr-partner-data-form
  #dataForm
  [disableAllSelectNewOption]="disableAllSelectNewOption"
  [coordinateLoadingMask]="coordinateLoadingMask"
  [hasSubmitAndNew]="hasSubmitAndNew"
  [editModel]="editModel$ | async"
  [isNew]="isNew$ | async"
  [type]="type"
  [disableEventButtons]="disableEventButtons"
  [showAddTripReason]="dataFormShowAddTripReason"
  [typeControlIsVertical]="dataFormTypeControlIsVertical"
  [dataFormTypeControlLabelBlock]="dataFormTypeControlLabelBlock"
  (submitForm)="onSubmit($event)"
  (delete)="onDelete()"
  (selectTripReason)="onSelectTripReason($event)"
  (modifyAddressFields)="onModifyAddressFields($event)"
></rr-partner-data-form>

<div ngClass.gt-sm="height100" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between stretch" fxLayoutGap="1em">
  <rr-progress-when-first-loading
    [fxFlex.gt-sm]="viewMode === ViewModeEnum.MAPSEARCH ? 100 : 50"
    #progressLoadingPanel
    fxFlexOrder="1"
    fxFlexOrder.gt-sm="0"
  >
    <rr-search-on-map
      *ngIf="viewMode === ViewModeEnum.MAPSEARCH"
      [coordinateLoading]="coordinateLoadingMask"
      [id]="(editModel$ | async)?.id"
      [hasModel]="(editModel$ | async) !== undefined"
      [results]="searchListResult"
      [selectedLocation]="lastSelectedLocationModel"
      [partnerType]="type"
      [searchStringText]="searchMapSearchString"
      [fragmentDialogSearchValue]="fragmentDialogSearchValue"
      [hasBackButton]="hasSearchAddressBackButton"
      [snackbarWeRefreshListDataPosition]="searchOnMapSnackbarWeRefreshListDataPosition"
      [isNew]="isNew$ | async"
      [mapboxMode]="mapboxMode"
      [outsideMarkers]="markers"
      [backgroundImage]="searchOnMapNotFoundImage"
      (markers)="markers = $event"
      (save)="onSaveSearchMap($event)"
      (cancel)="onCancelSearchMap()"
      (searchResult)="storeSearchMapSearchResult($event)"
      (searchString)="storeSearchMapSearchString($event)"
      (newPoi)="onDragendMap($event)"
    ></rr-search-on-map>
    <mat-card
      class="wid100 data-form-card auto-content-height"
      [ngClass]="{ 'hide-card': viewMode !== ViewModeEnum.DATAFORM }"
      #dataFormCard
      [@enterAnimation]
    >
      <mat-progress-bar color="warn" mode="indeterminate" *ngIf="loading$ | async"></mat-progress-bar>
      <mat-card-header>
        <mat-card-title>{{ newOrModifyFormTitle | transloco: { partnerTypeString: partnerTypeTranslateKey | transloco } }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ng-container *ngTemplateOutlet="dataForm.formTpl"></ng-container><span class="flex-spacer"></span>
        <!--
        <div *ngIf="modifyMode" @simpleFadeIn class="w-100 error-destination-calculator">
          {{ 'PARTNER.DETAILS.NO_MODIFY_DATAS' | transloco }}
        </div>
-->
      </mat-card-content>
      <mat-card-actions>
        <button
          type="button"
          *ngIf="hasBackButton"
          [@simpleFadeIn]="'enter'"
          (click)="onBack()"
          mat-icon-button
          matTooltipPosition="right"
          matTooltip="{{ backButtonTooltipText | transloco }}"
        >
          <mat-icon svgIcon="arrow-left"></mat-icon>
        </button>
        <button
          [id]="type | generateIdFromTypeEnum: '-back-to-map'"
          type="button"
          *ngIf="(isNew$ | async) === true && viewMode === ViewModeEnum.DATAFORM"
          [@simpleFadeIn]="'enter'"
          mat-icon-button
          (click)="changeToMapSearchView($event)"
          [disabled]="dataForm.form.disabled"
          matTooltipPosition="right"
          matTooltip="{{ 'PARTNER.DETAILS.BUTTON.CHANGE_TO_ADDRESS_SEARCH' | transloco }}"
        >
          <mat-icon svgIcon="map-search"></mat-icon>
        </button>
        <span class="flex-spacer"></span>
        <ng-container *ngTemplateOutlet="dataForm.buttonsTpl"></ng-container>
      </mat-card-actions>
    </mat-card>
  </rr-progress-when-first-loading>
  <rr-map-box
    *ngIf="viewMode === ViewModeEnum.DATAFORM"
    fxFlex="50"
    fxFlexOrder="1"
    enableOverlayFullScreenMode="true"
    [showRefreshLayer]="showRefreshLayer"
    [loadCurrentBrowserLocation]="isNew$ | async"
    [markers]="markers"
    [visibleMarkerTooltip]="false"
    [mapboxMode]="mapboxMode"
    [enableFullScreen]="false"
    (clickRefreshButton)="onClickRefreshButton()"
    (newPoi)="onDragendMap($event)"
  ></rr-map-box>
</div>
