import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injector, NgZone } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { DialogComponent, DialogOptionsModel } from '@roadrecord/dialog';
import {
  checkModifiedForm,
  HasPresenterSavePluginController,
  isImplementedPresenterSavePluginController,
  ViewSubmitPlugin,
} from '@roadrecord/utils';
import { isFunction, isNil, isString } from '@roadrecord/type-guard';
import { timer } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { FragmentHideDialogAction } from '../state/action/fragment-hide-dialog.action';
import { FragmentDialogTypeEnum } from '../state/model/fragment-dialog-type.enum';
import { FragmentModalWrapperComponentInterface } from './fragment-modal-wrapper-component.interface';
import { FragmentPresenterInterface } from './fragment-presenter.interface';
import { RootInjectorStore } from '@roadrecord/common/common';

export abstract class AbstractFragmentPresenterClass<MODEL, MODAL_WRAPPER_COMPONENT extends FragmentModalWrapperComponentInterface<MODEL>>
  implements FragmentPresenterInterface<MODEL, MODAL_WRAPPER_COMPONENT> {
  get cmpRef(): ComponentRef<MODAL_WRAPPER_COMPONENT> {
    return this._cmpRef;
  }
  get uiSaved(): boolean {
    return this._uiSaved;
  }

  set uiSaved(value: boolean) {
    this._uiSaved = value;
  }
  protected dialogRef: MatDialogRef<DialogComponent, any>;
  protected domPortalOutlet: DomPortalOutlet;
  protected portal: ComponentPortal<MODAL_WRAPPER_COMPONENT>;
  private _cmpRef: ComponentRef<MODAL_WRAPPER_COMPONENT>;
  protected dialogType: any = DialogComponent;
  private _uiSaved = false;

  protected constructor(
    readonly store: Store,
    readonly matDialog: MatDialog,
    readonly componentFactoryResolver: ComponentFactoryResolver,
    readonly fragmentType: FragmentDialogTypeEnum,
    readonly modalWrapperComponentType: any,
    readonly document: Document,
    readonly appRef: ApplicationRef,
    readonly injector: Injector,
    readonly detailsRoutingWhenSaveAndNotResetStrategy: { strategy: boolean },
    readonly extraDialogClass: string[] = [],
    public dialogConfig: Partial<MatDialogConfig> = {}
  ) {}

  checkDialogMaybeOpen(dialogData: object): void {
    if (dialogData !== undefined && this._cmpRef === undefined) {
      this.domPortalOutlet = new DomPortalOutlet(this.document.body, this.componentFactoryResolver, this.appRef, this.injector);
      this.portal = new ComponentPortal<MODAL_WRAPPER_COMPONENT>(this.modalWrapperComponentType);

      // Attach portal to host
      this._cmpRef = this.domPortalOutlet.attach(this.portal);

      // bind
      timer(0).subscribe(() => {
        if (this._cmpRef.instance.cancel !== undefined) {
          /*
          this._cmpRef.instance.cancel.subscribe(() => this.uiCloseDialog(true));
          */
          this._cmpRef.instance.cancel.subscribe(this.onCancel.bind(this));
        }
        this._cmpRef.instance.save.subscribe(model => this.uiSaveDialog(model));

        this.subscribeOutputs(this._cmpRef.instance);

        this.fillCmpInputs(this._cmpRef.instance);
        this._cmpRef.changeDetectorRef.detectChanges();
        // ki kell nyitni
        this.showDialog(dialogData);
      });
    } else if (dialogData === undefined && this._cmpRef !== undefined) {
      // be kell zarni
      this.hideDialog();
    }
  }

  private onCancel() {
    this.uiCloseDialog(true);
  }

  fillCmpInputs(instance: MODAL_WRAPPER_COMPONENT): void {}

  hideDialog(): void {
    if (this.dialogRef !== undefined) {
      if (isFunction(this._cmpRef.instance.beforeCloseDialog)) {
        this._cmpRef.instance.beforeCloseDialog();
      }
      this.dialogRef.close();
    }
  }

  beforeShowDialog(): void {}

  showDialog(dialogData?: unknown & Pick<DialogOptionsModel, 'showTopRightCloseButton'>): void {
    this.beforeShowDialog();
    // inputok miatt ertesitunk itt eloszor
    timer(0).subscribe(() => {
      const wrapperCmp = this._cmpRef.instance;
      this.detailsRoutingWhenSaveAndNotResetStrategy.strategy = false;
      const showTopRightCloseButton = !(
        isNil(dialogData) ||
        isNil(dialogData.showTopRightCloseButton) ||
        dialogData.showTopRightCloseButton === false
      );

      const defaultConfig = {
        panelClass: this.extraDialogClass.concat(['rr-dialog']),
        disableClose: true,
        data: {
          showTopRightCloseButton,
          cancel: showTopRightCloseButton ? this.onCancel.bind(this) : undefined,
          /*
          preventCloseCb: () => {
            let viewSubmitPluginId = null;
            let routedDataFormCmpRef = null;
            const detailsCmpRef = (this.cmpRef.instance as any).details.first;
            if (isImplementedPresenterSavePluginController(detailsCmpRef)) {
              viewSubmitPluginId = ((detailsCmpRef as HasPresenterSavePluginController<any, any>).presenterStateController.get(
                ViewSubmitPlugin
              ) as ViewSubmitPlugin<any, any>).id;
              routedDataFormCmpRef = (this.cmpRef.instance as any).details.first.dataForm;
            }
            // ha ablak bezarrassal zarjuk be akkor itt fogunk ellenorizni
            return checkModifiedForm({
              formModel: this.formValue,
              originalModel: this.newModel,
              routedDataFormCmpRef,
              viewSubmitPluginId,
            }).pipe(
              tap(result => {
                // ha ranyomott a mentes es bezarasra
                if (result.afterOkFirst === true) {
                  RootInjectorStore.getFromRootInjector(NgZone)
                    .onStable.pipe(take(1))
                    .subscribe(() => this.directSubmit());
                }
                if (result.afterOkFirst === true) {
                  // const ngZone = RootInjectorStore.getFromRootInjector(NgZone);
                  // this.cmpRef.instance.save
                  //   .pipe(take(1))
                  //   .subscribe(() => ngZone.onStable.pipe(take(1)).subscribe(() => hideAndDeletePresenter()));
                  /!**
                   * TODO RR MVP bekotese: az a gond hogy fragment presenter service-ben van tarolva ref,
                   * es innen nem erjuk el circular ref miatt, ezt kene valahogy atszervezni: hideAndDeletePresenter
                   *!/
                  // valamiert eltunik ezen a ponton a beallitas
                  if (viewSubmitPluginId === null && routedDataFormCmpRef === null) {
                    // deprecated mode: ahol nincs RR MVP megvalositva
                    this.directSubmit();
                  }
                }
              }),
              map(result => result.result),
              tap(isEqual => (this.uiSaved = isEqual))
            );
          },
*/
          contentTpl: wrapperCmp.cmpTemplate,
        } as DialogOptionsModel,
      };
      const config: MatDialogConfig = {
        ...defaultConfig,
        ...{ ...this.dialogConfig, data: { ...defaultConfig.data, ...this.dialogConfig.data } },
        closeOnNavigation: false,
      };
      this.dialogRef = this.matDialog.open(this.dialogType, config);
      this.afterShowDialog();
      this.dialogRef.afterOpened().subscribe(() => this._cmpRef.changeDetectorRef.detectChanges());
      if (this._cmpRef.instance.cancel === undefined) {
        this.dialogRef.beforeClosed().subscribe(cancel => {
          if (isString(cancel)) {
            // megse gomb megnyomasa
            this.uiCloseDialog(true);
          }
        });
      }

      this.dialogRef.afterClosed().subscribe(() => {
        this.detailsRoutingWhenSaveAndNotResetStrategy.strategy = undefined;
        this.domPortalOutlet.detach();
        this._cmpRef.destroy();
      });
    });
  }

  uiSaveDialog($event: MODEL): void {
    this._uiSaved = true;
    this.store.dispatch(new FragmentHideDialogAction(this.fragmentType, $event));
  }

  abstract uiCloseDialog(cancel: boolean): void;

  abstract get newModel(): MODEL;

  abstract get formValue(): MODEL;

  afterShowDialog(): void {}

  subscribeOutputs(instance: MODAL_WRAPPER_COMPONENT): void {}

  /**
   * Amikor programatically akarjuk a submitot hivni
   */
  abstract directSubmit(): void;
}
