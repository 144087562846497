import { GridColumnModel } from '@roadrecord/grid';
import { commonPartnerListColumnConfig } from './common-partner-list-column.config';
import { TranslocoService } from '@ngneat/transloco';
import { PartnerAddressFormatterReturnType, PartnerListModel } from '@roadrecord/partner/model';

// tslint:disable
export const partnerListColumnConfig: (_this: {
  translocoService: TranslocoService;
  partnerAddressFormatter: PartnerAddressFormatterReturnType;
}) => GridColumnModel<PartnerListModel>[] = (_this: {
  translocoService: TranslocoService;
  partnerAddressFormatter: PartnerAddressFormatterReturnType;
}): GridColumnModel<PartnerListModel>[] => {
  const commonListColumnConfig = commonPartnerListColumnConfig(_this);
  commonListColumnConfig.splice(1, 0, {
    name: 'is_private',
    displayNameTranslateKey: 'PARTNER.LIST.COLUMN.TYPE.HEADER',
    cellContentTemplateCallback: model =>
      _this.translocoService.translate(`PARTNER.LIST.COLUMN.TYPE.${model.trip_reason.is_private ? 'PRIVATE' : 'COMPANY'}`),
  });

  return commonListColumnConfig;
};
