export enum PreferencesContentWindowTypeEnum {
  ALL = 'ALL',
  FUELING_RANGE = 'FUELING-RANGE',
  MONTH_STATUS_LIST = 'MONTH-STATUS-LIST',
  FINALIZATION_OF_ROUTE_IMPORT_LIST = 'FINALIZATION-OF-ROUTE-IMPORT-LIST',
  FINALIZATION_OF_ROUTE_COPY_DAYS = 'FINALIZATION-OF-ROUTE-COPY-DAYS',
  FUELING_IMPORT_LIST = 'FUELING-IMPORT-LIST',
  HELP_VIDEO = 'HELP-VIDEO',
  REPORT_PRINT_ERROR_OR_WARNING = 'REPORT-PRINT-ERROR-OR-WARNING',
}
