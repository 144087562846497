import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CalendarModule } from './calendar/calendar.module';
import { DayListModule } from './day-list/day-list.module';
import { DetailsModule } from './details/details.module';

import { FuelingRoutingModule } from './fueling-routing.module';
import { FinalizationOfRouteService } from '@roadrecord/finalization-of-route/common';
import { UNIVERSAL_IMPORT_CONFIG } from '@roadrecord/universal-import/common';
import { importConfig } from './import/import-config';

@NgModule({
  imports: [CommonModule, FuelingRoutingModule, DetailsModule, CalendarModule, DayListModule],
  providers: [FinalizationOfRouteService, { provide: UNIVERSAL_IMPORT_CONFIG, useValue: importConfig }],
})
export class FuelingModule {}
