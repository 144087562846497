import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionDispatched, ofActionSuccessful, Store } from '@ngxs/store';
import {
  AppLoadedAction,
  AppStartAction,
  GetServerVersionAction,
  GetServerVersionErrorAction,
  GetServerVersionSuccessAction,
} from '@roadrecord/application-settings/state';
import { GetUserStatesAction, GetUserStatesErrorAction, RootInjectorStore, ViewportService } from '@roadrecord/common/common';
import { isNotHandledError } from '@roadrecord/utils';
import { I18nState, SelectLanguageAction, SetLanguageOptionsAction } from '@roadrecord/i18n-state';
import { IdleStartDetectAction } from '@roadrecord/idle-detect';
import { cache, MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';
import { PreferencesLoadAction, PreferencesLoadErrorAction, PreferencesLoadSuccessAction } from '@roadrecord/preferences-state/common';
import { SwUpdatesService } from '@roadrecord/service-worker';
import { isNil, isString } from '@roadrecord/type-guard';
import { AuthState, SocialRegistrationSuccessfulAction } from '@roadrecord/user/common';
import moment from 'moment';
import { asapScheduler, combineLatest, Observable, race, timer } from 'rxjs';
import { delay, filter, first, take } from 'rxjs/operators';
import { userBrowserName, userOsName } from '@roadrecord/main-helper';
import * as countries from 'i18n-iso-countries';

import i18n_iso_countries_hu from 'i18n-iso-countries/langs/en.json';
import i18n_iso_countries_en from 'i18n-iso-countries/langs/hu.json';
import { Injector } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { DateAdapter } from '@angular/material/core';
import {
  GetDateFirstDayWeekSettingAction,
  GetDateFirstDayWeekSettingErrorAction,
  GetDateFirstDayWeekSettingSuccessAction,
  updateFirstDayOfWeek,
} from '@roadrecord/user/application-settings';
import { environment } from '@roadrecord/environment';

export abstract class BaseAppModule {
  protected bootstraped = false;

  protected constructor(
    protected readonly logScopeName: string,
    protected translocoService: TranslocoService,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    protected document: Document,
    protected window: Window & { Cypress: any; store: any },
    protected isWebadmin: boolean,
    viewportService: ViewportService,
    protected store: Store,
    protected actions$: Actions,
    location: Location,
    swUpdatesService: SwUpdatesService,
    protected breakpointObserver: BreakpointObserver,
    // lazy load service, is required mileage list hotkey
    protected messageDialogService: MessageDialogService,
    protected injector: Injector
  ) {
    RootInjectorStore.setRootInjector(injector);
    // TODO atalakitani, valahogy webpack-vel kene beszurni
    if (environment.mode === 'e2e' && !isNil(window.Cypress)) {
      window.store = this.store;
    }
    this.setGoogleRouteTracking();

    moment.locale(environment.languages.defaultLang.split('-')[0]);
    // TEMP FIX!
    const sl = this.store.selectSnapshot<string>(I18nState.selectedLang);
    /*if (sl === 'hu') {
      this.store.dispatch(new SelectLanguageAction(LOCALES.HU_HU));
    } else if (sl === 'en') {
      this.store.dispatch(new SelectLanguageAction(LOCALES.EN_US));
    } else*/ if (
      sl !== 'hu-hu' &&
      sl !== 'en-us'
    ) {
      // ez kell american mode nelkul is
      this.store.dispatch(new SelectLanguageAction(environment.languages.defaultLang));
    } else {
      // ez kell american mode nelkul is
      this.store.dispatch(new SetLanguageOptionsAction());
    }

    this.ngxTranslateSetDefaultAndUseLanguage();

    this.bootstrap();

    this.watchLangChangAndReplaceBodyClass();
    document.body.classList.add(`browser-${userBrowserName}`);
    document.body.classList.add(`os-${userOsName}`);

    this.detectBreakpointChange(viewportService, document);

    this.actions$.pipe(ofActionSuccessful(AppLoadedAction), take(1)).subscribe(() => {
      const startActionCb = () => {
        const isLoggedIn = this.store.selectSnapshot(AuthState.isLoggedIn);
        this.store.dispatch(new AppStartAction(isLoggedIn ? 200 : 2000));
      };
      const routing = false;
      this.setBodyClassByUserLoggedIn(document);

      // tslint:disable-next-line
      console.info(this.logScopeName, `%c RoadRecord client version: ${environment.VERSION}`, 'background: #222; color: #bada55');
      if (routing === false) {
        startActionCb();
      }
    });

    // registrate custom material icons
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
    matIconRegistry.addSvgIcon(
      'freshbook-logo',
      injector.get(DomSanitizer).bypassSecurityTrustResourceUrl('./assets/logos/freshbook/freshbooks-logo.svg')
    );
    matIconRegistry.addSvgIcon(
      'freshbook-icon',
      injector.get(DomSanitizer).bypassSecurityTrustResourceUrl('./assets/logos/freshbook/freshbooks-icon.svg')
    );
  }

  private setGoogleRouteTracking() {
    if (environment.tracking.gtag.enable) {
      this.injector
        .get(Router)
        .events.pipe(filter(event => event instanceof NavigationEnd))
        .forEach((item: NavigationEnd) => {
          const { urlAfterRedirects: url } = item;
          const tagManager = this.injector.get(GoogleTagManagerService);
          if (!isNil(tagManager)) {
            tagManager.pushTag({
              event: 'page',
              pageName: url.charAt(0) !== '/' ? `/${url}` : url,
            });
          }
        });
    }
  }

  private watchLangChangAndReplaceBodyClass() {
    this.store.select(I18nState.selectedLang).subscribe(lang => {
      const bodyClassList = this.document.body.classList;
      let foundClass: string;
      bodyClassList.forEach(cssClass => {
        if (cssClass.startsWith('locale-')) {
          foundClass = cssClass;
        }
      });
      bodyClassList.remove(foundClass);
      bodyClassList.add(`locale-${lang}`);
    });
  }

  private setBodyClassByUserLoggedIn(document: Document): void {
    this.store.select(AuthState.isLoggedIn).subscribe(isLoggedIn => {
      if (isLoggedIn) {
        if (document.body.classList.contains('not-logged-in')) {
          document.body.classList.remove('not-logged-in');
        }
        if (!document.body.classList.contains('logged-in')) {
          document.body.classList.add('logged-in');
        }
      } else {
        if (document.body.classList.contains('logged-in')) {
          document.body.classList.remove('logged-in');
        }
        if (!document.body.classList.contains('not-logged-in')) {
          document.body.classList.add('not-logged-in');
        }
      }
    });
  }

  /**
   * TODO ez lehet mar nem kell mert main-bol adjuk hozza a mobile es tablet class-t ...
   */
  private detectBreakpointChange(viewportService: ViewportService, document: Document): void {
    viewportService.breakpoint$.subscribe(breakpoint =>
      Object.entries(breakpoint).forEach((status: [string, boolean]) => {
        if (status[1] === true) {
          if (!document.body.classList.contains(status[0])) {
            document.body.classList.add(status[0]);
          }
        } else {
          if (document.body.classList.contains(status[0])) {
            document.body.classList.remove(status[0]);
          }
        }
      })
    );
  }

  private ngxTranslateSetDefaultAndUseLanguage(): void {
    const selectedLang = this.store.selectSnapshot<string>(I18nState.selectedLang);

    this.document.body.classList.add(`locale-${selectedLang}`);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // debugger;
    // this.translateService.setActiveLang(selectedLang).subscribe(() => {
    this.translocoService
      .selectTranslate('COMMON.ERROR')
      .pipe(filter(v => isString(v)))
      .subscribe(title => (cache.title = title));
    this.translocoService
      .selectTranslate('COMMON.UNKNOWN_ERROR_WITHOUT_MESSAGE')
      .pipe(filter(v => isString(v)))
      .subscribe(text => (cache.text = text));
    // });

    countries.registerLocale(i18n_iso_countries_hu);
    countries.registerLocale(i18n_iso_countries_en);
  }

  private bootstrap(): void {
    this.bootstraped = true;
    const isLoggedIn = this.store.selectSnapshot(AuthState.isLoggedIn);

    const getErrorsSubscription = race([
      this.actions$.pipe(ofActionDispatched(GetServerVersionErrorAction), take(1)),
      this.actions$.pipe(ofActionDispatched(GetUserStatesErrorAction), take(1)),
      this.actions$.pipe(ofActionDispatched(PreferencesLoadErrorAction), take(1)),
      this.actions$.pipe(ofActionDispatched(GetDateFirstDayWeekSettingErrorAction), take(1)),
    ])
      .pipe(take(1))
      .subscribe((action: GetServerVersionErrorAction) => {
        const pageLoaderElement = this.document.getElementById('page-loader');
        if (!isNil(pageLoaderElement)) {
          document.getElementById('page-loader').classList.add('finished');
          timer(2000).subscribe(() => {
            pageLoaderElement.remove();
          });
        }
        if (isNotHandledError(action.error)) {
          console.error(this.logScopeName, action.error);
          this.messageDialogService
            .open({
              id: null,
              type: MessageDialogTypeEnum.ERROR,
              title: 'COMMON.APP_INITIALIZER.ERROR_FIRST_LOAD.TITLE',
              text: 'COMMON.APP_INITIALIZER.ERROR_FIRST_LOAD.DETAIL',
            })
            .afterClosed()
            .subscribe(() => location.reload());
        }
      });

    if (!isLoggedIn) {
      this.actions$
        .pipe(ofActionSuccessful(GetServerVersionSuccessAction), first())
        .subscribe(() => asapScheduler.schedule(() => this.store.dispatch(new AppLoadedAction())));
      this.store.dispatch(new GetServerVersionAction()).subscribe(() => {
        getErrorsSubscription.unsubscribe();
      });
      return;
    }
    // loggedIn user
    let watchActions: Observable<any>[];
    if (this.isWebadmin) {
      watchActions = [
        this.actions$.pipe(ofActionSuccessful(GetServerVersionSuccessAction), take(1)),
        this.actions$.pipe(ofActionSuccessful(GetDateFirstDayWeekSettingSuccessAction), take(1)),
      ];
    } else {
      watchActions = [
        this.actions$.pipe(ofActionSuccessful(GetServerVersionSuccessAction), take(1)),
        this.actions$.pipe(ofActionSuccessful(PreferencesLoadSuccessAction), take(1)),
        this.actions$.pipe(ofActionSuccessful(GetDateFirstDayWeekSettingSuccessAction), take(1)),
      ];
    }
    combineLatest([
      ...watchActions,
      // this.breakpointObserver.observe([Breakpoints.Handset]).pipe(
      //   first(),
      //   switchMap(isMobile => {
      //     if (isMobile.matches === true) {
      //       const pageLoaderElement = this.document.getElementById('page-loader');
      //       if (!isNil(pageLoaderElement)) {
      //         pageLoaderElement.classList.add('finished');
      //       }
      //       return this.messageDialogService
      //         .open({
      //           id: null,
      //           text: 'COMMON.MESSAGE_DIALOG.MOBILE_ONLY_BASIC_DATAS',
      //           type: MessageDialogTypeEnum.INFORMATION,
      //         })
      //         .afterClosed()
      //         .pipe(map(() => true));
      //     }
      //     return of(false);
      //   })
      // ),
    ])
      /**
       * ez itt azert kell mert a state nem vegez mindig a muveletekkel idoben igy a preferences state nem ertisiti ki a feliratkozokat a valtozasrol,
       * ezert kell egy iteracio ugras trukk, pl: ha first stepper elso lepesenel lep be a user mert ott youtube url-t pref state-bol toltjuk be
       */
      .pipe(delay(100))
      .subscribe(_actions => {
        getErrorsSubscription.unsubscribe();

        this.handleExtraInitialRoute();

        this.setFirstDayOfWeek(_actions[this.isWebadmin ? 1 : 2].response);

        if (_actions[0] !== undefined && (this.isWebadmin || _actions[1] !== undefined)) {
          asapScheduler.schedule(() => this.store.dispatch([new AppLoadedAction(), new IdleStartDetectAction()]));
        }
      });
    asapScheduler.schedule(() => {
      this.store.dispatch(new GetUserStatesAction()).subscribe(action => {
        if (action instanceof HttpErrorResponse) {
          getErrorsSubscription.unsubscribe();
          return;
        }
        let dispatchActions: any[] = [];
        if (this.isWebadmin) {
          dispatchActions = [new GetServerVersionAction(), new GetDateFirstDayWeekSettingAction()];
        } else {
          dispatchActions = [new GetServerVersionAction(), new PreferencesLoadAction(), new GetDateFirstDayWeekSettingAction()];
        }
        asapScheduler.schedule(() => this.store.dispatch(dispatchActions));
      });
    });
  }

  private setFirstDayOfWeek(day: number) {
    updateFirstDayOfWeek(day, this.injector.get(DateAdapter) as DateAdapter<unknown>);
  }

  /**
   * extra route inditasi lehetoseg, pl: social regisztracio eseten
   * @private
   */
  private handleExtraInitialRoute() {
    const route = localStorage.getItem('route');
    if (!isNil(route)) {
      localStorage.removeItem('route');
      const navigationExtras: NavigationExtras = {};
      if (route.indexOf('registration-successful') > -1) {
        navigationExtras.queryParams = { email: this.store.selectSnapshot(AuthState.user).email };
        this.store.dispatch(new SocialRegistrationSuccessfulAction(true));
      }
      // console.log('navigationExtras', navigationExtras);
      localStorage.removeItem('route');
      this.injector.get(Router).navigate([route], navigationExtras);
      return true;
    }
    return false;
  }
}
