import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fadeInEnter } from '@roadrecord/animations';
import { checkPropertyChange, deepEqual } from '@roadrecord/common/common';
import { isNil } from '@roadrecord/type-guard';
import { LiveAutoCompleteOptionsConfigModel } from '@roadrecord/live-auto-complete';
import { AbstractPeriodContextModel } from '@roadrecord/period-context/common';
import { VehicleModel } from '@roadrecord/vehicle/model/common';
import moment, { Moment } from 'moment';
import { AppTypeEnum, environment } from '@roadrecord/environment';
import { VehicleService } from '@roadrecord/vehicle/service/common';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'rr-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInEnter],
})
export class FormComponent implements OnChanges, OnInit {
  form: FormGroup & { submitted?: boolean };
  yearMonthControl = new FormControl(moment(), Validators.required);
  vehicleControl = new FormControl(null, Validators.required);

  @Input()
  dataModel: AbstractPeriodContextModel<any>;

  laVehicleOptionsConfig: LiveAutoCompleteOptionsConfigModel<VehicleModel>;
  @Input() hideHeader = false;
  readonly isHuAppType = environment.appType === AppTypeEnum.HU;
  @Output() formChange = new EventEmitter<{ vehicle: any; yearMonth: Moment }>();

  constructor(readonly vehicleService: VehicleService<VehicleModel>) {
    this.laVehicleOptionsConfig = vehicleService.liveAutocompleteConfig;
    this.initForm();
  }

  ngOnInit() {
    this.watchFormValueChanges();
  }

  private initForm(): void {
    this.form = new FormGroup({
      vehicle: this.vehicleControl,
      yearMonth: this.yearMonthControl,
    });
  }

  private watchFormValueChanges() {
    this.form.valueChanges
      .pipe(distinctUntilChanged((_old, _new) => deepEqual(_old, _new)))
      .subscribe(value => this.formChange.emit(value));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (checkPropertyChange('dataModel', changes)) {
      const yearMonthMoment = this.yearMonthControl.value;

      if (
        (!isNil(changes.dataModel.currentValue.vehicle) &&
          (this.vehicleControl.value !== changes.dataModel.currentValue.vehicle.id ||
            (!isNil(changes.dataModel.previousValue.vehicle) &&
              changes.dataModel.currentValue.vehicle.id === changes.dataModel.previousValue.vehicle.id &&
              changes.dataModel.currentValue.vehicle.plate_number !== changes.dataModel.previousValue.vehicle.plate_number))) ||
        +changes.dataModel.currentValue.year !== +yearMonthMoment.format('YYYY') ||
        +changes.dataModel.currentValue.month !== +yearMonthMoment.format('MM')
      ) {
        this.form.enable({ emitEvent: false });
        yearMonthMoment.set('year', changes.dataModel.currentValue.year);
        yearMonthMoment.set('month', changes.dataModel.currentValue.month - 1);

        this.form.patchValue(
          { vehicle: changes.dataModel.currentValue.vehicle, yearMonth: yearMonthMoment },
          { emitEvent: !changes['dataModel'].firstChange }
        );
      }
    }
  }
}
