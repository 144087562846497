import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  Output,
} from '@angular/core';
import { timer } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'rr-button-card',
  templateUrl: './button-card.component.html',
  styleUrls: ['./button-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonCardComponent implements AfterViewInit {
  readonly translateKeyPrefix = 'FIRST_STEPS.FINISH_WELCOME.LAYOUT';
  @Input() tagManagerId: string;
  @Input() disableButton = false;
  @Input() imageUrl: string;
  @Input() buttonLabel: string;
  @Input() buttonLink: string;
  @Output() clickButton = new EventEmitter<{ link: string; label: string }>();
  @Output() changeWidth = new EventEmitter<number>();

  constructor(private el: ElementRef, private ngZone: NgZone) {}

  ngAfterViewInit() {
    this.setCardsWrapperWidth(true);
    this.ngZone.onStable.pipe(take(1), untilDestroyed(this)).subscribe(() => this.setCardsWrapperWidth(true));
  }

  @HostListener('window:resize')
  onResizeWindow(): void {
    this.setCardsWrapperWidth();
  }

  private setCardsWrapperWidth(repeat = false) {
    const width = this.el.nativeElement.getBoundingClientRect().width;
    if (width > 0) {
      this.changeWidth.emit(width);
    } else if (repeat) {
      timer(300)
        .pipe(untilDestroyed(this))
        .subscribe(() => this.setCardsWrapperWidth(true));
    }
  }

  @HostListener('click', ['$event'])
  onClick($event: Event): boolean {
    $event.stopPropagation();
    $event.preventDefault();

    if (this.disableButton === false) {
      this.clickButton.emit({ link: this.buttonLink, label: this.buttonLabel });
    }

    return false;
  }
}
