import { striptags, TextPlaceholderHtmlTagReplacerPipe } from '@roadrecord/common/common';
import { MessageDialogOptionsInterface, MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';
import { isNil, isObject, isString } from '@roadrecord/type-guard';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServerErrorTypeEnum } from '../enum/server-error-type.enum';
import { HandledHttpError } from '../exception/handled-http.error';
import { RRHttpErrorResponse } from '../exception/rr-http-error-response';
import { MatDialogConfig } from '@angular/material/dialog';

export function handleErrorObjectGenerateDialogOptions(
  error: RRHttpErrorResponse,
  dialogOptionOverride?: Partial<MessageDialogOptionsInterface>,
  disableHtmlElements: string[] = []
) {
  let dialogOptions: MessageDialogOptionsInterface = {
    id: null,
    confirmLabel: 'COMMON.ACTION.CLOSE',
    text: '',
  };

  const BRTag = '[%br%]';
  const BRTagRegexp = '\\[\\%br\\%\\]';
  // let detailsIsHtml = false;
  let detail = striptags(error.error.detail !== undefined ? error.error.detail : '');
  const title = striptags(error.error.title !== undefined ? error.error.title : '');
  if (error.error.detail.indexOf(BRTag) > -1) {
    // br tag kezelese
    // detailsIsHtml = true;
    detail = detail.replace(new RegExp(BRTagRegexp, 'g'), `<br/>`);
  }
  if (isObject(error.error.html_tags)) {
    // detailsIsHtml = true;
    Object.entries(error.error.html_tags).forEach((entry: [string, { link: string; text: string }]) => {
      if (entry[0].split('_')[0] === 'a') {
        detail = detail.replace(
          // tslint:disable-next-line
          new RegExp('\\[\\%' + entry[0] + '\\%\\]', 'g'),
          `<a target='_blank' href='${entry[1].link}'>${entry[1].text}</a>`
        );
      } else {
        detail = new TextPlaceholderHtmlTagReplacerPipe().transform(detail, { disableHtmlElements });
      }
    });
  }
  /**
   * TODO ideiglenesen kikotve, TextPlaceholderHtmlTagReplacerPipe ki kell boviteni egy olyan keresessel ami megnezi
   * van-e html jelolo a kuldott szovegben
   */
  // if (detailsIsHtml) {
  dialogOptions.htmlMode = true;
  // }
  dialogOptions.text = new TextPlaceholderHtmlTagReplacerPipe().transform(detail, { disableHtmlElements });

  switch (error.error.error_type) {
    case ServerErrorTypeEnum.APPLICATION_ERROR:
      dialogOptions.title = 'COMMON.ERROR';
      dialogOptions.type = MessageDialogTypeEnum.ERROR;
      break;
    case ServerErrorTypeEnum.WARNING:
      dialogOptions.type = MessageDialogTypeEnum.WARNING;
      break;
    case ServerErrorTypeEnum.INFORMATION:
      dialogOptions.type = MessageDialogTypeEnum.INFORMATION;
      break;
  }

  // title override-ha kuldi a backend
  if (isString(title) && title.length > 0) {
    dialogOptions.title = title;
  }
  if (!isNil(dialogOptionOverride)) {
    dialogOptions = { ...dialogOptions, ...dialogOptionOverride };
  }

  return dialogOptions;
}

export class HandleErrorObject {
  public static handleErrorObject(
    error: RRHttpErrorResponse,
    matDialogOptions?: MatDialogConfig,
    dialogOptionOverride: Partial<MessageDialogOptionsInterface> = {},
    returnError = true
  ): Observable<HandledHttpError | { result: boolean }> {
    const dialogOptions = handleErrorObjectGenerateDialogOptions(error, dialogOptionOverride);
    const messageDialogService = MessageDialogService.INSTANCE;
    if (!isNil(messageDialogService.openedDialogRef)) {
      messageDialogService.openedDialogRef.close();
    }
    const return$ = messageDialogService.open(dialogOptions, matDialogOptions);
    if (returnError) {
      return return$.beforeClosed().pipe(map(() => error));
    } else {
      return return$.afterClosed();
    }
  }
}
