<mat-card class="help-text w-100" *ngIf="helpText" #helpTextElem>
  <mat-card-content>
    <div class="d-flex align-items-center">
      <mat-icon [svgIcon]="iconType" class="mr-3"></mat-icon>
      <div class="flex-column" [ngClass]="{ 'has-content': helpText?.content?.length > 0 }">
        <b class="title" [innerHTML]="helpText.title | textPlaceholderHtmlTagReplacer | sanitizeHtml"></b>
        <p class="content mb-0" [innerHTML]="helpText.content | textPlaceholderHtmlTagReplacer | sanitizeHtml"></p>
      </div> </div></mat-card-content></mat-card
><div *ngIf="showSkipButton"
  ><button
    [id]="layoutName + '-skip-button'"
    data-cy="skip-button"
    type="button"
    class="extended-fab"
    mat-fab
    [matTooltip]="'FIRST_STEPS.HELP_BOX.SKIP_STEP.TOOLTIP' | transloco"
    (click)="onClickSkipStep()"
    ><span class="text">{{ 'FIRST_STEPS.HELP_BOX.SKIP_STEP.BUTTON' | transloco }}</span
    ><mat-icon svgIcon="skip-forward"></mat-icon></button
></div>
