import { Provider } from '@angular/core';
import { numberCheckMethod } from './function/number-check.method';
import { PARAM_CHECK_METHOD_TOKEN, PARAM_NAME_TOKEN } from './injection.token';
import { ParamTypeCheckGuard } from './param-type-check.guard';

export const ID_NUMBER_CHECK_PROVIDERS: Provider[] = [
  { provide: PARAM_NAME_TOKEN, useValue: 'id' },
  { provide: PARAM_CHECK_METHOD_TOKEN, useValue: numberCheckMethod },
  ParamTypeCheckGuard,
];
