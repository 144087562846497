import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@roadrecord/environment';
import {
  BillingInformationResponseModel,
  CalculatedResponseModel,
  LicensedVehicleInfoModel,
  LicensedVehicleResponseModel,
  MagicLinkModel,
  PaymentResponseModel,
  PaymentTransactionResponseModel,
  PaymentTransactionResultModel,
  PlaceOrderModel,
  PlaceOrderResponseModel,
  PriceItemModel,
  PriceResponse,
} from './model/models';
import { Observable } from 'rxjs';
import { PaymentStatus } from './model/payment-status.enum';
import { AbstractEntityService, HttpListResponseModel } from '@roadrecord/utils';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { isEmpty, isNil } from '@roadrecord/type-guard';
import { MagicLinkPath } from './model/local-storage.enum';

@Injectable()
export class PriceService extends AbstractEntityService<HttpListResponseModel<any>, any> {
  readonly baseURLPattern;
  private _product_category = 2;

  get product_category(): number {
    return this._product_category;
  }

  set product_category(value: number) {
    this._product_category = value;
  }

  constructor(http: HttpClient) {
    super('', name, http);
    this.baseURLPattern = `${environment.apiUrl}payment-manager`;
  }

  getToString(model: PaymentTransactionResultModel): string {
    return '';
  }

  getModelIdValue(model: PaymentTransactionResultModel): any {
    return undefined;
  }

  /**
   * Magic linkkel történő vásárlás esetén a headerben továbbítani kell
   * @private
   */
  private createMagicLinkHeaderSection(): HttpHeaders | null {
    let header = null;

    const magicLinkKey = localStorage.getItem(MagicLinkPath.MAGIC_LINK_PATH);
    if (!isNil(magicLinkKey) && !isEmpty(magicLinkKey)) {
      const magicLinkData: MagicLinkModel = JSON.parse(magicLinkKey);
      header = new HttpHeaders().set('CRM-AUTHORIZATION', `${magicLinkData.id} ${magicLinkData.uuid}`);
    }

    return header;
  }

  /**
   * Elérhető árlista lekérdezése
   */
  getPrices(): Observable<PriceResponse> {
    const headers = this.createMagicLinkHeaderSection();
    const queryParams = new HttpParams().set('expand', 'product_category').set('product_category__id', `${this.product_category}`);

    return this.http.get<PriceResponse>(`${this.baseURLPattern}/product/`, {
      headers: headers,
      params: queryParams,
    });
  }

  /**
   * Egy adott árlista termék lekérdezése
   * @param product_id {number}
   */
  getSelectedPackageInformation(product_id: number): Observable<PriceItemModel> {
    const headers = this.createMagicLinkHeaderSection();
    const queryParams = new HttpParams().set('expand', 'product_category');

    return this.http.get<PriceItemModel>(`${this.baseURLPattern}/product/${product_id}/`, {
      headers: headers,
      params: queryParams,
    });
  }
  /**
   * Számlázási információk lekérdezése
   */
  getBillingInformation(): Observable<BillingInformationResponseModel> {
    const headers = this.createMagicLinkHeaderSection();

    return this.http.get<BillingInformationResponseModel>(`${this.baseURLPattern}/billing-information/`, {
      headers: headers,
    });
  }

  /**
   * Kiválasztott csomaghoz tartozó árkalkuláció a megadott gépjármű és idpőszak függvényében
   * @param subscription_start_date {string}
   * @param product_id {number}
   * @param vehicle_count {number}
   */
  getCalculatePrice(subscription_start_date: string, product_id: number, vehicle_count: number): Observable<CalculatedResponseModel> {
    const payload = {
      product_id: product_id,
      vehicle_count: vehicle_count,
      subscription_start_date: subscription_start_date,
    };
    const headers = this.createMagicLinkHeaderSection();

    return this.http.post<CalculatedResponseModel>(`${this.baseURLPattern}/calculate/`, payload, {
      headers: headers,
    });
  }

  /**
   * Rendelési adatok küldése
   * @param payload {PlaceOrderModel}
   */
  placeOrder(payload: PlaceOrderModel): Observable<PlaceOrderResponseModel> {
    const headers = this.createMagicLinkHeaderSection();

    return this.http.post<PlaceOrderResponseModel>(`${this.baseURLPattern}/place-order/`, payload, {
      headers: headers,
    });
  }

  /**
   * Kiválasztott rendelés kifizetése
   * @param transaction_id {number}
   * @param payment_provider_type {string}
   */
  executeOrder(transaction_id: number, payment_provider_type: string): Observable<PaymentResponseModel> {
    const payload = {
      payment_provider_type: payment_provider_type,
    };
    const headers = this.createMagicLinkHeaderSection();

    return this.http.post<PaymentResponseModel>(`${this.baseURLPattern}/execute-order/${transaction_id}/`, payload, {
      headers: headers,
    });
  }

  /**
   * Nyitott liszenszek lista lekérdezése
   */
  getPaymentTransaction(
    sort: Sort = { active: '', direction: 'asc' },
    page: PageEvent = {
      pageIndex: 0,
      pageSize: 99999999,
      length: 1,
    },
    simpleAllFilterValue = ''
  ): Observable<PaymentTransactionResponseModel> {
    const queryParams = this.getUrlParameters(sort, page, simpleAllFilterValue);

    return this.http.get<PaymentTransactionResponseModel>(
      `${this.baseURLPattern}/payment-transaction/?${queryParams}&status=${PaymentStatus.SUCCESS}&available_vehicles_greater_than=0`
    );
  }

  /**
   * Elérhető autók lekérdezése
   */
  getAvailableVehicles(
    sort: Sort = { active: '', direction: 'asc' },
    page: PageEvent = {
      pageIndex: 0,
      pageSize: 99999999,
      length: 1,
    },
    simpleAllFilterValue = ''
  ) {
    const queryParams = this.getUrlParameters(sort, page, simpleAllFilterValue);
    return this.http.get<any>(`${this.baseURLPattern}/available-vehicles/?${queryParams}`);
  }

  /**
   * Autó előfizetés összerendelés
   * @param vehicle_id {number}
   * @param payment_transaction_id {number}
   */
  createUseVehicleLicense(vehicle_id: number, payment_transaction_id: number) {
    const payload = {
      vehicle_id: vehicle_id,
      payment_transaction_id: payment_transaction_id,
    };
    return this.http.post(`${this.baseURLPattern}/use-vehicle-license/`, payload);
  }

  /**
   * Gépjárművek és tartozó licensek lekérdezése
   * @param sort {Sort}
   * @param page {PageEvent}
   * @param simpleAllFilterValue {string}
   */
  getLicensedVehicle(
    sort: Sort = { active: '', direction: 'asc' },
    page: PageEvent = {
      pageIndex: 0,
      pageSize: 99999999,
      length: 1,
    },
    simpleAllFilterValue = ''
  ): Observable<LicensedVehicleResponseModel> {
    const queryParams = this.getUrlParameters(sort, page, simpleAllFilterValue);
    return this.http.get<LicensedVehicleResponseModel>(`${this.baseURLPattern}/licensed-vehicle/?${queryParams}`);
  }

  getLicensedVehicleById(vehicle_id: number): Observable<LicensedVehicleInfoModel[]> {
    return this.http.get<LicensedVehicleInfoModel[]>(`${this.baseURLPattern}/licensed-vehicle/${vehicle_id}/`);
  }
}
