import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment, { Moment } from 'moment';
import { FakeCalendarHeaderComponent } from './fake-calendar-header.component';
import { YearMonthFieldDateFormat } from './year-month-field-date-format';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'rr-year-month-field',
  templateUrl: './year-month-field.component.html',
  styleUrls: ['./year-month-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MAT_DATE_FORMATS, useValue: YearMonthFieldDateFormat }],
})
export class YearMonthFieldComponent {
  readonly headerCmp = FakeCalendarHeaderComponent;
  @Input() labelTranslateKey: string;
  @Input() parentForm: FormGroup;
  @Input() yearMonthControl: FormControl;
  @Input() maxDate = moment().toDate();
  @Input() minDate = null;

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.yearMonthControl.setValue(moment(`${normalizedMonth.year()}-${normalizedMonth.month() + 1}-1`, 'YYYY-MM-DD'));
    datepicker.close();
  }
}
