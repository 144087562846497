import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { FuelingCalendarMonthViewDay } from './model/fueling-calendar-month-view-day';
import { isNil } from '@roadrecord/type-guard';

export function dblClickCellFunction(
  router: Router,
  route: ActivatedRoute,
  $event: Partial<FuelingCalendarMonthViewDay>,
  routePrefix: string[] = []
) {
  const selectedDay = moment($event.date).format('YYYY-MM-DD');
  if (!isNil($event.events) && $event.events.length === 0) {
    return router.navigate([...routePrefix, 'new'], { relativeTo: route, queryParams: { selectedDay } });
  } else {
    return router.navigate([...routePrefix, 'day-list'], { relativeTo: route, queryParams: { selectedDay } });
  }
}
