import {
  AbstractFragmentPresenterClass,
  FragmentDialogTypeEnum,
  FragmentPresenterConstructor,
  PresenterConfigInterface,
} from '@roadrecord/fragment-dialog';
import { TripReasonEditFragmentPresenter } from './trip-reason-edit-fragment.presenter';
import { TripReasonNewFragmentPresenter } from './trip-reason-new-fragment.presenter';

export function _newPresenterFactory({
  store,
  matDialog,
  fragmentType,
  componentFactoryResolver,
  document,
  appRef,
  injector,
}: FragmentPresenterConstructor<any>): AbstractFragmentPresenterClass<any, any> {
  return new TripReasonNewFragmentPresenter(store, matDialog, fragmentType, componentFactoryResolver, document, appRef, injector);
}

export function _editPresenterFactory({
  store,
  matDialog,
  fragmentType,
  componentFactoryResolver,
  document,
  appRef,
  injector,
}: FragmentPresenterConstructor<any>): AbstractFragmentPresenterClass<any, any> {
  return new TripReasonEditFragmentPresenter(store, matDialog, fragmentType, componentFactoryResolver, document, appRef, injector);
}

export const TRIP_REASON_NEW_FRAGMENT_PRESENTERS: PresenterConfigInterface[] = [
  {
    id: FragmentDialogTypeEnum.NEW_TRIP_REASON,
    appRef: null,
    componentFactoryResolver: null,
    document: null,
    injector: null,
    presenterFactory: _newPresenterFactory,
  },
] as PresenterConfigInterface[];

export const TRIP_REASON_EDIT_FRAGMENT_PRESENTERS: PresenterConfigInterface[] = [
  {
    id: FragmentDialogTypeEnum.EDIT_TRIP_REASON,
    appRef: null,
    componentFactoryResolver: null,
    document: null,
    injector: null,
    presenterFactory: _editPresenterFactory,
  },
] as PresenterConfigInterface[];
