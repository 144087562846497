import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { checkIsLoggedIn } from './check-is-logged-in.function';

@Injectable({ providedIn: 'root' })
export class NotNeedAuthGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }

  check(): boolean {
    const isLoggedIn = this.store.selectSnapshot<boolean>(states => checkIsLoggedIn(states.auth));
    if (isLoggedIn) {
      this.router.navigate(['/']);
    }
    return isLoggedIn === false;
  }
}
