import { Renderer2 } from '@angular/core';
import { isNil } from '@roadrecord/type-guard';
import { timer } from 'rxjs';

/**
 * @deprecated
 */
export function focusElement(renderer2: Renderer2, name: string): void {
  const element = renderer2.selectRootElement(`[name='${name}']`);
  if (element === null || element === undefined) {
    throw new Error(`Not found focused element: ${name}`);
  }
  element.focus();
}

export function focusElement2(document: Document, name: string, delay = 0): boolean {
  const element: HTMLInputElement = document.querySelector(`[name='${name}']`);
  if (element === null || element === undefined || !isNil(element.focus) === false) {
    // throw new Error(`Not found focused element: ${name}`);
    console.error('focusElement2', `Not found focused element: ${name}`);
    return false;
  }
  if (delay > 0) {
    timer(delay).subscribe(() => {
      element.focus();
    });
  } else {
    element.focus();
  }
  return true;
}

export function selectElement(document: Document, name: string): void {
  // TODO: ennek és az előző fv-nek az elejét ki lehetne emelni
  const element: HTMLInputElement = document.querySelector(`[name='${name}']`);
  if (element === null || element === undefined) {
    throw new Error(`Not found focused element: ${name}`);
  }
  element.select();
}
