import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppTypeEnum, environment } from '@roadrecord/environment';
import { PhoneNumberModel } from '../model/model';
import { Observable } from 'rxjs';
import { CompanyContextState } from '@roadrecord/company-context/common';
import { Store } from '@ngxs/store';
@Injectable({
  providedIn: 'root',
})
export class PhoneNumberService {
  readonly isUsAppType = environment.appType === AppTypeEnum.US;

  constructor(private http: HttpClient, private store: Store) {}

  checkShouldShowPhoneNumberDialog(): boolean {
    const hasPhoneNumber = this.store.selectSnapshot(CompanyContextState.hasPhoneNumber);
    const isStepperFinished = this.store.selectSnapshot(states => states.firstSteps.finished);
    return this.isUsAppType === true && hasPhoneNumber === true && isStepperFinished === true;
  }

  savePhoneNumber(payload: PhoneNumberModel): Observable<any> {
    return this.http.post(`${environment.api2Url}company/add-phone-number/`, payload);
  }
}
