import { InjectionToken } from '@angular/core';

export interface PresenterSaveSaveActionSnackbarMsgConfigToken {
  create?: string;
  modify?: string;
}

export const PRESENTER_SAVE_SAVE_ACTION_SNACKBAR_MSG_CONFIG = new InjectionToken<PresenterSaveSaveActionSnackbarMsgConfigToken>(
  'PRESENTER_SAVE_SAVE_ACTION_SNACKBAR_MSG_CONFIG'
);
