import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { ValidationMessagesComponent } from './validation-messages/validation-messages.component';

@NgModule({
  imports: [CommonModule, MatFormFieldModule, MatTooltipModule, MatIconModule, TranslocoModule],
  declarations: [ValidationMessagesComponent],
  exports: [ValidationMessagesComponent],
})
export class ValidatingModule {}
