import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Injector, NgZone, OnInit } from '@angular/core';
import { DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION } from '@roadrecord/dynamic-form';
import { TranslocoService } from '@ngneat/transloco';
import { DOCUMENT } from '@angular/common';
import { MessageDialogService } from '@roadrecord/message-dialog';
import {
  ApplicationSettingsTab,
  ApplicationSettingsTabCtor,
  ApplicationSettingsTabLayout,
  mixinApplicationSettingsTab,
} from '../tab.mixin';
import { Observable } from 'rxjs';
import {
  ApplicationSettingsRemoteGlobalConfig,
  ApplicationSettingsService,
  updateFirstDayOfWeek,
} from '@roadrecord/user/application-settings';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';

export class CompanyTabComponentBase {
  constructor(
    readonly layout: ApplicationSettingsTabLayout[],
    readonly applicationSettingsService: ApplicationSettingsService,
    readonly cdr: ChangeDetectorRef,
    readonly translocoService: TranslocoService,
    readonly document: Document,
    readonly messageDialogService: MessageDialogService,
    readonly injector: Injector,
    readonly ngZone: NgZone,
    readonly matSnackBar: MatSnackBar
  ) {}
}

export const _CompanyTabComponentBase: ApplicationSettingsTabCtor & typeof CompanyTabComponentBase = mixinApplicationSettingsTab(
  CompanyTabComponentBase
);

@Component({
  selector: 'rr-company-tab',
  templateUrl: './company-tab.component.html',
  styleUrls: ['./company-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION, useValue: true }],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    '[class.inited]': 'inited',
  },
})
export class CompanyTabComponent extends _CompanyTabComponentBase implements ApplicationSettingsTab, OnInit {
  constructor(
    applicationSettingsService: ApplicationSettingsService,
    cdr: ChangeDetectorRef,
    translocoService: TranslocoService,
    @Inject(DOCUMENT) document: Document,
    messageDialogService: MessageDialogService,
    injector: Injector,
    ngZone: NgZone,
    matSnackBar: MatSnackBar
  ) {
    super(
      [
        {
          label: 'USER.APPLICATION_SETTINGS.TABS.GLOBAL.PDF_SETTINGS.LABEL',
          formConfig: [
            {
              type: 'flex-layout',
              templateOptions: {
                cssClass: 'w-100',
                flex: {
                  fxLayout: 'column',
                  // fxLayout__gt_sm: 'row',
                  fxLayoutGap: '1em',
                },
              },
              fieldGroup: [
                {
                  type: 'toggle',
                  key: 'pdf.print_footer',
                  templateOptions: {
                    tooltip: 'USER.APPLICATION_SETTINGS.TABS.GLOBAL.PDF_SETTINGS.FIELDS.PRINT_FOOTER.TOOLTIP',
                    indeterminate: false,
                    transloco: true,
                    label: 'USER.APPLICATION_SETTINGS.TABS.GLOBAL.PDF_SETTINGS.FIELDS.PRINT_FOOTER.LABEL',
                    flex: {
                      fxFlex: '100%',
                    },
                  },
                },
                {
                  type: 'toggle',
                  key: 'pdf.destination_time_visible',
                  templateOptions: {
                    tooltip: 'USER.APPLICATION_SETTINGS.TABS.GLOBAL.PDF_SETTINGS.FIELDS.DESTINATION_TIME_VISIBLE.TOOLTIP',
                    indeterminate: false,
                    transloco: true,
                    label: 'USER.APPLICATION_SETTINGS.TABS.GLOBAL.PDF_SETTINGS.FIELDS.DESTINATION_TIME_VISIBLE.LABEL',
                    flex: {
                      fxFlex: '100%',
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          label: 'USER.APPLICATION_SETTINGS.TABS.GLOBAL.DATE_SETTINGS.LABEL',
          formConfig: [
            {
              type: 'flex-layout',
              templateOptions: {
                cssClass: 'w-100',
                flex: {
                  fxLayout: 'column',
                  fxLayout__gt_sm: 'row',
                  fxLayoutGap: '1em',
                },
              },
              fieldGroup: [
                {
                  type: 'select',
                  key: 'date.first_day_week',
                  templateOptions: {
                    tooltip: 'USER.APPLICATION_SETTINGS.TABS.GLOBAL.DATE_SETTINGS.FIELDS.FIRST_DAY_WEEK.TOOLTIP',
                    transloco: true,
                    label: 'USER.APPLICATION_SETTINGS.TABS.GLOBAL.DATE_SETTINGS.FIELDS.FIRST_DAY_WEEK.LABEL',
                    flex: {
                      fxFlex: '100%',
                    },
                    required: true,
                    options: [
                      { value: 5, label: 'USER.APPLICATION_SETTINGS.TABS.GLOBAL.DATE_SETTINGS.FIELDS.FIRST_DAY_WEEK.VALUES.SATURDAY' },
                      { value: 6, label: 'USER.APPLICATION_SETTINGS.TABS.GLOBAL.DATE_SETTINGS.FIELDS.FIRST_DAY_WEEK.VALUES.SUNDAY' },
                      { value: 0, label: 'USER.APPLICATION_SETTINGS.TABS.GLOBAL.DATE_SETTINGS.FIELDS.FIRST_DAY_WEEK.VALUES.MONDAY' },
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
      applicationSettingsService,
      cdr,
      translocoService,
      document,
      messageDialogService,
      injector,
      ngZone,
      matSnackBar
    );
  }

  ngOnInit() {
    // translate select options
    this.layout.forEach(layout =>
      layout.formConfig.forEach(formConfig =>
        formConfig.fieldGroup
          .filter(fg => fg.type === 'select')
          .forEach(fg =>
            fg.templateOptions.options.forEach(option => ((option as any).label = this.translocoService.translate((option as any).label)))
          )
      )
    );
    super.ngOnInit();
  }

  getAllHttp(): Observable<ApplicationSettingsRemoteGlobalConfig> {
    return this.applicationSettingsService.getAllGlobal();
  }

  saveAllHttp(formValue: ApplicationSettingsRemoteGlobalConfig): Observable<void> {
    return this.applicationSettingsService
      .saveAllGlobal(formValue)
      .pipe(tap(() => updateFirstDayOfWeek(this.form.getRawValue().date.first_day_week, this.injector.get(DateAdapter))));
  }
}
