export const STATE_LIST_US: string[] = [
  'AL Alabama',
  'AK Alaska',
  'AZ Arizona',
  'AR Arkansas',
  'CA California',
  'CO Colorado',
  'CT Connecticut',
  'DC District of Columbia',
  'DE Delaware',
  'FL Florida',
  'GA Georgia',
  'HI Hawaii',
  'ID Idaho',
  'IL llinois',
  'IN Indiana',
  'IA Iowa',
  'KS Kansas',
  'KY Kentucky',
  'LA Louisiana',
  'ME Maine',
  'MD Maryland',
  'MA Massachusetts',
  'MI Michigan',
  'MN Minnesota',
  'MS Mississippi',
  'MO Missouri',
  'MT Montana',
  'NE Nebraska',
  'NV Nevada',
  'NH New Hampshire',
  'NJ New Jersey',
  'NM New Mexico',
  'NY New York',
  'NC North Carolina',
  'ND North Dakota',
  'OH Ohio',
  'OK Oklahoma',
  'OR Oregon',
  'PA Pennsylvania',
  'RI Rhode Island',
  'SC South Carolina',
  'SD South Dakota',
  'TN Tennessee',
  'TX Texas',
  'UT Utah',
  'VT Vermont',
  'VA Virginia',
  'WA Washington',
  'WV West Virginia',
  'WI Wisconsin',
  'WY Wyoming',
];

export const STATE_LIST_CA: string[] = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon',
];
