import {
  getLangFromScope,
  getPipeValue,
  getScopeFromLang,
  InlineLoader,
  isScopeObject,
  ProviderScope,
  toCamelCase,
  TranslocoScope,
  TranslocoService,
} from '@ngneat/transloco';
import { isObject } from '@roadrecord/type-guard';

function prependScope(inlineLoader, scope) {
  return Object.keys(inlineLoader).reduce((acc, lang) => {
    acc[`${scope}/${lang}`] = inlineLoader[lang];
    return acc;
  }, {});
}

export function hasInlineLoader(item: any): item is ProviderScope {
  return item && isObject(item.loader);
}

export function resolveInlineLoader(providerScope: TranslocoScope | null, scope: string): InlineLoader | null {
  return hasInlineLoader(providerScope) ? prependScope(providerScope.loader, scope) : null;
}

interface LangResolverParams {
  inline: string | undefined;
  provider: string | undefined;
  active: string | undefined;
}

export class LangResolver {
  initialized = false;

  // inline => provider => active
  resolve({ inline, provider, active }: LangResolverParams = { inline: undefined, provider: undefined, active: undefined }) {
    let lang = active;
    /**
     * When the user changes the lang we need to update
     * the view. Otherwise, the lang will remain the inline/provided lang
     */
    if (this.initialized) {
      lang = active;
      return lang;
    }

    if (provider) {
      const [_, extracted] = getPipeValue(provider, 'static');
      lang = extracted;
    }

    if (inline) {
      const [_, extracted] = getPipeValue(inline, 'static');
      lang = extracted;
    }

    this.initialized = true;
    return lang;
  }

  /**
   *
   * Resolve the lang
   *
   * @example
   *
   * resolveLangBasedOnScope('todos/en') => en
   * resolveLangBasedOnScope('en') => en
   *
   */
  resolveLangBasedOnScope(lang: string) {
    const scope = getScopeFromLang(lang);
    return scope ? getLangFromScope(lang) : lang;
  }

  /**
   *
   * Resolve the lang path for loading
   *
   * @example
   *
   * resolveLangPath('todos', 'en') => todos/en
   * resolveLangPath('en') => en
   *
   */
  resolveLangPath(lang: string, scope: string | undefined) {
    return scope ? `${scope}/${lang}` : lang;
  }
}

interface ScopeResolverParams {
  inline: string | undefined;
  provider: TranslocoScope;
}

export class ScopeResolver {
  constructor(private translocoService: TranslocoService) {}

  // inline => provider
  resolve({ inline, provider }: ScopeResolverParams = { inline: undefined, provider: undefined }): string {
    if (inline) {
      return inline;
    }

    if (provider) {
      if (isScopeObject(provider)) {
        const { scope, alias = toCamelCase(scope) } = provider as ProviderScope;
        this.translocoService._setScopeAlias(scope, alias);
        return scope;
      }

      return provider as string;
    }

    return undefined;
  }
}

export function translocoLazyLoadScope(translocoService: TranslocoService, scope: string) {
  const scopeResolver = new ScopeResolver(translocoService);
  const langResolver = new LangResolver();

  const resolvedScope = scopeResolver.resolve({ inline: undefined, provider: scope });
  const path = langResolver.resolveLangPath(translocoService.getActiveLang(), resolvedScope);
  const inlineLoader = resolveInlineLoader(scope, resolvedScope);
  return translocoService._loadDependencies(path, inlineLoader);
}
