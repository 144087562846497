import { MessageDialogOptionsInterface, MessageDialogResultInterface, MessageDialogService } from '@roadrecord/message-dialog';
import { isNil } from '@roadrecord/type-guard';
import { timer } from 'rxjs';

export type finishObserverFn = (response: MessageDialogResultInterface) => void;
export const checkModifiedFormPopupState = { disableNextCheck: false };

export function checkModifiedFormPopup(finishObserver: finishObserverFn, enableAfterSaveFirstButton = true): void {
  if (checkModifiedFormPopupState.disableNextCheck === true) {
    checkModifiedFormPopupState.disableNextCheck = false;
    return finishObserver({ result: true });
  }
  const messageDialogService = MessageDialogService.INSTANCE;
  if (
    !isNil(messageDialogService.openedDialogRef) &&
    messageDialogService.openedDialogRef.componentInstance.data.id === 'check-modified-form-popup'
  ) {
    // Ha mar nyitva van dialog, akkor bezarjuk elotte
    console.warn('Closed other opened check modified popup');
    messageDialogService.openedDialogRef.close({ result: false });
  }

  const dialogOptions: MessageDialogOptionsInterface = {
    id: 'check-modified-form-popup',
    title: 'COMMON.DIALOG.TITLE.WARNING',
    text: 'COMMON.MESSAGE_DIALOG.HAS_NOT_SAVED_DATA',
    enableCancel: true,
    confirmLabel: 'COMMON.ACTION.OK',
    cancelLabel: 'COMMON.ACTION.CANCEL',
  };
  if (enableAfterSaveFirstButton) {
    dialogOptions.afterSaveFirstButton = {
      show: true,
      label: 'COMMON.MESSAGE_DIALOG.SAVE_AND_CLOSE',
    };
  }

  // timer: #RROHU-2975, #RROHU-477
  timer(300).subscribe(() =>
    messageDialogService
      .openWarning(dialogOptions)
      .afterClosed()
      .subscribe(result => finishObserver(result))
  );
}
