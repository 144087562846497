import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PreventClickModule, SanitizePipeModule } from '@roadrecord/common/common';
import { StickyNotificationBarComponent } from './sticky-notification-bar.component';
import { TranslocoModule } from '@ngneat/transloco';
import { CountDownTimerModule } from './count-down-timer/count-down-timer.module';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule, PreventClickModule, TranslocoModule, SanitizePipeModule, CountDownTimerModule],
  declarations: [StickyNotificationBarComponent],
  exports: [StickyNotificationBarComponent],
})
export class StickyNotificationBarModule {}
