import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecureDeleteDialogComponent } from './secure-delete-dialog/secure-delete-dialog.component';
import { DialogModule } from '@roadrecord/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { ValidatingModule } from '@roadrecord/validating';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TranslocoModule,
    ValidatingModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    FlexLayoutModule,
  ],
  declarations: [SecureDeleteDialogComponent],
  exports: [SecureDeleteDialogComponent],
})
export class SecureDeleteDialogModule {}
