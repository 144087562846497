import { Pipe, PipeTransform } from '@angular/core';
import { flatten } from '@ngneat/transloco';
import { getOriginalPath, mergeTranslateObjects } from '../utils/merge-translate-objects.function';
import { isString } from '@roadrecord/type-guard';

@Pipe({
  name: 'getTranslateKey',
  pure: true,
})
export class GetTranslateKeyDirective implements PipeTransform {
  transform(key: string | undefined | null, options: { fromPath: string; overridePath: string }): string {
    if (!isString(key)) {
      return key;
    }
    const mergeStore = flatten(mergeTranslateObjects(options.fromPath, options.overridePath));
    return getOriginalPath(mergeStore, key);
  }
}
