import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { SanitizePipeModule, TextPlaceholderHtmlTagReplacerModule } from '@roadrecord/common/common';
import { FirstStepperHelpBoxComponent } from './first-stepper-help-box.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    SanitizePipeModule,
    TextPlaceholderHtmlTagReplacerModule,
    MatIconModule,
    MatButtonModule,
    TranslocoModule,
    MatTooltipModule,
  ],
  declarations: [FirstStepperHelpBoxComponent],
  exports: [FirstStepperHelpBoxComponent],
})
export class FirstStepperHelpBoxModule {}
