import { GridHeaderGroupAction } from '../types/grid-header-group.action';
import { isNotDeletable } from '@roadrecord/common/common';

export function generateDefaultGridHeaderGroupActions<E>(_this: any): GridHeaderGroupAction<E>[] {
  return [
    {
      type: 'ITEM',
      label: { translate: 'COMMON.ACTION.GROUP_DELETE' },
      icon: 'delete',
      action: selectedEntities => _this.deleteSelectedElements({}),
      disabled: () =>
        _this.isLoadingResults ||
        _this.selection.selected.length === 0 ||
        (_this.selection.selected.length === 1 && isNotDeletable(_this.selection.selected[0])) ||
        (_this.selection.selected.length > 1 &&
          _this.selection.selected.filter(selected => isNotDeletable(selected)).length === _this.selection.selected.length),
    },
  ];
}
