import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeInVeryRightFast } from '@roadrecord/animations';

@Component({
  selector: 'rr-hover-edit',
  templateUrl: './hover-edit.component.html',
  styleUrls: ['./hover-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInVeryRightFast],
})
export class HoverEditComponent {
  @Input() hover: boolean;
  @Input() disableButton = false;
  @Output() clickButton = new EventEmitter<void>();

  onClickButton() {
    this.clickButton.emit();
  }
}
