export enum PartnerTypeEnum {
  ALL = -1,
  PARTNER = 0,
  CHARGING_STATION = 1,
  HEAD_OFFICE_OR_SITE = 2,
}

// tslint:disable-next-line:no-namespace
export namespace PartnerTypeEnum {
  export function toString(mode: PartnerTypeEnum): string {
    return PartnerTypeEnum[mode];
  }
}
