import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { Observable } from 'rxjs';
import { PartnerLocationHttpListResponseModel, PartnerService } from './partner.service';

@Injectable()
export class ChargingStationService extends PartnerService {
  readonly uuid;

  constructor(http: HttpClient) {
    super(http, 'charging-station', 'name');

    this.uuid = UUID.UUID();
  }

  searchByPartnerNameOrAddress(nameOrAddress: string): Observable<PartnerLocationHttpListResponseModel> {
    return super.searchByPartnerNameOrAddress(nameOrAddress, { search_type: 'gas_station' });
  }
}
