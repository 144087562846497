import {
  BASIC_DATA_PAGE_PATH,
  FINALIZATION_OF_ROUTE_ROUTE_PATH,
  FUELING_ROUTE_PATH,
  MILEAGE_ROUTE_PATH,
  MONTH_ACTIVITIES_PAGE_PATH,
  PARTNER_ANONYM_RENAME_FULL_ROUTE_PATH,
  PARTNER_ROUTE_PATH,
  RECOMMENDATION_SETTINGS_ROUTE_PATH,
  VEHICLE_ROUTE_PATH,
} from '@roadrecord/common/common';
import {
  DocumentDataCheckerActionConfig,
  DocumentDataCheckerActionsEnum,
  DocumentDataCheckerActionTypeEnum,
} from '@roadrecord/document-data-checker-action/model';

export enum DOCUMENT_DATA_CHECKER_ACTION_ROUTE_CONFIG_IDS_ENUM {
  // csak azt kell felvenni akit mashol is vizsgalni kell
  ANONYM_PARTNERS = 11,
}

export const DOCUMENT_DATA_CHECKER_ACTION_CONFIG: DocumentDataCheckerActionConfig = {
  actionTypes: [
    {
      id: 0,
      label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.MILEAGE_START.ACTIONS.MILEAGE_START_ADD_OR_MODIFY',
      action: DocumentDataCheckerActionsEnum.MILEAGE_START__ADD_OR_MODIFY,
    },
    {
      id: 2,
      label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.MILEAGE_END.ACTIONS.MILEAGE_END_ADD_OR_MODIFY',
      action: DocumentDataCheckerActionsEnum.MILEAGE_END__ADD_OR_MODIFY,
    },
    {
      id: 3,
      label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.FINALIZATION_OF_ROUTER.ACTIONS.RECOMMENDATION',
      action: DocumentDataCheckerActionsEnum.FINALIZATION_OF_ROUTE__RECOMMENDATION,
    },
    {
      id: 4,
      label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.FINALIZATION_OF_ROUTER.ACTIONS.FUELING_RANGE_WINDOW',
      action: DocumentDataCheckerActionsEnum.FINALIZATION_OF_ROUTE__FUELING_RANGE_WINDOW,
    },
    {
      id: 5,
      label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.FINALIZATION_OF_ROUTER.ACTIONS.MILEAGE_LIST_WINDOW',
      action: DocumentDataCheckerActionsEnum.FINALIZATION_OF_ROUTE__MILEAGE_LIST_WINDOW,
    },
    {
      id: 6,
      label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.FINALIZATION_OF_ROUTER.ACTIONS.MONTHLY_DISTANCE_CORRECTION',
      action: DocumentDataCheckerActionsEnum.FINALIZATION_OF_ROUTE__MONTHLY_DISTANCE_CORRECTION,
    },
    {
      id: 7,
      label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.FINALIZATION_OF_ROUTER.ACTIONS.JUST_FIX_ROAD',
      action: DocumentDataCheckerActionsEnum.FINALIZATION_OF_ROUTE__JUST_FIX_ROAD,
    },
    // floating window actions, ezt itt most meg duplikacio :(
    {
      id: 8,
      label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.FLOATING_WINDOWS.ACTIONS.FUELING_RANGE',
      action: DocumentDataCheckerActionsEnum.FLOATING_WINDOW__FUELING_RANGE,
    },
    {
      id: 9,
      label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.FLOATING_WINDOWS.ACTIONS.MILEAGE_LIST',
      action: DocumentDataCheckerActionsEnum.FLOATING_WINDOW__MILEAGE_LIST,
    },
  ],
  actions: [
    {
      id: 0,
      label: 'DOCUMENT_DATA_CHECKER_ACTIONS.LABELS.ROUTE',
      actions: [
        {
          id: 6,
          type: DocumentDataCheckerActionTypeEnum.ROUTE,
          label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.PARTNER.ROUTE_LABEL',
          route: `/${BASIC_DATA_PAGE_PATH}/${PARTNER_ROUTE_PATH}`,
          actions: [8, 9],
        },
        {
          id: 10,
          type: DocumentDataCheckerActionTypeEnum.ROUTE,
          label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.VEHICLE.ROUTE_LABEL',
          route: `/${BASIC_DATA_PAGE_PATH}/${VEHICLE_ROUTE_PATH}`,
          actions: [8, 9],
        },
        {
          id: 8,
          type: DocumentDataCheckerActionTypeEnum.ROUTE,
          label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.VEHICLE_PARTNER_JOIN.ROUTE_LABEL',
          route: `/${BASIC_DATA_PAGE_PATH}/vehicle-partner-join`,
          actions: [8, 9],
        },
        {
          id: 9,
          type: DocumentDataCheckerActionTypeEnum.ROUTE,
          label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.PARTNER_VEHICLE_JOIN.ROUTE_LABEL',
          route: `/${BASIC_DATA_PAGE_PATH}/partner-vehicle-join`,
          actions: [8, 9],
        },
        {
          id: 0,
          type: DocumentDataCheckerActionTypeEnum.ROUTE,
          label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.MILEAGE_START.ROUTE_LABEL',
          route: `/${MONTH_ACTIVITIES_PAGE_PATH}/${MILEAGE_ROUTE_PATH}/start/status`,
          actions: [0, 8, 9],
        },
        {
          id: 1,
          type: DocumentDataCheckerActionTypeEnum.ROUTE,
          label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.MILEAGE_LIST.ROUTE_LABEL',
          route: `/${MONTH_ACTIVITIES_PAGE_PATH}/${MILEAGE_ROUTE_PATH}/start`,
          actions: [8, 9],
        },
        {
          id: 2,
          type: DocumentDataCheckerActionTypeEnum.ROUTE,
          label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.FUELING.ROUTE_LABEL',
          route: `/${MONTH_ACTIVITIES_PAGE_PATH}/${FUELING_ROUTE_PATH}`,
          actions: [8, 9],
        },
        {
          id: 4,
          type: DocumentDataCheckerActionTypeEnum.ROUTE,
          label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.MILEAGE_END.ROUTE_LABEL',
          route: `/${MONTH_ACTIVITIES_PAGE_PATH}/${MILEAGE_ROUTE_PATH}/end/status`,
          actions: [2, 8, 9],
        },
        {
          id: 7,
          type: DocumentDataCheckerActionTypeEnum.ROUTE,
          label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.RECOMMENDATION_SETTINGS.ROUTE_LABEL',
          route: `/${MONTH_ACTIVITIES_PAGE_PATH}/${RECOMMENDATION_SETTINGS_ROUTE_PATH}`,
          actions: [8, 9],
        },
        {
          id: 5,
          type: DocumentDataCheckerActionTypeEnum.ROUTE,
          label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.FINALIZATION_OF_ROUTER.ROUTE_LABEL',
          route: `/${MONTH_ACTIVITIES_PAGE_PATH}/${FINALIZATION_OF_ROUTE_ROUTE_PATH}`,
          actions: [3, 4, 5, 6, 7],
        },
        {
          id: DOCUMENT_DATA_CHECKER_ACTION_ROUTE_CONFIG_IDS_ENUM.ANONYM_PARTNERS,
          type: DocumentDataCheckerActionTypeEnum.ROUTE,
          label: 'DOCUMENT_DATA_CHECKER_ACTIONS.ACTIONS.ANONYM_PARTNERS.ROUTE_LABEL',
          route: `${PARTNER_ANONYM_RENAME_FULL_ROUTE_PATH}`,
          actions: [],
        },
      ],
    },
    {
      id: 1,
      label: 'DOCUMENT_DATA_CHECKER_ACTIONS.LABELS.FLOATING_WINDOW',
      actions: [
        { id: 6, type: DocumentDataCheckerActionTypeEnum.DIRECT_ACTION, actions: [8] },
        { id: 7, type: DocumentDataCheckerActionTypeEnum.DIRECT_ACTION, actions: [9] },
      ],
    },
  ],
};
