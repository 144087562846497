<div>
  <mat-icon class="icon" [svgIcon]="icon" [attr.aria-label]="iconLabel"></mat-icon>
  <span class="message" [innerHTML]="text | transloco: textTranslateParams | sanitizeHtml"></span>
  <a *ngIf="closeActionText?.length > 0" href="#" rrPreventClick (click)="dismiss(false, $event)" class="action-button"
    >{{ closeActionText | transloco }}
  </a>

  <rr-count-down-timer *ngIf="showCountDownTimer" [startDate]="textTranslateParams.date"> </rr-count-down-timer>
</div>

<button type="button" mat-icon-button class="close-button" aria-label="Close" (click)="dismiss(true, $event)" *ngIf="hasCloseButton">
  <mat-icon aria-label="Dismiss notification" svgIcon="close"></mat-icon>
</button>
