import { Injectable } from '@angular/core';
import { AbstractEntityService, HttpListResponseModel } from '@roadrecord/utils';
import { HttpClient } from '@angular/common/http';
import { isNil } from '@roadrecord/type-guard';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Observable, of } from 'rxjs';

@Injectable()
export class StateService extends AbstractEntityService<HttpListResponseModel<string>, string> {
  private _states: string[] = [];

  get states() {
    return this._states;
  }

  set states(states: string[]) {
    this._states = states;
  }

  constructor(http: HttpClient) {
    super('state', '', http);
  }

  getToString(model: string): string {
    if (isNil(model)) {
      return null;
    }
    return model;
  }

  getAll(
    sort: Sort = { active: '', direction: 'asc' },
    page: PageEvent = {
      pageIndex: 1,
      pageSize: 99999999,
      length: 1,
    },
    simpleAllFilterValue: string = ''
  ): Observable<HttpListResponseModel<string>> {
    const filteredList = this._states.filter(state => state.toLowerCase().includes(simpleAllFilterValue.toLowerCase()));
    return of({ results: filteredList, count: filteredList.length, ordering_fields: [] } as HttpListResponseModel<string>);
  }

  getModelIdValue(model: string): any {
    return model;
  }
}
