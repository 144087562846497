import { PartnerListHuModel } from '../model/partner-list-hu.model';
import { isNil, isNotEmptyString, isNotNilAndString } from '@roadrecord/type-guard';

export function addressFormatter(model: PartnerListHuModel, hasPostCode = true) {
  const { address } = model;
  if (isNil(address)) {
    return '';
  }
  const strBuilder: string[] = [];

  if (hasPostCode) {
    strBuilder.push(`${address.postcode}`);
  }

  strBuilder.push(address.city);

  if (isNotNilAndString(address.street)) {
    if (isNotEmptyString(address.street)) {
      strBuilder[strBuilder.length - 1] = `${strBuilder[strBuilder.length - 1]},`;
      strBuilder.push(address.street);
    }
    strBuilder.push(address.house_number);
  }

  return strBuilder.filter(Boolean).join(' ');
}
