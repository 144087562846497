<div fxLayout="row" fxLayoutAlign="center center" fxFlex.gt-sm>
  <div fxLayout="column" fxLayoutAlign="center center" class="wid100">
    <mat-card>
      <mat-progress-bar color="warn" mode="indeterminate" *ngIf="form.disabled"></mat-progress-bar>
      <mat-card-header>
        <mat-card-title>{{ 'USER.PASSWORD.CHANGE_PASSWORD.TITLE' | transloco }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" role="form" novalidate class="wid100" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap=".5em">
          <!-- Old Password -->
          <mat-form-field class="wid100">
            <mat-label>{{ 'USER.PASSWORD.CHANGE_PASSWORD.OLD_PASSWORD' | transloco }}</mat-label>
            <input
              type="{{ visibilityTypePassword }}"
              formControlName="oldPassword"
              name="oldPassword"
              matInput
              required
              disallow-spaces
              maxlength="20"
              [placeholder]="'USER.PASSWORD.CHANGE_PASSWORD.OLD_PASSWORD' | transloco"
            />
            <button tabindex="-1" type="button" mat-icon-button matSuffix (click)="changeVisibilityPassword()">
              <mat-icon
                data-cy="login-card--form-control-password-visible-icon"
                [matTooltip]="(!visiblePassword ? 'COMMON.TOOLTIP.PASSWORD_SHOW' : 'COMMON.TOOLTIP.PASSWORD_HIDE') | transloco"
                matTooltipPosition="left"
                class="visible-icon"
                [svgIcon]="!visiblePassword ? 'eye' : 'eye-off'"
              ></mat-icon>
            </button>
            <mat-error>
              <rr-validation-messages [messages]="errorMessages" [errors]="oldPasswordControl?.errors"></rr-validation-messages>
            </mat-error>
          </mat-form-field>

          <!-- New Password -->
          <mat-form-field class="wid100">
            <mat-label>{{ 'USER.PASSWORD.CHANGE_PASSWORD.NEW_PASSWORD' | transloco }}</mat-label>
            <input
              type="{{ visibilityTypePassword }}"
              formControlName="newPassword"
              name="newPassword"
              matInput
              required
              [placeholder]="'USER.PASSWORD.CHANGE_PASSWORD.NEW_PASSWORD' | transloco"
            />
            <mat-error>
              <rr-validation-messages [messages]="errorMessages" [errors]="newPasswordControl?.errors"></rr-validation-messages>
            </mat-error>
            <button tabindex="-1" type="button" mat-icon-button matSuffix (click)="changeVisibilityPassword()">
              <mat-icon
                data-cy="login-card--form-control-password-visible-icon"
                [matTooltip]="(!visiblePassword ? 'COMMON.TOOLTIP.PASSWORD_SHOW' : 'COMMON.TOOLTIP.PASSWORD_HIDE') | transloco"
                matTooltipPosition="left"
                class="visible-icon"
                [svgIcon]="!visiblePassword ? 'eye' : 'eye-off'"
              ></mat-icon>
            </button>
            <mat-hint class="password-hint" *ngIf="newPasswordControl.invalid || newPasswordControl?.value?.length === 0">{{
              'COMMON.VALIDATION.PASSWORD_PATTERN' | transloco
            }}</mat-hint>
          </mat-form-field>

          <!-- Confirm New Password -->
          <mat-form-field class="wid100">
            <mat-label>{{ 'USER.PASSWORD.CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD' | transloco }}</mat-label>
            <input
              type="{{ visibilityTypePassword }}"
              formControlName="confirmNewPassword"
              name="confirmNewPassword"
              matInput
              required
              [placeholder]="'USER.PASSWORD.CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD' | transloco"
            />
            <mat-error>
              <rr-validation-messages [messages]="errorMessages" [errors]="confirmNewPasswordControl?.errors"></rr-validation-messages>
            </mat-error>
            <button tabindex="-1" type="button" mat-icon-button matSuffix (click)="changeVisibilityPassword()">
              <mat-icon
                data-cy="login-card--form-control-password-visible-icon"
                [matTooltip]="(!visiblePassword ? 'COMMON.TOOLTIP.PASSWORD_SHOW' : 'COMMON.TOOLTIP.PASSWORD_HIDE') | transloco"
                matTooltipPosition="left"
                class="visible-icon"
                [svgIcon]="!visiblePassword ? 'eye' : 'eye-off'"
              ></mat-icon>
            </button>
          </mat-form-field>
        </form>
      </mat-card-content>
      <mat-card-actions>
        <span class="flex-spacer"></span>
        <rr-details-button-row
          [isNew]="true"
          [disabled]="(form['submitted'] && form.invalid) || form.disabled"
          [form]="form"
          [hasSubmitAndNew]="false"
          (submitForm)="save()"
        ></rr-details-button-row>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
