import { Injector } from '@angular/core';
import { isNil } from '@roadrecord/type-guard';

export class RootInjectorStore {
  protected static rootInjector: Injector;

  private constructor() {}

  static getFromRootInjector(type: any) {
    return RootInjectorStore.rootInjector.get(type);
  }

  static setRootInjector(injector: Injector) {
    if (!isNil(RootInjectorStore.rootInjector)) {
      throw new Error('Double init RootInjectorStore');
    }
    RootInjectorStore.rootInjector = injector;
  }
}
