import { isNil } from '@roadrecord/type-guard';
import { asapScheduler } from 'rxjs';
import { produce } from '@ngxs-labs/immer-adapter';
import { StateContext } from '@ngxs/store';
import { AbstractPeriodContextStateModel } from '../model/abstract-period-context-state.model';
import { PeriodContextRefreshRemoteStateSuccessAction } from '../action/period-context-refresh-remote-state-success.action';

export function periodContextRefreshSuccessActionFn<STATEMODEL extends AbstractPeriodContextStateModel<any>>(
  ctx: StateContext<STATEMODEL>,
  action: PeriodContextRefreshRemoteStateSuccessAction
) {
  const dispatch = ctx.dispatch;

  if (isNil(action.state)) {
    return ctx.setState({ notInited: true } as any);
  }
  asapScheduler.schedule(() => dispatch({ type: '[Preferences] Close content window', windowType: 'ALL' }));
  produce<STATEMODEL>(ctx, draft => {
    delete draft.notInited;
    draft.model = {} as any;
    Object.entries(action.state).forEach(entry => (draft.model[entry[0]] = entry[1]));
    draft.loading = false;
  });
}
