import { Component, EventEmitter, forwardRef, Host, Input, OnInit, Output, ViewChild } from '@angular/core';
import { simpleFadeInAnimation, simpleFadeOutAnimation } from '@roadrecord/common/common';
import {
  CheckNewOrLoadPlugin,
  ENTITY_SERVICE_TOKEN,
  HasPresenterDeletePlugin,
  HasPresenterSavePluginController,
  NotificationStrategy,
  PRESENTER_COMPONENT,
  PRESENTER_PLUGINS,
  PRESENTER_SAVE_PLUGIN_OPTIONS_TOKEN,
  PRESENTER_STATE_CONTROLLER_PROVIDER,
  PRESENTER_STATE_DEFAULT_PROVIDERS,
  PresenterSavePluginOptionsModel,
  PresenterSaveSaveDataCallbackStrategy,
  PresenterStateController,
  SaveModel,
  VIEW_COMPONENT,
  VIEW_MODEL_PLUGIN_RESET_STATE,
} from '@roadrecord/utils';
import { Observable, ReplaySubject } from 'rxjs';
import { tripReasonDetailsRoutingWhenSaveAndNotResetStrategy } from '../trip-reason-details-routing-when-save-and-not-reset.strategy';
import { TripReasonService } from '../trip-reason.service';
import { DataFormComponent } from './data-form/data-form.component';
import { TripReasonModel } from '@roadrecord/trip-reason/model';
import { ActivatedRoute, Router } from '@angular/router';
import { detailsOnSubmitFunction, detailsHandleFormFunction } from './details-functions';

export const presenterSavePluginOptions = new PresenterSavePluginOptionsModel({
  responseErrorCallback: () => 'handleFormError',
  // tslint:disable-next-line:object-literal-shorthand
  responseOkCallback: function (): string {
    // tslint:disable-next-line:no-invalid-this
    if (this.routingMode === false) {
      return 'componentModeSave';
    }
  },
  // tslint:disable-next-line
  routingWhenSaveAndNotReset: function (): boolean {
    if (tripReasonDetailsRoutingWhenSaveAndNotResetStrategy.strategy !== undefined) {
      return tripReasonDetailsRoutingWhenSaveAndNotResetStrategy.strategy;
    }
    return true;
  },
  presenterSaveSaveDataCallbackStrategy: PresenterSaveSaveDataCallbackStrategy.BEFORE_RUN_PLUGIN,
});

export const tripReasonResetModel: TripReasonModel = {
  is_private: false,
  is_default: false,
  trip_reason: undefined,
} as TripReasonModel;

@Component({
  selector: 'rr-trip-reason-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  providers: [
    TripReasonService,
    { provide: ENTITY_SERVICE_TOKEN, useExisting: TripReasonService },
    { provide: PRESENTER_COMPONENT, useValue: forwardRef(() => DetailsComponent) },
    { provide: VIEW_COMPONENT, useValue: forwardRef(() => DataFormComponent) },
    {
      provide: PRESENTER_SAVE_PLUGIN_OPTIONS_TOKEN,
      useValue: presenterSavePluginOptions,
    },
    {
      provide: VIEW_MODEL_PLUGIN_RESET_STATE,
      useValue: tripReasonResetModel,
    },
    { provide: PRESENTER_PLUGINS, useValue: PRESENTER_STATE_DEFAULT_PROVIDERS },
    PRESENTER_STATE_CONTROLLER_PROVIDER,
  ],
  animations: [simpleFadeInAnimation, simpleFadeOutAnimation],
})
export class DetailsComponent
  implements OnInit, HasPresenterSavePluginController<TripReasonModel, DataFormComponent>, HasPresenterDeletePlugin {
  readonly isNew$: Observable<boolean>;
  readonly editModel$: ReplaySubject<TripReasonModel>;
  readonly loading$: ReplaySubject<boolean>;
  hasDelete = true;
  @ViewChild('dataForm', { static: true })
  dataForm: DataFormComponent;
  private checkNewOrLoadController: CheckNewOrLoadPlugin<TripReasonModel, number>;
  @Input()
  readonly routingMode = true;
  @Input()
  readonly controlBackButton = false;
  @Input()
  hasSubmitAndNew = true;
  @Input()
  windowEditModelId: number;
  @Output()
  readonly cancel = new EventEmitter<void>();
  @Output()
  save = new EventEmitter<TripReasonModel>();

  constructor(
    @Host() readonly presenterStateController: PresenterStateController<TripReasonModel, number>,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.editModel$ = presenterStateController.editModel$;
    this.loading$ = presenterStateController.loading$;
    this.isNew$ = presenterStateController.isNew$;

    this.checkNewOrLoadController = this.presenterStateController.get(CheckNewOrLoadPlugin);
  }

  async ngOnInit(): Promise<any> {
    await this.checkNewOrLoadController.checkIsNew(
      undefined,
      undefined,
      undefined,
      NotificationStrategy.FIRST_DATA_MODEL,
      false,
      this.windowEditModelId
    );
  }

  onSubmit(model: SaveModel<TripReasonModel>): void {
    detailsOnSubmitFunction(model);
  }

  onDelete(): void {}

  onBack($event: Event): boolean {
    $event.preventDefault();
    $event.stopPropagation();

    if (this.controlBackButton === false) {
      this.router.navigate(['../'], { relativeTo: this.route, replaceUrl: true });
      // this.location.back();
    } else {
      this.cancel.emit();
    }
    return false;
  }

  handleFormError(): void {
    detailsHandleFormFunction(this);
  }

  private componentModeSave(newTripReason: TripReasonModel): void {
    this.save.emit(newTripReason);
  }
}
