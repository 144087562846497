<ng-container *ngIf="isAmericanVersion">
  <ng-container *transloco="let t; read: translateKey">
    <h1>{{ t('TITLE') }}</h1>
    <div class="d-flex" [ngStyle]="{ 'max-height.px': (cardsWrapperWidth / 540) * 290 }">
      <rr-button-card
        tagManagerId="stepper-go-to-timeline"
        imageUrl="assets/images/stepper/finish-welcome/partnerek.svg"
        [buttonLabel]="t('FOOTER.BUTTON1.LABEL')"
        buttonLink="FOOTER.BUTTON1.LINK"
        (clickButton)="onStartApp($event.link, $event.label)"
        (changeWidth)="onChangeWidth($event)"
      ></rr-button-card>
      <rr-button-card
        tagManagerId="stepper-go-to-add-new-trip"
        imageUrl="assets/images/stepper/finish-welcome/tavolsagok.svg"
        [buttonLabel]="t('FOOTER.BUTTON2.LABEL')"
        buttonLink="FOOTER.BUTTON2.LINK"
        (clickButton)="onStartApp($event.link, $event.label)"
      ></rr-button-card>
    </div>
  </ng-container>
</ng-container>
