import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractEntityService } from '@roadrecord/utils';
import { Observable } from 'rxjs';
import { RecommendationSettingsModel } from './settings/model/recommendation-settings.model';
import { environment } from '@roadrecord/environment';

@Injectable()
export class RecommendationService extends AbstractEntityService<any, any> {
  constructor(http: HttpClient) {
    super('recommendation', 'name', http);
  }

  getSettings(): Observable<RecommendationSettingsModel> {
    return this.http.get<RecommendationSettingsModel>(`${environment.apiUrl}${this._entityUrl}/settings/`);
  }

  saveSettings(model: RecommendationSettingsModel): Observable<any> {
    return this.http.put<RecommendationSettingsModel>(`${environment.apiUrl}${this._entityUrl}/settings/`, model);
  }

  getModelIdValue(model: any): any {
    return '';
  }

  getToString(model: any): string {
    return undefined;
  }

  deleteGeneratedRoutes(): Observable<any> {
    return this.http.delete(`${environment.api2Url}finalization-of-route/delete/except-fixed-routes/`);
  }
}
