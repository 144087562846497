import { SaveModel } from '@roadrecord/utils';
import { TripReasonModel } from '@roadrecord/trip-reason/model';
import { DetailsComponent } from './details.component';

export function detailsOnSubmitFunction(model: SaveModel<TripReasonModel>) {
  if (model.model.is_private === true) {
    model.model.is_default = false;
  }
}

export function detailsHandleFormFunction(_this: DetailsComponent) {
  _this.dataForm.setEditModelUIRestriction();
}
