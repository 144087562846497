<ng-template #formTpl>
  <form
    [formGroup]="form"
    fxLayout="column"
    fxLayoutAlign="center stretch"
    novalidate
    (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); onSubmitCheck()"
  >
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
      <rr-day-select-form-control
        fxFlex.gt-sm="50"
        class="wid100"
        name="date"
        [fullDateMode]="true"
        [formGroup]="form"
        [autoCalculateMinMaxDate]="true"
        label="FUELING.DETAILS.DATA_FORM.LABEL.DATE"
        [required]="true"
      ></rr-day-select-form-control>
      <mat-form-field #timeFieldElementRef class="w-100 timepicker">
        <mat-label>{{ 'FUELING.DETAILS.DATA_FORM.LABEL.TIME' | transloco }}</mat-label>
        <input
          matInput
          [ngxMatDatetimePicker]="timePicker"
          [formControl]="timeControl"
          timePickerReadonlyMode="true"
          autoCastStringToMoment="true"
          [autoOpen]="isNew && autoOpen"
          name="time"
          readonly
        />
        <mat-datepicker-toggle matPrefix [for]="timePicker"
          ><mat-icon svgIcon="clock-outline" matDatepickerToggleIcon></mat-icon
        ></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #timePicker
          [htmlElementRef]="timeFieldElementRef"
          hideCalendar="true"
          okTooltip="FUELING.DETAILS.DATA_FORM.TIME_PICKER_POPUP.OK.TIME"
          autoCastMomentToString="true"
          [enableMeridian]="null | appTypeIsUs"
        ></ngx-mat-datetime-picker>
        <mat-error *ngIf="timeControl.invalid">
          <rr-validation-messages [errors]="timeControl.errors"></rr-validation-messages>
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
      <div class="loading-spinner-overlay-wrapper wid100">
        <div class="loading-mask" @fadeInEnter *ngIf="loadingNewPartner === true">
          <div class="spinner-wrapper">
            <mat-progress-spinner strokeWidth="10" mode="indeterminate" color="accent"></mat-progress-spinner>
          </div>
        </div>
        <rr-live-auto-complete
          #refuelingLocationLiveAutoComplete
          [optionsConfig]="laChargingStationOptionsConfig"
          [formControlRef]="refuelingLocationControl"
          [database]="chargingStationService"
          hasNewButton="true"
          [validationMessages]="laChargingStationValidatorMessages"
          placeholder="FUELING.DETAILS.DATA_FORM.LABEL.REFUELING_LOCATION"
          name="refueling_location"
          required
          (hasOneCheckResponse)="onHasOneCheckResponse($event)"
        ></rr-live-auto-complete>
      </div>
      <mat-form-field class="wid100" *ngIf="reduceDataset === false" @fadeInEnter @fadeOut2XFastLeave>
        <mat-label>{{ 'FUELING.DETAILS.DATA_FORM.LABEL.COST' | transloco }}</mat-label>
        <input
          rrFocusInput
          rrFocusDelay="300"
          #focusCost="rrFocusInput"
          type="text"
          required
          class="input-element"
          [imask]="{ mask: fuelingSettings.mask.cost }"
          unmask="typed"
          formControlName="cost"
          matInput
          name="cost"
          [placeholder]="'FUELING.DETAILS.DATA_FORM.LABEL.COST' | transloco"
        />
        <span matPrefix *ngIf="null | appTypeIsUs">{{ 'FUELING.DETAILS.DATA_FORM.LABEL.COST_SUFFIX' | transloco }}&nbsp;</span>
        <span matSuffix *ngIf="null | appTypeIsHu">&nbsp;{{ 'FUELING.DETAILS.DATA_FORM.LABEL.COST_SUFFIX' | transloco }}</span>
        <mat-error><rr-validation-messages [errors]="costControl?.errors"></rr-validation-messages></mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="1em" *ngIf="reduceDataset === false" @fadeInEnter @fadeOut2XFastLeave>
      <div class="w-100" fxLayout="column">
        <mat-form-field class="wid100">
          <mat-label>{{ 'FUELING.DETAILS.DATA_FORM.LABEL.QUANTITY' | transloco }}</mat-label>
          <input
            type="text"
            [imask]="{ mask: fuelingSettings.mask.quantity }"
            unmask="typed"
            formControlName="quantity"
            name="quantity"
            required
            matInput
            [placeholder]="'FUELING.DETAILS.DATA_FORM.LABEL.QUANTITY' | transloco"
          />
          <span matSuffix>{{ (isElectricVehicleType ? 'COMMON.UNIT.KWH' : 'COMMON.UNIT.LITER') | transloco }}</span>
          <mat-error><rr-validation-messages [errors]="quantityControl?.errors"></rr-validation-messages></mat-error>
        </mat-form-field>
        <mat-form-field class="wid100" *ngIf="!firstFueling">
          <mat-label>{{ 'FUELING.DETAILS.DATA_FORM.LABEL.DISTANCE' | transloco }}</mat-label>
          <input
            type="text"
            [imask]="{ mask: fuelingSettings.mask.distance }"
            unmask="typed"
            formControlName="distance"
            name="distance"
            matInput
            [placeholder]="'FUELING.DETAILS.DATA_FORM.LABEL.DISTANCE' | transloco"
          />
          <span matSuffix>{{ 'COMMON.UNIT.KM' | transloco }}</span>
          <mat-hint>{{ 'FUELING.DETAILS.DATA_FORM.HINT.DISTANCE' | transloco }}</mat-hint>
          <mat-error><rr-validation-messages [errors]="distanceControl?.errors"></rr-validation-messages></mat-error>
        </mat-form-field>
      </div>
      <div class="w-100" fxLayout="column">
        <div class="distance-container" [ngClass]="{ loading: timeControl.disabled }" *ngIf="!firstFueling">
          <div *ngIf="canCalculation === false">{{ 'FUELING.DETAILS.DATA_FORM.CAN_NOT_CALCULATION' | transloco }}</div>
          <div *ngIf="estimatedDistance">
            <div
              class="distance-label"
              [innerHTML]="
                'FUELING.DETAILS.DATA_FORM.LABEL.ESTIMATED_DISTANCE'
                  | transloco
                    : {
                        estimatedDistance: estimatedDistance | fixedNumber,
                        estimatedDistanceUnit: 'COMMON.UNIT.KM' | transloco,
                        recommendedMileage: recommendedMileage | fixedNumber,
                        recommendedMileageUnit: 'COMMON.UNIT.KM' | transloco
                      }
              "
            ></div>
            <div
              class="distance-and-estimated-distance-difference-warning"
              *ngIf="isHybridVehicleType; else maybeDistanceDifferenceWarning"
            >
              {{ 'FUELING.DETAILS.DATA_FORM.DISTANCE_DIFFERENCE.HYBRID' | transloco }}
            </div>
            <ng-template #maybeDistanceDifferenceWarning>
              <div class="distance-and-estimated-distance-difference-warning" *ngIf="showDistanceDifferenceWarning" @fadeInEnter>
                {{ 'FUELING.DETAILS.DATA_FORM.DISTANCE_DIFFERENCE.NOT_HYBRID' | transloco }}
              </div>
            </ng-template>
          </div>
        </div>
        <mat-form-field
          class="wid100"
          *ngIf="reduceDataset === false"
          @fadeInEnter
          @fadeOut2XFastLeave
          [matTooltipDisabled]="disableMileageTooltip"
          [matTooltip]="disableMileageTooltip === false ? (mileageTooltip.text | transloco: mileageTooltip.params) : undefined"
          matTooltipClass="mat-tooltip-white-space-break-spaces"
        >
          <mat-label>{{ 'FUELING.DETAILS.DATA_FORM.LABEL.MILEAGE' | transloco }}</mat-label>
          <input
            type="text"
            [required]="!isUsAppType"
            class="input-element"
            [imask]="{ mask: fuelingSettings.mask.mileage }"
            unmask="typed"
            [formControl]="mileageControl"
            matInput
            name="mileage"
            [placeholder]="'FUELING.DETAILS.DATA_FORM.LABEL.MILEAGE' | transloco"
          />
          <mat-hint *ngIf="!(previous_mileage?.value | isNil)">{{
            'FUELING.DETAILS.DATA_FORM.HINT.PREV_MILEAGE' | transloco: { prevMileage: formatPreviousMileageValue() }
          }}</mat-hint>
          <span matSuffix>{{ 'COMMON.UNIT.KM' | transloco }}</span>
          <mat-error>
            <rr-validation-messages [errors]="mileageControl?.errors" [messages]="mileageValidatorMessages"></rr-validation-messages>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="extra-row" *ngIf="isNew && reduceDataset === false" @fadeInEnter @fadeOut2XFastLeave>
      <mat-slide-toggle [formControl]="mileageCalculationControl"
        ><span [innerHTML]="'FUELING.DETAILS.DATA_FORM.MILEAGE_CALCULATION_LABEL' | transloco | sanitizeHtml"></span
      ></mat-slide-toggle>
    </div>
    <div class="extra-row" [ngClass]="{ 'showed-other-rows': isNew && reduceDataset === false }" *ngIf="smrMode">
      <mat-slide-toggle class="mr-3" [formControl]="reducedDatasetControl">{{
        'FUELING.DETAILS.DATA_FORM.REDUCED_MODE' | transloco
      }}</mat-slide-toggle></div
    >
  </form>
</ng-template>

<ng-template #buttonsTpl>
  <rr-details-button-row
    [isNew]="(editModel?.id | isPresent) === false"
    [disabled]="(form['submitted'] && form.invalid) || form.disabled"
    [form]="form"
    [hasDelete]="(editModel?.id | isPresent) === true"
    [disableDelete]="false"
    [hasSubmitAndNew]="false"
    (submitForm)="onSubmitCheck()"
    (delete)="uiDelete()"
  ></rr-details-button-row>
</ng-template>
