import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ReportPrintState } from '../state/report-print.state';
import { ReportPrintCancelAction } from '../state/action/report-print-cancel.action';

@Injectable()
export class LeaveCancelGuard implements CanDeactivate<any> {
  constructor(private store: Store) {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const run = this.store.selectSnapshot<boolean>(ReportPrintState.run);
    if (run) {
      this.store.dispatch(new ReportPrintCancelAction());
    }

    return true;
  }
}
