import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsDisabledPipe } from './is-disabled.pipe';

@NgModule({
  declarations: [IsDisabledPipe],
  imports: [CommonModule],
  exports: [IsDisabledPipe],
})
export class IsDisabledPipeModule {}
