import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { UniversalImportStatusModel } from '../../model/universal-import-status.model';
import { UNIVERSAL_IMPORT_CONFIG, UniversalImportConfig } from '../../model/universal-import-config';

@Component({
  selector: 'rr-status-panel',
  templateUrl: './status-panel.component.html',
  styleUrls: ['./status-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusPanelComponent {
  @Input() status: UniversalImportStatusModel;

  constructor(@Inject(UNIVERSAL_IMPORT_CONFIG) readonly importConfig: UniversalImportConfig<any>) {}
}
