<mat-progress-bar mode="indeterminate" *ngIf="form.disabled && form.submitted"></mat-progress-bar>
<form [formGroup]="form">
  <rr-formly-form class="w-100" [form]="form" [fields]="formlyFields" [model]="formlyData"></rr-formly-form>
</form>
<div class="footer d-flex mt-2"
  ><button type="button" (click)="bottomSheetRef.dismiss()" rrPreventClick mat-icon-button [matTooltip]="'COMMON.BACK' | transloco">
    <mat-icon svgIcon="arrow-left"></mat-icon> </button
  ><span class="flex-spacer"></span
  ><rr-submit-button-with-error
    [disabled]="form.disabled || (form.submitted && form.invalid)"
    [formSubmitted]="form.submitted"
    [formInvalid]="form.invalid"
    buttonLabel="COMMON.ACTION.SAVE"
    invalidTooltip="COMMON.VALIDATION.BUTTON"
    (clickButton)="onClickSubmit()"
  ></rr-submit-button-with-error
></div>
