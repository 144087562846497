import { CookieService } from 'ngx-cookie-service';
import { _getRegisterReferer } from './get-register-referer.function';

export function getRegisterReferer(cookieService: CookieService, document: Document) {
  return _getRegisterReferer(cookieService, document, 'rr');
}

export function _getRegisterOptimonkReferer(cookieService: CookieService, document: Document, prefix: string) {
  const optimonkRefererCookieValue = cookieService.get(`${prefix}optimonk_referer`);
  return optimonkRefererCookieValue.length > 0 ? optimonkRefererCookieValue : '';
}
