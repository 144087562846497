<ng-template #customTemplateRef>
  <rr-status-panel *ngIf="status?.total > 0" [status]="status"></rr-status-panel>
</ng-template>
<rr-grid
  #rrGrid
  [gridId]="gridId"
  [hasFilter]="true"
  [setIconFn]="setIconFn"
  [hasIconColumn]="true"
  [hasActionsColumn]="false"
  [multiSelection]="false"
  [hasCheckboxColumn]="false"
  titleIcon="cloud-upload"
  [databaseCallback]="databaseCallback"
  [columnDefinitions]="displayedColumns"
  [customAreaTemplateRef]="routingMode ? customTemplateRef : undefined"
  [hasRightClickContextDefaultGridOptions]="false"
  [titleText]="titleText"
  disableTitleTranslate="true"
  (editRow)="editRowDblClick($event)"
  [gridFilterLabel]="importConfig?.gridFilterLabel"
  [gridFilterPlaceholder]="importConfig?.gridFilterPlaceholder"
  [gridFilterHint]="importConfig?.gridFilterHint"
  [notEntitiesBackgroundImage]="importConfig?.gridNotEntitiesBackgroundImage"
  [hasNotEntitiesDataLabelLinkCallback]="uiBindedOnClickTryIt"
  [notEntitiesDataLabel]="
    'NOT_FOUND_IMPORT'
      | getTranslateKey
        : { fromPath: 'UNIVERSAL_IMPORT.LIST.NOT_FOUND_ENTITIES', overridePath: importConfig.translatePrefix + '.LIST.NOT_FOUND_ENTITIES' }
  "
  [notEntitiesDataLinkLabel]="
    'BUTTON_LABEL'
      | getTranslateKey
        : { fromPath: 'UNIVERSAL_IMPORT.LIST.NOT_FOUND_ENTITIES', overridePath: importConfig.translatePrefix + '.LIST.NOT_FOUND_ENTITIES' }
  "
  [headerGroupActions]="[]"
  [headerOtherActions]="headerOtherActions"
  [headerSingleButtonAction]="headerSingleButtonAction"
></rr-grid>
