<mat-tab-group mat-stretch-tabs class="mat-elevation-z4" *ngIf="inited; else initTpl">
  <mat-tab [label]="'USER.APPLICATION_SETTINGS.TABS.GLOBAL.LABEL' | transloco" *ngIf="showGlobalTab">
    <ng-template matTabContent>
      <rr-company-tab></rr-company-tab>
    </ng-template>
  </mat-tab>
  <mat-tab [label]="'USER.APPLICATION_SETTINGS.TABS.USER.LABEL' | transloco">
    <ng-template matTabContent>
      <rr-user-tab></rr-user-tab>
    </ng-template>
  </mat-tab>
</mat-tab-group>
<ng-template #initTpl>
  <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</ng-template>
