<ng-template #customAreaTemplateRef>{{ 'MILEAGE.LIST.HEADER_RIGHT_WARNING_TEXT' | transloco }}</ng-template>

<rr-grid
  #rrGrid
  [gridId]="gridId"
  [autoOpenEditRoute]="false"
  [multiSelection]="false"
  [fullDisableRowClick]="true"
  [hasCheckboxColumn]="false"
  [columnDefinitions]="displayedColumns"
  [titleText]="titleText"
  [hasFilter]="hasFilter"
  titleIcon="clock-end"
  [hasIconColumn]="true"
  [setIconFn]="setIconFn"
  [notEntitiesDataLabel]="'MILEAGE.LIST.NOT_ENTITIES_DATA_LABEL' | transloco"
  notEntitiesDataLinkLabel=""
  [hasRightClickContextDefaultGridOptions]="false"
  notEntitiesBackgroundImage="odometer"
  [disableRowClick]="true"
  [hasActionsColumn]="true"
  [editColumns]="['mileage', 'quantity', 'cost']"
  [editColumnsConfig]="editColumnsConfig"
  [editRowNewModel]="editRowNewModel"
  [editColumnUpdateActionAutoAddId]="false"
  (initEditControl)="initEditControl($any($event))"
  (createClickRowEdit)="onModifiedRow()"
  (updateClickRowEdit)="onModifiedRow()"
  [customAreaTemplateRef]="customAreaTemplateRef"
  [hasActionRemove]="true"
  [hasActionRemoveDisableCb]="hasActionRemoveDisableCb"
  [headerGroupActions]="[]"
  [headerOtherActions]="headerOtherActions"
></rr-grid>
