<ng-template #cellHeaderTemplate let-day="day" let-locale="locale">
  <div
    [fxLayout]="day.events.length ? 'row' : 'column'"
    [ngClass]="{ 'rr-day-header-with-content': day.events.length > 0, 'rr-day-header': day.events.length === 0 }"
  >
    <span fxFlex *ngIf="day.events.length > 0" class="rr-day-header-text">{{
      'FUELING.CALENDAR.CELL.HEADER.FUELING_NUMBERS' | transloco: { value: day.events.length }
    }}</span>
    <span
      class="cal-day-number"
      fxFlexAlign="end"
      matTooltip="{{ day.date | calendarDate: 'dayViewTitle':locale }}"
      matTooltipPosition="above"
      [ngClass]="setHeaderDayNumberCssClassFn(day)"
    >
      {{ day.date | calendarDate: 'monthViewDayNumber':locale }}
    </span>
  </div>
</ng-template>

<div fxLayout="column" fxLayoutAlign="center stretch" fxFlexFill>
  <rr-calendar-layout
    [viewDate]="viewDate$ | async"
    [enabledRightClickContextMenu]="true"
    [rightClickContextMenuOption]="rightClickContextMenuOption"
    titleIcon="gas-station"
    (clickCell)="onClickCell($event)"
    (contextMenuOnDayCell)="onContextMenuOnDayCell($event)"
    [cellHeaderTemplate]="cellHeaderTemplate"
    [topRightMenuAfterButtons]="topRightMenuAfterButtons"
    fxFlexFill
    [fillCalendarCb]="fillCalendar"
    (periodContextChange)="onPeriodContextChange($event)"
    [enableNotAccessRedirect]="false"
  ></rr-calendar-layout>
</div>
