import { CommonModule as NgCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CapitalizeDirective } from './capitalize.directive';

@NgModule({
  imports: [NgCommonModule],
  declarations: [CapitalizeDirective],
  exports: [CapitalizeDirective],
})
export class CapitalizeDirectiveModule {}
