import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { AppTypePipeModule, FocusInputModule, PreventClickModule } from '@roadrecord/common/common';
import { RecaptchaModule } from 'ng-recaptcha';
import { AuthenticationModule } from '../authentication.module';

import { LoginComponent } from './login.component';
import { ValidatingModule } from '@roadrecord/validating';
import { SocialButtonsCardModule } from '../social-buttons-card/social-buttons-card.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    FlexLayoutModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatButtonModule,
    MatProgressBarModule,

    AuthenticationModule,
    PreventClickModule,
    FocusInputModule,

    RecaptchaModule,
    TranslocoModule,
    RouterModule,
    ValidatingModule,
    SocialButtonsCardModule,
    AppTypePipeModule,
  ],
  declarations: [LoginComponent],
  exports: [LoginComponent],
})
export class LoginModule {}
