<rr-filter-panel
  [countAll]="countAll"
  [countError]="countError"
  [countNotChecked]="countNotChecked"
  [countOK]="countOK"
  [countWarning]="countWarning"
  [currentFilter]="currentFilter"
  [disabled]="runPdfGeneration"
  [run]="run"
></rr-filter-panel>
<div class="scroll position-relative">
  <mat-accordion [multi]="true" class="report-print-headers-align" *ngIf="run === false; else loaderTpl">
    <mat-expansion-panel
      *ngFor="let check of filteredList"
      [hideToggle]="run === false || check.status === ReportPrintStatusEnum.OK"
      [disabled]="run || check.status === ReportPrintStatusEnum.OK"
      [expanded]="check.status === ReportPrintStatusEnum.WARNING || check.status === ReportPrintStatusEnum.ERROR"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <ng-container [ngSwitch]="check.status">
            <ng-container *ngSwitchCase="ReportPrintStatusEnum.OK">
              <span class="status-chip check-success">{{ 'REPORT_PRINT.PANEL.STATUS.OK' | transloco }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="ReportPrintStatusEnum.WARNING">
              <span class="status-chip check-warning">{{ 'REPORT_PRINT.PANEL.STATUS.WARNING' | transloco }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="ReportPrintStatusEnum.ERROR">
              <span class="status-chip check-error">{{ 'REPORT_PRINT.PANEL.STATUS.ERROR' | transloco }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="ReportPrintStatusEnum.NOT_CHECKED">
              <span class="status-chip check-not-checking">{{ 'REPORT_PRINT.PANEL.STATUS.NOT_CHECKED' | transloco }}</span>
            </ng-container>
          </ng-container>
          <span class="title">{{ check.title }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <p>
          <b>{{ 'REPORT_PRINT.PANEL.CONTENT.RESULT_LABEL' | transloco }}</b
          >&nbsp;<span [innerHTML]="check.result | textPlaceholderHtmlTagReplacer"></span>
        </p>
      </ng-template>
      <mat-action-row *ngIf="run === false">
        <ng-container *ngIf="check?.actions?.length > 0">
          <button
            rrPreventClick
            type="button"
            mat-stroked-button
            color="warn"
            *ngFor="let action of check.actions"
            (click)="onClickAction(action.actions)"
          >
            {{ action.label | getLabelByCurrentLang | async }}
          </button>
        </ng-container>
        <ng-container *ngIf="(check.status === ReportPrintStatusEnum.ERROR && (helpVideoUrl$ | async)?.length > 0) || (faqConfig$ | async)">
          <div class="vertical-divider" *ngIf="check?.actions?.length > 0"></div>
          <button rrPreventClick type="button" *ngIf="(helpVideoUrl$ | async)?.length > 0" mat-button (click)="openHelpWindow()">
            <mat-icon class="light-color" svgIcon="play-circle-outline"></mat-icon>
            {{ 'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.ITEM.HELP_VIDEO' | transloco }}
          </button>
          <a *ngIf="faqConfig$ | async as faqConfig" mat-button target="_blank" [href]="faqConfig.url"
            ><mat-icon class="light-color" svgIcon="help-circle-outline"></mat-icon> {{ faqConfig.label | transloco }}</a
          >
        </ng-container>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
  <ng-template #loaderTpl>
    <mat-progress-spinner color="warn" mode="indeterminate" strokeWidth="8" class="tpl-loader position-absolute"></mat-progress-spinner>
  </ng-template>
</div>
