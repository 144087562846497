<div class="inside-scroll">
  <div class="static-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex.gt-md="36" fxFlex="90">
      <div class="static-content" fxLayout="column" fxLayoutAlign="center center">
        <img class="static-icon" fxFlexOffset="15px" [src]="'assets/images/registration/emaily.png'" alt="image" />
        <mat-card>
          <mat-card-title>{{ 'USER.PASSWORD.FORGOT_PASSWORD.PASSWORD_RESET_SENT.TITLE' | transloco }}</mat-card-title>
          <mat-card-content>
            <p class="static-description">{{ 'USER.PASSWORD.FORGOT_PASSWORD.PASSWORD_RESET_SENT.BODY' | transloco }}</p>
            <p class="static-description">{{ 'USER.PASSWORD.FORGOT_PASSWORD.PASSWORD_RESET_SENT.BODY_2' | transloco }}</p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  <rr-unauthenticated-footer></rr-unauthenticated-footer>
</div>
