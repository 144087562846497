import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ENTITY_SERVICE_TOKEN } from '@roadrecord/utils';
import { FirstStepsComponentTypeEnum } from '../../../../model/first-steps-component-type.enum';
import { firstStepsSteppingComponentCurrentStep } from '../../stepping.component';
import { PartnerModel, PartnerTypeEnum } from '@roadrecord/partner/model';
import { PARTNER_SERVICE_TYPE_TOKEN, PartnerService } from '@roadrecord/partner/common';
import { FirstStepsHelpModel } from '../../../../model/first-steps-next-step.model';

export const partnerStepService = () => {
  if (firstStepsSteppingComponentCurrentStep.step.type === FirstStepsComponentTypeEnum.PARTNER) {
    return PartnerTypeEnum.PARTNER;
  } else if (firstStepsSteppingComponentCurrentStep.step.type === FirstStepsComponentTypeEnum.HEAD_OFFICE_OR_SITE) {
    return PartnerTypeEnum.HEAD_OFFICE_OR_SITE;
  }
  return PartnerTypeEnum.PARTNER;
};

@Component({
  selector: 'rr-partner-wrapper-step',
  templateUrl: './partner-wrapper-step.component.html',
  styleUrls: ['./partner-wrapper-step.component.scss'],
  providers: [
    {
      provide: PARTNER_SERVICE_TYPE_TOKEN,
      useFactory: partnerStepService,
      deps: [],
    },
    {
      provide: ENTITY_SERVICE_TOKEN,
      useExisting: PartnerService,
    },
  ],
})
export class PartnerWrapperStepComponent implements OnInit {
  @Input()
  type: FirstStepsComponentTypeEnum;
  @Input()
  helpText: FirstStepsHelpModel;
  @Output()
  save = new EventEmitter<PartnerModel>();
  @Output() skipStep = new EventEmitter<void>();

  detailsHeight = '100%';

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    let type: PartnerTypeEnum;
    switch (this.type) {
      case FirstStepsComponentTypeEnum.PARTNER:
        type = PartnerTypeEnum.PARTNER;
        break;
      case FirstStepsComponentTypeEnum.HEAD_OFFICE_OR_SITE:
        type = PartnerTypeEnum.HEAD_OFFICE_OR_SITE;
        break;
    }
    this.route.snapshot.data = { type };
  }

  onSavePartner(partner: PartnerModel): void {
    this.save.emit(partner);
  }

  onSkipStep() {
    this.skipStep.emit();
  }
}
