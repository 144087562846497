import { animate, style, transition, trigger } from '@angular/animations';
import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';
import { checkPropertyChange } from '@roadrecord/common/common';
import { isEmptyString, isNil } from '@roadrecord/type-guard';
import { PresenterStateController } from '@roadrecord/utils';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'rr-progress-when-first-loading',
  templateUrl: './progress-when-first-loading.component.html',
  styleUrls: ['./progress-when-first-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // TODO kirakni egy kulon fajlba mert a card content is ezt hasznalja
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('.25s cubic-bezier(0.25, 0, 0.15, 1)', style({ opacity: 1 }))]),
      transition(':leave', [
        style({ transform: 'scale(1,1)', opacity: 1 }),
        animate('.15s cubic-bezier(0.25, 0, 0.15, 1)', style({ transform: 'scale(0,0)', opacity: 0 })),
      ]),
    ]),
    trigger('secondEnterAnimation', [
      transition('stop => start', [
        style({ transform: 'scale(0,0)', opacity: 0, width: '100%' }),
        animate('.25s cubic-bezier(0.25, 0, 0.15, 1)', style({ transform: 'scale(1,1)', opacity: 1 })),
      ]),
      transition('start => start2', [
        style({ opacity: 0, width: '100%' }),
        animate('.25s cubic-bezier(0.25, 0, 0.15, 1)', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ProgressWhenFirstLoadingComponent implements OnInit, OnChanges {
  @Input()
  loading = true;
  @Input()
  text: string;
  isDoneLoadingFadeAnimation = 0;

  constructor(
    @Attribute('type') readonly type: 'bar' | 'spinner',
    private cdr: ChangeDetectorRef,
    @Optional() private presenterStateController: PresenterStateController<any, any>,
    private translocoService: TranslocoService
  ) {
    if (isNil(this.type)) {
      this.type = 'bar';
    }
    this.checkPresentPresenterStateAndSubscribeFirstInit();
  }

  ngOnInit(): void {
    if (isEmptyString(this.text)) {
      // ha nincs megadva kulso input-kent a loading text, akkor beallitjuk a default-t
      this.text = this.translocoService.translate('PROGRESS_WHEN_FIRST_LOADING.DEFAULT_LOADING_TEXT');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.loading === false && isNil(this.presenterStateController) && checkPropertyChange('loading', changes)) {
      // ha nincs presenter state es valtozik a loading input
      this.cdr.markForCheck();
    }
  }

  doneLoadingFadeAnimation(): void {
    this.isDoneLoadingFadeAnimation++;
  }

  private checkPresentPresenterStateAndSubscribeFirstInit(): void {
    if (!isNil(this.presenterStateController)) {
      this.presenterStateController.finishFirstInit$ /*.pipe(delay(0))*/
        .subscribe(() => {
          this.loading = false;
          this.cdr.markForCheck();
        });
    }
  }
}
