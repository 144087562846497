import { ApplicationRef, ComponentFactoryResolver, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { AbstractFragmentPresenterClass, FragmentDialogTypeEnum, FragmentHideDialogAction } from '@roadrecord/fragment-dialog';
import { partnerDetailsRoutingWhenSaveAndNotResetStrategy } from '../details/partner-details-routing-when-save-and-not-reset.strategy';
import { currentHeadOrOfficeModalWrapperType, ModalWrapperComponent } from '../modal-wrapper/modal-wrapper/modal-wrapper.component';
import { PartnerModel, PartnerTypeEnum } from '@roadrecord/partner/model';

export class PartnerNewFragmentPresenter extends AbstractFragmentPresenterClass<PartnerModel, ModalWrapperComponent> {
  private readonly type: PartnerTypeEnum;

  constructor(
    store: Store,
    matDialog: MatDialog,
    fragmentType: FragmentDialogTypeEnum,
    componentFactoryResolver: ComponentFactoryResolver,
    document: Document,
    appRef: ApplicationRef,
    injector: Injector
  ) {
    super(
      store,
      matDialog,
      componentFactoryResolver,
      fragmentType,
      ModalWrapperComponent,
      document,
      appRef,
      injector,
      partnerDetailsRoutingWhenSaveAndNotResetStrategy,
      // (wrapperCmp: ModalWrapperComponent): Observable<boolean> => this._preventCloseCb(wrapperCmp),
      ['partner-dialog'],
      {
        height: '90%',
        width: '90%',
        maxWidth: '1540px',
      }
    );

    switch (fragmentType) {
      case FragmentDialogTypeEnum.NEW_PARTNER:
        this.type = PartnerTypeEnum.PARTNER;
        break;
      case FragmentDialogTypeEnum.NEW_HEAD_OR_OFFICE_SITE:
        this.type = PartnerTypeEnum.HEAD_OFFICE_OR_SITE;
        break;
      case FragmentDialogTypeEnum.NEW_CHARGING_STATION:
        this.type = PartnerTypeEnum.CHARGING_STATION;
        break;
    }
  }

  uiCloseDialog(cancel?: boolean): void {
    this.store.dispatch(new FragmentHideDialogAction(this.fragmentType, undefined, false, cancel));
  }

  get newModel(): PartnerModel {
    const newModel: Partial<PartnerModel> = {};
    newModel.trip_reason = this.cmpRef.instance.details.first.dataForm.tripReasonControl.value;
    if (newModel.trip_reason === null) {
      // fix
      newModel.trip_reason = undefined;
    }
    if (this.formValue.type !== undefined) {
      newModel.type = this.type;
    }
    return newModel as PartnerModel;
  }

  get formValue(): PartnerModel {
    const formValue = this.cmpRef.instance.details.first.presenterStateController.formGroupLastValue.value;
    const newFormValue: PartnerModel = {} as PartnerModel;
    /**
     * kis hack mert mi undefined-t hasznalunk de a material form null-t add vissza :( a formModel getternel
     */
    // tslint:disable-next-line
    Object.entries(formValue).forEach(entry => (newFormValue[entry[0]] = entry[1] === null ? undefined : entry[1]));
    return newFormValue;
  }

  // private _preventCloseCb(wrapperCmp: ModalWrapperComponent): Observable<boolean> {
  //   const formValue = wrapperCmp.details.first.dataForm.form.value;
  //   const newFormValue: PartnerModel = {} as PartnerModel;
  //   /**
  //    * kis hack mert mi undefined-t hasznalunk de a material form null-t add vissza :( a formModel getternel
  //    */
  //   // tslint:disable-next-line
  //   Object.entries(formValue).forEach(entry => (newFormValue[entry[0]] = entry[1] === null ? undefined : entry[1]));
  //   const newModel = new PartnerModel();
  //   newModel.type = this.type;
  //   newModel.trip_reason = wrapperCmp.details.first.dataForm.tripReasonControl.value;
  //   return checkModifiedForm(newFormValue, newModel);
  // }

  fillCmpInputs(instance: ModalWrapperComponent): void {
    currentHeadOrOfficeModalWrapperType.type = this.type;
  }

  directSubmit(): void {
    this.cmpRef.instance.details.first.dataForm.onSubmit();
  }
}
