import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';
import { take } from 'rxjs/operators';
import { baseNotAccessUrl } from './has-acces-menu-item.guard';
import { MenuService } from './menu.service';

export function notAccessRedirect(
  injector: Injector,
  route = [baseNotAccessUrl],
  text = 'APP_LAYOUT.MENU.NOT_ACCESS_DIALOG.TEXT',
  type = MessageDialogTypeEnum.INFORMATION
): void {
  const menuService = injector.get(MenuService) as MenuService;
  const messageDialogService = injector.get(MessageDialogService) as MessageDialogService;
  const translocoService = injector.get(TranslocoService) as TranslocoService;
  const router = injector.get(Router) as Router;

  menuService.selectedMenuItem$.pipe(take(1)).subscribe(selectedMenuItem => {
    const selectedMenuItemLabel = translocoService.translate(selectedMenuItem.label);
    const translates = translocoService.translate(text, { selectedMenuItemLabel });
    messageDialogService
      .open({ id: null, type, text: translates, translateText: false })
      .afterClosed()
      .subscribe(() => router.navigate(route));
  });
}
