import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { produce } from '@ngxs-labs/immer-adapter';
import { Action, Actions, ofAction, Selector, State, StateContext, Store } from '@ngxs/store';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { asapScheduler, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { RecommendationCancelErrorAction } from './action/recommendation-cancel-error.action';
import { RecommendationCancelSuccessAction } from './action/recommendation-cancel-success.action';
import { RecommendationCancelAction } from './action/recommendation-cancel.action';
import { RecommendationChangeFilterAction } from './action/recommendation-change-filter.action';
import { RecommendationContinueErrorAction } from './action/recommendation-continue-error.action';
import { RecommendationContinueSuccessAction } from './action/recommendation-continue-success.action';
import { RecommendationContinueAction } from './action/recommendation-continue.action';
import { RecommendationFinishErrorAction } from './action/recommendation-finish-error.action';
import { RecommendationFinishSuccessAction } from './action/recommendation-finish-success.action';
import { RecommendationFinishAction } from './action/recommendation-finish.action';
import { RecommendationInitErrorAction } from './action/recommendation-init-error.action';
import { RecommendationInitSuccessAction } from './action/recommendation-init-success.action';
import { RecommendationInitAction } from './action/recommendation-init.action';
import { RecommendationResetAction } from './action/recommendation-reset.action';
import { RecommendationCheckerResult } from './model/recommendation-checker-result';
import { RecommendationFinishStatusModel } from './model/recommendation-finish-status.model';
import { RecommendationProgressStatusModel } from './model/recommendation-progress-status.model';
import { RecommendationStateModel } from './model/recommendation-state.model';
import { RecommendationStatusEnum } from './model/recommendation-status.enum';
import { isNil } from '@roadrecord/type-guard';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { Router } from '@angular/router';
import { isSelfEmployedPeriodContextFn } from '../../is-self-employed-period-context';
import { isPrivatePeriodContextFn } from '../../is-private-period-context';
import { environment } from '@roadrecord/environment';

const defaultState: RecommendationStateModel = {
  run: false,
  loading: false,
  checkResults: undefined,
  currentFilter: 'ALL',
  continueProgress: { progress: -1 },
  success: undefined,
  version: 3,
};

const logScopeName = 'RecommendationState';

@State<RecommendationStateModel>({
  name: 'recommendationPage',
  defaults: { ...defaultState },
})
@Injectable()
export class RecommendationState {
  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private matSnackBar: MatSnackBar,
    private translocoService: TranslocoService,
    private messageDialogService: MessageDialogService,
    private store: Store,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    private router: Router
  ) {}

  private mock = false;
  @Selector()
  static continueProgress(state: RecommendationStateModel): { progress: number } {
    return state.continueProgress;
  }

  @Selector()
  static countAll(state: RecommendationStateModel): number {
    return state.checkResults ? state.checkResults.length : 0;
  }

  @Selector()
  static countAllWithoutError(state: RecommendationStateModel): number {
    return state.checkResults ? state.checkResults.filter(check => check.status !== RecommendationStatusEnum.ERROR).length : 0;
  }

  @Selector()
  static countOK(state: RecommendationStateModel): number {
    return state.checkResults ? state.checkResults.filter(check => check.status === RecommendationStatusEnum.OK).length : 0;
  }

  @Selector()
  static countWarning(state: RecommendationStateModel): number {
    return state.checkResults ? state.checkResults.filter(check => check.status === RecommendationStatusEnum.WARNING).length : 0;
  }

  @Selector()
  static countError(state: RecommendationStateModel): number {
    return state.checkResults ? state.checkResults.filter(check => check.status === RecommendationStatusEnum.ERROR).length : 0;
  }

  @Selector()
  static hasCheckErrorOrWarning(state: RecommendationStateModel): boolean {
    return RecommendationState.countWarning(state) > 0 || RecommendationState.countError(state) > 0;
  }

  @Selector()
  static currentFilter(state: RecommendationStateModel): RecommendationStatusEnum | 'ALL' {
    return state.currentFilter;
  }

  @Selector()
  static run(state: RecommendationStateModel): boolean {
    return state.run;
  }

  @Selector()
  static loading(state: RecommendationStateModel): boolean {
    return state.run && state.loading;
  }

  @Selector()
  static checkResults(state: RecommendationStateModel): RecommendationCheckerResult[] {
    return state.checkResults;
  }

  @Selector()
  static success(state: RecommendationStateModel): boolean {
    return state.success;
  }

  @Action(RecommendationChangeFilterAction)
  reportPrintChangeFilter(ctx: StateContext<RecommendationStateModel>, action: RecommendationChangeFilterAction): any {
    produce<RecommendationStateModel>(ctx, draft => {
      draft.currentFilter = action.status;
    });
  }

  @Action([RecommendationInitErrorAction, RecommendationContinueErrorAction, RecommendationFinishErrorAction, RecommendationCancelAction])
  recommendationCheckRequestError({ dispatch }: StateContext<RecommendationStateModel>): any {
    asapScheduler.schedule(() => dispatch(new RecommendationResetAction()));
  }

  @Action(RecommendationInitAction)
  recommendationInit(ctx: StateContext<RecommendationStateModel>): any {
    if (ctx.getState().run === true) {
      return;
    }
    produce<RecommendationStateModel>(ctx, draft => {
      draft.run = true;
      draft.loading = true;
    });
    return this.http.get<RecommendationCheckerResult[]>(`${environment.apiUrl}recommendation/init/`).pipe(
      tap(response => asapScheduler.schedule(() => ctx.dispatch(new RecommendationInitSuccessAction(response)))),
      catchError(err => {
        asapScheduler.schedule(() => ctx.dispatch(new RecommendationInitErrorAction(err)));
        return throwError(err);
      })
    );
  }

  @Action(RecommendationInitSuccessAction)
  recommendationInitSuccess(ctx: StateContext<RecommendationStateModel>, action: RecommendationInitSuccessAction): any {
    produce<RecommendationStateModel>(ctx, draft => {
      draft.loading = false;
      draft.run = true;

      let snackBarText: string;

      const dispatch = ctx.dispatch;

      const checkResults = { checkResults: action.response } as RecommendationStateModel;
      if (RecommendationState.countError(checkResults) > 0) {
        draft.currentFilter = RecommendationStatusEnum.ERROR;
        snackBarText = 'RECOMMENDATION.SNACKBAR.INIT_ERROR';
      } else if (RecommendationState.countWarning(checkResults) > 0) {
        draft.currentFilter = RecommendationStatusEnum.WARNING;
        snackBarText = 'RECOMMENDATION.SNACKBAR.INIT_WARNING';
      }

      if (snackBarText !== undefined && snackBarText.length > 0) {
        let periodContextKey: string;
        const periodContext = this.store.selectSnapshot(this.periodContextStateSelectorsService.context);
        if (isSelfEmployedPeriodContextFn(periodContext, this.periodContextStateSelectorsService)) {
          periodContextKey = 'isSelfEmployed';
        } else if (isPrivatePeriodContextFn(periodContext, this.periodContextStateSelectorsService)) {
          periodContextKey = 'privatee';
        } else if (this.store.selectSnapshot(this.periodContextStateSelectorsService.isCompany)) {
          periodContextKey = 'company';
        }

        draft.checkResults = action.response.map(check => {
          if (!isNil(check.actions)) {
            check.actions = check.actions.filter(checkAction => checkAction.rules[periodContextKey]);
          }
          return check;
        });
        this.matSnackBar.open(this.translocoService.translate(snackBarText));
      } else {
        asapScheduler.schedule(() => dispatch(new RecommendationContinueAction()));
      }
    });
    this.router.navigate(['/month-activities/recommendation/process']);
  }

  @Action(RecommendationContinueAction)
  recommendationContinue(ctx: StateContext<RecommendationStateModel>): any {
    produce<RecommendationStateModel>(ctx, draft => {
      if (draft.loading === false) {
        draft.loading = true;
      }
    });

    const dispatch = ctx.dispatch;

    return this.http.get<RecommendationProgressStatusModel>(`${environment.apiUrl}recommendation/continue/`).pipe(
      takeUntil(this.actions$.pipe(ofAction(RecommendationResetAction))),
      tap(response => asapScheduler.schedule(() => dispatch(new RecommendationContinueSuccessAction(response)))),
      catchError(err => {
        asapScheduler.schedule(() => dispatch(new RecommendationContinueErrorAction(err)));
        return throwError(err);
      })
    );
  }

  @Action(RecommendationContinueSuccessAction)
  recommendationContinueSuccess(ctx: StateContext<RecommendationStateModel>, action: RecommendationContinueSuccessAction): any {
    produce<RecommendationStateModel>(ctx, draft => {
      if (this.mock) {
        // draft.continueProgress += 25;
      } else {
        draft.continueProgress = action.response.progress > 100 ? { progress: 100 } : action.response;
        if (action.response.progress > 100) {
          // TODO ideiglenes
          console.error(logScopeName, 'Progress nagyobb mint 100!');
        }
      }
    });
  }

  @Action(RecommendationFinishAction)
  recommendationFinish({ dispatch }: StateContext<RecommendationStateModel>): any {
    return this.http.get<RecommendationFinishStatusModel>(`${environment.apiUrl}recommendation/finish/`).pipe(
      tap(response => asapScheduler.schedule(() => dispatch(new RecommendationFinishSuccessAction(response)))),
      catchError(err => {
        asapScheduler.schedule(() => dispatch(new RecommendationFinishErrorAction(err)));
        return throwError(err);
      })
    );
  }

  @Action(RecommendationFinishSuccessAction)
  recommendationFinishSuccess(ctx: StateContext<RecommendationStateModel>): any {
    produce<RecommendationStateModel>(ctx, draft => {
      draft.run = false;
      draft.success = true;
    });
  }

  @Action(RecommendationResetAction)
  recommendationReset(ctx: StateContext<RecommendationStateModel>): any {
    produce<RecommendationStateModel>(ctx, draft => {
      Object.entries(defaultState).forEach(entry => (draft[entry[0]] = entry[1]));
    });
  }

  @Action(RecommendationCancelAction)
  recommendationCancel({ dispatch }: StateContext<RecommendationStateModel>): any {
    return this.http.get<any>(`${environment.apiUrl}recommendation/cancel/`).pipe(
      tap(() => asapScheduler.schedule(() => dispatch(new RecommendationCancelSuccessAction()))),
      catchError(err => {
        asapScheduler.schedule(() => dispatch(new RecommendationCancelErrorAction(err)));
        return throwError(err);
      })
    );
  }

  @Action(RecommendationCancelSuccessAction)
  recommendationCancelSuccess(ctx: StateContext<RecommendationStateModel>) {
    produce<RecommendationStateModel>(ctx, draft => {
      draft.run = false;
      draft.success = false;
    });
  }
}
