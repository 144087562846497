import { PartnerListModel, PartnerTypeEnum } from '@roadrecord/partner/model';
import { isNil, isNumeric, isObject } from '@roadrecord/type-guard';

export function setIconFnPartner(model: PartnerListModel) {
  if (isNil(model) || !isObject(model) || isNil(model.trip_reason) || isNumeric(model.trip_reason)) {
    console.warn('Not found trip reason');
    return { value: '' };
  }
  return {
    value: model.type === PartnerTypeEnum.CHARGING_STATION ? 'gas-station' : model.trip_reason.is_private ? 'human-handsdown' : 'briefcase',
  };
}
