import { MenuItemModel } from './menu-item.model';
import { MenuPageModel } from './menu-page.model';

export class MenuLastRouteStateModel {
  item: MenuItemModel;
  parent: MenuPageModel;
  subRoute: string;

  constructor(data: MenuLastRouteStateModel) {
    Object.assign(this, data);
  }
}
