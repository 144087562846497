import { Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { openWindowAction, PreferencesContentWindowTypeEnum } from '@roadrecord/preferences-state/common';
import { LoadableService } from '@roadrecord/external-packages/ngx-loadable';
import { Moment } from 'moment';

export const finalizationOfRoutesCopyDaysOpenWindowAction = (
  store: Store,
  loadableService: LoadableService,
  injector: Injector,
  translocoService: TranslocoService,
  matSnackBar: MatSnackBar,
  defaultFrom?: Moment
) =>
  openWindowAction(
    store,
    loadableService,
    injector,
    translocoService,
    matSnackBar,
    'finalization-of-route-copy-days',
    PreferencesContentWindowTypeEnum.FINALIZATION_OF_ROUTE_COPY_DAYS,
    'FINALIZATION_OF_ROUTE.COPY_DAYS_WINDOW.HEADER_TITLE',
    {
      height: '410px',
      width: '540px',
    },
    false,
    undefined,
    defaultFrom !== undefined ? { defaultFrom } : undefined
  );
