import { Observable, throwError as _throw, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { throwHttpError } from '../function/throw-http-error.function';

/**
 *
 * @param number maxRetryAttempts probalkozasok szama
 * @param number scalingDuration probalkozasi idok
 * @param number[] excludedStatusCodes hibakodok amiknel nincs retry
 * @param number[] watchStatusCodes hibakodok amiknel van retry
 * @return (attempts: Observable<any>) => Observable<any>
 */
export const genericRetryStrategy = ({
  maxRetryAttempts = 3,
  scalingDuration = 1000,
  excludedStatusCodes = [500, 401, 0],
  watchStatusCodes = [],
}: {
  maxRetryAttempts?: number;
  scalingDuration?: number;
  excludedStatusCodes?: number[];
  watchStatusCodes?: number[];
} = {}) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      if (
        retryAttempt > maxRetryAttempts ||
        excludedStatusCodes.length === 0 ||
        excludedStatusCodes.findIndex(e => e === error.status) > -1
      ) {
        // ha lejart a probalkozasok szama, vagy a kizart hibakodokat tartalmazza
        return _throw(throwHttpError(error));
      } else {
        if (watchStatusCodes.length > 0 && watchStatusCodes.findIndex(e => e === error.status) > -1) {
          return timer(retryAttempt * scalingDuration);
        } else {
          return _throw(throwHttpError(error));
        }
      }
    })
  );
};
