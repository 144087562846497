<div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="1em" class="wid100">
  <rr-first-stepper-help-box
    iconType="car"
    [helpText]="helpText"
    (detailsHeight)="detailsHeight = $event"
    (skipStep)="onSkipStep()"
    layoutName="VEHICLE"
  ></rr-first-stepper-help-box>
  <rr-lazy-details
    moduleName="vehicle"
    [ngStyle]="{ 'min-height': detailsHeight }"
    [options]="detailsOptions"
    (events)="onDetailsEvents($event)"
  >
  </rr-lazy-details>
</div>
