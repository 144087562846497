<div
  #container
  [bounds]="document.body"
  [inBounds]="true"
  [handle]="dialogTitle"
  [ngClass]="{ collapsed: collapsed }"
  [preventDefaultEvent]="true"
  inBounds="true"
  ngDraggable
  [ngResizable]="rzHandles === undefined ? false : resizable"
  [rzHandles]="rzHandles ? rzHandles : defaultRzHandles"
  [ngStyle]="{
    minWidth: minWidth,
    maxWidth: maxWidth,
    width: width ? width : defaultWidth,
    height: height ? height : defaultHeight,
    left: 'calc((100% - ' + (width ? width : defaultWidth) + ' + ' + counterFirstValue * 20 + 'px)/2)',
    top: 'calc((100% - ' + (height ? height : defaultHeight) + ' - 80px + ' + counterFirstValue * 20 + 'px)/2)',
    zIndex: 999 + counter
  }"
  (click)="onClickContainer()"
  (started)="onStartedDrag()"
>
  <h2 #dialogTitle class="dialog-title">
    <div style="width: calc(100% - 120px)">{{ title }}</div>
    <div class="right-button-row">
      <button
        type="button"
        (click)="toggle()"
        mat-icon-button
        [matTooltip]="(!collapsed ? collapseTooltipTranslate : collapseTooltipOpenTranslate) | transloco"
        matTooltipPosition="below"
      >
        <mat-icon class="small" svgIcon="{{ !collapsed ? 'arrow-collapse-up' : 'arrow-collapse-down' }}"></mat-icon>
      </button>
      <ng-container *ngIf="showMaximizeAndMinimizeButtons">
        <button
          type="button"
          (click)="minimize()"
          *ngIf="maximized$ | async; else maximizedButton"
          mat-icon-button
          [matTooltip]="minimizeTooltipTranslate | transloco"
          matTooltipPosition="below"
        >
          <mat-icon svgIcon="window-minimize"></mat-icon>
        </button>
        <ng-template #maximizedButton>
          <button
            type="button"
            (click)="maximize()"
            mat-icon-button
            [matTooltip]="maximizeTooltipTranslate | transloco"
            matTooltipPosition="below"
          >
            <mat-icon svgIcon="window-maximize"></mat-icon>
          </button>
        </ng-template>
      </ng-container>
      <button type="button" (click)="onClose()" mat-icon-button [matTooltip]="closeTooltipTranslate | transloco" matTooltipPosition="below">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
  </h2>
  <div class="dialog-body">
    <iframe *ngIf="url; else contentTpl" [src]="url" allowfullscreen="allowfullscreen" frameborder="0"></iframe
    ><ng-template #contentTpl> <ng-content></ng-content></ng-template>
  </div>
</div>
