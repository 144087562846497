import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation, TRANSLOCO_LOADER, TranslocoLoader } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class HttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient /* @Inject(DEFAULT_LANG_PRE_LOAD_JSON_TOKEN) private frontendI18N: any*/) {}

  getTranslation(langPath: string) {
    // if (langPath === environment.languages.defaultLang) {
    //   return of(this.frontendI18N);
    // }
    return this.http.get<Translation>(`/assets/i18n/${langPath}.json`);
  }
}

export const translocoLoader = { provide: TRANSLOCO_LOADER, useClass: HttpLoader };
