import { DynamicFormConfigEnum, dynamicFormConfigHelper, DynamicFormConfigInterface } from '@roadrecord/dynamic-form';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CapitalizeDirective } from '@roadrecord/common/common';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

let firstNameSubscription: Subscription;
let lastNameSubscription: Subscription;

function generateFormlyFieldConfig(
  lastName: { key: string; matFormFieldClass: string; label: string; placeholder: string },
  firstName: { key: string; matFormFieldClass: string; label: string; placeholder: string }
): FormlyFieldConfig[] {
  return [
    {
      type: 'flex-layout',
      templateOptions: {
        cssClass: 'w-100',
        flex: {
          fxLayout: 'column',
          fxLayout__gt_sm: 'row',
          fxLayoutGap: '1em',
        },
      },
      fieldGroup: [
        {
          type: 'input',
          key: lastName.key,
          focus: true,
          templateOptions: {
            matFormFieldClass: lastName.matFormFieldClass,
            required: true,
            minLength: 2,
            maxLength: 40,
            transloco: true,
            label: lastName.label,
            placeholder: lastName.placeholder,
            flex: {
              fxFlex: '100%',
              fxFlex__gt_sm: '50%',
            },
          },
          hooks: {
            onInit: ({ formControl }) =>
              (lastNameSubscription = formControl.valueChanges
                .pipe(filter(() => formControl.enabled))
                .subscribe(v => formControl.patchValue(CapitalizeDirective.capitalizeText(v), { emitEvent: false }))),
            onDestroy: () => lastNameSubscription.unsubscribe(),
          },
        },
        {
          type: 'input',
          key: firstName.key,
          templateOptions: {
            matFormFieldClass: firstName.matFormFieldClass,
            required: true,
            minLength: 2,
            maxLength: 40,
            transloco: true,
            label: firstName.label,
            placeholder: firstName.placeholder,
            flex: {
              fxFlex: '100%',
              fxFlex__gt_sm: '50%',
            },
          },
          hooks: {
            onInit: ({ formControl }) =>
              (firstNameSubscription = formControl.valueChanges
                .pipe(filter(() => formControl.enabled))
                .subscribe(v => formControl.patchValue(CapitalizeDirective.capitalizeText(v), { emitEvent: false }))),
            onDestroy: () => firstNameSubscription.unsubscribe(),
          },
        },
      ],
    },
  ];
}

export const userRegistrationNameFormFragmentDynamicConfig: DynamicFormConfigInterface = dynamicFormConfigHelper(
  DynamicFormConfigEnum.USER_REGISTRATION_NAME_FORM_FRAGMENT,
  generateFormlyFieldConfig(
    { key: 'lastName', matFormFieldClass: 'x--last-name', label: 'USER.REGISTER.LAST_NAME', placeholder: 'USER.REGISTER.EX_LAST_NAME' },
    { key: 'firstName', matFormFieldClass: 'x--first-name', label: 'USER.REGISTER.FIRST_NAME', placeholder: 'USER.REGISTER.EX_FIRST_NAME' }
  )
);

export const companyMemberNameFormFragmentDynamicConfig: DynamicFormConfigInterface = dynamicFormConfigHelper(
  DynamicFormConfigEnum.COMPANY_MEMBER_NAME_FORM_FRAGMENT,
  generateFormlyFieldConfig(
    {
      key: 'last_name',
      matFormFieldClass: 'w-100',
      label: 'COMPANY_MEMBER.DETAILS.DATA_FORM.FIELD.LAST_NAME.LABEL',
      placeholder: 'COMPANY_MEMBER.DETAILS.DATA_FORM.FIELD.LAST_NAME.PLACEHOLDER',
    },
    {
      key: 'first_name',
      matFormFieldClass: 'w-100',
      label: 'COMPANY_MEMBER.DETAILS.DATA_FORM.FIELD.FIRST_NAME.LABEL',
      placeholder: 'COMPANY_MEMBER.DETAILS.DATA_FORM.FIELD.FIRST_NAME.PLACEHOLDER',
    }
  )
);
