import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';

import { fadeInAnimation } from '@roadrecord/common/common';
import { UserModel } from '@roadrecord/user/common';
import { Observable } from 'rxjs';
import { StartWelcomeComponentCommon } from './start-welcome-component.common';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { NextStepConfig } from '../../next-step.config';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Router } from '@angular/router';

@Component({
  selector: 'rr-start-welcome',
  templateUrl: './start-welcome.component.html',
  styleUrls: ['./start-welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('list', [transition(':enter', [query('@items', stagger(300, animateChild()))])]),
    trigger('items', [
      transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }), // initial
        animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ transform: 'scale(1)', opacity: 1 })), // final
      ]),
    ]),
    fadeInAnimation,
  ],
})
export class StartWelcomeComponent extends StartWelcomeComponentCommon implements OnDestroy {
  readonly user$: Observable<UserModel>;
  @Output()
  readonly nextStep = new EventEmitter<NextStepConfig>();

  constructor(
    translocoService: TranslocoService,
    store: Store,
    router: Router,
    http: HttpClient,
    cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) document: Document,
    gtmService: GoogleTagManagerService
  ) {
    super(translocoService, store, router, http, cdr, document, gtmService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  startSteps(): void {
    this.form.submitted = true;
    if (this.form.valid) {
      this.gtmService.pushTag({
        event: `Click START_STEPS`,
        label: 'FirstWelcomeChoiceClick',
        category: 'FirstStepper',
      });

      this.disableButton = true;
      this.nextStep.emit({ layoutName: 'START_WELCOME' });
    }
  }
}
