import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { produce } from '@ngxs-labs/immer-adapter';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { MaybeHandleHttpError } from '@roadrecord/utils';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { isNil } from '@roadrecord/type-guard';
import { asapScheduler, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ReportPrintChangeFilterAction } from './action/report-print-change-filter.action';
import { ReportPrintClearStateAction } from './action/report-print-clear-state.action';
import { ReportPrintCheckDataModel } from './model/report-print-check-data.model';
import { ReportPrintStateModel } from './model/report-print-state.model';
import { ReportPrintStatusEnum } from './model/report-print-status.enum';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { ReportPrintCancelAction } from './action/report-print-cancel.action';
import { environment } from '@roadrecord/environment';
import { ReportPrintFastCheckAction } from './action/report-print-fast-check.action';
import { FastCheckDataModelResponse } from './model/report-print-fast-check-data.model';
import { ReportPrintFastCheckSuccessAction } from './action/report-print-fast-check-success.action';
import { ReportPrintFastCheckErrorAction } from './action/report-print-fast-check-error.action';

const defaultState = {
  version: 3,
  run: false,
  counter: -1,
  checkList: undefined,
  currentStepCounter: 0,
  currentFilter: undefined,
};

@State<ReportPrintStateModel>({
  name: 'reportPrint',
  defaults: { ...defaultState },
})
@Injectable()
export class ReportPrintState {
  constructor(
    private readonly http: HttpClient,
    private messageDialogService: MessageDialogService,
    private store: Store,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>
  ) {}
  private counter = 0;

  @Selector()
  static countAll(state: ReportPrintStateModel): number {
    return state.checkList ? state.checkList.length : 0;
  }

  @Selector()
  static countAllWithoutError(state: ReportPrintStateModel): number {
    return state.checkList ? state.checkList.filter(check => check.status !== ReportPrintStatusEnum.ERROR).length : 0;
  }

  @Selector()
  static countOK(state: ReportPrintStateModel): number {
    return state.checkList ? state.checkList.filter(check => check.status === ReportPrintStatusEnum.OK).length : 0;
  }

  @Selector()
  static countWarning(state: ReportPrintStateModel): number {
    return state.checkList ? state.checkList.filter(check => check.status === ReportPrintStatusEnum.WARNING).length : 0;
  }

  @Selector()
  static countNotChecked(state: ReportPrintStateModel): number {
    return state.checkList ? state.checkList.filter(check => check.status === ReportPrintStatusEnum.NOT_CHECKED).length : 0;
  }

  @Selector()
  static countError(state: ReportPrintStateModel): number {
    return state.checkList ? state.checkList.filter(check => check.status === ReportPrintStatusEnum.ERROR).length : 0;
  }

  @Selector()
  static currentFilter(state: ReportPrintStateModel): ReportPrintStatusEnum | 'ALL' {
    return state.currentFilter;
  }

  @Selector()
  static checkList(state: ReportPrintStateModel): ReportPrintCheckDataModel[] {
    return state.checkList;
  }

  @Selector()
  static counter(state: ReportPrintStateModel): number {
    return state.counter;
  }

  @Selector()
  static currentStepCounter(state: ReportPrintStateModel): number {
    return state.currentStepCounter;
  }

  @Selector()
  static run(state: ReportPrintStateModel): boolean {
    return state.run;
  }

  @Action(ReportPrintFastCheckAction)
  reportPrintFastCheck(ctx: StateContext<ReportPrintStateModel>) {
    produce<ReportPrintStateModel>(ctx, draft => {
      draft.run = true;
      draft.checkList = [];
      draft.currentFilter = 'ALL';
    });

    const dispatch = ctx.dispatch;

    return this.http.get<FastCheckDataModelResponse>(`${environment.apiUrl}document-data-checker/fast-check-with-route-correction`).pipe(
      tap(result => asapScheduler.schedule(() => dispatch(new ReportPrintFastCheckSuccessAction(result)))),
      catchError(error => {
        asapScheduler.schedule(() => dispatch(new ReportPrintFastCheckErrorAction(error)));
        MaybeHandleHttpError.maybeHandleHttpError(error);
        return throwError(error);
      })
    );
  }

  @Action(ReportPrintFastCheckSuccessAction)
  reportPrintFastCheckSuccess(ctx: StateContext<ReportPrintStateModel>, action: ReportPrintFastCheckSuccessAction): any {
    let periodContextKey: string;

    if (this.store.selectSnapshot(this.periodContextStateSelectorsService.isSelfEmployed)) {
      periodContextKey = 'isSelfEmployed';
    } else if (this.store.selectSnapshot(this.periodContextStateSelectorsService.isPrivate)) {
      periodContextKey = 'privatee';
    } else if (this.store.selectSnapshot(this.periodContextStateSelectorsService.isCompany)) {
      periodContextKey = 'company';
    }
    produce<ReportPrintStateModel>(ctx, draft => {
      draft.counter = action.state.counter;

      if (draft.checkList === undefined) {
        draft.checkList = [];
        draft.currentFilter = 'ALL';
      }
      if (Array.isArray(action.state.results)) {
        draft.checkList = [
          ...action.state.results
            .map(check => {
              // TODO kivenni ha backend is valos lesz, mert most ismetli ugyan azt az id-t ezert animacio nem jo
              check.id += this.counter++;
              return check;
            })
            .map((check: ReportPrintCheckDataModel) => {
              if (!isNil(check.actions)) {
                check.actions = check.actions.filter(checkAction => checkAction.rules[periodContextKey]);
              }
              return check;
            })
            .reverse(),
          ...draft.checkList,
        ];

        draft.currentStepCounter = draft.checkList.length;
        draft.run = false;
      }
    });
  }

  @Action(ReportPrintChangeFilterAction)
  reportPrintChangeFilter(ctx: StateContext<ReportPrintStateModel>, action: ReportPrintChangeFilterAction): any {
    produce<ReportPrintStateModel>(ctx, draft => {
      draft.currentFilter = action.status;
    });
  }

  @Action(ReportPrintCancelAction)
  recommendationCheckRequestError({ dispatch }: StateContext<ReportPrintStateModel>): any {
    asapScheduler.schedule(() => dispatch(new ReportPrintClearStateAction()));
  }

  @Action(ReportPrintClearStateAction)
  reportPrintClearState(ctx: StateContext<ReportPrintStateModel>): any {
    produce<ReportPrintStateModel>(ctx, draft => {
      Object.entries(defaultState).forEach(entry => (draft[entry[0]] = entry[1]));
    });
  }
}
