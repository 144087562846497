export enum VehicleIdEnum {
  COMPANY_CAR = 'COMPANY_CAR',
  PRIVATE_CAR = 'PRIVATE_CAR',
  COMPANY_MOTORCYCLE = 'COMPANY_MOTORCYCLE',
  PRIVATE_MOTORCYCLE = 'PRIVATE_MOTORCYCLE',
  COMPANY_TRUCK = 'COMPANY_TRUCK',
}
// tslint:disable-next-line:no-namespace
export namespace VehicleIdEnum {
  export function isCompany(mode: VehicleIdEnum | string): boolean {
    return mode === VehicleIdEnum.COMPANY_CAR || mode === VehicleIdEnum.COMPANY_MOTORCYCLE || mode === VehicleIdEnum.COMPANY_TRUCK;
  }
}
