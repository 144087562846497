export const NEW = 'new';
export const WORKDAY = 'WORKDAY';
export const HOLIDAY = 'HOLIDAY';
export const SPECIAL_DAY_VIEW_TYPE_PUBLIC = 'PUBLIC';
export const SPECIAL_DAY_VIEW_TYPE_COMPANY = 'COMPANY';
export const SPECIAL_DAY_VIEW_TYPE_VEHICLE = 'VEHICLE';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const MILEAGE_START_OF_MONTH = 'start';
export const MILEAGE_END_OF_MONTH = 'end';

export enum SPECIAL_CALENDER_VIEW_TYPES {
  PUBLIC = 1,
  COMPANY = 2,
  VEHICLE = 3,
}

export const SPECIAL_CALENDER_VIEW_TYPES_MAP: { [name: string]: number } = {};
SPECIAL_CALENDER_VIEW_TYPES_MAP[SPECIAL_DAY_VIEW_TYPE_PUBLIC] = SPECIAL_CALENDER_VIEW_TYPES.PUBLIC;
SPECIAL_CALENDER_VIEW_TYPES_MAP[SPECIAL_DAY_VIEW_TYPE_COMPANY] = SPECIAL_CALENDER_VIEW_TYPES.COMPANY;
SPECIAL_CALENDER_VIEW_TYPES_MAP[SPECIAL_DAY_VIEW_TYPE_VEHICLE] = SPECIAL_CALENDER_VIEW_TYPES.VEHICLE;

export const PERCENTAGE_MULTIPLIER = 100;

export const SELECTABLE_YEARS = {
  START_YEAR: 1999,
  END_YEAR: 2050,
};

export const PARTNER_IMPORT_STATUSES = {
  PARTNER_IMPORT_NEW: 'NEW',
  PARTNER_IMPORT_NOT_IDENTIFIED: 'NOT_IDENTIFIED',
  PARTNER_IMPORT_MULTIPLE_RESULTS: 'MULTIPLE_RESULTS',
  PARTNER_IMPORT_MANUALLY_IMPORTED: 'MANUALLY_IMPORTED',
  PARTNER_IMPORT_CURRENTLY_IMPORTED: 'CURRENTLY_IMPORTED',
  PARTNER_IMPORT_PREVIOUSLY_IMPORTED: 'PREVIOUSLY_IMPORTED',
};

export const PARTNER_IMPORT_BOX_STATUSES = {
  PARTNER_IMPORT_STATUS: 'STATUS',
  PARTNER_IMPORT_NEW: 'NEW',
  PARTNER_IMPORT_PROCESSED: 'PROCESSED',
  PARTNER_IMPORT_NOT_IDENTIFIED: 'NOT_IDENTIFIED',
  PARTNER_IMPORT_MULTIPLE_RESULTS: 'MULTIPLE_RESULTS',
};
