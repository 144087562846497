import { DOCUMENT, Location } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';

// import { WINDOW } from '@roadrecord/common/common';
import {
  genericRetryStrategy,
  HandleErrorObject,
  handleHttpError,
  MaintenanceModeError,
  RRHttpErrorResponse,
  ServerSerrorMetaEnum,
  ThrowHttpErrorWithMetaAction,
} from '@roadrecord/utils';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { SwUpdatesService } from '@roadrecord/service-worker';
import { EMPTY, Observable, of, throwError as _throw } from 'rxjs';
import { catchError, filter, retryWhen, switchMap } from 'rxjs/operators';
import { userBrowserInfo } from '@roadrecord/main-helper';
import { environment } from '@roadrecord/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  readonly ID = 'HttpError';
  /**
   * ezzel tudjuk korlatozni hogy  a requestek ne induljanak el
   */
  private cancelPendingRequests = false;
  private window: Window = window;

  constructor(
    private translocoService: TranslocoService,
    // @Inject(WINDOW)
    @Inject(DOCUMENT) private document: Document,
    private location: Location,
    private store: Store,
    private router: Router,
    private swUpdatesService: SwUpdatesService,
    private messageDialogService: MessageDialogService
  ) {}

  private _disableErrorCodes: number[] = [];

  get disableErrorCodes(): number[] {
    return this._disableErrorCodes;
  }

  addDisableErrorCode(code: number): void {
    this._disableErrorCodes.push(code);
  }

  removeDisableErrorCode(code: number): void {
    if (this._disableErrorCodes.indexOf(code) > -1) {
      this._disableErrorCodes.splice(this._disableErrorCodes.indexOf(code), 1);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.cancelPendingRequests) {
      return EMPTY;
    }
    const isAssetsUrl = req.url.startsWith('./assets/') || req.url.startsWith('/assets/') || req.url.startsWith('assets/');
    if (isAssetsUrl || this.swUpdatesService.hasNewVersion$.getValue() === false) {
      return this.addNgswBypass(isAssetsUrl, req, next);
    } else {
      return this.swUpdatesService.hasNewVersion$.pipe(
        filter(hasNewVersion => hasNewVersion === false),
        switchMap(() => this.addNgswBypass(isAssetsUrl, req, next))
      );
    }
  }

  private addNgswBypass(isAssetsUrl: boolean, req: HttpRequest<any>, next: HttpHandler) {
    if (
      environment.ngswBypass === true &&
      !isAssetsUrl &&
      (req.url.startsWith(environment.apiUrl) ||
        req.url.startsWith(environment.api2Url) ||
        req.url.startsWith(environment.api3Url) ||
        req.url.startsWith(environment.webadminApiUrl) ||
        req.url.startsWith(environment.webadminApi2Url) ||
        req.url.startsWith(`${environment.baseUrl}/batch/`))
    ) {
      req = req.clone({ params: req.params.set('ngsw-bypass', 'true') });
    }
    if ((this.window as any).IS_E2E && userBrowserInfo.name === 'Firefox') {
      req = req.clone({ withCredentials: true });
    }
    return this.getNextRequest(next, req);
  }

  private getNextRequest(next: HttpHandler, req: HttpRequest<any>): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retryWhen(
        // internet hiba megfogasa
        genericRetryStrategy({
          watchStatusCodes: [0],
        })
      ),
      // TODO any,any nem jo!!!
      catchError<any, any>((error: RRHttpErrorResponse) => {
        if (this._disableErrorCodes.indexOf(error.status) > -1) {
          return _throw(error);
        }

        if (error instanceof MaintenanceModeError) {
          this.window.location.href = '/maintenance-mode.html';
          return of(error);
        }

        if (this.document.getElementById('page-loader') !== null) {
          this.store.dispatch({ type: '[APP] Remove app loading layout' });
        }

        const url = req.url.split('?')[0];
        if (
          req.url.startsWith('./assets/i18n') ||
          req.url.startsWith('/assets/i18n') ||
          req.url.startsWith('assets/i18n') ||
          (error.status === 401 && (url.endsWith('api-token-refresh/') || url.endsWith('logout/')))
        ) {
          return _throw(error);
        }

        const newError = handleHttpError(
          error,
          this.window,
          this.translocoService,
          this.messageDialogService,
          this.router,
          (__error: RRHttpErrorResponse, translocoService: TranslocoService) =>
            HandleErrorObject.handleErrorObject(error).subscribe(() => {
              if ([401, 405].indexOf(error.status) > -1) {
                return this.store.dispatch({ type: '[Auth API] Logout without call endpoint' });
              } else if ([404, 403].indexOf(error.status) > -1 && __error.hasMetas()) {
                // meta tag kezeles
                if (__error.hasMeta(ServerSerrorMetaEnum.INVALID_PERIOD_CONTEXT)) {
                  this.store.dispatch(new ThrowHttpErrorWithMetaAction(newError));
                  // OPEN required period context dialog
                }
              }
            })
        );

        return _throw(newError);
      })
    );
  }
}
