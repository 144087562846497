import { Location } from '@angular/common';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import {
  checkModifiedForm,
  HasPresenterSavePluginController,
  isImplementedPresenterSavePluginController,
  ViewSubmitPlugin,
} from '@roadrecord/utils';
import { SwUpdatesService } from '@roadrecord/service-worker';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, first, map, switchMap, take } from 'rxjs/operators';
import { AbstractFragmentPresenterClass } from '../presenter/abstract-fragment-presenter.class';
import { FragmentModalWrapperComponentInterface } from '../presenter/fragment-modal-wrapper-component.interface';
import { FragmentPresenterConstructor } from '../presenter/fragment-presenter.constructor';
import { FragmentLoadDialogAction } from '../state/action/fragment-load-dialog.action';
import { FragmentState } from '../state/fragment.state';
import { FragmentDialogTypeEnum } from '../state/model/fragment-dialog-type.enum';
import { PresenterConfigInterface } from './presenter-config.interface';
import { RootInjectorStore } from '@roadrecord/common/common';
import { isNil } from '@roadrecord/type-guard';

const logScopeName = 'FragmentPresenterService';

@Injectable({ providedIn: 'root' })
export class FragmentPresenterService implements OnDestroy {
  private presenters: { [s: string]: AbstractFragmentPresenterClass<any, FragmentModalWrapperComponentInterface<any>> } = {};
  private lastNavigationTrigger: 'imperative' | 'popstate' | 'hashchange';
  private readonly _config$ = new ReplaySubject<PresenterConfigInterface[]>(1);
  readonly config$ = this._config$.asObservable();
  private inited = false;
  private _firstInited = false;

  constructor(
    private store: Store,
    private matDialog: MatDialog,
    private router: Router,
    private location: Location,
    actions: Actions,
    private swUpdatesService: SwUpdatesService
  ) {
    actions.pipe(ofActionDispatched(FragmentLoadDialogAction), first()).subscribe(() => this.firstInit());
  }

  ngOnDestroy(): void {}

  firstInit(): void {
    if (this._firstInited === true) {
      return;
    }
    this._firstInited = true;

    this.swUpdatesService.hasNewVersion$
      .pipe(
        filter(hasNewVersion => hasNewVersion === false),
        switchMap(() =>
          this.store
            .select(states => {
              const appLoaded = states.applicationSettings.appLoaded;
              return !isNil(appLoaded) ? appLoaded : false;
            })
            .pipe(filter(loaded => loaded === true))
        ),
        take(1),
        switchMap(() => this.store.select(FragmentState.opened))
      )
      // this.store.select(FragmentState.opened)
      .subscribe(opened => {
        const openedKeys = Object.keys(opened);
        Object.entries(this.presenters).forEach(entry => {
          if (openedKeys.indexOf(entry[0]) === -1) {
            // ha van olyan kulcs a presenterek kozott ami nem letezik az openedbe akkor bezarjuk
            this.checkDialogMaybeOpen(undefined, entry[0]);
          }
        });

        const presentersKeys = Object.keys(this.presenters);
        Object.entries(opened).forEach(entry => {
          if (presentersKeys.indexOf(entry[0]) === -1) {
            // ha van olyan kulcs az opened kozott ami nem letezik a presenterbe akkor kinyitjuk az ablakot
            this.config$.pipe(first()).subscribe(config => {
              if (config.find(c => c.id === entry[0]) !== undefined) {
                // TODO URL check
                this.checkDialogMaybeOpen(entry[1], entry[0]);
              }
            });
          }
        });
      });

    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => (this.lastNavigationTrigger = event.navigationTrigger));
  }

  initConfig(config: PresenterConfigInterface[]): void {
    if (this.inited === false) {
      this.inited = true;
      this._config$.next(config);
    } else {
      console.warn(logScopeName, 'Duplicated init!');
    }
  }

  // TODO auto remove
  setConfigFromFeature(config: PresenterConfigInterface[]): void {
    this._config$.pipe(first()).subscribe(oldConfig => this._config$.next(oldConfig.concat(...config)));
  }

  removeConfigFromFeature(config: PresenterConfigInterface[]): void {
    this._config$.pipe(first()).subscribe(oldConfig => {
      const newConfig: PresenterConfigInterface[] = [];
      oldConfig.forEach(oldConf => {
        if (config.find(c => c.id === oldConf.id) === undefined) {
          newConfig.push(oldConf);
        }
      });
      return newConfig;
    });
  }

  private checkDialogMaybeOpen(dialogData: object, type: FragmentDialogTypeEnum | string): void {
    if (dialogData !== undefined && this.presenters[type] === undefined) {
      this.presenterFactory({
        store: this.store,
        matDialog: this.matDialog,
        dialogAreaViewContainerRef: undefined,
        fragmentType: type,
      } as FragmentPresenterConstructor<any>).subscribe(config => {
        this.presenters[type] = config;
        if (config !== undefined) {
          this.presenters[type].checkDialogMaybeOpen(dialogData);
        }
      });
    } else if (dialogData === undefined) {
      if (this.presenters[type] !== undefined) {
        const presenter: AbstractFragmentPresenterClass<any, any> = this.presenters[type];
        const hideAndDeletePresenter = () => {
          presenter.checkDialogMaybeOpen(dialogData);
          delete this.presenters[type];
        };
        if (this.lastNavigationTrigger === 'popstate' && presenter.uiSaved === false) {
          let viewSubmitPluginId = null;
          let routedDataFormCmpRef = null;
          const detailsCmpRef = (presenter.cmpRef.instance as any).details.first;
          if (isImplementedPresenterSavePluginController(detailsCmpRef)) {
            viewSubmitPluginId = ((presenter.cmpRef.instance.details.first as HasPresenterSavePluginController<
              any,
              any
            >).presenterStateController.get(ViewSubmitPlugin) as ViewSubmitPlugin<any, any>).id;
            routedDataFormCmpRef = presenter.cmpRef.instance.details.first.dataForm;
          }
          // ha back gombot nyomtak es a felulet nem mentett akkor ellenorzes kell
          checkModifiedForm({
            formModel: presenter.formValue,
            originalModel: presenter.newModel,
            routedDataFormCmpRef: viewSubmitPluginId,
            viewSubmitPluginId: routedDataFormCmpRef,
          }).subscribe(result => {
            if (result.result === true) {
              hideAndDeletePresenter();
            } else {
              this.location.forward();
              // ha ranyomott a mentes es bezarasra
              if (result.afterOkFirst === true) {
                const ngZone = RootInjectorStore.getFromRootInjector(NgZone);
                presenter.cmpRef.instance.save
                  .pipe(take(1))
                  .subscribe(() => ngZone.onStable.pipe(take(1)).subscribe(() => hideAndDeletePresenter()));
                // valamiert eltunik ezen a ponton a beallitas
                if (viewSubmitPluginId === null && routedDataFormCmpRef === null) {
                  // deprecated mode: ahol nincs RR MVP megvalositva
                  presenter.directSubmit();
                }
              }
            }
          });
        } else {
          hideAndDeletePresenter();
        }
      }
    }
  }

  /**
   * presenter gyar
   */
  private presenterFactory({
    store,
    matDialog,
    fragmentType,
  }: FragmentPresenterConstructor<any>): Observable<AbstractFragmentPresenterClass<any, any>> {
    return this.config$.pipe(
      map((config: PresenterConfigInterface[]) => {
        const foundConfig = config.find(conf => conf.id === fragmentType);
        if (foundConfig !== undefined) {
          return foundConfig.presenterFactory({
            store,
            matDialog,
            fragmentType,
            componentFactoryResolver: foundConfig.componentFactoryResolver,
            document: foundConfig.document,
            appRef: foundConfig.appRef,
            injector: foundConfig.injector,
          });
        }
        return undefined;
      }),
      first()
    );
  }
}
