import { DocumentDataCheckerActionsEnum } from '@roadrecord/document-data-checker-action/model';
import { isNil } from '@roadrecord/type-guard';

export function isDocumentDataCheckerRouteStateModel(model: any): model is DocumentDataCheckerRouteStateModel {
  return !isNil(model) && model.hasOwnProperty('action');
}

export interface DocumentDataCheckerRouteStateModel {
  action: DocumentDataCheckerActionsEnum;
}
