import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { StartData } from '../../model/start-data';
import { EndData } from '../../model/end-data';
import { isNil } from '@roadrecord/type-guard';
import moment from 'moment';
import { AbstractPeriodContextModel, PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, take } from 'rxjs/operators';
import { DocumentDataCheckerActionsEnum, isDocumentDataCheckerRouteStateModel } from '@roadrecord/document-data-checker-action/model';
import { LoadableService } from '@roadrecord/external-packages/ngx-loadable';
import { TranslocoService } from '@ngneat/transloco';
import { monthStatusListOpenWindowAction } from '../../window-content/window-content.action';
import { openMileageEditFormBottomSheet } from '../open-mileage-edit-form-bottom-sheet.function';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';

@UntilDestroy()
@Component({
  selector: 'rr-status-card',
  templateUrl: './status-card.component.html',
  styleUrls: ['./status-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusCardComponent implements OnInit, OnChanges {
  @Input() readonly layoutType: 'START' | 'END';
  @Input() readonly model: StartData | EndData;
  @Input() readonly loading: boolean;
  @Output() readonly loadingEvent = new EventEmitter<boolean>();
  @Output() readonly refresh = new EventEmitter<boolean>();

  date: Date;
  mileageAmount: number;
  readonly formatOdometer = '(ddd ddd ddd)';

  isSelfEmployed = false;

  constructor(
    private periodContextSelectors: PeriodContextStateSelectorsService<any, any>,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private matBottomSheet: MatBottomSheet,
    private loadableService: LoadableService,
    private injector: Injector,
    private translocoService: TranslocoService,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!isNil(changes['model']) && !isNil(changes['model'].currentValue)) {
      if (JSON.stringify(changes['model'].previousValue) !== JSON.stringify(changes['model'].currentValue)) {
        this.mileageAmount = this.model.mileage;

        this.date = moment(this.model.date).toDate();

        this.route.paramMap
          .pipe(
            untilDestroyed(this),
            take(1),
            map(() => window.history.state),
            filter(state => isDocumentDataCheckerRouteStateModel(state))
          )
          .subscribe(state => {
            const layoutType: 'START' | 'END' = this.route.snapshot.data.layoutType;
            if (
              (layoutType === 'START' && state.action === DocumentDataCheckerActionsEnum.MILEAGE_START__ADD_OR_MODIFY) ||
              (layoutType === 'END' && state.action === DocumentDataCheckerActionsEnum.MILEAGE_END__ADD_OR_MODIFY)
            ) {
              this.openEditFormBottomSheet();
            } else if (state.action === DocumentDataCheckerActionsEnum.FLOATING_WINDOW__MILEAGE_LIST) {
              monthStatusListOpenWindowAction(
                this.store,
                this.loadableService,
                this.injector,
                this.translocoService as any,
                this.matSnackBar
              );
            }
          });
      }
    }
  }

  switchToList(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  openEditFormBottomSheet(): void {
    openMileageEditFormBottomSheet(this.matBottomSheet, this.layoutType, this.model).subscribe(() => this.refresh.emit(true));
  }

  ngOnInit(): void {
    if (this.translocoService.getActiveLang() === 'hu-hu') {
      const periodContext = this.store.selectSnapshot<AbstractPeriodContextModel<any>>(this.periodContextSelectors.context);
      this.isSelfEmployed = this.isSelfEmployed = periodContext.vehicle.is_self_employed;
    }
  }
}
