<h2>{{ 'VEHICLE_PARTNER_JOIN.LIST.COLLECTED_DATAS.BOTTOMSHEET.TITLE' | transloco }}</h2>
<div class="scroll-panel">
  <table>
    <tbody>
      <tr *ngFor="let item of data">
        <td>
          <button
            mat-icon-button
            [matTooltip]="'VEHICLE_PARTNER_JOIN.LIST.COLLECTED_DATAS.BOTTOMSHEET.DELETE_TOOLTIP' | transloco: { name: item.name }"
            (click)="removeItem(item)"
          >
            <mat-icon svgIcon="delete"></mat-icon>
          </button>
        </td>
        <td>{{ item.first_name }} {{ item.first_name }}({{ item.email }})</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="w-100 d-flex justify-content-between">
  <button
    mat-icon-button
    (click)="onClickCancel()"
    [matTooltip]="'VEHICLE_PARTNER_JOIN.LIST.COLLECTED_DATAS.BOTTOMSHEET.BUTTONS.CANCEL_TOOLTIP' | transloco"
  >
    <mat-icon svgIcon="arrow-left"></mat-icon>
  </button>
  <button mat-raised-button color="primary" (click)="onClickSubmit()">
    {{ 'VEHICLE_PARTNER_JOIN.LIST.COLLECTED_DATAS.BOTTOMSHEET.BUTTONS.SUBMIT' | transloco }}
  </button>
</div>
