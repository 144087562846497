import { InjectionToken } from '@angular/core';
import { Masked } from 'imask';

export interface FuelingSettings {
  /**
   * arra hasznaljuk, hogy amikor a tankolast el akarjuk menteni akkor
   * az irrealisan magas ertekeket ebbol allapitjuk meg
   */
  warning: {
    quantity: { common: number; electric: number };
    cost: number;
  };
  mask: {
    cost: Masked<any>;
    quantity: Masked<any>;
    distance: Masked<any>;
    mileage: Masked<any>;
  };
}

// TODO lecserelni webpack file replacement-re
export const FUELING_SETTINGS = new InjectionToken('FUELING_SETTINGS');
