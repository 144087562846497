import { NgModule } from '@angular/core';
import { IsChargingStationTypePipe } from './is-charging-station-type.pipe';
import { IsHeadOffieOrSiteTypePipe } from './is-head-offie-or-site-type.pipe';
import { IsPartnerTypePipe } from './is-partner-type.pipe';
import { DateFormatPipe } from './date-format.pipe';

@NgModule({
  declarations: [IsPartnerTypePipe, IsChargingStationTypePipe, IsHeadOffieOrSiteTypePipe, DateFormatPipe],
  exports: [IsPartnerTypePipe, IsChargingStationTypePipe, IsHeadOffieOrSiteTypePipe, DateFormatPipe],
})
export class PartnerCommonPipeModule {}
