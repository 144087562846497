import { FinalizationOfRouteStateModel } from '../model/finalization-of-route-state.model';
import { isEmptyString, isNil, isString } from '@roadrecord/type-guard';
import moment from 'moment';

export class FinalizationOfRouteResetStateAction {
  static readonly type = '[Finalization of route Details Page] Reset state';
  readonly state: FinalizationOfRouteStateModel;

  constructor({
    state,
    autoFill = false,
    selectedDay,
  }: {
    state?: FinalizationOfRouteStateModel;
    /**
     * True eseten az uj state allapotot automatikusan toltjuk ki, viszont ilyenkor szukseges a selectedDay is!
     */
    autoFill?: boolean;
    selectedDay?: string | Date;
  }) {
    if (autoFill === false) {
      if (isNil(state)) {
        throw new Error('Not found state, when autoFill disabled');
      }
      this.state = state;
    } else {
      if (isNil(selectedDay) || isEmptyString(selectedDay)) {
        throw new Error('Not found selectedDayText, when autoFill is enabled');
      }
      this.state = {
        loading: true,
        stepDay: undefined,
        data: undefined,
        summary: undefined,
        selectedSummaryIndex: 0,
        saveSnackBar: undefined,
        dayStepperOptions: undefined,
        version: 3,
        // toDate miatt nem hasznalhato a .utc
        selectedDay: isString(selectedDay) ? moment(selectedDay).utcOffset(0, false).toDate() : selectedDay,
      };
    }
  }
}
