import { MessageDialogOptionsInterface } from './message-dialog-options.interface';
import { MessageDialogTypeEnum } from './message-dialog-type.enum';
import { PortalInjector } from '@angular/cdk/portal';

export class MessageDialogOptionsModel implements MessageDialogOptionsInterface {
  readonly id: string;
  readonly type?: MessageDialogTypeEnum;
  readonly customTypeImage?: string;
  readonly title?: string;
  readonly titleParams = {};
  readonly text?: string;
  readonly textParams = {};
  readonly translateText = true;
  readonly htmlMode = false;
  readonly enableCancel = false;
  readonly enableOk = true;
  readonly showProgressBar = false;
  readonly cancelLabel = 'COMMON.ACTION.CANCEL';
  readonly confirmLabel = 'COMMON.ACTION.OK';
  readonly enableForeverHideFeature = false;
  readonly spriteClasses?: string[];
  readonly elementRef?: any;
  readonly elementRefInjector?: PortalInjector;
  readonly showSlideToggle?: string;
  readonly afterSaveFirstButton?: { show: boolean; label?: string };

  constructor(data: MessageDialogOptionsInterface) {
    Object.assign(this, data);
  }
}
