import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { CheckModifiedManualForm, ENTITY_SERVICE_TOKEN, HttpListResponseModel } from '@roadrecord/utils';
import { GridComponent, GridRemoveStateAction } from '@roadrecord/grid';
import { TranslocoService } from '@ngneat/transloco';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VehicleAccountService } from '../vehicle-account.service';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Observable, of, timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { listColumnConfig } from './list-column.config';
import { VehicleAccountListModel } from '../model/vehicle-account-list.model';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { VehicleAccountVisibilityStatusEnum } from '../model/vehicle-account-visibility-status.enum';
import { Store } from '@ngxs/store';
import { SelectionChange } from '@angular/cdk/collections';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CollectedDataBottomSheetComponent } from '../collected-data-bottom-sheet/collected-data-bottom-sheet.component';
import { VehicleDataStore } from '../../vehicle-data.store';
import { Location } from '@angular/common';
import { VehicleAccountState } from '../state/vehicle-account.state';

@Component({
  selector: 'rr-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [VehicleAccountService, { provide: ENTITY_SERVICE_TOKEN, useExisting: VehicleAccountService }],
})
export class ListComponent extends CheckModifiedManualForm<VehicleAccountListModel[]> implements OnInit {
  displayedColumns = listColumnConfig(this);
  @ViewChild('rrGrid', { static: true })
  rrGrid: GridComponent;
  getListCallback = this.getList.bind(this);
  private isNew = false;
  private vehicleId: number;
  private loadedElements: VehicleAccountListModel[] = [];
  private disableSelectionDetect = false;
  modifyElements = [];
  disableChangeDetect = false;
  gridTitle: string;

  constructor(
    readonly translocoService: TranslocoService,
    private vehicleAccountService: VehicleAccountService,
    private messageDialogService: MessageDialogService,
    private matSnackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private store: Store,
    private router: Router,
    private matBottomSheet: MatBottomSheet,
    private location: Location
  ) {
    super();
    // direct grid state reset
    const gridId = this.router.url.split('?')[0];
    if (
      this.store.selectSnapshot<boolean>(states => states.grids.container[gridId] && states.grids.container[gridId].selected !== undefined)
    ) {
      this.store.dispatch(new GridRemoveStateAction(gridId));
    }
  }

  getList(
    database: VehicleAccountService,
    sort: Sort = { active: '', direction: 'asc' },
    page: PageEvent = {
      pageIndex: 1,
      pageSize: 99999999,
      length: 1,
    },
    simpleAllFilterValue = ''
  ): Observable<HttpListResponseModel<VehicleAccountListModel>> {
    this.disableSelectionDetect = true;
    this.rrGrid.selection.clear();
    const stream = database.getAll(sort, page, simpleAllFilterValue, this.isNew ? undefined : this.vehicleId).pipe(
      catchError(error => {
        this.disableSelectionDetect = false;
        return of(error);
      }),
      map(response => {
        response.results = response.results.map(result => {
          const foundElement = this.loadedElements.find(
            loadedElement => database.getModelIdValue(loadedElement) === database.getModelIdValue(result)
          );
          if (foundElement === undefined) {
            return result;
          }
          return foundElement;
        });
        return response;
      }),
      shareReplay(1)
    );

    return stream;
  }

  ngOnInit(): void {
    this.gridTitle = this.translocoService.translate('VEHICLE.ACCOUNTS.LIST.TITLE.DEFAULT', {
      plateNumber: this.store.selectSnapshot(VehicleAccountState.plateNumber),
    });

    if (this.route.snapshot.queryParamMap.has('isNew')) {
      this.isNew = this.route.snapshot.queryParamMap.get('isNew') === 'true';
    } else {
      this.vehicleId = +this.route.snapshot.queryParamMap.get('vehicle_id');
    }
  }

  onSelectionChanges($event: SelectionChange<any>) {
    if (this.disableSelectionDetect === true) {
      this.disableSelectionDetect = false;
      return;
    }
    $event.added.forEach(added => {
      const foundIndex = this.loadedElements.findIndex(
        loadedElement => this.vehicleAccountService.getModelIdValue(loadedElement) === this.vehicleAccountService.getModelIdValue(added)
      );
      this.loadedElements[foundIndex] = { ...this.loadedElements[foundIndex], status: VehicleAccountVisibilityStatusEnum.VISIBLE };
    });

    $event.removed.forEach(removed => {
      const foundIndex = this.loadedElements.findIndex(
        loadedElement => this.vehicleAccountService.getModelIdValue(loadedElement) === this.vehicleAccountService.getModelIdValue(removed)
      );
      this.loadedElements[foundIndex] = { ...this.loadedElements[foundIndex], status: VehicleAccountVisibilityStatusEnum.NOT_VISIBLE };
    });

    this.modifyElements = this.loadedElements.filter(loadedElement => loadedElement.status !== loadedElement.originalStatus);
  }

  openColledDataBottomSheet() {
    const bottomSheetRef = this.matBottomSheet.open(CollectedDataBottomSheetComponent, {
      panelClass: 'collected-data-bottom-sheet',
      data: [...this.modifyElements],
    });
    bottomSheetRef.afterDismissed().subscribe((datas: { data: VehicleAccountListModel[]; restore: VehicleAccountListModel[] }) => {
      if (datas !== undefined && datas.restore.length > 0) {
        datas.restore.forEach(model => {
          const selectedModel = this.rrGrid.dataSource.data.find(
            (dataSourceModel: VehicleAccountListModel) => dataSourceModel.account_id === model.account_id
          );
          if (selectedModel !== undefined) {
            if (this.rrGrid.selection.isSelected(selectedModel)) {
              this.rrGrid.selection.deselect(selectedModel);
            } else {
              this.rrGrid.selection.select(selectedModel);
            }
          }
        });
        this.cdr.detectChanges();
      }
    });
  }

  onAfterLoaded() {
    const selected = this.rrGrid.dataSource.data.filter(
      (x: VehicleAccountListModel) => x.status === VehicleAccountVisibilityStatusEnum.VISIBLE
    );
    if (selected.length > 0) {
      this.rrGrid.selection.select(...selected);
    }

    const newElements: VehicleAccountListModel[] = this.rrGrid.dataSource.data.filter(
      (dataSourceModel: VehicleAccountListModel) =>
        this.loadedElements.findIndex(
          loadedElement =>
            this.vehicleAccountService.getModelIdValue(dataSourceModel) === this.vehicleAccountService.getModelIdValue(loadedElement)
        ) === -1
    ) as VehicleAccountListModel[];
    this.loadedElements = this.loadedElements.concat(newElements);
    timer(1000).subscribe(() => (this.disableSelectionDetect = false));
    this.cdr.detectChanges();
  }

  save() {
    const changedElements = this.getChangedStatus();

    if (this.isNew) {
      this.disableChangeDetect = true;
      VehicleDataStore.INSTANCE().storeModifyAccounts(changedElements);
      this.navigateBack();
    } else {
      if (changedElements.length > 0) {
        this.vehicleAccountService.update(this.vehicleId, changedElements).subscribe(() => {
          this.disableChangeDetect = true;
          this.navigateBack();
        });
      } else {
        this.matSnackBar.open(this.translocoService.translate('COMMON.SNACKBAR.NO_CHANGES'));
        this.navigateBack();
      }
    }
  }

  private getChangedStatus() {
    return this.getChangedElements().map(loadedElement => ({ account_id: loadedElement.account_id, status: loadedElement.status }));
  }

  private getChangedElements() {
    return this.loadedElements.filter(loadedElement => loadedElement.status !== loadedElement.originalStatus);
  }

  navigateBack() {
    this.location.back();
    // this.router.navigate([BASIC_DATA_PAGE_PATH, VEHICLE_ROUTE_PATH, this.isNew ? 'new' : this.vehicleId], {
    //   /* replaceUrl: true */
    // });
  }

  get formModel(): VehicleAccountListModel[] {
    if (this.disableChangeDetect === true) {
      return [];
    }
    return this.getChangedElements();
  }

  get originalModel(): VehicleAccountListModel[] {
    return [];
  }
}
