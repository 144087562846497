import { ModuleWithProviders, NgModule } from '@angular/core';
import { UpdatePartnerStateResolverService } from './update-partner-state-resolver.service';

@NgModule()
export class UpdatePartnerStateResolverModule {
  static forChild(): ModuleWithProviders<UpdatePartnerStateResolverModule> {
    return {
      ngModule: UpdatePartnerStateResolverModule,
      providers: [UpdatePartnerStateResolverService],
    };
  }
}
