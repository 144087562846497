import { InjectionToken, Injector } from '@angular/core';
import { StorageEngine } from '@ngxs/storage-plugin';
import { Store } from '@ngxs/store';
import { WINDOW } from '@roadrecord/common/common';
import { isNil, isString } from '@roadrecord/type-guard';
import { default as jwt_decode } from 'jwt-decode';
import { timer } from 'rxjs';
import { UserModel } from './authentication/model/user.model';
import { AuthState } from './state/auth.state';

export const RRStorageRouterExcludePathsToken = new InjectionToken('RR_STORAGE_ROUTER_EXCLUDE_PATHS_TOKEN');

export class RRStorageEngine implements StorageEngine {
  private userId: string;
  private store: Store;
  private routerExcludedPaths: string[] = [];
  private readonly hostname: string;

  set user(user: UserModel) {
    this.userId = user.email;
  }

  getUserId(): string {
    return this.userId;
  }

  constructor(injector: Injector) {
    const window: Window = injector.get(WINDOW) as Window;
    this.hostname = window.location.hostname;
    timer(0).subscribe(() => {
      this.routerExcludedPaths = injector.get(RRStorageRouterExcludePathsToken) as string[];
      if (isNil(this.routerExcludedPaths)) {
        this.routerExcludedPaths = [];
      }
      this.store = injector.get(Store);
      this.store.select(AuthState.user).subscribe(user => {
        if (!isNil(user)) {
          this.userId = user.email;
        } else {
          this.userId = undefined;
        }
      });
    });
  }

  getItem(key: string): string {
    if (key !== 'auth') {
      key = this.keyPrefix(key);
    }
    if (key !== undefined) {
      const value = localStorage.getItem(`${this.hostname}_${key}`);
      if (key === 'auth' && isString(value)) {
        try {
          const user = jwt_decode(value) as UserModel;
          if (user.email !== undefined) {
            this.userId = user.email;
          }
        } catch (e) {}
      }
      return value;
    }
    // tslint:disable-next-line
    return null;
  }

  setItem(key: string, value: string | { notFoundAuthFutureValue: boolean }): void {
    let routerSave = false;
    if (key !== 'auth') {
      if (key === 'router') {
        routerSave = true;
      }
      key = this.keyPrefix(key);
    }
    if (key !== undefined && value !== JSON.stringify({ notFoundAuthFutureValue: 1 })) {
      // TODO ATNEZNI ES KIVENNI A NOT FOUND AUTH FUTURE VALUE-t
      if (typeof value === 'object' && value.notFoundAuthFutureValue === true) {
        delete value.notFoundAuthFutureValue;
      } else if (isString(value)) {
        const json = JSON.parse(value);
        if (json.notFoundAuthFutureValue !== undefined && !isNil(localStorage.getItem(`${this.hostname}_${key}`))) {
          return;
        }
        delete json.notFoundAuthFutureValue;
        value = JSON.stringify(json);
      }
      if (isString(value)) {
        if (routerSave && this.routerExcludedPaths.length > 0) {
          const routerState = JSON.parse(value);
          if (
            !isNil(routerState.state) &&
            !isNil(routerState.state.url) &&
            this.routerExcludedPaths.indexOf(routerState.state.url.split('?')[0]) > -1
          ) {
            return;
          }
        }
        localStorage.setItem(`${this.hostname}_${key}`, value);
      }
    }
  }

  keyPrefix(key: string): string {
    if (this.userId === undefined) {
      return undefined;
    }
    return `${this.userId}_${key}`;
  }

  get length(): number {
    return localStorage.length;
  }

  clear(): void {
    localStorage.clear();
  }

  key(index: number): string {
    return localStorage.key(index);
  }

  removeItem(key: string): void {
    if (key !== 'auth') {
      key = this.keyPrefix(key);
    }
    if (key !== undefined) {
      localStorage.removeItem(`${this.hostname}_${key}`);
    }
  }
}
