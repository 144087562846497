<ng-template #cmpTemplate>
  <rr-partner-details
    #details
    [hasSubmitAndNew]="false"
    [hasBackButton]="true"
    [hasSearchAddressBackButton]="true"
    [routingMode]="false"
    [dataFormShowAddTripReason]="true"
    [controlBackButton]="true"
    searchOnMapSnackbarWeRefreshListDataPosition="bottom"
    [windowEditModelId]="windowEditModelId"
    (cancel)="cancel.emit()"
    (save)="uiSave($event)"
  ></rr-partner-details>
</ng-template>
