// declare const require: any;
// import * as versionConfig from '../../version.json';
// tslint:disable-next-line
import versionConfig from '../../version.json';

export const versionHuProd: {
  name: string;
  date: string;
  dayCounter: number;
  config: {
    branchTo: string;
    versionPlaceholder: string;
  };
} = (versionConfig as { release: any[] }).release.find(release => release.name === 'hu-frontend-production');

export const versionHuFBTesthosting: {
  name: string;
  date: string;
  dayCounter: number;
  config: {
    branchTo: string;
    versionPlaceholder: string;
  };
} = (versionConfig as { release: any[] }).release.find(release => release.name === 'hu-frontend-fbtest');

export const versionHuTest: {
  name: string;
  date: string;
  dayCounter: number;
  config: {
    branchTo: string;
    versionPlaceholder: string;
  };
} = (versionConfig as { release: any[] }).release.find(release => release.name === 'hu-frontend-test');

export const versionHuStage: {
  name: string;
  date: string;
  dayCounter: number;
  config: {
    branchTo: string;
    versionPlaceholder: string;
  };
} = (versionConfig as { release: any[] }).release.find(release => release.name === 'hu-frontend-stage');

export const versionHuWebadminTest: {
  name: string;
  date: string;
  dayCounter: number;
  config: {
    branchTo: string;
    versionPlaceholder: string;
  };
} = (versionConfig as { release: any[] }).release.find(release => release.name === 'hu-webadmin-test');

export const versionHuWebadminStage: {
  name: string;
  date: string;
  dayCounter: number;
  config: {
    branchTo: string;
    versionPlaceholder: string;
  };
} = (versionConfig as { release: any[] }).release.find(release => release.name === 'hu-webadmin-stage');

export const versionHuWebadminProd: {
  name: string;
  date: string;
  dayCounter: number;
  config: {
    branchTo: string;
    versionPlaceholder: string;
  };
} = (versionConfig as { release: any[] }).release.find(release => release.name === 'hu-webadmin-production');

export const versionUsProd: {
  name: string;
  date: string;
  dayCounter: number;
  config: {
    branchTo: string;
    versionPlaceholder: string;
  };
} = (versionConfig as { release: any[] }).release.find(release => release.name === 'us-frontend-production');

export const versionUsFBTesthosting: {
  name: string;
  date: string;
  dayCounter: number;
  config: {
    branchTo: string;
    versionPlaceholder: string;
  };
} = (versionConfig as { release: any[] }).release.find(release => release.name === 'us-frontend-fbtest');

export const versionUsTest: {
  name: string;
  date: string;
  dayCounter: number;
  config: {
    branchTo: string;
    versionPlaceholder: string;
  };
} = (versionConfig as { release: any[] }).release.find(release => release.name === 'us-frontend-test');

export const versionUsStage: {
  name: string;
  date: string;
  dayCounter: number;
  config: {
    branchTo: string;
    versionPlaceholder: string;
  };
} = (versionConfig as { release: any[] }).release.find(release => release.name === 'us-frontend-stage');

export const versionUsWebadminTest: {
  name: string;
  date: string;
  dayCounter: number;
  config: {
    branchTo: string;
    versionPlaceholder: string;
  };
} = (versionConfig as { release: any[] }).release.find(release => release.name === 'us-webadmin-test');

export const versionUsWebadminStage: {
  name: string;
  date: string;
  dayCounter: number;
  config: {
    branchTo: string;
    versionPlaceholder: string;
  };
} = (versionConfig as { release: any[] }).release.find(release => release.name === 'us-webadmin-stage');

export const versionUsWebadminProd: {
  name: string;
  date: string;
  dayCounter: number;
  config: {
    branchTo: string;
    versionPlaceholder: string;
  };
} = (versionConfig as { release: any[] }).release.find(release => release.name === 'us-webadmin-production');
