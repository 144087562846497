import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { BulkEditTypeBottomSheetComponent } from './bulk-edit-type-bottom-sheet.component';

@NgModule({
  declarations: [BulkEditTypeBottomSheetComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslocoModule, MatSnackBarModule, MatTooltipModule, MatButtonToggleModule],
  exports: [BulkEditTypeBottomSheetComponent],
})
export class BulkEditTypeBottomSheetModule {}
