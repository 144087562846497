export enum AreaOfInterestEnum {
  OPEN_END_LEASING_CAR,
  OCCUPATIONAL_LEASING_CAR,
  CLOSED_END_LEASING_CAR,
  COMPANY_CAR,
  SELF_EMPLOYED_CAR,
  PRIVATE_CAR,
  OPEN_ENDED_TRUCK,
  COMPANY_TRUCK,
  FAMILY_DOCTOR,
  NOTE_TO_MYSELF,
}
