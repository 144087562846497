// tslint:disable

import { FormlyForm, FormlyFormBuilder } from '@ngx-formly/core';
import { Component, DoCheck, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

@Component({
  selector: 'rr-formly-form',
  template: `
    <formly-field
      *ngFor="let field of fields"
      hide-deprecation
      [form]="field.form"
      [options]="field.options"
      [model]="field.model"
      [field]="field"
    >
    </formly-field>
    <ng-container #content>
      <ng-content></ng-content>
    </ng-container>
  `,
  providers: [FormlyFormBuilder],
})
export class RRFormlyForm extends FormlyForm implements DoCheck, OnChanges, OnDestroy {
  ngOnChanges(changes: SimpleChanges) {
    if (changes.fields && this.form) {
      // Remove clearControl (https://github.com/ngx-formly/ngx-formly/blob/v5.12.7/src/core/src/lib/components/formly.form.ts#L113)
      this.fields = changes['fields'].currentValue;
      delete changes['fields'];
    }
    super.ngOnChanges(changes);
  }

  ngDoCheck() {
    super.ngDoCheck();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
