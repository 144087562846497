import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportPrintComponent } from './report-print/report-print.component';
import { InvalidPeriodContextGuard } from '@roadrecord/period-context/common';
import { CloseDialogsBeforeRouteGuard, PreferencesContentWindowTypeEnum } from '@roadrecord/preferences-state/common';
import { LeaveCancelGuard } from './guard/leave-cancel.guard';

const routes: Routes = [
  {
    path: '',
    component: ReportPrintComponent,
    canActivate: [CloseDialogsBeforeRouteGuard],
    canDeactivate: [InvalidPeriodContextGuard, LeaveCancelGuard],
    data: {
      closeWindowKeys: [PreferencesContentWindowTypeEnum.ALL],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [CloseDialogsBeforeRouteGuard, LeaveCancelGuard],
  exports: [RouterModule],
})
export class ReportPrintRoutingModule {}
