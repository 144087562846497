import { AfterViewInit, Component, HostBinding, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import {
  FocusInputDirective,
  globalEmailControlValidationMessages,
  slideLeftInUnathAnimation,
  slideLeftUnathOutAnimation,
  ViewportService,
} from '@roadrecord/common/common';
import { MaybeHandleHttpError } from '@roadrecord/utils';
import { rrFormErrorStateMatcher } from '@roadrecord/validating';
import { EmailValidators } from 'ngx-validators';
import { timer } from 'rxjs';
import { AuthService } from '../../../auth.service';
import { UserModel } from '../../../model/user.model';
import { resendActivationRouterStep } from './resend-activation-router.step';

@Component({
  selector: 'rr-resend-activation',
  templateUrl: './resend-activation.component.html',
  styleUrls: ['./resend-activation.component.scss'],
  providers: [rrFormErrorStateMatcher],
  animations: [slideLeftInUnathAnimation, slideLeftUnathOutAnimation],
})
export class ResendActivationComponent implements OnInit, AfterViewInit {
  @HostBinding('@enterAnimation')
  enterAnimation;
  @HostBinding('@.disabled')
  disabledAnimation = this.authService.unauthFirstAnimDisabled;
  @HostBinding('@leaveAnimation')
  leaveAnimation;
  @ViewChild('focusEmail', { static: true })
  focusEmail: FocusInputDirective;

  form: FormGroup & { submitted?: boolean };
  emailControl = new FormControl(undefined, [Validators.required, Validators.minLength(6), EmailValidators.normal]);
  emailControlValidationMessages = globalEmailControlValidationMessages;
  /**
   * sweetalert2 fix: ha a user enter-vel nyomja meg a gombot akkor a valamiert az inputon valtodik ki az event ezert
   * becsukodik es ujra kinyitodik az ablak, ezzel a flag-vel javitjuk
   * @deprecated
   */
  private nextKeyupSkip = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService<UserModel>,
    private translocoService: TranslocoService,
    private viewportService: ViewportService
  ) {}

  private initEmailControlValue(): void {
    if (this.route.snapshot.queryParams.email) {
      this.emailControl.patchValue(this.route.snapshot.queryParams.email);
    } else {
      this.router.navigate(['/']);
    }
  }

  @HostListener('@enterAnimation.done')
  onEnterDone(): void {
    this.viewportService.routerAnimation = false;
  }

  @HostListener('@enterAnimation.start')
  onLeaveStart(): void {
    this.viewportService.routerAnimation = true;
  }

  ngOnInit(): void {
    this.initEmailControlValue();

    this.form = new FormGroup({
      email: this.emailControl,
    });
  }

  resendActivation(): void {
    this.form.submitted = true;
    if (this.form.invalid) {
      return;
    }
    const formValue = this.form.value;
    this.form.disable();
    this.authService.resendActivation(formValue.email).subscribe(
      () => {
        resendActivationRouterStep.resendActivation = true;
        this.router.navigate(['/resend-activation-successful'], { queryParams: { email: formValue.email } });
      },
      (errorResponse: any) => {
        this.form.enable();
        this.nextKeyupSkip = true;
        MaybeHandleHttpError.maybeHandleHttpError(errorResponse, () => {
          this.focusEmail.focusElement(100);
          this.nextKeyupSkip = false;
        });
      }
    );
  }

  onKeyupForm($event, formElement: HTMLFormElement): boolean | void {
    $event.stopPropagation();
    $event.preventDefault();
    if (this.nextKeyupSkip === true) {
      this.nextKeyupSkip = false;
      return false;
    }
    const findedElem = Array.from(formElement.getElementsByTagName($event.target.tagName)).find(element => element === $event.target);
    if (findedElem !== undefined) {
      this.resendActivation();
    }
  }

  ngAfterViewInit(): void {
    timer(0).subscribe(() => {
      if (this.authService.unauthFirstAnimDisabled === true) {
        this.authService.unauthFirstAnimDisabled = false;
        this.disabledAnimation = this.authService.unauthFirstAnimDisabled;
      }
    });
  }
}
