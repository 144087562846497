import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isNil } from '@roadrecord/type-guard';

export class CommonValidators {
  static greaterThan(min: number): ValidatorFn {
    const fn: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || isNil(min)) {
        return undefined; // don't validate empty values to allow optional controls
      }
      const value = parseFloat(control.value);
      // Controls with NaN values after parsing should be treated as not having a
      // minimum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-min
      return !isNaN(value) && value <= min ? { greaterThan: { min, actual: control.value } } : undefined;
    };
    return fn;
  }
}

function isEmptyInputValue(value: { length: number }): boolean {
  // we don't check for string here so it also works with arrays
  return isNil(value) || value.length === 0;
}
