import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { fadeInEnter, fadeOut2XFastLeave } from '@roadrecord/animations';
import { checkPropertyChange, FocusInputDirective } from '@roadrecord/common/common';
import { FragmentDialogTypeEnum, FragmentHideDialogAction, FragmentRemoveDialogAction } from '@roadrecord/fragment-dialog';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { isBoolean, isNil, isNumeric, isString } from '@roadrecord/type-guard';
import {
  afterViewInitMethodName,
  HasAfterInitView,
  HasViewModelPlugin,
  HasViewSubmitPlugin,
  HttpListResponseModel,
  SaveModel,
} from '@roadrecord/utils';
import { rrFormErrorStateMatcher, ValidationMessageModel } from '@roadrecord/validating';
import moment, { Moment } from 'moment';
import { combineLatest, race, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, take, tap } from 'rxjs/operators';
import { FuelingModel } from '../../model/fueling.model';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { CHARGING_STATION_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG } from '@roadrecord/partner/live-auto-complete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FuelTypeEnum } from '@roadrecord/vehicle/model/common';
import { ChargingStationService } from '@roadrecord/partner/common';
import { LiveAutoCompleteComponent, liveAutoCompleteDiFactoryReturnType } from '@roadrecord/live-auto-complete';
import { PartnerListModel, PartnerModel } from '@roadrecord/partner/model';
import { FUELING_SETTINGS, FuelingSettings } from '../../fueling-settings.token';
import { DecimalPipe } from '@angular/common';
import { UpdatedMileageListAction } from '@roadrecord/mileage/state';
import { AppTypeEnum, environment } from '@roadrecord/environment';

@UntilDestroy()
@Component({
  selector: 'rr-data-form, rr-fueling-data-form',
  templateUrl: './data-form.component.html',
  styleUrls: ['./data-form.component.scss'],
  providers: [rrFormErrorStateMatcher],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInEnter, fadeOut2XFastLeave],
})
export class DataFormComponent
  implements OnInit, OnChanges, HasViewModelPlugin<FuelingModel>, HasViewSubmitPlugin<FuelingModel>, HasAfterInitView {
  @ViewChild('formTpl', { static: true })
  formTpl: TemplateRef<any>;
  @ViewChild('buttonsTpl', { static: true })
  buttonsTpl: TemplateRef<any>;
  /**
   * Reduced dataset hasznalata
   */
  @Input() smrMode: boolean;
  @Input()
  readonly hasSubmitAndNew = true;
  @Input()
  readonly isNew: boolean = true;
  @Input()
  readonly editModel: FuelingModel;
  @Input()
  readonly selectedDayText: string;
  @Input()
  readonly dialogId: string;
  @Input()
  consumption: { value: number };
  @Input()
  previous_mileage: { value: number };
  @Output()
  readonly submitForm = new EventEmitter<SaveModel<FuelingModel>>();
  @Output()
  readonly newConsumptionDataRequestSignal = new EventEmitter<{ date: Moment | Date; time: string }>();
  @Output()
  readonly deleted = new EventEmitter<void>();
  @ViewChildren('focusCost')
  focusCostDirective: QueryList<FocusInputDirective>;
  isElectricVehicleType: boolean;
  isHybridVehicleType: boolean;
  showDistanceDifferenceWarning = false;
  form: FormGroup;
  readonly timeControl = new FormControl(undefined, Validators.required);
  readonly costControl = new FormControl({ value: undefined, disabled: true }, Validators.required);
  readonly mileageControl = new FormControl({
    value: undefined,
    disabled: true,
  });
  readonly quantityControl = new FormControl({ value: undefined, disabled: true }, Validators.required);
  readonly refuelingLocationControl = new FormControl({ value: null, disabled: true }, Validators.required);
  readonly mileageValidatorMessages = [
    {
      errorKey: 'min',
      translateKey: 'FUELING.DETAILS.DATA_FORM.VALIDATION.MILEAGE.MIN',
    },
  ];
  readonly dateControl = new FormControl(undefined, Validators.required);
  readonly distanceControl = new FormControl(undefined);
  estimatedDistance: string;

  firstLoadedData = false;
  laChargingStationOptionsConfig = this._laChargingStationOptionsConfig();
  loadingNewPartner = false;
  firstFueling = true;
  recommendedMileage: number;
  private mustCalculation = false;
  lastSearchedTime: string;
  lastSearchedDate: Moment | Date;
  private firstConsumptionRequest = true;
  @ViewChildren('refuelingLocationLiveAutoComplete') readonly refuelingLocationLiveAutoComplete: QueryList<
    LiveAutoCompleteComponent<PartnerModel>
  >;
  @Input() selectedTime: string;
  @Input() disableAutoOpenFields = false;
  /**
   * Tudunk-e kalkulalni
   * #RROHU-2369
   */
  @Input() canCalculation = true;
  /**
   * Az az érték, ami az előző óra állástól számítva, a meglévő tankolásig felrözgített utat a user
   */
  @Input() calculated_distance: number | null = null;
  /**
   * Az az érték, ami az előző óraállás és a felvitt utak összege,
   * mivel a previous mileage érték az mindig tankoláskorit jelent,
   * addig az előző óraállás amúgy lehet fix km, vagy akár hóeleji/végi is
   */
  @Input() calculated_fueling_mileage: number | null = null;
  /**
   * Ez arra jo hogyha van felugro ablak mert mondjuk uj charging station-bol ter vissza akkor
   * fel tudjuk tartani a felugro ablakot
   */
  autoOpen = true;
  reduceDataset = false;
  reducedDatasetControl: FormControl;
  mileageCalculationControl = new FormControl({ value: true, disabled: true });
  private hasOneRefuelingLocation = true;
  laChargingStationValidatorMessages: ValidationMessageModel[] = this.laChargingStationOptionsConfig.validatorMessages;
  disableMileageTooltip = true;
  mileageTooltip: { text: string; params: { calculated_distance: string; calculated_fueling_mileage?: string } } | null = null;

  readonly isUsAppType = environment.appType === AppTypeEnum.US;

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private translocoService: TranslocoService,
    private matSnackBar: MatSnackBar,
    private store: Store,
    private actions$: Actions,
    readonly chargingStationService: ChargingStationService,
    private messageDialogService: MessageDialogService,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    private route: ActivatedRoute,
    @Inject(FUELING_SETTINGS) readonly fuelingSettings: FuelingSettings,
    @Inject(CHARGING_STATION_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG)
    private _laChargingStationOptionsConfig: liveAutoCompleteDiFactoryReturnType<PartnerListModel>
  ) {
    if (this.isUsAppType === true) {
      this.mileageControl.setValidators([this.mileageValidator.bind(this)]);
    } else {
      this.distanceControl.setValidators(Validators.required);
      this.mileageControl.setValidators([Validators.required, this.mileageValidator.bind(this)]);
    }
    this.getSelectedPeriodContextVehicleType();

    this.watchDistanceControlChanges();
    this.watchMileageControlChanges();
    this.watchFuelControlChanges();
    this.watchDateAndTimeControlsChanges();
    this.subscribeNewChargingStation();
    this.detectMileageListWindowEditAction();
  }

  private detectMileageListWindowEditAction() {
    this.actions$
      .pipe(ofActionSuccessful(UpdatedMileageListAction), untilDestroyed(this))
      .subscribe(() => this.fireNewConsumptionDataRequestSignal());
  }

  @Input()
  set mileageCalculationValue(value: { value: boolean }) {
    if (this.form !== undefined && isBoolean(value.value)) {
      this.form.patchValue({ mileage_calculation: value.value });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!isNil(changes['calculated_distance']) || !isNil(changes['calculated_fueling_mileage'])) {
      /**
       * 1. calculated_distance === null and calculated_fueling_mileage === null,
       * akkor nem jelenítünk meg tooltip-et
       *
       * 2. calculated_distance != null and calculated_fueling_mileage === null,
       * akkor a szöveg: "csak calculated distance van"
       *
       * 3. calculated_distance === null and calculated_fueling_mileage != null,
       * akkor nem jelenítünk meg szöveget
       *
       * 4. calculated_distance != null and calculated_fueling_mileage != null,
       * akkor a szöveg: "van mindkettő param"
       */

      if (isNil(this.calculated_distance)) {
        // 1., 3.-as eset
        this.disableMileageTooltip = true;
      } else {
        this.disableMileageTooltip = false;
        const decimalPipe = new DecimalPipe(this.translocoService.getActiveLang());
        const translatePrefix = 'FUELING.DETAILS.DATA_FORM.MILEAGE_TOOLTIP.';
        if (isNil(this.calculated_fueling_mileage)) {
          // 2.-es eset
          this.mileageTooltip = {
            text: `${translatePrefix}JUST_CALCULATE_DISTANCE`,
            params: {
              calculated_distance: decimalPipe.transform(this.calculated_distance),
            },
          };
        } else {
          // 4.-es eset
          this.mileageTooltip = {
            text: `${translatePrefix}CALCULATE_DISTANCE_AND_CALCULATED_FUELING_MILEAGE`,
            params: {
              calculated_distance: decimalPipe.transform(this.calculated_distance),
              calculated_fueling_mileage: decimalPipe.transform(this.calculated_fueling_mileage),
            },
          };
        }
      }
    }

    if (changes.isNew !== undefined) {
      if (changes.isNew.currentValue === false) {
        delete this.estimatedDistance;
        // szerkesztes mod
        // ha szerkeszt akkor az elso quantity valtozasig nem szamolunk
        this.mustCalculation = false;
        delete this.estimatedDistance;
        const subscription = this.quantityControl.valueChanges
          .pipe(
            filter(() => this.quantityControl.enabled),
            filter(() => this.previous_mileage !== undefined),
            first(),
            untilDestroyed(this)
          )
          .subscribe(value => {
            if (this.previous_mileage.value > 0) {
              // csak akkor kapcsoljuk be a szamolast ha van elozo kilometerorallas
              this.mustCalculation = true;
              this.calculateMileageAndSetControlValue();
              this.calculateEstimateDistance(value);
              subscription.unsubscribe();
            }
          });
      } else if (changes.isNew.currentValue === true) {
        // bovitjuk a validatorokat, mert lehet nincs meg toltoallomasa a rendszerben
        const refuelingLocationControlValidators = /*Validators.compose([*/ this.validateHasRefuelingLocation.bind(
          this
        ); /*, Validators.required]);*/
        this.laChargingStationValidatorMessages = [
          ...this.laChargingStationOptionsConfig.validatorMessages,
          { errorKey: 'hasNotChargingStation', translateKey: 'FUELING.DETAILS.DATA_FORM.VALIDATION.HAS_NOT_CHARGING_STATION' },
        ];
        this.refuelingLocationControl.setValidators(refuelingLocationControlValidators);
        this.refuelingLocationLiveAutoComplete.first.insideFormControl.setValidators(refuelingLocationControlValidators);
        // letiltjuk a mezoket, amig nincs date es time
        this.disableControlsWithoutDateAndTime();
        // ha uj akkor kalkulalunk, majd kesobbi szabalyok meg ezt modosithatjak!
        this.mustCalculation = true;
        this.matSnackBar.open(this.translocoService.translate('FUELING.SNACKBAR.PLEASE_SELECT_TIME'));
      }
    }
    if (changes.previous_mileage !== undefined && changes.previous_mileage.currentValue !== undefined) {
      // legeleso tankolasnal nem szamolunk, vagyis amikor meg nincs kilometerorallasa
      if (changes.previous_mileage.currentValue.value === 0) {
        this.mustCalculation = false;
        this.firstFueling = true;
        delete this.estimatedDistance;
      } else if (changes.previous_mileage.currentValue.value > 0) {
        this.mustCalculation = true;
        this.firstFueling = false;
      }
    }
    // ha a chargingStations input valtozik
    if (this.dateControl.disabled) {
      this.dateControl.enable();
    }
    if (this.timeControl.disabled) {
      this.timeControl.enable();
    }
    // if (this.refuelingLocationControl.disabled) {
    //   debugger
    //   this.refuelingLocationControl.enable();
    // }

    if (this.isNew === true && isNil(this.timeControl.value)) {
      if (isString(this.selectedTime) && this.selectedTime.length > 0) {
        this.timeControl.patchValue(this.selectedTime);
      } else {
        this.timeControl.valueChanges.pipe(untilDestroyed(this), take(1)).subscribe(() => this.form.enable());
      }
    }

    if (
      (checkPropertyChange('consumption', changes) || checkPropertyChange('previous_mileage', changes)) &&
      !isNil(changes.consumption.currentValue) &&
      !isNil(changes.previous_mileage.currentValue)
    ) {
      if (this.dateControl.disabled) {
        this.dateControl.enable({ emitEvent: false, onlySelf: true });
      }
      if (this.timeControl.disabled) {
        this.timeControl.enable({ emitEvent: false, onlySelf: true });
      }
      if (this.refuelingLocationControl.disabled) {
        this.enableControlsWithoutDateAndTime();
      }

      if (this.isNew === false && this.firstConsumptionRequest === true) {
        this.firstConsumptionRequest = false;
      } else {
        this.calculateMileageAndSetControlValue();
        this.calculateEstimateDistance(this.quantityControl.value);
      }
    }

    if (checkPropertyChange('selectedDayText', changes) && this.form !== undefined) {
      this.form.get('date').patchValue(moment(`${changes.selectedDayText.currentValue} 00:00`).toDate());
    }
  }

  getFormData() {
    return this.form.value;
  }

  onSubmit(): void {}

  /**
   * interface miatt kell, nem hasznaljuk ezen a layouton
   */
  onSubmitReset(): void {}

  calculateMileageAndSetControlValue(): void {
    if (
      this.mustCalculation === false ||
      isNil(this.quantityControl.value) ||
      this.quantityControl.value === 0 ||
      this.form.disabled ||
      this.firstLoadedData === false ||
      isNil(this.consumption.value)
    ) {
      return;
    }
    const fuelControlValue = !isNil(this.quantityControl.value) ? parseFloat(this.quantityControl.value) : 0;
    const value = Math.round(this.previous_mileage.value + (fuelControlValue / this.consumption.value) * 100);
    let result = value > 999999 ? 999999 : value;
    if (result === 0 || isNaN(result)) {
      result = undefined;
    }
    // this.mileageControl.setValue(result);
    this.recommendedMileage = result;
  }

  mileageValidator(control: AbstractControl): ValidationErrors | undefined {
    if (isNil(control.value)) {
      return undefined;
    }
    const value = parseFloat(control.value);
    let previousValue = 0;
    if (this.previous_mileage !== undefined) {
      previousValue = this.previous_mileage.value;
    }

    const decimalPipe = new DecimalPipe(this.translocoService.getActiveLang());
    // Controls with NaN values after parsing should be treated as not having a
    // minimum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-min
    return !isNaN(value) && value < previousValue
      ? {
          min: {
            min: decimalPipe.transform(previousValue + 1),
            actual: decimalPipe.transform(control.value),
          },
        }
      : undefined;
  }

  uiDelete(): void {
    this.deleted.emit();
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParamMap.get('returnFromNewChargingStation') !== null) {
      this.autoOpen = false;
    }
    this.initForm();
    this.watchValueChangesRefuelingLocationControl();
  }

  private watchValueChangesRefuelingLocationControl(): void {
    this.refuelingLocationControl.valueChanges
      .pipe(filter(v => !isNil(v)))
      .subscribe(() => this.focusCostDirective.first.focusElement(300));
  }

  onSubmitCheck(): void {
    let messageId: string;
    const extraTextParams: { [key: string]: string } = {};
    let customYesButtonLabel: string | null = null;
    let customNoButtonLabel: string | null = null;
    const value = this.form.value;
    if (
      !isNil(value.quantity) && this.isElectricVehicleType
        ? +value.quantity > this.fuelingSettings.warning.quantity.electric
        : +value.quantity > this.fuelingSettings.warning.quantity.common
    ) {
      messageId = `${this.isElectricVehicleType ? 'ELECTRIC' : 'COMMON'}.QUANTITY_GREATER_THAN`;
    }
    if (!isNil(value.cost) && +value.cost > this.fuelingSettings.warning.cost) {
      if (!isNil(messageId)) {
        // ha mind2 szabaly ervenyben van
        messageId = `${this.isElectricVehicleType ? 'ELECTRIC' : 'COMMON'}.QUANTITY_AND_COST_GREATER_THAN`;
      } else {
        // csak ez a szabaly van ervenyben
        messageId = 'COMMON.COST_GREATER_THAN';
      }
    }

    if (value.mileage < this.calculated_fueling_mileage) {
      const decimalPipe = new DecimalPipe(this.translocoService.getActiveLang());
      messageId = 'CALCULATED_FUELING_MILEAGE_GREATE_THAN_MILEAGE.CONTENT';
      extraTextParams.previous_mileage = decimalPipe.transform(this.previous_mileage.value);
      extraTextParams.calculated_distance = decimalPipe.transform(this.calculated_distance);
      extraTextParams.calculated_fueling_mileage = decimalPipe.transform(this.calculated_fueling_mileage);
      extraTextParams.mileage = decimalPipe.transform(value.mileage);
      customYesButtonLabel = 'FUELING.DETAILS.ERROR.CALCULATED_FUELING_MILEAGE_GREATE_THAN_MILEAGE.BUTTON.YES';
      customNoButtonLabel = 'FUELING.DETAILS.ERROR.CALCULATED_FUELING_MILEAGE_GREATE_THAN_MILEAGE.BUTTON.NO';
    }

    if (!isNil(messageId)) {
      const decimalPipe = new DecimalPipe(this.translocoService.getActiveLang());
      this.messageDialogService
        .openWarning({
          id: null,
          title: 'COMMON.DIALOG.TITLE.WARNING',
          text: `FUELING.DETAILS.ERROR.${messageId}`,
          textParams: { quantity: decimalPipe.transform(value.quantity), cost: decimalPipe.transform(value.cost), ...extraTextParams },
          confirmLabel: isNil(customYesButtonLabel) ? 'COMMON.ACTION.YES' : customYesButtonLabel,
          enableCancel: true,
          cancelLabel: isNil(customNoButtonLabel) ? 'COMMON.ACTION.NO' : customNoButtonLabel,
        })
        .afterClosed()
        .subscribe(result => {
          if (result.result) {
            this.onSubmit();
          }
        });
    } else {
      this.onSubmit();
    }
  }

  private subscribeNewChargingStation(): void {
    race([
      this.actions$.pipe(
        ofActionSuccessful(FragmentHideDialogAction),
        filter(action => action.dialogType === FragmentDialogTypeEnum.NEW_CHARGING_STATION)
      ),
      this.actions$.pipe(
        ofActionSuccessful(FragmentRemoveDialogAction),
        filter(action => action.dialogType === FragmentDialogTypeEnum.NEW_CHARGING_STATION)
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe((action: FragmentHideDialogAction | FragmentRemoveDialogAction) => {
        if (action instanceof FragmentHideDialogAction && !isNil(action.data)) {
          if (action.cancel === true || action.locationBack === true) {
            return;
          }
          this.loadingNewPartner = true;
          this.cdr.markForCheck();
          this.chargingStationService
            .getByIds([action.data.id])
            .pipe(map(response => (response.results.length > 0 ? response.results[0] : null)))
            .subscribe(newchargingStation => {
              this.refuelingLocationControl.patchValue(newchargingStation);
              this.loadingNewPartner = false;
              this.focusCostDirective.first.focusElement(300);
              this.updateRefuelingLocationAutoCompleteFormControlsValidators(true);
            });
        }
      });
  }

  private getSelectedPeriodContextVehicleType(): void {
    const fuelType = this.store.selectSnapshot(this.periodContextStateSelectorsService.context).vehicle.fuel_type;
    this.isElectricVehicleType = fuelType === FuelTypeEnum.ELECTRIC;
    this.isHybridVehicleType = [FuelTypeEnum.HYBRID, FuelTypeEnum.DIESEL_HYBRID].indexOf(fuelType) > -1;
  }

  private watchMileageControlChanges(): void {
    /**
     * - km ora allas erteke valtozasa eseten a tavolsag mezo erteke:
     *     - km óra aktuális értéke - előző km óra állás
     */
    this.mileageControl.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        filter(() => this.quantityControl.enabled && this.firstLoadedData)
      )
      .subscribe(mileage => {
        if (this.mileageControl.invalid) {
          return;
        }
        if (this.previous_mileage !== undefined && !isNil(mileage) && mileage > 0 && mileage !== this.previous_mileage.value) {
          this.distanceControl.patchValue(`${parseInt(mileage, undefined) - this.previous_mileage.value}`, {
            emitEvent: false,
          });
        } else {
          if (this.isUsAppType === false) {
            this.distanceControl.setValue(0);
          }
        }
        this.cdr.detectChanges();
      });
  }

  private watchDistanceControlChanges(): void {
    /**
     * - tavolsag mezo valtozasa eseten km ora allas erteke:
     *      - előző km óra állás + a tavolság mező aktuális értéke
     */
    this.distanceControl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(250),
        distinctUntilChanged(),
        filter(() => this.quantityControl.enabled && this.firstLoadedData)
      )
      .subscribe(distance => {
        if (this.previous_mileage !== undefined && !isNil(distance) && distance > 0) {
          console.log('mileage', `${this.previous_mileage.value + parseInt(distance, undefined)}`);
          this.mileageControl.patchValue(`${this.previous_mileage.value + parseInt(distance, undefined)}`, { emitEvent: false });
        }
        this.calculateDistanceDifference();
        this.cdr.detectChanges();
      });
  }

  private watchFuelControlChanges(): void {
    this.quantityControl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(250),
        distinctUntilChanged(),
        filter(() => this.quantityControl.enabled && this.consumption && isNumeric(this.consumption.value) && this.firstLoadedData)
      )
      .subscribe(fuel => {
        this.calculateMileageAndSetControlValue();
        this.calculateEstimateDistance(fuel);
        this.cdr.detectChanges();
      });
  }

  private calculateEstimateDistance(fuel): void {
    if (
      this.mustCalculation === false ||
      isNil(this.quantityControl.value) ||
      this.quantityControl.value === 0 ||
      this.firstLoadedData === false ||
      isNil(this.consumption.value)
    ) {
      return;
    }

    if (this.consumption === undefined) {
      this.estimatedDistance = `0`;
    } else if (!isNil(fuel)) {
      this.estimatedDistance = `${parseInt(`${(fuel / this.consumption.value) * 100}`, undefined)}`;
    } else {
      this.estimatedDistance = `${parseInt(`${(1 / this.consumption.value) * 100}`, undefined)}`;
    }

    this.calculateDistanceDifference();
  }

  /**
   * 10% -s kulonbseg eseten jelezni kell a usernek amennyiben nem hybrid tipusu autoja van
   * a metodus ha nem hybrid tipusu auto es van szamolva estimate distance (megteheto ut)
   * akkor kulonbseg fuggvenyeben allitja be a 'showDistanceDifferenceWarning' flag-et
   */
  private calculateDistanceDifference(): void {
    if (!this.isHybridVehicleType && this.estimatedDistance !== undefined) {
      const tenPercentDifference = (+this.estimatedDistance / 100) * 10;
      let difference = this.distanceControl.value - +this.estimatedDistance;
      if (difference < 0) {
        difference = -1 * difference;
      }
      this.showDistanceDifferenceWarning = difference >= tenPercentDifference;
    }
  }

  private watchDateAndTimeControlsChanges(): void {
    combineLatest([
      this.dateControl.valueChanges.pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        tap(() => {
          if (this.focusCostDirective !== undefined && this.focusCostDirective.length > 0) {
            this.focusCostDirective.first.focusElement(450);
          }
        })
      ),
      this.timeControl.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged()),
    ])
      .pipe(untilDestroyed(this))
      .subscribe((values: [Moment | Date, string]) => {
        if (
          (this.timeControl.invalid && Object.keys(this.timeControl.errors).length === 1 && !isNil(this.timeControl.errors.required)) ||
          isNil(values[1]) ||
          isNil(values[0])
        ) {
          return;
        }
        const date = values[0];
        const time = values[1];
        if (
          this.lastSearchedDate !== undefined &&
          moment(this.lastSearchedDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD') &&
          this.lastSearchedTime === time
        ) {
          return;
        }

        this.lastSearchedDate = date;
        this.lastSearchedTime = time;
        if (!isNil(date) && !isNil(time)) {
          if ((this.smrMode === true && this.reducedDatasetControl.value === false) || isNil(this.smrMode) || this.smrMode === false) {
            this.fireNewConsumptionDataRequestSignal();
          }
          if (isNil(this.refuelingLocationControl.value)) {
            // amig nincs auto kivalasztva addig megprobaljuk helyette
            this.refuelingLocationLiveAutoComplete.first.hasOneCheckGet(true);
          } else {
            timer(0).subscribe(() => {
              if (!isNil(this.refuelingLocationControl.value)) {
                this.focusCostDirective.first.focusElement(300);
              } else {
                if (this.disableAutoOpenFields === false) {
                  this.refuelingLocationLiveAutoComplete.first.openPanel();
                }
              }
            });
          }
        }

        this.cdr.markForCheck();
      });
  }

  private fireNewConsumptionDataRequestSignal() {
    this.newConsumptionDataRequestSignal.emit({ date: this.lastSearchedDate, time: this.lastSearchedTime });
  }

  onHasOneCheckResponse(response: HttpListResponseModel<any>) {
    if (Array.isArray(response.results)) {
      if (response.results.length === 0) {
        // nincs meg toltoallomas
        this.updateRefuelingLocationAutoCompleteFormControlsValidators(false);
      } else if (response.results.length === 1) {
        // auto kivalasztas
        this.matSnackBar.open(
          this.translocoService.translate('FUELING.SNACKBAR.AUTO_SELECT_REFUELING_LOCATION', { name: response.results[0].name })
        );
        this.focusCostDirective.first.focusElement(300);
      } else {
        if (this.disableAutoOpenFields === false) {
          this.refuelingLocationLiveAutoComplete.first.openPanel();
        }
      }
    }
  }

  private updateRefuelingLocationAutoCompleteFormControlsValidators(hasOneRefuelingLocation: boolean) {
    this.hasOneRefuelingLocation = hasOneRefuelingLocation;
    this.refuelingLocationControl.markAsTouched();
    this.refuelingLocationControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
    const liveAutoCompleteInsiderControl = this.refuelingLocationLiveAutoComplete.first.insideFormControl;
    liveAutoCompleteInsiderControl.markAsTouched();
    liveAutoCompleteInsiderControl.updateValueAndValidity({
      emitEvent: false,
      onlySelf: true,
    });
  }

  /**
   * Azt kell ellenorizni hogy legalabb egy toltoallomas van-e a rendszerben
   */
  private validateHasRefuelingLocation() {
    return this.hasOneRefuelingLocation ? null : { hasNotChargingStation: true };
  }

  /**
   * amig nincs date es time kitoltve addig tiltjuk a mezoket
   */
  private disableControlsWithoutDateAndTime() {
    if (this.refuelingLocationControl.enabled) {
      this.refuelingLocationControl.disable();
      this.costControl.disable();
      this.quantityControl.disable();
      this.mileageControl.disable();
      this.distanceControl.disable();
    }
  }

  private enableControlsWithoutDateAndTime() {
    if (this.refuelingLocationControl.disabled) {
      this.refuelingLocationControl.enable();
      this.costControl.enable();
      this.quantityControl.enable();
      this.mileageControl.enable();
      this.distanceControl.enable();
    }
  }

  private initForm(): void {
    const fields: any = {
      date: this.dateControl,
      time: this.timeControl,
      cost: this.costControl,
      quantity: this.quantityControl,
      mileage: this.mileageControl,
      refueling_location: this.refuelingLocationControl,
      distance: this.distanceControl,
      mileage_calculation: this.mileageCalculationControl,
    };
    if (this.smrMode === true) {
      this.reducedDatasetControl = new FormControl({ value: false, disabled: true });
      fields['reduced_dataset'] = this.reducedDatasetControl;
      this.reducedDatasetControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(reducedDataset => {
        this.reduceDataset = reducedDataset;
        this.cdr.detectChanges();
        if (reducedDataset === true) {
          // remove controls
          if (this.form.get('cost') !== null) {
            this.form.removeControl('cost');
            this.form.removeControl('quantity');
            this.form.removeControl('distance');
            this.form.removeControl('mileage');
            this.form.removeControl('mileage_calculation');
          }
        } else {
          // add controls
          if (this.form.get('cost') === null) {
            this.form.addControl('cost', this.costControl);
            this.form.addControl('quantity', this.quantityControl);
            this.form.addControl('distance', this.distanceControl);
            this.form.addControl('mileage', this.mileageControl);
            this.form.addControl('mileage_calculation', this.mileageCalculationControl);

            this.fireNewConsumptionDataRequestSignal();
          }
        }
      });
    }
    this.form = this.fb.group(fields);
  }

  formatPreviousMileageValue() {
    return new DecimalPipe(this.translocoService.getActiveLang()).transform(this.previous_mileage.value);
  }

  [afterViewInitMethodName]() {
    if (this.isNew) {
      this.mileageCalculationControl.disable();
      if (this.smrMode) {
        this.reducedDatasetControl.disable();
      }
    }
  }
}
