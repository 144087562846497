import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPanelComponent } from './filter-panel.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { NgOverrideModule } from '@roadrecord/ng-override';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [FilterPanelComponent],
  imports: [CommonModule, MatTooltipModule, TranslocoModule, NgOverrideModule, MatButtonModule],
  exports: [FilterPanelComponent],
})
export class FilterPanelModule {}
