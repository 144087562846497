import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsFunctionNotPurePipe } from './is-function-not-pure.pipe';
import { IsFunctionPipe } from './is-function.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [IsFunctionPipe, IsFunctionNotPurePipe],
  exports: [IsFunctionPipe, IsFunctionNotPurePipe],
})
export class IsFunctionPipeModule {}
