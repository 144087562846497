<ng-template #iconTemplate let-item="item" let-last="last">
  <mat-icon [svgIcon]="item.icon" class="light-color" mat-list-icon></mat-icon>
  <span *ngIf="!isClosed" class="menu-label" mat-line>{{ item.label | transloco }}</span> <span class="small-menu-label" mat-line></span>
  <mat-divider *ngIf="!last" inset></mat-divider>
</ng-template>

<div class="custom-area" #customArea *ngIf="customTemplateAreaComponentPortal">
  <ng-template [cdkPortalOutlet]="customTemplateAreaComponentPortal"></ng-template>
</div>
<div class="scroll-area" [ngStyle]="{ height: calcScrollAreaHeight() }" [ngClass]="{ 'is-closed': isClosed }">
  <mat-nav-list>
    <ng-container *ngIf="customBackButtonLabel">
      <a
        mat-list-item
        (click)="onClose()"
        class="wid100 close-button active"
        [matTooltip]="isClosed ? (customBackButtonLabel | transloco) : undefined"
        matTooltipPosition="right"
      >
        <mat-icon mat-list-icon svgIcon="arrow-left"></mat-icon>
        <span class="menu-label">{{ customBackButtonLabel | transloco }}</span>
      </a>
      <mat-divider></mat-divider>
    </ng-container>
    <ng-container *ngFor="let item of items; trackBy: trackByFn; last as last">
      <ng-template [ngIf]="item.outsideLink !== undefined" [ngIfElse]="itemWithRoute">
        <a
          mat-list-item
          *ngIf="item.disabled === false; else disabledItem"
          [attr.tabindex]="-1"
          [href]="item.outsideLink"
          target="_blank"
          [matTooltip]="isClosed ? (item.label | transloco) : undefined"
          matTooltipPosition="right"
          [ngClass]="item.uniqId.toLowerCase()"
        >
          <ng-container *ngTemplateOutlet="iconTemplate; context: { item: item, last: last }"></ng-container>
        </a>
        <ng-template #disabledItem>
          <mat-list-item
            class="disabled"
            [matTooltip]="'APP_LAYOUT.MENU.TOOLTIP.NOT_AVAILABLE' | transloco"
            matTooltipShowDelay="300"
            matTooltipPosition="above"
            matTooltipClass="mat-remove-margin"
            [ngClass]="{ active: item.active }"
          >
            <ng-container *ngTemplateOutlet="iconTemplate; context: { item: item, last: last }"></ng-container>
          </mat-list-item>
        </ng-template>
      </ng-template>
      <ng-template #itemWithRoute>
        <ng-template [ngIf]="item.route !== undefined" [ngIfElse]="itemWithRouterFn">
          <a
            *ngIf="item.disabled === false; else disabledItem"
            rrHoverState
            #hoverState="rrHoverState"
            mat-list-item
            [class]="item.uniqId.toLowerCase() + ' list-item'"
            [matTooltip]="isClosed ? (item.helpTooltip | transloco) : undefined"
            matTooltipPosition="right"
            [routerLink]="getRouterLink(item)"
            [ngClass]="{ active: item.active }"
            [attr.tabindex]="item.active ? 0 : -1"
            (click)="onClickItem($event, item)"
          >
            <ng-container *ngTemplateOutlet="iconTemplate; context: { item: item, last: last }"></ng-container
            ><rr-menu-item-help
              *ngIf="item?.helpTooltip?.length > 0"
              [hover]="isClosed === false && hoverState.hover"
              [helpTooltip]="item.helpTooltip"
            ></rr-menu-item-help
          ></a>
          <ng-template #disabledItem>
            <mat-list-item
              class="disabled"
              [matTooltip]="'APP_LAYOUT.MENU.TOOLTIP.NOT_AVAILABLE' | transloco"
              matTooltipShowDelay="300"
              matTooltipPosition="above"
              matTooltipClass="mat-remove-margin"
              [ngClass]="{ active: item.active }"
            >
              <ng-container *ngTemplateOutlet="iconTemplate; context: { item: item, last: last }"></ng-container>
            </mat-list-item>
          </ng-template>
        </ng-template>
        <ng-template #itemWithRouterFn>
          <a
            mat-list-item
            *ngIf="item.disabled === false; else disabledItem"
            [class]="item.uniqId.toLowerCase() + ' list-item'"
            [attr.tabindex]="item.active ? 0 : -1"
            (click)="onClickRouterFnItem($event, item)"
            [matTooltip]="isClosed ? (item.label | transloco) : undefined"
            [ngClass]="{ active: item.active }"
            matTooltipPosition="right"
          >
            <ng-container *ngTemplateOutlet="iconTemplate; context: { item: item, last: last }"></ng-container>
          </a>
          <ng-template #disabledItem>
            <mat-list-item
              class="disabled"
              [matTooltip]="'APP_LAYOUT.MENU.TOOLTIP.NOT_AVAILABLE' | transloco"
              matTooltipShowDelay="300"
              matTooltipPosition="above"
              matTooltipClass="mat-remove-margin"
              [ngClass]="{ active: item.active }"
            >
              <ng-container *ngTemplateOutlet="iconTemplate; context: { item: item, last: last }"></ng-container>
            </mat-list-item>
          </ng-template>
        </ng-template>
      </ng-template>
    </ng-container>
  </mat-nav-list>
</div>
