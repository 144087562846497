// export const IS_EDITABLE = 'MODEL__IS_EDITABLE';
//
// export interface HasEditable {
//     [IS_EDITABLE]: boolean;
// }
// TODO https://github.com/Microsoft/TypeScript/wiki/What's-new-in-TypeScript#constant-named-properties

export const IS_EDITABLE_KEY = 'is_editable';

export interface HasEditable {
  is_editable: boolean;
}
