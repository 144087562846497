import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { CanColor, CanColorCtor, mixinColor } from '@angular/material/core';
import { matDatepickerAnimations } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { CONTAINER_DATA } from './container-data.token';
import { NgxMatCalendar } from './calendar';
import { NgxMatTimepickerComponent } from './timepicker.component';
import { NgxMatDatetimePicker } from './datetime-picker.component';

// Boilerplate for applying mixins to MatDatepickerContent.
/** @docs-private */
class MatDatepickerContentBase {
  constructor(public _elementRef: ElementRef) {}
}
const _MatDatepickerContentMixinBase: CanColorCtor & typeof MatDatepickerContentBase = mixinColor(MatDatepickerContentBase);

/**
 * Component used as the content for the datepicker dialog and popup. We use this instead of using
 * NgxMatCalendar directly as the content so we can control the initial focus. This also gives us a
 * place to put additional features of the popup that are not part of the calendar itself in the
 * future. (e.g. confirmation buttons).
 * @docs-private
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ngx-mat-datetime-content',
  templateUrl: './datetime-content.component.html',
  styleUrls: ['./datetime-content.component.scss'],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: 'mat-datepicker-content',
    '[@transformPanel]': '"enter"',
    '[class.mat-datepicker-content-touch]': 'datepicker.touchUi',
  },
  animations: [matDatepickerAnimations.transformPanel, matDatepickerAnimations.fadeInCalendar],
  exportAs: 'ngxMatDatetimeContent',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:no-inputs-metadata-property
  inputs: ['color'],
})
export class NgxMatDatetimeContent<D extends Moment> extends _MatDatepickerContentMixinBase implements AfterViewInit, CanColor {
  /** Reference to the internal calendar component. */
  @ViewChild(NgxMatCalendar) _calendar: NgxMatCalendar<D>;

  /** Reference to the internal time picker component. */
  @ViewChild(NgxMatTimepickerComponent) _timePicker: NgxMatTimepickerComponent<D>;

  /** Reference to the datepicker that created the overlay. */
  datepicker: NgxMatDatetimePicker<D>;

  /** Whether the datepicker is above or below the input. */
  _isAbove: boolean;

  /** Whether or not the selected date is valid (min,max...) */
  get valid(): boolean {
    if (this.datepicker.hideTime) return this.datepicker.valid;
    return this._timePicker && this._timePicker.valid && this.datepicker.valid;
  }

  get isViewMonth(): boolean {
    if (!this._calendar || this._calendar.currentView === null) return true;
    return this._calendar.currentView === 'month';
  }

  constructor(
    elementRef: ElementRef,
    @Inject(CONTAINER_DATA) readonly containerData: { hideCalendar: boolean; okTooltip: string; autoCastMomentToString: boolean }
  ) {
    super(elementRef);
  }

  ngAfterViewInit() {
    if (this.containerData.hideCalendar === false) {
      this._calendar.focusActiveCell();
    }
  }
}
