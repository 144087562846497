import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toBoolean',
})
export class ToBooleanPipe implements PipeTransform {
  transform(value: any): any {
    return value === true || value === 1 || value === '1' || value === 'true';
  }
}
