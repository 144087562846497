export const USER_ROUTE_PATH = 'user';
export const USER_STATISTIC_ROUTE_PATH = 'user/statistic';
export const USER_LOGIN_PATH = 'login';
export const USER_PASSWORD_RESENT = 'password-reset-sent';
export const USER_FORGOT_PASSWORD = 'forgot-password';
export const USER_RESET_PASSWORD = 'reset-password';

export const FIRST_STEPS_PATH = 'first-steps';
export const FIRST_STEPS_STEPPING_PATH = 'steps';

// TODO refactoralni a neveket is a key alapjan dasherize-vel
// Pages
export const BASIC_DATA_PAGE_PATH = 'basic-data';
export const MONTH_ACTIVITIES_PAGE_PATH = 'month-activities';
// Page => basic data
export const PARTNER_ROUTE_PATH = 'partners';
export const CHARGING_STATION_ROUTE_PATH = 'charging-stations';
export const HEAD_OFFICE_OR_SITE_PATH = 'head-offices-or-sites';
export const REPORT_SITE_PATH = 'report-sites';
export const VEHICLE_ROUTE_PATH = 'vehicles';
export const VEHICLE_ACCOUNT_ROUTE_PATH = 'accounts';
export const TRIP_REASON_ROUTE_PATH = 'tripreasons';
export const DAILY_PERMANENT_DESTINATION_PATH = 'daily-permanent-destination';
// Page => month activities
export const START_MONTH_STATUS_ROUTE_PATH = 'mileages/start/status';
export const SPECIAL_DAYS_ROUTE_PATH = 'specialdays';
export const FUELING_ROUTE_PATH = 'fueling';
export const END_MONTH_STATUS_ROUTE_PATH = 'mileages/end/status';
export const RECOMMENDATION_ROUTE_PATH = 'recommendation';
export const RECOMMENDATION_SETTINGS_ROUTE_PATH = 'recommendation/settings';
export const FINALIZATION_OF_ROUTE_ROUTE_PATH = 'finalization-of-route';
export const FINALIZATION_OF_ROUTE_IMPORT_ROUTE_PATH = 'finalization-of-route/import';
export const FUELING_IMPORT_ROUTE_PATH = 'fueling/import';
export const EXPENSE_ROUTH_PATH = 'expense';
export const MILEAGE_ROUTE_PATH = 'mileages';
export const DEFAULT_ROUTE_PATH = `${BASIC_DATA_PAGE_PATH}/${PARTNER_ROUTE_PATH}`;
// Page => Partner->Import
export const PARTNER_IMPORT_ROUTE_PATH = 'import';
export const PARTNER_IMPORT_FULL_ROUTE_PATH = `${PARTNER_ROUTE_PATH}/${PARTNER_IMPORT_ROUTE_PATH}`;
export const PARTNER_DISTANCE_ROUTE_PATH = 'partner-distance';
export const PARTNER_ANONYM_RENAME_ROUTE_PATH = 'rename-anonyms';
export const PARTNER_ANONYM_RENAME_FULL_ROUTE_PATH = `${BASIC_DATA_PAGE_PATH}/${PARTNER_ROUTE_PATH}/${PARTNER_ANONYM_RENAME_ROUTE_PATH}`;
export const REPORT_PRINT_PATH = 'report-print';
export const FUEL_PRICE_ROUTE_PATH = 'fuel-price';
// Page => Profile->Company user
export const CHANGE_PASSWORD = 'change-password';
export const SUBSCRIPTION_DATA = 'subscription-data';
export const COMPANY_MEMBER = 'company/member';
export const COMPANY_MEMBER_RESET_PASSWORD = 'make-password';
export const STANDARD_MILEAGE_RATE_ROUTE_PATH = 'standard-mileage-rate';
export const APPLICATION_SETTINGS = 'application-settings';
export const TRIAL_TIME_ROUTE_PATH = 'trial-time';
export const TRIAL_TIME_EXPIRED_ROUTE_PATH = 'expired';
export const TRIAL_TIME_AUTO_SUBSCRIBE_ROUTE_PATH = 'auto-subscribe';
export const PRICE_SITE_PATH = 'price';
export const VEHICLE_PARTNER_JOIN_PATH = 'vehicle-partner-join';
