import { FragmentDialogTypeEnum } from '../model/fragment-dialog-type.enum';
import { DialogOptionsModel } from '@roadrecord/dialog';

/**
 * Ablak megjelenitesehez kell
 */
export class FragmentShowDialogAction<DATATYPE> {
  static readonly type = '[Fragment] Show dialog';

  /*
  constructor(readonly dialogType: FragmentDialogTypeEnum | string, readonly data?: DATATYPE, readonly cmpData?: object) {}
*/
  constructor(
    readonly dialogType: FragmentDialogTypeEnum | string,
    readonly data?: DATATYPE & Pick<DialogOptionsModel, 'showTopRightCloseButton'>,
    readonly cmpData?: object
  ) {}
}
