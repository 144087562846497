import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { FirstStepsComponentTypeEnum } from '../../model/first-steps-component-type.enum';
import { FirstStepsNextStepModel } from '../../model/first-steps-next-step.model';
import { StepperConfigModel } from '../../model/stepper-config.model';
import { NextStepConfig } from '../../next-step.config';

export let firstStepsSteppingComponentCurrentStep: { step: FirstStepsNextStepModel } = { step: undefined };

@Component({
  selector: 'rr-stepping',
  templateUrl: './stepping.component.html',
  styleUrls: ['./stepping.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SteppingComponent {
  constructor(private readonly cdr: ChangeDetectorRef) {
    this.stepControl.setErrors({ error: true });
  }

  @Input()
  set currentStep(value: FirstStepsNextStepModel) {
    firstStepsSteppingComponentCurrentStep.step = value;
    this.selectedIndex = value.step;
    this.currentStepViewModel = value;
    this.cdr.markForCheck();
    if (this.matHorizontalStepperQueryList !== undefined) {
      (this.matHorizontalStepperQueryList.first as any)._updateSelectedItemIndex(value.step);
    }
  }
  @Input()
  config: StepperConfigModel;
  @Output()
  readonly nextStep = new EventEmitter<NextStepConfig>();
  readonly FirstStepsComponentTypeEnum = FirstStepsComponentTypeEnum;
  readonly stepControl = new FormGroup({});
  selectedIndex: number;
  currentStepViewModel: FirstStepsNextStepModel;

  @ViewChildren(MatHorizontalStepper)
  matHorizontalStepperQueryList: QueryList<MatHorizontalStepper>;

  onSelectionChange($event: StepperSelectionEvent): void {
    if ($event.selectedIndex === this.currentStepViewModel.step) {
      this.stepControl.setErrors({ error: true });
    }
  }
  onSaveStep(layoutName: string): void {
    this.stepControl.setErrors(undefined);
    this.cdr.markForCheck();

    this.nextStep.emit({ layoutName: 'AUTODETECT' });
  }

  onSkipStep(layoutName: string) {
    this.nextStep.emit({ skip: true, layoutName });
  }
}
