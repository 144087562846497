import { Directive, OnInit, Type } from '@angular/core';
import {
  commonHttpStreamErrorHandler,
  getSearchMissingFieldAndShowNotificationWithCustomBase,
  SearchMissingFieldAndShowNotificationBase,
  SearchMissingFieldAndShowNotificationConstructorParams,
  SearchMissingFieldAndShowNotificationCtor,
  SearchMissingFieldAndShowNotificationFields,
} from '@roadrecord/utils';
import { VehicleHuModel } from '@roadrecord/vehicle/model/hu';
import { Constructor } from '@roadrecord/common/common';
import { Observable } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { isNil } from '@roadrecord/type-guard';
import { Notification, NotificationsService } from '@roadrecord/external-packages/angular2-notifications';
import { ActivatedRoute } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { searchMissingFieldTranslate } from './search-missing-field.config';
import { VehicleService } from '@roadrecord/vehicle/service/common';
import { VehicleRelatedRemoteModel } from '@roadrecord/vehicle/model/common';

// importtal szoktuk hasznalni!

export interface DetailsCalendarAverageFuelBottomSheetCommon extends SearchMissingFieldAndShowNotificationFields<VehicleHuModel> {}

export type DetailsCalendarAverageFuelBottomSheetCommonCtor = Constructor<DetailsCalendarAverageFuelBottomSheetCommon>;

export interface DetailsCalendarAverageFuelBottomSheetCommonConstructorParams
  extends SearchMissingFieldAndShowNotificationConstructorParams {
  readonly vehicleService: VehicleService<any>;
}

export function mixinDetailsCalendarAverageFuelBottomSheetCommon<
  T extends Constructor<SearchMissingFieldAndShowNotificationConstructorParams>
  // & Constructor<DetailsCalendarAverageFuelBottomSheetCommonConstructorParams>
>(base: T): SearchMissingFieldAndShowNotificationCtor<any> & DetailsCalendarAverageFuelBottomSheetCommonCtor & T {
  @Directive() /* TODO ng 11-ben mar lehet nem kell, de most nem talalja meg, attol fuggetlenul hogy meg van adva os interface-kben */
  class Mixin
    extends ((base as unknown) as SearchMissingFieldAndShowNotificationCtor<VehicleHuModel> &
      Constructor<SearchMissingFieldAndShowNotificationConstructorParams> &
      Constructor<DetailsCalendarAverageFuelBottomSheetCommonConstructorParams>)
    implements OnInit {
    constructor(...args: any[]) {
      super(...args);
    }

    searchMissingFieldAndShowNotification__BottomSheetOnSubmit(formValue: VehicleHuModel): Observable<any> {
      return this.vehicleService.patch(
        this.vehicleService.getModelIdValue(this.searchMissingFieldAndShowNotification__OriginalData.data),
        formValue
      );
    }

    searchMissingFieldAndShowNotification__GetData(): Observable<any> {
      return this.route.data.pipe(untilDestroyed(this)).pipe(
        map(routeData => {
          if (
            /* #RROHU-1994 4-es pont */
            !isNil(routeData.periodContextVehicle) &&
            ((routeData.periodContextVehicle.is_basic_norm === true && isNil(routeData.periodContextVehicle.average_fuel)) ||
              (routeData.periodContextVehicle.is_basic_norm === false && isNil(routeData.periodContextVehicle.consumption_flat_rate)))
          ) {
            return { data: routeData.periodContextVehicle };
          } else {
            return null;
          }
        })
      );
    }

    searchMissingFieldAndShowNotification__OpenBottomSheet(model: { data: VehicleHuModel } & any) {
      ((this.vehicleService as unknown) as { getVehiclesRelated(): Observable<VehicleRelatedRemoteModel> })
        .getVehiclesRelated()
        .subscribe(
          vehicleRelated => super.searchMissingFieldAndShowNotification__OpenBottomSheet({ ...model, vehicleRelated }),
          commonHttpStreamErrorHandler()
        );
    }

    ngOnInit(): void {
      super.ngOnInit();
    }
  }

  return (Mixin as unknown) as SearchMissingFieldAndShowNotificationCtor<VehicleHuModel> &
    DetailsCalendarAverageFuelBottomSheetCommonCtor &
    T;
}

// tslint:disable-next-line:class-name
class _BASE extends SearchMissingFieldAndShowNotificationBase {
  constructor(
    readonly vehicleService: VehicleService<VehicleHuModel>,
    searchMissingFieldAndShowNotification__BottomSheetClassRef: Type<any>,
    notificationSettingsOverride: Partial<Notification>,
    route: ActivatedRoute,
    notificationsService: NotificationsService,
    bottomSheet: MatBottomSheet
  ) {
    super(
      searchMissingFieldAndShowNotification__BottomSheetClassRef,
      notificationSettingsOverride,
      route,
      notificationsService,
      bottomSheet
    );
  }
}

export const BASE = mixinDetailsCalendarAverageFuelBottomSheetCommon(
  getSearchMissingFieldAndShowNotificationWithCustomBase<VehicleHuModel>(_BASE, searchMissingFieldTranslate)
) as SearchMissingFieldAndShowNotificationCtor<VehicleHuModel> & typeof _BASE;
