import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

export function numberCheckMethod(
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  route: ActivatedRoute,
  router: Router,
  paramName: string
): Observable<boolean> | Promise<boolean> | boolean {
  const paramValue = next.paramMap.get(paramName);
  if (isNaN(parseInt(paramValue, undefined))) {
    const redirectUrl = state.url
      .split('/')
      .filter(urlFragment => urlFragment.length > 0)
      .slice(0, -1);
    router.navigate([`/${redirectUrl.join('/')}`]);
    return false;
  }
  return true;
}
