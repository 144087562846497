import { FragmentDialogTypeEnum } from '../model/fragment-dialog-type.enum';

/**
 * fragment state hasznalja belsoleg
 */
export class FragmentRemoveDialogAction {
  static readonly type = '[Fragment] Remove dialog';

  constructor(readonly dialogType: FragmentDialogTypeEnum | string) {}
}
