import { Action, State, StateContext } from '@ngxs/store';
import { asapScheduler, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from '../authentication/auth.service';
import { UserModel } from '../authentication/model/user.model';
import { OldRegistrationErrorAction } from './action/register/old-registration-error.action';
import { OldRegistrationSuccessAction } from './action/register/old-registration-success.action';
import { OldRegistrationAction } from './action/register/old-registration.action';
import { OldRegistrationLoadingAction } from './action/register/old-registration-loading.action';
import { LoginSuccessAction } from './action/login/login-success.action';
import { RegistrationAction } from './action/register/registration.action';
import { RegistrationErrorAction } from './action/register/registration-error.action';
import { RegistrationLoadingAction } from './action/register/registration-loading.action';
import { RegistrationSuccessAction } from './action/register/registration-success.action';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Injectable, Injector } from '@angular/core';
import { USRegistrationAction } from './action/register/us-registration.action';

@State<{ loading: boolean; version: number }>({
  name: 'registerPage',
  defaults: { loading: false, version: 3 },
})
@Injectable()
export class RegistrationState {
  constructor(private readonly authService: AuthService<UserModel>, private injector: Injector) {}

  private getGoogleTagManager() {
    return this.injector.get(GoogleTagManagerService, { pushTag: (x: any) => {} });
  }

  @Action(USRegistrationAction)
  usRegistration(
    { dispatch, patchState }: StateContext<{ loading: boolean; version: number }>,
    payload: USRegistrationAction
  ): Observable<any> {
    patchState({ loading: true });
    return this.authService.registerV3(payload.user, payload.registration_type).pipe(
      tap((response: { token: string }) => {
        dispatch(new LoginSuccessAction(response.token));
        asapScheduler.schedule(() => dispatch(new RegistrationSuccessAction(payload.user.email)));
      }),
      catchError(error => {
        asapScheduler.schedule(() => dispatch(new RegistrationErrorAction(error)));
        return throwError(error);
      })
    );
  }

  @Action(RegistrationAction)
  registration(
    { dispatch, patchState }: StateContext<{ loading: boolean; version: number }>,
    payload: RegistrationAction
  ): Observable<any> {
    patchState({ loading: true });
    return this.authService.registerV2(payload.user).pipe(
      tap((response: { token: string }) => {
        dispatch(new LoginSuccessAction(response.token));
        asapScheduler.schedule(() => dispatch(new RegistrationSuccessAction(payload.user.email)));
      }),
      catchError(error => {
        asapScheduler.schedule(() => dispatch(new RegistrationErrorAction(error)));
        return throwError(error);
      })
    );
  }

  @Action(RegistrationErrorAction)
  registrationError({ patchState }: StateContext<{ loading: boolean; version: number }>): void {
    patchState({ loading: false });
  }

  @Action(RegistrationLoadingAction)
  registrationLoading({ patchState }: StateContext<{ loading: boolean; version: number }>, action: RegistrationLoadingAction) {
    patchState({ loading: action.loading });
  }

  @Action(OldRegistrationAction)
  oldRegistration(
    { dispatch, patchState }: StateContext<{ loading: boolean; version: number }>,
    payload: OldRegistrationAction
  ): Observable<any> {
    patchState({ loading: true });
    return this.authService.register(payload.user).pipe(
      tap((response: { token: string }) => {
        dispatch(new LoginSuccessAction(response.token));
        asapScheduler.schedule(() => dispatch(new OldRegistrationSuccessAction(payload.user.email)));
      }),
      catchError(error => {
        asapScheduler.schedule(() => dispatch(new OldRegistrationErrorAction(error)));
        return throwError(error);
      })
    );
  }

  @Action(OldRegistrationErrorAction)
  oldRegistrationError({ patchState }: StateContext<{ loading: boolean; version: number }>, action: OldRegistrationErrorAction): void {
    patchState({ loading: false });
  }

  @Action(OldRegistrationLoadingAction)
  oldRegistrationLoading({ patchState }: StateContext<{ loading: boolean; version: number }>, action: OldRegistrationLoadingAction) {
    patchState({ loading: action.loading });
  }
}
