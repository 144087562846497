import { Pipe, PipeTransform } from '@angular/core';
import { HashMap } from '@ngneat/transloco/lib/types';
import { Translate } from './translate.type';
import { isFunction, isNil } from '@roadrecord/type-guard';

@Pipe({
  name: 'getTranslateParams',
  pure: false,
})
export class GetTranslateParamsPipe implements PipeTransform {
  transform(value?: Translate): HashMap | undefined {
    if (isNil(value)) {
      return undefined;
    }
    if (isFunction(value.translateParams)) {
      return value.translateParams();
    }
    return value.translateParams;
  }
}
