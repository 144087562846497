import { Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { openWindowAction, PreferencesContentWindowTypeEnum } from '@roadrecord/preferences-state/common';
import { LoadableService } from '@roadrecord/external-packages/ngx-loadable';

export const monthStatusListOpenWindowAction = (
  store: Store,
  loadableService: LoadableService,
  injector: Injector,
  translocoService: TranslocoService,
  matSnackBar: MatSnackBar
) =>
  openWindowAction(
    store,
    loadableService,
    injector,
    translocoService,
    matSnackBar,
    'mileage-list-window-content',
    PreferencesContentWindowTypeEnum.MONTH_STATUS_LIST,
    'MILEAGE.LIST_WINDOW_CONTENT.HEADER_TITLE',
    {
      height: '480px',
      minWidth: '600px',
      rzHandles: 'n,e,s,w,se,sw,ne,nw',
    },
    false
  );
