<ng-template #passwordSuffixTpl>
  <button tabindex="-1" type="button" mat-icon-button matSuffix (click)="changeVisibilityPassword()" rrPreventClick>
    <mat-icon
      class="visible-icon"
      [matTooltip]="(!visiblePassword ? 'COMMON.TOOLTIP.PASSWORD_SHOW' : 'COMMON.TOOLTIP.PASSWORD_HIDE') | transloco"
      matTooltipPosition="left"
      [svgIcon]="!visiblePassword ? 'eye' : 'eye-off'"
    >
    </mat-icon>
  </button>
</ng-template>
