import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

@Injectable()
export class HeaderLangCodeInterceptorService implements HttpInterceptor {
  constructor(private translocoService: TranslocoService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(
      req.clone({
        setHeaders: {
          lang: this.translocoService.getActiveLang(),
        },
      })
    );
  }
}
