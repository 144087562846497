export interface RegisterSocialModel {
  email?: string;
  first_name: string;
  last_name: string;
  company_name: string;
  phone_number: string;
  area_of_interest: number;
  is_magazine: boolean;
  password: string;
  confirmed_password: string;
}

export enum RegistrationEnumType {
  REG_TYPE_A = 'REG_TYPE_A',
  REG_TYPE_B = 'REG_TYPE_B',
}
