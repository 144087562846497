import { Pipe, PipeTransform } from '@angular/core';
import { isBoolean } from '@roadrecord/type-guard';

@Pipe({
  name: 'isBoolean',
})
export class IsBooleanPipePipe implements PipeTransform {
  transform(value: any): any {
    return isBoolean(value);
  }
}
