import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { IsNumericModule, ToNumberModule, TwoWaySwitchFormControlModule } from '@roadrecord/common/common';
import { ProgressWhenFirstLoadingModule } from '@roadrecord/progress-when-first-loading';
import { FormModule } from '@roadrecord/utils';
import { ValidatingModule } from '@roadrecord/validating';
import { DataFormComponent } from './data-form/data-form.component';
import { DetailsRoutingModule } from './details-routing.module';
import { DetailsComponent } from './details.component';
import { SecureDeleteDialogModule } from '@roadrecord/secure-delete-dialog';
import { DynamicFormModule } from '@roadrecord/dynamic-form';

@NgModule({
  imports: [
    CommonModule,
    DetailsRoutingModule,

    ReactiveFormsModule,

    FlexLayoutModule,

    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatSnackBarModule,

    ValidatingModule,
    FormModule,
    ProgressWhenFirstLoadingModule,

    TranslocoModule,
    MatIconModule,
    MatSelectModule,
    ValidatingModule,
    IsNumericModule,
    TwoWaySwitchFormControlModule,
    ToNumberModule,
    SecureDeleteDialogModule,
    DynamicFormModule,
  ],
  declarations: [DetailsComponent, DataFormComponent],
})
export class DetailsModule {}
