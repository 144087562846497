import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function readIdParam(route: ActivatedRoute): Observable<number> {
  return new Observable<number>(observer =>
    route.paramMap
      .pipe(
        map((params: ParamMap) => {
          const id = params.get('id');
          if (id !== undefined && !isNaN(parseInt(id, undefined))) {
            return parseInt(id, undefined);
          }
          return undefined;
        })
      )
      .subscribe(v => (observer.next(v), observer.complete()))
  );
}
