import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { FIRST_STEPS_PATH, ViewportService } from '@roadrecord/common/common';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { FirstStepsState } from '../state/first-steps.state';
import { isNil } from '@roadrecord/type-guard';

@Injectable({ providedIn: 'root' })
export class MustFirstStepGuard implements CanLoad, CanActivateChild {
  constructor(private readonly router: Router, private readonly viewportService: ViewportService, private readonly store: Store) {}

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }

  private check(): Observable<boolean> {
    return this.store.select(FirstStepsState.finished).pipe(
      filter(state => !isNil(state)),
      map(state => {
        if (state === false && !this.viewportService.isMobile) {
          this.router.navigate(['/', FIRST_STEPS_PATH]);
          return false;
        } else {
          return true;
        }
      }),
      take(1)
    );
  }
}
