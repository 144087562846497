<ng-container [ngSwitch]="orderStep">
  <ng-container *ngSwitchCase="PriceStepEnum.PACKAGE_LIST">
    <rr-price-list
      [priceList]="priceList"
      [loading]="loading$ | async"
      [isBasicPrice]="isBasicPrice"
      [showInStepper]="showInStepper"
      (navigateEvent)="navigateEvent($event)"
      (backToStepper)="onBackToStepper()"
    >
    </rr-price-list>
  </ng-container>

  <ng-container *ngSwitchCase="PriceStepEnum.ORDER_FORM">
    <rr-order-form
      [priceList]="priceList"
      [isBasicPrice]="isBasicPrice"
      [showInStepper]="showInStepper"
      [selectedPricePackage]="selectedPricePackage"
      [isOnSale]="isOnSale"
      (navigateEvent)="navigateEvent($event)"
    >
    </rr-order-form>
  </ng-container>

  <!--
  <ng-container *ngSwitchCase="PriceStepEnum.ORDER_SUMMARY">
    <rr-order-summary
      [orderSummaryInformation]="orderSummaryInformation"
      [selectedPricePackage]="selectedPricePackage"
      (navigateEvent)="navigateEvent($event)"
    >
    </rr-order-summary>
  </ng-container>
-->
</ng-container>
