import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridModule } from '@roadrecord/grid';
import { BulkEditTripReasonBottomSheetModule } from './bulk-edit-trip-reason-bottom-sheet/bulk-edit-trip-reason-bottom-sheet.module';
import { BulkEditTypeBottomSheetModule } from './bulk-edit-type-bottom-sheet/bulk-edit-type-bottom-sheet.module';
import { ListComponent } from './list.component';

@NgModule({
  imports: [CommonModule, GridModule, BulkEditTripReasonBottomSheetModule, BulkEditTypeBottomSheetModule],
  declarations: [ListComponent],
})
export class ListModule {}
