<rr-company-member-data-form
  #dataForm
  (submitForm)="onSubmit($event)"
  (delete)="onDelete($event)"
  [editModel]="editModel$ | async"
  [isNew]="isNew$ | async"
  [hasSubmitAndNew]="hasSubmitAndNew"
></rr-company-member-data-form>

<div class="center-center-wrapper">
  <rr-progress-when-first-loading>
    <mat-card>
      <mat-progress-bar color="warn" mode="indeterminate" *ngIf="loading$ | async"></mat-progress-bar>
      <mat-card-header>
        <mat-card-title>{{
          ((isNew$ | async) === true ? 'COMPANY_MEMBER.DETAILS.TITLE.NEW' : 'COMPANY_MEMBER.DETAILS.TITLE.MODIFY') | transloco
        }}</mat-card-title>
        <ng-container *ngIf="(editModel$ | async)?.status === CompanyUserStatusEnum.INVITED">
          <span class="flex-spacer"></span>
          <div class="mat-chip">{{ 'COMPANY_MEMBER.DETAILS.SENDED_EMAIL' | transloco }}</div>
        </ng-container>
      </mat-card-header>
      <mat-card-content> <ng-container *ngTemplateOutlet="dataForm.formTpl"></ng-container> </mat-card-content>
      <mat-card-actions>
        <button
          type="button"
          mat-icon-button
          (click)="onBack($event)"
          matTooltipPosition="right"
          matTooltip="{{ 'COMMON.ACTION.TOOLTIP.CANCEL' | transloco }}"
        >
          <mat-icon svgIcon="arrow-left"></mat-icon>
        </button>
        <span class="flex-spacer"></span>
        <ng-container *ngTemplateOutlet="dataForm.buttonsTpl"></ng-container>
      </mat-card-actions>
    </mat-card>
  </rr-progress-when-first-loading>
</div>
