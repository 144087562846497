<ng-container *ngIf="editMode === false || disableEdit === true; else editTpl">
  <ng-container *ngIf="config.contentEditCellTemplateRefIndex !== undefined; else normalTpl">
    <ng-container
      [ngTemplateOutlet]="editCellsTemplateRef[config.contentEditCellTemplateRefIndex]"
      [ngTemplateOutletContext]="{ $implicit: editModel }"
    ></ng-container>
  </ng-container>
  <ng-template #normalTpl>
    {{ text }}
  </ng-template>
</ng-container>
<ng-template #editTpl>
  <ng-container [ngSwitch]="config.inputType" *ngIf="control !== undefined && parentFormGroup?.get(config.name) !== null">
    <ng-container *ngSwitchCase="'TEXT'" [formGroup]="parentFormGroup">
      <mat-form-field
        floatLabel="never"
        [hideRequiredMarker]="config?.validators === undefined || config?.validators?.length === 0"
        class="input"
      >
        <input
          matInput
          type="text"
          [placeholder]="config.placeholder | transloco"
          [formControl]="control"
          [required]="!(config?.validators === undefined || config?.validators?.length === 0)"
          *ngIf="config?.textMask | isNil; else textMaskTpl"
        />
        <button
          *ngIf="config?.clearable"
          type="button"
          matSuffix
          mat-icon-button
          class="clear-button small d-inline-block"
          tabindex="-1"
          rrPreventClick
          (click)="control.setValue('')"
        >
          <mat-icon svgIcon="close" class="small"></mat-icon>
        </button>

        <ng-template #textMaskTpl>
          <input
            matInput
            type="text"
            [placeholder]="config.placeholder | transloco"
            [formControl]="control"
            [required]="!(config?.validators === undefined || config?.validators?.length === 0)"
            [imask]="{ mask: getTextMask(config) }"
            unmask="typed"
          />
        </ng-template>
        <span matSuffix *ngIf="config?.suffix">{{ config.suffix | transloco }}</span>
        <span matPrefix *ngIf="config?.prefix">{{ config.prefix | transloco }}&nbsp;</span>
        <mat-error>
          <rr-validation-messages [errors]="control?.errors" [messages]="config?.validatorMessages"></rr-validation-messages>
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'NUMBER'" [formGroup]="parentFormGroup">
      <mat-form-field floatLabel="never" [hideRequiredMarker]="config?.validators === undefined || config?.validators?.length === 0">
        <input
          matInput
          type="number"
          [placeholder]="config.placeholder | transloco"
          [formControl]="control"
          [required]="!(config?.validators === undefined || config?.validators?.length === 0)"
        />
        <span matSuffix *ngIf="config?.suffix">{{ config.suffix | transloco }}</span>
        <span matPrefix *ngIf="config?.prefix">{{ config.prefix | transloco }}&nbsp;</span>
        <mat-error>
          <rr-validation-messages [errors]="control?.errors" [messages]="config?.validatorMessages"></rr-validation-messages>
        </mat-error>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="'LIVEAUTOCOMPLETE'" [formGroup]="parentFormGroup">
      <rr-live-auto-complete
        formFieldClass="live-autocomplete"
        floatLabel="never"
        [hideRequiredMarker]="config?.validators === undefined || config?.validators?.length === 0"
        [optionsConfig]="config.meta.liveAutoCompleteOptionsConfig"
        [database]="config.meta?.liveAutoDatabase"
        [databaseCallback]="config.meta?.databaseCallback"
        hasPrefix="true"
        [formControlRef]="control"
        [hasNewButton]="config.meta?.hasNewButton"
        [placeholder]="config.placeholder"
        [required]="!(config?.validators === undefined || config?.validators?.length === 0)"
        [name]="config.name"
        [validationMessages]="config?.validatorMessages"
        [hasActionsMenu]="false"
      ></rr-live-auto-complete>
    </ng-container>

    <ng-container *ngSwitchCase="'DATEPICKER'" [formGroup]="parentFormGroup">
      <mat-form-field
        floatLabel="never"
        [hideRequiredMarker]="config?.validators === undefined || config?.validators?.length === 0"
        class="input"
      >
        <input
          matInput
          readonly
          [max]="config?.max"
          [min]="config?.min"
          [formControl]="control"
          [matDatepicker]="picker"
          (click)="picker.open()"
          [placeholder]="config.placeholder | transloco"
          [required]="!(config?.validators === undefined || config?.validators?.length === 0)"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error>
          <rr-validation-messages [errors]="control?.errors" [messages]="config?.validatorMessages"></rr-validation-messages>
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'SELECT'" [formGroup]="parentFormGroup">
      <mat-form-field
        floatLabel="never"
        [hideRequiredMarker]="config?.validators === undefined || config?.validators?.length === 0"
        class="input"
      >
        <mat-select [formControl]="control" [required]="!(config?.validators === undefined || config?.validators?.length === 0)">
          <mat-option *ngFor="let item of config?.meta?.selectData" [value]="item">
            {{ item }}
          </mat-option>
        </mat-select>
        <mat-error>
          <rr-validation-messages [errors]="control?.errors" [messages]="config?.validatorMessages"></rr-validation-messages>
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'FILEUPLOAD'" [formGroup]="parentFormGroup">
      <ng-container *ngIf="config.contentUploadFileCellsTemplateRefIndex !== undefined; else normalTpl">
        <ng-container
          [ngTemplateOutlet]="uploadFileCellsTemplateRef[config.contentUploadFileCellsTemplateRefIndex]"
          [ngTemplateOutletContext]="{ $implicit: (parentFormGroup?.controls)['file']?.value }"
        >
        </ng-container>
      </ng-container>
      <ng-template #normalTpl>
        {{ text }}
      </ng-template>
    </ng-container>
  </ng-container>
</ng-template>
