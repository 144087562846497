import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isString } from '@roadrecord/type-guard';
import { addControlError, removeControlError } from '../datetime/start-and-end-time.validator';

// copy from https://github.com/Nightapes/ngx-validators/blob/master/src/components/util.ts
export class Util {
  static isNotPresent(control: AbstractControl): boolean {
    const value = control.value;
    if (value === undefined || value === null) {
      return true;
    }
    return value === '';
  }

  static addError(control: AbstractControl, errorId: string, value: any): void {
    addControlError(control, errorId, value);
  }

  static removeError(control: AbstractControl, errorId: string): void {
    removeControlError(control, errorId);
  }
}

// @deprecated kovi korokben torolni
// export function mismatchedPasswords(passwordControlName?: string, confirmPasswordControlName?: string): ValidatorFn {
//   return (group: AbstractControl): { [key: string]: any } => {
//     if (isNil(group)) {
//       return undefined;
//     }
//     const newPasswordValue = group.get(passwordControlName ? passwordControlName : 'newPassword').value;
//     const newPasswordConfirmValue = group.get(confirmPasswordControlName ? confirmPasswordControlName : 'confirmPassword').value;
//     if (newPasswordValue !== newPasswordConfirmValue) {
//       Util.addError(group.get(passwordControlName ? passwordControlName : 'newPassword'), 'mismatchedPasswords', true);
//       Util.addError(group.get(confirmPasswordControlName ? confirmPasswordControlName : 'confirmPassword'), 'mismatchedPasswords', true);
//
//       return { mismatchedPasswords: true };
//     } else {
//       const control1 = group.get(passwordControlName ? passwordControlName : 'confirmPassword');
//       const control2 = group.get(confirmPasswordControlName ? confirmPasswordControlName : 'confirmPassword');
//       Util.removeError(control1, 'mismatchedPasswords');
//       Util.removeError(control2, 'mismatchedPasswords');
//       if (!isNil(control1.errors)) {
//         if (Object.keys(control1.errors).length === 0) {
//           control1.setErrors(undefined);
//         }
//       }
//       if (!isNil(control2.errors)) {
//         if (Object.keys(control2.errors).length === 0) {
//           control2.setErrors(undefined);
//         }
//       }
//     }
//     return undefined;
//   };
// }

/**
 * Mindig csak a rePassword mezobe irjuk vagy vesszuk el a hibat
 * Mindig az elso jelszo mezore kell irni!
 */
export function mismatchedRePassword(rePasswordControl: AbstractControl) {
  return (passwordControl: AbstractControl): ValidationErrors => {
    if (isString(rePasswordControl.value) && rePasswordControl.value.length > 0) {
      if (passwordControl.value !== rePasswordControl.value) {
        Util.addError(rePasswordControl, 'mismatchedPasswords', true);
      } else {
        Util.removeError(rePasswordControl, 'mismatchedPasswords');
      }
    }
    return null;
  };
}

export function mismatchedPassword(passwordControl: AbstractControl) {
  return (rePasswordControl: AbstractControl): ValidationErrors => {
    if (passwordControl.valid && rePasswordControl.value !== passwordControl.value) {
      return { mismatchedPasswords: true };
    }
    return null;
  };
}
