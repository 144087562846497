<form [formGroup]="form" novalidate (ngSubmit)="onSubmit()" class="form-setting">
  <mat-card>
    <mat-progress-bar color="warn" mode="indeterminate" *ngIf="loading$ | async"></mat-progress-bar>
    <mat-card-header>
      <mat-card-title>
        <div class="d-flex w-100">
          <div class="d-flex align-items-center">{{ 'PRICE.DATA_FORM.ORDER.TITLE' | transloco }}</div>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column" fxLayout.gt-sm="row" class="w-100" fxLayoutGap="1em">
        <div fxFlex="100%" fxLayout="row" fxFlex.gt-sm="50%" fxFlex.lt-md="100%">
          <mat-form-field class="w-100">
            <mat-label>
              {{ 'PRICE.DATA_FORM.ORDER.FIELD.FIRST_NAME.LABEL' | transloco }}
            </mat-label>
            <input
              formControlName="first_name"
              name="first_name"
              matInput
              type="text"
              rrCapitalize
              [placeholder]="'PRICE.DATA_FORM.ORDER.FIELD.FIRST_NAME.PLACEHOLDER' | transloco"
              required
            />
            <mat-error> <rr-validation-messages [errors]="firstNameControl?.errors"></rr-validation-messages> </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="100%" fxLayout="row" fxFlex.gt-sm="50%" fxFlex.lt-md="100%">
          <mat-form-field class="w-100">
            <mat-label>
              {{ 'PRICE.DATA_FORM.ORDER.FIELD.LAST_NAME.LABEL' | transloco }}
            </mat-label>
            <input
              formControlName="last_name"
              name="last_name"
              matInput
              type="text"
              rrCapitalize
              [placeholder]="'PRICE.DATA_FORM.ORDER.FIELD.LAST_NAME.PLACEHOLDER' | transloco"
              required
            />
            <mat-error> <rr-validation-messages [errors]="lastNameControl?.errors"></rr-validation-messages> </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column" fxLayout.gt-sm="row" class="w-100" fxLayoutGap="1em">
        <div fxLayout="row" fxLayout.gt-sm="row" fxFlex.gt-sm="50%" fxFlex.lt-md="100%">
          <mat-form-field class="w-100">
            <mat-label>
              {{ 'PRICE.DATA_FORM.ORDER.FIELD.EMAIL.LABEL' | transloco }}
            </mat-label>
            <input
              readonly
              formControlName="email"
              name="email"
              matInput
              type="email"
              [placeholder]="'PRICE.DATA_FORM.ORDER.FIELD.EMAIL.PLACEHOLDER' | transloco"
              required
            />
            <mat-error> <rr-validation-messages [errors]="emailControl?.errors"></rr-validation-messages> </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.gt-sm="row" fxFlex.gt-sm="50%" fxFlex.lt-md="100%">
          <mat-form-field class="w-100">
            <mat-label>
              {{ 'PRICE.DATA_FORM.ORDER.FIELD.PHONE.LABEL' | transloco }}
            </mat-label>
            <input
              [imask]="{ mask: '{(}000{)}000{-}0000', parse: parseUsPhoneNumber, lazy: false }"
              unmask="typed"
              [placeholder]="'PRICE.DATA_FORM.ORDER.FIELD.PHONE.PLACEHOLDER' | transloco"
              formControlName="phone_number"
              name="phone_number"
              matInput
              type="text"
            />
            <span class="phone-prefix" matPrefix>{{ 'USER.REGISTER.PHONE_NUMBER_PREFIX' | transloco }}&nbsp;</span>
            <mat-error>
              <rr-validation-messages
                [errors]="phoneNumberControl?.errors"
                [messages]="phoneControlValidationMessages"
              ></rr-validation-messages>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayout.gt-sm="row" class="w-100">
        <mat-form-field class="w-100">
          <mat-label>
            {{ 'PRICE.DATA_FORM.ORDER.FIELD.COMPANY.LABEL' | transloco }}
          </mat-label>
          <input
            formControlName="company_name"
            name="company_name"
            matInput
            type="text"
            [placeholder]="'PRICE.DATA_FORM.ORDER.FIELD.COMPANY.PLACEHOLDER' | transloco"
          />
          <mat-error> <rr-validation-messages [errors]="companyNameControl?.errors"></rr-validation-messages> </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayout.gt-sm="row" class="w-100" fxLayoutGap="1em">
        <div fxLayout="row" fxLayout.gt-sm="row" fxFlex.gt-sm="50%" fxFlex.lt-md="100%">
          <mat-form-field class="w-100">
            <mat-label>
              {{ 'PRICE.DATA_FORM.ORDER.FIELD.STREET_1.LABEL' | transloco }}
            </mat-label>
            <input
              formControlName="address_line1"
              name="address_line1"
              matInput
              type="text"
              [placeholder]="'PRICE.DATA_FORM.ORDER.FIELD.STREET_1.PLACEHOLDER' | transloco"
              required
            />
            <mat-error>
              <rr-validation-messages [errors]="streetControl1?.errors"></rr-validation-messages>
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.gt-sm="row" fxFlex.gt-sm="50%" fxFlex.lt-md="100%">
          <mat-form-field class="w-100">
            <mat-label>
              {{ 'PRICE.DATA_FORM.ORDER.FIELD.STREET_2.LABEL' | transloco }}
            </mat-label>
            <input
              formControlName="address_line2"
              name="address_line2"
              matInput
              type="text"
              [placeholder]="'PRICE.DATA_FORM.ORDER.FIELD.STREET_2.PLACEHOLDER' | transloco"
            />
            <mat-error>
              <rr-validation-messages [errors]="streetControl2?.errors"></rr-validation-messages>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column" fxLayout.gt-sm="row" class="w-100" fxLayoutGap="1em">
        <div fxLayout="row" fxLayout.gt-sm="row" fxFlex.gt-sm="25%" fxFlex.lt-md="100%">
          <mat-form-field class="w-100">
            <mat-label>
              {{ 'PRICE.DATA_FORM.ORDER.FIELD.CITY.LABEL' | transloco }}
            </mat-label>
            <input
              formControlName="city"
              name="city"
              matInput
              type="text"
              [placeholder]="'PRICE.DATA_FORM.ORDER.FIELD.CITY.PLACEHOLDER' | transloco"
              required
            />
            <mat-error>
              <rr-validation-messages [errors]="cityControl?.errors"></rr-validation-messages>
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.gt-sm="row" fxFlex.gt-sm="25%" fxFlex.lt-md="100%">
          <mat-form-field class="w-100">
            <mat-label>
              {{ 'PRICE.DATA_FORM.ORDER.FIELD.COUNTRY.LABEL' | transloco }}
            </mat-label>
            <mat-select
              [placeholder]="'PRICE.DATA_FORM.ORDER.FIELD.COUNTRY.PLACEHOLDER' | transloco"
              [formControl]="countryControl"
              name="country"
              required
            >
              <mat-option *ngFor="let country of countries" [value]="country.value">{{ country.label | transloco }}</mat-option>
            </mat-select>

            <mat-error>
              <rr-validation-messages [errors]="countryControl?.errors"></rr-validation-messages>
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.gt-sm="row" fxFlex.gt-sm="25%" fxFlex.lt-md="100%">
          <rr-live-auto-complete
            name="state"
            required
            [database]="stateService"
            [optionsConfig]="laStateOptionsConfig"
            [formControlRef]="stateControl"
            [label]="'PRICE.DATA_FORM.ORDER.FIELD.STATE.LABEL.' + (countryControl.value === 'US' ? 'US' : 'CA')"
            [placeholder]="'PRICE.DATA_FORM.ORDER.FIELD.STATE.PLACEHOLDER.' + (countryControl.value === 'US' ? 'US' : 'CA')"
            [hasActionsMenu]="false"
            hasNewButton="false"
            pageSizeOverride="99999999999"
          >
          </rr-live-auto-complete>
        </div>

        <div fxLayout="row" fxLayout.gt-sm="row" fxFlex.gt-sm="25%" fxFlex.lt-md="100%">
          <mat-form-field class="w-100">
            <mat-label>
              {{ 'PRICE.DATA_FORM.ORDER.FIELD.ZIP.LABEL' | transloco }}
            </mat-label>
            <input
              formControlName="zip"
              name="zip"
              matInput
              type="text"
              [placeholder]="'PRICE.DATA_FORM.ORDER.FIELD.ZIP.PLACEHOLDER' | transloco"
              required
            />
            <mat-error>
              <rr-validation-messages [errors]="zipControl?.errors"></rr-validation-messages>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column" fxLayout.gt-sm="row" class="w-100" fxLayoutGap="1em">
        <div fxLayout="row" fxLayout.gt-sm="row" [fxFlex.gt-sm]="isOnSale ? '50%' : '33%'" fxFlex.lt-md="100%">
          <mat-form-field class="w-100">
            <mat-label>
              {{ 'PRICE.DATA_FORM.ORDER.FIELD.SUBSCRIPTION_LENGTH.LABEL' | transloco }}
            </mat-label>
            <mat-select
              [placeholder]="'PRICE.DATA_FORM.ORDER.FIELD.SUBSCRIPTION_LENGTH.LABEL' | transloco"
              formControlName="selected_package_name"
              name="selected_package_name"
            >
              <mat-option *ngFor="let price of priceList" [value]="price">
                <span>{{ price.longname }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.gt-sm="row" fxFlex.gt-sm="33%" fxFlex.lt-md="100%" *ngIf="!isOnSale">
          <mat-form-field class="w-100">
            <mat-label>
              {{ 'PRICE.DATA_FORM.ORDER.FIELD.SUBSCRIPTION_START_DATE.LABEL' | transloco }}
            </mat-label>
            <input
              type="text"
              matInput
              readonly
              required
              (click)="picker.open()"
              [matDatepicker]="picker"
              [placeholder]="'PRICE.DATA_FORM.ORDER.FIELD.SUBSCRIPTION_START_DATE.PLACE_HOLDER' | transloco"
              [formControl]="subscriptionStartDateControl"
              name="subscription_start_date"
            />
            <mat-icon
              matPrefix
              color="accent"
              svgIcon="information-outline"
              class="starting-date-information"
              [matTooltip]="'PRICE.DATA_FORM.ORDER.SUBSCRIPTION_START_DATE_INFORMATION' | transloco"
              matTooltipClass="start-date-tooltip"
            ></mat-icon>

            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error><rr-validation-messages [errors]="subscriptionStartDateControl?.errors"></rr-validation-messages></mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.gt-sm="row" [fxFlex.gt-sm]="isOnSale ? '50%' : '33%'" fxFlex.lt-md="100%">
          <mat-form-field class="w-100">
            <mat-label>
              {{ 'PRICE.DATA_FORM.ORDER.FIELD.NUMBER_OF_VEHICLE.LABEL' | transloco }}
            </mat-label>
            <mat-select
              [placeholder]="'PRICE.DATA_FORM.ORDER.FIELD.NUMBER_OF_VEHICLE.PLACEHOLDER' | transloco"
              formControlName="vehicle_count"
              name="vehicle_count"
              required
            >
              <mat-option *ngFor="let index of [].constructor(500); let vehicleNumber = index" [value]="vehicleNumber + 1">
                <span>{{ vehicleNumber + 1 }}</span>
              </mat-option>
            </mat-select>

            <mat-error>
              <rr-validation-messages [errors]="numberOfVehicleControl?.errors"></rr-validation-messages>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column" fxLayout.gt-sm="column" class="w-100 pt-2" style="font-size: 18px">
        <div *ngIf="calculatedInformation !== null" class="p-2 calculation-information">
          <div class="information-container" fxLayout="column" fxLayout.gt-sm="row">
            <strong>{{ calculatedInformation.package_name }},</strong>
            <span class="pl-1"> Number of vehicles: {{ calculatedInformation.vehicle_count }},</span>
            <span class="pl-1"> One-time payment of: ${{ calculatedInformation.total_price | number }} </span>
          </div>
          <div
            class="d-flex"
            *ngIf="
              isBasicPrice === true &&
              selectedPricePackage.product_category.is_lifetime === false &&
              form.controls['subscription_start_date'].value
            "
          >
            <span>
              Your available subscription period: {{ calculatedInformation.subscription_start_date | dateFormat }} -
              {{ calculatedInformation.subscription_end_date | dateFormat }}
            </span>
          </div>
        </div>
        <div class="d-flex pt-3 align-items-center calculate-container" fxLayoutGap="1em" *ngIf="loadingCalculate$ | async">
          <mat-progress-spinner diameter="25" color="primary" strokeWidth="2" mode="indeterminate"> </mat-progress-spinner>
          <span style="font-size: 16px">{{ 'PRICE.DATA_FORM.ORDER.CALCULATE_REFRESH_TEXT' | transloco }}</span>
        </div>

        <div class="d-flex justify-content-center pt-4">
          <span style="color: grey">{{ 'PRICE.DATA_FORM.ORDER.INFO_TEXT' | transloco }}</span>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="d-flex">
      <button
        type="button"
        mat-icon-button
        matTooltipPosition="right"
        matTooltip="{{ 'PRICE.DATA_FORM.BACK_TOOLTIP' | transloco }}"
        (click)="onNavigatePriceList()"
      >
        <mat-icon svgIcon="arrow-left"></mat-icon>
      </button>
      <div class="d-flex justify-content-center w-100">
        <button type="submit" mat-raised-button color="primary" [disabled]="(formSubmitted && form.invalid) || (loading$ | async) === true">
          Submit
        </button>
      </div>
    </mat-card-actions>
    <mat-card-footer>
      <div class="d-flex flex-column pt-2">
        <p class="d-flex justify-content-center p-secure-text">Secure Payments Safe and Secure SSL Encrypted</p>
        <div class="d-flex justify-content-center pay-logo-container">
          <img class="static-icon pay-logo" [src]="'assets/images/registration/paylogo.png'" alt="paylogo" />
        </div>
      </div>
    </mat-card-footer>
  </mat-card>
</form>
