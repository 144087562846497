import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { CountDownTimerComponent } from './count-down-timer.component';

@NgModule({
  imports: [CommonModule, TranslocoModule],
  declarations: [CountDownTimerComponent],
  exports: [CountDownTimerComponent],
})
export class CountDownTimerModule {}
