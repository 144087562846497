import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';

export function matPaginatorIntlFactory(translocoService: TranslocoService): MatPaginatorIntl {
  const paginatorIntl = new MatPaginatorIntl();
  paginatorIntl.itemsPerPageLabel = '';
  paginatorIntl.firstPageLabel = translocoService.translate('COMMON.PAGINATOR.FIRST_PAGE');
  paginatorIntl.lastPageLabel = translocoService.translate('COMMON.PAGINATOR.LAST_PAGE');
  paginatorIntl.nextPageLabel = translocoService.translate('COMMON.PAGINATOR.NEXT_PAGE');
  paginatorIntl.previousPageLabel = translocoService.translate('COMMON.PAGINATOR.PREVIOUS_PAGE');
  paginatorIntl.getRangeLabel = getRangeLabel;
  return paginatorIntl;
}

function getRangeLabel(page: number, pageSize: number, length: number): string {
  if (length === 0 || pageSize === 0) {
    return `0 / ${length}`;
  }
  length = Math.max(length, 0);
  const startIndex = page * pageSize;
  const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
  return `${startIndex + 1} - ${endIndex} / ${length}`;
}
