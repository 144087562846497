import { Provider } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';

export const MatDialogConfigProvider: Provider = {
  provide: MAT_DIALOG_DEFAULT_OPTIONS,
  useValue: {
    autoFocus: false,
    closeOnNavigation: false,
    disableClose: false,
    hasBackdrop: true,
  } as MatDialogConfig,
};
