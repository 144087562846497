<mat-card>
  <mat-card-title>{{ 'FIRST_STEPS.FINISH_MOBILE.LAYOUT.TITLE' | transloco }}</mat-card-title>
  <mat-card-content>
    <p>{{ 'FIRST_STEPS.FINISH_MOBILE.LAYOUT.CONTENT' | transloco }}</p>
    <img [src]="imageUrl | sanitizeResourceUrl" />

    <div style="display: inline-block; margin-right: 1em" class="apple">
      <a [href]="appleStoreUrl | sanitizeUrl" class="apple-store" target="_blank"><img src="/assets/images/mobile-badges/ios.svg" /></a>
    </div>
    <div style="display: inline-block" class="google">
      <a [href]="googleStoreUrl | sanitizeUrl" class="play-store" target="_blank"><img src="/assets/images/mobile-badges/android.svg" /></a>
    </div>
    <small class="d-inline-block mobile-badge-hint">{{ 'FIRST_STEPS.FINISH_MOBILE.LAYOUT.MOBILE_BADGE_HINT' | transloco }}</small>
  </mat-card-content>
  <mat-card-actions align="end">
    <button
      id="first-stepper-finish-mobile-layout-next-step"
      type="button"
      mat-raised-button
      color="primary"
      (click)="onClickNextStep()"
      [disabled]="disableButton"
    >
      {{ 'FIRST_STEPS.FINISH_MOBILE.LAYOUT.BASE_BUTTON' | transloco }}
    </button>
  </mat-card-actions>
</mat-card>
