import { AbstractConstructor, Constructor } from '@roadrecord/common/common';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Observable } from 'rxjs';
import {
  AbstractRRFormWithDynamicFormConstructorParams,
  AbstractRRFormWithDynamicFormCtor,
  mixinAbstractRRFormWithDynamicFormWithGenericType,
} from '../form/mixin/abstract-rr-form-with-dynamic-form.mixin';
import { MaybeHandleHttpError } from '../http/function/maybe-handle-http-error.function';
import { Injector } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { AbstractEntityService } from '../entity/service/abstract-entity.service';
import { HttpListResponseModel } from '../http/model/http-list-response.model';

/**
 * Example use:
 * html:
 * `<mat-progress-bar mode="indeterminate" *ngIf="form.disabled && form.submitted"></mat-progress-bar>
 <form [formGroup]="form">
 <rr-formly-form class="w-100" [form]="form" [fields]="formlyFields"></rr-formly-form>
 </form>
 <div class="footer text-right mt-2"
 ><rr-submit-button-with-error
 [disabled]="form.disabled || (form.submitted && form.invalid)"
 [formSubmitted]="form.submitted"
 [formInvalid]="form.invalid"
 buttonLabel="COMMON.ACTION.SAVE"
 invalidTooltip="COMMON.VALIDATION.BUTTON"
 (clickButton)="onClickSubmit()"
 ></rr-submit-button-with-error
 ></div>`
 *
 * scss:
 * `:host {
  .mat-progress-bar {
    top: 0;
    width: 100%;
    position: absolute;
    left: 0;
  }
}`
 */

export interface RRFormWithDynamicFormBottomSheetConfig<MODEL> {
  data: MODEL;
  onSubmit: (formValue: MODEL) => Observable<unknown>;
}

/**
 * mixin-elt osztalyban kell hasznalni
 * tovabba ezeknek leteznie kell a mixinelt osztalyban
 */
// tslint:disable-next-line:no-empty-interface
export interface BottomSheetRRFormWithDynamicForm<MODEL> {}

export type BottomSheetRRFormWithDynamicFormCtor<MODEL> = Constructor<BottomSheetRRFormWithDynamicForm<MODEL>>;

/**
 * aki extendalja majd a mixin-t annak ezeket super-ben at kell adnia
 */
export interface BottomSheetRRFormWithDynamicFormConstructorParams<MODEL, BSCONFIG extends RRFormWithDynamicFormBottomSheetConfig<MODEL>> {
  readonly bottomSheetRef: MatBottomSheetRef<unknown>;
  readonly bottomSheetData: BSCONFIG;
}

/**
 * Ha valamilyen mezo hianyara barmilyen entitasra, fel akarunk dobni egy notifit
 * es utana bottom sheet-ben lehet modositani
 *
 * felhivas: az abstract hulyeseg miatt, extends-be belle kellett rakni a
 * Constructor<BottomSheetRRFormWithDynamicFormConstructorParams<MODEL, BSCONFIG>>
 *   ezert az intelisense nem fogja jol erzekelni ha hianyzik egy mezo :(
 *
 * @param _base
 */
export function mixinBottomSheetRRFormWithDynamicFormWithGenericType<MODEL, BSCONFIG extends RRFormWithDynamicFormBottomSheetConfig<MODEL>>(
  _base: any
) {
  return (function mixinBottomSheetRRFormWithDynamicForm<
    T extends AbstractConstructor<AbstractRRFormWithDynamicFormConstructorParams<MODEL>> &
      Constructor<BottomSheetRRFormWithDynamicFormConstructorParams<MODEL, BSCONFIG>>
  >(base: T): AbstractRRFormWithDynamicFormCtor<MODEL> & BottomSheetRRFormWithDynamicFormCtor<MODEL> & T {
    abstract class Mixin extends ((base as unknown) as AbstractRRFormWithDynamicFormCtor<MODEL> &
      Constructor<AbstractRRFormWithDynamicFormConstructorParams<MODEL>> &
      Constructor<BottomSheetRRFormWithDynamicFormConstructorParams<MODEL, BSCONFIG>>) {
      constructor(...args: any[]) {
        super(...args);
      }

      onSubmit(formValue: MODEL) {
        this.bottomSheetData.onSubmit(formValue).subscribe(
          () => this.bottomSheetRef.dismiss(),
          error => {
            MaybeHandleHttpError.maybeHandleHttpError(error);
            this.form.enable();
            this.handleFormControlsServerError(error);
          }
        );
      }
    }

    return (Mixin as unknown) as AbstractRRFormWithDynamicFormCtor<MODEL> & BottomSheetRRFormWithDynamicFormCtor<MODEL> & T;
  })(_base);
}

// @ts-ignore
export function getBottomSheetComponentBase<MODEL, BSREF>() {
  class BottomSheetComponentBase {
    constructor(
      readonly injector: Injector,
      readonly document: Document,
      readonly translocoService: TranslocoService,
      readonly messageDialogService: MessageDialogService,
      readonly entityService: AbstractEntityService<HttpListResponseModel<MODEL>, MODEL>,
      readonly bottomSheetRef: MatBottomSheetRef<BSREF>,
      readonly bottomSheetData: RRFormWithDynamicFormBottomSheetConfig<MODEL>
    ) {}
  }

  return mixinBottomSheetRRFormWithDynamicFormWithGenericType<MODEL, RRFormWithDynamicFormBottomSheetConfig<MODEL>>(
    mixinAbstractRRFormWithDynamicFormWithGenericType<MODEL>(BottomSheetComponentBase)
  ) as AbstractRRFormWithDynamicFormCtor<MODEL> & BottomSheetRRFormWithDynamicFormCtor<MODEL> & typeof BottomSheetComponentBase;
}
