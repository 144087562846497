<div class="scroll">
  <div class="wrapper-component">
    <div fxFlex.gt-md fxLayout="row" fxLayoutAlign="center center" class="wrapper-row">
      <div class="wid100" fxLayout="column" fxLayoutAlign="center center">
        <mat-card class="rr-card2 login-form-card fix-actions-row-height">
          <mat-progress-bar *ngIf="loading" color="warn" mode="indeterminate"></mat-progress-bar>
          <mat-card-header>
            <mat-card-title>
              <mat-icon svgIcon="key"></mat-icon>
              <span>{{ translatePrefix + '.TITLE' | transloco }}</span>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <form
              #formElem
              (keyup.enter)="onKeyupForm($event, formElem)"
              [formGroup]="form"
              fxLayout="column"
              fxLayoutAlign="center stretch"
              novalidate
            >
              <mat-form-field disabled fxFlex="100" class="x--email">
                <mat-label>{{ translatePrefix + '.EMAIL' | transloco }}</mat-label>
                <input
                  [placeholder]="translatePrefix + '.EMAIL' | transloco"
                  [value]="email"
                  disabled
                  matInput
                  name="email"
                  required
                  type="email"
                />
              </mat-form-field>
              <mat-form-field class="wid100 x--password">
                <mat-label>{{ translatePrefix + '.PASSWORD' | transloco }}</mat-label>
                <input
                  #focusPassword="rrFocusInput"
                  [placeholder]="translatePrefix + '.PASSWORD' | transloco"
                  [type]="passwordType"
                  formControlName="password"
                  matInput
                  name="password"
                  required
                  rrFocusDelay="300"
                  rrFocusInput="true"
                />
                <button tabindex="-1" type="button" mat-icon-button matSuffix (click)="changeVisibilityPassword()">
                  <mat-icon
                    [matTooltip]="(!visiblePassword ? 'COMMON.TOOLTIP.PASSWORD_SHOW' : 'COMMON.TOOLTIP.PASSWORD_HIDE') | transloco"
                    matTooltipPosition="left"
                    class="visible-icon"
                    [svgIcon]="!visiblePassword ? 'eye' : 'eye-off'"
                  ></mat-icon>
                </button>
                <mat-hint class="password-hint" *ngIf="passwordControl.invalid || passwordControl?.value?.length === 0">{{
                  'COMMON.VALIDATION.PASSWORD_PATTERN' | transloco
                }}</mat-hint>
                <mat-error>
                  <rr-validation-messages
                    [errors]="passwordControl?.errors"
                    [messages]="passwordControlValidationMessages"
                  ></rr-validation-messages>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="wid100 x--confirm-password">
                <mat-label>{{ translatePrefix + '.CONFIRM_PASSWORD' | transloco }}</mat-label>
                <input
                  [placeholder]="translatePrefix + '.CONFIRM_PASSWORD' | transloco"
                  [type]="passwordType"
                  formControlName="confirmPassword"
                  matInput
                  name="confirmPassword"
                  required
                />
                <button tabindex="-1" type="button" mat-icon-button matSuffix (click)="changeVisibilityPassword()">
                  <mat-icon
                    [matTooltip]="(!visiblePassword ? 'COMMON.TOOLTIP.PASSWORD_SHOW' : 'COMMON.TOOLTIP.PASSWORD_HIDE') | transloco"
                    matTooltipPosition="left"
                    class="visible-icon"
                    [svgIcon]="!visiblePassword ? 'eye' : 'eye-off'"
                  ></mat-icon>
                </button>
                <mat-error>
                  <rr-validation-messages
                    [errors]="confirmPasswordControl?.errors"
                    [messages]="passwordControlValidationMessages"
                  ></rr-validation-messages>
                </mat-error>
              </mat-form-field>
              <mat-error
                *ngIf="
                  form.hasError('mismatchPassword') &&
                  !confirmPasswordControl.hasError('required') &&
                  !confirmPasswordControl?.hasError('invalidPassword') &&
                  !passwordControl.hasError('required') &&
                  !passwordControl?.hasError('invalidPassword')
                "
                class="rr-form-mat-error"
                >{{ 'COMMON.VALIDATION.DIFFERENT_PASSWORD' | transloco }}
              </mat-error>
            </form>
          </mat-card-content>
          <mat-card-actions>
            <span class="flex-spacer"></span>
            <button
              (click)="resetPassword()"
              [disabled]="(form['submitted'] && form.invalid) || form.disabled"
              [ngClass]="{ 'invalid-form': form['submitted'] && form.invalid }"
              class="submit-button"
              color="primary"
              mat-raised-button
              type="submit"
            >
              <div
                *ngIf="form['submitted'] && form.invalid"
                class="invalid-form-button-overlay"
                matTooltip="{{ form['submitted'] === true && form.invalid ? ('COMMON.VALIDATION.BUTTON' | transloco) : undefined }}"
                matTooltipPosition="left"
              >
                <mat-icon svgIcon="alert"></mat-icon>
              </div>
              {{ translatePrefix + '.SAVE_LOGIN' | transloco }}
            </button>
          </mat-card-actions>
        </mat-card>
        <rr-unauthenticated-footer></rr-unauthenticated-footer>
      </div>
    </div>
  </div>
</div>

<re-captcha
  #captchaRef="reCaptcha"
  *ngIf="useCaptcha"
  [siteKey]="reCaptchaKey"
  size="invisible"
  (resolved)="onResolveCaptcha($event)"
></re-captcha>
