import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxsModule } from '@ngxs/store';
import {
  AppTypePipeModule,
  CapitalizeDirectiveModule,
  FocusInputModule,
  IsNilPipeModule,
  PreventClickModule,
  SanitizePipeModule,
} from '@roadrecord/common/common';
import { ValidatingModule } from '@roadrecord/validating';
import { RecaptchaModule } from 'ng-recaptcha';
import { RegistrationState } from '../../../state/registration.state';
import { AuthenticationModule } from '../authentication.module';
import { RegisterComponent } from './register.component';
import { RegistrationSuccessfulComponent } from './registration-successful/registration-successful.component';
import { ResendActivationSuccessfulComponent } from './resend-activation/resend-activation-successful/resend-activation-successful.component';
import { ResendActivationComponent } from './resend-activation/resend-activation.component';
import { DynamicFormModule } from '@roadrecord/dynamic-form';
import { SocialButtonsCardModule } from '../social-buttons-card/social-buttons-card.module';
import { FormModule } from '@roadrecord/utils';
import { IMaskModule } from 'angular-imask';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    FlexLayoutModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatButtonModule,
    MatProgressBarModule,

    AuthenticationModule,
    ValidatingModule,
    PreventClickModule,
    FocusInputModule,
    CapitalizeDirectiveModule,
    RecaptchaModule,
    MatSelectModule,

    TranslocoModule,
    NgxsModule.forFeature([RegistrationState]),
    RouterModule,
    IsNilPipeModule,
    DynamicFormModule,
    SocialButtonsCardModule,
    AppTypePipeModule,
    SanitizePipeModule,
    FormModule,
    IMaskModule,
  ],
  declarations: [RegisterComponent, ResendActivationComponent, ResendActivationSuccessfulComponent, RegistrationSuccessfulComponent],
  exports: [RegisterComponent, ResendActivationComponent, ResendActivationSuccessfulComponent, RegistrationSuccessfulComponent],
  bootstrap: [RegisterComponent],
})
export class RegisterModule {}
