import { Injectable, NgZone } from '@angular/core';
import { Actions, Store } from '@ngxs/store';

import { AuthState } from '@roadrecord/user/common';
import { SystemMessagesWorkerBridgeService } from '@roadrecord/system-messages';
import { NotificationsService } from '@roadrecord/external-packages/angular2-notifications';

@Injectable()
export class SystemMessagesWorkerBridgeImplService extends SystemMessagesWorkerBridgeService {
  constructor(ngZone: NgZone, store: Store, notificationsService: NotificationsService, actions$: Actions) {
    super(
      ngZone,
      store.selectSnapshot(AuthState.token).replace(new RegExp(/\./, 'g'), ''),
      store.selectSnapshot(AuthState.user).email,
      notificationsService,
      actions$
    );
  }

  getWorker(): Worker {
    return new Worker('./../../worker/firebase/firebase.worker', { name: 'system-messages', type: 'module' });
  }
}
