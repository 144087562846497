import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { WINDOW } from '@roadrecord/common/common';
import { StateAllModel } from './model/state-all.model';
import { STATE_PREFIX_TOKEN } from '@roadrecord/company-context/common';
import { DetectZoom } from '../authentication/detect-zoom';
import { environment } from '@roadrecord/environment';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { PaymentTransactionResponseModel } from '../price/model/models';
import { PaymentStatus } from '../price/model/payment-status.enum';

/**
 * Az alkalmazas allapoatainak kezelesere szolgal
 *
 * workflow-k:
 * - olvasas es valtozas figyeles:
 * STATE_SERVICE.subscribeChange<STATE_MODEL>(
 *    STATE_NAME
 * )
 * .subscribe(
 *    change => {
 *            ...
 *    }
 * );
 * tovabbi parameterezhetosegert nezd meg a subscribeChange metodus dokumentiaciojat
 *
 * - ha egy component-ben figyelunk egy state-t es szeretnenk modnjuk egy loader-t felrakni a frissitese alatt:
 * STATE_SERVICE.subscribeBeforeRefresh(STATE_NAME)
 *     .subscribe(() => ...);
 *
 * ---
 *
 * Provide:
 * StateModule.forRoot() -val provide-old
 *
 * TODO https://stackoverflow.com/questions/39085632/sessionStorage-is-not-defined-angular-universal/47875597
 * server miatt jo lehet ha lesz PW https://github.com/Hacklone/angular2-cool-storage/blob/master/src/cool-storage-base.ts
 */
@Injectable({ providedIn: 'root' })
export class StateService {
  constructor(
    @Optional()
    @Inject(STATE_PREFIX_TOKEN)
    prefix: string,
    @Optional() private http: HttpClient,
    @Inject(WINDOW) private readonly window: Window
  ) {}

  // @ConsoleLogBeforeRun('get all')
  getAll(): StateAllModel {
    const detectZoom = new DetectZoom(window);

    return this.http.get<StateAllModel>(`${environment.apiUrl}state/`, {
      headers: {
        'Meta-Resolution': `${this.window.screen.width}x${this.window.screen.height}@${detectZoom.zoom()}@${
          this.window.document.body.clientWidth
        }x${this.window.document.body.clientHeight}`,
      },
    });
  }

  getPaymentTransaction(
    page: PageEvent = {
      pageIndex: 0,
      pageSize: 99999999,
      length: 1,
    }
  ): Observable<PaymentTransactionResponseModel> {
    const queryParams = `page_size=${page.pageSize}&page=${page.pageIndex + 1}`;
    return this.http.get<PaymentTransactionResponseModel>(
      `${environment.apiUrl}payment-manager/payment-transaction/?${queryParams}&status=${PaymentStatus.SUCCESS}&available_vehicles_greater_than=0`
    );
  }

  // /**
  //  * Teljes state frissitese, es ujra kiertesites a valtozasrol
  //  *
  //  * @return {Observable<any>} - cold observable
  //  */
  // @ConsoleLogBeforeRun('refresh all')
  // refreshAll(): Observable<StateAllModel> {
  //   return this.remoteHandlerService.getAll().pipe(
  //     // TEST exclude function
  //     // map(state => {
  //     //   // state['rr.menu']['excluded'] = ['MILAGE_OF_PREVIOUS_MONTH'];
  //     //   state['rr.menu']['disabledMenuPageGroup'] = ['MONTH_ACTIVITIES'];
  //     //   return state;
  //     // }),
  //     tap(states => {
  //       // toroljuk a storage-t
  //       this.sessionStorageHandlerService.clear();
  //       // osszes egyedet beallitjuk, igy mindenki erzekeli
  //       Object.entries(states).forEach(
  //         stateEntry =>
  //           stateEntry[0] === `${this.prefix}.first-steps`
  //             ? this.setSessionStorageAndNotifyChange(stateEntry[0], stateEntry[1], StateChangeOperationEnum.SET)
  //             : undefined
  //       );
  //     })
  //   );
  // }
}
