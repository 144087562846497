import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionDispatched, ofActionSuccessful, Select, Store } from '@ngxs/store';
import {
  CapitalizeDirective,
  globalEmailControlValidationMessages,
  globalPasswordControlValidationMessages,
  globalPhoneControlValidationMessages,
  mismatchedPassword,
  mismatchedRePassword,
  slideLeftInUnathAnimation,
  slideLeftUnathOutAnimation,
  USER_PASSWORD_RESENT,
  ViewportService,
} from '@roadrecord/common/common';
import {
  commonHttpStreamErrorHandler,
  FieldHttpError,
  HandleErrorObject,
  manualHandleFormControlsSetServerError,
  ManualViewRemoteFieldErrorPlugin,
  MaybeHandleHttpError,
} from '@roadrecord/utils';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { isMobileOrTablet } from '@roadrecord/mobile-detect';
import { phoneNumberValidator, phoneNumberValidator2 } from '@roadrecord/ngx-phone-validators';
import { isNil, isNumeric } from '@roadrecord/type-guard';
import { rrFormErrorStateMatcher } from '@roadrecord/validating';
import { CookieService } from 'ngx-cookie-service';
import { EmailValidators, PasswordValidators, UniversalValidators } from 'ngx-validators';
import { asapScheduler, Observable, timer } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { OldRegistrationErrorAction } from '../../../state/action/register/old-registration-error.action';
import { OldRegistrationSuccessAction } from '../../../state/action/register/old-registration-success.action';
import { OldRegistrationAction } from '../../../state/action/register/old-registration.action';
import { AuthService } from '../../auth.service';
import { UserModel } from '../../model/user.model';
import { fromForgotPassword } from '../forgot-password/forgot-password/from-forgot-password';
import { AreaOfInterestEnum } from './model/area-of-interest.enum';
import { canRegisterSuccessFulVisiblePage } from './registration-successful/registration-successful.component';
import { AreaOfInterestUSEnum } from './model/area-of-interest-us.enum';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION } from '@roadrecord/dynamic-form';
import { getRegisterReferer } from './get-register-referer/get-register-referer-rr.function';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { RegisterSocialModel } from '../../model/register-social.model';
import { OldRegistrationLoadingAction } from '../../../state/action/register/old-registration-loading.action';
import { userRegistrationNameFormFragmentDynamicConfig } from '../../../dynamic-form/user-registration-name-form-fragment-dynamic.config';
import { FingerprintService } from '../fingerprint/fingerprint.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppTypeEnum, environment } from '@roadrecord/environment';
import { getRegisterOptimonkReferer } from './get-register-referer/get-register-referer-mw.function';

/**
 * Ez az angol verzio!
 */

@UntilDestroy()
@Component({
  selector: 'rr-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [
    FingerprintService,
    rrFormErrorStateMatcher,
    CookieService,
    { provide: DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION, useValue: true },
  ],
  animations: [slideLeftInUnathAnimation, slideLeftUnathOutAnimation],
})
export class RegisterComponent implements OnInit, AfterViewInit {
  isMobileOrTablet = isMobileOrTablet();
  @HostBinding('@enterAnimation')
  enterAnimation;
  @HostBinding('@.disabled')
  disabledAnimation = this.authService.unauthFirstAnimDisabled;
  @HostBinding('@leaveAnimation')
  leaveAnimation;

  @ViewChild('captchaRef')
  captchaRef: any;
  visiblePassword = false;
  @Select(states => states.registerPage.loading)
  loading$: Observable<boolean>;
  passwordType = 'password';
  form: FormGroup & { submitted?: boolean };
  companyNameControl = new FormControl('', [Validators.required, Validators.minLength(5)]);
  areaOfInterestControl = new FormControl(undefined, Validators.required);
  emailControl = new FormControl('', [Validators.required, EmailValidators.normal]);
  passwordControl: AbstractControl;
  confirmPasswordControl: AbstractControl;
  phoneNumberControl = new FormControl('', Validators.required);
  magazineSubscribeControl = new FormControl(false);
  privacyStatementControl = new FormControl(false, [Validators.requiredTrue]);
  emailControlValidationMessages = globalEmailControlValidationMessages;
  passwordControlValidationMessages = globalPasswordControlValidationMessages;
  phoneControlValidationMessages = globalPhoneControlValidationMessages;
  AreaOfInterestEnum: any;
  @Input() uiDisableCaptcha: boolean;
  useCaptcha = environment.useCaptcha;
  readonly reCaptchaKey = environment.captchaCode;
  private runCaptchaReset = false;
  readonly nameFormFragmentFormlyFields: FormlyFieldConfig[];
  readonly isAmericanVersion = environment.appType === AppTypeEnum.US;
  @Input() showEmail = true;
  @Input() showEmailHint = true;
  @Input() showPassword = true;
  @Input() showPrivacyCheckBox = true;
  @Input() loadCompanyRegistrationComplete = false;
  @Input() saveCompanyRegistrationComplete = false;
  @Output() completeCompanyRegistrationSave = new EventEmitter<void>();
  inputConfig = [
    'showEmail',
    'showEmailHint',
    'showPassword',
    'showPrivacyCheckBox',
    'loadCompanyRegistrationComplete',
    'saveCompanyRegistrationComplete',
    'submitLabel',
    'uiDisableCaptcha',
  ];
  outputConfig = ['completeCompanyRegistrationSave'];
  @Input() submitLabel = 'USER.REGISTER.CREATE';
  private viewRemoteFieldErrorPlugin: ManualViewRemoteFieldErrorPlugin<any, any>;
  readonly telephoneMask = { mask: '000000000' };

  recaptchaImageWindowObserver: MutationObserver;
  recaptchaImageWindow: HTMLElement | null = null;

  constructor(
    private router: Router,
    private translocoService: TranslocoService,
    private authService: AuthService<UserModel>,
    private viewportService: ViewportService,
    private store: Store,
    private actions: Actions,
    @Inject(DOCUMENT) private document: Document,
    private messageDialogService: MessageDialogService,
    private cookieService: CookieService,
    private injector: Injector,
    private fingerprintService: FingerprintService,
    private cdr: ChangeDetectorRef
  ) {
    this.nameFormFragmentFormlyFields = userRegistrationNameFormFragmentDynamicConfig.config(this.injector);
  }

  private setAmericanVersionExtraFieldsConfigs() {
    this.companyNameControl.setValidators(null);
    this.companyNameControl.setErrors(null);
  }

  ngOnInit(): void {
    this.loading$
      .pipe(
        filter(() => this.form !== undefined),
        untilDestroyed(this)
      )
      .subscribe(loading => (loading ? this.form.disable() : this.form.enable()));

    this.initPasswordControlsValidators();

    if (this.isAmericanVersion) {
      this.setAmericanVersionExtraFieldsConfigs();
    }
    this.showEmail = coerceBooleanProperty(this.showEmail);
    this.showPassword = coerceBooleanProperty(this.showPassword);
    this.showPrivacyCheckBox = coerceBooleanProperty(this.showPrivacyCheckBox);
    this.uiDisableCaptcha = coerceBooleanProperty(this.uiDisableCaptcha);
    this.loadCompanyRegistrationComplete = coerceBooleanProperty(this.loadCompanyRegistrationComplete);
    this.saveCompanyRegistrationComplete = coerceBooleanProperty(this.saveCompanyRegistrationComplete);

    if (this.uiDisableCaptcha) {
      this.useCaptcha = false;
    }

    if (this.isAmericanVersion) {
      this.showPrivacyCheckBox = false;
      this.phoneNumberControl.setValidators(Validators.compose([Validators.required, phoneNumberValidator2]));
      this.phoneControlValidationMessages = [
        {
          errorKey: 'phoneNumber',
          translateKey: 'COMMON.VALIDATION.PHONE.NO_PHONE_NUMBER',
        },
      ];
    } else {
      this.phoneNumberControl.setAsyncValidators(phoneNumberValidator(this.isAmericanVersion === false ? 'HU' : 'US'));
    }
    this.AreaOfInterestEnum = Object.entries(this.isAmericanVersion === false ? AreaOfInterestEnum : AreaOfInterestUSEnum).filter(
      entry => !isNumeric(entry[0])
    );

    const form: any = {
      companyName: this.companyNameControl,
      phoneNumber: this.phoneNumberControl,
      magazineSubscribe: this.magazineSubscribeControl,
      areaOfInterest: this.areaOfInterestControl,
    };
    if (this.showPassword) {
      form.password = this.passwordControl;
      form.confirmPassword = this.confirmPasswordControl;
    }
    if (this.showEmail) {
      form.email = this.emailControl;
    }
    if (this.showPrivacyCheckBox) {
      form.privacyStatement = this.privacyStatementControl;
    }

    this.form = new FormGroup(form);
    if (!this.loadCompanyRegistrationComplete) {
      // // sima reg mode
      // this.form.setValidators(mismatchedPasswords('password', 'confirmPassword'));
      // // BUGFIX: Formly miatt
      // this.form.setAsyncValidators((c: AbstractControl) => Promise.resolve(null));
    } else {
      // ha betoltunk adatokat pl social login miatt
      this.store.dispatch(new OldRegistrationLoadingAction(true));
      this.form.disable();
      this.authService.getCompanyRegistrationCompleteDatas().subscribe(
        (result: any) => {
          if (!isNil(result)) {
            this.form.patchValue(
              Object.entries(result).reduce((acc, next) => {
                acc[this.snakeToCamel(next[0])] = next[1];
                return acc;
              }, {})
            );
          }
          this.store.dispatch(new OldRegistrationLoadingAction(false)).subscribe(() => this.emailControl.disable());
        },
        commonHttpStreamErrorHandler(() => this.store.dispatch(new OldRegistrationLoadingAction(false)))
      );
    }

    this.viewRemoteFieldErrorPlugin = new ManualViewRemoteFieldErrorPlugin(this.translocoService, this.document, this.messageDialogService);
    this.viewRemoteFieldErrorPlugin.formGroupLastValue = this.form;

    this.cdr.detectChanges();
  }

  private snakeToCamel(str) {
    return str.replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''));
  }

  private camelToSnake(str) {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  }

  changeVisibilityPassword(): void {
    this.visiblePassword = !this.visiblePassword;
    this.passwordType === 'password' ? (this.passwordType = 'text') : (this.passwordType = 'password');
  }

  register(captchaResponse: string): void {
    if (this.saveCompanyRegistrationComplete) {
      const user: RegisterSocialModel = {
        first_name: CapitalizeDirective.capitalizeText(this.form.get('firstName').value),
        last_name: CapitalizeDirective.capitalizeText(this.form.get('lastName').value),
        company_name: this.companyNameControl.value,
        phone_number: this.phoneNumberControl.value,
        area_of_interest: +this.areaOfInterestControl.value,
        is_magazine: this.form.value.magazineSubscribe,
        password: this.form.value.password,
        confirmed_password: this.form.value.confirmPassword,
      };
      this.authService.saveCompanyRegistrationCompleteDatas(user).subscribe(
        () => this.completeCompanyRegistrationSave.emit(),
        error => {
          if (error instanceof FieldHttpError) {
            this.convertFieldHttpErrorKeys(error);
            manualHandleFormControlsSetServerError(error, this.viewRemoteFieldErrorPlugin);
          }
          MaybeHandleHttpError.maybeHandleHttpError(error);
        }
      );
    } else {
      const user: any = {
        captcha_token: captchaResponse || undefined,
        email: this.emailControl.value,
        first_name: CapitalizeDirective.capitalizeText(this.form.get('firstName').value),
        is_magazine: this.form.value.magazineSubscribe,
        last_name: CapitalizeDirective.capitalizeText(this.form.get('lastName').value),
        name: this.companyNameControl.value,
        password: this.passwordControl.value,
        phone_number: this.phoneNumberControl.value,
        username: this.emailControl.value,
        area_of_interest: +this.areaOfInterestControl.value,
        referrer: getRegisterReferer(this.cookieService, this.document),
        optimonk_referrer: getRegisterOptimonkReferer(this.cookieService, this.document),
        device_fingerprint: this.fingerprintService.getCode(),
      };

      const successActionSubscription = this.actions
        .pipe(ofActionSuccessful(OldRegistrationSuccessAction), take(1), untilDestroyed(this))
        .subscribe(action => {
          canRegisterSuccessFulVisiblePage.visible = true;
          this.router.navigate(['/registration-successful'], { queryParams: { email: this.emailControl.value } });
        });

      this.actions
        .pipe(ofActionDispatched(OldRegistrationErrorAction), take(1), untilDestroyed(this))
        .subscribe((action: OldRegistrationErrorAction) => {
          successActionSubscription.unsubscribe();
          this.form.enable();
          if (this.useCaptcha) {
            this.runCaptchaReset = true;
            this.captchaRef.reset();
          }
          // kezzel kezeljuk kulon a mezo szintu hibakat
          if (action.error instanceof FieldHttpError) {
            this.convertFieldHttpErrorKeys(action.error);
            manualHandleFormControlsSetServerError(action.error, this.viewRemoteFieldErrorPlugin);
          }
          MaybeHandleHttpError.maybeHandleHttpError(action.error, () => {
            const conflict = 409;
            if (action.error.status !== undefined && action.error.status === conflict) {
              fromForgotPassword.is = true;
              asapScheduler.schedule(() =>
                HandleErrorObject.handleErrorObject(action.error).subscribe(() => this.router.navigate([`/${USER_PASSWORD_RESENT}`]))
              );
            }
          });
        });

      // ideiglenesen, amig nem lesz megcsinalva a cookie
      if (this.cookieService.check('_fbp')) {
        user.client_fbp = this.cookieService.get('_fbp');
      }
      // ideiglenesen, amig nem lesz megcsinalva a cookie
      if (this.cookieService.check('_fbc')) {
        user.client_fbc = this.cookieService.get('_fbc');
      }

      this.store.dispatch(new OldRegistrationAction(user));
    }
  }

  private convertFieldHttpErrorKeys(error: FieldHttpError) {
    error.error.errors = Object.entries(error.error.errors).reduce((prev, curr) => {
      prev[this.snakeToCamel(curr[0])] = curr[1];
      return prev;
    }, {});
  }

  onKeyupForm($event): boolean | void {
    $event.stopPropagation();
    $event.preventDefault();

    this.onSubmit();

    return false;
  }

  @HostListener('@enterAnimation.done')
  onEnterDone(): void {
    this.viewportService.routerAnimation = false;
  }

  @HostListener('@enterAnimation.start')
  onLeaveStart(): void {
    this.viewportService.routerAnimation = true;
  }

  private initPasswordControlsValidators(): void {
    this.passwordControl = new FormControl(undefined);
    this.confirmPasswordControl = new FormControl(undefined);
    const passwordFieldsValidationsRules = [
      Validators.required,
      PasswordValidators.digitCharacterRule(1),
      PasswordValidators.lowercaseCharacterRule(1),
      PasswordValidators.uppercaseCharacterRule(1),
      UniversalValidators.noWhitespace,
      Validators.minLength(8),
      Validators.maxLength(20),
      mismatchedRePassword(this.confirmPasswordControl),
    ];
    this.passwordControl.setValidators(passwordFieldsValidationsRules);
    this.confirmPasswordControl.setValidators(Validators.compose([Validators.required, mismatchedPassword(this.passwordControl)]));
  }

  private initRecaptchaImageWindow() {
    if (isNil(this.recaptchaImageWindowObserver)) {
      // @ts-ignore
      this.recaptchaImageWindow = [...document.querySelectorAll('iframe')].find(iframe =>
        iframe.src.includes('google.com/recaptcha/api2/bframe')
      )?.parentNode?.parentNode as HTMLDivElement;

      if (!isNil(this.recaptchaImageWindow)) {
        this.recaptchaImageWindowObserver = new MutationObserver(() => {
          if (
            this.recaptchaImageWindow.style.visibility !== 'visible' ||
            this.recaptchaImageWindow.style.opacity !== '1' ||
            this.recaptchaImageWindow.style.top !== '10px'
          ) {
            this.recaptchaImageWindow.style.top = '10px';
            this.recaptchaImageWindow.style.opacity = '1';
            this.recaptchaImageWindow.style.visibility = 'visible';
          }
        });
        this.recaptchaImageWindowObserver.observe(this.recaptchaImageWindow, { attributeFilter: ['style'] });
      }
    }
  }

  onSubmit(): void {
    this.form.submitted = true;
    if (this.form.invalid || this.store.selectSnapshot<boolean>(states => states.registerPage.loading) === true) {
      return;
    }
    this.form.disable();
    if (this.useCaptcha) {
      this.captchaRef.execute();
      /*
      this.initRecaptchaImageWindow();
*/
    } else {
      this.register('');
    }
  }

  onResolveCaptcha($event: string): void {
    if (this.runCaptchaReset === true) {
      this.runCaptchaReset = false;
      return;
    }
    if ($event === null) {
      this.messageDialogService
        .openError({ id: null, text: 'COMMON.GOOGLE_RECAPTCHA_ERROR', htmlMode: true })
        .afterClosed()
        .subscribe(() => this.form.enable());
      return;
    }
    this.register($event);
  }

  ngAfterViewInit(): void {
    timer(0).subscribe(() => {
      if (this.authService.unauthFirstAnimDisabled === true) {
        this.authService.unauthFirstAnimDisabled = false;
        this.disabledAnimation = this.authService.unauthFirstAnimDisabled;
      }
    });
  }

  onClickLoginWithEmail() {
    this.router.navigate(['login']);
  }

  parseUsPhoneNumber(value: string) {
    return value.replace(new RegExp(/-/, 'g'), '').replace(new RegExp(/\(/, 'g'), '').replace(new RegExp(/\)/, 'g'), '');
  }
}
