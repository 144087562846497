import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class OpenNewOrModifyQueryParamCheckGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const result = next.queryParamMap.get('selectedDay') !== null && next.queryParamMap.get('selectedDay').length > 0;
    if (result === false) {
      const splittedUrl = state.url.split('/');
      splittedUrl.pop();
      this.router.navigate([splittedUrl.join('/')]);
    }
    return result;
  }
}
