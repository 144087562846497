import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { ReportPrintChangeFilterAction } from '../state/action/report-print-change-filter.action';
import { ReportPrintStatusEnum } from '../state/model/report-print-status.enum';

@Component({
  selector: 'rr-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterPanelComponent {
  ReportPrintStatusEnum = ReportPrintStatusEnum;

  @Input()
  currentFilter: ReportPrintStatusEnum | 'ALL';
  @Input()
  countAll: number;
  @Input()
  run: boolean;
  @Input()
  countOK: number;
  @Input()
  countWarning: number;
  @Input()
  countNotChecked: number;
  @Input()
  countError: number;
  @Input()
  disabled = false;

  constructor(private store: Store) {}

  changeFilter(status: ReportPrintStatusEnum | 'ALL'): void {
    this.store.dispatch(new ReportPrintChangeFilterAction(status));
  }
}
