import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckModifiedMVPFormGuard, ID_NUMBER_CHECK_PROVIDERS, ParamTypeCheckGuard } from '@roadrecord/utils';
import { DetailsComponent } from './details.component';
import { InvalidPeriodContextGuard } from '@roadrecord/period-context/common';

const routes: Routes = [
  { path: 'new', component: DetailsComponent, canDeactivate: [InvalidPeriodContextGuard, CheckModifiedMVPFormGuard] },
  {
    path: ':id',
    component: DetailsComponent,
    canActivate: [ParamTypeCheckGuard],
    canDeactivate: [InvalidPeriodContextGuard, CheckModifiedMVPFormGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [ID_NUMBER_CHECK_PROVIDERS],
})
export class DetailsRoutingModule {}
