import { Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { BehaviorSubject, race } from 'rxjs';
import { MessageDialogComponent } from './message-dialog.component';
import { MessageDialogOptionsInterface } from './model/message-dialog-options.interface';
import { MessageDialogOptionsModel } from './model/message-dialog-options.model';
import { MessageDialogResultInterface } from './model/message-dialog-result.interface';
import { MessageDialogTypeEnum } from './model/message-dialog-type.enum';
import { skip, take } from 'rxjs/operators';
import { isNil } from '@roadrecord/type-guard';

@Injectable()
export class MessageDialogService {
  constructor(private _matDialog: MatDialog, private store: Store, private actions$: Actions, private ngZone: NgZone) {
    MessageDialogService._INSTANCE = this;
  }

  private static _INSTANCE: MessageDialogService;

  static get INSTANCE(): MessageDialogService {
    return this._INSTANCE;
  }

  private _openedDialogRef: MatDialogRef<MessageDialogComponent, MessageDialogResultInterface>;

  get openedDialogRef(): MatDialogRef<MessageDialogComponent, MessageDialogResultInterface> {
    return this._openedDialogRef;
  }

  get matDialog(): MatDialog {
    return this._matDialog;
  }

  openInformation(
    options: MessageDialogOptionsInterface,
    dialogOptions: MatDialogConfig = {}
  ): MatDialogRef<MessageDialogComponent, MessageDialogResultInterface> {
    return this.open({ ...options, type: MessageDialogTypeEnum.INFORMATION }, dialogOptions);
  }

  openWarning(
    options: MessageDialogOptionsInterface,
    dialogOptions: MatDialogConfig = {}
  ): MatDialogRef<MessageDialogComponent, MessageDialogResultInterface> {
    return this.open({ ...options, type: MessageDialogTypeEnum.WARNING }, dialogOptions);
  }

  openError(
    options: MessageDialogOptionsInterface,
    dialogOptions: MatDialogConfig = {}
  ): MatDialogRef<MessageDialogComponent, MessageDialogResultInterface> {
    return this.open({ ...options, type: MessageDialogTypeEnum.ERROR }, dialogOptions);
  }

  open(
    options: MessageDialogOptionsInterface,
    dialogOptions: MatDialogConfig = {}
  ): MatDialogRef<MessageDialogComponent, MessageDialogResultInterface> {
    options = new MessageDialogOptionsModel(options);

    if (options.enableForeverHideFeature) {
      const resultObj: any = {};
      const result$ = new BehaviorSubject(null);
      const actionType = '[User settings] Get forever hide settings';
      const actionTypeSuccess = `${actionType} Success`;
      race([
        this.actions$.pipe(ofActionSuccessful({ type: `${actionType} Error` })),
        this.actions$.pipe(ofActionSuccessful({ type: actionTypeSuccess })),
      ])
        .pipe(take(1))
        .subscribe((result: { type: string; response?: { value?: string[] } }) => {
          if (!isNil(result.response) && Array.isArray(result.response.value)) {
            if ((result.response.value as string[]).indexOf(options.id) === -1) {
              const ref = this.openDialog(dialogOptions, options);
              ref.afterClosed().subscribe(dialogResult => result$.next(dialogResult));
            } else {
              result$.next({ result: true, foreverHideDialog: true });
            }
          }
        });
      this.store.dispatch({ type: actionType });
      // if (this.store.selectSnapshot(UserSettingsState.showMessageModal(options.id))) {
      //   return this.openDialog(dialogOptions, options);
      // }
      // return { afterClosed: () => of({ result: true, foreverHideDialog: true }) } as any;
      resultObj['afterClosed'] = () => result$.pipe(skip(1), take(1));
      return resultObj;
    }
    return this.openDialog(dialogOptions, options);
  }

  private openDialog(
    dialogOptions: MatDialogConfig,
    options: MessageDialogOptionsInterface | MessageDialogOptionsModel
  ): MatDialogRef<MessageDialogComponent, MessageDialogResultInterface> {
    if (dialogOptions.panelClass === undefined) {
      dialogOptions.panelClass = 'message-dialog';
    } else {
      if (Array.isArray(dialogOptions.panelClass)) {
        dialogOptions.panelClass.push('message-dialog');
      } else {
        dialogOptions.panelClass = [dialogOptions.panelClass, 'message-dialog'];
      }
    }

    if (NgZone.isInAngularZone()) {
      return this._openDialog(dialogOptions, options);
    } else {
      return this.ngZone.run(() => this._openDialog(dialogOptions, options));
    }
  }

  private _openDialog(
    dialogOptions: MatDialogConfig,
    options: MessageDialogOptionsInterface | MessageDialogOptionsModel
  ): MatDialogRef<MessageDialogComponent, MessageDialogResultInterface> {
    this._openedDialogRef = this._matDialog.open(MessageDialogComponent, {
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      restoreFocus: true,
      minWidth: 300,
      maxWidth: 800,
      ...dialogOptions,
      data: options,
    });

    this._openedDialogRef.afterClosed().subscribe(result => {
      // remove ref when close dialog
      delete this._openedDialogRef;
      // if enabled feature, and checkbox checked, dispatch action
      if (options.enableForeverHideFeature && !isNil(result.foreverHideDialog) && result.foreverHideDialog) {
        this.store.dispatch({ type: '[User settings] Add forever hide message modal', id: options.id });
      }
    });

    return this._openedDialogRef;
  }
}
