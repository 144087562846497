import { PeriodContextStateHuModel } from '../model/period-context-state-hu.model';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PeriodContextHuState } from '../period-context-hu.state';
import { PeriodContextHuModel } from '../model/period-context-hu.model';
import { TripReportProcessTypeEnum } from '@roadrecord/trip-reason/model';

@Injectable()
export class PeriodContextStateSelectorsHuService extends PeriodContextStateSelectorsService<
  PeriodContextHuModel,
  PeriodContextStateHuModel
> {
  @Selector([PeriodContextHuState])
  runVehiclePull(state: PeriodContextStateHuModel): boolean {
    return super.runVehiclePull(state);
  }

  @Selector([PeriodContextHuState])
  loading(state: PeriodContextStateHuModel): boolean {
    return super.loading(state);
  }

  @Selector([PeriodContextHuState])
  state(state: PeriodContextStateHuModel): PeriodContextStateHuModel {
    return super.state(state);
  }

  @Selector([PeriodContextHuState])
  context(state: PeriodContextStateHuModel): PeriodContextHuModel {
    return super.context(state);
  }

  @Selector([PeriodContextHuState])
  hasContext(state: PeriodContextStateHuModel): boolean {
    return super.hasContext(state);
  }

  @Selector([PeriodContextHuState])
  viewDate(state: PeriodContextStateHuModel): Date {
    return super.viewDate(state);
  }

  @Selector([PeriodContextHuState])
  reportType(state: PeriodContextStateHuModel): TripReportProcessTypeEnum {
    return super.reportType(state);
  }

  @Selector([PeriodContextHuState])
  isCompany(state: PeriodContextStateHuModel): boolean {
    return super.isCompany(state);
  }

  @Selector([PeriodContextHuState])
  isPrivate(state: PeriodContextStateHuModel): boolean {
    return super.isPrivate(state);
  }

  @Selector([PeriodContextHuState])
  isSelfEmployed(state: PeriodContextStateHuModel): boolean {
    return super.isSelfEmployed(state);
  }
}
