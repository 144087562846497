import { Injectable, Injector } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslocoService } from '@ngneat/transloco';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as moment_ from 'moment';
import { asapScheduler, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SelectLanguageAction } from './action/select-language.action';
import { SetLanguageOptionsSuccessAction } from './action/set-language-options-success.action';
import { SetLanguageOptionsAction } from './action/set-language-options.action';
import { I18nStateModel } from './model/i18n-state.model';
import { LOCALESgetValues } from '@roadrecord/environment';

const moment = moment_;

@State<I18nStateModel>({
  name: 'i18n',
  defaults: {
    selectedLang: undefined,
    version: 3,
  },
})
@Injectable()
export class I18nState {
  private translocoSubscription: Subscription;

  constructor(private translocoService: TranslocoService, private injector: Injector) {}

  @Selector()
  static selectedLang(state: I18nStateModel): string {
    return state.selectedLang;
  }

  @Action(SelectLanguageAction)
  selectLanguage({ patchState, dispatch }: StateContext<I18nStateModel>, { lang }: SelectLanguageAction) {
    if (LOCALESgetValues().indexOf(lang) > -1) {
      patchState({ selectedLang: lang });
    } /*else {
      // set default
      patchState({ selectedLang: getFeatureFlagStatus('AMERICAN_MODE') ? LOCALES.EN_US : this.currentEnvironment.defaultLocale });
    }*/

    asapScheduler.schedule(() => dispatch(new SetLanguageOptionsAction()));
  }

  @Action(SetLanguageOptionsAction)
  setLanguageOptions({ getState, dispatch }: StateContext<I18nStateModel>) {
    const selectedLang = getState().selectedLang;
    this.translocoService.setActiveLang(selectedLang);
    if (this.translocoSubscription !== undefined && !this.translocoSubscription.closed) {
      this.translocoSubscription.unsubscribe();
    }
    this.translocoSubscription = this.translocoService
      .load(selectedLang)
      .pipe(take(1))
      .subscribe(() => {
        this.translocoService.setActiveLang(selectedLang);
      });
    moment.locale(selectedLang.split('-')[0]);
    this.injector.get(DateAdapter).setLocale(selectedLang.split('-')[0]);
    dispatch(new SetLanguageOptionsSuccessAction());
  }
}
