<div class="inside-scroll background-image-1">
  <div class="static-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex.gt-md="36" fxFlex="90">
      <div class="static-content" fxLayout="column" fxLayoutAlign="center center">
        <img class="static-icon" fxFlexOffset="15px" [src]="'assets/images/registration/pipay.png'" alt="image" />
        <mat-card>
          <mat-card-title>{{ translateKeyPrefix + 'TITLE' | transloco }}</mat-card-title>
          <mat-card-content>
            <p class="static-description info-1">{{ translateKeyPrefix + 'ROW' | transloco: param }}</p>
          </mat-card-content>
          <mat-card-actions
            ><button
              type="button"
              mat-raised-button
              color="primary"
              (click)="onStartApp()"
              [disabled]="disableButton || currentNumber === 0"
            >
              {{ buttonText ? buttonText : (translateKeyPrefix + 'BUTTON' | transloco) }}
            </button></mat-card-actions
          >
        </mat-card>
      </div>
    </div>
  </div>
  <rr-unauthenticated-footer></rr-unauthenticated-footer>
</div>
