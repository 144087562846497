import { Pipe, PipeTransform } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MenuItemModel } from '../model/menu-item.model';
import { MenuPageModel } from '../model/menu-page.model';

const cacheItems$: WeakMap<MenuPageModel, Observable<MenuItemModel[]>> = new WeakMap();

@Pipe({
  name: 'getItem',
})
export class GetItemPipe implements PipeTransform {
  transform(menuPage: MenuPageModel, args: { excludedItems$: Observable<string[]>; disabledMenuItems$: Observable<string[]> }): any {
    if (cacheItems$.has(menuPage)) {
      return cacheItems$.get(menuPage);
    }
    const stream = combineLatest(
      of(menuPage).pipe(switchMap((_menuPage: MenuPageModel) => _menuPage.items$)),
      args.excludedItems$,
      args.disabledMenuItems$
    ).pipe(
      map((state: [MenuItemModel[], string[], string[]]) => {
        // exclude szures
        const items =
          state[1] === undefined || state[1].length === 0 ? state[0] : state[0].filter(item => state[1].indexOf(item.uniqId) === -1);
        // disable szures
        return items.map(item => {
          if (state[2].indexOf(item.uniqId) > -1) {
            item = { ...item, disabled: true };
          }
          return item;
        });
      }),
      // hide szures
      map(items => items.filter(item => item.hide === false && item.hidden === false))
    );
    cacheItems$.set(menuPage, stream);
    return stream;
  }
}
