import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { isNumeric, isString } from '@roadrecord/type-guard';
import { BehaviorSubject, timer } from 'rxjs';

@Component({
  selector: 'rr-draggable-dialog',
  templateUrl: './draggable-dialog.component.html',
  styleUrls: ['./draggable-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraggableDialogComponent {
  get counter(): number {
    return this._counter;
  }
  url: SafeUrl;
  maximized$ = new BehaviorSubject<boolean>(false);
  @ViewChild('container', { static: true })
  container: ElementRef;
  @Output()
  closeDialog = new EventEmitter<void>();
  collapsed = false;
  @Input()
  title: string;
  resizable = true;
  defaultWidth = '60%';
  defaultHeight = '60%';
  @Input() showMaximizeAndMinimizeButtons = true;
  @Input() width: string;
  @Input() height: string;
  @Input() minWidth = '200px';
  @Input() maxWidth = '100%';
  defaultRzHandles = 'se,sw,ne,nw';
  @Input() rzHandles: string;
  @Input() collapseTooltipTranslate = 'COMMON.DIALOG.TOOLTIP.COLLAPSE';
  @Input() collapseTooltipOpenTranslate = 'COMMON.DIALOG.TOOLTIP.COLLAPSE_OPEN';
  @Input() minimizeTooltipTranslate = 'COMMON.DIALOG.TOOLTIP.MINIMIZE';
  @Input() maximizeTooltipTranslate = 'COMMON.DIALOG.TOOLTIP.MAXIMIZE';
  @Input() closeTooltipTranslate = 'COMMON.DIALOG.TOOLTIP.CLOSE';
  @Input() active: boolean;
  @Output() activate = new EventEmitter<void>();
  private storeSize: { width: number; height: number; top: string; left: string; transform: string };
  private closedToggleContainerOriginalHeight: string;
  /**
   * azert kell mert ha valtozik az active azzal a counter is es igy ugral az ablak
   */
  counterFirstValue: number;

  constructor(@Inject(DOCUMENT) readonly document: Document, private readonly sanitizer: DomSanitizer) {}

  private _counter: number;

  @Input()
  set counter(value: number) {
    this._counter = value;
    if (!isNumeric(this.counterFirstValue)) {
      this.counterFirstValue = value;
    }
  }

  @Input()
  set videoUrl(value: string) {
    if (isString(value)) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(value);
    } else {
      this.url = undefined;
    }
  }

  maximize(): void {
    this.maximized$.next(true);
    this.resizable = false;
    const container: HTMLDivElement = this.container.nativeElement;
    container.style.transition = 'all 1s ease';

    this.storeSize = {
      width: container.offsetWidth,
      height: container.offsetHeight,
      top: container.style.top,
      left: container.style.left,
      transform: container.style.transform,
    };
    container.style.width = '100%';
    if (!this.collapsed) {
      container.style.height = 'calc(100% - 80px)';
    }
    container.style.top = '0';
    container.style.left = '0';
    container.style.transform = 'translate(0, 0)';
    timer(1000).subscribe(() => (container.style.transition = null));
  }

  minimize(): void {
    this.maximized$.next(false);
    this.resizable = true;
    const container: HTMLDivElement = this.container.nativeElement;
    container.style.transition = 'all 1s ease';
    container.style.width = `${this.storeSize.width}px`;
    if (!this.collapsed) {
      container.style.height = `${this.storeSize.height}px`;
    }
    container.style.top = this.storeSize.top;
    container.style.left = this.storeSize.left;
    container.style.transform = this.storeSize.transform;
    timer(1000).subscribe(() => (container.style.transition = null));
  }

  onClose(): void {
    this.closeDialog.emit();
  }

  toggle(): void {
    this.collapsed = !this.collapsed;
    this.resizable = !this.collapsed;
    const container: HTMLDivElement = this.container.nativeElement;
    container.style.transition = 'all 1s ease';
    if (this.collapsed) {
      this.closedToggleContainerOriginalHeight = container.style.height;
      container.style.height = '0px';
    } else {
      container.style.height = this.closedToggleContainerOriginalHeight;
    }
    timer(1000).subscribe(() => (container.style.transition = null));
  }

  onClickContainer() {
    if (this.active === false) {
      this.activate.emit();
    }
  }

  onStartedDrag() {
    this.onClickContainer();
  }
}
