import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractEntityService, BatchRequestModel, HttpListResponseModel } from '@roadrecord/utils';
import { MileageListModel, MileageModel } from '@roadrecord/mileage/model';
import { Observable } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { isNil } from '@roadrecord/type-guard';
import { environment } from '@roadrecord/environment';

@Injectable()
export class MileageListService extends AbstractEntityService<HttpListResponseModel<MileageListModel>, MileageModel> {
  constructor(http: HttpClient) {
    super('mileage/list', '-mileage_datetime', http, false, true);
  }

  getToString(model: MileageModel): string {
    return model.date;
  }

  getModelIdValue(model: MileageModel): any {
    if (model !== undefined && model.hasOwnProperty('id') && !isNil(model.id)) {
      return model.id;
    }
    return undefined;
  }

  removeBatch(id: string | number): BatchRequestModel {
    return { method: 'delete', relative_url: `/${environment.baseApi3Endpoint}/${this._entityUrl}/${id}/` };
  }

  getAll(
    sort: Sort = { active: '', direction: 'asc' },
    page: PageEvent = {
      pageIndex: 1,
      pageSize: 99999999,
      length: 1,
    },
    simpleAllFilterValue: string = ''
  ): Observable<HttpListResponseModel<MileageListModel>> {
    this.nextRequestUseApiUrl3 = true;
    return super.getAll(sort, page, simpleAllFilterValue);
  }
}
