import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ToBooleanPipe } from './to-boolean.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [ToBooleanPipe],
  exports: [ToBooleanPipe],
})
export class ToBooleanModule {}
