import { Attribute, Component, forwardRef, HostBinding, Input, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NoopFunctionType } from '../noop/noop-function.type';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

export const TWO_WAY_SWITCH_FORM_ELEMENT_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line:no-use-before-declare
  useExisting: forwardRef(() => TwoWaySwitchFormControlComponent),
  multi: true,
};

@Component({
  selector: 'rr-two-way-switch-form-control',
  templateUrl: './two-way-switch.form-control.component.html',
  styleUrls: ['./two-way-switch.form-control.component.scss'],
  providers: [TWO_WAY_SWITCH_FORM_ELEMENT_ACCESSOR],
})
export class TwoWaySwitchFormControlComponent implements ControlValueAccessor {
  /**
   * checked erteket taroljuk benne
   */
  @Input() value: boolean;
  @Input() option_false: any;
  @Input() option_true: any;
  @Input() option_false_tooltip: string;
  @Input() option_true_tooltip: string;
  @Input() tooltip_false: any;
  @Input() tooltip_true: any;
  @HostBinding('class.disabled')
  @Input()
  disabled = false;
  @Input() saveNumberTypeMode = false;
  @Input() icon_name_false: string;
  @Input() icon_name_true: string;
  /**
   * akkor kell hasznalni ha nem lehetseges kozvetlen megadni a name-t, pl: name="valami"
   * ha bindolni kell akkor ezt kell hasznalni
   */
  @Input() inputAttrName: string;
  private propagateOnChange: (value: boolean) => void;
  private propagateOnTouched: NoopFunctionType;
  @Input() color: 'accent' | 'primary' = 'accent';

  constructor(@Attribute('name') readonly name: string) {}

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateOnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  setCheck(value: boolean): void {
    if (this.disabled) {
      return;
    }
    if (this.saveNumberTypeMode) {
      this.value = (value === true ? 1 : 0) as any;
    } else {
      this.value = value;
    }
    this.propagateOnChange(this.value);
    this.propagateOnTouched();
  }

  setCheckFromEvent(event: MatSlideToggleChange): void {
    this.setCheck(event.checked);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
