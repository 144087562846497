import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { checkModifiedForm } from '../check-modify/function/check-modified-form.function';
import { CheckModifiedManualForm } from '../check-modify/check-modified-manual-form.class';
import { CheckModifiedManualFormGuard } from '../check-modify/check-modified-manual-form-guard.service';
import { Observable } from 'rxjs';
import { HasPresenterSavePluginController } from '../controller/plugin/presenter-save/has-presenter-save-plugin.interface';
import { VIEW_MODEL_PLUGIN_RESET_STATE } from '../controller/plugin/view-model/view-model.plugin';
import { Store } from '@ngxs/store';
import { isLazyDetailsCmp } from '@roadrecord/lazy-details';
import { map } from 'rxjs/operators';
import { ViewSubmitPlugin } from '../controller/plugin/view-submit/view-submit.plugin';

@Injectable({ providedIn: 'root' })
/**
 * RR MVP form -nal vizsgaljuk hogy valtozott-e a form ami nincs elmentve, ha igen
 * akkor figyelmeztetest dobunk es megadalyozzuk a route -rol valo tavozast
 *
 * Amennyiben a component implementalja a CheckModifiedManualForm osztalyt akkor a CheckModifiedManualFormGuard.check -vel vizsgalodik
 */
export class CheckModifiedMVPFormGuard implements CanDeactivate<any> {
  constructor(private store: Store) {}

  disableNextCheck = false;

  canDeactivate(
    _component: HasPresenterSavePluginController<any, any>,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.store.selectSnapshot(states => states.menu).invalid_period_context !== 0 /*InvalidPeriodContextEnum.DISABLED*/) {
      // invalid period context mod eseten nem kezelunk semmit, mert hatterben redirecteljuk a usert
      return true;
    }

    if (this.disableNextCheck) {
      this.disableNextCheck = false;
      return true;
    }

    let component = _component;
    if (isLazyDetailsCmp(_component)) {
      component = _component.lazyCmp;
    }

    const presenterStateController = (component as HasPresenterSavePluginController<any, any>).presenterStateController;
    const viewSubmitPluginId = (presenterStateController.get(ViewSubmitPlugin) as ViewSubmitPlugin<any, any>).id;

    if (component instanceof CheckModifiedManualForm) {
      return CheckModifiedManualFormGuard.check(component as any, viewSubmitPluginId).pipe(map(result => result.result));
    }

    const formValue = presenterStateController.formGroupLastValue.getRawValue();
    const newFormValue = {};
    /**
     * kis hack mert mi undefined-t hasznalunk de a material form null-t add vissza :( a formModel getternel
     */
    // tslint:disable-next-line
    Object.entries(formValue).forEach(entry => (newFormValue[entry[0]] = entry[1] === null ? undefined : entry[1]));

    let resetModel: any;
    if (presenterStateController.editModelLastValue) {
      resetModel = presenterStateController.editModelLastValue;
    } else {
      resetModel = presenterStateController.get(VIEW_MODEL_PLUGIN_RESET_STATE);
    }

    return checkModifiedForm({
      formModel: newFormValue,
      originalModel: resetModel,
      routedDataFormCmpRef: component.dataForm,
      viewSubmitPluginId,
    }).pipe(map(result => result.result));
  }
}
