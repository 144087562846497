import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { commonHttpStreamErrorHandler, ENTITY_SERVICE_TOKEN } from '@roadrecord/utils';
import {
  generateDefaultGridHeaderOtherActions,
  GridComponent,
  gridDefaultRightClickOption,
  GridHeaderOtherAction,
  GridRightClickOptionModel,
} from '@roadrecord/grid';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { CompanyMemberService } from '../company-member.service';
import { CompanyMemberStatusEnum } from './../model/company-member-status.enum';
import { listColumnConfig } from './list-column.config';
import { secureDialogFullNameLabelKeyFn } from './secure-dialog-full-name-label-key.function';

@Component({
  selector: 'rr-list',
  templateUrl: './list.component.html',
  providers: [CompanyMemberService, { provide: ENTITY_SERVICE_TOKEN, useExisting: CompanyMemberService }],
})
export class ListComponent implements AfterViewInit {
  displayedColumns = listColumnConfig(this);
  @ViewChild('rrGrid', { static: true })
  rrGrid: GridComponent;
  rightClickContextMenuOption: GridRightClickOptionModel<any>;
  secureDialogFullNameLabelKeyFn = secureDialogFullNameLabelKeyFn;
  headerOtherActions: GridHeaderOtherAction<any>[];

  constructor(
    readonly translocoService: TranslocoService,
    private companyUserService: CompanyMemberService,
    private messageDialogService: MessageDialogService,
    private matSnackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    const rightClickOptions = gridDefaultRightClickOption(this.rrGrid);
    const rowsConfig = [...rightClickOptions.rows];
    // SELECTED_DELETE kivetele
    rowsConfig.splice(
      rowsConfig.findIndex(rowConfig => rowConfig.label.indexOf('SELECTED_DELETE') > -1),
      1
    );
    this.rightClickContextMenuOption = {
      grid: rightClickOptions.grid,
      rows: rowsConfig,
    };
    this.cdr.detectChanges();

    const headerOtherActions = generateDefaultGridHeaderOtherActions<any>(this.rrGrid);
    headerOtherActions.push({
      type: 'ITEM',
      icon: 'email-send-outline',
      label: { translate: 'COMPANY_MEMBER.LIST.FAB.RESEND_INVITE.TOOLTIP' },
      action: () => {
        this.rrGrid.isLoadingResults = true;
        const email = this.rrGrid.selection.selected[0].email;
        this.companyUserService.resendInvite(email).subscribe(
          () => {
            this.rrGrid.isLoadingResults = false;
            this.matSnackBar.open(this.translocoService.translate('COMPANY_MEMBER.RESEND_INVITE.SUCCESS_RESEND', { email }));
          },
          commonHttpStreamErrorHandler(() => (this.rrGrid.isLoadingResults = false))
        );
      },
      disabled: () => {
        const selected = this.rrGrid.selection.selected;
        return (
          this.rrGrid.isLoadingResults ||
          selected.length === 0 ||
          selected.length > 1 ||
          this.rrGrid.selection.selected[0].status !== CompanyMemberStatusEnum.INVITED
        );
      },
    });
    this.headerOtherActions = headerOtherActions;
  }
}
