import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { CompanyContextState } from '@roadrecord/company-context/common';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isNil } from '@roadrecord/type-guard';
import { PricePaymentSessionStorageEnum } from '../../price/model/local-storage.enum';

/**
 * Layout ki/belepest szabalyozzuk
 */
@Injectable()
export class TrialTimeExpiredLayoutGuard implements CanActivate, CanDeactivate<any> {
  constructor(private store: Store, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.getIsTrialTimeExpired$().pipe(
      map(isExpiredTrialTime => {
        const payment_in_trial_time = isNil(sessionStorage.getItem(PricePaymentSessionStorageEnum.PRICE_PAYMENT_IN_TRIAL_TIME));
        if (isExpiredTrialTime === false && payment_in_trial_time) {
          return this.router.createUrlTree(['/']);
        }
        return isExpiredTrialTime || !payment_in_trial_time;
      })
    );
  }

  canDeactivate(): Observable<boolean> {
    return this.getIsTrialTimeExpired$().pipe(map(isExpiredTrialTime => !isExpiredTrialTime));
  }

  private getIsTrialTimeExpired$() {
    return this.store.select(CompanyContextState.isTrialTimeExpired);
  }
}
