import { isNotDeletable, isNotEditable } from '@roadrecord/common/common';
import { RightClickMenuItemModel } from '@roadrecord/right-click-menu';
import { GridComponent } from '../../grid/grid.component';
import { GridRightClickOptionModel } from './grid-right-click-option.model';

export function gridDefaultRightClickOption<T>(_this: GridComponent): GridRightClickOptionModel<T> {
  const addItem: RightClickMenuItemModel<T> = {
    label: 'COMMON.ACTION.ADD',
    icon: 'plus',
    click: () => _this.router.navigate(['./new'], { relativeTo: _this.route }),
  };
  return {
    grid: [addItem],
    rows: [
      {
        label: 'COMMON.ACTION.DELETE',
        icon: 'delete',
        enabled: $event => {
          return !_this.isLoadingResults && ($event ? !isNotDeletable($event) : true);
        },
        click: $event => _this.deleteSelectedElements({ deleteElements: [$event.item] }),
        disabledText: 'Nem törölhető',
      },
      {
        label: 'COMMON.ACTION.SELECTED_DELETE',
        icon: 'delete',
        visible: () => _this.selection.selected !== undefined && _this.selection.selected.length > 1,
        click: $event => _this.deleteSelectedElements({ deleteElements: _this.selection.selected }),
      },
      {
        label: 'COMMON.ACTION.EDIT',
        icon: 'pencil',
        click: $event => _this.handleRowClick({ element: $event.item, index: -1 }),
        enabled: $event => !_this.isLoadingResults && ($event ? !isNotEditable($event) : true),
        disabledText: 'Nem módosítható',
      },
      { divider: true },
      addItem,
    ],
  };
}
