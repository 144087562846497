import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from '@roadrecord/type-guard';
import { MarketPopUpInfo } from '../models/market-popup-models';

@Pipe({
  name: 'markerPopUp',
})
export class MarkerPopUpPipe implements PipeTransform {
  transform(selectedMarker: MarketPopUpInfo, feature: GeoJSON.Feature): boolean {
    return !isNil(selectedMarker) && selectedMarker.rawPartnerInfo === feature.properties.partner;
  }
}
