import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UNIVERSAL_IMPORT_CONFIG, UniversalImportConfig } from '../../../model/universal-import-config';

@Component({
  selector: 'rr-finish-step',
  templateUrl: './finish-step.component.html',
  styleUrls: ['./finish-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinishStepComponent {
  readonly importListRoute: string;
  readonly translatePrefix: string;

  constructor(@Inject(UNIVERSAL_IMPORT_CONFIG) readonly importConfig: UniversalImportConfig<any>) {
    this.importListRoute = importConfig.importListRoute;
    this.translatePrefix = `${importConfig.translatePrefix}.UPLOAD_STEPPER.DONE.`;
  }
}
