<rr-grid
  [multiSelection]="true"
  [hasCheckboxColumn]="true"
  [columnDefinitions]="displayedColumns"
  [hasIconColumn]="false"
  [titleText]="['TRIP_REASON.LIST.TITLE', 'TRIP_REASON.LIST.SELECTED_TITLE']"
  titleIcon="book-open-page-variant"
  [hasCommonActionsColumn]="true"
  [hasCommonEditActionDisableCb]="hasActionEditDisableCb"
  [hasActionRemoveDisableCb]="hasActionRemoveDisableCb"
  [hasFilter]="true"
  gridFilterLabel="TRIP_REASON.LIST.FILTER_LABEL.LABEL"
  gridFilterPlaceholder="TRIP_REASON.LIST.FILTER_LABEL.PLACEHOLDER"
  gridFilterHint="TRIP_REASON.LIST.FILTER_LABEL.HINT"
  #rrGrid
></rr-grid>
