import { isObject } from '@roadrecord/type-guard';
import { Observable } from 'rxjs';

export const isObjectValue = () => <T>(source: Observable<T>) =>
  new Observable<T>(observer =>
    source.subscribe({
      next(x): void {
        if (isObject(x)) {
          observer.next(x);
        }
      },
      error(err): void {
        observer.error(err);
      },
      complete(): void {
        observer.complete();
      },
    })
  );
