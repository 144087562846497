import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { matSnackbarErrorConfigTopCenter } from '@roadrecord/common/common';
import { Observable } from 'rxjs';
import { hasAccessUniversalImportUploadFile } from './has-access-universal-import-upload-file';
import { UNIVERSAL_IMPORT_CONFIG, UniversalImportConfig } from '../model/universal-import-config';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable()
export class HasAccessUniversalImportUploadFileGuard implements CanActivate {
  constructor(
    private router: Router,
    private matSnackBar: MatSnackBar,
    private translocoService: TranslocoService,
    private gtmService: GoogleTagManagerService,
    @Inject(UNIVERSAL_IMPORT_CONFIG) private importConfig: UniversalImportConfig<any>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (hasAccessUniversalImportUploadFile.hasAccess) {
      return true;
    } else {
      this.matSnackBar.open(
        this.translocoService.translate('COMMON.ACCESS.NOT_ACCESS_MENU_ITEM'),
        undefined,
        matSnackbarErrorConfigTopCenter
      );
      return this.router.parseUrl(this.importConfig.importListRoute);
    }
  }
}
