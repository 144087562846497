export enum MILEAGE_RECORD_TYPE_ENUM {
  // kezdő km óra állás
  MONTHLY_STARTING_ODOMETER_READING,
  // hóvégi km óra állás
  END_MONTH_ODOMETER_READING,
  //  tankolás
  FILLING,
  // szerviz km óra állás
  FIX_ODOMETER_READING,
  // záró km óra állás
  MONTHLY_CLOSING_ODOMETER_READING = 4,
}

export namespace MILEAGE_RECORD_TYPE_ENUM {
  export function getEnumNameByValue(value: number) {
    return Object.entries(MILEAGE_RECORD_TYPE_ENUM)[value][1];
  }
}
