import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { PaymentRedirectResponseModel } from '../model/models';
import { PaymentStatus } from '../model/payment-status.enum';
import {
  BASIC_DATA_PAGE_PATH,
  mixinWithCounterButton,
  PRICE_SITE_PATH,
  USER_ROUTE_PATH,
  VEHICLE_ROUTE_PATH,
  WithCounterButton,
  WithCounterButtonCtor,
} from '@roadrecord/common/common';
import { MagicLinkPath, PricePaymentSessionStorageEnum } from '../model/local-storage.enum';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { Store } from '@ngxs/store';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatButton } from '@angular/material/button';
import { isNil } from '@roadrecord/type-guard';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { checkIsLoggedIn } from '../../authentication/guard/check-is-logged-in.function';

export class PaymentSuccessfulBase {
  constructor(readonly translateKeyPrefix: string, readonly translocoService: TranslocoService, readonly cdr: ChangeDetectorRef) {}
}

export const _PaymentSuccessfulBase: WithCounterButtonCtor & typeof PaymentSuccessfulBase = mixinWithCounterButton(PaymentSuccessfulBase);

@UntilDestroy()
@Component({
  selector: 'rr-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PaymentComponent extends _PaymentSuccessfulBase implements WithCounterButton, AfterViewInit {
  @Input()
  showInStepper = false;

  @Output() backToStepper = new EventEmitter<boolean>();

  responseText = '';
  isPaymentSuccess = false;

  @ViewChild('successButton') successButton: MatButton;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    cdr: ChangeDetectorRef,
    translocoService: TranslocoService,
    private gtmService: GoogleTagManagerService
  ) {
    super('PRICE.DATA_FORM.PAYMENT.SUCCESS.', translocoService, cdr);
    this.counterLimit = 7;
    const paymentResponse = sessionStorage.getItem(PricePaymentSessionStorageEnum.PRICE_PAYMENT_RESPONSE);

    if (!isNil(paymentResponse)) {
      const startPos = paymentResponse.indexOf('response=');
      if (startPos > -1) {
        this.responseText = decodeURIComponent(paymentResponse.substr(paymentResponse.indexOf('response=') + 9));
      }
    } else {
      this.responseText = this.route.snapshot.queryParamMap.get('response');
    }

    const responseObject = JSON.parse(this.responseText) as PaymentRedirectResponseModel;
    this.isPaymentSuccess =
      !isNil(responseObject) && responseObject.hasOwnProperty('status') && responseObject.status === PaymentStatus.SUCCESS;

    if (this.isPaymentSuccess === true) {
      localStorage.removeItem(MagicLinkPath.MAGIC_LINK_PATH);
      sessionStorage.removeItem('payment-in-stepper');
      sessionStorage.removeItem(PricePaymentSessionStorageEnum.PRICE_PAYMENT_IN_TRIAL_TIME);

      this.gtmService.pushTag({
        event: 'purchase',
        tax: 0,
        shipping: 0,
        coupon: '',
        currency: 'USD',
        transaction_id: responseObject.billing_account,
        value: responseObject.subscription_name,
        items: [
          {
            index: 0,
            coupon: '',
            discount: 0,
            price: responseObject.total_gross,
            item_id: responseObject.product_id,
            quantity: responseObject.vehicle_count,
            item_name: responseObject.subscription_name,
          },
        ],
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.isPaymentSuccess === false) {
      this.stopCounter();
    }
  }
  onStartApp() {
    if (this.showInStepper === true) {
      this.backToStepper.emit(true);
    } else if (!isNil(this.successButton)) {
      this.router.navigate([`/${BASIC_DATA_PAGE_PATH}/${VEHICLE_ROUTE_PATH}`]);
    }
  }

  onClickNavigateToOrder(): void {
    if (this.showInStepper === true) {
      this.backToStepper.emit(false);
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: { package_data: this.route.snapshot.queryParamMap.get('response') },
        fragment: 'anchor',
      };
      const isLoggedIn = this.store.selectSnapshot<boolean>(states => checkIsLoggedIn(states.auth));
      const urlPattern = isLoggedIn === true ? `/${USER_ROUTE_PATH}/${PRICE_SITE_PATH}` : `/${PRICE_SITE_PATH}`;

      this.router.navigate([urlPattern], navigationExtras);
    }
  }
}
