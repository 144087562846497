import { CommonModule, DOCUMENT } from '@angular/common';
import { ApplicationRef, ComponentFactoryResolver, Inject, Injector, ModuleWithProviders, NgModule, OnDestroy } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { FragmentState } from '../state/fragment.state';
import { FRAGMENT_PRESENTER_CONFIG_TOKEN, FRAGMENT_PRESENTER_FEATURE_CONFIG_TOKEN } from './fragment-presenter-config.token';
import { FragmentPresenterService } from './fragment-presenter.service';
import { PresenterConfigInterface } from './presenter-config.interface';

@NgModule({
  imports: [
    CommonModule,
    // PortalModule,
    NgxsModule.forFeature([FragmentState]),
  ],
})
export class FragmentPresenterRootModule {
  constructor(
    @Inject(FRAGMENT_PRESENTER_CONFIG_TOKEN) config: PresenterConfigInterface[],
    fragmentPresenterService: FragmentPresenterService,
    componentFactoryResolver: ComponentFactoryResolver,
    @Inject(DOCUMENT) document: Document,
    appRef: ApplicationRef,
    injector: Injector
  ) {
    fragmentPresenterService.initConfig(config.map(conf => ({ ...conf, componentFactoryResolver, document, appRef, injector })));
  }
}

// TODO nem mindig hivodik be!
// TODO: Add Angular decorator.
@NgModule()
export class FragmentPresenterFeatureModule implements OnDestroy {
  constructor(
    @Inject(FRAGMENT_PRESENTER_FEATURE_CONFIG_TOKEN) readonly config: PresenterConfigInterface[],
    readonly fragmentPresenterService: FragmentPresenterService,
    componentFactoryResolver: ComponentFactoryResolver,
    @Inject(DOCUMENT) document: Document,
    appRef: ApplicationRef,
    injector: Injector
  ) {
    fragmentPresenterService.setConfigFromFeature(config.map(conf => ({ ...conf, componentFactoryResolver, document, appRef, injector })));
  }

  ngOnDestroy(): void {
    this.fragmentPresenterService.removeConfigFromFeature(this.config);
  }
}

@NgModule()
export class FragmentPresenterModule {
  static forRoot(/*config: any*/): ModuleWithProviders<FragmentPresenterRootModule> {
    return {
      ngModule: FragmentPresenterRootModule,
      providers: [
        {
          provide: FRAGMENT_PRESENTER_CONFIG_TOKEN,
          useValue: [],
        },
      ],
    };
  }

  static forFeature(config: PresenterConfigInterface[]): ModuleWithProviders<FragmentPresenterFeatureModule> {
    return {
      ngModule: FragmentPresenterFeatureModule,
      providers: [
        {
          provide: FRAGMENT_PRESENTER_FEATURE_CONFIG_TOKEN,
          useValue: config,
        },
      ],
    };
  }
}
