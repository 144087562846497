import { HttpClient } from '@angular/common/http';
import { BatchRequestModel } from '../../batch-request/model/batch-request.model';
import { environment } from '@roadrecord/environment';
import { AbstractEntityService } from './abstract-entity.service';

/**
 * grid eseten az entitas service-nek meg kell ezt az osztaly valositania
 */
export abstract class AbstractWebadminEntityService<HTTPMODEL, MODEL> extends AbstractEntityService<HTTPMODEL, MODEL> {
  protected constructor(protected _entityUrl: string, protected _entityDefaultOrder: string, protected http: HttpClient, apiUrl2 = false) {
    super(_entityUrl, _entityDefaultOrder, http, apiUrl2);
  }

  removeBatch(id: string | number): BatchRequestModel {
    return {
      method: 'delete',
      relative_url: `/webadmin/${environment.baseApiEndpoint}/${this._entityUrl}/${id}/`,
    };
  }
}
