<mat-card *transloco="let t; read: 'MILEAGE.DETAILS'">
  <mat-card-header>
    <mat-card-title>{{ t('TITLE.' + layoutType) }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="rr_date_calendar">
      <span class="rr_date_calendar_binds"></span>
      <span class="rr_date_calendar_month">{{ date | amDateFormat: 'MMMM' | capitalize }}</span>
      <h1 class="rr_date_calendar_day">{{ date | amDateFormat: 'D' }}</h1>
    </div>
    <ng2-odometer
      [matTooltip]="'MILEAGE.ODOMETER_CLICK_TOOLTIP' | transloco"
      [number]="mileageAmount"
      duration="1000"
      [format]="formatOdometer"
      theme="default"
      [animation]="'slide'"
      [auto]="true"
      class="pointer"
      (click)="openEditFormBottomSheet()"
    ></ng2-odometer>
    <span class="actual-mileage-hint" *ngIf="layoutType === 'END' && isSelfEmployed === false">
      {{
        'FUELING_RANGE.ACTUAL_MILEAGE_TEXT'
          | transloco
            : {
                actualMileage: $any(model)?.actual_mileage
              }
      }}
    </span>
  </mat-card-content>
  <mat-progress-bar color="accent" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <mat-card-actions>
    <button
      type="button"
      mat-flat-button
      color="accent"
      [matTooltip]="t('TOOLTIP.DEBUGGING')"
      [disabled]="loading"
      (click)="switchToList()"
    >
      <span>{{ t('BUTTON.DEBUGGING') }}</span>
    </button>
    <button
      type="button"
      mat-raised-button
      [color]="'primary'"
      [matTooltip]="t('TOOLTIP.' + ((model?.id | isNil) ? 'ADD' : 'EDIT'))"
      [disabled]="loading"
      (click)="openEditFormBottomSheet()"
      >{{ 'COMMON.ACTION.' + ((model?.id | isNil) ? 'ADD' : 'EDIT') | transloco }}</button
    >
    <!--
    <a mat-stroked-button [matTooltip]="t('TOOLTIP.CALCULATOR_ASSISTANT_BUTTON')" class="ml-0 mr-0" [routerLink]="[kmToolRoutePath]">
      <span>{{ t('BUTTON.CALCULATOR_ASSISTANT') }}</span>
    </a>
-->
  </mat-card-actions>
</mat-card>
