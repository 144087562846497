import { Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { openWindowAction, PreferencesContentWindowTypeEnum } from '@roadrecord/preferences-state/common';
import { LoadableService } from '@roadrecord/external-packages/ngx-loadable';
import { FUELING_IMPORT_ROUTE_PATH, MONTH_ACTIVITIES_PAGE_PATH } from '@roadrecord/common/common';

export const fuelingImportAction = (
  store: Store,
  loadableService: LoadableService,
  injector: Injector,
  translocoService: TranslocoService,
  matSnackBar: MatSnackBar
) =>
  openWindowAction(
    store,
    loadableService,
    injector,
    translocoService,
    matSnackBar,
    'fueling-import-list-window-content',
    PreferencesContentWindowTypeEnum.FUELING_IMPORT_LIST,
    'FUELING.CALENDAR.HEADER.TOP_RIGHT_MENU.IMPORT_LIST_WINDOW.LABEL',
    {
      height: '480px',
      minWidth: '600px',
      rzHandles: 'n,e,s,w,se,sw,ne,nw',
    },
    false,
    undefined,
    {
      importRoutePath: `${MONTH_ACTIVITIES_PAGE_PATH}/${FUELING_IMPORT_ROUTE_PATH}/upload`,
    }
  );
