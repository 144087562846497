<context-menu [menuClass]="rightClickContextMenuClass" [disabled]="!enabledRightClickContextMenu">
  <ng-container *ngFor="let menuItem of rightClickContextMenuOption">
    <ng-template
      contextMenuItem
      let-item
      [divider]="menuItem?.divider !== undefined ? menuItem.divider : false"
      [visible]="menuItem?.visible !== undefined ? menuItem.visible : true"
      [enabled]="menuItem?.enabled !== undefined ? menuItem.enabled : true"
      (execute)="menuItem?.click($event)"
      [subMenu]="menuItem?.subMenu?.length > 0 ? subMenu : undefined"
    >
      <div
        matRipple
        [matRippleDisabled]="!(menuItem?.enabled !== undefined ? menuItem.enabled(item) : true)"
        [matTooltip]="(menuItem?.disabledText | isFunction) ? menuItem?.disabledText(item) : (menuItem?.disabledText | transloco)"
      >
        <mat-icon *ngIf="menuItem?.icon" [svgIcon]="menuItem?.icon"></mat-icon>
        <span>{{ menuItem.label | transloco }}</span>
      </div>
    </ng-template>
    <context-menu #subMenu [menuClass]="rightClickContextMenuClass">
      <ng-container *ngFor="let _menuItem of menuItem.subMenu; let index = index">
        <ng-template
          contextMenuItem
          let-item
          [divider]="_menuItem?.divider !== undefined ? _menuItem.divider : false"
          [visible]="_menuItem?.visible !== undefined ? _menuItem.visible : true"
          [enabled]="_menuItem?.enabled !== undefined ? _menuItem.enabled : true"
          (execute)="_menuItem?.click($event, index)"
        >
          <div
            matRipple
            [matRippleDisabled]="!(_menuItem?.enabled !== undefined ? _menuItem.enabled(item) : true)"
            [matTooltip]="(_menuItem?.disabledText | isFunction) ? _menuItem?.disabledText(item) : (_menuItem?.disabledText | transloco)"
          >
            <mat-icon *ngIf="_menuItem?.icon" [svgIcon]="_menuItem?.icon"></mat-icon>
            <span>{{ _menuItem.label | transloco }}</span>
          </div>
        </ng-template>
      </ng-container>
    </context-menu>
  </ng-container>
</context-menu>

<ng-template #baseCellContent let-day="day" let-locale="locale">
  <ul *ngIf="day?.events?.length > 0" class="rr-cell-events-list" [ngStyle]="{ height: day?.events?.length > 4 ? 'auto' : '100%' }">
    <li
      *ngFor="let event of day.events"
      [matTooltip]="event?.tooltipText ? event.tooltipText : undefined"
      [matTooltipClass]="['rr-calendar-cell-content-tooltip', 'mat-tooltip-white-space-pre']"
      matTooltipPosition="above"
      matTooltipShowDelay="400"
      [ngClass]="{ 'has-tooltip': event?.tooltipText !== undefined }"
    >
      <span>{{ event.title }}</span>
    </li>
  </ul>
</ng-template>
<ng-template #baseCellHeader let-day="day" let-locale="locale">
  <div class="rr-day-header" fxLayout="column">
    <span
      fxFlexAlign="end"
      class="cal-day-number"
      matTooltip="{{ day.date | calendarDate: 'dayViewTitle':locale }}"
      matTooltipPosition="above"
      matTooltipClass="mat-tooltip-white-space-pre"
      matTooltipShowDelay="400"
      [ngClass]="setHeaderDayNumberCssClassFn(day)"
    >
      {{ day.date | calendarDate: 'monthViewDayNumber':locale }}
    </span>
  </div>
</ng-template>
<ng-template #calendarCellTemplate let-day="day" let-locale="locale">
  <div
    class="cal-cell-top"
    [ngClass]="setCellCss(day)"
    (contextmenu)="onContextMenuOnDayCell($event, day)"
    [matTooltip]="(day.events.length === 0 ? 'CALENDAR.AGENDA.LIST.TOOLTIP.MODIFY' : 'CALENDAR.AGENDA.LIST.TOOLTIP.NEW') | transloco"
  >
    <ng-container
      *ngTemplateOutlet="cellHeaderTemplate ? cellHeaderTemplate : baseCellHeader; context: { day: day, locale: locale }"
    ></ng-container>
    <div class="rr-day-container">
      <ng-container
        *ngTemplateOutlet="cellContainerTemplate ? cellContainerTemplate : baseCellContent; context: { day: day, locale: locale }"
      ></ng-container>
    </div>
  </div>
</ng-template>
<div
  [@slideBottomIn]="{ value: true, params: { top: hasListViewButton ? '36px' : '0px' } }"
  *ngIf="events"
  class="calendar-animation-wrapper"
>
  <mat-card
    class="rr-calendar-wrapper-card"
    [ngClass]="events.calendarRowCount > 0 ? 'row-number-of-pieces-' + events.calendarRowCount : undefined"
  >
    <mwl-calendar-month-view
      [viewDate]="viewDate"
      [weekStartsOn]="weekStartsOn"
      [weekendDays]="weekendDays"
      [locale]="locale"
      [events]="events.events"
      (dayClicked)="onClickDay($any($event.day))"
      (beforeViewRender)="onBeforeViewRender($event)"
      [cellTemplate]="calendarCellTemplate"
    >
    </mwl-calendar-month-view>
  </mat-card>
</div>
