import { setThrowOverrideHandler } from '@roadrecord/worker/shared';
import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { proxy, Remote, wrap } from '@roadrecord/comlink';
import { AppFirebaseWorker } from '@roadrecord/worker/firebase';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { NotificationsService } from '@roadrecord/external-packages/angular2-notifications';
import { ShowSystemMessageAction } from './state/action/show-system-message.action';
import { isFunction, isNil } from '@roadrecord/type-guard';
import { timer } from 'rxjs';
import { notifyMessage } from './function/notify-message.function';
import { environment } from '@roadrecord/environment';

// ez kell ide?!?
setThrowOverrideHandler();

@Injectable()
export class SystemMessagesWorkerBridgeService implements OnDestroy {
  private workerProxy: Remote<AppFirebaseWorker>;
  private rawWorker: Worker;

  constructor(
    private ngZone: NgZone,
    @Inject('') private authId: string,
    @Inject('') private userEmail: string,
    @Inject('') private notificationsService: NotificationsService,
    @Inject('') private actions$: Actions
  ) {
    if (!isNil(environment.fbConfig)) {
      ngZone.runOutsideAngular(() => {
        this.initWorker();

        this.watchShotMessageAction();
      });
    }
  }

  private watchShotMessageAction() {
    Promise.resolve().then(() => {
      return this.actions$.pipe(ofActionSuccessful(ShowSystemMessageAction)).subscribe(({ msg }: ShowSystemMessageAction) => {
        this.ngZone.run(() => notifyMessage(this.notificationsService, msg));
      });
    });
  }

  getWorker(): Worker {
    return null;
  }

  initWorker() {
    if (this.rawWorker !== undefined) {
      return;
    }
    this.rawWorker = this.getWorker();
    const workerProxy = wrap<AppFirebaseWorker>(this.rawWorker);
    // @ts-ignore
    new workerProxy(this.authId, this.userEmail, environment.fbConfig).then(_workerProxy => (this.workerProxy = _workerProxy));
  }

  ngOnDestroy(): void {
    delete this.workerProxy;
    // safe remove, without error :(
    Promise.resolve(null).then(() => {
      this.rawWorker.terminate();
      delete this.rawWorker;
    });
  }

  async subscribeSystemMessages(cb: (msg: any) => void) {
    if (!isNil(this.workerProxy) && isFunction(this.workerProxy.subscribeSystemMessages)) {
      await this.ngZone.runOutsideAngular(
        async () => await this.workerProxy.subscribeSystemMessages(proxy(msg => this.ngZone.run(() => cb(msg))))
      );
    } else {
      console.warn('Wait firebase worker...');
      timer(300).subscribe(async () => await this.subscribeSystemMessages(cb));
    }
  }

  async subscribePrivateMessages(cb: (msg: any) => void) {
    await this.ngZone.runOutsideAngular(
      async () => await this.workerProxy.subscribePrivateMessages(proxy(msg => this.ngZone.run(() => cb(msg))))
    );
  }
}
