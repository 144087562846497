import { Directive, HostListener, Input } from '@angular/core';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';

/**
 * ezt ki kellett masolni hogy tudjuk patchelni
 */
@Directive({
  selector: '[rrContextMenu]',
})
export class ContextMenuAttachDirective {
  @Input() public contextMenuSubject: any;
  @Input() public rrContextMenu: ContextMenuComponent;

  constructor(private contextMenuService: ContextMenuService) {}

  @HostListener('contextmenu', ['$event'])
  public onContextMenu(event: MouseEvent): void {
    if (!this.rrContextMenu.disabled) {
      if ((event.target as HTMLElement).classList.contains('disable-right-click')) {
        return;
      }
      this.contextMenuService.show.next({
        contextMenu: this.rrContextMenu,
        event,
        item: this.contextMenuSubject,
      });
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
