import { FragmentDialogTypeEnum } from '@roadrecord/fragment-dialog';
import { isNil } from '@roadrecord/type-guard';

export interface LiveAutoCompleteNewButtonOptionModelInterface {
  readonly label?: string;
  readonly newFragment?: FragmentDialogTypeEnum;
  readonly showTopRightCloseButton?: boolean;
}

export class LiveAutoCompleteNewButtonOptionModel {
  readonly label?: string;
  readonly newFragment?: FragmentDialogTypeEnum;
  readonly showTopRightCloseButton?: boolean;

  constructor(_this: LiveAutoCompleteNewButtonOptionModelInterface) {
    if (isNil(_this) || Object.keys(_this).length === 0) {
      throw new Error('Not found options');
    }
    Object.assign(this, _this);
  }
}
