import { Injectable } from '@angular/core';
import { CalendarUtils, DateAdapter } from 'angular-calendar';
import { getMonthView, GetMonthViewArgs, MonthView } from 'calendar-utils';
import moment from 'moment';

@Injectable()
export class RRCalendarUtils extends CalendarUtils {
  startDate = undefined;
  endDate = undefined;

  constructor(dateAdapter: DateAdapter) {
    super(dateAdapter);
  }

  getMonthView(args: GetMonthViewArgs): MonthView {
    if (this.startDate !== undefined) {
      args.viewStart = moment(`${this.startDate} 00:00`, 'YYYY-MM-DD HH:mm').toDate();
    } else {
      args.viewStart = this.dateAdapter.startOfMonth(args.viewDate);
    }

    if (Object.prototype.toString.call(args.viewStart) === '[object Date]') {
      // it is a date
      if (isNaN(args.viewStart.getTime())) {
        // d.valueOf() could also work
        // date is not valid
        args.viewStart = this.dateAdapter.startOfMonth(args.viewDate);
      }
    }

    if (this.endDate !== undefined) {
      args.viewEnd = moment(`${this.endDate} 00:00`, 'YYYY-MM-DD HH:mm').toDate();
    } else {
      args.viewEnd = this.dateAdapter.startOfMonth(args.viewDate);
    }

    if (args.viewDate < args.viewStart) {
      args.viewDate = args.viewStart;
    }

    const firstDayOfMonth = moment(args.viewStart, 'YYYY-MM-DD HH:mm').toDate();
    const calendarMonthViewOptions = getMonthView(this.dateAdapter, args);

    calendarMonthViewOptions.days.forEach(day => {
      day.inMonth = !(day.date < firstDayOfMonth || day.date > args.viewEnd);
    });
    return calendarMonthViewOptions;
  }
}
