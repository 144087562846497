<ng-container
  *rrTransloco="
    let t;
    fromPath: 'UNIVERSAL_IMPORT.UPLOAD_STEPPER.DONE';
    overridePath: importConfig.translatePrefix + '.UPLOAD_STEPPER.DONE'
  "
>
  <img src="assets/images/partner/import/kavey2.png" class="mx-auto" />
  <p class="mx-auto pt-4">{{ t('TEXT') }}</p>
  <button class="mx-auto d-block" color="accent" mat-raised-button [routerLink]="[importListRoute]">
    {{ t('BACK_TO_IMPORT_LIST_BUTTON_TEXT') }}
  </button>
</ng-container>
