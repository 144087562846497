import { NgModule } from '@angular/core';
import { ListComponent } from './list.component';
import { StatusPanelComponent } from './status-panel/status-panel.component';
import { GridModule } from '@roadrecord/grid';
import { TranslocoModule } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { PreventClickModule } from '@roadrecord/common/common';
import { TranslocoUtilsDirectiveModule } from '@roadrecord/transloco-utils';

@NgModule({
  declarations: [ListComponent, StatusPanelComponent],
  imports: [
    CommonModule,
    GridModule,
    TranslocoModule,
    MatIconModule,
    FlexLayoutModule,
    MatButtonModule,
    PreventClickModule,
    TranslocoUtilsDirectiveModule,
  ],
  exports: [ListComponent],
})
export class ListModule {}
