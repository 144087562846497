import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExpiredTrialTimeRoutingModule } from './expired-trial-time-routing.module';
import { TrialTimeExpiredComponent } from './expired/trial-time-expired/trial-time-expired.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { AutoSubscribeComponent } from './expired/auto-subscribe/auto-subscribe.component';
import { PriceModule } from '../../price/price.module';

@NgModule({
  declarations: [TrialTimeExpiredComponent, AutoSubscribeComponent],
  imports: [CommonModule, ExpiredTrialTimeRoutingModule, MatCardModule, MatButtonModule, TranslocoModule, PriceModule],
})
export class ExpiredTrialTimeModule {}
