import { transferHandlers } from '@roadrecord/comlink';
import { DataValidationError } from './exception/data-validation-error';
import { FileSizeError } from './exception/file-size.error';
import { FileTypeError } from './exception/file-type.error';
import { ServerError } from './exception/server-error';
import { PeriodChangeMayChangeError } from './exception/period-change-may-change-error';
import { FileParseError } from './exception/file-parse.error';
import { ZipContentError } from './exception/zip-content.error';

export const setThrowOverrideHandler = () => {
  const originalThrowHandler = transferHandlers.get('throw');

  transferHandlers.set('throw', {
    ...originalThrowHandler,
    serialize(obj: any) {
      const exception = obj.value;
      const isError = exception instanceof Error;
      let serialized = exception;
      let customSeriazable = false;
      if (
        exception instanceof FileSizeError ||
        exception instanceof FileTypeError ||
        exception instanceof FileParseError ||
        exception instanceof DataValidationError ||
        exception instanceof ServerError ||
        exception instanceof PeriodChangeMayChangeError ||
        exception instanceof ZipContentError
      ) {
        customSeriazable = true;
      }
      if (isError) {
        serialized = {
          isError,
          message: exception.message,
          stack: exception.stack,
          type: exception.name,
          serializedData: customSeriazable ? JSON.stringify(exception) : undefined,
        };
      }
      return [serialized, []];
    },
    deserialize(obj) {
      if ((obj as any).isError) {
        let newErrorObj: any = new Error();
        if ((obj as any).type === 'FileSizeError') {
          newErrorObj = new FileSizeError();
        } else if ((obj as any).type === 'FileTypeError') {
          newErrorObj = new FileTypeError();
        } else if ((obj as any).type === 'FileParseError') {
          newErrorObj = new FileParseError();
        } else if ((obj as any).type === 'DataValidationError') {
          newErrorObj = new DataValidationError(undefined, undefined, undefined);
        } else if ((obj as any).type === 'ServerError') {
          newErrorObj = new ServerError(undefined, undefined);
        } else if ((obj as any).type === 'PeriodChangeMayChangeError') {
          newErrorObj = new PeriodChangeMayChangeError(undefined);
        } else if ((obj as any).type === 'ZipContentError') {
          newErrorObj = new ZipContentError();
        }
        // tslint:disable-next-line
        const data = (obj as any).serializedData === undefined ? {} : JSON.parse((obj as any).serializedData);
        // tslint:disable-next-line:prefer-object-spread
        throw Object.assign(newErrorObj, obj, data);
      }
      throw obj;
    },
  });
};
