import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { SystemMessagesStateModel } from './model/system-messages-state.model';
import { AddSystemMessageAction } from './action/add-system-message.action';
import { append, patch } from '@ngxs/store/operators';
import { ShowSystemMessageAction } from './action/show-system-message.action';
import { timer } from 'rxjs';
import moment from 'moment';
import { Injectable } from '@angular/core';

@State<SystemMessagesStateModel>({
  name: 'systemMessages',
  defaults: {
    showedMessages: [],
    version: 1,
  },
})
@Injectable()
export class SystemMessagesState implements NgxsOnInit {
  ngxsOnInit(ctx: StateContext<SystemMessagesStateModel>): any {
    timer(5000).subscribe(() => {
      this.startMessageGarbageCollector(ctx);
    });
  }

  /**
   * kidobjuk az osszes olyan uzenetet ami mar vegleg lejart
   * @param ctx
   */
  private startMessageGarbageCollector(ctx: StateContext<SystemMessagesStateModel>) {
    const now = moment().toDate().getTime();
    const showedMessages = ctx.getState().showedMessages;
    const originalCount = showedMessages.length;
    // maradek meg ervenyes, de mar latott
    const newState = showedMessages.filter(msg => msg.expire_end > now);
    if (newState.length !== originalCount) {
      ctx.setState(
        patch({
          showedMessages: newState,
        })
      );
      // tslint:disable-next-line:no-console
      console.debug('[System messages store GC] Truncate old messages:', newState.length > 0 ? originalCount - newState.length : 0);
    } else {
      // tslint:disable-next-line:no-console
      console.debug('[System messages store GC] Not found expired message');
    }
  }

  @Action(AddSystemMessageAction)
  addSystemMessage(ctx: StateContext<SystemMessagesStateModel>, { msg }: AddSystemMessageAction) {
    if (ctx.getState().showedMessages.some(smsg => smsg.id === msg.id) === false) {
      return ctx.dispatch(new ShowSystemMessageAction(msg));
    } else {
      console.warn(`Duplicate message, id: ${msg.id}`);
    }
  }

  @Action(ShowSystemMessageAction)
  showSystemMessage(ctx: StateContext<SystemMessagesStateModel>, { msg }: ShowSystemMessageAction) {
    ctx.setState(
      patch({
        showedMessages: append([{ id: msg.id, expire_end: msg.expire_end }]),
      })
    );
  }
}
