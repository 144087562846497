import { ModuleWithProviders, NgModule } from '@angular/core';
import { GetVehicleByPeriodContextResolver } from './get-vehicle-by-period-context.resolver';

@NgModule()
export class GetVehicleByPeriodContextResolverModule {
  static forChild(): ModuleWithProviders<GetVehicleByPeriodContextResolverModule> {
    return {
      ngModule: GetVehicleByPeriodContextResolverModule,
      providers: [GetVehicleByPeriodContextResolver],
    };
  }
}
