import {
  liveAutoCompleteCommonDiFactory,
  LiveAutoCompleteEditButtonOptionModel,
  LiveAutoCompleteOptionsConfigModel,
} from '@roadrecord/live-auto-complete';
import { PartnerListModel, PartnerTypeEnum } from '@roadrecord/partner/model';
import { FragmentDialogTypeEnum } from '@roadrecord/fragment-dialog';

export function commonPartnerLiveAutoCompleteDiFactory(config: LiveAutoCompleteOptionsConfigModel<PartnerListModel>) {
  return liveAutoCompleteCommonDiFactory(
    'common--partner',
    () =>
      new LiveAutoCompleteOptionsConfigModel<PartnerListModel>({
        ...config,
        editOptions: new LiveAutoCompleteEditButtonOptionModel<PartnerListModel>({
          editFragment: entity => {
            switch (entity.type) {
              case PartnerTypeEnum.PARTNER:
                return FragmentDialogTypeEnum.EDIT_PARTNER;
              case PartnerTypeEnum.CHARGING_STATION:
                return FragmentDialogTypeEnum.EDIT_CHARGING_STATION;
              case PartnerTypeEnum.HEAD_OFFICE_OR_SITE:
                return FragmentDialogTypeEnum.EDIT_HEAD_OR_OFFICE_SITE;
            }
            return FragmentDialogTypeEnum.EDIT_PARTNER;
          },
        }),
      })
  );
}
