import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { VehicleAccountModule } from './vehicle-account.module';
import { ListComponent } from './list/list.component';
import { isNumeric } from '@roadrecord/type-guard';
import { BASIC_DATA_PAGE_PATH, matSnackbarErrorConfigTopCenter, VEHICLE_ROUTE_PATH } from '@roadrecord/common/common';
import { Store } from '@ngxs/store';
import { VehicleAccountState } from './state/vehicle-account.state';
import { VehicleAccountModeDisableAction } from './state/action/vehicle-account-mode-disable.action';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: VehicleAccountModule,
})
export class VehicleAccountGuard implements CanActivate, CanDeactivate<ListComponent> {
  constructor(private router: Router, private store: Store, private matSnackBar: MatSnackBar, private translocoService: TranslocoService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (
      ((next.queryParamMap.has('isNew') && next.queryParamMap.get('isNew') === 'true') ||
        (next.queryParamMap.has('vehicle_id') && isNumeric(next.queryParamMap.get('vehicle_id')))) &&
      this.store.selectSnapshot(VehicleAccountState.accountModeEnable)
    ) {
      return true;
    }
    this.matSnackBar.open(
      this.translocoService.translate('COMMON.ACCESS.NOT_ACCESS_MENU_ITEM_WITH_NAME', {
        menuItemName: this.translocoService.translate('APP_LAYOUT.MENU.ITEM.VEHICLE_ACCOUNT'),
      }),
      undefined,
      matSnackbarErrorConfigTopCenter
    );
    return (this.router as any).navigationId === 1 ? this.router.createUrlTree([BASIC_DATA_PAGE_PATH, VEHICLE_ROUTE_PATH]) : false;
  }

  canDeactivate(
    component: ListComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.dispatch(new VehicleAccountModeDisableAction()).pipe(map(() => true));
  }
}
