import { Pipe, PipeTransform } from '@angular/core';
import { isFunction, isNil } from '@roadrecord/type-guard';

@Pipe({
  name: 'isDisabled',
  pure: false,
})
export class IsDisabledPipe<E> implements PipeTransform {
  transform(entity?: E, value?: { disabled?: ((entity?: E) => boolean) | boolean }): boolean {
    return (isNil(value.disabled) ? false : isFunction(value.disabled) ? value.disabled(entity) : value.disabled) || false;
  }
}
