<div class="count-down-timer">
  <div class="wrapper">
    <div class="description">
      <p>{{ 'COUNT_DOWN_TIMER.DAYS' | transloco }}</p>
      <p>{{ 'COUNT_DOWN_TIMER.HOURS' | transloco }}</p>
      <p>{{ 'COUNT_DOWN_TIMER.MINUTES' | transloco }}</p>
      <p>{{ 'COUNT_DOWN_TIMER.SECONDS' | transloco }}</p>
    </div>
    <div class="times">
      <p>{{ days }}</p>
      <p>{{ hours }}</p>
      <p>{{ minutes }}</p>
      <p>{{ seconds }}</p>
    </div>
  </div>
</div>
