import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProgressWhenFirstLoadingComponent } from './progress-when-first-loading.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatProgressBarModule, MatProgressSpinnerModule],
  declarations: [ProgressWhenFirstLoadingComponent],
  exports: [ProgressWhenFirstLoadingComponent],
})
export class ProgressWhenFirstLoadingModule {}
