import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { PARTNER_ANONYM_RENAME_FULL_ROUTE_PATH } from '@roadrecord/common/common';
import { PartnerService } from '../partner.service';
import { MessageDialogService } from '@roadrecord/message-dialog';

export function gotoAnonymPartners(
  partnerService: PartnerService,
  router: Router,
  cb = () => {},
  extraQueryParams = { fromPartner: false, backUrl: null }
) {
  const navigationExtras: any = {
    state: {
      list: [],
    },
  };

  let request$ = partnerService.getAnonymPartners();
  if (extraQueryParams.fromPartner === true) {
    request$ = partnerService.getAnonymAllPartners();
    navigationExtras.queryParams = { fromPartner: true, backUrl: extraQueryParams.backUrl };
  }

  return request$.pipe(
    tap(anonymPartners => {
      if (anonymPartners.results.length === 0) {
        const translatePrefix = 'PARTNER.ANONYMS_PARTNER.NOT_FOUND_MODAL.';
        MessageDialogService.INSTANCE.openWarning({
          id: null,
          text: `${translatePrefix}CONTENT`,
        })
          .afterClosed()
          .subscribe(cb);
      } else {
        // redirect
        router.navigate([PARTNER_ANONYM_RENAME_FULL_ROUTE_PATH], navigationExtras);
        /*
        router.navigate([PARTNER_ANONYM_RENAME_FULL_ROUTE_PATH], {
          state: {
            list: anonymPartners.results,
          },
        });
*/
      }
    })
  );
}
