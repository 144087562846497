import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Host, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { COMPANY_MEMBER, simpleFadeInAnimation, simpleFadeOutAnimation, USER_ROUTE_PATH } from '@roadrecord/common/common';
import {
  CHECK_NEW_OR_LOAD_CONTROLLER_PROVIDER,
  CheckModifiedMVPFormGuard,
  CheckNewOrLoadPlugin,
  ENTITY_SERVICE_TOKEN,
  HasPresenterSavePluginController,
  MaybeHandleHttpError,
  PRESENTER_COMPONENT,
  PRESENTER_PLUGINS,
  PRESENTER_SAVE_PLUGIN_OPTIONS_TOKEN,
  PRESENTER_SAVE_PLUGIN_PROVIDER,
  PRESENTER_SAVE_SAVE_ACTION_SNACKBAR_MSG_CONFIG,
  PRESENTER_STATE_CONTROLLER_PROVIDER,
  PresenterSavePluginOptionsModel,
  PresenterSaveSaveDataCallbackStrategy,
  PresenterStateController,
  SaveModel,
  VIEW_COMPONENT,
  VIEW_MODEL_PLUGIN_PROVIDER,
  VIEW_MODEL_PLUGIN_RESET_STATE,
  VIEW_REMOTE_FIELD_ERROR_PLUGIN_PROVIDER,
  VIEW_SUBMIT_PLUGIN_PROVIDER,
} from '@roadrecord/utils';
import { Observable, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CompanyMemberService } from '../company-member.service';
import { CompanyMemberDetailsModel } from '../model/company-member-details.model';
import { CompanyMemberRoleEnum } from '../model/company-member-role.enum';
import { CompanyMemberStatusEnum } from '../model/company-member-status.enum';
import { DataFormComponent } from './data-form/data-form.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { isNil } from '@roadrecord/type-guard';

export const presenterSavePluginOptions = new PresenterSavePluginOptionsModel({
  responseErrorCallback: () => 'handleFormError',
  // tslint:disable-next-line:object-literal-shorthand
  responseOkCallback: function (): string {
    // tslint:disable-next-line:no-invalid-this
    if (this.routingMode === false) {
      return 'componentModeSave';
    }
  },
  // tslint:disable-next-line
  // routingWhenSaveAndNotReset: function(): boolean {
  //   if (tripReasonDetailsRoutingWhenSaveAndNotResetStrategy.strategy !== undefined) {
  //     return tripReasonDetailsRoutingWhenSaveAndNotResetStrategy.strategy;
  //   }
  //   return true;
  // },
  presenterSaveSaveDataCallbackStrategy: PresenterSaveSaveDataCallbackStrategy.BEFORE_RUN_PLUGIN,
});

export const companyUserDetailsResetModel: Partial<CompanyMemberDetailsModel> = { role: 3 };

@Component({
  selector: 'rr-company-member-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  providers: [
    CompanyMemberService,
    { provide: PRESENTER_SAVE_SAVE_ACTION_SNACKBAR_MSG_CONFIG, useValue: { create: 'COMPANY_MEMBER.DETAILS.SNACKBAR_NEW_SUCCESS' } },
    { provide: ENTITY_SERVICE_TOKEN, useExisting: CompanyMemberService },
    { provide: PRESENTER_COMPONENT, useValue: forwardRef(() => DetailsComponent) },
    { provide: VIEW_COMPONENT, useValue: forwardRef(() => DataFormComponent) },
    {
      provide: PRESENTER_SAVE_PLUGIN_OPTIONS_TOKEN,
      useValue: presenterSavePluginOptions,
    },
    {
      provide: VIEW_MODEL_PLUGIN_RESET_STATE,
      useValue: companyUserDetailsResetModel,
    },
    {
      provide: PRESENTER_PLUGINS,
      useValue: [
        // remove delete, replace manual secure remove dialog
        CHECK_NEW_OR_LOAD_CONTROLLER_PROVIDER,
        PRESENTER_SAVE_PLUGIN_PROVIDER,
        VIEW_REMOTE_FIELD_ERROR_PLUGIN_PROVIDER,
        VIEW_SUBMIT_PLUGIN_PROVIDER,
        VIEW_MODEL_PLUGIN_PROVIDER,
      ],
    },
    PRESENTER_STATE_CONTROLLER_PROVIDER,
  ],
  animations: [simpleFadeInAnimation, simpleFadeOutAnimation],
})
export class DetailsComponent implements OnInit, HasPresenterSavePluginController<CompanyMemberDetailsModel, DataFormComponent> {
  readonly isNew$: Observable<boolean>;
  readonly editModel$: Observable<CompanyMemberDetailsModel>;
  readonly loading$: ReplaySubject<boolean>;
  @ViewChild('dataForm', { static: true })
  dataForm: DataFormComponent;
  private checkNewOrLoadController: CheckNewOrLoadPlugin<CompanyMemberDetailsModel, number>;
  @Input()
  readonly routingMode = true;
  @Input()
  readonly controlBackButton = false;
  @Input()
  hasSubmitAndNew = true;
  @Output()
  readonly cancel = new EventEmitter<void>();
  @Output()
  save = new EventEmitter<CompanyMemberDetailsModel>();
  CompanyUserStatusEnum = CompanyMemberStatusEnum;

  constructor(
    @Host() readonly presenterStateController: PresenterStateController<CompanyMemberDetailsModel, number>,
    private location: Location,
    private router: Router,
    private checkModifiedMVPFormGuard: CheckModifiedMVPFormGuard,
    private companyMemberService: CompanyMemberService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private matSnackBar: MatSnackBar,
    private transloco: TranslocoService
  ) {
    this.editModel$ = presenterStateController.editModel$.pipe(
      filter(editModel => {
        if (!isNil(editModel) && editModel.role === CompanyMemberRoleEnum.OWNER) {
          checkModifiedMVPFormGuard.disableNextCheck = true;
          router.navigate([USER_ROUTE_PATH, COMPANY_MEMBER]);
          return {} as any;
        }
        return editModel;
      })
    );
    this.loading$ = presenterStateController.loading$;
    this.isNew$ = presenterStateController.isNew$;

    this.checkNewOrLoadController = this.presenterStateController.get(CheckNewOrLoadPlugin);
  }

  async ngOnInit(): Promise<any> {
    await this.checkNewOrLoadController.checkIsNew();
  }

  onSubmit(model: SaveModel<CompanyMemberDetailsModel>): void {
    if (this.presenterStateController.isNewLastValue === false) {
      model.removeFieldsBeforeSave = ['id', 'email'];
      if (this.presenterStateController.editModelLastValue.status === CompanyMemberStatusEnum.INVITED) {
        model.removeFieldsBeforeSave.push('status');
      }
    }
  }

  onDelete(id: any): void {
    this.presenterStateController.loading$.next(true);
    this.cdr.detectChanges();
    this.companyMemberService.remove(id).subscribe(
      () => {
        this.matSnackBar.open(this.transloco.translate('COMPANY_MEMBER.SECURE_REMOVE.SUCCESS_REMOVE_SNACKBAR'));
        this.checkModifiedMVPFormGuard.disableNextCheck = true;
        this.router.navigate(['../'], { relativeTo: this.route });
      },
      error => {
        this.presenterStateController.loading$.next(false);
        MaybeHandleHttpError.maybeHandleHttpError(error);
      }
    );
  }

  onBack($event: Event): boolean {
    $event.preventDefault();
    $event.stopPropagation();

    if (this.controlBackButton === false) {
      // this.router.navigate(['../'], { relativeTo: this.route, replaceUrl: true });
      this.location.back();
    } else {
      this.cancel.emit();
    }
    return false;
  }

  handleFormError(): void {
    this.dataForm.setEditModelUIRestriction();
  }

  private componentModeSave(newTripReason: CompanyMemberDetailsModel): void {
    this.save.emit(newTripReason);
  }
}
