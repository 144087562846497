import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ReportPrintCheckDataModel } from '../../state/model/report-print-check-data.model';
import { ReportPrintStatusEnum } from '../../state/model/report-print-status.enum';
import { DocumentDataCheckerActionsEnum } from '@roadrecord/document-data-checker-action/model';
import { Select } from '@ngxs/store';
import { FaqConfigModel, PreferencesState } from '@roadrecord/preferences-state/common';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ViewportService } from '@roadrecord/common/common';

@Component({
  selector: 'rr-result-panel',
  templateUrl: './result-panel.component.html',
  styleUrls: ['./result-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultPanelComponent implements OnDestroy {
  @Input() countAll: number;
  @Input() countError: number;
  @Input() countNotChecked: number;
  @Input() countOK: number;
  @Input() countWarning: number;
  @Input() currentFilter: ReportPrintStatusEnum | 'ALL';
  @Input() runPdfGeneration: boolean;
  @Input() run: boolean;
  @Input() pdfSrc: string;
  @Input() filteredList: ReportPrintCheckDataModel[];
  ReportPrintStatusEnum = ReportPrintStatusEnum;
  @Output() clickAction = new EventEmitter<{ action: DocumentDataCheckerActionsEnum }[]>();
  @Select(PreferencesState.faq)
  readonly faqConfig$: Observable<FaqConfigModel>;
  @Select(PreferencesState.helpVideoUrl)
  readonly helpVideoUrl$: Observable<string>;

  constructor(private viewportService: ViewportService, private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {}

  onClickAction(actions: { action: DocumentDataCheckerActionsEnum }[]) {
    this.clickAction.emit(actions);
  }

  openHelpWindow(): void {
    this.helpVideoUrl$.pipe(first()).subscribe(url => this.viewportService.openVideoDialog$.next(url));
  }
}
