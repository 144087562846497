import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { catchError, switchMap } from 'rxjs/operators';
import { MaybeHandleHttpError } from '@roadrecord/utils';
import { isFunction, isNil } from '@roadrecord/type-guard';
import { VehicleService } from '@roadrecord/vehicle/service/common';

/**
 * Period context-ben tarolt kocsi id alapjan betoltjuk a kocsi adatait,
 * abban az esetben ha az atadott `getVehicletByPeriodContextCheckerFn` function
 * true-val ter vissza.
 */
@Injectable()
export class GetVehicleByPeriodContextResolver implements Resolve<any> {
  constructor(
    private vehicleService: VehicleService<any>,
    private store: Store,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    if (
      isFunction(route.data.getVehicleAndRelatedByPeriodContextResolverDisableCb) &&
      route.data.getVehicleAndRelatedByPeriodContextResolverDisableCb(route.data) === true
    ) {
      return null;
    }
    if (isNil(route.data.getVehicletByPeriodContextCheckerFn)) {
      return null;
    }
    const periodContext = this.store.selectSnapshot(this.periodContextStateSelectorsService.context) as any;
    return of(route.data.getVehicletByPeriodContextCheckerFn(periodContext)).pipe(
      switchMap(hasContext =>
        hasContext
          ? this.vehicleService.get(periodContext.vehicle.id).pipe(
              catchError(err => {
                MaybeHandleHttpError.maybeHandleHttpError(err);
                return throwError(err);
              })
            )
          : of(null)
      )
    );
  }
}
