<rr-grid
  #rrGrid
  [columnDefinitions]="displayedColumns"
  [databaseCallback]="getListDatabaseCallback()"
  [hasCheckboxColumn]="true"
  [multiSelection]="false"
  [titleText]="gridTitles"
  [hasCommonActionsColumn]="true"
  [rightClickContextMenuOption]="rightClickContextMenuOption"
  [deleteElementsOptions]="deleteElementsOptions"
  [hasNotEntitiesDataLabelLinkCallback]="hasNotEntitiesDataLabelLinkCallback"
  titleIcon="gas-station"
  (editRow)="onEditRow($any($event.element))"
  (selectionChange)="onSelectionChange($event)"
  (deletedRow)="dispatchRefuelingStagesRefreshAction()"
  [headerGroupActions]="[]"
  [headerOtherActions]="headerOtherActions"
></rr-grid>
