import { ConstructorProvider, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { ENTITY_SERVICE_TOKEN } from '../../../../entity/service/entity-service.token';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { CheckModifiedMVPFormGuard } from '../../../guard/check-modified-mvp-form-guard.service';
import { PresenterStateController } from '../../presenter-state/presenter-state.controller';
import { PRESENTER_SAVE_PLUGIN_OPTIONS_TOKEN } from './model/presenter-save-plugin-options.model';
import { PresenterSavePluginController } from './presenter-save.plugin';
import { PRESENTER_SAVE_SAVE_ACTION_SNACKBAR_MSG_CONFIG } from './presenter-save-save-action-snackbar-msg-config.token';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

export const PRESENTER_SAVE_PLUGIN_PROVIDER: ConstructorProvider = {
  provide: PresenterSavePluginController,
  deps: [
    PresenterStateController,
    ENTITY_SERVICE_TOKEN,
    TranslocoService,
    Router,
    ActivatedRoute,
    [new Optional(), PRESENTER_SAVE_PLUGIN_OPTIONS_TOKEN],
    [new Optional(), GoogleTagManagerService],
    CheckModifiedMVPFormGuard,
    Store,
    MessageDialogService,
    [new Optional(), PRESENTER_SAVE_SAVE_ACTION_SNACKBAR_MSG_CONFIG],
  ],
};
