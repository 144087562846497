<div
  class="simple-notification"
  [@enterLeave]="item.state"
  (click)="onClick($event)"
  [class]="theClass"
  [ngClass]="{
    alert: item.type === 'alert',
    error: item.type === 'error',
    warn: item.type === 'warn',
    success: item.type === 'success',
    info: item.type === 'info',
    bare: item.type === 'bare',
    'rtl-mode': rtl,
    'has-icon': item.icon !== 'bare'
  }"
  (mouseenter)="onEnter()"
  (mouseleave)="onLeave()"
>
  <div *ngIf="!item.html">
    <div class="sn-header d-flex" *ngIf="item.icon && item.title && item.title.length > 0">
      <div class="icon" *ngIf="item.icon !== 'bare'" [innerHTML]="safeSvg"></div>
      <div *ngIf="item.title && item.title.length > 0" class="sn-title" [innerHTML]="title"></div>
    </div>
    <div class="sn-content" [innerHTML]="content"></div>
  </div>
  <div *ngIf="item.html" class="html-wrapper">
    <div class="sn-html" *ngIf="htmlIsTemplate; else regularHtml">
      <ng-container *ngTemplateOutlet="item.html; context: item.context"></ng-container>
    </div>

    <ng-template #regularHtml>
      <div class="sn-content" [innerHTML]="safeInputHtml"></div>
    </ng-template>

    <div class="icon" [class.icon-hover]="clickIconToClose" *ngIf="item.icon" [innerHTML]="safeSvg" (click)="onClickIcon($event)"></div>
  </div>

  <div class="sn-progress-loader" *ngIf="showProgressBar">
    <span [ngStyle]="{ width: progressWidth + '%' }"></span>
  </div>
</div>
<div class="custom-overlay"><mat-icon rrPreventClick (click)="onClickClose()" svgIcon="close" class="close-button"></mat-icon></div>
