import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsPrivateCarPipe } from './is-private-car.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [IsPrivateCarPipe],
  exports: [IsPrivateCarPipe],
})
export class IsPrivateCarPipeModule {}
