import { InvalidPeriodContextEnum } from '@roadrecord/period-context/common';

export class MenuStateModel {
  open: boolean;
  show: boolean;
  last_route: any; // MenuLastRouteStateModel;
  excluded: string[];
  disabled_menu_message: string;
  disabled_menu_page_group: string[];
  disabled_menu_items: string[];
  disabled_menu_action?: 'GO_TO_VEHICLES';
  invalid_period_context: InvalidPeriodContextEnum;
  version: number;
}
