<p class="mt-1 mb-0">{{ 'PARTNER.LIST.BULK_EDIT_TYPE_BOTTOM_SHEET.CONTENT' | transloco }}</p>
<div class="mat-button-toggle-group-wrapper partner-type-select mt-4">
  <label
    [ngStyle]="{ color: typeControl.valid ? 'black' : '#f44336' }"
    class="pr-1"
    matTooltip="{{ 'PARTNER.DETAILS.DATA_FORM.TOOLTIP.TYPE_LABEL' | transloco }}"
    matTooltipPosition="below"
    >{{ 'PARTNER.DETAILS.DATA_FORM.LABEL.TYPE' | transloco }}*</label
  >
  <mat-button-toggle-group [formControl]="typeControl" name="type" class="primary-color-checked">
    <mat-button-toggle
      [value]="PartnerTypeEnum.PARTNER"
      *ngIf="type === PartnerTypeEnum.HEAD_OFFICE_OR_SITE || type === PartnerTypeEnum.CHARGING_STATION"
    >
      {{ 'PARTNER.TYPE.PARTNER' | transloco }}
    </mat-button-toggle>
    <mat-button-toggle
      [value]="PartnerTypeEnum.CHARGING_STATION"
      *ngIf="type === PartnerTypeEnum.HEAD_OFFICE_OR_SITE || type === PartnerTypeEnum.PARTNER"
    >
      {{ 'PARTNER.TYPE.CHARGING_STATION' | transloco }}
    </mat-button-toggle>
    <mat-button-toggle
      [value]="PartnerTypeEnum.HEAD_OFFICE_OR_SITE"
      *ngIf="type === PartnerTypeEnum.PARTNER || type === PartnerTypeEnum.CHARGING_STATION"
    >
      {{ 'PARTNER.TYPE.HEAD_OFFICE_OR_SITE' | transloco }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
