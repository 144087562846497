import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxsModule } from '@ngxs/store';
import { RecommendationContinueComponent } from './recommendation-continue/recommendation-continue.component';
import { RecommendationComponent } from './recommendation/recommendation.component';
import { RecommendationState } from './state/recommendation.state';
import { GetLabelByCurrentLangPipeModule } from '@roadrecord/report-print/common';
import { RecommendationCheckModule } from '../recommendation-check/recommendation-check.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    TranslocoModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatSnackBarModule,
    NgxsModule.forFeature([RecommendationState]),
    GetLabelByCurrentLangPipeModule,
    RecommendationCheckModule,
  ],
  declarations: [RecommendationComponent, RecommendationContinueComponent],
  exports: [RecommendationComponent, RecommendationContinueComponent],
})
export class RecommendationModule {}
