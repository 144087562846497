import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultPanelComponent } from './result-panel/result-panel.component';
import { FilterPanelModule } from '../filter-panel/filter-panel.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslocoModule } from '@ngneat/transloco';
import { PreventClickModule, TextPlaceholderHtmlTagReplacerModule } from '@roadrecord/common/common';
import { GetLabelByCurrentLangPipeModule } from '../get-label-by-current-lang-pipe/get-label-by-current-lang-pipe.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [ResultPanelComponent],
  imports: [
    CommonModule,
    FilterPanelModule,
    MatDividerModule,
    MatExpansionModule,
    TranslocoModule,
    TextPlaceholderHtmlTagReplacerModule,
    GetLabelByCurrentLangPipeModule,
    MatButtonModule,
    MatIconModule,
    PreventClickModule,
    MatProgressSpinnerModule,
  ],
  exports: [ResultPanelComponent],
})
export class ResultPanelModule {}
