import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { HideStickyNotificationAction } from '@roadrecord/app-base';

@UntilDestroy()
@Component({
  selector: 'rr-count-down-timer',
  templateUrl: './count-down-timer.component.html',
  styleUrls: ['./count-down-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountDownTimerComponent implements OnInit {
  private distance: number;
  private targetTime: number;

  /**
   * @sanya Mivel a datumot ertekkent is hasznaljuk, ezert felesleges translate params -ba csomagolni
   * csak siman vegyuk at a date erteket input-ba es majd translate-be csak atadod parameternek
   * Translate params-ba csak static dolgo legyenek ha hasznalod, tehat csak translate-hez hasznald es
   * masra ne hasznald az ertekeket!
   */
  @Input()
  startDate: string;

  days: string;
  hours: string;
  minutes: string;
  seconds: string;

  constructor(private ngZone: NgZone, private cdr: ChangeDetectorRef, private store: Store) {}

  ngOnInit() {
    const _startDate = new Date(this.startDate);
    // Mivel a visszaszámlálás adott nap 23:59:59-ig tart és a paraméterben kapott startDate-nek sajnálatos módon
    // az időpontra vonatkozó attribútumai nincsenek beállítva, ezárt most ez pótoljuk. Amennyiben ez nem tennénk meg
    // a vissszámlás hibásan működne - közel 1 napot csalna.
    _startDate.setHours(23);
    _startDate.setMinutes(59);
    _startDate.setSeconds(59);

    this.targetTime = new Date(_startDate).getTime();

    this.ngZone.runOutsideAngular(() => {
      const subscription = interval(1000)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.distance = this.targetTime - new Date().getTime();

          if (this.distance < 0) {
            subscription.unsubscribe();
            this.store.dispatch(new HideStickyNotificationAction());
          } else {
            this.ngZone.run(() => {
              this.calculateViewDateVariables();
              this.cdr.markForCheck();
            });
          }
        });
    });
  }

  private calculateViewDateVariables() {
    const days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((this.distance % (1000 * 60)) / 1000);

    this.days = `${days > 0 ? days : 0}`;
    this.hours = `0${hours > 0 ? hours : ''}`.slice(-2);
    this.minutes = `0${minutes > 0 ? minutes : ''}`.slice(-2);
    this.seconds = `0${seconds > 0 ? seconds : ''}`.slice(-2);
  }
}
