import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { PartnerTypeEnum } from '@roadrecord/partner/model';

@Component({
  selector: 'rr-bulk-edit-type-bottom-sheet',
  templateUrl: './bulk-edit-type-bottom-sheet.component.html',
  styleUrls: ['./bulk-edit-type-bottom-sheet.component.scss'],
})
export class BulkEditTypeBottomSheetComponent {
  @Input() type: PartnerTypeEnum;
  PartnerTypeEnum = PartnerTypeEnum;
  typeControl = new FormControl(undefined, Validators.required);
}
