<div class="center-center-wrapper p-2" [ngClass]="{ 'payment-success': isPaymentSuccess === true }">
  <mat-card class="p-0">
    <mat-card-header>
      <mat-card-title>
        {{ (isPaymentSuccess ? 'PRICE.DATA_FORM.PAYMENT.SUCCESS.TITLE' : 'PRICE.DATA_FORM.PAYMENT.FAILED.TITLE') | transloco }}
      </mat-card-title>
    </mat-card-header>

    <mat-card-content class="p-4">
      <ng-container *ngIf="isPaymentSuccess; else paymentFailed">
        <p class="text-left">
          Our team is pleased to welcome your business on board. If you have any questions, or need assistance, please feel free to reach
          out to us at any time. Kind regards,
        </p>
        <p class="text-left">The MileageWise Team</p>
        <p class="text-center">
          <img
            class="alignnone size-full wp-image-19926"
            src="https://www.mileagewise.com/wp-content/uploads/2022/10/team-photo-scaled.jpg"
            alt="mileagewise team photo"
            width="100%"
            height="auto"
          />
        </p>
        <div class="d-flex justify-content-center pt-2">
          <button #successButton type="button" mat-raised-button color="primary" class="continue-button w-auto" (click)="onStartApp()">
            {{ buttonText ? buttonText : (translateKeyPrefix + 'BUTTON' | transloco) }}
          </button>
        </div>
      </ng-container>

      <ng-template #paymentFailed>
        <div class="d-flex align-items-center flex-column p-2">
          <img
            class="align-content-center"
            src="https://www.mileagewise.com/wp-content/uploads/2020/03/.car-with-no-mileage-log.png"
            alt=""
            width="200"
            height="200"
          />
          <p class="text-center pt-4">
            {{ 'PRICE.DATA_FORM.PAYMENT.FAILED.CONTENT' | transloco }}
          </p>

          <div class="d-flex justify-content-center pt-2 w-100">
            <button type="button" mat-raised-button color="primary" class="w-100" (click)="onClickNavigateToOrder()">
              {{ 'PRICE.DATA_FORM.PAYMENT.FAILED.BUTTONS.ORDER' | transloco }}
            </button>
          </div>

          <div class="d-flex justify-content-center pt-2 w-100">
            <a
              class="w-100"
              [attr.href]="'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.URLS.SUPPORT' | transloco"
              target="_blank"
              mat-raised-button
              color="accent"
            >
              {{ 'PRICE.DATA_FORM.PAYMENT.FAILED.BUTTONS.SUPPORT' | transloco }}
            </a>
          </div>
        </div>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
