import { Inject, Injectable, InjectFlags, InjectionToken, Injector, OnDestroy, StaticClassProvider } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ENTITY_SERVICE_TOKEN } from '../../../entity/service/entity-service.token';
import { AbstractEntityService } from '../../../entity/service/abstract-entity.service';
import { HttpListResponseModel } from '../../../http/model/http-list-response.model';
import { isFunction, isNil } from '@roadrecord/type-guard';
import { BehaviorSubject, ConnectableObservable, Observable, of, ReplaySubject, Subject, Subscriber, timer } from 'rxjs';
import { distinctUntilChanged, publishReplay, switchMap, take } from 'rxjs/operators';
// import { VIEW_MODEL_RESET_STATE, ViewModelResetState } from '../view-model/view-model.controller';
import { PresenterState } from './presenter-state.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export const PRESENTER_COMPONENT = new InjectionToken('PRESENTER_COMPONENT');
export const VIEW_COMPONENT = new InjectionToken('VIEW_COMPONENT');
export const PRESENTER_PLUGINS = new InjectionToken('PRESENTER_PLUGINS');

let id = 0;

@UntilDestroy()
@Injectable()
export class PresenterStateController<MODEL, IDTYPE> implements PresenterState<MODEL>, OnDestroy {
  readonly isNew$: Observable<boolean>;
  readonly editModel$ = new ReplaySubject<MODEL>(1);
  readonly refreshModelCommand$ = new Subject<any>();
  readonly loading$ = new ReplaySubject<boolean>(1);
  readonly formGroup$ = new BehaviorSubject<FormGroup>(undefined);
  readonly hasDeleteButton$ = new BehaviorSubject<boolean>(true);
  readonly finishFirstInit$: Observable<void>;
  readonly id = id++;
  private readonly injector: Injector;
  private finishFirstInitObserver: Subscriber<void>;

  constructor(
    @Inject(PRESENTER_PLUGINS) private presenterPlugins: StaticClassProvider[],
    private treeParentInjector: Injector,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(ENTITY_SERVICE_TOKEN) private service: AbstractEntityService<HttpListResponseModel<MODEL>, MODEL>
  ) /* @Inject(VIEW_MODEL_RESET_STATE) resetState: ViewModelResetState*/ {
    this.finishFirstInit$ = new Observable<void>(observer => {
      this.finishFirstInitObserver = observer;
    }).pipe(publishReplay(1));
    (this.finishFirstInit$ as ConnectableObservable<void>).connect();

    this.injector = Injector.create({
      providers: [
        { provide: ActivatedRoute, useValue: this.route },
        { provide: Router, useValue: this.router },
        { provide: PresenterStateController, useValue: this },
        // { provide: VIEW_MODEL_RESET_STATE, useValue: resetState },
        // mindenhol az elsonek kell lennie a presenter statenek es akkor be tudjuk factoryval injectalni
        // itt mappolni kell az objectet
        ...presenterPlugins,
      ],
      parent: treeParentInjector,
    });

    this.editModel$.pipe(untilDestroyed(this)).subscribe(editModel => (this._editModelLastValue = editModel));
    this.isNew$ = this.editModel$.pipe(
      take(1),
      switchMap(editModel => of(isNil(this.service.getModelIdValue(editModel))))
    );
    this.isNew$.pipe(untilDestroyed(this)).subscribe(isNew => {
      this._isNewLastValue = isNew;
      if (isNew === true) {
        this.hasDeleteButton$.next(false);
      }
    });

    timer(0).subscribe(() => {
      presenterPlugins.forEach(provider => {
        this.get(provider.provide);
      });

      this.loading$.pipe(untilDestroyed(this)).subscribe(loading => (this._loadingLastValue = loading));
      this.formGroup$
        .pipe(
          untilDestroyed(this),
          distinctUntilChanged(value => !isNil(value))
        )
        .subscribe(formGroup => (this._formGroupLastValue = formGroup));
      this.hasDeleteButton$.pipe(untilDestroyed(this)).subscribe(deletable => (this._hasDeleteButtonLastValue = deletable));
    });
  }

  private _hasDeleteButtonLastValue: boolean;

  get hasDeleteButtonLastValue(): boolean {
    return this._hasDeleteButtonLastValue;
  }

  private _isNewLastValue: boolean;

  get isNewLastValue(): boolean {
    return this._isNewLastValue;
  }

  private _editModelLastValue: MODEL;

  get editModelLastValue(): MODEL {
    return this._editModelLastValue;
  }

  private _loadingLastValue: boolean;

  get loadingLastValue(): boolean {
    return this._loadingLastValue;
  }

  private _formGroupLastValue: FormGroup;

  get formGroupLastValue(): FormGroup & { submitted?: boolean } {
    return this._formGroupLastValue;
  }

  get routerData(): any {
    return this.route.snapshot.data;
  }

  setFirstInit(): void {
    if (isNil(this.finishFirstInitObserver)) {
      throw new Error('This view is inited');
    }

    this.finishFirstInitObserver.next();
    this.finishFirstInitObserver.complete();
    this.finishFirstInitObserver = undefined;
  }

  get<T>(token: any, notFoundValue?: T, flags?: InjectFlags): T {
    // switch (token) {
    //     case PRESENTER_COMPONENT:
    //
    //     case VIEW_COMPONENT:
    //
    //     default:
    // tslint:disable-next-line:deprecation
    return this.injector.get(token, notFoundValue, flags);
    // }
  }

  ngOnDestroy(): void {
    /* Az injector fa masolasa miatt a pluginek-nek nem hivodik meg az onDestroy metodusa */
    this.presenterPlugins.forEach(plugin => {
      const pluginInstance = this.injector.get(plugin.provide);
      if (isFunction(pluginInstance['ngOnDestroy'])) {
        pluginInstance['ngOnDestroy']();
      }
    });
  }
}
