import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Store } from '@ngxs/store';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { HttpErrorInterceptor } from '@roadrecord/http-error-interceptor';
import { isNil, isString } from '@roadrecord/type-guard';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HasIdModelInterface } from '@roadrecord/utils';
import { VehicleIdType, VehicleModel } from '@roadrecord/vehicle/model/common';
import { VehicleService } from '@roadrecord/vehicle/service/common';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';

@Injectable()
export class UpdateVehicleStateResolverService implements Resolve<any> {
  private httpErrorInterceptor: HttpErrorInterceptor;
  constructor(
    private vehicleService: VehicleService<VehicleModel>,
    private store: Store,
    private periodContextStateSelector: PeriodContextStateSelectorsService<any, any>,
    @Inject(HTTP_INTERCEPTORS) httpInterceptors: any[]
  ) {
    this.httpErrorInterceptor = httpInterceptors.find(httpInterceptor => httpInterceptor instanceof HttpErrorInterceptor);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const model = this.store.selectSnapshot<HasIdModelInterface<VehicleIdType> | string>(
      route.data.UpdateVehicleStateResolverService.stateSelect
    );

    let vehicle_id = null;
    const isModelValid = !isNil(model) && !isString(model);

    if (this.shouldSelectDefaultSelectedCar(route)) {
      const periodContext = this.store.selectSnapshot(this.periodContextStateSelector.context);
      vehicle_id = periodContext.vehicle.id;
    }

    if (isModelValid || !isNil(vehicle_id)) {
      this.httpErrorInterceptor.addDisableErrorCode(404);

      if (isNil(vehicle_id) && isModelValid && !isString(model)) {
        vehicle_id = model.id;
      }

      return this.vehicleService.get(vehicle_id, undefined, 'departure,departure.trip_reason').pipe(
        switchMap(remoteModel => {
          this.httpErrorInterceptor.removeDisableErrorCode(404);
          return this.store
            .dispatch(
              new UpdateFormValue({
                value: { vehicle: remoteModel },
                path: route.data.UpdateVehicleStateResolverService.updateKey,
              })
            )
            .pipe(map(() => remoteModel));
        }),
        catchError(error => {
          this.httpErrorInterceptor.removeDisableErrorCode(404);
          return this.store
            .dispatch(
              new UpdateFormValue({
                value: { vehicle: null },
                path: route.data.UpdateVehicleStateResolverService.updateKey,
              })
            )
            .pipe(map(() => error));
        })
      );
    }
    return undefined;
  }

  private shouldSelectDefaultSelectedCar(route: ActivatedRouteSnapshot): boolean {
    return !isNil(route.queryParams.selectDefaultSelectedCar);
  }
}
