<ng-template #customRightAreaTpl>
  <div class="top-custom-right-section-wrapper">
    <div [matTooltip]="'VEHICLE_PARTNER_JOIN.LIST.COLLECTED_DATAS.OPEN_BUTTON.TOOLTIP' | transloco">
      <button
        mat-raised-button
        color="accent"
        class="show-collected-data"
        [disabled]="modifyElements.length === 0"
        (click)="openColledDataBottomSheet()"
      >
        {{ 'VEHICLE_PARTNER_JOIN.LIST.COLLECTED_DATAS.OPEN_BUTTON.LABEL' | transloco: { modifiedCount: modifyElements.length } }}
      </button>
    </div>
    <div>
      <button mat-raised-button color="accent" (click)="navigateBack()" class="mr-2">{{ 'COMMON.ACTION.BACK' | transloco }}</button>
      <button mat-raised-button color="primary" (click)="save()">{{ 'COMMON.ACTION.SAVE_AND_BACK' | transloco }}</button>
    </div>
  </div>
</ng-template>
<rr-grid
  [hasDetailsView]="false"
  [hasRightClickContextDefaultGridOptions]="false"
  [disableRowClick]="true"
  [customRightAreaTpl]="customRightAreaTpl"
  [multiSelection]="true"
  [hasCheckboxColumn]="true"
  [databaseCallback]="getListCallback"
  [columnDefinitions]="displayedColumns"
  [hasIconColumn]="false"
  [titleText]="[gridTitle, gridTitle]"
  titleIcon="account-plus"
  [hasActionsColumn]="false"
  [hasFilter]="true"
  [hasNotEntitiesDataLabel]="false"
  [rowSelectIsNotDeletableAndEditableTranslateKey]="'VEHICLE.ACCOUNTS.LIST.SELECTED_OWNER_ROW'"
  (selectionChanges)="onSelectionChanges($event)"
  (afterLoaded)="onAfterLoaded()"
  [headerGroupActions]="[]"
  [headerOtherActions]="[]"
  #rrGrid
></rr-grid>
