import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TextPlaceholderHtmlTagReplacerPipe } from './text-placeholder-html-tag-replacer.pipe';

/**
 * Ez egy olyan pipe-t tartalmazz ami kepes egy elore meghatarozott placeholderSeparator es kulon megadott
 * html tag-eket feloldani es kicserelni valos html tag-re
 */
@NgModule({
  imports: [CommonModule],
  declarations: [TextPlaceholderHtmlTagReplacerPipe],
  exports: [TextPlaceholderHtmlTagReplacerPipe],
})
export class TextPlaceholderHtmlTagReplacerModule {}
