import { UniversalImportConfig } from '@roadrecord/universal-import/common';
import { FUELING_IMPORT_ROUTE_PATH, FUELING_ROUTE_PATH, MONTH_ACTIVITIES_PAGE_PATH } from '@roadrecord/common/common';

import { PreferencesCloseContentWindowAction, PreferencesContentWindowTypeEnum } from '@roadrecord/preferences-state/common';
import { Store } from '@ngxs/store';
import { importListColumnConfig } from './list-column.config';
import { fuelingImportUploadSourceTypes, FuelingImportUploadSourceTypesEnum } from './model/fueling-import-upload-source-types';
import { environment } from '@roadrecord/environment';

export const importConfig: UniversalImportConfig<FuelingImportUploadSourceTypesEnum> = {
  importButtons: [
    {
      logoUrl: 'assets/logos/ms-excel/ms-excel-logo.svg',
      translateKey: fuelingImportUploadSourceTypes.find(type => type.value === FuelingImportUploadSourceTypesEnum.UNIVERSAL).translateKey,
      config: {
        type: FuelingImportUploadSourceTypesEnum.UNIVERSAL,
        acceptedFileTypes: ['EXCEL', 'CSV'],
        importSampleUrl: () => environment.fuelingImportSampleUrl,
        maxFileSize: 1024 * 1024,
        controllerType: 'EXCEL_OR_CSV',
      },
      defaultSelected: true,
    },
  ],
  listColumnConfig: importListColumnConfig,
  translatePrefix: 'FUELING.IMPORT',
  importListRoute: `/${MONTH_ACTIVITIES_PAGE_PATH}/${FUELING_IMPORT_ROUTE_PATH}`,
  importMainRoute: `/${MONTH_ACTIVITIES_PAGE_PATH}/${FUELING_ROUTE_PATH}`,
  uploadSourceTypes: fuelingImportUploadSourceTypes,
  gaCategory: 'fueling-import',
  gtmPrefix: 'fueling_import',
  getHeaderRowIndex: (sourceType: FuelingImportUploadSourceTypesEnum) => 0,
  // sourceType === FuelingImportUploadSourceTypesEnum.BILLINGO ? 1 : 0,
  removeFirstRowsNumber: (sourceType: FuelingImportUploadSourceTypesEnum) => 0,
  // sourceType === FuelingImportUploadSourceTypesEnum.BILLINGO ? 1 : 0,
  hasFabBack: true,
  hooks: {},
  gridNotEntitiesBackgroundImage: 'partnerimport',
  beforeRouteUploadLayout: (store: Store) =>
    store.dispatch(new PreferencesCloseContentWindowAction(PreferencesContentWindowTypeEnum.FUELING_IMPORT_LIST)),
};
