import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { mixinWithCounterButton, USER_LOGIN_PATH, WithCounterButton, WithCounterButtonCtor } from '@roadrecord/common/common';
import { timer } from 'rxjs';
import { AuthState } from '../../../../state/auth.state';
import { Store } from '@ngxs/store';
import { SocialRegistrationSuccessfulAction } from '../../../../state/action/register/social-registration-successful.action';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '@roadrecord/environment';
import { UntilDestroy } from '@ngneat/until-destroy';

export class RRRegistrationSuccessfulBase {
  constructor(readonly translateKeyPrefix: string, readonly translocoService: TranslocoService, readonly cdr: ChangeDetectorRef) {}
}

export const _RRRegistrationSuccessfulBase: WithCounterButtonCtor & typeof RRRegistrationSuccessfulBase = mixinWithCounterButton(
  RRRegistrationSuccessfulBase
);

// declare var fbq: any;
export const canRegisterSuccessFulVisiblePage = { visible: false };

@UntilDestroy()
@Component({
  selector: 'rr-registration-successful',
  templateUrl: './registration-successful.component.html',
  styleUrls: ['./registration-successful.component.scss'],
})
export class RegistrationSuccessfulComponent extends _RRRegistrationSuccessfulBase implements WithCounterButton, OnInit, AfterViewInit {
  param = { email: '' };
  socialRegistrationSuccessfulMode = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private matDialog: MatDialog,
    cdr: ChangeDetectorRef,
    private store: Store,
    translocoService: TranslocoService
  ) {
    super('USER.REGISTER.REGISTRATION_SUCCESSFUL.', translocoService, cdr);
    this.counterLimit = 7;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.socialRegistrationSuccessfulMode = this.store.selectSnapshot(AuthState.socialRegistrationSuccessfulMode);
    if (
      this.route.snapshot.queryParams.email &&
      (canRegisterSuccessFulVisiblePage.visible || environment.mode === 'e2e' || this.socialRegistrationSuccessfulMode)
    ) {
      this.param.email = this.route.snapshot.queryParams.email;
    } else {
      this.router.navigate([`${USER_LOGIN_PATH}`]);
      return;
    }

    this.matDialog.closeAll();
  }

  ngAfterViewInit(): void {
    if (environment.mode === 'e2e') {
      timer(0).subscribe(() => this.cdr.detectChanges());
    }
  }

  onStartApp() {
    const redirectFn = () => window.location.replace('/');
    if (this.socialRegistrationSuccessfulMode) {
      this.store.dispatch(new SocialRegistrationSuccessfulAction(false)).subscribe(redirectFn);
    } else {
      redirectFn();
    }
  }
}
