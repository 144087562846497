import { InjectionToken } from '@angular/core';
import { DeletePopupYesCallbackResult } from '@roadrecord/message-dialog';

export interface PresenterDeleteOptions<SERVICE> {
  text?: string;
  slideToggleText?: string;
  serviceRemoveCb?: (
    service: SERVICE,
    result: DeletePopupYesCallbackResult,
    entityId: string | number,
    okCb: (responseOk: any) => void,
    errorCb: (responseError: any) => void
  ) => void;
}

export const PRESENTER_DELETE_OPTIONS = new InjectionToken<PresenterDeleteOptions<any>>('PRESENTER_DELETE_OPTIONS');
