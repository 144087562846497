import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isNil, isNotEmptyString, isString } from '@roadrecord/type-guard';
import { MessageDialogOptionsModel } from './model/message-dialog-options.model';
import { MessageDialogResultInterface } from './model/message-dialog-result.interface';
import { MessageDialogTypeEnum } from './model/message-dialog-type.enum';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'rr-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageDialogComponent {
  readonly foreverHideDialogControl = new FormControl(false);
  readonly icon: string;
  readonly spriteClasses: string[];
  private slideToggleValue = false;

  constructor(
    readonly dialogRef: MatDialogRef<MessageDialogComponent, MessageDialogResultInterface>,
    @Inject(MAT_DIALOG_DATA)
    readonly data: MessageDialogOptionsModel
  ) {
    if (!(data instanceof MessageDialogOptionsModel)) {
      this.data = new MessageDialogOptionsModel(data);
    }

    if (!isNil(this.data.type)) {
      this.spriteClasses = this.getBackgroundImage(this.data.type);
    } else if (isString(this.data.customTypeImage)) {
      this.icon = this.data.customTypeImage;
    } else if (Array.isArray(this.data.spriteClasses) && this.data.spriteClasses.length > 0) {
      this.spriteClasses = this.data.spriteClasses;
    }
  }

  private getBackgroundImage(type: MessageDialogTypeEnum) {
    switch (type) {
      case MessageDialogTypeEnum.ERROR:
        // swalOptions.title = translocoService.translate('COMMON.ERROR');
        return ['common-sprite', 'common-torott_auto'];
        break;
      case MessageDialogTypeEnum.WARNING:
        return ['common-sprite', 'common-felkialtojely'];
        break;
      case MessageDialogTypeEnum.INFORMATION:
        return ['common-sprite', 'common-kortey'];
        break;

      default:
        return undefined;
    }
  }

  onClickCancel(): void {
    const result: MessageDialogResultInterface = { result: false };
    this.setForeverHideToResult(result);
    this.dialogRef.close(result);
  }

  onClickOk() {
    const result: MessageDialogResultInterface = { result: true };
    this.setForeverHideToResult(result);
    if (!isNil(this.data.showSlideToggle) && isNotEmptyString(this.data.showSlideToggle)) {
      result['slideToggleValue'] = this.slideToggleValue;
    }
    this.dialogRef.close(result);
  }

  private setForeverHideToResult(result: MessageDialogResultInterface) {
    if (this.data.enableForeverHideFeature) {
      result['foreverHideDialog'] = this.foreverHideDialogControl.value;
    }
  }

  onSlideToggleChange($event: MatSlideToggleChange) {
    this.slideToggleValue = $event.checked;
  }

  onClickAfterOkFirst() {
    const result: MessageDialogResultInterface = { result: false, afterOkFirst: true };
    this.dialogRef.close(result);
  }
}
