import { DOCUMENT, Location } from '@angular/common';
import { ChangeDetectorRef, Component, forwardRef, Host, Inject, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { MenuService } from '@roadrecord/app-layout/common';
import {
  CheckModifiedMVPFormGuard,
  ENTITY_SERVICE_TOKEN,
  PRESENTER_COMPONENT,
  PRESENTER_DELETE_OPTIONS,
  PRESENTER_PLUGINS,
  PRESENTER_SAVE_PLUGIN_OPTIONS_TOKEN,
  PRESENTER_STATE_CONTROLLER_PROVIDER,
  PRESENTER_STATE_DEFAULT_PROVIDERS,
  PresenterDeleteOptions,
  PresenterStateController,
  VIEW_COMPONENT,
  VIEW_MODEL_PLUGIN_RESET_STATE,
} from '@roadrecord/utils';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { FuelingService } from '../../fueling.service';
import { DataFormComponent } from '../data-form/data-form.component';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { ChargingStationService } from '@roadrecord/partner/common';
import { NotificationsService } from '@roadrecord/external-packages/angular2-notifications';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CommonDetailsComponent, presenterDeleteOptionServiceRemoveCb, presenterSavePluginOptions } from './details.common';
import { VehicleService } from '@roadrecord/vehicle/service/common';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'rr-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  providers: [
    ChargingStationService,
    FuelingService,
    {
      provide: PRESENTER_DELETE_OPTIONS,
      useValue: {
        slideToggleText: 'FUELING.DELETE_DIALOG_RECALCULATION_TOGGLE',
        serviceRemoveCb: presenterDeleteOptionServiceRemoveCb,
      } as PresenterDeleteOptions<FuelingService>,
    },
    { provide: ENTITY_SERVICE_TOKEN, useExisting: FuelingService },
    { provide: PRESENTER_COMPONENT, useValue: forwardRef(() => DetailsComponent) },
    { provide: VIEW_COMPONENT, useValue: forwardRef(() => DataFormComponent) },
    {
      provide: PRESENTER_SAVE_PLUGIN_OPTIONS_TOKEN,
      useValue: presenterSavePluginOptions,
    },
    {
      provide: VIEW_MODEL_PLUGIN_RESET_STATE,
      useValue: { mileage_calculation: false },
    },
    { provide: PRESENTER_PLUGINS, useValue: PRESENTER_STATE_DEFAULT_PROVIDERS },
    PRESENTER_STATE_CONTROLLER_PROVIDER,
  ],
})
export class DetailsComponent extends CommonDetailsComponent {
  @ViewChild('dataForm', { static: true })
  dataForm: DataFormComponent;
  smrMode: boolean;

  constructor(
    router: Router,
    route: ActivatedRoute,
    @Host() presenterStateController: PresenterStateController<any, any>,
    cdr: ChangeDetectorRef,
    translocoService: TranslocoService,
    @Inject(DOCUMENT) document: Document,
    matSnackBar: MatSnackBar,
    fuelingService: FuelingService,
    checkModifiedMVPFormGuard: CheckModifiedMVPFormGuard,
    store: Store,
    menuService: MenuService,
    messageDialogService: MessageDialogService,
    periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    notificationsService: NotificationsService,
    bottomSheet: MatBottomSheet,
    vehicleService: VehicleService<any>,
    location: Location
  ) {
    super(
      router,
      route,
      presenterStateController,
      cdr,
      translocoService,
      document,
      matSnackBar,
      fuelingService,
      checkModifiedMVPFormGuard,
      store,
      menuService,
      messageDialogService,
      periodContextStateSelectorsService,
      notificationsService,
      bottomSheet,
      vehicleService,
      location
    );
  }

  protected onModifyInit(editModel: any): any {
    return editModel;
  }

  protected getSaveHttpParams() {
    return null;
  }
}
