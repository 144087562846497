import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { globalPasswordControlValidationMessages } from '@roadrecord/common/common';

@Component({
  selector: 'rr-password-suffix',
  templateUrl: './password-suffix.component.html',
  styleUrls: ['./password-suffix.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordSuffixComponent implements OnInit {
  visiblePassword = false;
  passwordType = 'password';
  @Input() formlyFields: FormlyFieldConfig[];
  @Input() passwordGroupId: number;
  @ViewChild('passwordSuffixTpl', { static: true }) passwordSuffixTpl: TemplateRef<any>;

  changeVisibilityPassword(): void {
    this.visiblePassword = !this.visiblePassword;
    this.passwordType === 'password' ? (this.passwordType = 'text') : (this.passwordType = 'password');

    const passwordGroup = this.passwordGroup;
    passwordGroup.fieldGroup[0].templateOptions.passwordType = this.passwordType;
    passwordGroup.fieldGroup[1].templateOptions.passwordType = this.passwordType;
  }

  ngOnInit(): void {
    const passwordGroup = this.passwordGroup;
    passwordGroup.fieldGroup[0].templateOptions.suffix = this.passwordSuffixTpl;
    passwordGroup.fieldGroup[0].templateOptions.validationMessages = globalPasswordControlValidationMessages;
    passwordGroup.fieldGroup[1].templateOptions.suffix = this.passwordSuffixTpl;
    passwordGroup.fieldGroup[1].templateOptions.validationMessages = globalPasswordControlValidationMessages;
  }

  get passwordGroup() {
    return this.formlyFields[this.passwordGroupId];
  }
}
