import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AddAppOverlayAction, AppOverlayFinishedAction } from '@roadrecord/app-layout-state';
import { WINDOW } from '@roadrecord/common/common';
import { IdleStopDetectAction } from '@roadrecord/idle-detect';
import { MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';
import { IS_WEBADMIN } from '@roadrecord/utils';
import { default as jwt_decode } from 'jwt-decode';
import { asapScheduler, Observable, of, throwError, timer } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../authentication/auth.service';
import { checkIsLoggedIn } from '../authentication/guard/check-is-logged-in.function';
import { UserModel } from '../authentication/model/user.model';
import { LoginErrorAction } from './action/login/login-error.action';
import { LoginSuccessAction } from './action/login/login-success.action';
import { LoginAction } from './action/login/login.action';
import { LogoutErrorAction } from './action/logout/logout-error.action';
import { LogoutSuccessAction } from './action/logout/logout-success.action';
import { LogoutWithoutCallEndpointSuccessAction } from './action/logout/logout-without-call-endpoint-success.action';
import { LogoutWithoutCallEndpointAction } from './action/logout/logout-without-call-endpoint.action';
import { LogoutAction } from './action/logout/logout.action';
import { RefreshTokenAction } from './action/token/refresh-token.action';
import { AuthStateModel } from './model/auth-state.model';
import { isNil } from '@roadrecord/type-guard';
import { SocialRegistrationSuccessfulAction } from './action/register/social-registration-successful.action';

// Ha valtozik akkor at kell irni a @roadrecord/main-helper/main-functions.ts -ben is
export const authStateVersion = 4;

@State<AuthStateModel>({
  name: 'auth',
  defaults: { version: authStateVersion, socialRegistrationSuccessfulMode: false } as AuthStateModel,
})
@Injectable()
export class AuthState {
  constructor(
    private readonly authService: AuthService<UserModel>,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly translocoService: TranslocoService,
    private readonly matDialog: MatDialog,
    private readonly matSnackBar: MatSnackBar,
    private readonly router: Router,
    @Inject(WINDOW) private readonly window: Window,
    @Inject(IS_WEBADMIN) private readonly isWebadmin: boolean,
    private messageDialogService: MessageDialogService
  ) {}

  @Selector()
  static socialRegistrationSuccessfulMode(state: AuthStateModel): boolean {
    return state.socialRegistrationSuccessfulMode;
  }

  @Selector()
  static token(state: AuthStateModel): string {
    return state.token;
  }

  @Selector()
  static isLoggedIn(state: AuthStateModel): boolean {
    return checkIsLoggedIn(state);
  }

  @Selector()
  static user(state: AuthStateModel): UserModel {
    return state.user;
  }

  @Action(SocialRegistrationSuccessfulAction)
  socialRegistrationSuccessful({ patchState }: StateContext<AuthStateModel>, action: SocialRegistrationSuccessfulAction) {
    patchState({ socialRegistrationSuccessfulMode: action.flag });
  }

  @Action(LoginAction)
  login({ setState, dispatch }: StateContext<AuthStateModel>, payload: LoginAction): Observable<any> {
    return this.authService.login(payload.user).pipe(
      tap((result: HttpResponse<UserModel>) => asapScheduler.schedule(() => dispatch(new LoginSuccessAction(result.headers.get('token'))))),
      catchError(error => {
        asapScheduler.schedule(() => dispatch(new LoginErrorAction(error)));
        return throwError(error);
      })
    );
  }

  @Action([LoginSuccessAction, RefreshTokenAction])
  loginSuccess({ dispatch, setState, getState }: StateContext<AuthStateModel>, action: LoginSuccessAction | RefreshTokenAction): void {
    const state = getState();
    // if (action instanceof LoginSuccessAction) {
    //   this.breakpointObserver
    //     .observe([Breakpoints.Handset])
    //     .pipe(first())
    //     .subscribe(isMobile => {
    //       if (isMobile.matches === true) {
    //         this.messageDialogService.open({
    //           id: null,
    //           text: 'COMMON.MESSAGE_DIALOG.MOBILE_ONLY_BASIC_DATAS',
    //         });
    //       }
    //     });
    // }
    this.setState(action.token, setState, state);
  }

  @Action(LogoutAction)
  logout({ getState, setState, dispatch }: StateContext<AuthStateModel>, payload: LogoutAction): Observable<void> {
    dispatch(new IdleStopDetectAction());
    if (payload.message !== undefined) {
      return this.messageDialogService
        .open(
          {
            id: null,
            type: MessageDialogTypeEnum.WARNING,
            text: payload.message,
          },
          { backdropClass: 'logout-backdrop' }
        )
        .afterClosed()
        .pipe(switchMap(() => this.logoutProcess(dispatch, setState)));
    }
    return this.logoutProcess(dispatch, setState);
  }

  @Action(LogoutWithoutCallEndpointAction)
  logoutWithoutCallEndpointAction(
    { getState, setState, dispatch }: StateContext<AuthStateModel>,
    payload: LogoutWithoutCallEndpointAction
  ): any {
    dispatch(new IdleStopDetectAction());
    if (payload.message !== undefined) {
      return this.messageDialogService
        .open(
          {
            id: null,
            type: MessageDialogTypeEnum.WARNING,
            text: payload.message,
          },
          { backdropClass: 'logout-backdrop' }
        )
        .afterClosed()
        .pipe(
          tap(() => dispatch(new AddAppOverlayAction(this.translocoService.translate('COMMON.OVERLAY.RELOAD')))),
          tap(() => dispatch(new AppOverlayFinishedAction())),
          tap(() => {
            setState({} as AuthStateModel);
            this.logoutWithoutCallEndpoint(dispatch, setState);
          })
        )
        .subscribe(() => this.dispatchLoginRedirect(false, 500));
    } else {
      asapScheduler.schedule(() => dispatch(new AddAppOverlayAction(this.translocoService.translate('COMMON.OVERLAY.RELOAD'))));
      setState({} as AuthStateModel);
      this.dispatchLoginRedirect(false);
      this.logoutWithoutCallEndpoint(dispatch, setState);
    }
  }

  private setState(token: string, setState: (val: AuthStateModel) => any, state: AuthStateModel): UserModel {
    const user: UserModel = jwt_decode(token);
    setState({ ...state, token, user, version: authStateVersion });
    return user;
  }

  private logoutProcess(dispatch: (actions: any | any[]) => Observable<void>, setState: (val: AuthStateModel) => any): Observable<void> {
    dispatch(new AddAppOverlayAction(this.translocoService.translate('COMMON.OVERLAY.LOGOUT')));
    return this.authService.logout().pipe(
      catchError(error => {
        if (!isNil(error.status) && error.status === 401) {
          this.dispatchLoginRedirect();
        } else {
          asapScheduler.schedule(() => dispatch(new LogoutErrorAction(error)));
        }
        return of(undefined);
      }),
      tap(() => {
        this.matDialog.closeAll();
        this.matSnackBar.dismiss();
      }),
      tap(() => setState({} as AuthStateModel)),
      tap(() => asapScheduler.schedule(() => dispatch(new LogoutSuccessAction()))),
      tap(() => asapScheduler.schedule(() => dispatch(new AppOverlayFinishedAction()))),
      tap(() => {
        this.dispatchLoginRedirect();
      })
    );
  }

  private dispatchLoginRedirect(routerRedirect = false, delay = 1500): void {
    if (delay === 0) {
      this.redirectToLogin(routerRedirect);
    } else {
      timer(delay).subscribe(() => this.redirectToLogin(routerRedirect));
    }
  }

  private redirectToLogin(routerRedirect = false): any {
    if (routerRedirect) {
      this.router.navigate(['/login']);
    } else {
      if (window.location.hash !== '#/') {
        window.location.href = '/';
      } else {
        window.location.reload(true);
      }
    }
  }

  private logoutWithoutCallEndpoint(dispatch: (actions: any | any[]) => Observable<void>, setState: (val: AuthStateModel) => any): void {
    asapScheduler.schedule(() => dispatch(new LogoutWithoutCallEndpointSuccessAction()));
  }
}
