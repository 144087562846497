import { uaParser } from '@roadrecord/main-helper';

let detectedState = null;
export let isMobile: boolean;
export let isTablet: boolean;

export function isMobileOrTablet() {
  if (detectedState === null) {
    // @ts-ignore
    const device = uaParser.getDevice();
    isMobile = device.type === 'mobile';
    isTablet = device.type === 'tablet';
    detectedState = isMobile || isTablet;
    return detectedState;
  }
  return detectedState;
}
