export enum FragmentDialogTypeEnum {
  NEW_PARTNER = 'NEW_PARTNER',
  EDIT_PARTNER = 'EDIT_PARTNER',
  NEW_HEAD_OR_OFFICE_SITE = 'NEW_HEAD_OR_OFFICE_SITE',
  EDIT_HEAD_OR_OFFICE_SITE = 'EDIT_HEAD_OR_OFFICE_SITE',
  NEW_CHARGING_STATION = 'NEW_CHARGING_STATION',
  EDIT_CHARGING_STATION = 'EDIT_CHARGING_STATION',
  // NEW_VEHICLE = 'NEW_VEHICLE',
  NEW_TRIP_REASON = 'NEW_TRIP_REASON',
  EDIT_TRIP_REASON = 'EDIT_TRIP_REASON',
  // NEW_FUELING = 'NEW_FUELING',
  // LIST_FUELING = 'LIST_FUELING',
  // SPECIAL_DAY = 'SPECIAL_DAY',
  // DESTINATION = 'DESTINATION',
  // RECOMMENDATION = 'RECOMMENDATION'
}
