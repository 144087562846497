<button
  type="button"
  mat-icon-button
  [matTooltip]="'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.TOOLTIP_ICON_BUTTON' | transloco"
  matTooltipPosition="below"
  [matMenuTriggerFor]="helpMenu"
  class="help-video-button"
>
  <mat-icon svgIcon="help-circle-outline"></mat-icon>
</button>
<mat-menu #helpMenu="matMenu" class="nav-toolbar-help-menu">
  <ng-template matMenuContent>
    <button type="button" *ngIf="(helpVideoUrl$ | async)?.length > 0" mat-menu-item class="help-menu-item" (click)="openHelpWindow()">
      <mat-icon svgIcon="play-circle-outline"></mat-icon>
      <span>{{ 'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.ITEM.HELP_VIDEO' | transloco }}</span>
    </button>
    <a *ngIf="faqConfig$ | async as faqConfig" mat-menu-item class="help-menu-item" target="_blank" [href]="faqConfig.url">
      <mat-icon svgIcon="help-circle-outline"></mat-icon>
      <span>{{ faqConfig.label | transloco }}</span>
    </a>
    <mat-menu #introjsMenu="matMenu">
      <a mat-menu-item class="help-menu-item" (click)="onClickIntrojsStart(INTROJS_TYPES_ENUM.APP_START)">
        <mat-icon svgIcon="play-box-outline"></mat-icon>
        <span>{{ 'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.ITEM.INTROS.APP_START' | transloco }}</span>
      </a>
      <a mat-menu-item class="help-menu-item" (click)="onClickIntrojsStart(INTROJS_TYPES_ENUM.MONTH_ACTIVITIES)">
        <mat-icon svgIcon="play-box-outline"></mat-icon>
        <span>{{ 'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.ITEM.INTROS.MONTH_ACTIVITIES' | transloco }}</span>
      </a>
    </mat-menu>
    <a mat-menu-item class="help-menu-item" [disabled]="disableIntrojsStartButtons" [matMenuTriggerFor]="introjsMenu">
      <mat-icon svgIcon="play-box-outline"></mat-icon>
      <span>{{ 'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.ITEM.INTROS.LABEL' | transloco }}</span>
    </a>
    <a mat-menu-item class="help-menu-item" [href]="googleStoreUrl | sanitizeUrl" target="_blank">
      <mat-icon svgIcon="google"></mat-icon>
      <span>{{ 'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.ITEM.ANDROID_DOWNLOAD' | transloco }}</span>
    </a>
    <a mat-menu-item class="help-menu-item" [href]="appleStoreUrl | sanitizeUrl" target="_blank">
      <mat-icon svgIcon="apple"></mat-icon>
      <span>{{ 'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.ITEM.IOS_DOWNLOAD' | transloco }}</span>
    </a>
    <a mat-menu-item class="help-menu-item" [href]="'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.URLS.FORUM' | transloco" target="_blank">
      <mat-icon svgIcon="forum-outline"></mat-icon>
      <span>{{ 'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.ITEM.FORUM' | transloco }}</span>
    </a>
    <a mat-menu-item class="help-menu-item" [href]="'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.URLS.SUPPORT' | transloco" target="_blank">
      <mat-icon svgIcon="comment-question-outline"></mat-icon>
      <span>{{ 'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.ITEM.SUPPORT' | transloco }}</span>
    </a>
    <a
      mat-menu-item
      class="help-menu-item"
      [href]="'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.URLS.CONTACT' | transloco"
      target="_blank"
      *ngIf="null | appTypeIsHu"
    >
      <mat-icon svgIcon="email-outline"></mat-icon>
      <span>{{ 'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.ITEM.SEND_MESSAGE' | transloco }}</span>
    </a>

    <a mat-menu-item class="help-menu-item" [href]="'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.URLS.OUTSOURCE' | transloco" target="_blank">
      <mat-icon svgIcon="handshake"></mat-icon>
      <span>{{ 'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.ITEM.OUTSOURCE' | transloco }}</span>
    </a>

    <a mat-menu-item class="help-menu-item" [href]="'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.URLS.INTEGRATIONS' | transloco" target="_blank">
      <mat-icon svgIcon="chip"></mat-icon>
      <span>{{ 'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.ITEM.INTEGRATIONS' | transloco }}</span>
    </a>

    <a mat-menu-item class="help-menu-item" [href]="'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.URLS.PRINT_SAMPLE' | transloco" target="_blank">
      <mat-icon svgIcon="printer"></mat-icon>
      <span>{{ 'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.ITEM.PRINT_PATTERNS' | transloco }}</span>
    </a>
    <a
      mat-menu-item
      class="help-menu-item version"
      [href]="'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.URLS.PROGRAM_CHANGELOG' | transloco"
      target="_blank"
      matTooltipClass="mat-tooltip-white-space-pre"
      [matTooltip]="versionTooltip"
      [ngStyle]="viewportService.isMobile ? { 'height.px': 74 } : undefined"
    >
      <mat-icon svgIcon="history"></mat-icon>
      <span
        >{{ 'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.ITEM.VERSION_HISTORY' | transloco }}
        <ng-container *ngIf="viewportService.isMobile"
          ><small>{{ serverVersion }}</small
          ><small>{{ clientVersion }}</small></ng-container
        ></span
      >
    </a>
  </ng-template>
</mat-menu>
