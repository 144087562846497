import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { GridModule } from '@roadrecord/grid';
import { DayListComponent } from './day-list.component';

@NgModule({
  imports: [CommonModule, GridModule, TranslocoModule],
  declarations: [DayListComponent],
  exports: [DayListComponent],
})
export class DayListModule {}
