import { Component, HostBinding, Inject, Injector, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { BatchRequestService, ENTITY_SERVICE_TOKEN, HttpListResponseModel, MaybeHandleHttpError } from '@roadrecord/utils';
import {
  BASIC_DATA_PAGE_PATH,
  deepEqual,
  NoopFunction,
  PARTNER_ANONYM_RENAME_ROUTE_PATH,
  PARTNER_ROUTE_PATH,
} from '@roadrecord/common/common';
import { GridColumnModel, GridComponent, gridDatabaseCallback, GridHeaderGroupAction, GridHeaderOtherAction } from '@roadrecord/grid';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, map, skip } from 'rxjs/operators';
import { baseNotAccessUrl, MenuService } from '@roadrecord/app-layout/common';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { UniversalImportStatusModel } from '../model/universal-import-status.model';
import { hasAccessUniversalImportUploadFile } from '../upload-stepper/has-access-universal-import-upload-file';
import { UniversalImportService } from '../universal-import.service';
import { UNIVERSAL_IMPORT_CONFIG, UniversalImportConfig } from '../model/universal-import-config';
import { setIconFn } from './set-icon.function';
import { simpleMergeTranslateObjectsFlatten } from '@roadrecord/transloco-utils';
import { isFunction, isNil, isString } from '@roadrecord/type-guard';
import { AnonymPartnerModel, PARTNER_ADRESS_FORMATTER, PartnerAddressFormatterReturnType } from '@roadrecord/partner/model';
import { HttpClient } from '@angular/common/http';
import { environment } from '@roadrecord/environment';
import { MessageDialogService } from '@roadrecord/message-dialog';

@UntilDestroy()
@Component({
  selector: 'rr-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [{ provide: ENTITY_SERVICE_TOKEN, useExisting: UniversalImportService }],
})
export class ListComponent implements OnDestroy {
  databaseCallback: gridDatabaseCallback;
  @ViewChild('rrGrid', { static: true })
  rrGrid: GridComponent;
  displayedColumns: GridColumnModel<any>[] = this.importConfig.listColumnConfig(this);
  setIconFn = setIconFn(this);
  status: UniversalImportStatusModel;
  private mergedTranslate = simpleMergeTranslateObjectsFlatten('UNIVERSAL_IMPORT.LIST', `${this.importConfig.translatePrefix}.LIST`);
  @Input() titleText: [string, string];
  @Input() gridId: string;
  @Input() routingMode = true;
  @HostBinding('class.universal-import-list-window') hostClass = true;
  @Input() importRoutePath: string | null = null;

  headerSingleButtonAction: GridHeaderGroupAction<any>;

  readonly headerOtherActions: GridHeaderOtherAction<any>[] = [
    {
      type: 'ITEM',
      label: { translate: 'COMMON.ACTION.REFRESH' },
      icon: 'refresh',
      action: () => this.rrGrid.refresh(),
    },
    {
      type: 'ITEM',
      icon: 'cloud-upload',
      label: { translate: this.mergedTranslate['FAB_BUTTON.UPLOAD_FILE'] },
      disabled: () => this.rrGrid.isLoadingResults || (this.status !== undefined && this.status.progress > 0),
      action: this.onClickTryIt.bind(this),
    },
  ];
  uiBindedOnClickTryIt = this.onClickTryIt.bind(this);
  partnerAnonymRenameRoute = ['/', BASIC_DATA_PAGE_PATH, PARTNER_ROUTE_PATH, PARTNER_ANONYM_RENAME_ROUTE_PATH];

  constructor(
    private importService: UniversalImportService<any>,
    private batchRequestService: BatchRequestService,
    readonly translocoService: TranslocoService,
    private route: ActivatedRoute,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private store: Store,
    private http: HttpClient,
    private menuService: MenuService,
    private messageDialogService: MessageDialogService,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    @Inject(UNIVERSAL_IMPORT_CONFIG) readonly importConfig: UniversalImportConfig<any>,
    private injector: Injector,
    @Inject(PARTNER_ADRESS_FORMATTER) readonly partnerAddressFormatter: PartnerAddressFormatterReturnType
  ) {
    hasAccessUniversalImportUploadFile.hasAccess = false;
    this.assignDatabaseCallback();
    this.checkPeriodContextChange();
    this.setTitle();
    this.detectLangChanges();

    console.log('importConfig', this.importConfig);

    // add extra grid header other button
    if (this.importConfig.hasFabBack) {
      this.headerOtherActions = [
        ...this.headerOtherActions,
        {
          type: 'ITEM',
          icon: 'undo',
          route: isString(this.importConfig.importMainRoute) ? [this.importConfig.importMainRoute] : undefined,
          label: { translate: this.mergedTranslate['FAB_BUTTON.BACK_TO'] },
          disabled: () => this.rrGrid.isLoadingResults,
        },
      ];
    }
  }

  /**
   * ujra forditjuk a stringeket
   *
   * @private
   */
  private detectLangChanges() {
    this.translocoService.langChanges$.pipe(untilDestroyed(this), skip(1)).subscribe(() => {
      // Ha valtozik a nyelv... elsot figyelmen kivul hagyjuk, es amit kell meghivunk kulon elso initre
      this.setTitle();
      // this.globalFloatingButtonService.check();
    });
  }

  private setTitle() {
    this.titleText = [this.mergedTranslate['TITLE'], this.mergedTranslate['TITLE']];
  }

  ngOnDestroy(): void {
    this.matSnackBar.dismiss();
  }

  onClickTryIt(): void {
    hasAccessUniversalImportUploadFile.hasAccess = true;
    if (!isNil(this.importConfig) && isFunction(this.importConfig.beforeRouteUploadLayout)) {
      this.importConfig.beforeRouteUploadLayout(this.store);
    }

    if (this.importRoutePath !== null) {
      this.router.navigate([this.importRoutePath]);
    } else {
      this.router.navigate(['./upload'], { relativeTo: this.route });
    }
  }

  private assignDatabaseCallback(): void {
    this.databaseCallback = (database, sort, page, filter) => {
      this.batchRequestService.start();
      this.batchRequestService.add(this.importService.getAllBatch(sort, page, filter));
      this.batchRequestService.add(this.importService.getStatusBatch());

      return this.batchRequestService.end().pipe(
        untilDestroyed(this),
        map((values: [{ body: HttpListResponseModel<any> }, { body: UniversalImportStatusModel }]) => {
          this.status = values[1].body;
          this.addSingeButtonToHeader();
          return values[0].body;
        })
      );
    };
  }

  private checkPeriodContextChange(): void {
    this.store
      .select(this.periodContextStateSelectorsService.context)
      .pipe(
        skip(1),
        distinctUntilChanged((_old, _new) => deepEqual(_old, _new)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.rrGrid.isLoadingResults = true;
        this.menuService.hasMenuItemByUrl().subscribe(v => {
          if (v === true) {
            this.rrGrid.refresh();
          } else {
            this.router.navigate([baseNotAccessUrl]);
          }
        });
      });
  }

  editRowDblClick({ element }: { element: unknown }): void {
    if (isFunction(this.importConfig.hooks.listRowDblClick)) {
      this.importConfig.hooks
        .listRowDblClick(this.injector, element)
        .pipe(untilDestroyed(this))
        .subscribe(
          () => this.rrGrid.refresh(),
          error => console.error(error)
        );
    }
  }

  private addSingeButtonToHeader(): void {
    if (this.status.progress === 0 && this.status.total > 0 && this.importConfig.gaCategory === 'finalization-of-route-import') {
      this.headerSingleButtonAction = {
        type: 'ITEM',
        action: () => this.navigateToAnonymPartner(),
        label: { translate: 'UNIVERSAL_IMPORT.STATUS.BUTTON.SUCCESS' },
      };
    }
  }
  private navigateToAnonymPartner(): void {
    this.http
      .get<{
        results: AnonymPartnerModel[];
      }>(`${environment.apiUrl}partners/bulk/anonymous-rename/?page_size=25&page=1`)
      .subscribe(
        response => {
          if (response.results.length === 0) {
            this.messageDialogService.openWarning({
              id: null,
              text: 'PARTNER.ANONYMS_PARTNER.NOT_FOUND_MODAL.CONTENT',
            });
          } else {
            this.router.navigate(this.partnerAnonymRenameRoute);
          }
        },
        error => {
          MaybeHandleHttpError.maybeHandleHttpError(error);
        }
      );
  }
}
