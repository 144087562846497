/**
 * Returns Content-Security-Policy Nonce
 */
export function getContentSecurityPolicyNonceFromDom(): string {
  let bestNonce = '';
  Array.from(document.getElementsByTagName('script')).forEach(script => {
    if (script.nonce.length > bestNonce.length) {
      bestNonce = script.nonce;
    }
  });
  console.log('Best nonce for startup:' + bestNonce);
  return bestNonce;
}
