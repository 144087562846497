import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ButtonTypeEnum } from './model/button-type.enum';
import { GridBottomSheetConfig } from './model/grid-bottom-sheet.config';

@Component({
  selector: 'rr-grid-bottom-sheet',
  templateUrl: './grid-bottom-sheet.component.html',
  styleUrls: ['./grid-bottom-sheet.component.scss'],
})
export class GridBottomSheetComponent {
  ButtonTypeEnum = ButtonTypeEnum;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) readonly config: GridBottomSheetConfig<any>) {}
}
