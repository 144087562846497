import { AfterViewInit, ChangeDetectorRef, Component, HostBinding, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { AddAppOverlayAction } from '@roadrecord/app-layout-state';
import {
  FocusInputDirective,
  globalPasswordControlValidationMessages,
  mismatchedPassword,
  mismatchedRePassword,
  slideLeftInUnathAnimation,
  slideLeftUnathOutAnimation,
  USER_LOGIN_PATH,
  ViewportService,
} from '@roadrecord/common/common';
import { IS_WEBADMIN, MaybeHandleHttpError } from '@roadrecord/utils';
import { MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';
import { isMobile, isTablet } from '@roadrecord/mobile-detect';
import { isNil } from '@roadrecord/type-guard';
import { rrFormErrorStateMatcher } from '@roadrecord/validating';
import { PasswordValidators, UniversalValidators } from 'ngx-validators';
import { timer } from 'rxjs';
import { CompanyMemberService } from '../../../authed-layouts/company/member/company-member.service';
import { LoginAction } from '../../../state/action/login/login.action';
import { LogoutWithoutCallEndpointAction } from '../../../state/action/logout/logout-without-call-endpoint.action';
import { AuthService } from '../../auth.service';
import { IResetPasswordUser } from '../../model/IResetPasswordUser';
import { UserModel } from '../../model/user.model';
import { ResetPasswordModeEnum } from './reset-password-mode.enum';
import { UserLoginModel } from '@roadrecord/user/common';
import { FingerprintService } from '../fingerprint/fingerprint.service';
import { environment } from '@roadrecord/environment';

@Component({
  selector: 'rr-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [rrFormErrorStateMatcher, CompanyMemberService, FingerprintService],
  animations: [slideLeftInUnathAnimation, slideLeftUnathOutAnimation],
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {
  @HostBinding('@enterAnimation')
  enterAnimation;
  @HostBinding('@.disabled')
  disabledAnimation = this.authService.unauthFirstAnimDisabled;
  @HostBinding('@leaveAnimation')
  leaveAnimation;
  @ViewChild('focusPassword', { static: true })
  focusPassword: FocusInputDirective;

  resetPasswordToken: string;
  visiblePassword = false;
  loading = false;
  email: string;
  passwordType = 'password';

  form: FormGroup & { submitted?: boolean };
  passwordControl: AbstractControl;
  confirmPasswordControl: AbstractControl;
  passwordControlValidationMessages = globalPasswordControlValidationMessages;
  /**
   * sweetalert2 fix: ha a user enter-vel nyomja meg a gombot akkor a valamiert az inputon valtodik ki az event ezert
   * becsukodik es ujra kinyitodik az ablak, ezzel a flag-vel javitjuk
   * @deprecated
   */
  private nextKeyupSkip = false;

  readonly useCaptcha: boolean;
  readonly reCaptchaKey: string;
  @ViewChild('captchaRef')
  readonly captchaRef: any;
  private runCaptchaReset = false;
  translatePrefix: string;
  private userMode: ResetPasswordModeEnum;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService<UserModel>,
    private readonly route: ActivatedRoute,
    private readonly translocoService: TranslocoService,
    private readonly viewportService: ViewportService,
    private readonly store: Store,
    @Inject(IS_WEBADMIN) readonly isWebadmin: boolean,
    private messageDialogService: MessageDialogService,
    private cdr: ChangeDetectorRef,
    private companyUserService: CompanyMemberService,
    private fingerprintService: FingerprintService
  ) {
    if (environment.useCaptcha) {
      this.useCaptcha = environment.useCaptcha;
      this.reCaptchaKey = environment.captchaCode;
    }

    this.initPasswordControlsValidators();
  }

  ngOnInit(): void {
    this.userMode = this.route.snapshot.data.userMode;
    if (this.userMode === ResetPasswordModeEnum.SIMPLE_USER) {
      this.translatePrefix = 'USER.PASSWORD.RESET_PASSWORD';
    } else {
      this.translatePrefix = 'COMPANY_MEMBER.RESET_PASSWORD';
    }

    this.resetPasswordToken = this.route.snapshot.queryParams.token || '';
    this.email = this.route.snapshot.queryParams.email || '';

    this.form = new FormGroup(
      {
        password: this.passwordControl,
        confirmPassword: this.confirmPasswordControl,
      }
      // mismatchedPassword('password', 'confirmPassword')
    );

    const error = this.route.snapshot.queryParams.error || '';
    if (error.length > 0) {
      let text: string;
      let type: MessageDialogTypeEnum;
      switch (error) {
        case 'invalid-token':
          text = `${this.translatePrefix}.INVALID_TOKEN_WINDOW.TEXT`;
          type = MessageDialogTypeEnum.WARNING;
          break;
      }

      this.form.disable();
      this.messageDialogService
        .open({ id: null, type, text })
        .beforeClosed()
        .subscribe(() => {
          this.store.dispatch(new AddAppOverlayAction(this.translocoService.translate('COMMON.OVERLAY.RELOAD')));
          this.store.dispatch(new LogoutWithoutCallEndpointAction());
        });
      return;
    }

    this.checkHasResetPasswordTokenAndEmail();
  }

  checkHasResetPasswordTokenAndEmail(): void {
    if (isNil(this.resetPasswordToken) || this.resetPasswordToken.length === 0 || isNil(this.email) || this.email.length === 0) {
      this.messageDialogService
        .openError({
          id: null,
          title: 'COMMON.ERROR',
          text: `${this.translatePrefix}.ERROR_NOT_FOUND_TOKEN_OR_EMAIL.CONTENT`,
          confirmLabel: `${this.translatePrefix}.ERROR_NOT_FOUND_TOKEN_OR_EMAIL.BUTTON`,
          htmlMode: true,
        })
        .afterClosed()
        .subscribe(() => this.router.navigate(['/']));
      this.form.disable();
      this.loading = true;
    }
  }

  resetPassword(): void {
    this.form.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.form.disable();
    this.loading = true;
    if (this.useCaptcha) {
      this.captchaRef.execute();
    } else {
      this.submitForm('');
    }
  }

  submitForm(captchaResponse: string): void {
    const passwordControlValue = this.form.value.password;
    const user: IResetPasswordUser = {
      email: this.email,
      password: passwordControlValue,
      password_confirmation: this.form.value.password,
      token: this.resetPasswordToken,
    };

    (this.userMode === ResetPasswordModeEnum.SIMPLE_USER
      ? this.authService.resetPassword(user)
      : this.companyUserService.makePassword(user)
    ).subscribe(
      () => {
        if (isMobile || isTablet) {
          this.loading = false;
          this.cdr.detectChanges();
          this.messageDialogService
            .openInformation({
              id: null,
              title: `${this.translatePrefix}.ON_MOBILE_WINDOW.TITLE`,
              text: `${this.translatePrefix}.ON_MOBILE_WINDOW.TEXT`,
              enableCancel: false,
              enableOk: true,
            })
            .beforeClosed()
            .subscribe(() => this.router.navigate([USER_LOGIN_PATH]));
          return;
        }

        const loginUser: UserLoginModel = {
          email: this.email,
          password: passwordControlValue,
          captcha_token: captchaResponse,
          is_admin_login: false,
          device_fingerprint: this.fingerprintService.getCode(),
        };
        this.store.dispatch(new LoginAction(loginUser)).subscribe(() => this.router.navigate(['/']));
      },
      (errorResponse: any) => {
        this.form.enable();
        if (this.useCaptcha) {
          this.runCaptchaReset = true;
          this.captchaRef.reset();
        }
        this.nextKeyupSkip = true;
        MaybeHandleHttpError.maybeHandleHttpError(errorResponse, () => {
          this.focusPassword.focusElement(100);
        });
      }
    );
  }

  changeVisibilityPassword(): void {
    this.visiblePassword = !this.visiblePassword;
    this.passwordType === 'password' ? (this.passwordType = 'text') : (this.passwordType = 'password');
  }

  onKeyupForm($event, formElement: HTMLFormElement): boolean | void {
    $event.stopPropagation();
    $event.preventDefault();
    if (this.nextKeyupSkip === true) {
      this.nextKeyupSkip = false;
      return false;
    }
    const findedElem = Array.from(formElement.getElementsByTagName($event.target.tagName)).find(element => element === $event.target);
    if (findedElem !== undefined) {
      this.resetPassword();
    }
  }

  @HostListener('@enterAnimation.done')
  onEnterDone(): void {
    this.viewportService.routerAnimation = false;
  }

  @HostListener('@enterAnimation.start')
  onLeaveStart(): void {
    this.viewportService.routerAnimation = true;
  }

  private initPasswordControlsValidators(): void {
    this.passwordControl = new FormControl(undefined);
    this.confirmPasswordControl = new FormControl(undefined);
    const passwordFieldsValidationsRules = [
      Validators.required,
      PasswordValidators.digitCharacterRule(1),
      PasswordValidators.lowercaseCharacterRule(1),
      PasswordValidators.uppercaseCharacterRule(1),
      UniversalValidators.noWhitespace,
      Validators.minLength(8),
      Validators.maxLength(20),
      mismatchedRePassword(this.confirmPasswordControl),
    ];
    this.passwordControl.setValidators(passwordFieldsValidationsRules);
    this.confirmPasswordControl.setValidators(Validators.compose([Validators.required, mismatchedPassword(this.passwordControl)]));
  }

  onResolveCaptcha($event: string): void {
    if (this.runCaptchaReset === true) {
      this.runCaptchaReset = false;
      return;
    }
    if ($event === null) {
      this.messageDialogService
        .openError({ id: null, text: 'COMMON.GOOGLE_RECAPTCHA_ERROR', htmlMode: true })
        .afterClosed()
        .subscribe(() => this.form.enable());
      return;
    }
    this.submitForm($event);
  }

  ngAfterViewInit(): void {
    timer(0).subscribe(() => {
      if (this.authService.unauthFirstAnimDisabled === true) {
        this.authService.unauthFirstAnimDisabled = false;
        this.disabledAnimation = this.authService.unauthFirstAnimDisabled;
      }
    });
  }
}
