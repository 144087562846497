import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationAnimationType } from '../../enums/notification-animation-type.enum';
import { Notification } from '../../interfaces/notification.type';
import { Position } from '../../interfaces/options.type';
import { NotificationsService } from '../../services/notifications.service';
import { NotificationComponent } from '../notification/notification.component';
import { isNil } from '@roadrecord/type-guard';
import { NotificationEvent } from '../../interfaces/notification-event.type';

@Component({
  selector: 'rr-simple-notifications',
  templateUrl: './simple-notifications.component.html',
  styleUrls: ['./simple-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleNotificationsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(NotificationComponent) notificationComponents: QueryList<NotificationComponent>;
  @Output() create = new EventEmitter();
  @Output() destroy = new EventEmitter();
  allPositionType: Position[] = [
    ['top', 'left'],
    ['top', 'center'],
    ['top', 'right'],
    ['middle', 'left'],
    ['middle', 'center'],
    ['middle', 'right'],
    ['bottom', 'left'],
    ['bottom', 'center'],
    ['bottom', 'right'],
  ];
  allNotification: {
    [key: string]: Notification[];
  } = this.initAllNotification();
  // Sent values
  timeOut = 0;
  maxLength = 0;
  clickToClose = true;
  clickIconToClose = false;
  showProgressBar = true;
  pauseOnHover = true;
  theClass = '';
  rtl = false;
  animate: NotificationAnimationType = NotificationAnimationType.FromRight;
  private lastNotificationCreated: Notification;
  private listener: Subscription;
  // Received values
  private lastOnBottom = true;
  private maxStack = 8;
  private preventLastDuplicates: any = false;
  private preventDuplicates = false;

  constructor(private service: NotificationsService, private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.notificationComponents.changes.subscribe(cmps => this.service.setNotificationComponents([...cmps]));
  }

  ngOnInit() {
    this.attachChanges(this.service.globalOptions);

    this.listener = this.service.emitter.subscribe(item => {
      switch (item.command) {
        case 'cleanAll':
          this.allNotification = this.initAllNotification();
          break;

        case 'clean':
          // tslint:disable-next-line:no-non-null-assertion
          this.cleanSingle(item.notification);
          break;

        case 'set':
          if (item.add) {
            // tslint:disable-next-line:no-non-null-assertion
            this.add(item.notification);
          } else {
            this.defaultBehavior(item);
          }
          break;

        default:
          this.defaultBehavior(item);
          break;
      }
      if (!(this.cd as ViewRef).destroyed) {
        this.cd.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    if (this.listener) {
      this.listener.unsubscribe();
    }
    this.cd.detach();
  }

  // TODO
  // Default behavior on event
  defaultBehavior(value: NotificationEvent): void {
    const notificationGroupKey = this.getNotificationGroupKey(value.notification);

    this.allNotification[notificationGroupKey].splice(this.allNotification[notificationGroupKey].indexOf(value.notification), 1);
    this.destroy.emit(this.buildEmit(value.notification, false));
  }

  // Add the new notification to the notification array
  add(item: Notification): void {
    item.createdOn = new Date();

    const toBlock: boolean = this.preventLastDuplicates || this.preventDuplicates ? this.block(item) : false;

    // Save this as the last created notification
    this.lastNotificationCreated = item;
    // Override icon if set
    if (item.override) {
      if (item.override.icons && item.override.icons[item.type]) {
        item.icon = item.override.icons[item.type];
      } else if (item.override.icon) {
        item.icon = item.override.icon;
      }
    }

    const notificationGroupKey = this.getNotificationGroupKey(item);

    if (!toBlock) {
      // Check if the notification should be added at the start or the end of the array
      if (this.lastOnBottom) {
        if (this.allNotification[notificationGroupKey].length >= this.maxStack) {
          this.allNotification[notificationGroupKey].splice(0, 1);
        }

        this.allNotification[notificationGroupKey].push(item);
      } else {
        if (this.allNotification[notificationGroupKey].length >= this.maxStack) {
          this.allNotification[notificationGroupKey].splice(this.allNotification[notificationGroupKey].length - 1, 1);
        }

        this.allNotification[notificationGroupKey].splice(0, 0, item);
      }

      this.create.emit(this.buildEmit(item, true));
    }
  }

  private getNotificationGroupKey(item: Notification) {
    if (!isNil(item.override) && !isNil(item.override.position)) {
      return `${item.override.position[0]}_${item.override.position[1]}`;
    } else {
      return `${this.service.globalOptions.position[0]}_${this.service.globalOptions.position[1]}`;
    }
  }

  // Check if notifications s1hould be prevented
  block(item: Notification): boolean {
    const toCheck = item.html ? this.checkHtml : this.checkStandard;

    const notificationGroupKey = this.getNotificationGroupKey(item);
    const notifications = this.allNotification[notificationGroupKey];
    if (this.preventDuplicates && notifications.length > 0) {
      for (const notification of notifications) {
        if (toCheck(notification, item)) {
          return true;
        }
      }
    }

    if (this.preventLastDuplicates) {
      let comp: Notification;

      if (this.preventLastDuplicates === 'visible' && notifications.length > 0) {
        if (this.lastOnBottom) {
          comp = notifications[notifications.length - 1];
        } else {
          comp = notifications[0];
        }
      } else if (this.preventLastDuplicates === 'all' && this.lastNotificationCreated) {
        comp = this.lastNotificationCreated;
      } else {
        return false;
      }
      return toCheck(comp, item);
    }

    return false;
  }

  checkStandard(checker: Notification, item: Notification): boolean {
    return checker.type === item.type && checker.title === item.title && checker.content === item.content && checker.state === item.state;
  }

  checkHtml(checker: Notification, item: Notification): boolean {
    return checker.html
      ? checker.type === item.type &&
          checker.title === item.title &&
          checker.content === item.content &&
          checker.html === item.html &&
          checker.state === item.state
      : false;
  }

  // Attach all the changes received in the options object
  attachChanges(options: any) {
    for (const key in options) {
      if (this.hasOwnProperty(key)) {
        (this as any)[key] = options[key];
      } else if (key === 'icons') {
        this.service.icons = options[key];
      }
    }
  }

  buildEmit(notification: Notification, to: boolean) {
    const toEmit: Notification = {
      createdOn: notification.createdOn,
      type: notification.type,
      icon: notification.icon,
      id: notification.id,
      autoId: notification.autoId,
    };

    if (notification.html) {
      toEmit.html = notification.html;
    } else {
      toEmit.title = notification.title;
      toEmit.content = notification.content;
    }

    if (!to) {
      toEmit.destroyedOn = new Date();
    }

    return toEmit;
  }

  // TODO
  cleanSingle(item: Notification): void {
    let indexOfDelete = 0;
    let doDelete = false;
    let noti;

    const notificationGroupKey = this.getNotificationGroupKey(item);

    this.allNotification[notificationGroupKey].forEach((notification, idx) => {
      if (notification.id === item.id) {
        indexOfDelete = idx;
        noti = notification;
        doDelete = true;
      }
    });

    if (doDelete) {
      this.allNotification[notificationGroupKey].splice(indexOfDelete, 1);
      this.destroy.emit(this.buildEmit(noti, false));
    }
  }

  private initAllNotification() {
    return this.allPositionType.reduce((prev, curr) => {
      prev[`${curr[0]}_${curr[1]}`] = [] as Notification[];
      return prev;
    }, {});
  }
}
