import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { canRegisterSuccessFulVisiblePage } from './authentication/layout/register/registration-successful/registration-successful.component';

@Injectable({
  providedIn: 'root',
})
export class StopDeactivateGuard implements CanDeactivate<any> {
  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return !canRegisterSuccessFulVisiblePage.visible;
  }
}
