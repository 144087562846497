import { Component } from '@angular/core';
import { environment } from '@roadrecord/environment';

@Component({
  selector: 'rr-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss'],
})
export class WarningComponent {
  readonly iosMarketUrl = environment.badge.ios.url;
  readonly androidMarketUrl = environment.badge.android.url;
}
