import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { resendActivationRouterStep } from '../resend-activation-router.step';
import { isNil } from '@roadrecord/type-guard';

// prettier-ignore

@Component({
  selector: 'rr-resend-activation-successful',
  templateUrl: './resend-activation-successful.component.html',
  styleUrls: ['./resend-activation-successful.component.scss']
})
export class ResendActivationSuccessfulComponent implements OnInit {
  email: string;

  constructor(private readonly router: Router , private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (isNil(this.route.snapshot.queryParams.email) || resendActivationRouterStep.resendActivation === false) {
      this.router.navigate(['/']);
    }else if(!isNil(this.route.snapshot.queryParams.email)){
      this.email = this.route.snapshot.queryParams.email;
    }
  }
}
