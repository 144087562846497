import {
  CheckModifiedManualForm,
  CheckModifiedMVPFormGuard,
  CheckNewOrLoadPlugin,
  commonHttpStreamErrorHandler,
  getSearchMissingFieldAndShowNotificationWithCustomBase,
  HasPresenterDeletePlugin,
  HasPresenterSavePluginController,
  PresenterSavePluginOptionsModel,
  PresenterSaveSaveDataCallbackStrategy,
  PresenterStateController,
  SaveModel,
  SearchMissingFieldAndShowNotificationCtor,
  SearchMissingFieldAndShowNotificationFields,
} from '@roadrecord/utils';
import { FuelingModel } from '../../model/fueling.model';
import { ChangeDetectorRef, Directive, Host, Inject, OnDestroy, OnInit, Type } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Notification, NotificationsService } from '@roadrecord/external-packages/angular2-notifications';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TranslocoService } from '@ngneat/transloco';
import { deepEqual, FUELING_ROUTE_PATH, MONTH_ACTIVITIES_PAGE_PATH, throwNotOverrideError } from '@roadrecord/common/common';
import { VehicleModel } from '@roadrecord/vehicle/model/common';
import { DataFormComponent } from '../data-form/data-form.component';
import { Observable, ReplaySubject, timer } from 'rxjs';
import { DOCUMENT, Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuelingService } from '../../fueling.service';
import { Store } from '@ngxs/store';
import { baseNotAccessUrl, MenuService } from '@roadrecord/app-layout/common';
import { MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { RealFuelBottomSheetComponent } from '../real-fuel-bottom-sheet/real-fuel-bottom-sheet.component';
import { untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, map, skip, take, tap } from 'rxjs/operators';
import moment, { Moment } from 'moment';
import { isBoolean, isEmptyString, isNil } from '@roadrecord/type-guard';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { FuelingRangeRefreshAction } from '@roadrecord/fueling-range/common';
import { searchMissingFieldTranslate } from './search-missing-field.config';
import { VehicleService } from '@roadrecord/vehicle/service/common';

/**
 * fueling detailshezz kello eszkozok,
 * fileReplacement miatt lett igy csinalva
 */

export class SearchMissingFieldAndShowNotificationBase extends CheckModifiedManualForm<FuelingModel | string> {
  constructor(
    readonly searchMissingFieldAndShowNotification__BottomSheetClassRef: Type<any>,
    readonly notificationSettingsOverride: Notification,
    readonly route: ActivatedRoute,
    readonly notificationsService: NotificationsService,
    readonly bottomSheet: MatBottomSheet
  ) {
    super();
  }

  get formModel(): FuelingModel | string {
    throw throwNotOverrideError('formModel');
  }

  get originalModel(): FuelingModel | string {
    throw throwNotOverrideError('originalModel');
  }
}

export const presenterSavePluginOptions = new PresenterSavePluginOptionsModel({
  presenterSaveSaveDataCallbackStrategy: PresenterSaveSaveDataCallbackStrategy.BEFORE_RUN_PLUGIN,
  // tslint:disable-next-line
  routingWhenSaveAndNotReset: function (): boolean {
    // tslint:disable-next-line
    this.checkModifiedMVPFormGuard.disableNextCheck = true;
    // tslint:disable-next-line
    const fromQueryParam = this.route.snapshot.queryParamMap.get('from');
    if (fromQueryParam && fromQueryParam === 'dayList') {
      // tslint:disable-next-line
      this.router.navigate([this.presenterStateController.isNewLastValue ? '../day-list' : '../'], {
        relativeTo: this.route,
        queryParams: { selectedDay: this.selectedDayText },
      });
    } else {
      this.location.back();
      // this.router.navigate([this.presenterStateController.isNewLastValue ? '../' : '../../'], { relativeTo: this.route });
    }
    return false;
  },
  // tslint:disable-next-line
  responseOkCallback: function (): string {
    return 'dispatchRefuelingStagesRefreshAction';
  },
});

export const presenterDeleteOptionServiceRemoveCb = (service, result, entityId, okCb, errorCb) =>
  service.removeV2(entityId, result.slideToggleValue).subscribe(okCb, errorCb);

const _BASE = getSearchMissingFieldAndShowNotificationWithCustomBase<VehicleModel>(
  SearchMissingFieldAndShowNotificationBase,
  searchMissingFieldTranslate
);

@Directive()
export abstract class CommonDetailsComponent
  extends (_BASE as SearchMissingFieldAndShowNotificationCtor<VehicleModel> & typeof SearchMissingFieldAndShowNotificationBase)
  implements
    SearchMissingFieldAndShowNotificationFields<VehicleModel>,
    OnInit,
    HasPresenterSavePluginController<FuelingModel, DataFormComponent>,
    HasPresenterDeletePlugin,
    OnDestroy {
  readonly isNew$: Observable<boolean>;
  readonly editModel$: ReplaySubject<any>;
  readonly loading$: ReplaySubject<boolean>;
  // @ViewChild('dataForm', { static: true })
  dataForm: DataFormComponent;

  consumption: { value: number };
  previous_mileage: { value: number };
  selectedDayText: string;
  mileageCalculationValue: { value: boolean } = { value: false };
  selectedTime: string;
  // wokirng with am pm dateFormat = this.currentEnvironment.appType === AppTypeEnum.HU ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD hh:mm A';
  dateFormat = 'YYYY-MM-DD HH:mm';
  /**
   * Tudunk-e kalkulalni
   * #RROHU-2369
   */
  canCalculation = true;
  calculated_distance: number | null = null;
  calculated_fueling_mileage: number | null = null;
  private checkNewOrLoadController: CheckNewOrLoadPlugin<FuelingModel, number>;
  private runOnNewConsumptionDataRequestSignal = false;
  private vehicleRealFuelNotificationAlertRef: Notification;
  private skipNextPeriodContextCheck = false;

  constructor(
    private router: Router,
    route: ActivatedRoute,
    @Host() readonly presenterStateController: PresenterStateController<any, any>,
    private cdr: ChangeDetectorRef,
    private translocoService: TranslocoService,
    @Inject(DOCUMENT) private document: Document,
    private matSnackBar: MatSnackBar,
    private fuelingService: FuelingService,
    private checkModifiedMVPFormGuard: CheckModifiedMVPFormGuard,
    private store: Store,
    private menuService: MenuService,
    private messageDialogService: MessageDialogService,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    notificationsService: NotificationsService,
    bottomSheet: MatBottomSheet,
    private vehicleService: VehicleService<any>,
    private location: Location
  ) {
    super(RealFuelBottomSheetComponent, null, route, notificationsService, bottomSheet);

    this.editModel$ = this.presenterStateController.editModel$;
    this.loading$ = this.presenterStateController.loading$;
    this.isNew$ = presenterStateController.isNew$.pipe(
      untilDestroyed(this),
      take(1),
      tap(() => this.cdr.detectChanges())
    );

    this.checkNewOrLoadController = this.presenterStateController.get(CheckNewOrLoadPlugin);

    this.checkPeriodContextChange();
  }

  get formModel(): any {
    const newModel = this.presenterStateController.formGroupLastValue.getRawValue();
    return {
      cost: newModel.cost,
      fueling_datetime:
        (newModel as any).date && (newModel as any).time
          ? this.getDateTimeString((newModel as any).date, (newModel as any).time)
          : undefined,
      mileage: newModel.mileage,
      quantity: newModel.quantity,
      refueling_location: newModel.refueling_location,
      distance: newModel.distance,
    } as any;
  }

  get originalModel(): any {
    const newModel = this.presenterStateController.editModelLastValue ? this.presenterStateController.editModelLastValue : {};
    return {
      cost: newModel.cost,
      fueling_datetime:
        (newModel as any).date && (newModel as any).time
          ? this.getDateTimeString((newModel as any).date, (newModel as any).time)
          : undefined,
      mileage: newModel.mileage,
      quantity: newModel.quantity,
      refueling_location: newModel.refueling_location ? newModel.refueling_location.id : undefined,
      distance: newModel.distance,
    } as any;
  }

  ngOnInit() {
    super.ngOnInit();

    this.selectedTime = this.route.snapshot.queryParamMap.get('selectedTime');
    let selectedDayText = this.route.snapshot.queryParamMap.get('selectedDay');
    if (selectedDayText !== null && this.route.snapshot.paramMap.get('id') === null) {
      // new
      this.dataForm.firstLoadedData = true;
      this.checkNewOrLoadController.checkIsNew().then(() => {
        this.newCheckIsNewThen();
        this.selectedDayText = selectedDayText;

        if (this.route.snapshot.queryParamMap.get('returnFromNewChargingStation') !== null) {
          this.messageDialogService
            .open({
              id: null,
              title: 'FUELING.RECORD_NEW_CHARGING_STATION.TITLE',
              text: 'FUELING.RECORD_NEW_CHARGING_STATION.TEXT',
              textParams: {
                date: this.selectedDayText,
              },
              translateText: true,
              confirmLabel: 'FUELING.RECORD_NEW_CHARGING_STATION.BUTTON',
              type: MessageDialogTypeEnum.INFORMATION,
            })
            .afterClosed()
            .subscribe(() => {
              // eltaviltjuk az url-bol az uzenet flag-et
              this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                  selectedDay: this.selectedDayText,
                  // selectedTime: this.presenterStateController.editModelLastValue.
                },
                replaceUrl: true,
              });

              this.dataForm.focusCostDirective.first.focusElement(300);
            });
        }
      });
    } else {
      // modify
      this.checkNewOrLoadController.checkIsNew().then(() => {
        this.editModel$.pipe(take(1)).subscribe(editModel => {
          selectedDayText = editModel.fueling_datetime.split('T')[0];
          timer(0).subscribe(() => {
            /**
             * TODO datum rosszul van kezelve a serveren es timezone-val menti de kozben nem allitja
             * be a timezone-t ezert ideiglenes megoldas hogy mi szedjuk szet mint szoveg es rakjuk
             * ossze a datumot
             */
            const splittedDateTime = editModel.fueling_datetime.split('T');
            editModel.date = moment(`${splittedDateTime[0]} ${splittedDateTime[1].split('.')[0]}`).toDate() as any;
            editModel.time = moment(editModel.date).format('HH:mm:ss');

            this.dataForm.form.patchValue(this.onModifyInit(editModel));
            this.editModel$.next(editModel);
            this.selectedDayText = selectedDayText;
            if (this.dataForm.firstLoadedData === false) {
              timer(0).subscribe(() => {
                this.dataForm.form.enable();
                // nem szabad modifyben kalkulaodnia
                timer(500).subscribe(() => (this.dataForm.firstLoadedData = true));
              });
            }
          });
        });
      });
    }

    // Set canCalculation
    this.searchMissingFieldAndShowNotification__GetData()
      .pipe(untilDestroyed(this), take(1))
      .subscribe(hasPeriodContext => (this.canCalculation = hasPeriodContext === null));
  }

  onDelete(): void {}

  onSubmit(model: SaveModel<FuelingModel>): void {
    const newModel = model.model;
    model.model = {
      cost: newModel.cost,
      fueling_datetime: this.getDateTimeString((newModel as any).date, (newModel as any).time),
      mileage: newModel.mileage,
      quantity: newModel.quantity,
      refueling_location: newModel.refueling_location,
      distance: newModel.distance,
      mileage_calculation:
        /* csak uj eseten kuldjuk az erteket */ this.presenterStateController.isNewLastValue === false
          ? false
          : newModel.mileage_calculation,
    } as any;
    model.removeFieldsBeforeSave = ['time', 'date'];
  }

  onNewConsumptionDataRequestSignal($event: { date: Moment | Date; time: string }): void {
    if (this.runOnNewConsumptionDataRequestSignal === true) {
      return;
    }
    this.runOnNewConsumptionDataRequestSignal = true;
    this.loading$.next(true);
    this.cdr.detectChanges();
    this.consumption = undefined;
    this.previous_mileage = undefined;

    this.translocoService
      .selectTranslate(['FUELING.SNACKBAR.LOADING_RELATION_DATAS', 'FUELING.SNACKBAR.LOADED_RELATION_DATAS', 'COMMON.ACTION.CLOSE_LOWER'])
      .pipe(untilDestroyed(this))
      .subscribe(translatedTexts => {
        this.matSnackBar.open(translatedTexts[0]);

        const dateTimeStr = this.getDateTimeString(moment($event.date, 'YYYY-MM-DD').toDate(), $event.time);
        this.fuelingService
          .getNewFuelingParams(
            dateTimeStr,
            this.presenterStateController.isNewLastValue
              ? null
              : this.fuelingService.getModelIdValue(this.presenterStateController.editModelLastValue)
          )
          .subscribe(
            value => {
              this.calculated_distance = value.calculated_distance;
              this.calculated_fueling_mileage = value.calculated_fueling_mileage;
              this.mileageCalculationValue = {
                value: isBoolean(value.mileage_calculation) ? value.mileage_calculation : false,
              };
              this.consumption = { value: value.consumption };
              this.previous_mileage = { value: value.previous_mileage };
              this.loading$.next(false);
              this.cdr.detectChanges();
              this.matSnackBar.open(translatedTexts[1], translatedTexts[2]);
              this.runOnNewConsumptionDataRequestSignal = false;
            },
            commonHttpStreamErrorHandler(() => (this.runOnNewConsumptionDataRequestSignal = false))
          );
      });
  }

  onBack(): void {
    this.presenterStateController.formGroupLastValue.submitted = true;

    const options: NavigationExtras = { relativeTo: this.route };
    let route = '..';
    const fromQueryParam = this.route.snapshot.queryParamMap.get('from');
    if (fromQueryParam && fromQueryParam === 'dayList') {
      options.queryParams = { selectedDay: this.route.snapshot.queryParamMap.get('selectedDay') };
      if (this.presenterStateController.isNewLastValue) {
        route = '../day-list';
      } else {
        route = '../';
      }
    }
    this.router.navigate([route], options);
  }

  deleteOkCallback(): void {
    this.dispatchRefuelingStagesRefreshAction();
    this.router.navigate(['../'], { relativeTo: this.route, preserveQueryParams: true });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.matSnackBar.dismiss();
  }

  @Dispatch()
  dispatchRefuelingStagesRefreshAction(): FuelingRangeRefreshAction {
    return new FuelingRangeRefreshAction();
  }

  searchMissingFieldAndShowNotification__BottomSheetOnSubmit(formValue: VehicleModel): Observable<unknown> {
    this.skipNextPeriodContextCheck = true;
    timer(5000)
      .pipe(untilDestroyed(this))
      .subscribe(() => (this.skipNextPeriodContextCheck = false));
    return this.vehicleService
      .patch(this.vehicleService.getModelIdValue(this.searchMissingFieldAndShowNotification__OriginalData.data), formValue)
      .pipe(tap(() => (this.canCalculation = true)));
  }

  searchMissingFieldAndShowNotification__GetData(): Observable<unknown> {
    return this.route.data
      .pipe(untilDestroyed(this))
      .pipe(
        map(routeData =>
          !isNil(routeData.periodContextVehicle) && isNil(routeData.periodContextVehicle.real_fuel)
            ? { data: routeData.periodContextVehicle }
            : null
        )
      );
  }

  searchMissingFieldAndShowNotification__AfterDismissedBottomSheet() {
    if (!isNil(this.dataForm.lastSearchedDate) && !isNil(this.dataForm.lastSearchedTime)) {
      this.onNewConsumptionDataRequestSignal({
        date: this.dataForm.lastSearchedDate,
        time: this.dataForm.lastSearchedTime,
      } as any);
    }
    if (isNil(this.dataForm.refuelingLocationControl.value) || isEmptyString(this.dataForm.refuelingLocationControl.value)) {
      this.dataForm.refuelingLocationLiveAutoComplete.first.openPanel();
    }
  }

  protected abstract getSaveHttpParams(): any;

  private checkPeriodContextChange(): void {
    this.store
      .select(this.periodContextStateSelectorsService.context)
      .pipe(
        skip(1),
        distinctUntilChanged((_old, _new) => deepEqual(_old, _new)),
        untilDestroyed(this),
        take(1)
      )
      .subscribe(() => {
        if (this.skipNextPeriodContextCheck === true) {
          this.skipNextPeriodContextCheck = false;
          return;
        }
        this.loading$.next(true);
        this.checkModifiedMVPFormGuard.disableNextCheck = true;
        this.menuService
          .hasMenuItemByUrl()
          .pipe(untilDestroyed(this))
          .subscribe(v => {
            if (v === true) {
              // notAccessRedirect(this.injector, [`/${MONTH_ACTIVITIES_PAGE_PATH}/${FUELING_ROUTE_PATH}`]);
              this.router.navigate([`/${MONTH_ACTIVITIES_PAGE_PATH}/${FUELING_ROUTE_PATH}`]);
            } else {
              // notAccessRedirect(this.injector);
              this.router.navigate([baseNotAccessUrl]);
            }
          });
      });
  }

  private getDateTimeString(date: Date, time: string, format = this.dateFormat): string {
    let splittedTime = time.split(' ');
    if (splittedTime.length === 2) {
      const meridian = splittedTime[1];
      splittedTime = splittedTime[0].split(':');
      if (meridian === 'PM') {
        splittedTime[0] = `${+splittedTime[0] + 12}`;
      }
    } else {
      splittedTime = splittedTime[0].split(':');
    }
    const datetime = moment(date);
    datetime.set({
      hour: parseInt(splittedTime[0], undefined),
      minute: parseInt(splittedTime[1], undefined),
    });

    return datetime.format(format);
  }

  protected abstract onModifyInit<T>(editModel: T): T;

  protected newCheckIsNewThen() {}
}
