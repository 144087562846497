import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxsModule } from '@ngxs/store';

import {
  CardContentLoadingBarModule,
  DataCyDirectiveModule,
  FileSizePipeModule,
  IsBooleanPipeModule,
  IsDisabledPipeModule,
  IsFunctionPipeModule,
  IsNilPipeModule,
  PreventClickModule,
  SanitizePipeModule,
} from '@roadrecord/common/common';
import { RightClickMenuModule } from '@roadrecord/right-click-menu';
import { ValidatingModule } from '@roadrecord/validating';
import { GridBottomSheetComponent } from './grid-bottom-sheet/grid-bottom-sheet.component';
import { GridComponent } from './grid/grid.component';
import { GridState } from './state/grid.state';
import { IsNotDeletableAndEditablePipe } from './pipe/is-not-deletable-and-editable.pipe';
import { SecureDeleteDialogModule } from '@roadrecord/secure-delete-dialog';
import { EditCellComponent } from './edit-cell/edit-cell.component';
import { LiveAutoCompleteModule } from '@roadrecord/live-auto-complete';
import { HasIdPipe } from './has-id.pipe';
import { IMaskModule } from 'angular-imask';
import { SplitButtonModule } from '@roadrecord/split-button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,

    FlexLayoutModule,

    TranslocoModule,

    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule,
    MatRippleModule,
    MatButtonModule,
    MatSnackBarModule,
    MatRadioModule,
    MatBottomSheetModule,

    PreventClickModule,
    ValidatingModule,
    IsFunctionPipeModule,
    IsBooleanPipeModule,

    CardContentLoadingBarModule,
    NgxsModule.forFeature([GridState]),

    RightClickMenuModule,
    MatProgressBarModule,
    SecureDeleteDialogModule,
    LiveAutoCompleteModule,
    IMaskModule,
    // TODO elvileg ezt nem kene importalni, kovi updatenel ellenorizni hogy szukseg-e meg
    IsNilPipeModule,
    SanitizePipeModule,
    FileSizePipeModule,
    SplitButtonModule,
    IsDisabledPipeModule,
    DataCyDirectiveModule,
    MatDialogModule,
    MatDatepickerModule,
    MatSelectModule,
    InfiniteScrollModule,
  ],
  declarations: [GridComponent, GridBottomSheetComponent, IsNotDeletableAndEditablePipe, EditCellComponent, HasIdPipe],
  exports: [GridComponent],
  entryComponents: [GridBottomSheetComponent],
})
export class GridModule {}
