import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FieldType } from '@ngx-formly/material/form-field';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'rr-mat-input-field',
  template: `
    <ng-container *ngIf="to?.numberMaskOptions; else simpleField">
      <input
        matInput
        [id]="id"
        type="text"
        [readonly]="to.readonly"
        [required]="to.required"
        [errorStateMatcher]="errorStateMatcher"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [tabindex]="to.tabindex"
        [placeholder]="to.placeholder"
        [name]="field.key"
        [imask]="to.numberMaskOptions"
        unmask="typed"
        #inputElement
      />
    </ng-container>
    <ng-template #simpleField>
      <input
        *ngIf="type !== 'number'; else numberTmp"
        matInput
        [id]="id"
        [type]="type || 'text'"
        [readonly]="to.readonly"
        [required]="to.required"
        [errorStateMatcher]="errorStateMatcher"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [tabindex]="to.tabindex"
        [placeholder]="to.placeholder"
        [name]="field.key"
        #inputElement
      />
      <ng-template #numberTmp>
        <input
          matInput
          [id]="id"
          type="number"
          [readonly]="to.readonly"
          [required]="to.required"
          [errorStateMatcher]="errorStateMatcher"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [tabindex]="to.tabindex"
          [placeholder]="to.placeholder"
          [name]="field.key"
        />
      </ng-template>
    </ng-template>
  `,
})
export class MaterialInputField extends FieldType implements OnInit {
  @ViewChild(MatInput, { static: false }) formFieldControl!: MatInput;
  @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;

  constructor(errorStateMatcher: ErrorStateMatcher) {
    super();
    this.errorStateMatcher = errorStateMatcher as any;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  get type() {
    return this.to.type || 'text';
  }
}
