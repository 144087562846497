import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { environment } from '@roadrecord/environment';

@UntilDestroy()
@Component({
  selector: 'rr-unauthenticated-footer',
  templateUrl: './unauthenticated-footer.component.html',
  styleUrls: ['./unauthenticated-footer.component.scss'],
})
export class UnauthenticatedFooterComponent {
  readonly clientVersion = environment.VERSION;
  readonly serverVersion$: Observable<string>;

  constructor(private store: Store) {
    this.serverVersion$ = this.store.select(states => states.applicationSettings.serverVersion).pipe(untilDestroyed(this));
  }

  getThisYear(): number {
    return moment().toDate().getFullYear();
  }
}
