import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialButtonsCardComponent } from './social-buttons-card/social-buttons-card.component';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [SocialButtonsCardComponent],
  imports: [CommonModule, MatCardModule, MatButtonModule, RouterModule, TranslocoModule, MatIconModule],
  exports: [SocialButtonsCardComponent],
})
export class SocialButtonsCardModule {}
