import { ChangeDetectorRef, Component, Inject, Optional, Output } from '@angular/core';
import { BehaviorSubject, Observable, race } from 'rxjs';
import { PARTNER_SERVICE_TYPE_TOKEN, PartnerService, partnerServiceFactory } from '@roadrecord/partner/common';
import { liveAutoCompleteDiFactoryReturnType } from '@roadrecord/live-auto-complete';
import { PartnerListModel } from '@roadrecord/partner/model';
import { Validators } from '@angular/forms';
import {
  commonHttpStreamErrorHandler,
  FieldHttpError,
  focusElement2,
  FormControl,
  FormGroup,
  HttpListResponseModel,
  MaybeHandleHttpError,
} from '@roadrecord/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  FragmentDialogTypeEnum,
  FragmentHideDialogAction,
  FragmentRemoveDialogAction,
  FragmentShowDialogAction,
} from '@roadrecord/fragment-dialog';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { PARTNER_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG } from '@roadrecord/partner/live-auto-complete';
import { ActivatedRoute } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { UniversalValidators } from 'ngx-validators';
import { CommonValidators } from '@roadrecord/validating';
import { isNil, isNotEmptyString, isString } from '@roadrecord/type-guard';
import { StartData } from '../../model/start-data';
import { EndData } from '../../model/end-data';
import { isStartData } from '../../model/is-start-data.function';
import moment from 'moment';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { MileageService } from '../../mileage.service';
import { globalImaskNumberConfigGenerator } from '@roadrecord/imask/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { DOCUMENT } from '@angular/common';
import { deletePopup, MessageDialogService } from '@roadrecord/message-dialog';
import { HttpErrorInterceptor } from '@roadrecord/http-error-interceptor';
import { filter, map, take } from 'rxjs/operators';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';

@UntilDestroy()
@Component({
  selector: 'rr-data-form-sheet',
  templateUrl: './data-form-sheet.component.html',
  styleUrls: ['./data-form-sheet.component.scss'],
  providers: [
    {
      provide: PartnerService,
      useFactory: partnerServiceFactory,
      deps: [ActivatedRoute, HttpClient, [new Optional(), PARTNER_SERVICE_TYPE_TOKEN]],
    },
    MileageService,
  ],
})
export class DataFormSheetComponent {
  loading = false;
  pickupMode = false;
  readonly mileageControl = new FormControl(
    0,
    Validators.compose([Validators.required, UniversalValidators.isNumber, CommonValidators.greaterThan(0)])
  );
  readonly dateControl = new FormControl(null, Validators.required);
  readonly partner_idControl = new FormControl();
  readonly form = new FormGroup({
    mileage: this.mileageControl,
    date: this.dateControl,
    partner_id: this.partner_idControl,
  });
  @Output() createdForm = new BehaviorSubject(this.form);
  readonly laPartnerOptionsConfig = this._laPartnerOptionsConfig();
  minDate: any;
  maxDate: any;
  readonly mileageNumberMask = globalImaskNumberConfigGenerator({ scale: 0, max: 999999 });
  private httpErrorInterceptor: HttpErrorInterceptor;

  constructor(
    private mileageService: MileageService,
    readonly partnerService: PartnerService,
    @Inject(PARTNER_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG)
    private _laPartnerOptionsConfig: liveAutoCompleteDiFactoryReturnType<PartnerListModel>,
    private store: Store,
    private periodContextSelectors: PeriodContextStateSelectorsService<any, any>,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    readonly data: {
      layoutType: 'START' | 'END';
      model: StartData | EndData;
    },
    private matBottomSheet: MatBottomSheet,
    private matSnackBar: MatSnackBar,
    private translocoService: TranslocoService,
    @Inject(DOCUMENT) private document: Document,
    private cdr: ChangeDetectorRef,
    @Inject(HTTP_INTERCEPTORS) private httpInterceptors: any[],
    private actions$: Actions
  ) {
    this.httpErrorInterceptor = httpInterceptors.find(httpInterceptor => httpInterceptor instanceof HttpErrorInterceptor);
    this.init();
  }

  private init() {
    if (isStartData(this.data.model)) {
      this.pickupMode = this.data.model.is_first_mileage;
      if (this.pickupMode === false) {
        this.dateControl.disable();
      }
    }
    if (this.data.layoutType === 'END' && !isStartData(this.data.model)) {
      this.form.removeControl('partner_id');
      if (this.data.model.is_last_mileage === false) {
        this.form.get('date').disable();
      }
    }
    // set min/max date
    const date = moment(this.data.model.date);
    this.minDate = date.startOf('month').toDate();
    this.maxDate = date.endOf('month').toDate();

    this.form.patchValue(this.data.model);
  }

  getPartnerLiveAutocompleteCallback(): (
    sort: Sort,
    page: PageEvent,
    simpleAllFilterValue: string
  ) => Observable<HttpListResponseModel<PartnerListModel>> {
    return this.getPartnerLiveAutocompleteList.bind(this);
  }

  getPartnerLiveAutocompleteList(
    sort: Sort = { active: '', direction: 'asc' },
    page: PageEvent = {
      pageIndex: 1,
      pageSize: 99999999,
      length: 1,
    },
    simpleAllFilterValue = ''
  ): Observable<HttpListResponseModel<PartnerListModel>> {
    sort.active = 'name';
    return this.partnerService
      .getAllWithExtraQueryParams(sort, page, simpleAllFilterValue, { 'vehicle-takeover': 1, expand: 'trip_reason' })
      .pipe(untilDestroyed(this));
  }

  onClickNewPartner(search: string) {
    this.partner_idControl.disable();

    race([
      this.actions$.pipe(
        ofActionSuccessful(FragmentHideDialogAction),
        filter(action => action.dialogType === FragmentDialogTypeEnum.NEW_PARTNER)
      ),
      this.actions$.pipe(
        ofActionSuccessful(FragmentRemoveDialogAction),
        filter(action => action.dialogType === FragmentDialogTypeEnum.NEW_PARTNER)
      ),
    ])
      .pipe(untilDestroyed(this), take(1))
      .subscribe((action: FragmentHideDialogAction | FragmentRemoveDialogAction) => {
        this.partner_idControl.enable();
        if (
          (action instanceof FragmentHideDialogAction && (action.cancel === true || action.locationBack === true)) ||
          action instanceof FragmentRemoveDialogAction
        ) {
          return;
        }
        this.partnerService
          .getByIds([action.data.id], 'expand=trip_reason')
          .pipe(
            map(response => (response.results.length > 0 ? response.results[0] : null)),
            untilDestroyed(this)
          )
          .subscribe(newPartner => this.partner_idControl.patchValue(newPartner));
      });
    this.store.dispatch(new FragmentShowDialogAction(FragmentDialogTypeEnum.NEW_PARTNER, { showTopRightCloseButton: true }, { search }));
  }

  onClickCancel() {
    this.matBottomSheet.dismiss();
  }

  onClickSubmit() {
    this.form.submitted = true;
    if (this.form.valid) {
      this.form.clearRemoteErrors();
      this.loading = true;
      const formRawValue = this.form.getRawValue();
      const formValue = {
        ...formRawValue,
        date: isString(formRawValue.date) ? formRawValue.date : formRawValue.date.format('YYYY-MM-DD'),
      };
      this.form.disable();
      this.mileageService
        .createOrModifyMileage(formValue, this.data.layoutType, this.data.model.id, !isStartData(this.data.model) ? 'false' : undefined)
        .subscribe(
          (response: any) => {
            if (
              !isNil(response) &&
              !isStartData(this.data.model) &&
              isNotEmptyString(response.key) &&
              response.key === 'EQUALITY_OF_MILEAGES_EXCEPTION' &&
              Array.isArray(response.variables)
            ) {
              MessageDialogService.INSTANCE.openWarning(
                {
                  id: null,
                  enableCancel: true,
                  htmlMode: true,
                  title: 'COMMON.DIALOG.TITLE.WARNING',
                  text: 'MILEAGE.EQUALITY_OF_MILEAGES_EXCEPTION.CONTENT',
                  confirmLabel: 'MILEAGE.EQUALITY_OF_MILEAGES_EXCEPTION.YES',
                  cancelLabel: 'MILEAGE.EQUALITY_OF_MILEAGES_EXCEPTION.NO',
                },
                { maxWidth: 800 }
              )
                .afterClosed()
                .subscribe(({ result }) => {
                  if (result === true) {
                    this.mileageService.createOrModifyMileage(formValue, this.data.layoutType, this.data.model.id, 'true').subscribe(
                      () => {
                        this.matSnackBar.open(this.translocoService.translate('MILEAGE.DETAILS.DATA_FORM.SAVE_SUCCESS_SNACKBAR'));
                        this.matBottomSheet.dismiss();
                      },
                      commonHttpStreamErrorHandler(() => {
                        this.loading = false;
                        this.form.enable();
                      })
                    );
                  } else {
                    this.loading = false;
                    this.form.enable();
                  }
                });
            } else {
              this.matSnackBar.open(this.translocoService.translate('MILEAGE.DETAILS.DATA_FORM.SAVE_SUCCESS_SNACKBAR'));
              this.matBottomSheet.dismiss();
            }
          },
          error => {
            console.log(error);
            MaybeHandleHttpError.maybeHandleHttpError(error);
            this.loading = false;
            this.form.enable();
            if (isStartData(this.data.model) && this.data.model.is_first_mileage === false) {
              this.dateControl.disable();
            } else if (error instanceof FieldHttpError) {
              const remoteErrorsEntries = Object.entries(error.error.errors);
              this.form.setRemoteErrors(
                remoteErrorsEntries.reduce((prev, curr) => {
                  prev.push({ path: curr[0], message: curr[1][0] });
                  return prev;
                }, [] as { path: string; message: string }[])
              );
              // focus first error
              for (let i = 0; i < remoteErrorsEntries.length; i++) {
                const focused = focusElement2(this.document, remoteErrorsEntries[i][0]);
                if (focused) {
                  i = remoteErrorsEntries.length;
                }
              }
            }
          }
        );
    }
  }

  onDelete() {
    deletePopup({
      matSnackBar: this.matSnackBar,
      translocoService: this.translocoService,
      messageDialogService: MessageDialogService.INSTANCE,
      yesCallback: () => {
        this.loading = true;
        this.form.disable();
        this.cdr.detectChanges();
        this.mileageService.remove2(this.data.model).subscribe(
          () => {
            this.matSnackBar.open(this.translocoService.translate('MILEAGE.DETAILS.DATA_FORM.DELETE_SUCCESS_SNACKBAR'));
            this.matBottomSheet.dismiss();
          },
          commonHttpStreamErrorHandler(() => {
            this.loading = false;
            this.form.enable();
          })
        );
      },
    });
  }
}
