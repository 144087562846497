import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

export class RRValidators {
  /**
   *
   * @param min
   * @param decimalPipe azert callback, mert ha nyelvvaltas van akkor igy mukodni fog
   */
  static min(min: number, decimalPipe: () => DecimalPipe): ValidatorFn {
    const ngValidator = Validators.min(min);
    return (control: AbstractControl): ValidationErrors | null => {
      const maybeError = ngValidator(control);
      if (maybeError !== null) {
        maybeError.min.min = decimalPipe().transform(maybeError.min.min);
        maybeError.min.actual = decimalPipe().transform(maybeError.min.actual);
      }
      return maybeError;
    };
  }

  static max(max: number, decimalPipe: () => DecimalPipe): ValidatorFn {
    const ngValidator = Validators.max(max);
    return (control: AbstractControl): ValidationErrors | null => {
      const maybeError = ngValidator(control);
      if (maybeError !== null) {
        maybeError.max.max = decimalPipe().transform(maybeError.max.max);
        maybeError.max.actual = decimalPipe().transform(maybeError.max.actual);
      }
      return maybeError;
    };
  }
}
