import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngxs/store';
import { UniversalImportService } from '@roadrecord/universal-import/common';
import { FuelingImportModel } from './model/fueling-import.model';

export const fuelingImportEntityDefaultOrder = 'state';

@Injectable()
export class FuelingUniversalImportService extends UniversalImportService<FuelingImportModel> {
  constructor(http: HttpClient, store: Store, injector: Injector) {
    super(http, store, injector, 'fixed-destination/import', fuelingImportEntityDefaultOrder);
  }
}
