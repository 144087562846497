import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { MapBoxComponent } from './map-box.component';
import { PreventClickModule } from '@roadrecord/common/common';
import { TripReasonIconModule } from '@roadrecord/trip-reason/common';
import { MarkerPopUpPipe } from './pipes/marker-popup.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { mapBoxProviders } from './map-box.providers';

@NgModule({
  declarations: [MapBoxComponent, MarkerPopUpPipe],
  imports: [
    CommonModule,
    NgxMapboxGLModule,
    TranslocoModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    PreventClickModule,
    TripReasonIconModule,
    MatBadgeModule,
  ],
  providers: [mapBoxProviders],
  exports: [MapBoxComponent],
})
export class MapBoxModule {}
