import { Directive, Input, Self } from '@angular/core';
import { MatOption } from '@angular/material/core';

export interface RawValue<MODEL> {
  rawValue: MODEL;
}

@Directive({
  selector: 'mat-option[rrRawValue]',
})
export class RawValueDirective<MODEL> {
  private matOption: MatOption & RawValue<MODEL>;

  @Input()
  set rrRawValue(value: MODEL) {
    this.matOption.rawValue = value;
  }

  constructor(@Self() matOption: MatOption) {
    this.matOption = matOption as MatOption & RawValue<MODEL>;
  }
}
