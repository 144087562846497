import { Location } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { isNotEditable } from '@roadrecord/common/common';
import { ENTITY_SERVICE_TOKEN } from '../../../entity/service/entity-service.token';
import { AbstractEntityService } from '../../../entity/service/abstract-entity.service';
import { HttpListResponseModel } from '../../../http/model/http-list-response.model';
import { isNil, isNumeric } from '@roadrecord/type-guard';

import { forkJoin, Observable } from 'rxjs';
import { PresenterStateController } from '../../../form/controller/presenter-state/presenter-state.controller';
import { readIdParam } from '../../function/read-id-param.function';
import { NotificationStrategy } from './notification.strategy.enum';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable()
export class CheckNewOrLoadPlugin<MODEL, IDTYPE> {
  constructor(
    private readonly presenterStateController: PresenterStateController<MODEL, IDTYPE>,
    @Inject(ENTITY_SERVICE_TOKEN) private readonly service: AbstractEntityService<HttpListResponseModel<MODEL>, MODEL>,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    @Optional() private gtmService: GoogleTagManagerService
  ) {}

  async checkIsNew(
    hasIdCb?: () => void,
    combineStreams?: Observable<any>[],
    combineStreamsSubscriber?: (datas: any[]) => any,
    notificationStrategy = NotificationStrategy.FIRST_DATA_MODEL,
    justNewMode = false,
    editId?: number,
    /**
     * felugro ablakok miatt kel
     */
    routeMode?: boolean
  ): Promise<any> {
    const id =
      routeMode === undefined || routeMode === true
        ? await (isNumeric(editId) ? Promise.resolve(editId) : readIdParam(this.route).toPromise())
        : editId;

    if (id !== undefined && justNewMode === false) {
      // edit mode
      if (hasIdCb !== undefined) {
        hasIdCb();
      }
      if (combineStreams !== undefined && combineStreams.length > 0) {
        const remoteDatas = await forkJoin([this.service.get(id), ...combineStreams]).toPromise();
        if (this.checkIsNotEditable(remoteDatas[0]) === true) {
          return;
        }

        if (notificationStrategy === NotificationStrategy.FIRST_DATA_MODEL) {
          this.setDataModel(remoteDatas);
          this.notifyStreams(combineStreamsSubscriber, remoteDatas);
        } else if (notificationStrategy === NotificationStrategy.FIRST_COMBINE_STREAMS) {
          const first = remoteDatas.shift();
          remoteDatas.push(first);
          this.notifyStreams(combineStreamsSubscriber, remoteDatas);
          this.setDataModel(remoteDatas);
        }
      } else {
        const remoteData = await this.service.get(id).toPromise();
        if (this.checkIsNotEditable(remoteData) === true) {
          return;
        }

        this.presenterStateController.editModel$.next(remoteData);
      }
    } else if (justNewMode === true || (combineStreams !== undefined && combineStreams.length > 0)) {
      // new mode
      const remoteDatas = await forkJoin(combineStreams).toPromise();
      if (notificationStrategy === NotificationStrategy.FIRST_DATA_MODEL) {
        this.presenterStateController.editModel$.next(undefined);
        if (combineStreamsSubscriber !== undefined) {
          combineStreamsSubscriber(remoteDatas);
        }
      } else if (notificationStrategy === NotificationStrategy.FIRST_COMBINE_STREAMS) {
        if (combineStreamsSubscriber !== undefined) {
          const returnData: MODEL = combineStreamsSubscriber(remoteDatas);
          if (!isNil(returnData)) {
            this.presenterStateController.editModel$.next(returnData);
          } else {
            this.presenterStateController.editModel$.next(undefined);
          }
        } else {
          this.presenterStateController.editModel$.next(undefined);
        }
      }
    } else {
      this.presenterStateController.editModel$.next(undefined);
    }
    this.presenterStateController.loading$.next(false);
    this.presenterStateController.setFirstInit();
  }

  notifyStreams(combineStreamsSubscriber: (datas: any[]) => void, remoteDatas: any[]): void {
    if (combineStreamsSubscriber !== undefined) {
      combineStreamsSubscriber(remoteDatas);
    }
  }

  setDataModel(remoteDatas: any[]): void {
    this.presenterStateController.editModel$.next(remoteDatas[remoteDatas.length - 1]);
  }

  /**
   * nem szerkeszthetoseg ellenorzese
   */
  private checkIsNotEditable(data): boolean {
    const translocoService: TranslocoService = this.presenterStateController.get(TranslocoService);
    if (isNotEditable(data)) {
      const snackbar: MatSnackBar = this.presenterStateController.get(MatSnackBar);
      if (!isNil(snackbar)) {
        snackbar.open(translocoService.translate('CHECK_NEW_OR_LOAD_PLUGIN.SELECTED_NOT_DELETABLE'));
      }
      this.location.back();
      return true;
    }
    return false;
  }
}
