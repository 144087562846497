import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, map, skip, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StartData } from '../model/start-data';
import { EndData } from '../model/end-data';
import { MileageService } from '../mileage.service';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { baseNotAccessUrl, MenuService } from '@roadrecord/app-layout/common';
import { Store } from '@ngxs/store';
import { deepEqual } from '@roadrecord/common/common';

@UntilDestroy()
@Component({
  selector: 'rr-details',
  templateUrl: './details.component.html',
  providers: [MileageService],
})
export class DetailsComponent implements OnInit {
  readonly layoutType$: Observable<'START' | 'END'> = this.route.data.pipe(map(data => data.layoutType));
  readonly loading$ = new BehaviorSubject(true);
  model: StartData | EndData;
  private readonly data$ = new BehaviorSubject(true);

  constructor(
    private route: ActivatedRoute,
    private mileageService: MileageService,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    private store: Store,
    private menuService: MenuService,
    private router: Router
  ) {
    this.checkPeriodContextChange();
  }

  ngOnInit() {
    this.data$
      .pipe(
        untilDestroyed(this),
        switchMap(() => this.route.data),
        switchMap(data => this.mileageService.getData(data.layoutType))
      )
      .subscribe((data: StartData | EndData) => {
        this.model = data;
        this.loading$.next(false);
      });
  }

  onLoadingEvent($event: boolean) {
    this.loading$.next($event);
  }

  onRefresh() {
    this.loading$.next(true);
    this.data$.next(true);
  }

  private checkPeriodContextChange(): void {
    this.store
      .select(this.periodContextStateSelectorsService.context)
      .pipe(
        untilDestroyed(this),
        skip(1),
        distinctUntilChanged((_old, _new) => deepEqual(_old, _new))
      )
      .subscribe(() => {
        this.menuService.hasMenuItemByUrl().subscribe(v => {
          if (v === true) {
            this.data$.next(true);
          } else {
            // notAccessRedirect(this.injector);
            this.router.navigate([baseNotAccessUrl]);
          }
        });
      });
  }
}
