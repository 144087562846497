import { AbstractControl, ValidatorFn } from '@angular/forms';

// TODO deprecated mivel van mar egy jobb start-and-end-validator amire le kell cserelni!!!
export function ValidatorStartEndDate(startDateFieldName = 'start_date', endDateFieldName = 'end_date'): ValidatorFn {
  return (control: AbstractControl): any => {
    const startDate = control.get(startDateFieldName);
    const endDate = control.get(endDateFieldName);

    if (!startDate.value || !endDate.value) {
      return undefined;
    }

    const d1 = Date.parse(startDate.value);
    const d2 = Date.parse(endDate.value);

    if (d1 > d2) {
      endDate.setErrors({ invalidEndDate: true });
      startDate.setErrors({ invalidEndDate: true });
      return {};
    } else {
      if (!endDate.hasError('customError')) {
        endDate.setErrors(undefined);
      }
      if (!startDate.hasError('customError')) {
        startDate.setErrors(undefined);
      }
      return undefined;
    }
  };
}
