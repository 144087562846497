import { ChangeDetectorRef, Directive, OnDestroy, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { isNotHandledError } from '@roadrecord/utils';
import { MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';
import { isNil, isString } from '@roadrecord/type-guard';
import { untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, ConnectableObservable, EMPTY, Observable, of, timer } from 'rxjs';
import { publishReplay, skip, switchMap } from 'rxjs/operators';
import { FirstStepsService } from '../first-steps.service';
import { FirstStepsNextStepModel } from '../model/first-steps-next-step.model';
import { StepperConfigModel } from '../model/stepper-config.model';
import { FirstStepsState } from '../state/first-steps.state';
import { LoadableService } from '@roadrecord/external-packages/ngx-loadable';
import { GetUserStatesAction } from '@roadrecord/common/common';
import { FirstStepsRefreshRemoteStateSuccessAction } from '../state/action/first-steps-refresh-remote-state-success.action';
import { NextStepConfig } from '../next-step.config';
import { firstStepperFinish } from '../layouts/finish-welcome/first-stepper-finish.function';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AddAppOverlayAction, AppOverlayFinishedAndRemoveOverlayAction } from '@roadrecord/app-layout-state';
import { FirstStepsComponentTypeEnum } from '../model/first-steps-component-type.enum';
import { CompleteIntroAction, INTROJS_TYPES_ENUM, IntrojsState, StartIntroAction } from '@roadrecord/introjs/common';
import { HttpClient } from '@angular/common/http';
import { AppTypeEnum, environment } from '@roadrecord/environment';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { AppReadyAction } from '../../../../../application-settings/state/src/lib/state/action/app-ready.action';
import { MenuService } from '@roadrecord/app-layout/common';

function animateText({ text, elem, speed, renderer }: { text: string; speed: number; renderer: Renderer2; elem: HTMLElement }) {
  let i = 0;
  const maxLength = text.length;
  const showChars: string[] = [];

  const subscription = timer(0, speed).subscribe(() => {
    if (i < maxLength) {
      showChars.push(text[i++]);
      renderer.setProperty(elem, 'innerHTML', showChars.join(''));
    } else {
      subscription.unsubscribe();
    }
  });
}

@Directive()
export class CommonWrapperComponent implements OnDestroy {
  slideBottom: boolean;
  firstLoading = true;
  stepperConfig: StepperConfigModel;
  currentStep: FirstStepsNextStepModel;
  readonly isUsAppType = environment.appType === AppTypeEnum.US;
  readonly loading$ = new BehaviorSubject<boolean>(true);
  /**
   * @deprecated
   */
  private initedLangChange = false;

  constructor(
    protected firstStepsService: FirstStepsService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected translocoService: TranslocoService,
    protected window: Window,
    protected store: Store,
    protected actions$: Actions,
    protected messageDialogService: MessageDialogService,
    protected cdr: ChangeDetectorRef,
    protected gtmService: GoogleTagManagerService,
    loadableService: LoadableService,
    protected document: Document,
    protected renderer: Renderer2,
    protected readonly http: HttpClient,
    protected menuService: MenuService
  ) {
    this.preloadVehicleDetailsJs(loadableService);

    this.stepperConfigDetectLangChanges();
    const firstStepsStepState = this.store.selectSnapshot(FirstStepsState.step);
    if (firstStepsStepState < 0) {
      this.currentStep = ({
        step: firstStepsStepState,
      } as any) as FirstStepsNextStepModel;

      this.loading$.next(false);
      if (this.firstLoading === true) {
        this.firstLoading = false;
      }
    } else {
      this.onNextStep({ layoutName: 'AUTODETECT' });
    }
  }

  beforeStartNextStepXhr() {}

  afterStartNextStepXhr() {}

  onNextStep($event: NextStepConfig): void {
    // mivel nem valtozik valosan a route ezert a data megmarad benne igy masodik partner layout betoltesenel meg az elso type-val
    // fog initializaodni! ezert itt reseteljuk a route data-t
    delete this.route.snapshot.data.type;
    this.beforeStartNextStepXhr();

    this.firstStepsService.nextStep($event).subscribe(
      nextStep => {
        if (nextStep.step === -4) {
          // skipAll
          const prefix = 'FOOTER.BUTTON2.';

          this.addAnimatedOverlayText();
          this._onStart(firstStepperFinish(`${prefix}LINK`, `${prefix}LABEL`, this.translocoService, this.gtmService, 'SkipAll'));
          return;
        }

        if ($event.layoutName === 'AUTODETECT') {
          let layoutName = '';
          switch (nextStep.type) {
            case FirstStepsComponentTypeEnum.PARTNER:
              layoutName = 'PARTNER';
              break;
            case FirstStepsComponentTypeEnum.HEAD_OFFICE_OR_SITE:
              layoutName = 'HEAD_OFFICE_OR_SITE';
              break;
            case FirstStepsComponentTypeEnum.SOCIAL_REG_FINISH:
              layoutName = 'SOCIAL_REG_FINISH';
              break;
            case FirstStepsComponentTypeEnum.VEHICLE:
              layoutName = 'VEHICLE';
              break;
          }
        }

        this.gtmService.pushTag({
          event: $event.layoutName,
          label: 'CompletedStep',
          category: 'FirstStepper',
        });

        this.handleNextStepMessage(nextStep);
        this.currentStep = nextStep;

        if (this.firstLoading === true) {
          this.firstLoading = false;
        }

        this.afterStartNextStepXhr();
        this.cdr.detectChanges();
      },
      error => {
        if (isNotHandledError(error)) {
          this.messageDialogService.open({
            id: null,
            type: MessageDialogTypeEnum.ERROR,
            title: 'COMMON.ERROR',
            text: 'COMMON.UNKNOWN_ERROR_WITH_CODE',
            textParams: { errorCode: `FSNSTEP1-${error.status}` },
            htmlMode: true,
          });
        }
      }
    );
  }

  private addAnimatedOverlayText() {
    const appOverlayText: HTMLDivElement = (this.document.querySelector('#app-overlay > p') as any) as HTMLDivElement;
    let img = this.document.createElement('img') as HTMLImageElement;
    img.src = 'assets/images/slogan.svg';
    img.style.transition = 'all 450ms ease-in-out';
    img.style.maxWidth = '300px';
    img.style.marginBottom = '2rem';
    this.document.getElementById('app-overlay').insertBefore(img, appOverlayText);

    img = this.document.createElement('img') as HTMLImageElement;
    img.src = 'assets/images/start-anim/bars.svg';
    img.style.transition = 'all 450ms ease-in-out';
    img.style.maxWidth = '300px';
    img.style.marginBottom = '2rem';
    this.document.getElementById('app-overlay').insertBefore(img, appOverlayText);

    this.store.dispatch(new AddAppOverlayAction('', 'rgb(242, 86, 72)', 'background-color 450ms ease-in-out'));
    timer(14 * 350).subscribe(() => this.store.dispatch(new AppOverlayFinishedAndRemoveOverlayAction(450)));
  }

  onStart(routePath: string): void {
    this.firstStepsService.isFinished().subscribe(
      isFinished => {
        if (isFinished) {
          this.currentStep = {
            step: -3,
            message: undefined,
            type: undefined,
            help_text: undefined,
          };
          this._onStart(routePath);
        } else {
          this.onNextStep({ layoutName: 'AUTODETECT' });
        }
      },
      error => {
        if (isNotHandledError(error)) {
          this.messageDialogService.openError({
            id: null,
            title: 'COMMON.ERROR',
            text: 'COMMON.UNKNOWN_ERROR_WITH_CODE',
            textParams: { errorCode: `FSFINISHED1-${error.status}` },
            htmlMode: true,
          });
        }
      }
    );
  }

  private _onStart(routePath: string) {
    this.menuService.setMenuPageToDefault();
    this.store.dispatch(new CompleteIntroAction(INTROJS_TYPES_ENUM.AFTER_FIRST_STEPPER, true));
    this.slideBottom = true;
    this.actions$.pipe(untilDestroyed(this, 'ngOnDestroy'), ofActionSuccessful(FirstStepsRefreshRemoteStateSuccessAction)).subscribe(() => {
      this.window.location.href = `${this.window.location.origin}/#/${routePath}`;
      this.store.dispatch(
        new AppReadyAction(() => {
          const startIntroActionNeeded = this.store.selectSnapshot(IntrojsState.notShowedFilter(INTROJS_TYPES_ENUM.WELCOME));
          if (startIntroActionNeeded) {
            setTimeout(() => {
              this.store.dispatch(new StartIntroAction(INTROJS_TYPES_ENUM.WELCOME));
            }, 300);
          }
        })
      );
    });
    this.store.dispatch(new GetUserStatesAction());
  }

  protected preloadVehicleDetailsJs(loadableService: LoadableService) {
    timer(1000).subscribe(() => loadableService.preload('vehicle'));
  }

  protected stepperConfigDetectLangChanges() {
    // console.info(logScopeName, 'stepperConfigDetectLangChanges');
    this.getStepperConfig()
      .pipe(untilDestroyed(this, 'ngOnDestroy'))
      .subscribe(config => {
        // console.info(logScopeName, 'stepperConfigDetectLangChanges subscribe');
        if (!isNil(this.stepperConfig)) {
          this.stepperConfig.steps.forEach((item, index) => (this.stepperConfig.steps[index].title = config.steps[index].title));
          this.cdr.detectChanges();
        } else {
          this.stepperConfig = config;
        }
      });
  }

  /**
   * @deprecated
   */
  protected initLangChange() {
    // console.info(logScopeName, 'initLangChange', this.initedLangChange);
    if (this.initedLangChange === false) {
      this.initedLangChange = true;
      this.translocoService.langChanges$
        .pipe(
          untilDestroyed(this, 'ngOnDestroy'),
          skip(1),
          switchMap(() => this.firstStepsService.nextStep())
        )
        .subscribe(nextStep => {
          this.currentStep = { ...this.currentStep, help_text: nextStep.help_text };
          this.cdr.detectChanges();
        });
    }
  }

  protected handleNextStepMessage(nextStep): void {
    if (isString(nextStep.message)) {
      this.messageDialogService.open({
        id: null,
        type: MessageDialogTypeEnum.INFORMATION,
        text: nextStep.message,
      });
    }
  }

  protected getStepperConfig(): Observable<StepperConfigModel> {
    // console.info(logScopeName, 'getStepperConfig');
    const config = new Observable<StepperConfigModel>(observer => {
      // console.info(logScopeName, 'getStepperConfig new observable');
      this.firstStepsService.getConfig().subscribe(
        remoteConfig => {
          // console.info(logScopeName, 'getStepperConfig stream send remoteConfig', remoteConfig);
          observer.next(remoteConfig);
          this.cdr.detectChanges();
          // observer.complete();
        },
        error => {
          if (isNotHandledError(error)) {
            this.messageDialogService.openError({
              id: null,
              title: 'COMMON.ERROR',
              text: 'COMMON.UNKNOWN_ERROR_WITH_CODE',
              textParams: { errorCode: `FSCONFIG1-${error.status}` },
              htmlMode: true,
            });
          }
        }
      );
    }).pipe(publishReplay(1));

    // stream beinditasa
    (config as ConnectableObservable<StepperConfigModel>).connect();
    return config;
  }

  ngOnDestroy() {}
}
