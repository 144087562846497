import { Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { PreferencesOpenContentWindowAction, UIOptions } from '../action/preferences-open-content-window.action';
import { PreferencesContentWindowTypeEnum } from '../model/preferences-content-window-type.enum';
import { LoadableService } from '@roadrecord/external-packages/ngx-loadable';
import { ModuleWithRoot } from '@roadrecord/common/common';

export const openWindowAction = (
  store: Store,
  loadableService: LoadableService,
  injector: Injector,
  translocoService: TranslocoService,
  matSnackBar: MatSnackBar,
  preloadWindow: string,
  windowType: PreferencesContentWindowTypeEnum,
  windowTitle: string,
  uiOptions: UIOptions,
  showMaximizeAndMinimizeButtons: boolean,
  videoUrl?: string,
  inputs?: { [key: string]: any },
  snackBarMessage = 'COMMON.OPENING_DIALOG'
) => {
  const matSnackBarRef = matSnackBar.open(translocoService.translate(snackBarMessage));
  return loadableService.preload(preloadWindow).then(ngModuleRef => {
    const elementModuleRef = ngModuleRef.create(injector);
    const rootComponent = (ngModuleRef.moduleType as ModuleWithRoot).rootComponent;
    const factory = elementModuleRef.componentFactoryResolver.resolveComponentFactory(rootComponent);

    store.dispatch(
      new PreferencesOpenContentWindowAction(
        windowType,
        windowTitle,
        factory.componentType,
        uiOptions,
        showMaximizeAndMinimizeButtons,
        elementModuleRef.componentFactoryResolver,
        elementModuleRef,
        videoUrl,
        inputs
      )
    );

    matSnackBarRef.dismiss();
  });
};
