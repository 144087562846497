<form [formGroup]="form" class="mt-2">
  <h3 class="d-inline-flex mb-1"
    ><mat-icon svgIcon="car" class="light-color car-icon"></mat-icon>{{ 'APP_LAYOUT.PERIOD_CONTEXT_DISPLAY.TITLE' | transloco }}</h3
  >
  <div class="form-controls">
    <div
      ><rr-live-auto-complete
        name="vehicle"
        required
        [database]="vehicleService"
        [optionsConfig]="laVehicleOptionsConfig"
        hasPrefix="true"
        [formControlRef]="vehicleControl"
        label="APP_LAYOUT.PERIOD_CONTEXT_DISPLAY.PLATE_NUMBER"
        placeholder="APP_LAYOUT.PERIOD_CONTEXT_DISPLAY.PLATE_NUMBER_FIELD"
        [validationMessages]="laVehicleOptionsConfig.validatorMessages"
        [hasActionsMenu]="false"
        hasNewButton="false"
      >
      </rr-live-auto-complete
    ></div>
    <div>
      <rr-year-month-field
        [parentForm]="form"
        [yearMonthControl]="yearMonthControl"
        labelTranslateKey="APP_LAYOUT.PERIOD_CONTEXT_DISPLAY.YEAR_MONTH"
      >
      </rr-year-month-field>
    </div>
  </div>
</form>
