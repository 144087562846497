import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'rr-sticky-notification-bar',
  templateUrl: './sticky-notification-bar.component.html',
  styleUrls: ['./sticky-notification-bar.component.scss'],
  animations: [
    trigger('hideAnimation', [
      state('show', style({ height: '*' })),
      state('hide', style({ height: 0 })),
      // this should be kept in sync with the animation durations in:
      // - aio/src/styles/2-modules/_notification.scss
      // - aio/src/app/app.component.ts : notificationDismissed()
      transition('show => hide', animate(250)),
    ]),
  ],
})
export class StickyNotificationBarComponent {
  @Input()
  icon: string;
  @Input()
  iconLabel: string;
  @Input()
  text: string;
  @Input()
  textTranslateParams?: Record<string, unknown>;
  @Input()
  closeActionText: string;
  @Input()
  hasCloseButton = false;
  @Input()
  showCountDownTimer = false;
  @Output()
  dismissed = new EventEmitter<any>();

  @HostBinding('@hideAnimation')
  @Input()
  showNotification: 'show' | 'hide' = 'show';

  dismiss(cancel: boolean, $event): void {
    if (($event.target as HTMLAnchorElement).classList.contains('message-link') === true) {
      const anchor = document.createElement('a');
      anchor.target = '_blank';
      anchor.href = ($event.target as HTMLAnchorElement).href;
      anchor.click();
    } else {
      this.dismissed.next(cancel);
    }
  }
}
