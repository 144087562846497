import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApplicationSettingsRoutingModule } from './application-settings-routing.module';
import { ApplicationSettingsComponent } from './application-settings/application-settings.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CompanyTabComponent } from './company-tab/company-tab.component';
import { UserTabComponent } from './user-tab/user-tab.component';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DynamicFormModule } from '@roadrecord/dynamic-form';
import { TranslocoModule } from '@ngneat/transloco';
import { DialogListControlComponent } from './dialog-list-control/dialog-list-control.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogListControlFormlyField } from './dialog-list-control/dialog-list-control-formly-field';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TwoWaySwitchFormControlModule } from '@roadrecord/common/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    ApplicationSettingsComponent,
    CompanyTabComponent,
    UserTabComponent,
    DialogListControlComponent,
    DialogListControlFormlyField,
  ],
  imports: [
    CommonModule,
    ApplicationSettingsRoutingModule,
    MatTabsModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    DynamicFormModule,
    TranslocoModule,
    MatListModule,
    MatIconModule,
    ReactiveFormsModule,
    FormlyMatToggleModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'dialog-list-control',
          component: DialogListControlFormlyField,
        },
      ],
    }),
    MatTooltipModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    TwoWaySwitchFormControlModule,
  ],
})
export class ApplicationSettingsModule {}
