<div *ngIf="run$ | async">
  <div class="recommendation-loading-overlay">
    <div class="spinner-wrapper" @zoomInEnter *ngIf="continueProgress$ | async as continueProgress">
      <mat-progress-spinner color="accent" mode="determinate" [strokeWidth]="4" [diameter]="50" [value]="continueProgress?.progress">
      </mat-progress-spinner>
      <div class="spinner-text">{{ continueProgress?.progress === -1 ? 0 : continueProgress?.progress }}%</div>
    </div>
    <h1 @fadeInEnter>{{ 'RECOMMENDATION.PANEL.IN_PROCESS' | transloco }}</h1>
  </div>
</div>
