import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment';
import { isEmptyString } from '@roadrecord/type-guard';
import { GridColumnModel } from '@roadrecord/grid';
import { UniversalImportConfig } from '@roadrecord/universal-import/common';
import { simpleMergeTranslateObjectsFlatten } from '@roadrecord/transloco-utils';
import { PartnerAddressFormatterReturnType, PartnerListModel } from '@roadrecord/partner/model';

export const importListColumnConfig: (_this: {
  translocoService: TranslocoService;
  importConfig: UniversalImportConfig<any>;
  partnerAddressFormatter: PartnerAddressFormatterReturnType;
}) => GridColumnModel<any>[] = (_this: {
  translocoService: TranslocoService;
  importConfig: UniversalImportConfig<any>;
  partnerAddressFormatter: PartnerAddressFormatterReturnType;
}) => {
  const mergedTranslate = simpleMergeTranslateObjectsFlatten(
    'UNIVERSAL_IMPORT.LIST.COLUMNS',
    `${_this.importConfig.translatePrefix}.LIST.COLUMNS`
  );

  return [
    {
      name: 'date',
      cellHeaderTemplateCallback: () => mergedTranslate['DATE.TITLE'],
      cellContentTemplateCallback: (model: any) => {
        return moment(model.date).format('L');
      },
    },
    { name: 'name', displayNameTranslateKey: mergedTranslate['PARTNER_NAME.TITLE'] },
    {
      name: 'address',
      cellHeaderTemplateCallback: () => mergedTranslate['ADDRESS.TITLE'],
      cellContentTemplateCallback: (model: any) =>
        isEmptyString(model.address)
          ? `${model.postcode}, ${_this.partnerAddressFormatter({ address: model } as PartnerListModel)}`
          : model.address,
    },
    {
      name: 'comment',
      cellHeaderTemplateCallback: () => mergedTranslate['COMMENT.TITLE'],
      cellContentTemplateCallback: (model: any) => _this.translocoService.translate(model.comment, { data: model }),
    },
  ];
};
