import {
  LiveAutocompleDisplayFunction,
  LiveAutoCompleteEditButtonOptionModel,
  LiveAutoCompleteNewButtonOptionModel,
  LiveAutocompleteOptionDisabledBindFunction,
  LiveAutocompleteOptionDisplayBindFunction,
  LiveAutocompleteOptionModelIconFunction,
  LiveAutoCompleteOptionsConfigModel,
  LiveAutocompleteOptionValueBindFunction,
  LiveAutocompletePrefixIconFunction,
} from '@roadrecord/live-auto-complete';
import { isNil, isString } from '@roadrecord/type-guard';
import { FragmentDialogTypeEnum } from '@roadrecord/fragment-dialog';

export const laStateOptionDisabledBindFn: LiveAutocompleteOptionDisabledBindFunction<string> = (model: string, selectedModel: string) =>
  model === selectedModel;
export const laStateOptionDisplayFn: LiveAutocompleteOptionDisplayBindFunction<string> = (model: string) => model;
export const laStateOptionValueBindFn: LiveAutocompleteOptionValueBindFunction<string> = (model: string) => (!isNil(model) ? model : null);
export const laStatePrefixIconFn: LiveAutocompletePrefixIconFunction<string> = (model: string) => {
  if (isString(model) || isNil(model)) {
    return undefined;
  }
  return model;
};

export const laStateOptionModelIconFn: LiveAutocompleteOptionModelIconFunction<string> = () => undefined;
export const laStateDisplayFn: LiveAutocompleDisplayFunction<string> = (model: string) => model;

export const laStateOptionsConfig = new LiveAutoCompleteOptionsConfigModel({
  optionDisabledBindFn: laStateOptionDisabledBindFn,
  optionDisplayFn: laStateOptionDisplayFn,
  optionValueBindFn: laStateOptionValueBindFn,
  optionModelIconFn: laStateOptionModelIconFn,
  displayFn: laStateDisplayFn,
  prefixIconFn: laStatePrefixIconFn,
  placeholder: 'TRIP_REASON.LIVE_AUTO_COMPLETE.PLACEHOLDER',
  newOptions: new LiveAutoCompleteNewButtonOptionModel({
    label: 'TRIP_REASON.LIVE_AUTO_COMPLETE.ADD_NEW_LABEL',
    newFragment: FragmentDialogTypeEnum.NEW_TRIP_REASON,
  }),
  editOptions: new LiveAutoCompleteEditButtonOptionModel({
    editFragment: FragmentDialogTypeEnum.EDIT_TRIP_REASON,
  }),
  validatorMessages: [],
});
