<div
  ngClass.gt-sm="height100"
  fxLayout="column"
  fxLayout.gt-sm="row"
  fxLayoutAlign="space-between stretch"
  fxLayoutGap="1em"
  class="w-100"
  *ngIf="user$ | async as user"
>
  <div class="fix-content-area text-center" @fadeIn>
    <h1>{{ 'FIRST_STEPS.START_WELCOME.HELLO' | transloco: { firstName: firstName.toUpperCase() } }}</h1>
    <p class="w-100 font-weight-bold text mt-3 mb-3" style="line-height: 32px"
      ><span [innerHTML]="'FIRST_STEPS.START_WELCOME.CONTENT' | transloco"></span
    ></p>
    <form [formGroup]="form" data-cy="privacy-form">
      <mat-checkbox formControlName="aszf" class="mb-1">
        <span [innerHTML]="'FIRST_STEPS.START_WELCOME.FORM.ASZF_LABEL' | transloco"></span>
      </mat-checkbox>
      <mat-error>
        <rr-validation-messages
          *ngIf="form['submitted']"
          [messages]="[{ errorKey: 'required', translateKey: 'FIRST_STEPS.START_WELCOME.FORM.ASZF_REQUIRED' }]"
          [errors]="aszfControl?.errors"
        ></rr-validation-messages>
      </mat-error>
    </form>
    <div class="d-flex flex-row align-items-center justify-content-center start-welcome-content" style="gap: 4rem">
      <div
        ><div><img src="assets/images/stepper/start-welcome/stepper_pricing.svg" (click)="goToPriceList()" style="cursor: pointer" /></div
        ><button
          type="button"
          mat-raised-button
          class="mt-3 d-inline-block mx-auto black-button"
          (click)="goToPriceList()"
          data-cy="go-to-price-list"
          ><span>{{ 'FIRST_STEPS.START_WELCOME.PRICE_LIST' | transloco }}</span
          ><mat-icon svgIcon="play" style="margin-top: -2px; margin-left: 4px"></mat-icon></button
      ></div>
      <div
        ><img src="assets/images/stepper/start-welcome/start_setup.svg" (click)="startSteps()" style="cursor: pointer" /><button
          data-cy="start-steps"
          [disabled]="disableButton || (form.submitted && form.invalid)"
          type="button"
          mat-raised-button
          (click)="startSteps()"
          class="mt-3 d-block mx-auto black-button"
          ><span>{{ 'FIRST_STEPS.START_WELCOME.START_BUTTON' | transloco }}</span
          ><mat-icon svgIcon="play" style="margin-top: -2px; margin-left: 4px; color: #ff645f"></mat-icon></button
      ></div>
    </div>
  </div>
</div>
