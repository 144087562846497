import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import {
  deepEqual,
  disableMenuDetectRouteChangeOption,
  MONTH_ACTIVITIES_PAGE_PATH,
  REPORT_PRINT_PATH,
  REPORT_SITE_PATH,
  USER_FORGOT_PASSWORD,
  USER_LOGIN_PATH,
  USER_PASSWORD_RESENT,
  USER_RESET_PASSWORD,
  ViewportService,
} from '@roadrecord/common/common';
import { isFunction, isInstanceof, isNil, isString } from '@roadrecord/type-guard';
import { AuthState } from '@roadrecord/user/common';
import { IS_WEBADMIN, isPresentValue } from '@roadrecord/utils';
import { BehaviorSubject, ConnectableObservable, Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, first, map, multicast, shareReplay, skip, startWith, switchMap, take, tap } from 'rxjs/operators';
import { MENU_CONFIG_TOKEN } from './menu-config.token';
import { MenuItemModel } from './model/menu-item.model';
import { MenuLastRouteStateModel } from './model/menu-last-route-state.model';
import { MenuPageModel } from './model/menu-page.model';
import { MenuStateModel } from './state/model/menu-state.model';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { environment } from '@roadrecord/environment';
import { MenuRestoreLastStateAction } from './state/action/menu-restore-last-state.action';

const logScopeName = '[MenuService]';

@Injectable({ providedIn: 'root' })
export class MenuService implements OnDestroy {
  routerChangeSubscription: Subscription;
  readonly currentVisibleMenuPages$: Observable<MenuPageModel[]>;
  selectedOneMenuPage: string;
  private readonly _menuPages$ = new BehaviorSubject<MenuPageModel[]>([]);
  private readonly _currentState = new BehaviorSubject<MenuLastRouteStateModel>(undefined);
  private state: { [key: string]: { visible: boolean; lastSelected: boolean } };
  private readonly _clickWithoutRoute$ = new Subject<MenuItemModel>();
  private readonly _lastSelectedMenuPageUniqId = new BehaviorSubject<string>(undefined);
  private loginUserSubscription: Subscription;
  private isLoggedInAndMenuChangesSubscription: Subscription;
  private checkMenuPagesChangesSubscription: Subscription;

  constructor(
    @Inject(MENU_CONFIG_TOKEN) readonly menuConfig: MenuPageModel[],
    @Inject(IS_WEBADMIN) readonly isWebadmin: boolean,
    private router: Router,
    injector: Injector,
    private store: Store,
    private http: HttpClient,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    private viewportService: ViewportService,
    private actions: Actions
  ) {
    (this._selectedMenuItem$ as ConnectableObservable<any>).connect();
    this.currentVisibleMenuPages$ = this.watchMenuPages(injector);

    const periodContextStreamPipes = [filter((value: { notInited?: any }) => !isNil(value) && isNil(value.notInited))];
    if (isWebadmin) {
      // ha webadmin akkor elindintjuk a szalat TODO ki kene kotni webadmin eseten
      periodContextStreamPipes.push(startWith(undefined));
    }

    let firstPeriodContext = true;
    /**
     * ha be van lepve es van period context akkor ujra bedobjuk a sima menu config-t es majd leszurjuk kesobb
     */
    store
      .select(states => states.menu)
      .pipe(
        startWith(undefined),
        distinctUntilChanged((_old, _new) => deepEqual(_old, _new)),
        isPresentValue()
      )
      .subscribe(() => this._checkMenuPagesChanges());

    (store.select(this.periodContextStateSelectorsService.context) as any).pipe(...periodContextStreamPipes).subscribe(() => {
      if (firstPeriodContext === true) {
        firstPeriodContext = false;
      }
      this._checkMenuPagesChanges();
    });

    if (router.url === '/') {
      router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          take(1)
        )
        .subscribe((event: NavigationEnd) => this.setSelectedMenuItemRouteFromUrl(event.url));
    } else {
      this._selectedMenuItemRoute$.next(router.url);
    }

    this.detectRouteChange(router);
    this.handleDetectRouteChange(router.url);

    this.loginUserSubscription = store.select(AuthState.isLoggedIn).subscribe(() => {
      const menuPages: MenuPageModel[] = [...this._menuPages$.getValue()];
      menuPages.forEach(menuPage => {
        menuPage.visible = menuPage.uniqId !== 'USER_PROFILE_MENU';
      });
      this._menuPages$.next(menuPages);
    });

    // #RROHU-2106 - ha valtozik a kepernyomeret akkor kikenyszeritjuk a menu ujraszamolasat
    this.viewportService.breakpoint$.subscribe(() => this._checkMenuPagesChanges());

    this.actions.pipe(ofActionSuccessful(MenuRestoreLastStateAction)).subscribe(() => this.restoreLastState());
  }

  private _selectedMenuItemRoute$ = new BehaviorSubject<string>('/');

  get selectedMenuItemRoute$(): Observable<string> {
    return this._selectedMenuItemRoute$.asObservable();
  }

  private _selectedMenuItem$ = this._selectedMenuItemRoute$.pipe(
    skip(1),
    map(v => (isNil(v) ? '' : v)),
    switchMap(_url => this.findMenuItemByUrl(_url)),
    multicast(() => new BehaviorSubject(undefined))
  );

  get selectedMenuItem$(): Observable<MenuItemModel> {
    return this._selectedMenuItem$;
  }

  get menuPages$(): Observable<MenuPageModel[]> {
    return this._menuPages$.asObservable();
  }

  get menuPagesCurrentValue(): MenuPageModel[] {
    return this._menuPages$.getValue();
  }

  get currentStateChange$(): Observable<MenuLastRouteStateModel> {
    return this._currentState.asObservable();
  }

  set currentState(state: MenuLastRouteStateModel) {
    if (!isInstanceof(state, MenuLastRouteStateModel)) {
      // Vedelem, ha nem menu state modelbol szarmazik a state
      console.warn(logScopeName, 'State is not MenuItemModel');
      return;
    }
    if (state === undefined || state.parent === undefined) {
      // Ha nincs state-ben parent vagy MenuNoStateModel-bol szarmazik akkor nincs state valtozas
      console.info(logScopeName, 'Not found parent item or state is no-state');
      return;
    }

    this._lastSelectedMenuPageUniqId.next(state.parent.uniqId);
    const currentState = this._currentState.getValue();
    if (
      // ha nincs jelenlegi state
      currentState === undefined ||
      // // vagy MenuNoStateModel -bol szartmazik
      // currentState instanceof MenuNoStateModel ||
      // vagy nem egyezik meg a tarolt parent route path
      currentState.parent.route !== state.parent.route ||
      // vagy nem egyezik meg a tarolt item route path
      currentState.item.route !== state.item.route ||
      // vagy nem egyezik meg a tarol subroute
      currentState.item.subRoute !== state.item.subRoute
    ) {
      // uj allapot van
      console.info(logScopeName, `Set new state: ${state.parent.route} -> ${state.item.route}`);
      this._currentState.next(state);
    } else {
      // Az uj state megegyezik a jelenlegi state-vel
      console.info(logScopeName, `There is no save status because there is no change: ${state.parent.route} -> ${state.item.route}`);
    }
  }

  get clickWithoutRoute$(): Observable<MenuItemModel> {
    return this._clickWithoutRoute$.asObservable();
  }

  get lastSelectedMenuPageUniqId(): Observable<string> {
    return this._lastSelectedMenuPageUniqId.asObservable();
  }

  clickWithoutRouteNext(item: MenuItemModel): void {
    this._clickWithoutRoute$.next(item);
  }

  visibleOneMenuPage(uniqId: string, customBackButtonLabel?: string, customBackButtonLabelCallback?: () => void): void {
    this.state = {};
    const currentSelectedMenuState = this._currentState.getValue();
    const menuPages: MenuPageModel[] = [...this._menuPages$.getValue()];
    menuPages.forEach(menuPage => {
      this.state[menuPage.uniqId] = {
        visible: menuPage.visible,
        lastSelected: !isNil(currentSelectedMenuState) && currentSelectedMenuState.parent.uniqId === menuPage.uniqId,
      };
      if (menuPage.uniqId === uniqId) {
        menuPage.visible = true;
        if (!isNil(customBackButtonLabel)) {
          menuPage.customBackButtonLabel = customBackButtonLabel;
        }
        if (isFunction(customBackButtonLabelCallback)) {
          menuPage.customBackButtonLabelCallback = customBackButtonLabelCallback;
        }
      } else {
        menuPage.visible = false;
      }
    });
    this._menuPages$.next(menuPages);
    this.selectedOneMenuPage = uniqId;
  }

  restoreLastState(): void {
    const menuPages = [...this._menuPages$.getValue()];
    console.log('restoreLastState', this.state);
    // if (!isNil(this.state)) {
    Object.entries(this.state).forEach(entry => {
      const find = menuPages.find(menuPage => menuPage.uniqId === entry[0]);
      if (!isNil(find)) {
        find.visible = entry[1].visible;
      }
    });
    // }

    this._menuPages$.next(menuPages);

    // if (!isNil(this.state)) {
    const lastSelectedKey = Object.values(this.state).findIndex(state => state.lastSelected);

    let lastSelectedUniqId: string;
    if (lastSelectedKey !== undefined) {
      lastSelectedUniqId = Object.keys(this.state)[lastSelectedKey];
    }
    this._lastSelectedMenuPageUniqId.next(lastSelectedUniqId);
    // }

    this.state = {};
    this._lastSelectedMenuPageUniqId.next(undefined);
    delete this.selectedOneMenuPage;
  }

  ngOnDestroy(): void {
    this.checkMenuPagesChangesSubscription.unsubscribe();
    this.routerChangeSubscription.unsubscribe();
    this.loginUserSubscription.unsubscribe();
    this.isLoggedInAndMenuChangesSubscription.unsubscribe();
  }

  handleDetectRouteChange(url: string): any {
    let findItem: MenuItemModel;
    // TODO config alapu exclude lista
    if (
      [
        '/',
        `/${USER_LOGIN_PATH}`,
        `/${USER_RESET_PASSWORD}`,
        `/${USER_FORGOT_PASSWORD}`,
        `/${USER_PASSWORD_RESENT}`,
        '/register',
        '/registration-successful',
        '/resendactivation',
        '/resend-activation-successful',
        `/${MONTH_ACTIVITIES_PAGE_PATH}/${REPORT_SITE_PATH}`,
        `/${MONTH_ACTIVITIES_PAGE_PATH}/${REPORT_PRINT_PATH}`,
        // `/${USER_ROUTE_PATH}/${PRICE_SITE_PATH}`,
      ].indexOf(url) > -1 /*||
      this.store.selectSnapshot(states => states.firstSteps.finished) === true*/
    ) {
      return;
    }
    const splittedUrl = url.split('?')[0].split('/');
    const urlFragment = splittedUrl[2];
    // user menu page route kezelese
    const urlFragmentFull = [...splittedUrl].splice(1, splittedUrl.length - 1).join('/');
    // item osszetett url kezelese
    const urlFragmentWithouFirstLevel = urlFragmentFull.length > 3 ? splittedUrl.splice(2, splittedUrl.length - 1).join('/') : undefined;

    let findMenuPage: MenuPageModel;
    // timer(0).subscribe(() => {
    this.currentVisibleMenuPages$.pipe(first()).subscribe(visibleMenuPages => {
      findMenuPage = visibleMenuPages.find(menuPage => {
        let found = !isNil(urlFragmentWithouFirstLevel)
          ? menuPage.itemsLastValue.find(item => item.route === urlFragmentWithouFirstLevel)
          : undefined;
        if (found === undefined) {
          found = menuPage.itemsLastValue.find(item => item.route === urlFragment || item.route === urlFragmentFull);
        }
        if (!isNil(found)) {
          findItem = found;
          return true;
        }
        return false;
      });
      // });
      if (!isNil(findItem) && !isNil(findMenuPage)) {
        console.info(logScopeName, `Founded menu config items => MenuPage: ${findMenuPage.route}/${findItem.route}`);
        const _splittedUrl = url.split('/');
        _splittedUrl.shift();
        const joinedItemUrl = _splittedUrl.join('/');
        if (isString(findItem.route) && findItem.route.length > 0 && joinedItemUrl !== findItem.route) {
          let subRoute = joinedItemUrl.split(findItem.route)[1].slice(1);
          if (isNil(findItem.saveSubRoutes) || findItem.saveSubRoutes.indexOf(subRoute) === -1) {
            subRoute = undefined;
          }
          findItem = new MenuItemModel({ ...findItem, subRoute });
        } else {
          findItem = new MenuItemModel({ ...findItem, subRoute: undefined });
        }
        // if (findItem.saveSelectState === true) {
        return (this.currentState = new MenuLastRouteStateModel({
          parent: findMenuPage,
          item: findItem,
          subRoute: findItem.subRoute,
        }));
        // }
      } else {
        // TODO kivetel listat kesziteni
        console.warn(logScopeName, `Not found item and page: ${url}`);
      }
    });
  }

  refreshFromUserState(keyPath: string): Observable<MenuStateModel> {
    return this.http.get<MenuStateModel>(`${environment.apiUrl}state/${keyPath}`);
  }

  hasMenuItemByUrl(_url?: string): Observable<boolean> {
    if (!isString(_url)) {
      _url = this.router.url;
    }
    return this.findMenuItemByUrl(_url).pipe(
      map(foundMenuItem => foundMenuItem !== undefined && foundMenuItem.hide === false && foundMenuItem.disabled === false)
    );
  }

  findMenuItemByUrl(_url: string): Observable<MenuItemModel> {
    return this.currentVisibleMenuPages$.pipe(
      filter(menuPages => Array.isArray(menuPages)),
      take(1),
      map(menuPages => {
        let foundMenuItem: MenuItemModel;
        menuPages.find(menuPage => {
          foundMenuItem = menuPage.itemsLastValue.find(menuItem => {
            const menuItemUrl = `/${menuPage.route}/${menuItem.route}`;
            return _url.startsWith(menuItemUrl) || menuItemUrl.startsWith(_url);
          });
          return foundMenuItem !== undefined;
        });
        return foundMenuItem;
      })
    );
  }

  private _checkMenuPagesChanges(): void {
    // if (this.checkMenuPageTimerSubscription !== undefined && !this.checkMenuPageTimerSubscription.closed) {
    //   this.checkMenuPageTimerSubscription.unsubscribe();
    // }
    // this.checkMenuPageTimerSubscription = timer(0).subscribe(() => this.checkMenuPagesChanges(true, this.menuConfig, this.router));
    this.checkMenuPagesChanges(true, this.menuConfig, this.router);
  }

  private checkMenuPagesChanges(value: boolean, menuConfig: MenuPageModel[], router: Router): void {
    if (value) {
      this._menuPages$.next(menuConfig);
      this.handleDetectRouteChange(router.url);
    } else {
      if (this._menuPages$.getValue().length > 0) {
        this._menuPages$.next([]);
      }
    }
  }

  private detectRouteChange(router: Router): void {
    this.routerChangeSubscription = router.events
      .pipe(
        filter(event => event instanceof NavigationEnd && this.store.selectSnapshot(states => states.firstSteps.finished) === true),
        switchMap(event =>
          this.currentVisibleMenuPages$.pipe(
            map(currentVisibleMenuPages => (currentVisibleMenuPages.length > 0 ? event : undefined)),
            take(1)
          )
        ),
        filter(event => event !== undefined),
        filter(() => this.store.selectSnapshot<boolean>(AuthState.isLoggedIn)),
        filter(() => !disableMenuDetectRouteChangeOption.disable)
      )
      .subscribe((event: NavigationEnd) => {
        console.info(logScopeName, 'Detect route change');
        const url = event.urlAfterRedirects;
        this.setSelectedMenuItemRouteFromUrl(url);
        this.handleDetectRouteChange(url);
      });
  }

  private setSelectedMenuItemRouteFromUrl(url: string): void {
    if (this._selectedMenuItemRoute$.getValue() !== url) {
      this._selectedMenuItemRoute$.next(url);
    }
  }

  private watchMenuPages(injector: Injector): Observable<MenuPageModel[]> {
    return this._menuPages$.pipe(
      switchMap(menuPages =>
        this._selectedMenuItemRoute$.pipe(
          filter(value => value !== undefined),
          map(() => menuPages),
          take(1)
        )
      ),
      switchMap((_menu: MenuPageModel[]) =>
        of<MenuPageModel[]>(
          _menu.filter(menuPage => {
            if (menuPage.visible === true) {
              if (!isNil(menuPage.contextCallback)) {
                const opts = menuPage.contextCallback();
                const cbArguments = [];
                if (!isNil(opts.deps)) {
                  // tslint:disable-next-line:deprecation
                  opts.deps.forEach(dep => cbArguments.push(injector.get(dep)));
                }

                return opts.callback(...cbArguments);
              }
              return true;
            } else if (menuPage.visible === false && menuPage.hidden === true) {
              return true;
            }
            return false;
          })
        )
      ),
      switchMap(menuPages =>
        this._selectedMenuItemRoute$.pipe(
          map(_selectedMenuItemRoute => _selectedMenuItemRoute.split('?')[0].split('#')[0]),
          map(_selectedMenuItemRoute =>
            menuPages.map(menuPage => {
              const selectedMenuPageRoute = menuPage.route;
              const selectedMenuItemRoute = _selectedMenuItemRoute.split('/')[2];
              const foundOldActiveMenuItemIndex = menuPage.itemsLastValue.findIndex(menuItem => menuItem.active);
              let maybeIndex: any;
              let foundNewActiveMenuItemIndex = menuPage.itemsLastValue.findIndex((menuItem, index) => {
                if (!isNil(menuItem.outsideLink)) {
                  return false;
                }
                const route = menuItem.route;
                const fullRoute = `${selectedMenuPageRoute.length === 0 ? '' : `/${selectedMenuPageRoute}`}/${route}`;
                if (fullRoute === _selectedMenuItemRoute) {
                  return true;
                }
                const splittedRoute = route.split('/');
                if (splittedRoute.length > 1) {
                  if (
                    route ===
                    _selectedMenuItemRoute
                      .split('/')
                      .splice(-1 * splittedRoute.length)
                      .join('/')
                  ) {
                    maybeIndex = index;
                  } else if (fullRoute.startsWith(_selectedMenuItemRoute) || _selectedMenuItemRoute.startsWith(fullRoute)) {
                    maybeIndex = index;
                  }
                  return false;
                } else {
                  if (route === selectedMenuItemRoute) {
                    maybeIndex = index;
                  } else if (fullRoute.startsWith(_selectedMenuItemRoute) || _selectedMenuItemRoute.startsWith(fullRoute)) {
                    maybeIndex = index;
                  }
                  return false;
                }
              });

              if (foundNewActiveMenuItemIndex === -1 && maybeIndex !== undefined) {
                foundNewActiveMenuItemIndex = maybeIndex;
              }

              const newItems = [...menuPage.itemsLastValue];
              if (foundOldActiveMenuItemIndex > -1) {
                newItems[foundOldActiveMenuItemIndex].active = false;
              }
              if (foundNewActiveMenuItemIndex > -1) {
                newItems[foundNewActiveMenuItemIndex].active = true;
                if (
                  menuPage.visible === false &&
                  menuPage.hidden === true &&
                  this.selectedOneMenuPage !== menuPage.uniqId &&
                  _selectedMenuItemRoute !== '/'
                ) {
                  this.visibleOneMenuPage(menuPage.uniqId);
                }
              }
              if (foundOldActiveMenuItemIndex > -1 || foundNewActiveMenuItemIndex > -1) {
                menuPage.items = newItems;
              }
              return menuPage;
            })
          )
        )
      ),
      // map(menuPages => menuPages.filter(menuPage => menuPage.visible === true && menuPage.hidden === false)),
      shareReplay(1)
    );
  }

  setMenuPageToDefault(): void {
    const menuPages: MenuPageModel[] = [...this._menuPages$.getValue()];
    menuPages.forEach(menuPage => {
      menuPage.visible = menuPage.uniqId !== 'USER_PROFILE_MENU';
    });
    // this._menuPages$.next(menuPages);
  }
}
