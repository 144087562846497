import { FirstStepsComponentTypeEnum } from './first-steps-component-type.enum';

export interface FirstStepsHelpModel {
  title: string;
  content: string;
}

export class FirstStepsNextStepModel {
  step: number;
  type: FirstStepsComponentTypeEnum;
  help_text: FirstStepsHelpModel;
  message: string;
}
