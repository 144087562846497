import { commonHttpStreamErrorHandler, openWindowOrMessageDialog } from '@roadrecord/utils';
import { HttpClient } from '@angular/common/http';
import { environment } from '@roadrecord/environment';
import { TranslocoService } from '@ngneat/transloco';
import { SubscriptionInfoEnum } from './model/subscription-info-enum';

const SubscriptionInfoEnumValues = Object.values(SubscriptionInfoEnum);

export function openOrderInANewTab(
  http: HttpClient,
  urlFragment: string,
  translocoService: TranslocoService,
  expiredSubscription: SubscriptionInfoEnum
) {
  http.get<{ link: string }>(urlFragment).subscribe(
    result =>
      openWindowOrMessageDialog({
        linkName: translocoService.translate(`APP_LAYOUT.NAV_TOOLBAR.ITEM.${SubscriptionInfoEnumValues[expiredSubscription]}`),
        linkUrl: result.link,
        windowTarget: '_blank',
      }),
    commonHttpStreamErrorHandler()
  );
}

export function goToOrderInANewTab(expiredSubscription: SubscriptionInfoEnum, http: HttpClient, translocoService: TranslocoService) {
  let urlFragment: string;

  switch (expiredSubscription) {
    case SubscriptionInfoEnum.NO_SUBSCRIPTION:
    case SubscriptionInfoEnum.VALID_SUBSCRIPTION:
    case SubscriptionInfoEnum.EXPIRING_SUBSCRIPTION:
    case SubscriptionInfoEnum.NOTIFY_SUBSCRIPTION:
      urlFragment = 'company/redirect/go-to-ordering/';
      break;
    case SubscriptionInfoEnum.EXPIRED_SUBSCRIPTION:
      urlFragment = 'company/redirect/go-to-renew-subscription/';
      break;
  }
  openOrderInANewTab(http, `${environment.apiUrl}${urlFragment}`, translocoService, expiredSubscription);
}
