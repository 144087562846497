import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { DetailsModule as PartnerDetailsModule } from '@roadrecord/partner/common';
import { PartnerWrapperStepComponent } from './partner-wrapper-step.component';
import { SanitizePipeModule, TextPlaceholderHtmlTagReplacerModule } from '@roadrecord/common/common';
import { FirstStepperHelpBoxModule } from '../../first-stepper-help-box/first-stepper-help-box.module';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
    PartnerDetailsModule,
    SanitizePipeModule,
    TextPlaceholderHtmlTagReplacerModule,
    FirstStepperHelpBoxModule,
  ],
  declarations: [PartnerWrapperStepComponent],
  exports: [PartnerWrapperStepComponent],
})
export class PartnerWrapperStepModule {}
