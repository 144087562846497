import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngxs/store';
import { UniversalImportService } from '@roadrecord/universal-import/common';
import { PartnerImportModel } from './model/partner-import.model';

export const partnerImportEntityDefaultOrder = 'status_order';

@Injectable()
export class PartnerUniversalImportService extends UniversalImportService<PartnerImportModel> {
  constructor(http: HttpClient, store: Store, injector: Injector) {
    super(http, store, injector, 'partner/import', partnerImportEntityDefaultOrder);
  }
}
