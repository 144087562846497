import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { FINALIZATION_OF_ROUTE_ROUTE_PATH, MONTH_ACTIVITIES_PAGE_PATH } from '@roadrecord/common/common';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { MenuService } from './menu.service';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';

/*
export const baseNotAccessUrl = `${MONTH_ACTIVITIES_PAGE_PATH}/${SPECIAL_DAYS_ROUTE_PATH}`;
*/
export const baseNotAccessUrl = `${MONTH_ACTIVITIES_PAGE_PATH}/${FINALIZATION_OF_ROUTE_ROUTE_PATH}`;

/**
 * Megvizsgaljuk hogy a betoltendo route szerepel-e a lathato menu-ben, ha nem akkor elutasitjuk a routing-t
 */
@Injectable({
  providedIn: 'root',
})
export class HasAccesMenuItemGuard implements CanActivate, CanActivateChild {
  constructor(
    private menuService: MenuService,
    private router: Router,
    private store: Store,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>
  ) {}

  private check(state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(this.periodContextStateSelectorsService.hasContext).pipe(
      filter(hasContext => hasContext),
      take(1),
      switchMap(() => this.menuService.hasMenuItemByUrl(state.url).pipe(map(check => (check ? check : this.router.parseUrl('/')))))
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(state);
  }
}
