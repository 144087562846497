import { StartData } from '../model/start-data';
import { EndData } from '../model/end-data';
import { DataFormSheetComponent } from './data-form-sheet/data-form-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

export function openMileageEditFormBottomSheet(matBottomSheet: MatBottomSheet, layoutType: 'START' | 'END', model: StartData | EndData) {
  return matBottomSheet
    .open(DataFormSheetComponent, {
      closeOnNavigation: false,
      disableClose: true,
      autoFocus: true,
      restoreFocus: true,
      data: { layoutType: layoutType, model: model } as {
        layoutType: 'START' | 'END';
        model: StartData | EndData;
      },
    })
    .afterDismissed();
}
