import { InjectionToken } from '@angular/core';
import { isNil } from '@roadrecord/type-guard';

export const VIEW_MODEL_PLUGIN_OPTIONS_TOKEN = new InjectionToken('VIEW_MODEL_PLUGIN_OPTIONS_TOKEN');

export enum ViewModelOnChangesCallbackStrategy {
  BEFORE_RUN_PLUGIN,
  AFTER_RUN_PLUGIN,
}

export class ViewModelPluginOptionsModel {
  viewModelOnChangesCallbackStrategy: ViewModelOnChangesCallbackStrategy = ViewModelOnChangesCallbackStrategy.AFTER_RUN_PLUGIN;

  constructor(data?: ViewModelPluginOptionsModel) {
    if (!isNil(data)) {
      Object.assign(this, data);
    }
  }
}
