import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from '@roadrecord/type-guard';

@Pipe({
  name: 'isPresent',
})
export class IsPresentPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return !isNil(value);
  }
}
