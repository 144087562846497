<div
  *ngIf="loading === true"
  @enterAnimation
  (@enterAnimation.done)="doneLoadingFadeAnimation()"
  fxLayout="row"
  fxLayoutAlign="center center"
  fxFlex
>
  <div class="spinner-wrapper" fxLayout="column" fxLayoutAlign="center center" fxFlex>
    <h4 fxFlex>
      {{ text }}
      <ng-template [ngIf]="type === 'bar'" [ngIfElse]="spinnerType">
        <mat-progress-bar color="warn" mode="indeterminate"></mat-progress-bar>
      </ng-template>
      <ng-template #spinnerType> <mat-progress-spinner color="warn" mode="indeterminate"> </mat-progress-spinner> </ng-template>
    </h4>
  </div>
</div>
<div
  *ngIf="loading === false && isDoneLoadingFadeAnimation >= 0"
  fxLayout="row"
  fxLayoutAlign="center center"
  fxFlex
  [@secondEnterAnimation]="'start2'"
  (@secondEnterAnimation.done)="doneLoadingFadeAnimation()"
>
  <ng-content></ng-content>
</div>
