import { AbstractPeriodContextModel } from '../model/abstract-period-context.model';
import { AbstractPeriodContextStateModel } from '../model/abstract-period-context-state.model';
import { isNil } from '@roadrecord/type-guard';
import { VehicleScopeServerEnum } from '@roadrecord/vehicle/model/common';
import { TripReportProcessTypeEnum } from '@roadrecord/trip-reason/model';
import moment from 'moment';

// ideiglenesen ide is bemasoltam, mert mashoyg nem tudtam feloldani a circular dependency-t

export abstract class PeriodContextStateSelectorsService<
  MODEL extends AbstractPeriodContextModel<any>,
  STATEMODEL extends AbstractPeriodContextStateModel<MODEL>
> {
  runVehiclePull(state: STATEMODEL) {
    return state.runVehiclePull;
  }

  loading(state: STATEMODEL): boolean {
    return state.loading === undefined ? true : state.loading;
  }

  state(state: STATEMODEL): STATEMODEL {
    return state;
  }

  context(state: STATEMODEL): MODEL {
    return state.model;
  }

  hasContext(state: STATEMODEL): boolean {
    return !isNil(state.model) && isNil(state.model.notInited);
  }

  viewDate(state: STATEMODEL): Date {
    return state !== undefined && state.notInited === undefined
      ? moment(`${state.model.year} ${state.model.month} 1`, 'YYYY-MM-DD').toDate()
      : moment().toDate();
  }

  reportType(state: STATEMODEL): TripReportProcessTypeEnum {
    if (state.model.vehicle.type === VehicleScopeServerEnum.COMPANY) {
      return TripReportProcessTypeEnum.COMPANY;
    } else if (state.model.vehicle.type === VehicleScopeServerEnum.PRIVATE) {
      if (state.model.vehicle.is_self_employed === true) {
        return TripReportProcessTypeEnum.SELF_EMPLOYED;
      } else {
        return TripReportProcessTypeEnum.PRIVATE;
      }
    }
  }

  isCompany(state: STATEMODEL): boolean {
    return state !== undefined && state.model.vehicle !== undefined && state.model.vehicle.type === VehicleScopeServerEnum.COMPANY;
  }

  isPrivate(state: STATEMODEL): boolean {
    return state !== undefined && state.model.vehicle !== undefined && state.model.vehicle.type === VehicleScopeServerEnum.PRIVATE;
  }

  isSelfEmployed(state: STATEMODEL): boolean {
    return state !== undefined && state.model.vehicle !== undefined && state.model.vehicle.is_self_employed;
  }
}
