import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { checkMethod } from './check-method.type';
import { PARAM_CHECK_METHOD_TOKEN, PARAM_NAME_TOKEN } from './injection.token';

@Injectable()
export class ParamTypeCheckGuard implements CanActivate {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PARAM_CHECK_METHOD_TOKEN) private paramCheckMethod: checkMethod,
    @Inject(PARAM_NAME_TOKEN) private paramName
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.paramCheckMethod(next, state, this.route, this.router, this.paramName);
  }
}
