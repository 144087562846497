import { getValue, HashMap, isDefined, setValue, Translation, TRANSLOCO_TRANSPILER, TranslocoTranspiler } from '@ngneat/transloco';
import { isObject, isString } from '@roadrecord/type-guard';
import { Injectable } from '@angular/core';

/**
 * from: https://stackoverflow.com/a/33445095
 * @deprecated
 *
 * @param obj
 * @param propertyPath
 */
function objectHasPropertyPath(obj, propertyPath) {
  if (!propertyPath) return false;

  const properties = propertyPath.split('.');

  for (let i = 0; i < properties.length; i++) {
    const prop = properties[i];

    if (!obj || !obj.hasOwnProperty(prop)) {
      return false;
    } else {
      obj = obj[prop];
    }
  }

  return true;
}

// @deprecated
@Injectable()
export class DefaultTranspiler implements TranslocoTranspiler {
  transpile(value: any, params: HashMap<any> = {}, translation: Translation): any {
    if (isString(value)) {
      return value.replace(/{{(.*?)}}/g, function (_, match) {
        match = match.trim();
        if (isDefined(params[match])) {
          return params[match];
        } else if (objectHasPropertyPath(params, match)) {
          return getValue(params, match);
        }

        const fromTranslation = getValue(translation, match);
        return isDefined(fromTranslation) ? fromTranslation : '';
      });
    }

    if (isObject(value) && params) {
      /**
       *
       * @example
       *
       * const en = {
       *  a: {
       *    b: {
       *      c: "Hello {{ value }}"
       *    }
       *  }
       * }
       *
       * const params =  {
       *  "b.c": { value: "Transloco "}
       * }
       *
       * service.selectTranslate('a', params);
       *
       * // the first param will be the result of `en.a`.
       * // the second param will be `params`.
       * parser.transpile(value, params, {});
       *
       *
       */
      Object.keys(params).forEach(p => {
        // get the value of "b.c" inside "a" => "Hello {{ value }}"
        const v = getValue(value, p);
        // get the params of "b.c" => { value: "Transloco" }
        const getParams = getValue(params, p);

        // transpile the value => "Hello Transloco"
        const transpiled = this.transpile(v, getParams, translation);

        // set "b.c" to `transpiled`
        value = setValue(value, p, transpiled);
      });
    }

    return value;
  }
}

// @deprecated
export const customTranspiler = {
  provide: TRANSLOCO_TRANSPILER,
  useClass: DefaultTranspiler,
};
