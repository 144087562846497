<div class="wrapper d-flex flex-row" *transloco="let t; read: 'PARTNER.DETAILS'">
  <div class="d-flex flex-column w-100" [ngStyle]="{ 'max-width': '100%' }">
    <mat-card class="search-card">
      <mat-card-header>
        <mat-card-title
          >{{ t('TITLE.SEARCH_ON_MAP.' + (partnerType === PartnerTypeEnum.CHARGING_STATION ? 'CHARGING_STATION' : 'COMMON')) }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="1em">
        <div class="search-field">
          <mat-form-field data-cy="partner-search-mat-form-field">
            <mat-label>{{ searchInputTranslatedPlaceholder | transloco }}</mat-label>
            <input
              name="search-on-map"
              rrFocusInput="true"
              [rrFocusDelay]="300"
              [formControl]="searchControl"
              [errorStateMatcher]="searchFieldErrorMatcher"
              matInput
              [placeholder]="searchInputTranslatedPlaceholder | transloco"
              (blur)="onBlurSearchField()"
              (focus)="onFocusSearchField()"
              (keyup.enter)="onSearch()"
              data-with-browser-autocomplete
              data-cy="partner-search-field"
            />
            <mat-hint>{{ t('SEARCH_ON_MAP.SEARCH_HINT') }}</mat-hint>
            <mat-error *ngIf="searchControl?.errors">
              <rr-validation-messages [errors]="searchControl?.errors"></rr-validation-messages>
            </mat-error>
          </mat-form-field>
          <button
            type="button"
            mat-mini-fab
            color="primary"
            (click)="onSearch()"
            [disabled]="loading"
            matTooltipPosition="left"
            [matTooltip]="t('SEARCH_ON_MAP.LABEL.SEARCH_START')"
          >
            <mat-icon svgIcon="magnify"></mat-icon>
          </button>
        </div>
      </mat-card-content>
      <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loading"></mat-progress-bar>
      <mat-card-actions>
        <button
          type="button"
          *ngIf="hasBackButton"
          (click)="onCancel()"
          mat-icon-button
          matTooltipPosition="above"
          [matTooltip]="
            (!!id || hasModel === true ? 'PARTNER.DETAILS.SEARCH_ON_MAP.TOOLTIP.BREAKOFF' : 'COMMON.ACTION.TOOLTIP.CANCEL') | transloco
          "
        >
          <mat-icon svgIcon="arrow-left"></mat-icon>
        </button>
        <ng-container *ngIf="coordinateLoading">
          <span class="flex-spacer"></span>
          <div
            ><i>{{ t('SEARCH_ON_MAP.COORDINATE_DATA_DOWNLOAD') }}</i></div
          >
        </ng-container>
      </mat-card-actions>
    </mat-card>
    <rr-map-box
      enableOverlayFullScreenMode="true"
      [loadCurrentBrowserLocation]="isNew"
      [markers]="currentMarkers"
      [visibleMarkerTooltip]="false"
      [mapboxMode]="mapboxMode"
      [enableFullScreen]="false"
      (newPoi)="onDragendMap($event)"
    ></rr-map-box>
  </div>
  <mat-card class="auto-content-height result-card w-100" @fadeIn2XFastEnter>
    <mat-card-content>
      <div class="d-flex flex-column h-100">
        <div>
          <div class="d-flex flex-row filter-panel align-items-center">
            <div class="d-flex justify-content-sm-between w-100">
              <b class="mr-2 text-nowrap">{{ t('SEARCH_ON_MAP.FILTER_PANEL.LEFT.TITLE') }}</b>
              <div class="d-flex align-items-center" style="gap: 5px">
                <mat-icon color="accent" svgIcon="information-outline" [matTooltip]="t('SEARCH_ON_MAP.FILTER_PANEL.HELP')"></mat-icon>
                <b class="mr-2">{{ t('SEARCH_ON_MAP.FILTER_PANEL.TITLE') }}</b>
              </div>
            </div>
            <form [formGroup]="filterForm" class="d-flex flex-nowrap w-auto">
              <mat-checkbox class="mr-3" [formControl]="filterFormCompaniesControl">{{
                t('TAB.COMPANIES', { value: ((results ? results : []) | filterLocations: { companies: true }).length })
              }}</mat-checkbox>
              <mat-checkbox *ngIf="null | appTypeIsHu" class="mr-3" [formControl]="filterFormHeadquartesControl">{{
                t('TAB.COMPANIES_HEADQUARTES', { value: ((results ? results : []) | filterLocations: { headquartes: true }).length })
              }}</mat-checkbox>
              <mat-checkbox [formControl]="filterFormAddressesControl">{{
                t('TAB.ADDRESSES', { value: ((results ? results : []) | filterLocations: { addresses: true }).length })
              }}</mat-checkbox>
            </form>
          </div>
          <!-- divider miatt kell a div wrapper -->
          <mat-divider></mat-divider>
        </div>
        <div class="search-result" [ngClass]="firstSearched ? 'has-not-found-image' : ''">
          <img
            class="not-found-image"
            *ngIf="firstSearched"
            [src]="'/assets/images/grid-background/' + backgroundImage + '.svg' | sanitizeResourceUrl"
          />
          <ng-template [ngIf]="remoteError" [ngIfElse]="resultList">
            <h2 class="remote-error">{{ remoteError }}</h2>
          </ng-template>
          <ng-template #resultList>
            <ng-container *ngIf="results !== undefined && results.length === 0; else resultListTpl">
              <h2 class="not-found-result-by-criteria">{{ t('SEARCH_ON_MAP.NOT_MATCH_SEARCH') }}</h2>
            </ng-container>
            <ng-template #resultListTpl>
              <ng-container *ngIf="results !== undefined && results.length > 0">
                <rr-search-on-map-list
                  invalidText="PARTNER.DETAILS.SEARCH_ON_MAP.TOOLTIP.SELECTED_HAS_NOT_VALID_COORDINATES"
                  [selectedLocation]="selectedLocation"
                  [valid]="valid"
                  [results]="results | filterLocations: filterForm?.value"
                  (clickItem)="onSelectPartnerDblClick($event)"
                  (clickShowOnMap)="onSelectPartner($event)"
                ></rr-search-on-map-list>
              </ng-container>
            </ng-template>
          </ng-template>
        </div>
      </div>
    </mat-card-content>
    <mat-progress-bar mode="indeterminate" color="primary" *ngIf="coordinateLoading"></mat-progress-bar>
  </mat-card>
</div>
