import { TranslocoService } from '@ngneat/transloco';
import { GridColumnModel } from '@roadrecord/grid';
import { CompanyMemberListModel } from '../model/company-member-list.model';

export const listColumnConfig: (_this: { translocoService: TranslocoService }) => GridColumnModel<CompanyMemberListModel>[] = (_this: {
  translocoService: TranslocoService;
}) => {
  return [
    {
      name: 'first_name',
      displayNameTranslateKey: 'COMPANY_MEMBER.LIST.COLUMN.FULL_NAME.TITLE',
      cellContentTemplateCallback: user => _this.translocoService.translate('COMPANY_MEMBER.LIST.COLUMN.FULL_NAME.VALUE', { ...user }),
    },
    { name: 'email', displayNameTranslateKey: 'COMPANY_MEMBER.LIST.COLUMN.EMAIL.TITLE' },
    {
      name: 'role',
      displayNameTranslateKey: 'COMPANY_MEMBER.LIST.COLUMN.ROLE.TITLE',
      cellContentTemplateCallback: (model: CompanyMemberListModel) =>
        _this.translocoService.translate(`COMPANY_MEMBER.LIST.COLUMN.ROLE.CONTENTS._${model.role}`),
    },
    {
      name: 'status',
      displayNameTranslateKey: 'COMPANY_MEMBER.LIST.COLUMN.STATUS.TITLE',
      cellContentTemplateCallback: (model: CompanyMemberListModel) =>
        _this.translocoService.translate(`COMPANY_MEMBER.LIST.COLUMN.STATUS.CONTENTS._${model.status}`),
    },
  ];
};
