export function grid3_4migrateFn(state: any) {
  return { container: {}, previousContainer: {}, version: 4 };
}

export const stateMigrations = [
  {
    version: 3,
    key: 'grids',
    versionKey: 'version',
    migrate: grid3_4migrateFn,
  },
];
