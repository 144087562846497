import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';
import { isFunction, isNil } from '@roadrecord/type-guard';
import { PresenterStateController, ViewRemoteFieldErrorPlugin } from '@roadrecord/utils';
import { InjectionToken, Injector } from '@angular/core';

export const DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION = new InjectionToken('DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION');

export class ViewRemoteFieldErrorFormlyExtension implements FormlyExtension {
  private viewRemoteFieldErrorPlugin: ViewRemoteFieldErrorPlugin<any, any>;
  private injector: Injector;

  onPopulate(field: FormlyFieldConfig & { _rr_details_injector: Injector }): void {
    if (
      !isNil(field.fieldGroup) &&
      !isNil(field['_rr_details_injector'])
      /* mivel di-val nincs jol megcsinalva formly reszerol ezert mindig ki kell vennunk ha talalunk uj injectort */
    ) {
      /**
       * Eltaroljuk a data-form injectort
       */
      this.injector = field['_rr_details_injector'];
      if (this.injector.get(DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION, false) === true) {
        // disable extension
        return;
      }
      this.viewRemoteFieldErrorPlugin = this.injector.get(PresenterStateController).get(ViewRemoteFieldErrorPlugin);
      return;
    }
    if (isNil(field.key) || !isNil(field['_initedViewRemoteFieldErrorPlugin'])) {
      /**
       * mar volt inicializalva a plugin ezen a mezon
       */
      return;
    }

    if (this.injector.get(DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION, false) === true) {
      // disable extension
      return;
    }

    if (isNil(this.viewRemoteFieldErrorPlugin)) {
      console.error('Not found view remote field error plugin');
      return;
    }

    field['_initedViewRemoteFieldErrorPlugin'] = true;
    const validator = this.viewRemoteFieldErrorPlugin.customRemoteValidatorMessage(field.key as any);
    if (/* olyan esetre ahol hasznalva van a ViewRemoteFieldErrorPlugin */ isFunction(validator)) {
      if (Array.isArray(field['_validators'])) {
        field['_validators'].push(validator);
      } else {
        field['_validators'] = [validator];
      }
    }
  }
}

export function registerViewRemoteFieldErrorPlugin() {
  return {
    extensions: [
      {
        name: 'viewRemoteFieldErrorPlugin',
        extension: new ViewRemoteFieldErrorFormlyExtension(),
      },
    ],
  };
}
