<mat-progress-bar *ngIf="hasError === false && (run || loading)" mode="indeterminate" color="accent"></mat-progress-bar>
<div class="header">
  <div fxLayout="column" fxLayoutAlign="space-between center">
    <div class="image-wrapper w-100 d-flex justify-content-center" *ngIf="currentStepCounter > -1">
      <img
        @fadeInEnter
        [src]="
          'assets/images/report-print/' + (run || currentStepCounter === 0 ? 'ember1.png' : countError > 0 ? 'ember2.png' : 'ember3.png')
        "
        alt="report-print"
      />
    </div>
    <div class="status-display" *ngIf="hasError === false && currentStepCounter > -1 && counter > -1">
      <span
        [@fadeInEnter]
        *ngIf="currentStepCounter !== counter; else summaTpl"
        [innerHTML]="
          'REPORT_PRINT.HEADER.CHECK_COUNTER_RUN'
            | transloco
              : {
                  counter: counter,
                  countError: countError
                }
        "
      >
      </span>
      <ng-template #summaTpl>
        <span
          [@fadeInEnter]
          [innerHTML]="
            'REPORT_PRINT.HEADER.CHECK_COUNTER_STOPPED'
              | transloco
                : {
                    counter: counter,
                    countError: countError
                  }
          "
        ></span>
      </ng-template>
    </div>
    <div fxLayout="row" fxLayoutGap="16px" *ngIf="hasError === false">
      <ng-container *ngIf="visiblePrintButton">
        <button
          id="print-anyway"
          color="accent"
          *ngIf="countWarning > 0 || countError > 0; else simpleTpl"
          @fadeInEnter
          [disabled]="loading"
          mat-raised-button
          class="submit-button"
          type="button"
          (click)="onPrintPdf(false)"
        >
          {{ printButtonLabel | transloco }}
        </button>
        <ng-template #simpleTpl>
          <button
            color="primary"
            @fadeInEnter
            [disabled]="loading"
            mat-raised-button
            class="submit-button"
            type="button"
            (click)="onPrintPdf(false)"
          >
            {{ printButtonLabel | transloco }}
          </button>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>
