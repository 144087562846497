import { HasViewSubmitPlugin } from '../../../controller/plugin/view-submit/has-view-submit-plugin.interface';

export class CheckModifierSubmitAction {
  static readonly type = '[Check modifier] Submit';

  constructor(
    readonly dataFormCmpRef: HasViewSubmitPlugin<any>,
    /* azert kell, mert ha van tobb view submit plugin ami fut akkor el tudjak donteni melyiknek kell reagalni */ readonly viewSubmitPluginId?: number
  ) {}
}
