import moment from 'moment';
import { TranslocoService } from '@ngneat/transloco';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { isNil } from '@roadrecord/type-guard';

export function firstStepperFinish(
  link: string,
  buttonLabel: string,
  translocoService: TranslocoService,
  gtmService: GoogleTagManagerService | null,
  gtmLabel = 'FinishWelcomeNextButtonClick',
  translateKey = 'FIRST_STEPS.FINISH_WELCOME.LAYOUT'
) {
  let translatedLink = translocoService.translate(`${translateKey}.${link}`);

  if (translatedLink.indexOf(':CURRENT_DATE') > -1) {
    translatedLink = translatedLink.replace(':CURRENT_DATE', moment().format('YYYY-MM-DD'));
  }

  const translatedButtonLabel = translocoService.translate(`${translateKey}.${buttonLabel}`);

  if (!isNil(gtmService)) {
    gtmService.pushTag({
      event: `Redirect to: ${translocoService.translate(translatedButtonLabel)} [${translatedLink}]`,
      category: 'FirstStepper',
      label: 'FinishWelcomeNextButtonClick',
    });
  }
  return translatedLink;
}
