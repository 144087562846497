import { MessageDialogService } from '@roadrecord/message-dialog';

export interface DetectPopupBlockerMessageDialogTextParams {
  linkName: string;
  linkUrl: string;
  windowTarget: '_blank' | '_parent' | '_self' | '_top';
  windowParams?: string;
}

export function openWindowOrMessageDialog(messageDialogTextParams: DetectPopupBlockerMessageDialogTextParams) {
  const newWin = window.open(messageDialogTextParams.linkUrl, messageDialogTextParams.windowTarget, messageDialogTextParams.windowParams);
  if (!newWin || newWin.closed || typeof newWin.closed === 'undefined' || (newWin.location as any) === 'about:blank') {
    // POPUP BLOCKED
    const translatePrefix = 'COMMON.POPUPBLOCKER_DIALOG.';
    MessageDialogService.INSTANCE.openInformation({
      id: null,
      title: `${translatePrefix}TITLE`,
      text: `${translatePrefix}CONTENT`,
      textParams: {
        linkName: messageDialogTextParams.linkName,
        linkUrl: messageDialogTextParams.linkUrl,
      },
      htmlMode: true,
    });
    return null;
  }
  return newWin;
}
