import { Pipe, PipeTransform } from '@angular/core';
import { SplitButtonAction } from '@roadrecord/split-button';
import { RRCalendarViewType } from '../calendar/rr-calendar-view-type';

@Pipe({
  name: 'addDefaultTopRightSplitButtonActions',
})
export class AddDefaultTopRightSplitButtonActionsPipe implements PipeTransform {
  transform(value: SplitButtonAction[], viewType: RRCalendarViewType, toggleViewType: () => void): SplitButtonAction[] {
    return [
      /*
      {
        type: 'ITEM',
        label: { translate: `CALENDAR.HEADER.TOOLTIP.BUTTON.${viewType === RRCalendarViewType.LIST ? 'CALENDAR' : 'LIST'}` },
        icon: viewType === RRCalendarViewType.CALENDAR ? 'playlist-edit' : 'calendar-edit',
        action: toggleViewType,
      },
*/
      /*
      ...((Array.isArray(value) && value.length > 0 ? [{ type: 'DIVIDER' }, ...value] : []) as SplitButtonAction[]),
*/
      ...((Array.isArray(value) && value.length > 0 ? [...value] : []) as SplitButtonAction[]),
    ];
  }
}
