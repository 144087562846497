<div class="row">
  <div [ngClass]="{ 'col-12': !(selectedSourceTypeTranslateKey | isPresent), 'col-5': selectedSourceTypeTranslateKey | isPresent }">
    <ng-container
      *rrTransloco="
        let t;
        fromPath: 'UNIVERSAL_IMPORT.UPLOAD_STEPPER.SOURCE_TYPE';
        overridePath: importConfig.translatePrefix + '.UPLOAD_STEPPER.SOURCE_TYPE'
      "
    >
      <div [ngClass]="{ 'two-panel-mode': selectedSourceTypeTranslateKey | isPresent }">
        <h2 class="text-center">{{ t('LABEL') }}</h2>
        <div
          class="d-flex flex-row flex-wrap justify-content-center button-flex-grid mb-4"
          [ngClass]="{ 'mx-auto justify-content-center': !(selectedSourceTypeTranslateKey | isPresent) }"
        >
          <button
            *ngFor="let button of importConfig.importButtons; let i = index"
            [attr.id]="importConfig.gtmPrefix + '_data_source_selector_type_select_' + button.translateKey.toLowerCase()"
            (click)="onClickSourceType(button.config)"
            mat-raised-button
            [color]="sourceTypeControl?.value?.type === button.config.type ? 'primary' : 'accent'"
            class="mat-elevation-z8 source-type-{{ button.config.type }}"
            [ngClass]="{ selected: sourceTypeControl?.value?.type === button.config.type }"
            [matTooltip]="t('TYPES.' + button.translateKey + '.TOOLTIP')"
          >
            <img class="w-100" [src]="button.logoUrl | sanitizeUrl" />
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="col-7" *ngIf="selectedSourceTypeTranslateKey | isPresent">
    <ng-container
      *rrTransloco="let t; fromPath: 'UNIVERSAL_IMPORT.UPLOAD_STEPPER'; overridePath: importConfig.translatePrefix + '.UPLOAD_STEPPER'"
    >
      <h2 class="text-center">{{ t('SELECT_SOURCE.HELP.LABEL') }}</h2>
      <mat-card
        @fadeIn2XFastEnter
        @fadeOut2XFastLeave
        class="mat-elevation-z4 source-type-description"
        *ngIf="t('SOURCE_TYPE.TYPES.' + selectedSourceTypeTranslateKey + '.DESCRIPTION') as translate"
      >
        <mat-card-title
          ><b>{{ t('SOURCE_TYPE.TYPES.' + selectedSourceTypeTranslateKey + '.LABEL') }}</b></mat-card-title
        >
        <mat-card-content #matCardContent
          ><div *ngIf="hasCollapsed; else shortTextTpl" class="content position-relative" [@collapse]="collapsed"
            ><span [innerHTML]="translate"></span
            ><div
              *ngIf="collapsed"
              @fadeOut2XFastLeave
              class="more position-absolute"
              style="
                max-height: 160px;
                padding-top: 2rem;
                bottom: 0;
                width: 100%;
                text-align: center;
                background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, transparent), color-stop(1, white));
              "
              ><button type="button" mat-raised-button color="accent" (click)="onClickMore()">{{
                t('SELECT_SOURCE.MORE_TEXT_BUTTON_LABEL')
              }}</button></div
            ></div
          ><ng-template #shortTextTpl><span [innerHTML]="translate"></span></ng-template
        ></mat-card-content>
      </mat-card>
      <a
        *ngIf="importSampleUrl !== null"
        class="d-none hidden-download-sample-link"
        [href]="importSampleUrl"
        target="_blank"
        #downloadFileAnchor
      ></a>
      <div class="mt-3 mb-3 buttons-row">
        <a
          [attr.id]="importConfig.gtmPrefix + '_data_source_selector_faq_' + selectedSourceTypeTranslateKey.toLowerCase()"
          style="grid-column-start: 1"
          *ngIf="t('SOURCE_TYPE.TYPES.' + selectedSourceTypeTranslateKey + '.GYIKURL') as linkUrl"
          @fadeIn2XFastEnter
          mat-raised-button
          [href]="linkUrl"
          target="_blank"
          class="w-100 gyik-button"
        >
          {{ t(fileUploadTranslatePrefix + 'GYIK_BUTTON_LABEL') }}<mat-icon svgIcon="help-circle"></mat-icon>
        </a>

        <button
          [attr.id]="importConfig.gtmPrefix + '_data_source_selector_next_step_' + selectedSourceTypeTranslateKey.toLowerCase()"
          style="grid-column-start: 2"
          type="button"
          mat-raised-button
          color="primary"
          *ngIf="selectedSourceTypeTranslateKey | isPresent"
          class="next-step-button w-100"
          (click)="onClickNextStep()"
          >{{ t('SELECT_SOURCE.NEXT_BUTTON_LABEL') }}<mat-icon svgIcon="step-forward"></mat-icon>
        </button>

        <button
          [attr.id]="importConfig.gtmPrefix + '_data_source_selector_download_sample_' + selectedSourceTypeTranslateKey.toLowerCase()"
          style="grid-column-start: 1"
          *ngIf="importSampleUrl !== null"
          @fadeIn2XFastEnter
          mat-raised-button
          class="d-inlie-block download-sample w-100"
          (click)="downloadSampleXLSFile()"
        >
          {{ t(fileUploadTranslatePrefix + 'DOWNLOAD_SAMPLE_FILE') }}<mat-icon svgIcon="download-circle"></mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</div>
