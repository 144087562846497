import { IconColumnDataModel } from '@roadrecord/grid';
import { UniversalImportStatusEnum } from '../model/universal-import-status.enum';

export const setIconFn = (_this: any): any => (model: any): string | IconColumnDataModel => {
  const translatePrefix = `${_this.importConfig.translatePrefix}.LIST.COLUMNS.STATUS.TOOLTIP.`;
  switch (model.status) {
    case UniversalImportStatusEnum.PROGRESS:
      return {
        value: 'progress-check',
        cssClassName: 'progress',
        tooltipTextTranslateKey: `${translatePrefix}PROGRESS`,
      };
    case UniversalImportStatusEnum.SUCCESS:
      return {
        value: 'check',
        cssClassName: 'success',
        tooltipTextTranslateKey: `${translatePrefix}SUCCESS`,
      };
    case UniversalImportStatusEnum.ERROR:
      return {
        value: 'alert',
        cssClassName: 'error',
        tooltipTextTranslateKey: `${translatePrefix}ERROR`,
      };
    default:
      return '';
  }
};
