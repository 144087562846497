import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { fadeInEnter } from '@roadrecord/animations';
import { CompanyMeModel, PRICE_SITE_PATH, USER_ROUTE_PATH, UserCompanyMeState } from '@roadrecord/common/common';
import { commonHttpStreamErrorHandler } from '@roadrecord/utils';
import { isNil } from '@roadrecord/type-guard';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { CompanyContextState, SubscriptionGoToAction, SubscriptionInfoEnum } from '@roadrecord/company-context/common';
import { TranslocoService } from '@ngneat/transloco';
import { getSubscriptionCallbackUrl } from '../../../subscription/get-subscription-callback-url.function';
import { Router } from '@angular/router';
import { AppTypeEnum, environment } from '@roadrecord/environment';

@UntilDestroy()
@Component({
  selector: 'rr-subscription-data',
  templateUrl: './subscription-data.component.html',
  styleUrls: ['./subscription-data.component.scss'],
  animations: [fadeInEnter],
})
export class SubscriptionDataComponent {
  @SelectSnapshot(UserCompanyMeState.get)
  companyMeState: CompanyMeModel;
  @SelectSnapshot(CompanyContextState.subscriptionState)
  readonly subscriptionInfo: SubscriptionInfoEnum;
  SubscriptionInfoEnumValues = SubscriptionInfoEnum.getObjectValues();
  readonly isUsAppType = environment.appType === AppTypeEnum.US;

  constructor(private store: Store, private translocoService: TranslocoService, private router: Router) {
    this.store
      .select(UserCompanyMeState.error)
      .pipe(
        untilDestroyed(this),
        filter(error => !isNil(error))
      )
      .subscribe(error => commonHttpStreamErrorHandler()(error));
  }

  onClickSubscriptionGoTo() {
    if (this.isUsAppType === true) {
      this.router.navigate([`/${USER_ROUTE_PATH}/${PRICE_SITE_PATH}/`]);
    } else {
      this.store.dispatch(
        new SubscriptionGoToAction(
          {
            linkName: this.translocoService.translate(
              'USER_AUTHED.SUBSCRIPTION_DATA.GO_TO_BUTTON.' + this.SubscriptionInfoEnumValues[this.subscriptionInfo]
            ),
            windowTarget: '_blank',
          },
          getSubscriptionCallbackUrl(this.subscriptionInfo)
        )
      );
    }
  }
}
