import { Component, Inject, OnInit } from '@angular/core';
// circular dep miatt kellett trukozni
// import { HttpErrorInterceptor } from '@roadrecord/http-error-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationSettingsService } from '@roadrecord/user/application-settings';

@Component({
  selector: 'rr-application-settings',
  templateUrl: './application-settings.component.html',
  styleUrls: ['./application-settings.component.scss'],
  providers: [ApplicationSettingsService],
})
export class ApplicationSettingsComponent implements OnInit {
  inited = false;
  showGlobalTab = false;

  private httpErrorInterceptor: { addDisableErrorCode(code: number): void; removeDisableErrorCode(code: number): void };

  constructor(private applicationSettingsService: ApplicationSettingsService, @Inject(HTTP_INTERCEPTORS) httpInterceptors: any[]) {
    this.httpErrorInterceptor = httpInterceptors.find(httpInterceptor => (httpInterceptor as any).ID === 'HttpError');
  }

  ngOnInit() {
    this.httpErrorInterceptor.addDisableErrorCode(403);
    this.applicationSettingsService.getAllGlobal().subscribe(
      () => (this.showGlobalTab = true),
      () => {},
      () => {
        this.httpErrorInterceptor.removeDisableErrorCode(403);
        return (this.inited = true);
      }
    );
  }
}
