import { Pipe, PipeTransform } from '@angular/core';
import { isFunction } from '@roadrecord/type-guard';

@Pipe({
  name: 'isFunctionNotPure',
  pure: false,
})
export class IsFunctionNotPurePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return isFunction(value);
  }
}
