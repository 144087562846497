import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxsModule } from '@ngxs/store';
import { PreventClickModule, SanitizePipeModule, TextPlaceholderHtmlTagReplacerModule } from '@roadrecord/common/common';
import { NgOverrideModule } from '@roadrecord/ng-override';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HeaderPanelComponent } from './header-panel/header-panel.component';
import { ReportPrintCheckListFilterPipe } from './pipe/report-print-check-list-filter.pipe';
import { ReportPrintRoutingModule } from './report-print-routing.module';
import { ReportPrintComponent } from './report-print/report-print.component';
import { ReportPrintState } from './state/report-print.state';
import { GetLabelByCurrentLangPipeModule } from './get-label-by-current-lang-pipe/get-label-by-current-lang-pipe.module';
import { ResultPanelModule } from './result-panel/result-panel.module';

@NgModule({
  imports: [
    CommonModule,
    ReportPrintRoutingModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    FlexLayoutModule,
    TranslocoModule,
    NgxsModule.forFeature([ReportPrintState]),
    MatDividerModule,
    MatTooltipModule,
    NgOverrideModule,
    MatSnackBarModule,
    TextPlaceholderHtmlTagReplacerModule,
    PdfViewerModule,
    PreventClickModule,
    GetLabelByCurrentLangPipeModule,
    ResultPanelModule,
    SanitizePipeModule,
  ],
  declarations: [ReportPrintComponent, ReportPrintCheckListFilterPipe, HeaderPanelComponent],
})
export class ReportPrintModule {}
