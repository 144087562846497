import { MatDateFormats } from '@angular/material/core';

export const HU_HU_MAT_MOMENT_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY. MMMM D., dddd',
  },
  display: {
    dateInput: 'YYYY. MMMM D., dddd',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const HU_HU_MAT_MOMENT_DATETIME_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY. MMMM D., dddd HH:mm',
  },
  display: {
    dateInput: 'YYYY. MMMM D., dddd HH:mm',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const HU_HU_MAT_MOMENT_TIME_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'HH:mm',
  },
  display: {
    dateInput: 'HH:mm',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const HU_HU_MAT_DATE_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const EN_US_MAT_MOMENT_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'MM/DD/YYYY, dddd',
  },
  display: {
    dateInput: 'MM/DD/YYYY, dddd',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const EN_US_MAT_MOMENT_DATETIME_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'MMMM D dddd YYYY h:mm A',
  },
  display: {
    dateInput: 'MMMM D dddd YYYY h:mm A',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const EN_US_MAT_MOMENT_TIME_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'h:mm A',
  },
  display: {
    dateInput: 'h:mm A',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const EN_US_MAT_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
