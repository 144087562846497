import { CommonModule as NgCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UppercaseDirective } from './uppercase.directive';

@NgModule({
  imports: [NgCommonModule],
  declarations: [UppercaseDirective],
  exports: [UppercaseDirective],
})
export class UppercaseDirectiveModule {}
