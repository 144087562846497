<ng-template #formTpl>
  <form
    [formGroup]="form"
    fxLayout="column"
    fxLayoutAlign="center stretch"
    (keydown.enter)="onSubmit()"
    [ngClass]="{ 'is-new': isNew }"
    novalidate
  >
    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="1em" fxLayoutGap.gt-sm="0em">
      <mat-form-field class="wid100" data-cy="partner-name">
        <mat-label>{{ nameControlLabel | transloco }}</mat-label>
        <input matInput [placeholder]="nameControlLabel | transloco" name="name" formControlName="name" type="text" required />
        <mat-error>
          <rr-validation-messages [errors]="nameControl?.errors"></rr-validation-messages>
        </mat-error>
        <span matSuffix>
          <button
            *ngIf="nameControl?.value; else autoFillBtnTpl"
            type="button"
            mat-icon-button
            @zoomIn2XEnter
            class="clear-button small d-inline-block"
            tabindex="-1"
            rrPreventClick
            [disabled]="presenterStateController.loading$ | async"
            (click)="nameControl.setValue('')"
          >
            <mat-icon svgIcon="close" class="small"></mat-icon>
          </button>
          <ng-template #autoFillBtnTpl
            ><button
              mat-mini-fab
              @zoomIn2XEnter
              type="button"
              class="d-inline-block"
              tabindex="-1"
              rrPreventClick
              style="transform: scale(0.8)"
              [matTooltip]="'PARTNER.DETAILS.DATA_FORM.TOOLTIP.FILL_NAME_WITH_ADDRESS.' + translateTypeSuffix | transloco"
              [disabled]="presenterStateController.loading$ | async"
              (click)="nameControl.setValue(partnerAddressFormatter($any({ address: form.getRawValue() })))"
              ><mat-icon svgIcon="map-marker-left-outline"></mat-icon></button
          ></ng-template>
        </span>
      </mat-form-field>
      <rr-formly-form class="w-100" [form]="form" [fields]="addressFormFragmentFormlyFields"></rr-formly-form>
      <div class="loading-spinner-overlay-wrapper wid100 mt-1">
        <div class="loading-mask" @fadeInEnter *ngIf="loadingFullTripReasonList">
          <div class="spinner-wrapper">
            <mat-progress-spinner strokeWidth="10" mode="indeterminate" color="accent"></mat-progress-spinner>
          </div>
        </div>
        <div class="mat-button-toggle-group-wrapper partner-type-select">
          <label
            matTooltip="{{ 'PARTNER.DETAILS.DATA_FORM.TOOLTIP.TYPE_LABEL' | transloco }}"
            matTooltipPosition="below"
            [ngClass]="{ 'fix-mac-before-2015': dataFormTypeControlLabelBlock }"
            >{{ 'PARTNER.DETAILS.DATA_FORM.LABEL.TYPE' | transloco }}</label
          >
          <mat-button-toggle-group formControlName="type" name="type" [vertical]="typeControlIsVertical" class="primary-color-checked">
            <mat-button-toggle [value]="PartnerTypeEnum.PARTNER"> {{ 'PARTNER.TYPE.PARTNER' | transloco }} </mat-button-toggle>
            <mat-button-toggle [value]="PartnerTypeEnum.CHARGING_STATION">
              {{ 'PARTNER.TYPE.CHARGING_STATION' | transloco }}
            </mat-button-toggle>
            <mat-button-toggle [value]="PartnerTypeEnum.HEAD_OFFICE_OR_SITE">
              {{ 'PARTNER.TYPE.HEAD_OFFICE_OR_SITE' | transloco }}
            </mat-button-toggle>
          </mat-button-toggle-group>
          <mat-error>
            <rr-validation-messages [errors]="typeControl?.errors"></rr-validation-messages>
          </mat-error>
        </div>
        <rr-live-auto-complete
          #tripReasonLiveAutoComplete
          name="trip_reason"
          required
          [formControlRef]="tripReasonControl"
          [database]="tripReasonService"
          hasPrefix="true"
          hasNewButton="true"
          [optionsConfig]="laTripReasonOptionsConfig"
          placeholder="PARTNER.DETAILS.DATA_FORM.LABEL.TRIP_REASON"
          [validationMessages]="laTripReasonOptionsConfig.validatorMessages"
        >
        </rr-live-auto-complete>
      </div>
      <div
        fxLayoutGap="1em"
        class="wid100"
        fxLayout="column"
        fxLayout.gt-sm="row"
        *ngIf="tripReasonLiveAutoComplete?.selectedValue"
        @zoomIn2XEnter
      >
        <div
          class="inline-block trip-reason-text-wrapper"
          matTooltip="{{ 'PARTNER.DETAILS.DATA_FORM.TRIP_REASON.MESSAGE.DETERMINED_BY_TRIP_REASON' | transloco }}"
          matTooltipPosition="below"
        >
          <span class="trip-reason-two-way-left-text">{{ 'PARTNER.DETAILS.DATA_FORM.TRIP_REASON.LABEL.LEFT' | transloco }}</span>
          <span *ngIf="$any(tripReasonLiveAutoComplete?.selectedValue)?.is_private === false" class="trip-reason-two-way-content">
            <mat-icon class="light-color" svgIcon="briefcase"></mat-icon>
            {{ 'TRIP_REASON.DETAILS.DATA_FORM.FIELD.IS_PRIVATE.LABELS.BUSINESS' | transloco }}
          </span>
          <span *ngIf="$any(tripReasonLiveAutoComplete?.selectedValue)?.is_private === true" class="trip-reason-two-way-content">
            <mat-icon svgIcon="human-handsdown" class="light-color"></mat-icon>
            {{ 'TRIP_REASON.DETAILS.DATA_FORM.FIELD.IS_PRIVATE.LABELS.PRIVATE' | transloco }}
          </span>
          <span class="trip-reason-two-way-right-text">{{ 'PARTNER.DETAILS.DATA_FORM.TRIP_REASON.LABEL.RIGHT' | transloco }}</span>
        </div>
      </div>
      <div class="meta-datas wid100" fxLayoutGap="1em" fxLayout="column" fxLayout.gt-sm="row">
        <mat-form-field fxFlex="100%" fxFlex.gt-sm="31%">
          <mat-label>{{ 'PARTNER.DETAILS.DATA_FORM.LABEL.COUNTRY' | transloco }}</mat-label>
          <input
            name="country"
            matInput
            [placeholder]="'PARTNER.DETAILS.DATA_FORM.LABEL.COUNTRY' | transloco"
            [value]="editModel?.local_country"
            type="text"
            disabled="disabled"
          />
        </mat-form-field>
        <mat-form-field fxFlex="100%" fxFlex.gt-sm="31%">
          <mat-label>{{ 'PARTNER.DETAILS.DATA_FORM.LABEL.LATITUDE' | transloco }}</mat-label>
          <input
            name="latitude"
            matInput
            [placeholder]="'PARTNER.DETAILS.DATA_FORM.LABEL.LATITUDE' | transloco"
            [value]="editModel?.latitude"
            type="text"
            disabled="disabled"
          />
        </mat-form-field>
        <mat-form-field fxFlex="100%" fxFlex.gt-sm="31%">
          <mat-label>{{ 'PARTNER.DETAILS.DATA_FORM.LABEL.LONGITUDE' | transloco }}</mat-label>
          <input
            name="longitude"
            matInput
            [placeholder]="'PARTNER.DETAILS.DATA_FORM.LABEL.LONGITUDE' | transloco"
            [value]="editModel?.longitude"
            type="text"
            disabled="disabled"
          />
        </mat-form-field>
      </div>
      <p *ngIf="editModel !== undefined && !checkIsValidCoordinateFn(editModel)">
        {{ 'PARTNER.DETAILS.DATA_FORM.INVALID_COORDINATES' | transloco }}
      </p>
    </div>
  </form>
  <div class="loading-mask" *ngIf="coordinateLoadingMask">
    <mat-spinner color="warn"></mat-spinner>
    <h2>{{ 'PARTNER.DETAILS.SEARCH_ON_MAP.COORDINATE_DATA_DOWNLOAD' | transloco }}</h2>
  </div>
</ng-template>

<ng-template #buttonsTpl>
  <rr-details-button-row
    [gaPrefix]="gaType"
    [isNew]="isNew"
    [disabled]="(form['submitted'] && form.invalid) || form.disabled || loadingFullTripReasonList || disableEventButtons"
    [form]="form"
    [hasSubmitAndNew]="hasSubmitAndNew"
    [disableDelete]="false"
    (delete)="onDelete()"
    (submitForm)="onSubmit()"
    (submitAndNew)="onSubmitReset()"
  ></rr-details-button-row>
</ng-template>
