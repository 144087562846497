import { Pipe, PipeTransform } from '@angular/core';
import { AppTypeEnum, environment } from '@roadrecord/environment';

@Pipe({
  name: 'appTypeIsHu',
})
export class AppTypeIsHuPipe implements PipeTransform {
  transform(value: null): any {
    return environment.appType === AppTypeEnum.HU;
  }
}
