import { Action, State, StateContext } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import { UserSettingsStateModel } from './model/user-settings-state.model';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApplicationSettingsService } from '../application-settings.service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { asapScheduler, of, throwError } from 'rxjs';
import { MaybeHandleHttpError } from '@roadrecord/utils';
import { AddForeverHideMessageModalAction } from './action/add-forever-hide-message-modal.action';
import { GetForeverHideSettingsAction } from './action/get-forever-hide-settings.action';
import { GetForeverHideSettingsSuccessAction } from './action/get-forever-hide-settings-success.action';
import { GetForeverHideSettingsErrorAction } from './action/get-forever-hide-settings-error.action';
import { GetDateFirstDayWeekSettingAction } from './action/get-date-first-day-week-setting.action';
import { GetDateFirstDayWeekSettingSuccessAction } from './action/get-date-first-day-week-setting-success.action';

@State<UserSettingsStateModel>({
  name: 'settings',
  defaults: {
    foreverHideModals: [],
    version: 3,
  },
})
@Injectable()
export class UserSettingsState {
  private _applicationSettingsService: ApplicationSettingsService;
  /**
   * Tipust nem lehet beallitani a circular dep miatt
   */
  private httpErrorInterceptor: any;

  constructor(private http: HttpClient, @Inject(HTTP_INTERCEPTORS) private httpInterceptors: any[]) {
    this.httpErrorInterceptor = httpInterceptors.find(httpInterceptor => httpInterceptor.ID === 'HttpError');
  }

  @Action(GetDateFirstDayWeekSettingAction)
  getDateFirstDayWeekSetting({ dispatch }: StateContext<UserSettingsStateModel>) {
    this.httpErrorInterceptor.addDisableErrorCode(400);
    this.httpErrorInterceptor.addDisableErrorCode(404);
    return this.applicationSettingsService.getOneGlobal('date', 'first_day_week').pipe(
      tap(result => {
        this.httpErrorInterceptor.removeDisableErrorCode(400);
        this.httpErrorInterceptor.removeDisableErrorCode(404);
        asapScheduler.schedule(() => dispatch(new GetDateFirstDayWeekSettingSuccessAction(result.value)));
      }),
      catchError(err => {
        this.httpErrorInterceptor.removeDisableErrorCode(400);
        this.httpErrorInterceptor.removeDisableErrorCode(404);
        // set default when has http error
        const result = 0;
        asapScheduler.schedule(() => dispatch(new GetDateFirstDayWeekSettingSuccessAction(result)));
        // asapScheduler.schedule(() => dispatch(new GetDateFirstDayWeekSettingErrorAction(err)));
        return of(result);
      })
    );
  }

  @Action(AddForeverHideMessageModalAction)
  addDisableShowMessageModal(ctx: StateContext<UserSettingsStateModel>, { id }: AddForeverHideMessageModalAction) {
    ctx.setState(patch({ foreverHideModals: append([id]) }));
    return this.applicationSettingsService.getOneUser('message_dialog', 'forever_hide').pipe(
      switchMap((options: { value: string[] }) => {
        options.value.push(id);
        return this.applicationSettingsService.saveOneUser('message_dialog', 'forever_hide', options.value);
      }),
      catchError(err => {
        asapScheduler.schedule(() => MaybeHandleHttpError.maybeHandleHttpError(err));
        return throwError(err);
      })
    );
  }

  @Action(GetForeverHideSettingsAction)
  getForeverHideSettings({ dispatch }: StateContext<UserSettingsStateModel>) {
    return this.applicationSettingsService.getOneUser('message_dialog', 'forever_hide').pipe(
      tap(result => asapScheduler.schedule(() => dispatch(new GetForeverHideSettingsSuccessAction(result)))),
      catchError(err => {
        asapScheduler.schedule(() => dispatch(new GetForeverHideSettingsErrorAction(err)));
        return throwError(err);
      })
    );
  }

  /**
   * lazy init service
   */
  get applicationSettingsService() {
    if (this._applicationSettingsService === undefined) {
      this._applicationSettingsService = new ApplicationSettingsService(this.http);
    }
    return this._applicationSettingsService;
  }
}
