/** @docs-private */
import { TranslocoService } from '@ngneat/transloco';
import { ChangeDetectorRef, Injector, NgZone, OnInit } from '@angular/core';
import { Constructor, deepEqual, RRFormGroup } from '@roadrecord/common/common';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { commonHttpStreamErrorHandler, ManualViewRemoteFieldErrorPlugin } from '@roadrecord/utils';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApplicationSettingsConfigs, ApplicationSettingsService } from '@roadrecord/user/application-settings';
import { tsDeepcopy } from '@roadrecord/ts-deepcopy';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface ApplicationSettingsTabLayout {
  label: string;
  tooltip?: string;
  formConfig: FormlyFieldConfig[];
}

export interface ApplicationSettingsTab extends OnInit {
  originalRemoteValue: ApplicationSettingsConfigs;
  hasModify: boolean;
  inited: boolean;
  form: RRFormGroup;
  layout: ApplicationSettingsTabLayout[];
  onClickSubmit: () => void;
}

export type ApplicationSettingsTabCtor = Constructor<ApplicationSettingsTab>;

export interface ApplicationSettingsTabConstructorParams {
  readonly layout: ApplicationSettingsTabLayout[];
  readonly applicationSettingsService: ApplicationSettingsService;
  readonly cdr: ChangeDetectorRef;
  readonly translocoService: TranslocoService;
  readonly document: Document;
  readonly messageDialogService: MessageDialogService;
  readonly injector: Injector;
  readonly ngZone: NgZone;
  readonly matSnackBar: MatSnackBar;
}

export function mixinApplicationSettingsTab<T extends Constructor<ApplicationSettingsTabConstructorParams>>(
  base: T
): ApplicationSettingsTabCtor & T {
  return class extends base implements OnInit {
    originalRemoteValue = null;
    hasModify = false;
    inited = false;
    form = new RRFormGroup({});
    private viewRemoteFieldErrorPlugin: ManualViewRemoteFieldErrorPlugin<ApplicationSettingsConfigs, null>;

    constructor(...args: any[]) {
      super(...args);
    }

    ngOnInit(): void {
      this.layout.forEach(layout => (layout.formConfig[0]['_rr_details_injector'] = this.injector));

      this.getAllHttp().subscribe(result => {
        this.inited = true;
        this.cdr.markForCheck();

        this.viewRemoteFieldErrorPlugin = new ManualViewRemoteFieldErrorPlugin(
          this.translocoService,
          this.document,
          this.messageDialogService
        );
        this.viewRemoteFieldErrorPlugin.formGroupLastValue = this.form;
        this.originalRemoteValue = tsDeepcopy(result);
        this.ngZone.onStable.pipe(take(1)).subscribe(() => {
          this.form.patchValue(result);
          this.form.valueChanges.pipe(distinctUntilChanged((_old, _new) => deepEqual(_old, _new))).subscribe(value => {
            this.hasModify = !deepEqual(value, this.originalRemoteValue);
            this.cdr.markForCheck();
          });
        });
      }, commonHttpStreamErrorHandler());
    }

    getAllHttp(): Observable<ApplicationSettingsConfigs> {
      throw new Error('getAllHttp override is required!');
    }

    saveAllHttp(formValue: ApplicationSettingsConfigs): Observable<void> {
      throw new Error('getAllHttp override is required!');
    }

    onClickSubmit() {
      this.form.submitted = true;
      if (this.form.valid) {
        const formValue = this.form.value;
        this.form.disable();
        this.cdr.markForCheck();
        this.saveAllHttp(formValue).subscribe(
          () => {
            this.originalRemoteValue = tsDeepcopy(formValue);
            this.hasModify = false;
            this.matSnackBar.open(this.translocoService.translate('COMMON.NOTIFICATION.SUCCESS_SAVE'));
          },
          commonHttpStreamErrorHandler(),
          () => {
            this.form.enable();
            this.cdr.detectChanges();
          }
        );
      }
    }
  };
}
