import { Pipe, PipeTransform } from '@angular/core';
import { PricePackage } from '../model/package-type.enum';
import { PriceItemModel } from '../model/models';

@Pipe({
  name: 'italicTextFormatPipe',
})
export class ItalicTextFormatPipe implements PipeTransform {
  /**
   * Árlista esetén ha nem BRONZE vagy LIFETIME csomagról van szó,akkor a kiírt szöveget dőlt betűvel kell megjelníteni
   * @param itemIndex {number}
   * @param priceItem {PriceItemModel}
   */
  transform(itemIndex: number, priceItem: PriceItemModel): boolean {
    return (
      itemIndex === 0 &&
      ((priceItem.product_category.is_lifetime === false && priceItem.name.toUpperCase() !== PricePackage.BRONZE.toUpperCase()) ||
        priceItem.product_category.is_lifetime === true)
    );
  }
}
