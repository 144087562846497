<mat-list role="list">
  <mat-list-item role="listitem" *ngFor="let option of values$ | async; let i = index">
    <rr-two-way-switch-form-control
      [formControl]="getValueFormControl(i, option)"
      color="primary"
      icon_name_true="eye"
      icon_name_false="eye-off"
      [option_true]="'COMMON.DIALOG.FOREVER_HIDE.APPLICATION_SETTINGS.USER.DIALOG_NAMES.' + option.key"
    ></rr-two-way-switch-form-control>
    <!--    <mat-slide-toggle-->
    <!--      class="example-margin"-->
    <!--      color="accent"-->
    <!--      [checked]="option.value"-->
    <!--      [disabled]="disabled"-->
    <!--      (change)="onChangeToggle(option.key, $event)"-->
    <!--    >-->
    <!--      {{ 'COMMON.DIALOG.FOREVER_HIDE.APPLICATION_SETTINGS.USER.DIALOG_NAMES.' + option.key | transloco }}-->
    <!--    </mat-slide-toggle>-->
  </mat-list-item>
</mat-list>
