export enum RRSourceEnum {
  BACKEND,
  FRONTEND,
  WEBADMIN,
  MOBIL_IOS,
  MOBIL_ANDROID,
  CELERY,
  NGINX,
  WSGI,
  OSRM,
  OSM,
  CRM,
}
