import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailsModule } from './details/details.module';
import { ListModule } from './list/list.module';

import { TripReasonRoutingModule } from './trip-reason-routing.module';

@NgModule({
  imports: [CommonModule, TripReasonRoutingModule, ListModule, DetailsModule],
})
export class TripReasonModule {}
