import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { GetLabelByCurrentLangPipe } from './get-label-by-current-lang.pipe';

@NgModule({
  imports: [CommonModule, TranslocoModule],
  declarations: [GetLabelByCurrentLangPipe],
  exports: [GetLabelByCurrentLangPipe],
})
export class GetLabelByCurrentLangPipeModule {}
