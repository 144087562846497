import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { BaseViewRemoteFieldErrorPlugin } from './base-view-remote-field-error.plugin';

/**
 * @deprecated hasznald ezt: ManualViewRemoteFieldErrorPlugin
 */
export class NgxFormlyViewRemoteFieldErrorPlugin<MODEL, IDTYPE> extends BaseViewRemoteFieldErrorPlugin<MODEL, IDTYPE> {
  private form: FormGroup;
  constructor(translocoService: TranslocoService, @Inject(DOCUMENT) document: Document, messageDialogService: MessageDialogService) {
    super(translocoService, document, messageDialogService);
  }

  set formGroupLastValue(form: FormGroup) {
    this.form = form;
  }

  get formGroupLastValue(): FormGroup {
    return this.form;
  }
}
