import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

const simpleFadeInTransition = transition(':enter', [
  style({
    opacity: 0,
    visibility: 'hidden',
  }),
  animate(
    '400ms cubic-bezier(0.25, 0.8, 0.25, 0.8)',
    style({
      opacity: 1,
      visibility: 'visible',
    })
  ),
]);
export const simpleFadeInAnimation: AnimationTriggerMetadata = trigger('simpleFadeIn', [simpleFadeInTransition]);

const fadeInTransition = transition(':enter', [
  style({
    opacity: 0,
    visibility: 'hidden',
    width: '100%',
    height: '100%',
  }),
  animate(
    '400ms cubic-bezier(0.25, 0.8, 0.25, 0.8)',
    style({
      opacity: 1,
      visibility: 'visible',
    })
  ),
]);
export const fadeInAnimation: AnimationTriggerMetadata = trigger('fadeIn', [fadeInTransition]);

const simpleFadeOutTransition = transition(':leave', [
  style({
    opacity: 1,
    visibility: 'visible',
    width: '100%',
    height: '100%',
  }),
  animate(
    '400ms cubic-bezier(0.25, 0.8, 0.25, 0.8)',
    style({
      opacity: 0,
      visibility: 'hidden',
    })
  ),
]);
export const simpleFadeOutAnimation: AnimationTriggerMetadata = trigger('simpleFadeOut', [simpleFadeOutTransition]);

const simpleFadeOut2xTransition = transition(':leave', [
  style({
    opacity: 1,
    visibility: 'visible',
    width: '100%',
    height: '100%',
  }),
  animate(
    '800ms ease-out',
    style({
      opacity: 0,
      visibility: 'hidden',
    })
  ),
]);
export const simpleFadeOut2xAnimation: AnimationTriggerMetadata = trigger('simpleFadeOut2x', [simpleFadeOut2xTransition]);

const fadeOutTransition = transition(':leave', [
  style({
    opacity: 1,
    visibility: 'visible',
    width: '100%',
    height: '100%',
  }),
  animate(
    '400ms cubic-bezier(0.25, 0.8, 0.25, 0.8)',
    style({
      opacity: 0,
      visibility: 'hidden',
    })
  ),
]);

export const fadeOutAnimation: AnimationTriggerMetadata = trigger('fadeOut', [fadeOutTransition]);

export const fadeInOutAnimation: AnimationTriggerMetadata = trigger('fadeInOut', [fadeInTransition, fadeOutTransition]);
