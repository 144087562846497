import { Injectable } from '@angular/core';
import { AbstractEntityService, HttpListResponseModel } from '@roadrecord/utils';
import { HttpClient } from '@angular/common/http';
import { ForeverHideTypesEnum } from '@roadrecord/message-dialog';

/**
 * technikai okok miatt kerult ez a fajlt ide, mert a user/authed-layouts/application-settings-be tartozna
 */

export interface ApplicationSettingsRemoteGlobalConfig {
  pdf: {
    print_footer: boolean;
    destination_time_visible: boolean;
  };
  date: {
    first_day_week: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  };
}
export interface ApplicationSettingsRemoteUserConfig {
  message_dialog: {
    forever_hide: string[];
  };
}
export interface ApplicationSettingsFormUserConfigForeverHide {
  key: keyof typeof ForeverHideTypesEnum;
  value: boolean;
}
export interface ApplicationSettingsFormUserConfig {
  message_dialog: {
    forever_hide: ApplicationSettingsFormUserConfigForeverHide[];
  };
}

export type ApplicationSettingsConfigs = ApplicationSettingsRemoteGlobalConfig | ApplicationSettingsRemoteUserConfig;

@Injectable()
export class ApplicationSettingsService extends AbstractEntityService<
  HttpListResponseModel<ApplicationSettingsConfigs>,
  ApplicationSettingsConfigs
> {
  getModelIdValue(model: ApplicationSettingsConfigs): any {
    console.warn('Not implemented');
    return null;
  }

  getToString(model: ApplicationSettingsConfigs): string {
    return JSON.stringify(model);
  }

  constructor(http: HttpClient) {
    super('application-settings', '', http);
  }

  getAllGlobal() {
    return this.http.get<ApplicationSettingsRemoteGlobalConfig>(this.getListOrResourceUrl('global', undefined));
  }

  saveAllGlobal(form: ApplicationSettingsRemoteGlobalConfig) {
    return this.http.put<void>(`${this.getEntityApiUrl()}/global/`, form);
  }

  getAllUser() {
    return this.http.get<ApplicationSettingsRemoteUserConfig>(this.getListOrResourceUrl('user', undefined));
  }

  saveAllUser(form: ApplicationSettingsRemoteUserConfig) {
    return this.http.put<void>(`${this.getEntityApiUrl()}/user/`, form);
  }

  getOneGlobal(groupName: string, key: string) {
    return this.http.get<any>(this.getListOrResourceUrl(`global/${groupName}/${key}`, undefined));
  }

  saveOneGlobal(groupName: string, key: string, value: any) {
    return this.http.put(`${this.getEntityApiUrl()}/global/${groupName}/${key}/`, { value });
  }

  getOneUser(groupName: string, key: string) {
    return this.http.get<any>(this.getListOrResourceUrl(`user/${groupName}/${key}`, undefined));
  }

  saveOneUser(groupName: string, key: string, value: any) {
    return this.http.put(`${this.getEntityApiUrl()}/user/${groupName}/${key}/`, { value });
  }
}
