<ng-template #calendarListCellTemplate let-event="event">
  <h2>
    <mat-icon svgIcon="gas-station" class="light-color mr-2 align-text-bottom"></mat-icon>{{ event.fueling_datetime }}
    {{ event.partner_name }}
  </h2>
  <p>
    <span [innerHTML]="'FUELING.CALENDAR.LIST.DESCRIPTION.MILEAGE' | transloco: (event?.mileage ? event : { mileage: '-' })"></span
    ><br /><span
      [innerHTML]="'FUELING.CALENDAR.LIST.DESCRIPTION.QUANTITY' | transloco: (event?.quantity ? event : { quantity: '-' })"
    ></span
    ><br /><span [innerHTML]="'FUELING.CALENDAR.LIST.DESCRIPTION.COST' | transloco: (event?.cost ? event : { cost: '-' })"></span>
  </p>
</ng-template>

<ng-template #calendarListRowDescriptionTemplate let-day="day">
  <strong
    ><ng-container *ngIf="day.events.length > 0; else nincsUt">
      &nbsp;{{ 'FUELING.CALENDAR.LIST.SUM_HEADER_TITLE' | transloco: { eventsCount: day.events.length } }}
    </ng-container>
    <ng-template #nincsUt> {{ 'FUELING.CALENDAR.LIST.HAS_NOT_ROAD_THIS_DAY' | transloco }} </ng-template>
  </strong>
</ng-template>

<div fxLayout="column" fxLayoutAlign="center stretch" fxFlexFill>
  <rr-calendar-layout
    [viewType]="RRCalendarViewType.LIST"
    [viewDate]="viewDate$ | async"
    [listItemCellTemplateRef]="calendarListCellTemplate"
    titleIcon="gas-station"
    newButtonTooltip="FUELING.CALENDAR.LIST.TOOLTIP.BUTTON.NEW"
    modifyButtonTooltip="FUELING.CALENDAR.LIST.TOOLTIP.BUTTON.MODIFY"
    [listItemDescriptionCellTemplateRef]="calendarListRowDescriptionTemplate"
    fxFlexFill
    [fillCalendarCb]="getFillCalendar()"
    (periodContextChange)="onPeriodContextChange($event)"
    [enableNotAccessRedirect]="false"
    [databaseCallback]="getDatabaseCallback()"
    (clickCell)="onClickCell($event)"
    [topRightMenuAfterButtons]="topRightMenuAfterButtons"
  ></rr-calendar-layout>
</div>
