<ng-container *ngIf="firstMessageVisible; else allMessage">
  <ng-container *ngIf="(errorMessages$ | async)[0] as message">
    <div
      class="mat-error-div"
      #errorElement
      [matTooltip]="
        hasEllipsis
          ? message.validationMessageModel.errorKey === 'CUSTOMERROR'
            ? message.formError
            : (message.validationMessageModel.translateKey | transloco: message.formError)
          : undefined
      "
      #tooltip="matTooltip"
      (click)="tooltip.show()"
      [ngClass]="{ 'has-ellipsis': hasEllipsis }"
    >
      <ng-template [ngIf]="message.validationMessageModel.errorKey === 'CUSTOMERROR'" [ngIfElse]="simpleMode">
        <mat-icon *ngIf="hasEllipsis" class="small" svgIcon="information-outline"></mat-icon>
        <span>{{ message.formError }}</span>
      </ng-template>
      <ng-template #simpleMode>
        <mat-icon *ngIf="hasEllipsis" class="small" svgIcon="information-outline"></mat-icon>
        <span>{{ message.validationMessageModel.translateKey | transloco: message.formError }}</span>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
<ng-template #allMessage>
  <!-- TODO atvezetni a fenti valtozasokat vagy kirakni egy kulon componentbe -->
  <mat-error *ngFor="let message of errorMessages$ | async">
    {{ message.validationMessageModel.translateKey | transloco: message.formError }}
  </mat-error>
</ng-template>
