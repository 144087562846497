<mat-sidenav-container [ngClass]="{ 'is-closed': (menuOpened$ | async) === false, 'no-menu': !showSidenav }">
  <mat-sidenav
    #leftSidenav
    *ngIf="showSidenav"
    class="sidenav mat-elevation-z3"
    [mode]="sidenavMode"
    fixedInViewport="false"
    [opened]="menuOpened$ | async"
    (closedStart)="dispatchMenuCloseAction()"
  >
    <rr-menu (closeMenu)="toggleSidenav()" [currentBreakpoint]="currentBreakpoint" [selectedTabIndex]="selectedMenuTabIndex"> </rr-menu>
  </mat-sidenav>
  <div id="sidenav-content">
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>
<ng-container *ngFor="let draggableWindowOptions of draggableWindowOptions$ | async; trackBy: draggableWindowTrackByFn">
  <rr-draggable-dialog
    [videoUrl]="draggableWindowOptions?.videoUrl"
    (closeDialog)="onCloseDraggableWindow(draggableWindowOptions.type)"
    [title]="draggableWindowOptions.title | transloco"
    [width]="draggableWindowOptions?.uiOptions?.width"
    [height]="draggableWindowOptions?.uiOptions?.height"
    [minWidth]="draggableWindowOptions?.uiOptions?.minWidth"
    [maxWidth]="draggableWindowOptions?.uiOptions?.maxWidth"
    [rzHandles]="draggableWindowOptions?.uiOptions?.rzHandles"
    [showMaximizeAndMinimizeButtons]="
      draggableWindowOptions?.showMaximizeAndMinimizeButtons === undefined ? true : draggableWindowOptions.showMaximizeAndMinimizeButtons
    "
    [counter]="draggableWindowOptions.counter"
    [active]="draggableWindowOptions.active"
    (activate)="onActivateDraggableWindow($any(draggableWindowOptions.type))"
  >
    <ng-template
      [ngIf]="draggableWindowContentComponentPortal[draggableWindowOptions.type]"
      [cdkPortalOutlet]="draggableWindowContentComponentPortal[draggableWindowOptions.type]"
      (attached)="onBindInputsToAttachedCmp(draggableWindowOptions.inputs, $event)"
    ></ng-template>
  </rr-draggable-dialog>
</ng-container>
<hotkeys-cheatsheet title="{{ 'COMMON.HOT_KEY.HOT_KEYS' | transloco }}"></hotkeys-cheatsheet>
<rr-introjs
  *ngIf="showIntrojs$ | async"
  [steps]="introjsSteps$ | async"
  [callbacksOptions]="introjsCallbacks$ | async"
  [globalOptions]="introjsGlobalOptions$ | async"
></rr-introjs>

<ng-template #phoneDialogButtonsTpl>
  <button mat-raised-button color="primary" class="submit-button" [disabled]="formGroup.invalid" (click)="onClickPhoneSave()">
    <span>{{ 'COMMON.ACTION.SAVE' | transloco }}</span>
  </button>
</ng-template>

<ng-template #phoneDialogContentTpl>
  <div #phoneNumberForm [formGroup]="formGroup" class="phone-number-form">
    <p class="phone-number-description">
      {{ 'AFTER_REGISTRATION_PHONE_NUMBER_DIALOG.DESCRIPTION' | transloco }}
    </p>
    <mat-form-field class="wid100 x&#45;&#45;phone-number">
      <mat-label>{{ 'USER.REGISTER.PHONE_NUMBER' | transloco }}</mat-label>
      <input
        #phoneNumberInput
        [imask]="{ mask: '{(}000{)}000{-}0000', parse: parseUsPhoneNumber, lazy: false }"
        unmask="typed"
        [placeholder]="'USER.REGISTER.EX_PHONE_NUMBER' | transloco"
        class="input-element phone-input-element"
        [formControl]="phoneNumberControl"
        matInput
        name="phoneNumber"
        required
        type="text"
      />
      <span class="phone-prefix" matPrefix>{{ 'USER.REGISTER.PHONE_NUMBER_PREFIX' | transloco }}&nbsp;</span>
      <mat-error>
        <rr-validation-messages [errors]="phoneNumberControl?.errors" [messages]="phoneControlValidationMessages"></rr-validation-messages>
      </mat-error>
      <mat-hint *ngIf="phoneNumberControl?.value?.length === 0">{{ 'USER.REGISTER.HINT.PHONE_NUMBER' | transloco }}</mat-hint>
    </mat-form-field>
    <mat-slide-toggle class="phone-number-form-toggle" [formControl]="marketingActivityAllowedControl">
      {{ 'AFTER_REGISTRATION_PHONE_NUMBER_DIALOG.MARKETING_ACTIVITY_SWITCH_DESCRIPTION' | transloco }}
    </mat-slide-toggle>
  </div>
</ng-template>
