import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { ToBooleanModule } from '../to-boolean-pipe/to-boolean.module';
import { TwoWaySwitchFormControlComponent } from './two-way-switch.form-control.component';
import { IsEmptyStringPipeModule } from '../is-empty-string-pipe/is-empty-string-pipe.module';
import { IsNilPipeModule } from '../is-nil-pipe/is-nil-pipe.module';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatTooltipModule,
    TranslocoModule,
    ToBooleanModule,
    IsEmptyStringPipeModule,
    IsNilPipeModule,
  ],
  declarations: [TwoWaySwitchFormControlComponent],
  exports: [TwoWaySwitchFormControlComponent],
})
export class TwoWaySwitchFormControlModule {}
