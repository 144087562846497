import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsPresentPipe } from './is-present.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [IsPresentPipe],
  exports: [IsPresentPipe],
})
export class IsPresentPipeModule {}
