import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CardContentLoadingBarComponent } from './card-content-loading-bar.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatProgressBarModule],
  declarations: [CardContentLoadingBarComponent],
  exports: [CardContentLoadingBarComponent],
})
export class CardContentLoadingBarModule {}
