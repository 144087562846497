import { MatSelect } from '@angular/material/select';
import { ChangeDetectorRef, Directive, EventEmitter, NgZone, OnInit, QueryList } from '@angular/core';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { AbstractEntityService, HttpListResponseModel } from '@roadrecord/utils';
import { Sort, SortDirection } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { untilDestroyed } from '@ngneat/until-destroy';
import { isFunction, isNil, isNumeric } from '@roadrecord/type-guard';
import { deepEqual } from '@roadrecord/common/common';
import { ENTER, hasModifierKey, SPACE } from '@angular/cdk/keycodes';
import { MatOption } from '@angular/material/core';

const defaultRemoteConfig: PageEvent & { hasNextDataPage: boolean; scrollFilterValue: string } = {
  pageIndex: 0,
  pageSize: 25,
  length: 1,
  hasNextDataPage: true,
  scrollFilterValue: undefined,
};

/**
 * Erre csak azert van szukseg, hogy jobban el tudjuk valasztani
 * az ngx mat search select kodjat es az infiniti scroll kodjat
 */
@Directive()
export abstract class LiveAutoCompleteBase<DATA_MODEL> implements OnInit {
  /**
   * @ViewChild()
   */
  abstract readonly matSelect: MatSelect;
  /**
   * @ViewChild()
   */
  abstract readonly ngxMatSelectSearch: { adjustScrollTopToFitActiveOptionIntoView: () => void };
  /**
   * control for the selected value
   */
  abstract readonly formControlRef: FormControl;
  /**
   * database-kent hasznalt service megadasa aminek a getAll metodusat hivjuk
   * @Input()
   */
  abstract readonly database: AbstractEntityService<HttpListResponseModel<DATA_MODEL>, DATA_MODEL>;
  /**
   * egyedi database megadasi lehetoseg, ha megvan adva a database is akkor ez az erosebb
   * @Input()
   */
  abstract readonly databaseCallback: (
    sort: Sort,
    page: PageEvent,
    simpleAllFilterValue: string
  ) => Observable<HttpListResponseModel<DATA_MODEL>>;
  /**
   * remote rendezes iranya
   * @Input()
   */
  abstract readonly sortDirection: SortDirection = 'asc';
  /**
   * ebbe taroljuk a megjelenitendo listat
   */
  list$ = new BehaviorSubject<DATA_MODEL[]>([]);
  /**
   * Azt jelezzuk hogy search van folyamatban
   */
  loading$ = new BehaviorSubject(true);
  noEntriesFoundLabel$ = new BehaviorSubject('LIVE_AUTO_COMPLETE.LOADING_ITEMS');
  /**
   * Kereso mezohoz kulon control kell az ngx mat select search-nek
   */
  readonly searchFormControl = new FormControl('');
  /**
   * @Output()
   */
  readonly openSearchPanel = new EventEmitter<void>();
  stopHttp$ = new Subject<void>();
  /**
   * Ebben taroljuk a jelenlegi lapozas adatait
   */
  remoteConfig: PageEvent & { hasNextDataPage: boolean };
  /**
   * Ha scrollozas tortenik akkor ezen a stream-en jelzunk
   */
  protected readonly doScroll$ = new Subject<void>();
  /**
   * Ha teljesen ujra akarjuk kezdeni a lapozast
   */
  protected readonly resetBatchOffset$ = new Subject<void>();
  protected runHasOneCheck = false;
  /**
   * Arra hasznaljuk, hogy taroljuk az elozo listat, ha szukseges valamiert vissza allni,
   * pl: Lassu net mellett felnyitjak a panelt, de gyorsan bezarjak(xhr nem fejezodik be), ilyenkor a regi listat vissza
   * kell rakni, hogy ki tudjon jelolodni a jelenlegi elem
   */
  private prevList: DATA_MODEL[];
  firstLoading = true;
  infiniteScrollDisabled = true;
  readonly newButtonRawValue = '__NEW_BUTTON__';
  abstract readonly notFoundEntityLabel: string;
  abstract newMatOptionButton: QueryList<MatOption>;
  abstract notFoundMatOption: QueryList<MatOption>;
  abstract pageSizeOverride: number | undefined;

  constructor(protected cdr: ChangeDetectorRef, protected ngZone: NgZone, protected window: Window) {
    this.resetRemoteConfig();
  }

  ngOnInit() {
    if (isNumeric(this.pageSizeOverride)) {
      this.remoteConfig.pageSize = this.pageSizeOverride;
    }

    this.matSeleckHack();

    // TODO config-ba + sort-t is!
    this.loadRemoteData();
  }

  public resetRemoteConfig() {
    this.remoteConfig = { ...defaultRemoteConfig };
    if (isNumeric(this.pageSizeOverride)) {
      this.remoteConfig.pageSize = this.pageSizeOverride;
    }
  }

  /**
   * Load the next batch
   */
  getNextBatch(): void {
    if (this.remoteConfig.hasNextDataPage) {
      this.doScroll$.next();
    }
  }

  protected abstract stopHasOneCheck(): void;

  /**
   * Mat select open es close metodust kell overridolni, mert
   * sajnos ha emiter-en varjuk meg az esemenyt akkor az mar tul keso nekunk
   */
  private matSeleckHack() {
    const _this = this;
    const originalMethod_handleOpenKeydown = (this.matSelect as any)._handleOpenKeydown;
    (this.matSelect as any)._handleOpenKeydown = function (event: KeyboardEvent) {
      const manager = this._keyManager;
      const keyCode = event.keyCode;
      if (
        (keyCode === ENTER || keyCode === SPACE) &&
        manager.activeItem &&
        !hasModifierKey(event) &&
        manager.activeItem.rawValue === _this.newButtonRawValue
      ) {
        _this.onClickNewButton();
      }
      originalMethod_handleOpenKeydown.call(this, event);
    };

    let prevSelected: unknown;
    const originMethodOpen = this.matSelect.open;
    const originMethodClose = this.matSelect.close;
    const originMethod_onFocus = this.matSelect._onFocus;
    this.matSelect.open = function () {
      if (this.disabled || !this.options || !this.options.length || this._panelOpen) {
        return;
      }

      // ez azert kell mert ha lassu net mellett vagyunk, akkor 2xer hivodik meg az open
      this._panelOpen = true;
      prevSelected = this._selectionModel.selected.length > 0 ? this._selectionModel.selected[0].rawValue : null;
      // reset states
      _this.openSearchPanel.emit();
      _this.stopHasOneCheck();
      _this.prevList = _this.list$.getValue();
      _this.list$.next([]);
      _this.resetBatchOffset$.next();
      // call original overrided method
      _this.formControlRef.markAllAsTouched();
      timer(200).subscribe(() => {
        // eredeti mukodeshez vissza allitjuk
        this._panelOpen = false;
        originMethodOpen.call(this);
        _this.infiniteScrollDisabled = false;
      });
    };

    this.matSelect.close = function () {
      if (this._panelOpen) {
        originMethodClose.call(this);
        if (
          _this.loading$.getValue() === true ||
          this._selectionModel.selected.length === 0 ||
          deepEqual(prevSelected, this._selectionModel.selected[0].rawValue)
        ) {
          _this.stopHttp$.next();
          // vissza rakjuk az elozo listat, igy ha volt ertek kijelolve akkor az megjelnik ujra
          _this.list$.next(_this.prevList);
        }
      }
      _this.firstLoading = true;
      _this.infiniteScrollDisabled = true;
    };

    this.matSelect._onFocus = function () {
      originMethod_onFocus.call(this);
      /**
       * Ha nincs kivalasztott elem, akkor a focus hatasara kinyitjuk a kereso panel-t
       */
      if (isNil(_this.formControlRef.value)) {
        this.open();
      }
    };
  }

  private loadRemoteData() {
    this.resetBatchOffset$
      .pipe(
        untilDestroyed(this),
        // kereso mezo becsatolasa
        switchMap(() => {
          if (this.runHasOneCheck) {
            this.stopHasOneCheck();
          }
          return this.searchFormControl.valueChanges.pipe(
            startWith(this.searchFormControl.value),
            debounceTime(300),
            distinctUntilChanged(),
            // azert kell, mert amikor bezarodik a panel akkor kiuritodik az control, de arra mi mar nem akarunk reagalni
            filter(() => this.matSelect.panelOpen),
            tap(() => {
              this.resetRemoteConfig();
              this.list$.next([]);
              this.noEntriesFoundLabel$.next('LIVE_AUTO_COMPLETE.LOADING_ITEMS');
            })
          );
        }),
        // scroll bekotese
        switchMap(searchText =>
          this.doScroll$.pipe(
            startWith(searchText),
            map(() => searchText)
          )
        ),
        tap(() => {
          this.loading$.next(true);
          this.cdr.markForCheck();
        }),
        // http hivas
        switchMap(searchText => {
          const obs = isFunction(this.databaseCallback)
            ? this.databaseCallback({ active: '', direction: this.sortDirection }, this.remoteConfig, searchText)
            : this.database.getAll(
                {
                  active: this.database.entityDefaultOrder,
                  direction: this.sortDirection,
                },
                this.remoteConfig,
                searchText
              );
          return obs.pipe(takeUntil(this.stopHttp$));
        }),
        // http valasz rendezese
        map((response: HttpListResponseModel<DATA_MODEL>) => {
          if (response.results.length === 0) {
            // ha a valaszban ures a lista
            this.remoteConfig.hasNextDataPage = false;
          } else {
            if (response.results.length < this.remoteConfig.pageSize) {
              // ha a valaszban kevesebb az egyed szam mint a kert lapozo meret akkor nincs kovetkezo page
              this.remoteConfig.hasNextDataPage = false;
            } else {
              // kovetkezo kereshez emeljuk az oldalszamot
              this.remoteConfig.pageIndex++;
            }
          }
          return response.results;
        }),
        // elozo allapot befuzese(scroll miatt kell)
        switchMap(response => this.list$.pipe(take(1)).pipe(map(prevList => [response, prevList])))
      )
      .subscribe(([response, prevList]) => {
        if (this.firstLoading) {
          this.firstLoading = false;
        }
        this.noEntriesFoundLabel$.next(this.notFoundEntityLabel);
        this.loading$.next(false);
        this.list$.next(prevList.concat(response));

        // /**
        //  * Ha nincs-enek elemek, akkor van uj letrehozas gomb es arra focusalni kell,
        //  * viszont a renderer-t ki kell varni
        //  */
        // if (this.list$.getValue().length === 0) {
        //   if (this.ngZone.isStable) {
        //     this.focusNewMaptButton();
        //   } else {
        //     this.ngZone.onStable.pipe(untilDestroyed(this), take(1)).subscribe(this.focusNewMaptButton.bind(this));
        //   }
        // }

        // cdk overlay fix
        this.window.dispatchEvent(new Event('resize'));
      });
  }

  // /**
  //  * Uj elem letrehozasara focusalunk es a not found elemrol a focus-t levesszuk
  //  */
  // private focusNewMaptButton() {
  //   if (this.newMatOptionButton.length === 1) {
  //     const newMatOption = this.newMatOptionButton.first;
  //     newMatOption.focus();
  //     newMatOption.setActiveStyles();
  //
  //     // itt valamiert ngZone-val nem ment :(
  //     timer(0).subscribe(this.unfocusNotFoundMatOption.bind(this));
  //   }
  // }
  //
  // private unfocusNotFoundMatOption() {
  //   this.notFoundMatOption.first.setInactiveStyles();
  //   this.cdr.detectChanges();
  // }

  abstract onClickNewButton();
}
