import { EventEmitter, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DEFAULT_ICONS } from '../consts/default-icons.const';
import { NotificationType } from '../enums/notification-type.enum';
import { Icons } from '../interfaces/icons';
import { NotificationEvent } from '../interfaces/notification-event.type';
import { Notification } from '../interfaces/notification.type';
import { NotificationComponent } from '../components/notification/notification.component';
import { isNil, isNotEmptyString } from '@roadrecord/type-guard';
import { TranslocoService } from '@ngneat/transloco';

let id = 0;

@Injectable()
export class NotificationsService {
  static get INSTANCE(): NotificationsService {
    return this._INSTANCE;
  }
  private static _INSTANCE: NotificationsService;

  emitter = new Subject<NotificationEvent>();
  icons: Icons = DEFAULT_ICONS;
  private _notificationComponents = new BehaviorSubject<NotificationComponent[]>([]);

  constructor(@Inject('options') public globalOptions: any, private translocoService: TranslocoService) {
    if (!isNil(NotificationsService._INSTANCE)) {
      throw new Error('Double init NotificationsService');
    }
    NotificationsService._INSTANCE = this;
  }

  get notificationComponents(): Observable<NotificationComponent[]> {
    return this._notificationComponents.asObservable();
  }

  setNotificationComponents(value: NotificationComponent[]) {
    this._notificationComponents.next(value);
  }

  searchNotificationComponentByAutoId(autoId: number): NotificationComponent {
    return this._notificationComponents.getValue().find(cmp => cmp.autoId === autoId);
  }

  searchNotificationComponentByRefRemove(notification: Notification): void {
    if (!isNil(notification)) {
      this.searchNotificationComponentByAutoIdAndRemove(notification.autoId);
    }
  }

  searchNotificationComponentByAutoIdAndRemove(autoId: number): void {
    const ref = this._notificationComponents.getValue().find(cmp => cmp.autoId === autoId);
    if (!isNil(ref)) {
      ref.remove();
    }
  }

  set(notification: Notification, to: boolean): Notification {
    notification.autoId = ++id;
    notification.id =
      notification.override && notification.override.id ? notification.override.id : Math.random().toString(36).substring(3);
    notification.click = new EventEmitter<{}>();
    notification.clickIcon = new EventEmitter<{}>();
    notification.timeoutEnd = new EventEmitter<{}>();

    this.emitter.next({ command: 'set', notification, add: to });
    return notification;
  }

  success(title: string = '', content: string = '', override?: Partial<Notification>, context?: any): Notification {
    return this.set(
      {
        title,
        content: content || '',
        type: NotificationType.Success,
        icon: this.icons.success,
        override,
        context,
        autoId: ++id,
      },
      true
    );
  }

  error(title: string = '', content: string = '', override?: Partial<Notification>, context?: any): Notification {
    return this.set(
      {
        title,
        content: content || '',
        type: NotificationType.Error,
        icon: this.icons.error,
        override,
        context,
      },
      true
    );
  }

  alert(title: string = '', content: string = '', override?: Partial<Notification>, context?: any): Notification {
    return this.set(
      {
        title,
        content: content || '',
        type: NotificationType.Alert,
        icon: this.icons.alert,
        override,
        context,
      },
      true
    );
  }

  info(title: string = '', content: string = '', override?: Partial<Notification>, context?: any): Notification {
    if (isNotEmptyString(title)) {
      title = this.translocoService.translate(
        title,
        !isNil(override) && !isNil(override.titleTranslateParams) ? override.titleTranslateParams : undefined
      );
    }
    content = this.translocoService.translate(
      content,
      !isNil(override) && !isNil(override.contentTranslateParams) ? override.contentTranslateParams : undefined
    );
    return this.set(
      {
        title,
        content: content || '',
        type: NotificationType.Info,
        icon: this.icons.info,
        override,
        context,
      },
      true
    );
  }

  warn(title: string = '', content: string = '', override?: Partial<Notification>, context?: any): Notification {
    if (isNotEmptyString(title)) {
      title = this.translocoService.translate(
        title,
        !isNil(override) && !isNil(override.titleTranslateParams) ? override.titleTranslateParams : undefined
      );
    }
    content = this.translocoService.translate(
      content,
      !isNil(override) && !isNil(override.contentTranslateParams) ? override.contentTranslateParams : undefined
    );
    return this.set(
      {
        title,
        content: content || '',
        type: NotificationType.Warn,
        icon: this.icons.warn,
        override,
        context,
      },
      true
    );
  }

  bare(title: string = '', content: string = '', override?: Partial<Notification>, context?: any): Notification {
    return this.set(
      {
        title,
        content: content || '',
        type: NotificationType.Bare,
        icon: 'bare',
        override,
        context,
      },
      true
    );
  }

  // With type method
  create(title: any = '', content: any = '', type = NotificationType.Success, override?: any, context?: any): Notification {
    return this.set({ title, content, type, icon: (this.icons as any)[type], override, context, autoId: ++id }, true);
  }

  // HTML Notification method
  html(html: any, type = NotificationType.Success, override?: any, icon = 'bare', context?: any): Notification {
    return this.set({ html, type, icon: (this.icons as any)[icon], override, context, autoId: ++id }, true);
  }

  // Remove all notifications method
  remove(_id?: string): void {
    if (_id) {
      this.emitter.next({ command: 'clean', id: _id });
    } else {
      this.emitter.next({ command: 'cleanAll' });
    }
  }
}
