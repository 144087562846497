import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { hasDeletable, hasEditable, IS_DELETABLE_KEY, IS_EDITABLE_KEY } from '@roadrecord/common/common';
import { ENTITY_SERVICE_TOKEN } from '../../../../entity/service/entity-service.token';
import { AbstractEntityService, PATCH_NOT_MODIFIED } from '../../../../entity/service/abstract-entity.service';
import { FieldHttpError } from '../../../../http/exception/field-http-error';
import { HttpListResponseModel } from '../../../../http/model/http-list-response.model';
import { isHandledError } from '../../../../http/function/is-handled-error.function';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { isFunction, isNil } from '@roadrecord/type-guard';
import { Observable } from 'rxjs';
import { handleFormControlsServerError } from '../../../function/handle-form-controls-server-error.function';
import { CheckModifiedMVPFormGuard } from '../../../guard/check-modified-mvp-form-guard.service';
import { PRESENTER_COMPONENT, PresenterStateController } from '../../presenter-state/presenter-state.controller';
import { ViewRemoteErrorsModel } from '../view-remote-field-error/model/view-remote-errors.model';
import { ViewRemoteFieldErrorPlugin } from '../view-remote-field-error/view-remote-field-error.plugin';
import { handleServerError } from './handle-server-error.function';
import {
  PRESENTER_SAVE_PLUGIN_OPTIONS_TOKEN,
  PresenterSavePluginOptionsModel,
  PresenterSaveSaveDataCallbackStrategy,
} from './model/presenter-save-plugin-options.model';
import { SaveModel } from './model/save.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  PRESENTER_SAVE_SAVE_ACTION_SNACKBAR_MSG_CONFIG,
  PresenterSaveSaveActionSnackbarMsgConfigToken,
} from './presenter-save-save-action-snackbar-msg-config.token';
import { IS_WEBADMIN } from '../../../../app-type/is-webadmin.provider.token';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

export type FinishType = (errors?: any, loading?: boolean) => void;

@UntilDestroy()
@Injectable()
/**
 * ez az osztaly felelos a hivo (altalaban details route) altalanos submit kezelesert
 * eldonti hogy create vagy update legyen es meghivja az entitashoz tartozo service-t
 * server valasztol fuggoen hibat kezel es tovabb adja ha kell RemoteFieldErrorController-nek
 * vagy sikeres mentes eseten redirecttel attol fuggoen hogy sima mentes vagy mentes uj lett nyomva
 *
 * @template MODEL, IDTYPE
 */
export class PresenterSavePluginController<MODEL, IDTYPE> {
  private currentScope: any;

  constructor(
    private readonly presenterStateController: PresenterStateController<MODEL, IDTYPE>,
    @Inject(ENTITY_SERVICE_TOKEN) private readonly service: AbstractEntityService<HttpListResponseModel<MODEL>, MODEL>,
    private readonly translocoService: TranslocoService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    @Optional()
    @Inject(PRESENTER_SAVE_PLUGIN_OPTIONS_TOKEN)
    private options: PresenterSavePluginOptionsModel,
    @Optional() private gtmService: GoogleTagManagerService,
    @Optional() private readonly checkModifiedMVPFormGuard: CheckModifiedMVPFormGuard,
    private readonly store: Store,
    private messageDialogService: MessageDialogService,
    @Optional()
    @Inject(PRESENTER_SAVE_SAVE_ACTION_SNACKBAR_MSG_CONFIG)
    private saveActionSnackbarMsgConfig: PresenterSaveSaveActionSnackbarMsgConfigToken
  ) {
    if (!isNil(this.options)) {
      if (!(this.options instanceof PresenterSavePluginOptionsModel)) {
        throw new Error('Wrong options type! Required Presenter save options');
      }
    } else {
      this.options = new PresenterSavePluginOptionsModel();
    }
    this.registerPresenter();
  }

  // form submit hivasa
  saveData(saveModel: SaveModel<MODEL>, ok?: (response) => void, error?: (error) => void): void {
    const matSnackBar: MatSnackBar = this.presenterStateController.get(MatSnackBar);
    const firstStepsFinishedState =
      this.presenterStateController.get(IS_WEBADMIN) || this.store.selectSnapshot(states => states.firstSteps.finished);
    const firstStepsMode = firstStepsFinishedState === undefined || firstStepsFinishedState === false;
    this.presenterStateController.loading$.next(true);
    let httpMethod: Observable<HttpResponse<any>>;
    const model: any = saveModel.model;

    /**
     * eredeti model
     */
    const localDataModel: any = this.presenterStateController.editModelLastValue;
    let id = this.service.getModelIdValue(localDataModel);
    let _dataModel: MODEL & { id: number };

    if (id === undefined) {
      if (!isNil(matSnackBar) && !firstStepsMode) {
        matSnackBar.open(this.translocoService.translate('COMMON.SNACKBAR.NEW_START'));
      }

      _dataModel = this.removeFields(
        localDataModel !== undefined ? { ...localDataModel, ...model } : model,
        saveModel.removeFieldsBeforeSave
      );

      httpMethod = this.service.createStreamResponse(
        _dataModel,
        isFunction(this.options.sendSetQueryParams) ? this.options.sendSetQueryParams.call(this.currentScope, 'CREATE') : undefined
      );
    } else {
      if (!isNil(matSnackBar)) {
        matSnackBar.open(this.translocoService.translate('COMMON.SNACKBAR.MODIFY_START'));
      }

      const mergedDataModel = { ...localDataModel, ...model };

      if (!Array.isArray(saveModel.removeFieldsBeforeSave)) {
        saveModel.removeFieldsBeforeSave = [];
      }

      this.removeWhenHasIsEditableField(mergedDataModel, saveModel);
      this.removeWhenHasIsDeletableField(mergedDataModel, saveModel);
      _dataModel = this.removeFields(mergedDataModel, saveModel.removeFieldsBeforeSave);

      if (this.options.modifyPatchMode) {
        httpMethod = this.service.patchStreamResponseWithAutoDifferenceCalculation(
          id,
          _dataModel,
          localDataModel,
          this.options.patchRemoveFieldsBeforeDiff,
          isFunction(this.options.sendSetQueryParams) ? this.options.sendSetQueryParams.call(this.currentScope, 'MODIFY') : undefined
        );
      } else {
        httpMethod = this.service.updateStreamResponse(
          id,
          _dataModel,
          isFunction(this.options.sendSetQueryParams) ? this.options.sendSetQueryParams.call(this.currentScope, 'MODIFY') : undefined
        );
      }
    }
    httpMethod.pipe(untilDestroyed(this)).subscribe(
      responseOk => {
        // ha van id akkor modify uzenet ha nincs akkor uj letrehozasa
        const isNew = id === undefined;
        if (!isNil(this.gtmService)) {
          if (id === undefined) {
            _dataModel.id = +responseOk.headers.get('entity_id');
            id = _dataModel.id;
          }
        }
        if (ok !== undefined) {
          ok.call(this.currentScope, _dataModel, saveModel);
        }
        const hasChange = (responseOk as any) !== PATCH_NOT_MODIFIED;
        if (!firstStepsMode) {
          this.displaySnackbarNewOrModifySaveMessage(matSnackBar, isNew, hasChange);
        }

        if (saveModel.reset) {
          this.presenterStateController.editModel$.next(undefined);
          this.presenterStateController.loading$.next(false);
          const lastPathSegment = this.route.snapshot.url[this.route.snapshot.url.length - 1];
          if (lastPathSegment.path === 'new') {
            this.presenterStateController.refreshModelCommand$.next();
          } else {
            this.checkModifiedGuardDisableNextCheck();
            this.router.navigate(['../new'], {
              relativeTo: this.route,
              // replaceUrl: true
            });
          }
        } else if (saveModel.redirect) {
          let routingWhenSaveAndNotReset: boolean;
          if (isFunction(this.options.routingWhenSaveAndNotReset)) {
            routingWhenSaveAndNotReset = this.options.routingWhenSaveAndNotReset.call(this.currentScope);
          } else {
            routingWhenSaveAndNotReset = this.options.routingWhenSaveAndNotReset;
          }
          if (routingWhenSaveAndNotReset === true) {
            this.checkModifiedGuardDisableNextCheck();

            const backUrl = this.route.snapshot.queryParams.back_url;

            if (!isNil(this.route.snapshot.queryParams.back_url)) {
              this.router.navigate([backUrl], { relativeTo: this.route });
            } else {
              this.router.navigate(['../'], {
                ...(!isNil(this.options.routingModifyExtraParamsCb)
                  ? this.currentScope[this.options.routingModifyExtraParamsCb as string]()
                  : !isNil(this.options.routingModifyExtraParams)
                  ? this.options.routingModifyExtraParams
                  : undefined),
                relativeTo: this.route /*, replaceUrl: true */,
              });
            }
          }
        } else {
          // patch eseten
          this.presenterStateController.loading$.next(false);
          this.presenterStateController.formGroupLastValue.enable();
          if (hasChange) {
            // csak ha van valtozas akkor kell refresh model
            // not redirect, just save(modify modban lehetseges csak)
            this.presenterStateController.refreshModelCommand$.next(_dataModel);
          }
        }
      },
      responseError => {
        if (!isNil(matSnackBar)) {
          matSnackBar.dismiss();
        }
        const viewRemoteFieldErrorController: ViewRemoteFieldErrorPlugin<MODEL, IDTYPE> = this.presenterStateController.get(
          ViewRemoteFieldErrorPlugin
        );
        if (viewRemoteFieldErrorController !== undefined) {
          // binded variable reset
          viewRemoteFieldErrorController.setRemoteErrors(undefined);
          if (!isNil(matSnackBar)) {
            matSnackBar.open(
              this.translocoService.translate('COMMON.SNACKBAR.SAVE_FIELD_ERROR'),
              this.translocoService.translate('COMMON.ACTION.CLOSE_LOWER')
            );
          }
        }

        if (isHandledError(responseError)) {
          if (responseError instanceof FieldHttpError) {
            // responseError['error'] !== undefined && responseError['error']['errors'] !== undefined) {
            // form field validation error
            handleFormControlsServerError(responseError, this.finish.bind(this));
          } else {
            this.finish();
          }
        } else {
          // common finish method
          // check is generic or form field validation error
          handleServerError(responseError, this.translocoService, this.finish.bind(this), this.messageDialogService);
        }
        // check has parameter error callback
        if (error !== undefined) {
          error.call(this.currentScope, responseError);
        }
      }
    );
  }

  private checkModifiedGuardDisableNextCheck(): void {
    if (this.checkModifiedMVPFormGuard !== undefined) {
      this.checkModifiedMVPFormGuard.disableNextCheck = true;
    }
  }

  private removeWhenHasIsDeletableField(mergedDataModel: MODEL & any, saveModel: SaveModel<MODEL>): void {
    if (hasDeletable(mergedDataModel) && !hasDeletable(saveModel.removeFieldsBeforeSave)) {
      saveModel.removeFieldsBeforeSave.push(IS_DELETABLE_KEY);
    }
  }

  private removeWhenHasIsEditableField(mergedDataModel: MODEL & any, saveModel: SaveModel<MODEL>): void {
    if (hasEditable(mergedDataModel) && !hasEditable(saveModel.removeFieldsBeforeSave)) {
      saveModel.removeFieldsBeforeSave.push(IS_EDITABLE_KEY);
    }
  }

  private displaySnackbarNewOrModifySaveMessage(matSnackBar: MatSnackBar, isNew: boolean, hasChange: boolean): void {
    if (!isNil(matSnackBar)) {
      let createKey = 'COMMON.SNACKBAR.NEW_SUCCESS';
      let modifyKey = 'COMMON.SNACKBAR.MODIFY_SUCCESS';
      const noChangesKey = 'COMMON.SNACKBAR.NO_CHANGES';
      if (!isNil(this.saveActionSnackbarMsgConfig)) {
        if (!isNil(this.saveActionSnackbarMsgConfig.create)) {
          createKey = this.saveActionSnackbarMsgConfig.create;
        }
        if (!isNil(this.saveActionSnackbarMsgConfig.modify)) {
          modifyKey = this.saveActionSnackbarMsgConfig.modify;
        }
      }
      // ha van snackbar
      matSnackBar.open(this.translocoService.translate(!hasChange ? noChangesKey : !isNew ? modifyKey : createKey));

      // Ha van router akkor a route valtast is figyeljuk, mivel
      // ha route-t valtunk akkor az uzenet mar nem relevans
      // const router: Router = this.presenterStateController.get(Router);
      // let routerNavigationEndSubscription: Subscription;
      // if (!isNil(router)) {
      //   routerNavigationEndSubscription = router.events
      //     .pipe(
      //       filter(event => event instanceof NavigationEnd),
      //       /**
      //        * azert kell a second mert az
      //        * elso redirect a mentes utan a lista vagy ahova kertuk,
      //        * igy a masodik redirect amit figyelnunk kell hisz akkor tuti ki megy abbol
      //        * a layoutbol amiben volt
      //        */
      //       second()
      //     )
      //     .subscribe(() => /*snackbarRef.dismiss()*/ {});
      // }
      // amikor eltunik a snackbar akkor le kell iratkozni a router esemenyrol
      // snackbarRef.afterDismissed().subscribe(() => {
      //   if (!routerNavigationEndSubscription.closed) {
      //     routerNavigationEndSubscription.unsubscribe();
      //   }
      // });
    }
  }

  private registerPresenter(): void {
    // tslint:disable:no-invalid-this
    const presenter: any = this.presenterStateController.get(PRESENTER_COMPONENT);
    // TODO options hogy felul lehessen irni a metodus neveket
    const originalOnSubmitKey = `ORIGINAL_${this.options.bindOnSubmitMethodName}`;
    let onSubmitOriginalMethod;
    if (!isNil(presenter.prototype)) {
      onSubmitOriginalMethod = !isNil(presenter.prototype[originalOnSubmitKey])
        ? presenter.prototype[originalOnSubmitKey]
        : presenter.prototype[this.options.bindOnSubmitMethodName];
      if (presenter.prototype[originalOnSubmitKey] === undefined) {
        presenter.prototype[originalOnSubmitKey] = onSubmitOriginalMethod;
      }
    } else {
      onSubmitOriginalMethod = !isNil(presenter[originalOnSubmitKey])
        ? presenter[originalOnSubmitKey]
        : presenter[this.options.bindOnSubmitMethodName];
      if (presenter[originalOnSubmitKey] === undefined) {
        presenter[originalOnSubmitKey] = onSubmitOriginalMethod;
      }
    }

    if (onSubmitOriginalMethod === undefined) {
      throw new Error('Not found onSubmit');
    }
    const __this = this;
    const newFunction = function (): void {
      __this.currentScope = this;
      let responseOkCallback;
      let responseErrorCallback;

      if (!isNil(presenter.prototype)) {
        if (!isNil(__this.options.responseOkCallback)) {
          const responseOkCallbackName = isFunction(__this.options.responseOkCallback)
            ? __this.options.responseOkCallback.call(this)
            : __this.options.responseOkCallback;

          if (!isNil(presenter.prototype[responseOkCallbackName])) {
            responseOkCallback = presenter.prototype[responseOkCallbackName];
          }
        }
        if (!isNil(__this.options.responseErrorCallback)) {
          const responseErrorCallbackName = isFunction(__this.options.responseErrorCallback)
            ? __this.options.responseErrorCallback.call(this)
            : __this.options.responseErrorCallback;

          if (!isNil(presenter.prototype[responseErrorCallbackName])) {
            responseErrorCallback = presenter.prototype[responseErrorCallbackName];
          }
        }
      } else {
        if (!isNil(__this.options.responseOkCallback)) {
          const responseOkCallbackName = isFunction(__this.options.responseOkCallback)
            ? __this.options.responseOkCallback.call(this)
            : __this.options.responseOkCallback;

          if (!isNil(presenter[responseOkCallbackName])) {
            responseOkCallback = presenter[responseOkCallbackName];
          }
        }
        if (!isNil(__this.options.responseErrorCallback)) {
          const responseErrorCallbackName = isFunction(__this.options.responseErrorCallback)
            ? __this.options.responseErrorCallback.call(this)
            : __this.options.responseErrorCallback;

          if (!isNil(presenter[responseErrorCallbackName])) {
            responseErrorCallback = presenter[responseErrorCallbackName];
          }
        }
      }
      if (__this.options.presenterSaveSaveDataCallbackStrategy === PresenterSaveSaveDataCallbackStrategy.BEFORE_RUN_PLUGIN) {
        onSubmitOriginalMethod.call(this, arguments[0]);
        // TODO submit model check (new -val kell letrehozni!)
        __this.saveData(arguments[0], responseOkCallback, responseErrorCallback);
      } else {
        // TODO submit model check (new -val kell letrehozni!)
        __this.saveData(arguments[0], responseOkCallback, responseErrorCallback);
        onSubmitOriginalMethod.call(this, arguments[0]);
      }
    };

    if (!isNil(presenter.prototype)) {
      presenter.prototype[this.options.bindOnSubmitMethodName] = newFunction;
    } else {
      presenter[this.options.bindOnSubmitMethodName] = newFunction;
    }
  }

  private finish(errors?: ViewRemoteErrorsModel, loading = false): void {
    if (!isNil(errors)) {
      const viewRemoteFieldErrorController: ViewRemoteFieldErrorPlugin<MODEL, IDTYPE> = this.presenterStateController.get(
        ViewRemoteFieldErrorPlugin
      );
      if (viewRemoteFieldErrorController !== undefined) {
        viewRemoteFieldErrorController.setRemoteErrors(errors);
      }
    } else {
      this.presenterStateController.formGroupLastValue.enable();
    }
    this.presenterStateController.loading$.next(loading);
  }

  /**
   * atadott delete properties alapjan az adat model-bol torli a kert kulcsokat
   */
  private removeFields(dataModel: MODEL & { id: number }, deleteProperties: string[]): MODEL & { id: number } {
    if (Array.isArray(deleteProperties)) {
      deleteProperties.forEach(propertyName => delete dataModel[propertyName]);
    }
    return dataModel;
  }
}
