import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { PriceService } from '../price.service';
import { MagicLinkModel, NavigateEventType, PaymentRedirectResponseModel, PriceItemModel } from '../model/models';
import { PriceStepEnum } from '../model/price-step.enum';
import { MaybeHandleHttpError } from '@roadrecord/utils';
import { ActivatedRoute } from '@angular/router';
import { isEmpty, isNil } from '@roadrecord/type-guard';
import { PriceCategoryStatus, PriceCategoryURLStatus } from '../model/price-category-type.enum';
import { BehaviorSubject } from 'rxjs';
import { MagicLinkPath } from '../model/local-storage.enum';
import { ViewportService } from '@roadrecord/common/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'rr-order-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [PriceService],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderLayoutComponent implements OnInit {
  @Input()
  showInStepper = false;

  @Output() backToStepper = new EventEmitter<void>();

  priceList: PriceItemModel[] = [];
  selectedPricePackage: PriceItemModel = null;

  isBasicPrice = true;
  readonly magicId: string;
  readonly magicUUID: string;
  readonly PriceStepEnum = PriceStepEnum;
  orderStep: PriceStepEnum = PriceStepEnum.PACKAGE_LIST;

  loading$ = new BehaviorSubject(false);

  isOnSale = false;

  constructor(
    private route: ActivatedRoute,
    private priceService: PriceService,
    private renderer: Renderer2,
    private el: ElementRef,
    readonly viewportService: ViewportService
  ) {
    this.magicId = this.route.snapshot.queryParamMap.get('id');
    this.magicUUID = this.route.snapshot.queryParamMap.get('uuid');

    if (!isNil(this.magicId) && !isEmpty(this.magicId) && !isNil(this.magicUUID) && !isEmpty(this.magicUUID)) {
      const magicData: MagicLinkModel = {
        id: this.magicId,
        uuid: this.magicUUID,
      };
      this.isBasicPrice = false;
      localStorage.setItem(MagicLinkPath.MAGIC_LINK_PATH, JSON.stringify(magicData));
    } else {
      this.isBasicPrice = true;
    }
    this.loading$.next(true);
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      const packageData = params['package_data'];
      const categoryType = params['category_type'];

      if (!isNil(categoryType) && !isEmpty(categoryType)) {
        if (categoryType === PriceCategoryURLStatus.ON_SALE) {
          this.priceService.product_category = PriceCategoryStatus.ON_SALE;
          this.isOnSale = true;
        } else {
          this.priceService.product_category = PriceCategoryStatus.BASIC;
          this.isOnSale = false;
        }
      } else {
        this.priceService.product_category = PriceCategoryStatus.BASIC;
        this.isOnSale = false;
      }

      if (!isNil(packageData)) {
        const packageDataObject = JSON.parse(packageData) as PaymentRedirectResponseModel;
        this.priceService.getSelectedPackageInformation(packageDataObject.product_id).subscribe(packageInfo => {
          this.priceService.product_category =
            packageInfo.product_category.id === PriceCategoryStatus.ON_SALE ? PriceCategoryStatus.ON_SALE : PriceCategoryStatus.BASIC;
          this.getPriceLists();
        });
      } else {
        this.getPriceLists();
      }
    });

    if (!isNil(this.magicId) && !isEmpty(this.magicId) && !isNil(this.magicUUID) && !isEmpty(this.magicUUID)) {
      this.renderer.setStyle(this.el.nativeElement, 'display', 'block');
      this.renderer.setStyle(this.el.nativeElement, 'overflow', 'auto');
      this.renderer.setStyle(this.el.nativeElement, 'padding-bottom', '50px');
      this.renderer.setStyle(this.el.nativeElement, 'height', '-webkit-fill-available');
    }
  }

  navigateEvent(data: NavigateEventType) {
    switch (data.step) {
      case PriceStepEnum.PACKAGE_LIST:
        break;
      case PriceStepEnum.ORDER_FORM:
        this.selectedPricePackage = data.data as PriceItemModel;
        break;
    }
    this.orderStep = data.step;
  }

  private getPriceLists(): void {
    this.priceService.getPrices().subscribe(
      response => {
        this.priceList = response.results;
        this.loading$.next(false);
      },
      error => {
        console.error(error);
        this.loading$.next(false);
        MaybeHandleHttpError.maybeHandleHttpError(error);
      }
    );
  }

  onBackToStepper(): void {
    this.backToStepper.emit();
  }
}
