import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';

@Component({
  selector: 'rr-fake-calendar-header',
  templateUrl: './fake-calendar-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FakeCalendarHeaderComponent {
  constructor(
    private calendarCmp: MatCalendar<any>,
    private dateAdapter: DateAdapter<any>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    private cdr: ChangeDetectorRef
  ) {}

  get year() {
    return this.dateAdapter.format(this.calendarCmp.activeDate, 'YYYY');
  }

  onClickOpenYearSelect() {
    this.calendarCmp.currentView = 'multi-year';
    this.cdr.markForCheck();
  }

  onClickPrevYear() {
    this.setNewDate(-1);
  }

  onClickNextYear() {
    this.setNewDate(1);
  }

  private setNewDate(direction: 1 | -1) {
    this.calendarCmp.activeDate = this.dateAdapter.addCalendarYears(this.calendarCmp.activeDate, direction);
  }
}
