import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CallbackOptions, IntrojsService, Step } from '../introjs.service';
import { INTROJS_DEFAULT_OPTIONS, introjsDefaultOptions } from '../introjs-default.options';
import { tsDeepcopy } from '@roadrecord/ts-deepcopy';
import { Options } from 'intro.js';

@Component({
  selector: 'rr-introjs',
  templateUrl: './introjs.component.html',
  styleUrls: ['./introjs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  /**
   * Igy tudtuk megoldani hogy az introjs fo css fajlt ne toltsuk be angular.json-ben
   */
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: INTROJS_DEFAULT_OPTIONS, useValue: introjsDefaultOptions }, IntrojsService],
})
export class IntrojsComponent implements OnInit {
  @Input() steps: Step[];
  @Input() globalOptions: Options | null = null;
  @Input() callbacksOptions: CallbackOptions;
  @Input() autoStart = true;

  constructor(private introjsService: IntrojsService) {}

  ngOnInit() {
    if (this.autoStart) {
      this.start();
    }
  }

  start() {
    this.introjsService.start(tsDeepcopy(this.steps), this.callbacksOptions, this.globalOptions);
  }
}
