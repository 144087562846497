<p class="mt-1 mb-0">{{ 'PARTNER.LIST.BULK_EDIT_TRIP_REASON_BOTTOM_SHEET.CONTENT' | transloco }}</p>
<rr-live-auto-complete
  name="trip_reason"
  required
  [formControlRef]="tripReasonControl"
  [database]="tripReasonService"
  hasPrefix="true"
  hasNewButton="true"
  [optionsConfig]="laTripReasonOptionsConfig"
  placeholder="PARTNER.DETAILS.DATA_FORM.LABEL.TRIP_REASON"
  [validationMessages]="laTripReasonOptionsConfig.validatorMessages"
></rr-live-auto-complete>
