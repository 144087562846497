import { Injectable } from '@angular/core';
import { GetResult, load } from '@fingerprintjs/fingerprintjs';

@Injectable()
export class FingerprintService {
  private static CODE: GetResult = null;

  constructor() {
    if (FingerprintService.CODE === null) {
      load().then(fp => {
        return fp.get().then(code => {
          return (FingerprintService.CODE = code);
        });
      });
    }
  }

  getCode() {
    return FingerprintService.CODE.visitorId;
  }
}
