import { isNil } from '@roadrecord/type-guard';
import { finalizationOfRouteImportAction } from '../details/import/window-content/window-content.action';
import { finalizationOfRouteImportEntityDefaultOrder } from '../details/import/finalization-of-route-universal-import.service';
import { UniversalImportService } from '@roadrecord/universal-import/common';
import { PreferencesContentWindowTypeEnum, PreferencesState } from '@roadrecord/preferences-state/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadableService } from '@roadrecord/external-packages/ngx-loadable';
import { Store } from '@ngxs/store';
import { Injector } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FinalizationOfRouteService } from '../finalization-of-route.service';
import { SplitButtonAction } from '@roadrecord/split-button';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { RRCalendarHeaderButton } from '@roadrecord/calendar';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { generateRecommendationButtonTooltip } from './generate-recommendation-button-tooltip';
import { clickFixImportRightMenuButton } from './click-fix-import.function';

export function setFinalizationOfRouteTopRightButtons(
  _this: {
    topRightMenuExtraButton: RRCalendarHeaderButton;
    topRightMenuAfterButtons: SplitButtonAction[];
    onClickRecommendation: () => void;
    calendarComponent: any;
    universalImportService: UniversalImportService<any>;
    router: Router;
    route: ActivatedRoute;
    store: Store;
    loadableService: LoadableService;
    injector: Injector;
    translocoService: TranslocoService;
    matSnackBar: MatSnackBar;
    service: FinalizationOfRouteService;
    clearDailyPermanentDestination: () => void;
    justFixRoad: () => void;
    openCopyDaysWindow: () => void;
    openFuelingRangeWindow: () => void;
    openMonthStatusListWindow: () => void;
    periodContextIsPrivate?: boolean;
    gotoAnonymPartnersRename: () => void;
    periodContextStateSelectorsService?: PeriodContextStateSelectorsService<any, any>;
    isSMRVehicle?: () => boolean;
  },
  importRoutePrefix = ''
) {
  const isdisabled = () => (!isNil(_this.calendarComponent) ? _this.calendarComponent.isLoading : false);

  const keyPath = 'FINALIZATION_OF_ROUTE.CALENDAR.HEADER.TOP_RIGHT_MENU.RECOMMENDATION.TOOLTIP.';
  const periodContext = _this.store.selectSnapshot(_this.periodContextStateSelectorsService.context);

  const toolTipMessage = _this.translocoService.translate(
    `${keyPath}${generateRecommendationButtonTooltip(_this.store, _this.periodContextStateSelectorsService, periodContext)}`
  );

  _this.topRightMenuExtraButton = {
    label: 'FINALIZATION_OF_ROUTE.CALENDAR.HEADER.TOP_RIGHT_MENU.RECOMMENDATION.LABEL',
    click: () => (isdisabled() ? undefined : _this.onClickRecommendation()),
    isDisabled: isdisabled,
    icon: undefined,
    cssClass: 'recommendation-button',
    toolTip: toolTipMessage,
  };

  // Amig az osszevonas nem er veget addig, init-et atraktuk a valtozo init-be
  _this.topRightMenuAfterButtons = [];

  _this.topRightMenuAfterButtons.push(
    {
      id: 'finalization_of_routes_finalization_of_routes_import_menu_item',
      type: 'ITEM',
      label: { translate: 'FINALIZATION_OF_ROUTE.CALENDAR.HEADER.TOP_RIGHT_MENU.IMPORT.LABEL' },
      icon: 'cloud-upload',
      action: () => {
        _this.calendarComponent.showLoading();
        clickFixImportRightMenuButton(
          _this.universalImportService,
          finalizationOfRouteImportEntityDefaultOrder,
          importRoutePrefix,
          _this.router,
          _this.route
        );
      },
    },
    {
      type: 'ITEM',
      label: { translate: 'FINALIZATION_OF_ROUTE.CALENDAR.HEADER.TOP_RIGHT_MENU.IMPORT_LIST_WINDOW.LABEL' },
      icon: 'format-list-checks',
      action: () =>
        finalizationOfRouteImportAction(_this.store, _this.loadableService, _this.injector, _this.translocoService, _this.matSnackBar),
      disabled: () =>
        _this.store.selectSnapshot(PreferencesState.hasOpenedContentWindow)(
          PreferencesContentWindowTypeEnum.FINALIZATION_OF_ROUTE_IMPORT_LIST
        ) || _this.calendarComponent.isLoading,
    },
    {
      type: 'ITEM',
      label: { translate: 'FINALIZATION_OF_ROUTE.CALENDAR.HEADER.TOP_RIGHT_MENU.IMPORT_DELETE.LABEL' },
      icon: 'delete',
      action: () => {
        _this.calendarComponent.showLoading();
        _this.service.importedRoutesDelete().subscribe(() => _this.calendarComponent.refresh());
      },
      disabled: () => _this.calendarComponent.isLoading,
    },
    // new RRCalendarTopRightMenuDividerOption(),
    { type: 'DIVIDER' },
    {
      type: 'ITEM',
      label: { translate: 'FINALIZATION_OF_ROUTE.CALENDAR.HEADER.TOP_RIGHT_MENU.RENAME_ANONYM_PARTNERS.LABEL' },
      icon: 'rename-box',
      action: () => _this.gotoAnonymPartnersRename(),
      disabled: () => _this.calendarComponent.isLoading,
    },
    {
      type: 'ITEM',
      label: { translate: 'FINALIZATION_OF_ROUTE.CALENDAR.HEADER.TOP_RIGHT_MENU.CLEAR_DAILY_PERMANENT_DESTINATION.LABEL' },
      icon: 'reload',
      action: () => _this.clearDailyPermanentDestination(),
      disabled: () => _this.calendarComponent.isLoading,
    }
  );

  if (isNil(_this.periodContextIsPrivate) || _this.periodContextIsPrivate === false) {
    _this.topRightMenuAfterButtons.push(
      // new RRCalendarTopRightMenuDividerOption(),
      { type: 'DIVIDER' },
      {
        type: 'ITEM',
        label: { translate: 'FINALIZATION_OF_ROUTE.CALENDAR.HEADER.TOP_RIGHT_MENU.FUELING_RANGE.LABEL' },
        icon: 'map-marker-distance',
        action: () => _this.openFuelingRangeWindow(),
        disabled: () =>
          _this.store.selectSnapshot(PreferencesState.hasOpenedContentWindow)(PreferencesContentWindowTypeEnum.FUELING_RANGE) ||
          _this.calendarComponent.isLoading,
      },
      {
        type: 'ITEM',
        label: { translate: 'MILEAGE.LIST_WINDOW_CONTENT.HEADER_TITLE' },
        icon: 'clock-end',
        action: () => _this.openMonthStatusListWindow(),
        disabled: () =>
          _this.store.selectSnapshot(PreferencesState.hasOpenedContentWindow)(PreferencesContentWindowTypeEnum.MONTH_STATUS_LIST) ||
          _this.calendarComponent.isLoading,
      }
    );

    if (isNil(_this.isSMRVehicle) || _this.isSMRVehicle() === false) {
      _this.topRightMenuAfterButtons.push({
        type: 'ITEM',
        label: { translate: 'FUELING_RANGE.MONTHLY_DISTANCE_CORRECTION' },
        icon: 'scale-balance',
        action: () => _this.service.startCorrection(),
        disabled: () => _this.calendarComponent.isLoading,
      });
    }
  }

  _this.topRightMenuAfterButtons.push(
    { type: 'DIVIDER' },
    {
      type: 'ITEM',
      label: { translate: 'FINALIZATION_OF_ROUTE.CALENDAR.HEADER.TOP_RIGHT_MENU.COPY_DAYS.LABEL' },
      icon: 'content-copy',
      action: () => _this.openCopyDaysWindow(),
      disabled: () =>
        _this.store.selectSnapshot(PreferencesState.hasOpenedContentWindow)(
          PreferencesContentWindowTypeEnum.FINALIZATION_OF_ROUTE_COPY_DAYS
        ) || _this.calendarComponent.isLoading,
    }
  );
}
