import { Directive, ElementRef, HostListener } from '@angular/core';
import { isString } from '@roadrecord/type-guard';

@Directive({
  selector: '[formControl][rrUppercase],[formControlName][rrUppercase]',
})
export class UppercaseDirective {
  private lastValue: string;

  constructor(private ref: ElementRef) {}

  static uppercaseText(value: string): string {
    if (!isString(value)) {
      return value;
    }
    return value.toUpperCase();
  }

  @HostListener('input', ['$event'])
  capitalize($event): void {
    $event.preventDefault();
    const uppercaseText = UppercaseDirective.uppercaseText($event.target.value);
    if (uppercaseText !== $event.target.value) {
      $event.target.value = uppercaseText;
    }

    // amikor bemasoljak az erteket akkor kell kulon ertesites
    if (!this.lastValue || (this.lastValue && $event.target.value.length > 0 && this.lastValue !== uppercaseText)) {
      this.lastValue = this.ref.nativeElement.value = uppercaseText;
      // Propagation
      const evt = document.createEvent('HTMLEvents');
      evt.initEvent('input', false, true);
      $event.target.dispatchEvent(evt);
    }
  }
}
