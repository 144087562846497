import { Level } from '@roadrecord/logger';
import appTitleConfig from '../../../../../apps/app-title.json';
import { versionHuTest as version } from '../../../../../apps/scripts/version-reader';
import urlsConfig from '../../../../../apps/urls.json';
import { EnvironmentInterface } from '../environment/environment.interface';
import { AppTypeEnum } from '../app-type.enum';
import { LOCALES } from '../locales.enum';
import { RRSourceEnum } from '../rr-source.enum';

const baseUrl = urlsConfig.huTest; // '//api-test.roadrecord.hu'
const webadminBaseUrl = '//api-test.roadrecord.hu/webadmin';
const baseApiEndpoint = 'api/v1';
const baseApi2Endpoint = 'api/v2';
const baseApi3Endpoint = 'api/v3';
export const environment: EnvironmentInterface = {
  languages: {
    defaultLang: LOCALES.HU_HU,
    availableLangs: [LOCALES.HU_HU],
    fallbackLang: LOCALES.HU_HU,
  },
  idleTimeoutTime: 30 * 60,
  appNameTrasnalteKey: appTitleConfig.huTest, // 'APP_NAME.TEST',
  production: true,
  baseUrl,
  baseApiEndpoint,
  baseApi2Endpoint,
  baseApi3Endpoint,
  apiUrl: `${baseUrl}/${baseApiEndpoint}/`,
  api2Url: `${baseUrl}/${baseApi2Endpoint}/`,
  api3Url: `${baseUrl}/${baseApi3Endpoint}/`,
  webadminApiUrl: `${webadminBaseUrl}/${baseApiEndpoint}/`,
  webadminApi2Url: `${webadminBaseUrl}/${baseApi2Endpoint}/`,
  ng1Url: '//api-test.roadrecord.hu/',
  baseHref: '/',
  primaryColor: '#B24C63',
  titlePreFix: 'teszt',
  dispalyLog: true,
  VERSION: `${version.date}-${version.config.versionPlaceholder}-${version.dayCounter}`,
  partnerImportSampleUrl: 'https://static-test.roadrecord.hu/downloadable/partner_import_minta.xlsx',
  fuelingImportSampleUrl: 'https://static-test.roadrecord.hu/downloadable/tankolasok_import_minta.xlsx',
  firebaseLogging: {
    apiKey: 'AIzaSyD3RjyFaw_aYUkaQHcPHvbuf1aSsE6oK5Y',
    authDomain: 'roadrecordtestlogger.firebaseapp.com',
    databaseURL: 'https://roadrecordtestlogger.firebaseio.com',
    projectId: 'roadrecordtestlogger',
    storageBucket: 'roadrecordtestlogger.appspot.com',
    messagingSenderId: '79770178244',
  },
  serviceWorker: true,
  useCaptcha: true,
  captchaCode: '6LdsHiUUAAAAANvKJpVRvD8CHlI1WhOlJTMHJ1xM',
  mode: 'test-site',
  tracking: {
    gtag: {
      enable: true,
      code: 'GTM-TFHHFZS',
    },
  },
  fbUpdateUrl: 'https://roadrecordtestlogger.firebaseio.com/versions/hu-frontend-test.json',
  fbRemoteConfigUrl: 'https://rr-remote-config.firebaseio.com/config.json',
  fbRemoteMockHttpConfigUrl: 'https://rr-remote-config.firebaseio.com/mockHttp.json',
  remoteConfig: {
    enable: true,
    mockHttp: {
      enable: true,
    },
  },
  ws: {
    url: 'wss://ws.dev.roadrecord.hu/ws',
    cookieDomain: '.roadrecord.hu',
    secure: true,
    checkCookieName: 'token_authorization',
    cookieMaxLifeTime: 1, // minutes
  },
  ngswBypass: true,
  log: {
    source: RRSourceEnum.FRONTEND,
    remoteEnable: false,
    consoleEnable: true,
    enableStateLog: false,
    printStateToConsole: false,
    levels: {
      console: Level.LOG,
      remote: Level.LOG,
    },
  },
  featureFlags: [],
  finalizationOfRouteImportSampleUrl: 'https://static-test.roadrecord.hu/downloadable/fix_utak_import_minta.xlsx',

  switchToUserUrl: 'https://ttadmin.roadrecord.hu',
  appType: AppTypeEnum.HU,
  ms: {
    pdfPrinter: 'https://pdf-printer.test.roadrecord.hu',
    excelOrCsvPrinter: 'https://excel-printer.test.roadrecord.hu/',
  },
  fbConfig: {
    apiKey: 'AIzaSyCm_oIb0eEazIeCnoTORDIJVzihht3LzVs',
    authDomain: 'test-roadrecord-online.firebaseapp.com',
    databaseURL: 'https://test-roadrecord-online.firebaseio.com',
    projectId: 'test-roadrecord-online',
    storageBucket: 'test-roadrecord-online.appspot.com',
    messagingSenderId: '1062616967082',
    appId: '1:1062616967082:web:9504c2be78bfd301976dd3',
  },
  osmMapUrl: 'https://api-online.roadrecord.hu/styles/osm-liberty/style.json',
  map: {
    center: [19.3, 47.11],
    defaultZoom: 6,
    scaleUnit: 'metric',
  },
  badge: {
    android: {
      url: 'https://play.google.com/store/apps/details?id=hu.roadrecord.roadrecordonline',
    },
    ios: {
      url: 'https://itunes.apple.com/hu/app/roadrecord-online/id1441553506',
    },
  },
  apiUrls: {
    wpFeatureList: 'https://rrtest.roadrecord.hu/wp-ajax.php?action=get-features-list',
  },
};
