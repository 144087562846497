import { DynamicFormConfigEnum } from '@roadrecord/dynamic-form';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Injector } from '@angular/core';
import { tsDeepcopy } from '@roadrecord/ts-deepcopy';

/**
 * @deprecated
 */
export function dynamicFormConfigHelper(name: DynamicFormConfigEnum, config: FormlyFieldConfig[]) {
  return {
    name,
    config: (injector: Injector) =>
      tsDeepcopy(config).map(fieldGroup => {
        fieldGroup['_rr_details_injector'] = injector;
        return fieldGroup;
      }),
  };
}

/**
 * @internal
 *
 * RR Form presenter miatt kell csatolni az injector-t
 */
function dynamicFormAttachInjector(config: FormlyFieldConfig[], injector: Injector) {
  return config.map(fieldGroup => {
    fieldGroup['_rr_details_injector'] = injector;
    return fieldGroup;
  });
}

/**
 * Formly field config-t tudjuk vele confolni, vagy tovabbi muveleteket vegezni a config-on
 * mielott megkapja a megjelenites
 */
export function dynamicFormConfig(config: FormlyFieldConfig[], injector: Injector) {
  config = tsDeepcopy(config);
  return dynamicFormAttachInjector(config, injector);
}
