import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyDetailsComponent } from './lazy-details/lazy-details.component';
import { LoadableModule } from '@roadrecord/external-packages/ngx-loadable';

@NgModule({
  declarations: [LazyDetailsComponent],
  imports: [CommonModule, LoadableModule],
  exports: [LazyDetailsComponent],
})
export class LazyDetailsModule {}
