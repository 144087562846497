import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toNumber',
})
export class ToNumberPipe implements PipeTransform {
  transform(value: any, args = { float: false }): any {
    if (args.float === true) {
      return parseFloat(value);
    } else {
      return parseInt(value, undefined);
    }
  }
}
