import { AfterViewInit, ChangeDetectorRef, Directive, Renderer2 } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionDispatched, ofActionSuccessful, Store } from '@ngxs/store';
import {
  AddAppOverlayAction,
  AppOverlayFinishedAction,
  AppOverlayFinishedAndRemoveOverlayAction,
  RemoveAppOverlayAction,
} from '@roadrecord/app-layout-state';
import { environment } from '@roadrecord/environment';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { SwUpdatesService } from '@roadrecord/service-worker';
import { EventSourcePolyfill, NativeEventSource } from 'event-source-polyfill';
import { Observable, timer } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { isNotEmptyString } from '@roadrecord/type-guard';
import { ShowStickyNotificationAction } from './state/action/show-sticky-notification.action';
import { StickyNotificationOption } from './sticky-notifiction.option';
import { HideStickyNotificationAction } from './state/action/hide-sticky-notification.action';

const EventSource = NativeEventSource || EventSourcePolyfill;

@Directive()
export abstract class BaseAppComponent implements AfterViewInit {
  stickyNotificationOptions: StickyNotificationOption;
  currentRoute$: Observable<string>;

  protected constructor(
    protected readonly logScopeName: string,
    platformId: string,
    protected translocoService: TranslocoService,
    protected cdr: ChangeDetectorRef,
    protected store: Store,
    protected actions$: Actions,
    protected renderer: Renderer2,
    protected document: Document,
    protected matSnackBar: MatSnackBar,
    protected window: Window,
    protected swUpdatesService: SwUpdatesService,
    protected messageDialogService: MessageDialogService,
    router: Router
  ) {
    this.watchVisibleAppOverlay();
    timer(120 * 1000).subscribe(() => this.startVersionUpdateChecker());

    this.currentRoute$ = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url)
    );
    this.handleShowHideStickyNotificationOptionsAction();
  }

  private handleShowHideStickyNotificationOptionsAction() {
    this.actions$.pipe(ofActionSuccessful(ShowStickyNotificationAction)).subscribe(action => {
      this.stickyNotificationOptions = action.option;
      this.cdr.detectChanges();
    });
    this.actions$.pipe(ofActionSuccessful(HideStickyNotificationAction)).subscribe(() => {
      this.stickyNotificationOptions = undefined;
      this.cdr.detectChanges();
    });
  }

  private startVersionUpdateChecker(): void {
    if (
      environment.serviceWorker === false ||
      (this.swUpdatesService !== undefined && this.swUpdatesService.swu !== undefined && this.swUpdatesService.swu.isEnabled === false)
    ) {
      return;
    }
    try {
      let first = true;
      const source = new EventSource(`${environment.fbUpdateUrl}?ngsw-bypass=true`);
      source.addEventListener(
        'put',
        (e: any) => {
          if (first === true) {
            first = false;
            return;
          }
          const data = JSON.parse(e.data);
          if (data.path === '/' && data.data !== null && environment.VERSION !== data.data) {
            console.warn(this.logScopeName, 'New version avaiable: ', data.data);
            const updateCallback = () => timer(500).subscribe(() => this.window.location.reload());
            this.swUpdatesService.disableNextAvaibleCheck = true;
            this.swUpdatesService.swu.checkForUpdate().then(() => {
              this.showAppUpdateSnackbar(60, updateCallback);
              timer(5000, 1000)
                .pipe(
                  filter(tick => {
                    if (tick < 60) {
                      return true;
                    } else {
                      this.stickyNotificationOptions = {
                        text: this.translocoService.translate('COMMON.APP_UPDATE.SNACKBAR_END.TEXT'),
                        hasCloseButton: false,
                        closeActionText: '',
                      };
                      this.cdr.detectChanges();
                      updateCallback();
                      return false;
                    }
                  })
                )
                .subscribe(tick => {
                  this.showAppUpdateSnackbar(60 - (tick + 1), updateCallback);
                });
            });
          } else {
            console.warn(this.logScopeName, 'Wrong eventSource data: ', data, 'current version: ', environment.VERSION);
          }
        },
        false
      );
    } catch (ex) {
      console.warn(this.logScopeName, 'EventSource error: ', ex);
    }
  }

  private watchVisibleAppOverlay(): void {
    this.actions$
      .pipe(
        ofActionDispatched(AddAppOverlayAction)
        // nem kell leiratkozni mert jelenleg csak kilepesnel hasznaljuk
      )
      .subscribe((action: AddAppOverlayAction) => {
        const appOverlay: HTMLDivElement = (this.document.getElementById('app-overlay') as any) as HTMLDivElement;
        if (appOverlay !== null) {
          // szovbeget feltolteni
          // overlayt megjeleniteni
          const pElems = appOverlay.getElementsByTagName('p');
          if (pElems.length === 1) {
            (pElems[0] as HTMLParagraphElement).innerText = action.text;
            this.renderer.setStyle(appOverlay, 'display', 'flex');
            this.renderer.setStyle(appOverlay, 'transition', isNotEmptyString(action.transition) ? action.transition : 'none');
            timer(0).subscribe(() =>
              /*Animacio miatt kell, kulonben nem ervenyesul idoben a transition beallitas*/
              this.renderer.setStyle(appOverlay, 'background-color', isNotEmptyString(action.color) ? action.color : 'rgba(0, 0, 0, 0.4)')
            );
          }
        }
      });

    this.actions$.pipe(ofActionDispatched(RemoveAppOverlayAction)).subscribe(() => document.getElementById('app-overlay').remove());

    this.actions$
      .pipe(
        ofActionDispatched(AppOverlayFinishedAction)
        // nem kell leiratkozni mert jelenleg csak kilepesnel hasznaljuk, es olyankor meg ujra toltjuk az oldalt
      )
      .subscribe(() => {
        const appOverlay: HTMLDivElement = (this.document.getElementById('app-overlay') as any) as HTMLDivElement;
        if (appOverlay !== null) {
          this.renderer.addClass(appOverlay, 'finished');
        }
      });

    this.actions$.pipe(ofActionDispatched(AppOverlayFinishedAndRemoveOverlayAction)).subscribe(action => {
      const appOverlay: HTMLDivElement = (this.document.getElementById('app-overlay') as any) as HTMLDivElement;
      if (appOverlay !== null) {
        this.renderer.addClass(appOverlay, 'finished');
        this.renderer.setStyle(appOverlay, 'background-color', 'transparent');
        timer(action.transitionTime).subscribe(() => this.renderer.setStyle(appOverlay, 'display', 'none'));
      }
    });
  }

  ngAfterViewInit(): void {
    this.addEnvironmentNameToPageTitle();
  }

  private addEnvironmentNameToPageTitle(): void {
    this.translocoService.selectTranslate(environment.appNameTrasnalteKey).subscribe(title => (this.document.title = title));
  }

  private showAppUpdateSnackbar(updateTime: number, cb): void {
    this.stickyNotificationOptions = {
      text: this.translocoService.translate('COMMON.APP_UPDATE.SNACKBAR_RUN.TEXT', { updateTime }),
      hasCloseButton: false,
      closeActionText: this.translocoService.translate('COMMON.APP_UPDATE.SNACKBAR_RUN.ACTION'),
      dismissCallback: () => cb(),
    };
    this.cdr.detectChanges();
  }
}
