import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import { ApplicationSettingsState } from '@roadrecord/application-settings/state';
import { ViewportService } from '@roadrecord/common/common';
import { FaqConfigModel, PreferencesState } from '@roadrecord/preferences-state/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter, first, take, tap } from 'rxjs/operators';
import { INTROJS_TYPES_ENUM, StartIntroAction } from '@roadrecord/introjs/common';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { AppTypeEnum, environment } from '@roadrecord/environment';

@UntilDestroy()
@Component({
  selector: 'rr-nav-toolbar-help-menu',
  templateUrl: './nav-toolbar-help-menu.component.html',
  styleUrls: ['./nav-toolbar-help-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavToolbarHelpMenuComponent {
  @Select(PreferencesState.faq)
  readonly faqConfig$: Observable<FaqConfigModel>;
  @Select(PreferencesState.helpVideoUrl)
  readonly helpVideoUrl$: Observable<string>;
  @Input()
  gyikUrl: string;
  serverVersion: string;
  readonly clientVersion: string;
  versionTooltip: string;
  appType: AppTypeEnum;
  AppTypeEnum = AppTypeEnum;
  appleStoreUrl = environment.badge.ios.url;
  googleStoreUrl = environment.badge.android.url;
  INTROJS_TYPES_ENUM = INTROJS_TYPES_ENUM;
  disableIntrojsStartButtons: boolean;

  constructor(
    readonly viewportService: ViewportService,
    private store: Store,
    private translocoService: TranslocoService,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>
  ) {
    this.serverVersion = 'Server: betöltés alatt...';
    this.clientVersion = `Client: ${environment.VERSION}`;
    this.generateVersionTooltip();
    this.waitServerVersion(store);
    this.appType = environment.appType;

    this.store
      .select(this.periodContextStateSelectorsService.hasContext)
      .pipe(untilDestroyed(this))
      .subscribe(hasContext => (this.disableIntrojsStartButtons = hasContext === false));
  }

  openHelpWindow(): void {
    this.helpVideoUrl$.pipe(first()).subscribe(url => this.viewportService.openVideoDialog$.next(url));
  }

  /**
   * sw kesleltetes miatt inkabb bevarjuk a verziot es frissitunk mintsem fenttartsunk egy felesleges stream-et (@Select decoratorral)
   */
  private waitServerVersion(store: Store): void {
    store
      .select(ApplicationSettingsState.serverVersion)
      .pipe(
        filter(v => v !== undefined),
        take(1),
        tap(serverVersion => (this.serverVersion = `Server: ${serverVersion}`))
      )
      .subscribe(() => this.generateVersionTooltip());
  }

  private generateVersionTooltip(): void {
    this.translocoService
      .selectTranslate('APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.VERSION')
      .pipe(untilDestroyed(this))
      .subscribe(
        str =>
          (this.versionTooltip = `${str}:
      \r${this.serverVersion}
      \r${this.clientVersion}`)
      );
  }

  onClickIntrojsStart(type: INTROJS_TYPES_ENUM) {
    this.store.dispatch(
      new StartIntroAction(type, { skipShowedCheck: true, withPeriodContext: type === INTROJS_TYPES_ENUM.MONTH_ACTIVITIES })
    );
  }
}
