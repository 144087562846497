import { LocationTypeEnum } from './location-type.enum';

export class LocationModel {
  id: number;
  address: string;
  city: string;
  country: string;
  hash: string;
  house_number: string;
  is_valid: boolean;
  latitude: string;
  local_country: string;
  longitude: string;
  name: string;
  postcode: string;
  street: string;
  type: LocationTypeEnum;

  constructor(data?: LocationModel) {
    if (data !== undefined) {
      Object.assign(this, data);
    }
  }
}
