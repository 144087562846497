import { Injectable } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * ami nem kattint bele a mezobe vay a form-t legalabb 1xer nem submit-jak addig nem jelez ki hibat
 *
 *
 * megjegyzes:
 * mivel kozvetlen elerni az NgForm elemet nem mindig lehet csak sok felesleges koddal, ezert az ngForm-ba
 * csatolt elso FormGroup-ban taroljuk a submitted-t es onnan olvassuk ki
 */
@Injectable()
export class RRBaseFormErrorStateMatcher extends ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: (FormGroupDirective | NgForm | null) & { form: { submitted: boolean } }): boolean {
    const isSubmitted = form && form.form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
