import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'getLabelByCurrentLang',
})
export class GetLabelByCurrentLangPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}

  transform(value: { lang: string; title: string }[]): any {
    return this.translocoService.langChanges$.pipe(map(activeLang => value.find(v => v.lang === activeLang).title));
  }
}
