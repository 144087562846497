import { Component, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ENTITY_SERVICE_TOKEN } from '@roadrecord/utils';
import { FragmentModalWrapperComponentInterface } from '@roadrecord/fragment-dialog';
import { DetailsComponent } from '../../details/details.component';
import { TripReasonService } from '../../trip-reason.service';
import { TripReasonModel } from '@roadrecord/trip-reason/model';

@Component({
  selector: 'rr-trip-reason-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss'],
  providers: [
    TripReasonService,
    {
      provide: ENTITY_SERVICE_TOKEN,
      useExisting: TripReasonService,
    },
  ],
})
export class ModalWrapperComponent implements FragmentModalWrapperComponentInterface<TripReasonModel> {
  @Input() windowEditModelId: number;
  @Output()
  readonly save = new EventEmitter<TripReasonModel>();
  @Output()
  readonly cancel = new EventEmitter<void>();
  @ViewChild('cmpTemplate', { static: true })
  readonly cmpTemplate: TemplateRef<any>;
  /**
   * kulso template-k erhetik el
   */
  @ViewChildren('details')
  readonly details: QueryList<DetailsComponent>;

  uiSave(model: TripReasonModel): void {
    this.save.emit(model);
  }

  beforeCloseDialog(): void {}
}
