import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { filter } from 'rxjs/operators';
import { DialogComponent, DialogOptionsModel } from '@roadrecord/dialog';

@Component({
  selector: 'rr-secure-delete-dialog',
  templateUrl: './secure-delete-dialog.component.html',
  styleUrls: ['./secure-delete-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecureDeleteDialogComponent {
  @Input()
  firstName: string;
  @Input()
  lastName: string;
  @Input()
  fullName: string;
  @Input()
  id: number;
  @ViewChild('contentTpl')
  deleteContentTpl: TemplateRef<any>;
  @ViewChild('buttonsTpl')
  buttonsTpl: TemplateRef<any>;
  form: FormGroup & { submitted?: boolean };
  confirmControl = new FormControl('', Validators.required);
  deleteDialog: MatDialogRef<any>;
  private confirmControlName = 'confirm';
  @Output() remove = new EventEmitter<number>();
  @Input() translateDialogTitle: string;
  @Input() translateLastAndFirstNameLabel: string;
  @Input() translateFullNameLabel: string;
  @Input() translateInputLabel: string;
  @Input() translateInputPlaceholder: string;
  @Input() translateInputErrorMismatch: string;
  @Input() translateButtonTooltip: string;

  constructor(private readonly dialog: MatDialog, private translocoService: TranslocoService) {
    this.initForm();
  }

  openDelete(): void {
    this.form.reset();
    this.confirmControl.reset();
    this.deleteDialog = this.dialog.open(DialogComponent, {
      width: '40%',
      panelClass: ['rr-dialog'],
      data: {
        title: this.translocoService.translate(this.translateDialogTitle),
        contentTpl: this.deleteContentTpl,
        buttonsTpl: this.buttonsTpl,
      } as DialogOptionsModel,
    });
  }

  onSubmit(): void {
    this.form.submitted = true;
    if (this.form.valid) {
      this.remove.emit(this.id);
      this.deleteDialog.close();
    }
  }

  private initForm(): void {
    this.form = new FormGroup(
      {
        confirm: this.confirmControl,
      },
      this.mismatchedFullName.bind(this)
    );

    // ha elkezd gepelni akkor nem jelzunk ki hibat ujra
    this.confirmControl.valueChanges.pipe(filter(() => this.form.submitted)).subscribe(() => (this.form.submitted = false));
  }

  mismatchedFullName(group: AbstractControl): { [key: string]: any } {
    if (group.get(this.confirmControlName).value !== (this.lastName ? `${this.lastName} ${this.firstName}` : this.fullName)) {
      return { mismatchedFullName: true };
    } else {
      return undefined;
    }
  }
}
