import { Pipe, PipeTransform } from '@angular/core';
import { LocationModel, LocationTypeEnum } from '@roadrecord/partner/model';

const defualt = { companies: false, headquartes: false, addresses: false };

@Pipe({
  name: 'filterLocations',
})
export class FilterLocationsPipe implements PipeTransform {
  transform(value: LocationModel[], args: Partial<{ companies: boolean; headquartes: boolean; addresses: boolean }>): any {
    args = { ...defualt, ...args };
    const result = [];
    if (args.companies) {
      result.push(...filterCompanies(value));
    }
    if (args.headquartes) {
      result.push(...filterHeadquarters(value));
    }
    if (args.addresses) {
      result.push(...filterAddresses(value));
    }
    return result;
  }
}

export function filterCompanies(value: LocationModel[]) {
  return value.filter(location => location.type === LocationTypeEnum.COMPANY);
}

export function filterHeadquarters(value: LocationModel[]) {
  return value.filter(location => location.type === LocationTypeEnum.COMPANY_HEADQUARTERS);
}

export function filterAddresses(value: LocationModel[]) {
  return value.filter(location => location.type === LocationTypeEnum.ADDRESS);
}
