import { FieldHttpError } from '../../http/exception/field-http-error';

export function handleFormControlsServerError(responseError, finishCallback: (errors: { [key: string]: string }) => void): void {
  if (!(responseError instanceof FieldHttpError)) {
    console.warn('Not compatibility error type');
    return;
  }
  const errors = {};
  Object.entries(responseError.error.errors).forEach(entry => (errors[entry[0]] = entry[1][0]));
  finishCallback(errors);
}
