import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

export const isValidControl = (control: FormControl) => <T>(source: Observable<T>) =>
  new Observable<T>(observer =>
    source.subscribe({
      next(x): void {
        if (control.valid) {
          observer.next(x);
        }
      },
      error(err): void {
        observer.error(err);
      },
      complete(): void {
        observer.complete();
      },
    })
  );
