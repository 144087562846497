import { animate, state, style, transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn, fadeInLeft, fadeInRight, fadeOut, lightSpeedIn, pulse as _pulse, zoomIn, zoomOut } from 'ng-animate';

export const pulseInEnter = trigger('pulseInEnter', [
  transition(
    ':enter',
    useAnimation(_pulse, {
      params: { timing: 0.5, delay: 0 },
    })
  ),
]);
export const fadeInEnter = trigger('fadeInEnter', [transition(':enter', useAnimation(fadeIn))]);
export const fadeInSlowEnter = trigger('fadeInSlowEnter', [
  transition(
    ':enter',
    useAnimation(fadeIn, {
      params: { timing: 1, delay: 0 },
    })
  ),
]);
export const fadeIn2XFastEnter = trigger('fadeIn2XFastEnter', [
  transition(
    ':enter',
    useAnimation(fadeIn, {
      params: { timing: 0.5, delay: 0 },
    })
  ),
]);
export const fadeOutLeave = trigger('fadeOutLeave', [transition(':leave', useAnimation(fadeOut))]);
export const fadeOut2XFastLeave = trigger('fadeOut2XFastLeave', [
  transition(
    ':leave',
    useAnimation(fadeOut, {
      params: { timing: 0.5, delay: 0 },
    })
  ),
]);
export const zoomInEnter = trigger('zoomInEnter', [transition(':enter', useAnimation(zoomIn))]);
export const zoomIn2XEnter = trigger('zoomIn2XEnter', [
  transition(
    ':enter',
    useAnimation(zoomIn, {
      params: { timing: 0.5, delay: 0 },
    })
  ),
]);
export const zoomOutLeave = trigger('zoomOutLeave', [transition(':leave', useAnimation(zoomOut))]);
export const zoomOut2XLeave = trigger('zoomOut2XLeave', [
  transition(
    ':leave',
    useAnimation(zoomOut, {
      params: { timing: 0.5, delay: 0 },
    })
  ),
]);
export const lightSpeedInEnter = trigger('lightSpeedInEnter', [transition(':enter', useAnimation(lightSpeedIn))]);

////////////////////////
export const tableExpandPanel = trigger('detailExpand', [
  state('collapsed', style({ height: '0px', minHeight: '0' })),
  state('expanded', style({ height: '*' })),
  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
]);

export const fadeInVeryRightFast = trigger('fadeInVeryRightFast', [
  transition(
    ':enter',
    useAnimation(fadeInRight, {
      params: { timing: 0.1, delay: 0 },
    })
  ),
]);

export const fadeInRightFast = trigger('fadeInRightFast', [
  transition(
    ':enter',
    useAnimation(fadeInRight, {
      params: { timing: 0.25, delay: 0 },
    })
  ),
]);

export const fadeInLeftFast = trigger('fadeInLeftFast', [
  transition(
    ':enter',
    useAnimation(fadeInLeft, {
      params: { timing: 0.25, delay: 0 },
    })
  ),
]);
