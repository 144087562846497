<ngx-mat-calendar
  cdkTrapFocus
  *ngIf="containerData.hideCalendar === false"
  [id]="datepicker.id"
  [ngClass]="datepicker.panelClass"
  [startAt]="datepicker.startAt"
  [startView]="datepicker.startView"
  [minDate]="datepicker._minDate"
  [maxDate]="datepicker._maxDate"
  [dateFilter]="datepicker._dateFilter"
  [headerComponent]="datepicker.calendarHeaderComponent"
  [selected]="datepicker._selected"
  [dateClass]="datepicker.dateClass"
  [@fadeInCalendar]="'enter'"
  (selectedChange)="datepicker.select($event)"
  (yearSelected)="datepicker._selectYear($event)"
  (monthSelected)="datepicker._selectMonth($event)"
>
</ngx-mat-calendar>
<ng-container *ngIf="isViewMonth">
  <div *ngIf="!datepicker._hideTime" class="time-container" [class.disable-seconds]="!datepicker._showSeconds">
    <ngx-mat-timepicker
      [showSpinners]="datepicker._showSpinners"
      [showSeconds]="datepicker._showSeconds"
      [disabled]="datepicker._disabled"
      [stepHour]="datepicker._stepHour"
      [stepMinute]="datepicker._stepMinute"
      [stepSecond]="datepicker._stepSecond"
      [(ngModel)]="datepicker._selected"
      [color]="datepicker._color"
      [enableMeridian]="datepicker._enableMeridian"
      [disableMinute]="datepicker._disableMinute"
      [autoCastMomentToString]="containerData.autoCastMomentToString"
    >
    </ngx-mat-timepicker>
  </div>
  <!--  <div class="actions">-->
  <!--    <button-->
  <!--      type="button"-->
  <!--      mat-icon-button-->
  <!--      (click)="datepicker.cancel()"-->
  <!--      matTooltipPosition="right"-->
  <!--      matTooltip="{{ 'COMMON.ACTION.TOOLTIP.CANCEL' | transloco }}"-->
  <!--    >-->
  <!--      <mat-icon svgIcon="arrow-left"></mat-icon></button-->
  <!--    ><span class="flex-spacer"></span-->
  <!--    ><button-->
  <!--      (click)="datepicker.ok()"-->
  <!--      mat-icon-button-->
  <!--      [color]="datepicker._color"-->
  <!--      cdkFocusInitial-->
  <!--      [disabled]="!valid"-->
  <!--      [matTooltip]="containerData.okTooltip | transloco"-->
  <!--      class="submit"-->
  <!--    >-->
  <!--      <mat-icon svgIcon="check"></mat-icon>-->
  <!--    </button>-->
  <!--  </div>-->
</ng-container>
