<ng-template #formTpl>
  <form [formGroup]="form" novalidate (keyup.enter)="onSubmit()">
    <div fxLayout="column" fxLayoutAlign="center stretch">
      <rr-formly-form class="w-100" [form]="form" [fields]="nameFormFragmentFormlyFields"> </rr-formly-form>
      <div ffxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
        <mat-form-field class="w-100">
          <mat-label>{{ 'COMPANY_MEMBER.DETAILS.DATA_FORM.FIELD.EMAIL.LABEL' | transloco }}</mat-label>
          <input
            tabindex="3"
            [placeholder]="'COMPANY_MEMBER.DETAILS.DATA_FORM.FIELD.EMAIL.PLACEHOLDER' | transloco"
            class="input-element"
            [formControl]="emailControl"
            matInput
            name="email"
            required
            type="email"
          />
          <mat-error>
            <rr-validation-messages [errors]="emailControl?.errors" [messages]="emailControlValidationMessages"></rr-validation-messages>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100">
          <mat-label>{{ 'COMPANY_MEMBER.DETAILS.DATA_FORM.FIELD.ROLE.LABEL' | transloco }}</mat-label>
          <mat-select [formControl]="roleControl" required name="role" tabindex="4">
            <ng-container *ngFor="let entry of CompanyUserRoleEnum | keyvalue">
              <mat-option *ngIf="(entry.key | isNumeric) && entry.key > 1" [value]="entry.key | toNumber">
                {{ 'COMPANY_MEMBER.DETAILS.DATA_FORM.FIELD.ROLE.VALUE_LABEL._' + entry.key | transloco }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="w-100 text-right mb-0" *ngIf="isNew === false">
      <rr-two-way-switch-form-control
        *ngIf="editModel.status !== CompanyUserStatusEnum.INVITED"
        saveNumberTypeMode="true"
        formControlName="status"
        icon_name_false="account-remove"
        icon_name_true="account-check"
        name="status"
        option_false="COMPANY_MEMBER.DETAILS.DATA_FORM.FIELD.STATUS.INACTIVE"
        option_true="COMPANY_MEMBER.DETAILS.DATA_FORM.FIELD.STATUS.ACTIVE"
      ></rr-two-way-switch-form-control>
    </div>
  </form>
</ng-template>

<ng-template #buttonsTpl>
  <rr-details-button-row
    [isNew]="isNew"
    [disabled]="(form['submitted'] && form.invalid) || form.disabled"
    [form]="form"
    [hasSubmitAndNew]="hasSubmitAndNew"
    [hasDelete]="true"
    [disableDelete]="false"
    (delete)="openDelete()"
    (submitForm)="onSubmit()"
    (submitAndNew)="onSubmitReset()"
  >
    <button
      *ngIf="isNew === false && editModel?.status === CompanyUserStatusEnum.INVITED"
      type="button"
      mat-icon-button
      color="accent"
      ngProjectAs="before-save-or-edit"
      (click)="onClickResendInvite()"
      [matTooltip]="'COMPANY_MEMBER.DETAILS.DATA_FORM.BUTTON.RESEND_INVITE.TOOLTIP' | transloco"
    >
      <mat-icon svgIcon="email-send-outline"></mat-icon>
    </button>
  </rr-details-button-row>
</ng-template>
<rr-secure-delete-dialog
  *ngIf="showSecureDeleteDialog"
  [firstName]="getFirstName()"
  [lastName]="getLastName()"
  [id]="editModel?.id"
  (remove)="onDelete($event)"
  [translateDialogTitle]="'COMPANY_MEMBER.SECURE_REMOVE.CONFIRM_DELETE'"
  [translateLastAndFirstNameLabel]="'COMPANY_MEMBER.SECURE_REMOVE.WRITE_NAME'"
  [translateFullNameLabel]="'COMPANY_MEMBER.SECURE_REMOVE.WRITE_FULL_NAME'"
  [translateInputLabel]="'COMPANY_MEMBER.SECURE_REMOVE.INPUT.LABEL'"
  [translateInputPlaceholder]="'COMPANY_MEMBER.SECURE_REMOVE.INPUT.PLACEHOLDER'"
  [translateInputErrorMismatch]="'COMPANY_MEMBER.SECURE_REMOVE.INPUT.ERROR.MISMATCH'"
  [translateButtonTooltip]="'COMPANY_MEMBER.SECURE_REMOVE.BUTTON_TOOLTIP'"
>
</rr-secure-delete-dialog>
