import { cache, MessageDialogOptionsInterface, MessageDialogService } from '@roadrecord/message-dialog';
import { isFunction, isNil } from '@roadrecord/type-guard';

export function showCommonError(clickCallback?: (error: any) => void, options?: Partial<MessageDialogOptionsInterface>): void {
  if (isNil(options)) {
    options = {};
  }
  MessageDialogService.INSTANCE.openError({
    id: null,
    title: cache.title,
    text: cache.text,
    htmlMode: true,
    ...options,
  })
    .afterClosed()
    .subscribe(() => {
      if (isFunction(clickCallback)) {
        clickCallback(null);
      }
    });
}
