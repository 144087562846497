import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetIconConfigPipe } from './get-icon-config.pipe';

@NgModule({
  declarations: [GetIconConfigPipe],
  imports: [CommonModule],
  exports: [GetIconConfigPipe],
})
export class GetIconConfigModule {}
