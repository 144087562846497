import { Inject, Injectable, InjectionToken, Optional, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { checkPropertyChange, isNotDeletable } from '@roadrecord/common/common';
import { isBoolean, isFunction, isNil } from '@roadrecord/type-guard';
import { PresenterStateController, VIEW_COMPONENT } from '../../presenter-state/presenter-state.controller';
import {
  VIEW_MODEL_PLUGIN_OPTIONS_TOKEN,
  ViewModelOnChangesCallbackStrategy,
  ViewModelPluginOptionsModel,
} from './model/view-model-plugin-options.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { afterViewInitMethodName } from './model/has-after-init-view.interface';

export const VIEW_MODEL_PLUGIN_RESET_STATE = new InjectionToken('VIEW_MODEL_RESET_STATE');
/* tslint:disable */
export type ViewModelPluginResetState = <MODEL, IDTYPE>(
  viewComponent: any,
  presenterStateController: PresenterStateController<MODEL, IDTYPE>
) => MODEL | MODEL;

/* tslint:enable */

export function resetForm<MODEL>(form: FormGroup & { submitted?: boolean }, resetModel: MODEL): void {
  form.reset(resetModel);
  form.submitted = false;
  form.enable(/*{ emitEvent: false }*/);
}

@UntilDestroy()
@Injectable()
export class ViewModelPlugin<MODEL, IDTYPE> {
  private currentScope: any;

  constructor(
    private presenterStateController: PresenterStateController<MODEL, IDTYPE>,
    @Inject(VIEW_MODEL_PLUGIN_RESET_STATE) private resetState: ViewModelPluginResetState,
    @Optional()
    @Inject(VIEW_MODEL_PLUGIN_OPTIONS_TOKEN)
    private options: ViewModelPluginOptionsModel
  ) {
    if (!isNil(this.options)) {
      if (!(this.options instanceof ViewModelPluginOptionsModel)) {
        throw new Error('Wrong options type! Required Presenter save options');
      }
    } else {
      this.options = new ViewModelPluginOptionsModel();
    }
    this.registerPresenter();
  }

  loadDataModel(model: MODEL, resetModel: MODEL): void {
    const form = this.presenterStateController.formGroupLastValue;
    if (form !== undefined) {
      // TODO newCallback lehetosege (az isNew tovabbra is presenterbol jon,
      // de lehetoseget kell adni ezt felul biralni a plugin options-ben
      const isNew = this.presenterStateController.isNewLastValue;
      if (isNew === true) {
        // if (form.disabled) {
        if (!isNil(model) === false) {
          resetForm(form, resetModel);
        }
        // }
      } /*if (!isNil(model))*/ else {
        form.patchValue(model);
      }
      if (!isNil(this.currentScope) && isFunction(this.currentScope[afterViewInitMethodName])) {
        this.currentScope[afterViewInitMethodName]();
      }
    }
  }

  getResetState(): MODEL {
    if (isFunction(this.resetState)) {
      return this.resetState(this, this.presenterStateController);
    } else {
      return this.resetState;
    }
  }

  private registerPresenter(): void {
    // tslint:disable:no-invalid-this
    const view: any = this.presenterStateController.get(VIEW_COMPONENT);

    const originalOnChangesKey = 'ORIGINAL_NGONCHANGES';
    let ngOnChangesOriginalMethod;
    let succesSubmitWithoutRedirectMethod;

    if (!isNil(view.prototype)) {
      ngOnChangesOriginalMethod = !isNil(view.prototype[originalOnChangesKey])
        ? view.prototype[originalOnChangesKey]
        : view.prototype.ngOnChanges;

      succesSubmitWithoutRedirectMethod = !isNil(view.prototype['succesSubmitWithoutRedirect'])
        ? view.prototype.succesSubmitWithoutRedirect
        : null;

      if (view.prototype[originalOnChangesKey] === undefined) {
        view.prototype[originalOnChangesKey] = ngOnChangesOriginalMethod;
      }
    } else {
      // tslint:disable-next-line:max-line-length
      ngOnChangesOriginalMethod = !isNil(view[originalOnChangesKey]) ? view[originalOnChangesKey] : view.ngOnChanges;
      succesSubmitWithoutRedirectMethod = !isNil(view['succesSubmitWithoutRedirect']) ? view.succesSubmitWithoutRedirect : null;

      if (view[originalOnChangesKey] === undefined) {
        view[originalOnChangesKey] = ngOnChangesOriginalMethod;
      }
    }

    if (ngOnChangesOriginalMethod === undefined) {
      throw new Error('Not found ngOnChanges');
    }

    if (!isNil(this.presenterStateController.refreshModelCommand$)) {
      this.presenterStateController.refreshModelCommand$.pipe(untilDestroyed(this)).subscribe((model): void => {
        this.loadDataModel(model, this.getResetState());
        this.presenterStateController.editModel$.next(model);
        if (!isNil(model) && isFunction(succesSubmitWithoutRedirectMethod)) {
          // ilyenkor redirect nelkuli modositas van
          succesSubmitWithoutRedirectMethod.call(this.currentScope);
        }
        ngOnChangesOriginalMethod.call(this.currentScope ? this.currentScope : ngOnChangesOriginalMethod, {});
      });
    }

    const __this = this;
    const newFunction = function (): void {
      __this.currentScope = this;
      if (__this.options.viewModelOnChangesCallbackStrategy === ViewModelOnChangesCallbackStrategy.BEFORE_RUN_PLUGIN) {
        ngOnChangesOriginalMethod.call(this, arguments[0]);
      }
      // TODO model check?
      const changes: SimpleChanges = arguments[0];
      const isChange =
        checkPropertyChange('editModel', changes) && !(changes.editModel.firstChange && changes.editModel.currentValue == null);
      if (isBoolean(isChange) && isChange === true) {
        const changeValue = changes.editModel ? changes.editModel.currentValue : undefined;
        __this.checkHasDeletable.call(this, changeValue);
        // TODO getResetState-t csak akkor kerdezzuk le ha szukseg van ra a loadDataModel-ben mert felesleges ezen a
        // ponton hisz vissza hivhat component altal bejegyzett callback-et is amit ilyenkor
        // meg felesleges piszkalni hisz nem biztos hogy reset lesz ...
        __this.loadDataModel(changeValue, __this.getResetState());
      }
      if (__this.options.viewModelOnChangesCallbackStrategy === ViewModelOnChangesCallbackStrategy.AFTER_RUN_PLUGIN) {
        ngOnChangesOriginalMethod.call(this, arguments[0]);
      }
    };

    if (!isNil(view.prototype)) {
      view.prototype.ngOnChanges = newFunction;
    } else {
      view.ngOnChanges = newFunction;
    }
  }

  /**
   * nem torolhetoseg ellenorzese
   */
  private checkHasDeletable(changeValue: undefined): void {
    // tslint:disable
    if (!isNil(this['hasDelete'])) {
      if (!isNil(changeValue)) {
        this['hasDelete'] = !isNotDeletable(changeValue);
      } else {
        this['hasDelete'] = false;
      }
    }
    // tslint:enable
  }
}

// TODO add options => isNewIdCheck (service segitsegevel az id alapjan checkolja megegyszer hogy uj-e az egyed)
// export function inputDataModel(model: any,
//                                resetModel: any,
//                                isNewIdCheck = false,
//                                service: GenericAPIResource<HttpListResponseModel<any>, any>) {
//     if (this.form !== undefined) {
//         this.isNew = model === undefined;
//         if (isNewIdCheck === true) {
//             if (service === undefined) {
//                 throw new Error('Service is required!');
//             }
//             this.isNew = service.getModelIdValue(model) === undefined;
//         }
//
//         this.editModel = model === undefined ? {} : model;
//         if (this.isNew) {
//             if (this.form.disabled) {
//                 this.form.reset(resetModel);
//                 this.form['submitted'] = false;
//                 this.form.enable();
//                 this.disabled = false;
//             }
//         } else {
//             this.form.patchValue(model);
//         }
//     }
// }
