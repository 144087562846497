import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxsModule } from '@ngxs/store';
import { FragmentPresenterModule } from '@roadrecord/fragment-dialog';
import { LiveAutoCompleteModule } from '@roadrecord/live-auto-complete';
import {
  ModalWrapperModule as TripReasonModalWrapperModule,
  TRIP_REASON_EDIT_FRAGMENT_PRESENTERS,
  TRIP_REASON_NEW_FRAGMENT_PRESENTERS,
} from '@roadrecord/trip-reason/common';
import { ValidatingModule } from '@roadrecord/validating';
import { BulkEditTripReasonBottomSheetComponent } from './bulk-edit-trip-reason-bottom-sheet.component';

@NgModule({
  declarations: [BulkEditTripReasonBottomSheetComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LiveAutoCompleteModule,
    TranslocoModule,
    MatFormFieldModule,
    NgxsModule,
    MatSnackBarModule,
    TripReasonModalWrapperModule,
    FragmentPresenterModule.forFeature([...TRIP_REASON_NEW_FRAGMENT_PRESENTERS, ...TRIP_REASON_EDIT_FRAGMENT_PRESENTERS]),
    ValidatingModule,
  ],
  exports: [BulkEditTripReasonBottomSheetComponent],
})
export class BulkEditTripReasonBottomSheetModule {}
