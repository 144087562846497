<div [formGroup]="dayFormGroup">
  <div *ngIf="dayFormGroup.get('dayCheckBoxArray')" formArrayName="dayCheckBoxArray">
    <div
      class="mat-elevation-z1 day"
      *ngFor="let day of listEvents; trackBy: trackByFn; let i = index"
      [ngClass]="{ 'empty-day': day.events.length === 0 }"
    >
      <div
        class="header w-100"
        (click)="editEvents($any(day))"
        [matTooltip]="
          disabled
            ? ''
            : ((day.events.length === 0 ? 'CALENDAR.AGENDA.LIST.TOOLTIP.MODIFY' : 'CALENDAR.AGENDA.LIST.TOOLTIP.NEW') | transloco)
        "
      >
        <div class="label w-100">
          <div class="header-check-box-container">
            <mat-checkbox
              [formControlName]="i"
              [disabled]="day.events.length === 0"
              (click)="onDayClick(day, dayCheckBoxArray.at(i).value, $event)"
              class="d-flex align-items-center"
            >
            </mat-checkbox>
            <span class="d-flex align-items-center">{{ day.date | dateFormat }}</span>
            <ng-container *ngTemplateOutlet="listItemTitleAfterDateCellTemplateRef; context: { day: day }"> </ng-container>
          </div>
          <div>
            <ng-container *ngTemplateOutlet="listItemDescriptionCellTemplateRef; context: { day: day }"></ng-container>
          </div>
        </div>
      </div>

      <ng-container *ngIf="day.events.length > 0">
        <hr class="mt-3" style="width: calc(100% + 36px); margin-left: -16px" />
        <div class="content w-100">
          <table class="w-100 mt-3">
            <tbody>
              <ng-container *ngFor="let event of day.events; let last = last">
                <ng-container
                  *ngTemplateOutlet="
                    listItemCellTemplateRef;
                    context: {
                      event: event,
                      isLast: last,
                      selectionTripForm: selectionTripForm
                    }
                  "
                >
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
      </ng-container>
    </div>
  </div>
</div>
