import { Injectable } from '@angular/core';
import { AbstractControlOptions, AsyncValidatorFn, FormBuilder as NgFormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { PresenterStateController } from '../presenter-state/presenter-state.controller';
import { RRFormGroup } from '@roadrecord/common/common';

function isAbstractControlOptions(options: AbstractControlOptions | { [key: string]: any }): options is AbstractControlOptions {
  return (
    (options as AbstractControlOptions).asyncValidators !== undefined ||
    (options as AbstractControlOptions).validators !== undefined ||
    (options as AbstractControlOptions).updateOn !== undefined
  );
}

@Injectable()
export class FormBuilder<MODEL> extends NgFormBuilder {
  constructor(private formState: PresenterStateController<MODEL, number>) {
    super();
  }

  /**
   * Eredeti angular metodust masoltuk be hogy az vegen a sajat RRFormGroup-t tudjuk hasznalni
   * @param controlsConfig
   * @param options
   */
  group(controlsConfig: { [p: string]: any }, options: AbstractControlOptions | { [key: string]: any } | null = null): FormGroup {
    const controls = (this as any)._reduceControls(controlsConfig);

    let validators: ValidatorFn | ValidatorFn[] | null = null;
    let asyncValidators: AsyncValidatorFn | AsyncValidatorFn[] | null = null;
    let updateOn: any | undefined;

    if (options != null) {
      if (isAbstractControlOptions(options)) {
        // `options` are `AbstractControlOptions`
        validators = options.validators != null ? options.validators : null;
        asyncValidators = options.asyncValidators != null ? options.asyncValidators : null;
        updateOn = options.updateOn != null ? options.updateOn : undefined;
      } else {
        // `options` are legacy form group options
        validators = options['validator'] != null ? options['validator'] : null;
        asyncValidators = options['asyncValidator'] != null ? options['asyncValidator'] : null;
      }
    }

    const newGroup = new RRFormGroup(controls, { asyncValidators, updateOn, validators });
    this.formState.formGroup$.next(newGroup);
    return newGroup;
  }
}
