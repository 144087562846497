import { isBoolean } from '@roadrecord/type-guard';

export interface IsLazyDetailsInterface {
  isLazy: boolean;
  lazyCmp: any;
}

export function isLazyDetailsCmp(obj: any): obj is IsLazyDetailsInterface {
  return isBoolean(obj['isLazy']);
}
