import {
  liveAutoCompleteCommonDiFactory,
  LiveAutoCompleteEditButtonOptionModel,
  LiveAutoCompleteNewButtonOptionModel,
  LiveAutoCompleteOptionsConfigModel,
} from '@roadrecord/live-auto-complete';
import { PartnerListModel } from '@roadrecord/partner/model';
import { FragmentDialogTypeEnum } from '@roadrecord/fragment-dialog';

export function commonHeadOfficeOrSiteLiveAutoCompleteDiFactory(config: LiveAutoCompleteOptionsConfigModel<PartnerListModel>) {
  return liveAutoCompleteCommonDiFactory(
    'common--head-office-or-site',
    () =>
      new LiveAutoCompleteOptionsConfigModel<PartnerListModel>({
        ...config,
        newOptions: new LiveAutoCompleteNewButtonOptionModel({
          label: 'PARTNER.LIVE_AUTO_COMPLETE.ADD_NEW_LABEL.HEAD_OR_OFFICE_SITE',
          newFragment: FragmentDialogTypeEnum.NEW_HEAD_OR_OFFICE_SITE,
          showTopRightCloseButton: config.newOptions.showTopRightCloseButton,
        }),
        editOptions: new LiveAutoCompleteEditButtonOptionModel({
          editFragment: FragmentDialogTypeEnum.EDIT_HEAD_OR_OFFICE_SITE,
          showTopRightCloseButton: config.newOptions.showTopRightCloseButton,
        }),
        validatorMessages: [{ errorKey: 'required', translateKey: 'PARTNER.LIVE_AUTO_COMPLETE.VALIDATOR.REQUIRED.HEAD_OR_OFFICE_SITE' }],
      })
  );
}
