import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslocoModule } from '@ngneat/transloco';
import { UnauthenticatedFooterComponent } from './unauthenticated-footer/unauthenticated-footer.component';

@NgModule({
  imports: [CommonModule, TranslocoModule, MatDialogModule, MatSnackBarModule],
  declarations: [UnauthenticatedFooterComponent],
  exports: [UnauthenticatedFooterComponent],
})
export class AuthenticationModule {}
