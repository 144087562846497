import { deepEqual, RootInjectorStore } from '@roadrecord/common/common';
import { isBoolean, isNil, isTrue } from '@roadrecord/type-guard';
import { Observable } from 'rxjs';
import { getKeysByOtherModel } from '../get-keys-by-model.function';
import { checkModifiedFormPopup } from './check-modified-form-popup.function';
import { MessageDialogResultInterface } from '@roadrecord/message-dialog';
import { NgZone } from '@angular/core';
import { Store } from '@ngxs/store';
import { CheckModifierSubmitAction } from '../state/action/check-modifier-submit.action';
import { take } from 'rxjs/operators';
import { HasViewSubmitPlugin } from '../../controller/plugin/view-submit/has-view-submit-plugin.interface';
import { CheckModifiedManualFormGuardOptions } from '../check-modified-manual-form-guard.service';

const logScopeName = 'CheckModifiedForm';
/**
 * a metodussal ossze tudunk 2 model-t vetni es amennyiben nem egyezik meg ugy
 * egy elore meghatarozott szoveget dobunk
 * @param formModel form-ban tarolt adatmodel
 * @param originalModel eredeti szerkesztett model
 * @param resolve callback metodusa
 */
export function checkModifiedForm<MODEL>({
  formModel,
  originalModel,
  routedDataFormCmpRef,
  viewSubmitPluginId,
  options = { enableAfterSaveFirstButton: true },
}: {
  formModel: MODEL;
  originalModel: MODEL;
  routedDataFormCmpRef?: HasViewSubmitPlugin<MODEL>;
  viewSubmitPluginId?: number;
  options?: CheckModifiedManualFormGuardOptions;
}): Observable<MessageDialogResultInterface> {
  // console.info(logScopeName, 'check formModel: ', formModel);
  // console.info(logScopeName, 'check originalModel: ', originalModel);

  return new Observable<MessageDialogResultInterface>(observer => {
    const finishObserver = (result: MessageDialogResultInterface) => {
      if (isNil(result)) {
        result = { result: false };
      }
      if (isBoolean(result.afterOkFirst) && isTrue(result.afterOkFirst)) {
        RootInjectorStore.getFromRootInjector(NgZone)
          /* hogy minden befejezodjon */
          .onStable.pipe(take(1))
          .subscribe(() =>
            RootInjectorStore.getFromRootInjector(Store).dispatch(new CheckModifierSubmitAction(routedDataFormCmpRef, viewSubmitPluginId))
          );
      }
      observer.next(result);
      observer.complete();
    };
    if (formModel === originalModel || (isNil(formModel) && isNil(originalModel))) {
      return finishObserver({ result: true });
    }
    let isEqual = false;
    try {
      const partialLocalDataModel =
        isNil(options.checkRules) || options.checkRules.simpleDeepEqual === false
          ? getKeysByOtherModel<any>(formModel, originalModel)
          : originalModel;
      // console.log('formmodel', formModel);
      // console.log('partial', partialLocalDataModel);
      if (deepEqual(formModel, partialLocalDataModel)) {
        isEqual = true;
      }
    } catch (e) {
      console.error(e);
      // elengedjuk ha hiba van akkor valoszinuleg nincs kulonbseg
    }
    if (!isEqual) {
      checkModifiedFormPopup(finishObserver, isNil(options.enableAfterSaveFirstButton) || isTrue(options.enableAfterSaveFirstButton));
    } else {
      return finishObserver({ result: isEqual });
    }
  });
}
