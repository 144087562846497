import { BehaviorSubject, Observable } from 'rxjs';
import { MenuService } from '../menu.service';
import { MenuItemModel } from './menu-item.model';
import { isNil } from '@roadrecord/type-guard';

interface MenuPageModelConstructorInterface {
  uniqId: string;
  groupUniqId?: string;
  label: string;
  route: string;
  icon: string;
  items: MenuItemModel[];
  customAreaComponent?: any;
  visible?: boolean;
  /**
   * csak akkor ervenyes ha visible false, es arra valo hogy jelezzuk alapbol nem megjeleno menupont
   */
  hidden?: boolean;
  defaultRoute?: string;
  customBackButtonLabel?: string;
  customBackButtonLabelCallback?: (menuService: MenuService) => void;
  contextCallback?: () => { deps?: any[]; callback: (...args: any[]) => void };
}

export class MenuPageModel {
  readonly uniqId: string;
  readonly groupUniqId: string;
  readonly label: string;
  readonly route: string;
  readonly icon: string;
  readonly customAreaComponent: any;
  visible = true;
  hidden = false;
  customBackButtonLabel: string;
  defaultRoute?: string;
  customBackButtonLabelCallback: (menuService: MenuService) => void;
  contextCallback: () => { deps?: any[]; callback: (...args: any[]) => void };

  private readonly _items$: BehaviorSubject<MenuItemModel[]>;

  constructor(readonly options: MenuPageModelConstructorInterface) {
    let items = options.items;
    delete options.items;

    if (isNil(items) || !Array.isArray(items)) {
      items = [];
    } else {
      items.forEach(item => {
        if (!(item instanceof MenuItemModel)) {
          throw new Error('Menu item is not MenuItemModel');
        }
      });
    }
    this._items$ = new BehaviorSubject<MenuItemModel[]>(items);

    Object.assign(this, options);
  }

  get items$(): Observable<MenuItemModel[]> {
    return this._items$.asObservable();
  }

  set items(items: MenuItemModel[]) {
    this._items$.next(items);
  }

  get itemsLastValue(): MenuItemModel[] {
    return this._items$.getValue();
  }
}
