import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LocationModel } from '@roadrecord/partner/model';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { fadeInLeftFast, fadeInRightFast } from '@roadrecord/animations';

export const trackByFn = (index, item: LocationModel) => {
  return item.hash;
};

@Component({
  selector: 'rr-search-on-map-list',
  templateUrl: './search-on-map-list.component.html',
  styleUrls: ['./search-on-map-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInRightFast, fadeInLeftFast],
})
export class SearchOnMapListComponent {
  @Input() results: LocationModel[];
  @Input() selectedLocation: LocationModel;
  @Input() valid: boolean;
  @Input() invalidText: string;
  @Output() clickItem = new EventEmitter<LocationModel>();
  @Output() clickShowOnMap = new EventEmitter<LocationModel>();

  hoverIndex = -1;
  trackByFn = trackByFn;
  translatePrefix = 'PARTNER.DETAILS.SEARCH_ON_MAP.RESULT.';

  constructor(private gtmService: GoogleTagManagerService) {}

  onClick(location: LocationModel) {
    this.clickItem.emit(location);
  }

  onMouseEnter(index: number) {
    this.hoverIndex = index;
  }

  onMouseLeave() {
    this.hoverIndex = -1;
  }

  onClickShowOnMap(location: LocationModel) {
    this.clickShowOnMap.emit(location);
  }

  onClickListItem(location: LocationModel) {
    this.gtmService.pushTag({
      event: `Select with single click on list item`,
      category: 'RROHU-2784',
    });
    this.selectedLocation = location;
    this.onClickShowOnMap(location);
  }

  onDblClickListItem(location: LocationModel) {
    this.gtmService.pushTag({
      event: `Select with double click on list item`,
      category: 'RROHU-2784',
    });

    this.onClick(location);
  }

  onClickSelectButton(location: LocationModel) {
    this.gtmService.pushTag({
      event: `Select with select button`,
      category: 'RROHU-2784',
    });

    this.onClick(location);
  }

  onClickShowOnMapButton(location: LocationModel) {
    this.gtmService.pushTag({
      event: `Select with show on map button`,
      category: 'RROHU-2784',
    });
    this.onClickShowOnMap(location);
  }
}
