import { Provider } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { RRBaseFormErrorStateMatcher } from './rr-base-form-error-state-matcher';

export const rrFormErrorStateMatcher: Provider = [
  {
    provide: ErrorStateMatcher,
    useClass: RRBaseFormErrorStateMatcher,
  },
];
