import { FragmentDialogTypeEnum } from '@roadrecord/fragment-dialog';
import { isNil, isString } from '@roadrecord/type-guard';
import { PartnerListModel, PartnerTypeEnum } from '@roadrecord/partner/model';
import {
  LiveAutocompleDisplayFunction,
  LiveAutoCompleteEditButtonOptionModel,
  LiveAutoCompleteNewButtonOptionModel,
  LiveAutocompleteOptionDisabledBindFunction,
  LiveAutocompleteOptionDisplayBindFunction,
  LiveAutocompleteOptionModelIconFunction,
  LiveAutoCompleteOptionsConfigModel,
  LiveAutocompleteOptionValueBindFunction,
  LiveAutocompletePrefixIconFunction,
} from '@roadrecord/live-auto-complete';
import { setIconFnPartner } from '@roadrecord/partner/common';

export const laPartnerOptionDisabledBindFn: LiveAutocompleteOptionDisabledBindFunction<PartnerListModel> = (
  model: PartnerListModel,
  selectedModel: PartnerListModel
) => model.id === selectedModel.id;
export const laPartnerOptionDisplayFn: LiveAutocompleteOptionDisplayBindFunction<PartnerListModel> = (model: PartnerListModel) =>
  `${model.name} (${model.address.postcode}, ${model.address.city})`;
export const laPartnerOptionValueBindFn: LiveAutocompleteOptionValueBindFunction<PartnerListModel> = (model: PartnerListModel) =>
  !isNil(model) ? model.id : null;
export const laPartnerPrefixIconFn: LiveAutocompletePrefixIconFunction<PartnerListModel> = (model: PartnerListModel) =>
  isNil(model) || isString(model) ? undefined : setIconFnPartner(model).value;
export const laPartnerOptionModelIconFn: LiveAutocompleteOptionModelIconFunction<PartnerListModel> = (model: PartnerListModel) => ({
  icon: setIconFnPartner(model).value,
});
export const laPartnerDisplayFn: LiveAutocompleDisplayFunction<PartnerListModel> = (model: PartnerListModel) =>
  `${model.name} (${model.address.postcode}, ${model.address.city})`;

export const laPartnerOptionsConfig = new LiveAutoCompleteOptionsConfigModel({
  optionDisabledBindFn: laPartnerOptionDisabledBindFn,
  optionDisplayFn: laPartnerOptionDisplayFn,
  optionValueBindFn: laPartnerOptionValueBindFn,
  optionModelIconFn: laPartnerOptionModelIconFn,
  displayFn: laPartnerDisplayFn,
  prefixIconFn: laPartnerPrefixIconFn,
  placeholder: 'PARTNER.LIVE_AUTO_COMPLETE.PLACEHOLDER.PARTNER',
  newOptions: new LiveAutoCompleteNewButtonOptionModel({
    label: 'PARTNER.LIVE_AUTO_COMPLETE.ADD_NEW_LABEL.PARTNER',
    newFragment: FragmentDialogTypeEnum.NEW_PARTNER,
    showTopRightCloseButton: true,
  }),
  editOptions: new LiveAutoCompleteEditButtonOptionModel<PartnerListModel>({
    editFragment: entity => {
      switch (entity.type) {
        case PartnerTypeEnum.PARTNER:
          return FragmentDialogTypeEnum.EDIT_PARTNER;
        case PartnerTypeEnum.CHARGING_STATION:
          return FragmentDialogTypeEnum.EDIT_CHARGING_STATION;
        case PartnerTypeEnum.HEAD_OFFICE_OR_SITE:
          return FragmentDialogTypeEnum.EDIT_HEAD_OR_OFFICE_SITE;
      }
      return FragmentDialogTypeEnum.EDIT_PARTNER;
    },
    showTopRightCloseButton: true,
  }),
  validatorMessages: [{ errorKey: 'required', translateKey: 'PARTNER.LIVE_AUTO_COMPLETE.VALIDATOR.REQUIRED.PARTNER' }],
  lazyOptionElem: {
    name: 'partner-live-auto-complete-option',
    overlayClass: 'from-the-partner-base',
    options: {
      addressTextCb: model => `${model.address.postcode} ${model.address.city} ${model.address.street} ${model.address.house_number}`,
      extraRowConfig: null,
    },
  },
});
