import { PartnerTypeEnum } from '@roadrecord/partner/model';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { PartnerLocationHttpListResponseModel } from '../../../../../common/src/lib/partner.service';
import { Notification, NotificationsService } from '@roadrecord/external-packages/angular2-notifications';
import { TranslocoService } from '@ngneat/transloco';

export const warnNotificationCopmanySearchDoesNotWorkingRef: { ref: Notification } = { ref: null };

export function searchOnMapCMPAfterSearchByPartnerNameOrAddressHook(
  partnerType: PartnerTypeEnum,
  response: PartnerLocationHttpListResponseModel,
  notificationsService: NotificationsService,
  translocoService: TranslocoService
) {
  if (partnerType !== PartnerTypeEnum.CHARGING_STATION && response.meta.company_search === false) {
    warnNotificationCopmanySearchDoesNotWorkingRef.ref = notificationsService.warn(
      '',
      translocoService.translate('PARTNER.DETAILS.SEARCH_ON_MAP.COMPANY_SEARCH_DOES_NOT_WORKING')
    );
  }
}
