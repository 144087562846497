import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanyMeStateUpdateResolverService } from './company-me-state-update-resolver.service';
import { SubscriptionDataComponent } from './subscription-data/subscription-data.component';
import { InvalidPeriodContextGuard } from '@roadrecord/period-context/common';

const routes: Routes = [
  {
    path: '',
    component: SubscriptionDataComponent,
    resolve: {
      x: CompanyMeStateUpdateResolverService,
    },
    canDeactivate: [InvalidPeriodContextGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CompanyMeStateUpdateResolverService],
})
export class SubscriptionDataRoutingModule {}
