import { Action, Selector, State, StateContext } from '@ngxs/store';
import { VehicleAccountStateModel } from './model/vehicle-account-state.model';
import { VehicleAccountModeEnableAction } from './action/vehicle-account-mode-enable.action';
import { VehicleAccountModeDisableAction } from './action/vehicle-account-mode-disable.action';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';

@State<VehicleAccountStateModel>({
  name: 'vehicleAccount',
  defaults: {
    accountModeEnable: false,
    plateNumber: undefined,
    version: 3,
  },
})
@Injectable()
export class VehicleAccountState {
  @Selector()
  @ImmutableSelector()
  public static accountModeEnable(state: VehicleAccountStateModel): boolean {
    return state.accountModeEnable;
  }

  @Selector()
  @ImmutableSelector()
  public static plateNumber(state: VehicleAccountStateModel): string {
    return state.plateNumber;
  }

  @Action(VehicleAccountModeEnableAction)
  @ImmutableContext()
  vehicleAccountModeEnable({ setState }: StateContext<VehicleAccountStateModel>, action: VehicleAccountModeEnableAction) {
    setState(state => {
      state = { ...state, accountModeEnable: true, plateNumber: action.plateNumber };
      return state;
    });
  }

  @Action(VehicleAccountModeDisableAction)
  @ImmutableContext()
  vehicleAccountModeDisable({ setState }: StateContext<VehicleAccountStateModel>) {
    setState(state => {
      state = { ...state, accountModeEnable: false };
      return state;
    });
  }
}
