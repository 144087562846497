<div fxLayout="row" fxLayoutAlign="center center" class="filter-wrapper">
  <rr-filter-panel
    *rrNgIfTyped="currentFilter$ | async as currentFilter"
    [countAll]="countAll$ | async"
    [countError]="countError$ | async"
    [countOK]="countOK$ | async"
    [countWarning]="countWarning$ | async"
    [currentFilter]="currentFilter"
    [windowMode]="windowMode"
  ></rr-filter-panel>
  <span class="flex-spacer"></span>
  <ng-container *ngIf="!windowMode">
    <div class="button-disable-tooltip-wrapper" [matTooltip]="'RECOMMENDATION.PANEL.TOOLTIP.CORRECT' | transloco">
      <button
        class="recommendation-continue-button"
        type="button"
        mat-raised-button
        color="primary"
        (click)="onClickRecommendation()"
        [disabled]="(countError$ | async) > 0"
      >
        {{ 'RECOMMENDATION.PANEL.CONTINUE' | transloco }}
      </button>
    </div>
  </ng-container>
</div>
<div
  [ngStyle]="{
    'padding.px': 2,
    'height.%': 100,
    'max-height': 'calc(100% - 36px)'
  }"
  class="scroll"
>
  <mat-accordion *ngIf="checkResults$ | async | recommendationCheckListFilter: (currentFilter$ | async) as checkResults" [multi]="true">
    <mat-expansion-panel *ngFor="let check of checkResults" [expanded]="check.status !== RecommendationStatusEnum.OK">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <ng-container [ngSwitch]="check.status">
            <ng-container *ngSwitchCase="RecommendationStatusEnum.OK">
              <span class="status-chip check-success">{{ 'RECOMMENDATION.PANEL.STATUS.OK' | transloco }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="RecommendationStatusEnum.WARNING">
              <span class="status-chip check-warning">{{ 'RECOMMENDATION.PANEL.STATUS.WARNING' | transloco }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="RecommendationStatusEnum.ERROR">
              <span class="status-chip check-error">{{ 'RECOMMENDATION.PANEL.STATUS.ERROR' | transloco }}</span>
            </ng-container>
          </ng-container>
          <span class="title">{{ check.title }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <p>
          <b>{{ 'RECOMMENDATION.PANEL.CONTENT.RESULT_LABEL' | transloco }}</b
          >&nbsp;<span [innerHTML]="check.result | textPlaceholderHtmlTagReplacer"></span>
        </p>
      </ng-template>
      <mat-action-row *ngIf="check?.actions?.length > 0">
        <button
          rrPreventClick
          type="button"
          mat-stroked-button
          color="warn"
          *ngFor="let action of check.actions"
          (click)="onClickAction(action.actions)"
        >
          {{ action.label | getLabelByCurrentLang | async }}
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</div>
