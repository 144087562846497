import { GridColumnModel } from '@roadrecord/grid';
import { commonPartnerListColumnConfig } from './common-partner-list-column.config';
import { TranslocoService } from '@ngneat/transloco';
import { PartnerAddressFormatterReturnType, PartnerListModel } from '@roadrecord/partner/model';

// tslint:disable
export const chargingStationListColumnConfig: (_this: {
  translocoService: TranslocoService;
  partnerAddressFormatter: PartnerAddressFormatterReturnType;
}) => GridColumnModel<PartnerListModel>[] = (_this: {
  translocoService: TranslocoService;
  partnerAddressFormatter: PartnerAddressFormatterReturnType;
}): GridColumnModel<PartnerListModel>[] => {
  const commonListColumnConfig = commonPartnerListColumnConfig(_this);
  commonListColumnConfig.splice(1, 1);

  return commonListColumnConfig;
};
