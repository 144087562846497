import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { AbstractEntityService, HttpListResponseModel } from '@roadrecord/utils';
import { isNil, isNumeric } from '@roadrecord/type-guard';
import { MileageListModel, MileageModel } from '@roadrecord/mileage/model';
import { PartnerService } from '@roadrecord/partner/common';
import { StartData } from './model/start-data';
import { EndData } from './model/end-data';
import { isStartData } from './model/is-start-data.function';

@Injectable()
export class MileageService extends AbstractEntityService<HttpListResponseModel<MileageListModel>, MileageModel> {
  constructor(http: HttpClient, @Optional() private partnerService: PartnerService) {
    super('mileage', '-mileage_datetime', http);
  }

  getToString(model: MileageModel): string {
    return model.date;
  }

  getModelIdValue(model: MileageModel): any {
    if (model !== undefined && model.hasOwnProperty('id') && !isNil(model.id)) {
      return model.id;
    }
    return undefined;
  }

  getData<T extends 'START' | 'END'>(type: T) {
    return this.http.get<T extends 'START' ? StartData : EndData>(`${this.getEntityApiUrl()}/${type.toLowerCase()}-of-month-mileage/`);
  }

  createOrModifyMileage<T extends 'START' | 'END'>(
    mileage: T extends 'START' ? StartData : EndData,
    type: T,
    id?: number,
    equality_of_mileages_confirmed?: 'true' | 'false'
  ) {
    const queryParams = { params: {} };
    if (type === 'END') {
      queryParams.params['equality_of_mileages_confirmed'] = equality_of_mileages_confirmed;
    }
    return this.http[isNumeric(id) ? 'put' : 'post'](
      `${this.getEntityApiUrl()}/${type.toLowerCase()}-of-month-mileage/${isNumeric(id) ? `${id}/` : ''}`,
      mileage,
      queryParams
    );
  }

  /**
   * Az  abstract entity nem tamogatja a 2  tipust 1 service-ben, ezert kellett egy kulon
   */
  remove2(mileage: StartData | EndData) {
    return this.http.delete(`${this.getEntityApiUrl()}/${isStartData(mileage) ? 'start' : 'end'}-of-month-mileage/${mileage.id}/`);
  }
}
