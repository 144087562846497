import { TranslocoService } from '@ngneat/transloco';
import { GridColumnModel } from '@roadrecord/grid';
import { TripReasonModel } from '@roadrecord/trip-reason/model';

export const listColumnConfigCommon: (_this: { translocoService: TranslocoService }) => GridColumnModel<TripReasonModel>[] = (_this: {
  translocoService: TranslocoService;
}) => {
  return [
    { name: 'trip_reason', displayNameTranslateKey: 'TRIP_REASON.LIST.COLUMN.TRIP_REASON.TITLE' },
    {
      name: 'is_private',
      displayNameTranslateKey: 'TRIP_REASON.LIST.COLUMN.IS_PRIVATE.TITLE',
      cellMatIconCallback: (model: TripReasonModel) => {
        return {
          value: model.is_fueling_station ? 'gas-station' : model.is_private ? 'human-handsdown' : 'briefcase',
          cssClassName: 'light-color',
        };
      },
      cellMatIconAfterTextCallback: (model: TripReasonModel) =>
        model.is_private ? 'TRIP_REASON.LIST.COLUMN.IS_PRIVATE.CONTENT.PRIVATE' : 'TRIP_REASON.LIST.COLUMN.IS_PRIVATE.CONTENT.BUSINESS',
    },
    {
      name: 'is_default',
      displayNameTranslateKey: 'TRIP_REASON.LIST.COLUMN.COMMENT.TITLE',
      cellContentTemplateCallback: (model: TripReasonModel) => {
        if (model.is_default) {
          return _this.translocoService.translate('TRIP_REASON.LIST.COLUMN.COMMENT.CONTENTS.DEFAULT');
        } else if (model.is_fueling_station || model.is_headquarters || (model.is_deletable === false && model.is_editable === false)) {
          return _this.translocoService.translate('TRIP_REASON.LIST.COLUMN.COMMENT.CONTENTS.ROOT_NOT_DELETABLE_AND_NOT_EDITABLE');
        } else if (model.is_deletable === false) {
          return _this.translocoService.translate('TRIP_REASON.LIST.COLUMN.COMMENT.CONTENTS.ROOT_NOT_DELETABLE');
        } else if (model.is_editable === false) {
          return _this.translocoService.translate('TRIP_REASON.LIST.COLUMN.COMMENT.CONTENTS.ROOT_NOT_EDITABLE');
        }
        return '';
      },
    },
  ];
};
