import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject, throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import { catchError, filter, mapTo, switchMap, take } from 'rxjs/operators';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { AuthState } from '../../state/auth.state';
import { RefreshTokenAction } from '../../state/action/token/refresh-token.action';
import { UserModel } from '../model/user.model';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  @SelectSnapshot(AuthState.token) token: string | null;
  private isRefreshing = false;
  private refreshTokenSubject = new Subject<string>();

  constructor(private readonly store: Store, private readonly authService: AuthService<UserModel>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.getAuthorizedRequest(req)).pipe(
      catchError(error => {
        const url = error.url.split('?')[0];
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          url.endsWith('api-token-refresh/') === false &&
          url.endsWith('logout/') === false
        ) {
          return this.handle401Error(req, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken(this.token).pipe(
        switchMap(({ token }) => this.store.dispatch(new RefreshTokenAction(token)).pipe(mapTo({ token }))),
        switchMap(({ token }) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          return next.handle(this.getAuthorizedRequest(request));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => this.store.dispatch(new RefreshTokenAction(token))),
        switchMap(() => {
          return next.handle(this.getAuthorizedRequest(request));
        })
      );
    }
  }

  private getAuthorizedRequest(req: HttpRequest<any>): HttpRequest<any> {
    if (
      req.url.startsWith('./assets/') ||
      req.url.startsWith('/assets/') ||
      req.url.startsWith('assets/') ||
      /* Árlista és a hozzá tartozó végpontok JWT token nélkül de a CRM_AUTH paramtéerrel is elérhetők */
      (req.url.indexOf('payment-manager') > -1 && req.headers.get('CRM-AUTHORIZATION')) ||
      ([
        'login',
        'auth/forgetpassword',
        'resendactivation',
        'accounts',
        'resetpassword',
        /* kulon kezeljuk az auth header-t amikor be van lepve a user */ 'version',
      ].find(route => req.url.indexOf(route) > -1) &&
        /* exclude lista, mivel vannak endpointjaink amik reszben megegyeznek emiatt kulon kell kezelni... */
        ['login-as-staff'].find(route => req.url.indexOf(route) === -1))
    ) {
      return req;
    }
    return req.clone({ headers: req.headers.set('Authorization', `JWT ${this.token}`) });
  }
}
