<mat-list>
  <mat-list-item
    *ngFor="let location of results; let index = index; trackBy: trackByFn"
    [ngClass]="{
      selected: selectedLocation === location || hoverIndex === index,
      'wrong-coordinates': selectedLocation === location && valid === false
    }"
    (click)="onClickListItem(location)"
    (dblclick)="onDblClickListItem(location)"
    matTooltipShowDelay="2000"
    [matTooltip]="(valid === false ? invalidText : translatePrefix + 'ROW_TOOLTIP') | transloco"
    (mouseenter)="onMouseEnter(index)"
    (mouseleave)="onMouseLeave()"
  >
    <h4 mat-line @fadeInLeftFast>{{ location.name }}</h4>
    <p mat-line @fadeInLeftFast>{{ location.address }}</p>
    <div @fadeInRightFast class="actions-panel">
      <div class="text-center d-inline-block mr-2"
        ><button
          id="map-result-row-select"
          rrPreventClick
          type="button"
          mat-mini-fab
          color="primary"
          class="pulse-select-button"
          (click)="onClickSelectButton(location)"
          ><mat-icon svgIcon="check"></mat-icon
        ></button>
        <small class="d-block" rrPreventClick (click)="onClickSelectButton(location)">{{
          translatePrefix + 'ACTIONS.TOOLTIP.SELECT' | transloco
        }}</small>
      </div>
      <div class="text-center d-inline-block">
        <button
          id="map-result-row-show-on-map"
          rrPreventClick
          type="button"
          mat-mini-fab
          color="accent"
          (click)="onClickShowOnMapButton(location)"
          ><mat-icon svgIcon="eye"></mat-icon
        ></button>
        <small class="d-block" rrPreventClick (click)="onClickShowOnMapButton(location)">{{
          translatePrefix + 'ACTIONS.TOOLTIP.SHOW_ON_MAP' | transloco
        }}</small>
      </div>
    </div>
  </mat-list-item>
</mat-list>
