/**
 * @deprecated
 * rr-en meg hasznaljuk, de jo lenne kikotni
 */
export const globalPhoneControlValidationMessages = [
  {
    errorKey: 'noPhoneNumber',
    translateKey: 'COMMON.VALIDATION.PHONE.NO_PHONE_NUMBER',
  },
  {
    errorKey: 'phoneNumberTooLong',
    translateKey: 'COMMON.VALIDATION.PHONE.PHONE_NUMBER_TOO_LONG',
  },
  {
    errorKey: 'phoneNumberTooShort',
    translateKey: 'COMMON.VALIDATION.PHONE.PHONE_NUMBER_TOO_SHORT',
  },
  {
    errorKey: 'phoneNumberInvalidCountryCode',
    translateKey: 'COMMON.VALIDATION.PHONE.PHONE_NUMBER_INVALID_COUNTRY_CODE',
  },
];
