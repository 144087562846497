<div class="d-flex flex-column w-100 h-100">
  <div class="d-flex flex-row w-100 h-100 align-items-center">
    <mat-card class="rr-card2">
      <mat-card-header
        ><mat-card-title>{{ 'USER_AUTHED.SUBSCRIPTION_DATA.TITLE' | transloco }}</mat-card-title></mat-card-header
      >
      <mat-card-content>
        <div class="d-flex" @fadeInEnter>
          <!-- nem ertem miert kellett, ha nem rakom be akkor az elso subscription elemet nem talalja meg egy ideig -->
          <ng-container *ngIf="companyMeState?.subscription !== null">
            <table>
              <tbody>
                <tr>
                  <td>{{ 'USER_AUTHED.SUBSCRIPTION_DATA.LABELS.COMPANY_NAME' | transloco }}</td>
                  <td>
                    {{ companyMeState.name }}
                  </td>
                </tr>
                <tr>
                  <td>{{ 'USER_AUTHED.SUBSCRIPTION_DATA.LABELS.ACTIVE_CARS_COUNT' | transloco }}</td>
                  <td>
                    {{ companyMeState.subscription.car_active_no }}
                  </td>
                </tr>
                <tr>
                  <td>{{ 'USER_AUTHED.SUBSCRIPTION_DATA.LABELS.SUBSCRIBED_CARS_COUNT' | transloco }}</td>
                  <td>
                    {{ companyMeState.subscription.car_subscribe_no }}
                  </td>
                </tr>
                <tr>
                  <td>{{ 'USER_AUTHED.SUBSCRIPTION_DATA.LABELS.SUBSCRIBED_START' | transloco }}</td>
                  <td>
                    {{
                      companyMeState.subscription?.start
                        ? (companyMeState.subscription.start | amDateFormat: 'L')
                        : ('USER_AUTHED.SUBSCRIPTION_DATA.NOT_FOUND' | transloco)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ 'USER_AUTHED.SUBSCRIPTION_DATA.LABELS.SUBSCRIBED_END' | transloco }}</td>
                  <td>
                    {{
                      companyMeState.subscription?.end
                        ? (companyMeState.subscription.end | amDateFormat: 'L')
                        : ('USER_AUTHED.SUBSCRIPTION_DATA.NOT_FOUND' | transloco)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ 'USER_AUTHED.SUBSCRIPTION_DATA.LABELS.LENGTH' | transloco }}</td>
                  <td>
                    {{
                      companyMeState.subscription?.length
                        ? companyMeState.subscription.length
                        : ('USER_AUTHED.SUBSCRIPTION_DATA.NOT_FOUND' | transloco)
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
        <button type="button" mat-raised-button color="primary" (click)="onClickSubscriptionGoTo()">{{
          'USER_AUTHED.SUBSCRIPTION_DATA.GO_TO_BUTTON.' + SubscriptionInfoEnumValues[subscriptionInfo] | transloco
        }}</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
