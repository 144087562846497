import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { ApplicationSettingsService } from './application-settings.service';
import { Store } from '@ngxs/store';

export function createApplicationSettingsServiceInjector(injector: Injector): Injector {
  return Injector.create({
    providers: [
      {
        provide: ApplicationSettingsService,
        useFactory: (http: HttpClient, store: Store) => {
          return new ApplicationSettingsService(http, store);
        },
        deps: [HttpClient, Store],
      },
    ],
    parent: injector,
  });
}
