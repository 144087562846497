import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoModule } from '@ngneat/transloco';
import { MomentModule } from 'ngx-moment';

import { SubscriptionDataRoutingModule } from './subscription-data-routing.module';
import { SubscriptionDataComponent } from './subscription-data/subscription-data.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [SubscriptionDataComponent],
  imports: [
    CommonModule,
    SubscriptionDataRoutingModule,
    MatCardModule,
    MatProgressBarModule,
    MomentModule,
    TranslocoModule,
    MatButtonModule,
  ],
})
export class SubscriptionDataModule {}
