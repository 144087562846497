<mat-toolbar color="primary">
  <mat-toolbar-row class="sticky-notification-container">
    <rr-sticky-notification-bar
      *ngIf="stickyNotificationOptions"
      [closeActionText]="stickyNotificationOptions.closeActionText"
      [hasCloseButton]="stickyNotificationOptions.hasCloseButton"
      [text]="stickyNotificationOptions.text"
      [showCountDownTimer]="stickyNotificationOptions.showCountDownTimer"
      [textTranslateParams]="stickyNotificationOptions?.textTranslateParams"
      (dismissed)="onDismissStickyNotification($event)"
    ></rr-sticky-notification-bar>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <div id="app-toolbar-wrapper" fxLayout="row" fxLayoutAlign="start center" fxFlexFill>
      <ng-container *ngIf="isLoggedIn$ | async">
        <ng-container *ngIf="user$ | async as user">
          <div class="main-menu-button-container" fxFlex="50">
            <button
              type="button"
              *ngIf="showMenuButtons && !firstStepsMode && !is404ErrorPage && (isExpiredTrialTime$ | async) === false"
              @fadeInEnter
              mat-icon-button
              (click)="sideToggle()"
              fxFlexAlign="start"
              [matTooltip]="
                openedMenu === true
                  ? ('APP_LAYOUT.NAV_TOOLBAR.TOOLTIP.CLOSE_MENU' | transloco)
                  : ('APP_LAYOUT.NAV_TOOLBAR.TOOLTIP.OPEN_MENU' | transloco)
              "
              matTooltipPosition="right"
            >
              <mat-icon svgIcon="backburger" [ngClass]="{ closed: openedMenu === false }"></mat-icon>
            </button>
          </div>
        </ng-container>
      </ng-container>
      <div [ngClass]="{ 'margin-center': (isLoggedIn$ | async) === false }"
        ><a
          [href]="'APP_LAYOUT.NAV_TOOLBAR.HEADER_LOGO_URL' | transloco"
          target="_blank"
          style="display: inline-grid; vertical-align: middle"
          ><img src="assets/images/slogan.svg" style="width: 180px" /></a
      ></div>
      <ng-container *ngIf="isLoggedIn$ | async">
        <ng-container *ngIf="user$ | async as user">
          <div *ngIf="showMenuButtons" fxFlex="50" fxLayoutAlign="end" id="account-bar" @fadeInEnter>
            <ng-container *ngIf="!isWebadmin && (user?.staff_id | isPresent)">
              <div class="switched-user-information-panel">
                {{ 'APP_LAYOUT.NAV_TOOLBAR.SWITCH_USER.PANEL.MODE' | transloco }}<br />{{
                  'APP_LAYOUT.NAV_TOOLBAR.SWITCH_USER.PANEL.SWITCHED_USER_NAME' | transloco
                }}&nbsp;<span class="email">{{ user.email | textEllipsis: { maxChars: 24 } }}</span>
              </div>
              <button
                type="button"
                mat-mini-fab
                [matTooltip]="'APP_LAYOUT.NAV_TOOLBAR.SWITCH_USER.TOOLTIP' | transloco"
                class="switch-user mr-1"
                (click)="onClickSwitchUser()"
              >
                <mat-icon class="mr-0 small" svgIcon="login"></mat-icon>
              </button>
            </ng-container>
            <rr-nav-toolbar-help-menu *ngIf="!isWebadmin && (isExpiredTrialTime$ | async) === false"></rr-nav-toolbar-help-menu>
            <button
              type="button"
              @fadeInEnter
              mat-icon-button
              class="help-video-button user-menu-icon-button"
              [matTooltip]="'APP_LAYOUT.NAV_TOOLBAR.TOOLTIP.PROFILE' | transloco"
              matTooltipPosition="below"
              [matMenuTriggerFor]="userMenu"
            >
              <mat-icon svgIcon="account-circle"></mat-icon>
            </button>
            <mat-menu #userMenu="matMenu" class="navbar-user-menu">
              <ng-template matMenuContent>
                <a class="user-info" mat-menu-item disabled *ngIf="user">
                  <span class="circle">
                    {{
                      'APP_LAYOUT.PROFILE.MONOGRAM'
                        | transloco
                          : { first_name: user.first_name.charAt(0).toUpperCase(), last_name: user.last_name.charAt(0).toUpperCase() }
                    }}
                  </span>
                  <span class="text"
                    ><span class="user-full-name">{{
                      'APP_LAYOUT.PROFILE.NAME' | transloco: { last_name: user.last_name, first_name: user.first_name }
                    }}</span
                    ><br /><span class="user-email" [matTooltip]="user.email.length > 23 ? user.email : undefined">{{
                      user.email | textEllipsis: { maxChars: 23 }
                    }}</span
                    ><br /><span
                      class="company-label"
                      *ngIf="companyName$ | async as companyName"
                      [matTooltip]="companyName?.length > 23 ? companyName : undefined"
                      >{{ companyName | textEllipsis: { maxChars: 23 } }}</span
                    ></span
                  >
                </a>
                <button
                  type="button"
                  mat-menu-item
                  *ngIf="!isWebadmin && (isExpiredTrialTime$ | async) === false"
                  (click)="openMyProfileMenu()"
                  [disabled]="
                    firstStepsMode ||
                    (viewportService.breakpoint$ | async)?.mobile ||
                    menuService.selectedOneMenuPage === 'USER_PROFILE_MENU'
                  "
                >
                  <mat-icon svgIcon="account" style="margin-top: -4px"></mat-icon>
                  <span>{{ 'APP_LAYOUT.NAV_TOOLBAR.ITEM.PROFILE' | transloco }}</span>
                </button>
                <ng-container *ngIf="!isWebadmin">
                  <ng-container *rrNgIfTyped="expiredSubscription$ | async as expiredSubscription">
                    <a
                      *ngIf="!(expiredSubscription | isNil)"
                      mat-menu-item
                      rrPreventClick
                      (click)="onClickExpiresSubscription(expiredSubscription)"
                    >
                      <mat-icon svgIcon="tag-text-outline"></mat-icon>
                      <span>{{ 'APP_LAYOUT.NAV_TOOLBAR.ITEM.' + SubscriptionInfoEnumValues[expiredSubscription] | transloco }}</span></a
                    >
                  </ng-container>
                  <a *ngIf="showOnnSaleEndDate$ | async" mat-menu-item rrPreventClick (click)="onClickOnSaleEndDate()"
                    ><mat-icon svgIcon="tag-text-outline"></mat-icon
                    ><span>{{ 'APP_LAYOUT.NAV_TOOLBAR.ITEM.ON_SALE_END_DATE' | transloco }}</span></a
                  >
                </ng-container>
                <!--                <button mat-menu-item [matMenuTriggerFor]="languageMenu" *ngIf="false">-->
                <!--                  <mat-icon svgIcon="web"></mat-icon>{{ 'APP_LAYOUT.NAV_TOOLBAR.SELECT_LANGUAGE' | transloco }}-->
                <!--                </button>-->
                <button mat-menu-item (click)="logout($event)" type="button">
                  <mat-icon style="margin-top: -4px" svgIcon="exit-to-app"></mat-icon>{{ 'APP_LAYOUT.NAV_TOOLBAR.LOGOUT' | transloco }}
                </button>

                <div *ngIf="!isWebadmin" fxLayout="row" fxLayoutAlign="center center" class="footer">
                  <div class="wid100" fxLayoutAlign="center center">
                    <a
                      mat-icon-button
                      [href]="'APP_LAYOUT.NAV_TOOLBAR.FOOTER.URLS.FACEBOOK' | transloco"
                      target="_blank"
                      [matTooltip]="'APP_LAYOUT.NAV_TOOLBAR.FOOTER.CHECK_FB_PAGE' | transloco"
                    >
                      <mat-icon class="light-color" svgIcon="facebook"></mat-icon>
                    </a>
                  </div>
                  <div class="wid100" fxLayoutAlign="center center">
                    <a
                      mat-icon-button
                      [href]="'APP_LAYOUT.NAV_TOOLBAR.FOOTER.URLS.BLOG' | transloco"
                      target="_blank"
                      [matTooltip]="'APP_LAYOUT.NAV_TOOLBAR.FOOTER.CHECK_BLOG' | transloco"
                    >
                      <mat-icon class="light-color" svgIcon="blogger"></mat-icon>
                    </a>
                  </div>
                </div>

                <mat-menu #languageMenu="matMenu" *ngIf="false">
                  <ng-container *ngFor="let language of availableLanguages">
                    <button
                      *ngIf="(currentLangCode$ | async) !== language; else selectedLanguage"
                      mat-menu-item
                      (click)="setLang($any(language))"
                    >
                      {{ 'COMMON.LANGUAGES.' + language | transloco }}
                    </button>
                    <ng-template #selectedLanguage>
                      <button disabled mat-menu-item>{{ 'COMMON.LANGUAGES.' + language | transloco }}</button>
                    </ng-template>
                  </ng-container>
                </mat-menu>
              </ng-template>
            </mat-menu>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
