import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { WINDOW } from '@roadrecord/common/common';
import { I18nState } from '@roadrecord/i18n-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NextStepConfig } from '../../next-step.config';
import { environment } from '@roadrecord/environment';

@UntilDestroy()
@Component({
  selector: 'rr-finish-mobile-layout',
  templateUrl: './finish-mobile-layout.component.html',
  styleUrls: ['./finish-mobile-layout.component.scss'],
})
export class FinishMobileLayoutComponent {
  @Output()
  readonly nextStep = new EventEmitter<NextStepConfig>();
  disableButton = false;
  selectedLang: string;
  appleStoreUrl = environment.badge.ios.url;
  googleStoreUrl = environment.badge.android.url;
  imageUrl = 'assets/images/stepper/rr_fb_slider_1200x628_sarmos_csavo_20201204_en.jpg';

  constructor(private store: Store, @Inject(WINDOW) private window: Window) {
    this.store
      .select(I18nState.selectedLang)
      .pipe(untilDestroyed(this))
      .subscribe(selectedLang => (this.selectedLang = selectedLang));
  }

  onClickNextStep(): void {
    this.disableButton = true;
    this.nextStep.emit({ layoutName: 'FINISH_MOBILE' });
  }
}
