import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RecommendationState } from '../recommendation/state/recommendation.state';

@Injectable()
export class CheckIsRunningProcessGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.store.selectSnapshot<boolean>(RecommendationState.run)) {
      return true;
    } else {
      this.router.navigate(['/month-activities/finalization-of-route']);
      return false;
    }
  }
}
