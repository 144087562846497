import { FuelingSettings } from '@roadrecord/fueling/common';
import { globalImaskNumberConfigGenerator } from '@roadrecord/imask/common';

const commonNumberMaskConfig = { scale: 0, max: 999999 };

export const fuelingSettings: FuelingSettings = {
  warning: {
    quantity: { common: 80, electric: 100 },
    cost: 50000,
  },
  mask: {
    cost: globalImaskNumberConfigGenerator(commonNumberMaskConfig),
    quantity: globalImaskNumberConfigGenerator({ max: 999 }),
    distance: globalImaskNumberConfigGenerator(commonNumberMaskConfig),
    mileage: globalImaskNumberConfigGenerator({ ...commonNumberMaskConfig, scale: 1 }),
  },
};
