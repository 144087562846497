<div class="w-100" *transloco="let t; read: 'MILEAGE.DETAILS.DATA_FORM.LABEL'">
  <h3 class="mb-0">{{ 'MILEAGE.DETAILS.DATA_FORM.TITLE' | transloco }}</h3>
  <mat-form-field class="w-100">
    <mat-label>{{ t('MILEAGE') }}</mat-label>
    <input
      name="mileage"
      type="text"
      [placeholder]="t('MILEAGE')"
      matInput
      [formControl]="mileageControl"
      [imask]="mileageNumberMask"
      unmask="typed"
    />
    <span matSuffix>{{ 'COMMON.UNIT.KM' | transloco }}</span>
    <mat-hint class="new-car-hint" *ngIf="data.layoutType === 'END'">
      {{
        'FUELING_RANGE.ACTUAL_MILEAGE_TEXT'
          | transloco
            : {
                actualMileage: $any(data.model).actual_mileage
              }
      }}
    </mat-hint>
    <mat-error><rr-validation-messages [errors]="mileageControl?.errors"></rr-validation-messages></mat-error>
  </mat-form-field>
  <mat-form-field class="w-100">
    <mat-label>{{ t('DAY') }}</mat-label>
    <input
      [min]="minDate"
      [max]="maxDate"
      type="text"
      matInput
      [matDatepicker]="picker"
      [placeholder]="t('DAY')"
      [formControl]="dateControl"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-hint class="new-car-hint">{{ t(data.layoutType + '_NEW_CAR_HINT') }}</mat-hint>
    <mat-error><rr-validation-messages [errors]="dateControl?.errors"></rr-validation-messages></mat-error>
  </mat-form-field>
  <ng-container *ngIf="data.layoutType === 'START'">
    <rr-live-auto-complete
      *ngIf="pickupMode === true"
      [entityService]="partnerService"
      [databaseCallback]="getPartnerLiveAutocompleteCallback()"
      hasPrefix="true"
      [optionsConfig]="laPartnerOptionsConfig"
      [hasActionsMenu]="true"
      [formControlRef]="partner_idControl"
      placeholder="MILEAGE.DETAILS.DATA_FORM.LABEL.PARTNER_ID"
      name="partner_id"
      (openNew)="onClickNewPartner($event)"
    ></rr-live-auto-complete>
  </ng-container>
</div>
<div class="d-flex mt-3 actions-row">
  <button
    type="button"
    mat-icon-button
    [disabled]="loading"
    (click)="onClickCancel()"
    matTooltipPosition="right"
    [matTooltip]="'COMMON.ACTION.TOOLTIP.CANCEL' | transloco"
  >
    <mat-icon svgIcon="arrow-left"></mat-icon>
  </button>
  <span class="flex-spacer"></span>
  <rr-details-button-row
    [disabled]="loading"
    [form]="form"
    [isNew]="data.model?.id | isNil"
    [hasSubmitAndNew]="false"
    [hasDelete]="!(data.model?.id | isNil)"
    [texts]="{
      actions: {
        save: 'COMMON.ACTION.ADD',
        edit: 'COMMON.ACTION.EDIT'
      }
    }"
    (submitForm)="onClickSubmit()"
    (delete)="onDelete()"
  ></rr-details-button-row>
</div>
<mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>
