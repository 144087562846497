import { VehicleHuModel } from './vehicle-hu.model';

export class VehicleResetHuModel implements VehicleHuModel {
  vehicle_type = undefined;
  plate_number = undefined;
  fuel_type = undefined;
  manufacturer = undefined;
  model = undefined;
  vehicle_owner = undefined;
  vehicle_owner_tax_number = undefined;
  vehicle_driver = undefined;
  tax_id = undefined;

  is_basic_norm = false;
  local_average_fuel = undefined;
  real_fuel = undefined;
  maintenance_cost = undefined;
  has_climate = true;
  departure = undefined;
  delegation_starting = undefined;
  delegation_ending = undefined;
  average_fuel = undefined;
  company = undefined;
  company_tax_id = undefined;
  consumption_flat_rate = undefined;
  icon = undefined;
  id = undefined;
  is_self_employed = undefined;
  calculated_average_fuel = undefined;
  local_real_fuel = undefined;
  is_departure_used = false;
}
