import { isPrivatePeriodContextFn, isSelfEmployedPeriodContextFn } from '@roadrecord/recommendation';
import { Store } from '@ngxs/store';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';

export function generateRecommendationButtonTooltip(
  store: Store,
  periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
  periodContext: any
) {
  if (store.selectSnapshot(periodContextStateSelectorsService.isCompany)) {
    return 'COMPANY';
  } else if (isSelfEmployedPeriodContextFn(periodContext, periodContextStateSelectorsService)) {
    return 'SELF_EMPLOYED';
  } else if (isPrivatePeriodContextFn(periodContext, periodContextStateSelectorsService)) {
    return 'PRIVATE';
  }
}
