import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeInEnter } from '@roadrecord/animations';

@Component({
  selector: 'rr-header-panel',
  templateUrl: './header-panel.component.html',
  styleUrls: ['./header-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInEnter],
})
export class HeaderPanelComponent {
  @Input()
  run = true;
  @Input()
  currentStepCounter: number;
  @Input()
  counter: number;
  @Input()
  countError: any;
  @Input()
  countWarning: any;
  @Input()
  visiblePrintButton = false;
  @Input()
  printButtonLabel: string;

  @Input()
  loading = false;
  @Input()
  loadingProgressBarMode: string;

  @Input()
  hasError = false;

  @Output()
  printPdf = new EventEmitter<void | boolean>();
  @Output() clickOpenErrorWindow = new EventEmitter<void>();

  onPrintPdf(force: boolean): void {
    if ((this.visiblePrintButton && this.run === false && this.countError === 0) || force) {
      this.printPdf.emit(force);
    }
  }
}
