import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export const slideLeftInUnathAnimation: AnimationTriggerMetadata = trigger('enterAnimation', [
  transition(':enter', [
    style({
      transform: 'translate3d(100%, 0, 0)',
      // width: '75%',
      display: 'block',
      // opacity: 0,
      position: 'fixed',
      boxSizing: 'border-box',
      height: '100%',
      // margin: '-1em',
      // paddingTop: '1em',
      // paddingBottom: '1em'
      // right: '0em'
      // visibility: 'hidden'
    }),
    animate(
      '400ms linear',
      style({
        transform: 'translate3d(0, 0, 0)',
        width: '100%',
        height: '100%',
        opacity: 1,
        visibility: 'visible',
      })
    ),
    // animateChild()
  ]),
]);

export const slideLeftUnathOutAnimation: AnimationTriggerMetadata = trigger('leaveAnimation', [
  transition(':leave', [
    style({
      transform: 'translate3d(0, 0, 0)',
      // width: '75%',
      // display: 'block',
      opacity: 1,
      position: 'fixed',
      boxSizing: 'border-box',
      height: '100%',
      // top: '70px',
      // margin: '-1em',
      // padding: '1em',
      visibility: 'visible',
      // left: '0em'
      // top: '0em'
    }),
    animate(
      '400ms linear',
      style({
        transform: 'translate3d(-100%, 0, 0)',
        width: '100%',
        height: '100%',
        // opacity: 0,
        // visibility: 'hidden'
      })
    ),
    // animateChild()
  ]),
]);

export const slideLeftInAnimation: AnimationTriggerMetadata = trigger('enterAnimation', [
  transition(':enter', [
    style({
      transform: 'translate3d(100%, 0, 0)',
      width: '75%',
      display: 'block',
      opacity: 0,
      position: 'fixed',
      boxSizing: 'border-box',
      height: '100%',
      margin: '-1em',
      padding: '1em',
      visibility: 'hidden',
    }),
    animate(
      '400ms cubic-bezier(0.25, 0.8, 0.25, 0.8)',
      style({
        transform: 'translate3d(0, 0, 0)',
        width: '100%',
        opacity: 1,
        visibility: 'visible',
      })
    ),
    // animateChild()
  ]),
]);

export const slideLeftOutAnimation: AnimationTriggerMetadata = trigger('leaveAnimation', [
  transition(':leave', [
    style({
      transform: 'translate3d(0, 0, 0)',
      width: '75%',
      // display: 'block',
      opacity: 1,
      position: 'fixed',
      boxSizing: 'border-box',
      height: '100%',
      top: '70px',
      // margin: '-1em',
      // padding: '1em',
      visibility: 'visible',
      left: '0em',
      // top: '0em'
    }),
    animate(
      '400ms cubic-bezier(0.25, 0.8, 0.25, 0.8)',
      style({
        transform: 'translate3d(-100%, 0, 0)',
        width: '100%',
        opacity: 0,
        visibility: 'hidden',
      })
    ),
    // animateChild()
  ]),
]);

export const slideBottomOutAnimateSteps: /*AnimationMetadata*/ any[] = [
  style({
    transform: 'translate3d(0, 0, 0)',
    width: '100%',
    display: 'block',
    opacity: 1,
    position: 'absolute',
    boxSizing: 'border-box',
    height: '100%',
    margin: '-1em',
    padding: '1em',
    visibility: 'visible',
    left: '1em',
    top: '1em',
  }),
  animate(
    '800ms cubic-bezier(0.25, 0.8, 0.25, 0.8)',
    style({
      transform: 'translate3d(0, 100%, 0)',
      width: '100%',
      opacity: 0,
      visibility: 'hidden',
    })
  ),
  // animateChild()
];
export const slideBottomOutTransition: /*AnimationTransitionMetadata*/ any = transition(':leave', slideBottomOutAnimateSteps);

export const slideBottomOutAnimation: AnimationTriggerMetadata = trigger('slideBottomOut', [slideBottomOutTransition]);

export const slideBottomInAnimateSteps: /*AnimationMetadata*/ any[] = [
  style({
    transform: 'translate3d(0, 100%, 0)',
    width: '100%',
    display: 'block',
    opacity: 0,
    position: 'absolute',
    boxSizing: 'border-box',
    height: '100%',
    // margin: '1em',
    padding: '0',
    visibility: 'hidden',
    left: '0em',
    top: '0em',
  }),
  animate(
    '800ms cubic-bezier(0.25, 0.8, 0.25, 0.8)',
    style({
      transform: 'translate3d(0, {{top}}, 0)',
      width: '100%',
      opacity: 1,
      visibility: 'visible',
    })
  ),
  // animateChild()
];
export const slideBottomInTransition: /*AnimationTransitionMetadata*/ any = transition(':enter', slideBottomInAnimateSteps, {
  params: { top: '0' },
});

export const slideBottomInAnimation: AnimationTriggerMetadata = trigger('slideBottomIn', [slideBottomInTransition]);

export const slideTopOutAnimateSteps: /*AnimationMetadata*/ any[] = [
  style({
    transform: 'translate3d(0, 0, 0)',
    width: '100%',
    display: 'block',
    opacity: 1,
    position: 'absolute',
    boxSizing: 'border-box',
    height: '100%',
    margin: '-1em',
    padding: '1em',
    visibility: 'visible',
    left: '1em',
    top: '1em',
  }),
  animate(
    '800ms cubic-bezier(0.25, 0.8, 0.25, 0.8)',
    style({
      transform: 'translate3d(0, -100%, 0)',
      width: '100%',
      opacity: 0,
      visibility: 'hidden',
    })
  ),
  // animateChild()
];
export const slideTopOutTransition: /*AnimationTransitionMetadata*/ any = transition(':leave', slideTopOutAnimateSteps);

export const slideTopOutAnimation: AnimationTriggerMetadata = trigger('slideTopOut', [slideTopOutTransition]);
