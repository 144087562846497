import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { TranslocoModule } from '@ngneat/transloco';
import {
  AppTypePipeModule,
  DataCyDirectiveModule,
  SanitizePipeModule,
  TextPlaceholderHtmlTagReplacerModule,
} from '@roadrecord/common/common';
import { ProgressWhenFirstLoadingModule } from '@roadrecord/progress-when-first-loading';
import { ValidatingModule } from '@roadrecord/validating';
import { FirstStepsRoutingModule } from './first-steps-routing.module';
import { FinishMobileLayoutComponent } from './layouts/finish-mobile-layout/finish-mobile-layout.component';
import { FinishWelcomeComponent } from './layouts/finish-welcome/finish-welcome.component';
import { StartWelcomeComponent } from './layouts/start-welcome/start-welcome.component';
import { SteppingComponent } from './layouts/stepping/stepping.component';
import { PartnerWrapperStepModule } from './layouts/stepping/steps/partner-wrapper-step/partner-wrapper-step.module';
import { VehicleWrapperStepComponent } from './layouts/stepping/steps/vehicle-wrapper-step/vehicle-wrapper-step.component';
import { WrapperComponent } from './wrapper/wrapper.component';
import { LazyDetailsModule } from '@roadrecord/lazy-details';
import { RegisterModule } from '@roadrecord/user/common';
import { RegisterWrapperStepComponent } from './layouts/stepping/steps/register-wrapper-step/register-wrapper-step.component';
import { DynamicFormModule } from '@roadrecord/dynamic-form';
import { FirstStepperHelpBoxModule } from './layouts/stepping/first-stepper-help-box/first-stepper-help-box.module';
import { ButtonCardComponent } from './layouts/finish-welcome/button-card/button-card.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    ValidatingModule,
    MatFormFieldModule,

    FirstStepsRoutingModule,

    FlexLayoutModule,

    MatStepperModule,
    MatButtonModule,
    MatCardModule,
    // MatSnackBarModule,
    MatIconModule,
    LazyDetailsModule,
    ProgressWhenFirstLoadingModule,
    PartnerWrapperStepModule,
    TranslocoModule,
    RegisterModule,
    DynamicFormModule,
    MatTooltipModule,
    MatProgressBarModule,
    FlexLayoutModule,
    TextPlaceholderHtmlTagReplacerModule,
    FirstStepperHelpBoxModule,
    SanitizePipeModule,
    AppTypePipeModule,
    DataCyDirectiveModule,
  ],
  declarations: [
    SteppingComponent,
    StartWelcomeComponent,
    FinishWelcomeComponent,
    WrapperComponent,
    VehicleWrapperStepComponent,
    RegisterWrapperStepComponent,
    FinishMobileLayoutComponent,
    ButtonCardComponent,
  ],
})
export class FirstStepsModule {}
