/**
 * JS file betöltésére szolgáló segédfüggvény
 * @param url {string} útvonal ahol a script fájl elérhető
 * @param nonce {string}
 * @param async {boolean}
 * @param defer {boolean}
 */
export function loadJsScript(url: string, nonce = null, async = true, defer = true) {
  const scriptElement = document.createElement('script');

  scriptElement.src = url;
  scriptElement.async = async;
  scriptElement.defer = defer;
  scriptElement.type = 'text/javascript';

  if (nonce !== null) {
    scriptElement.nonce = nonce;
  }

  return scriptElement;
}

/**
 * Help Crunch inicializálását végző segédfüggvény
 * @param organization {string}
 * @param appId {string}
 */
export function loadHelpCrunch(organization: string, appId: string) {
  // @ts-ignore
  window.helpcrunchSettings = {
    appId: `${appId}`,
    organization: `${organization}`,
  };

  const helpCrunchScript = document.createElement('script');
  helpCrunchScript.innerText =
    '(function(w,d){' +
    'var hS=w.helpcrunchSettings;if(!hS||!hS.organization){return;}' +
    "var widgetSrc='https://'+hS.organization+'.widget.helpcrunch.com/';" +
    'w.HelpCrunch=function(){w.HelpCrunch.q.push(arguments)};' +
    "w.HelpCrunch.q=[];function r(){if (d.querySelector('script[src=\"' + widgetSrc + '\"')) { return; }" +
    "var s=d.createElement('script');s.async=1;s.type='text/javascript';s.src=widgetSrc;(d.body||d.head).appendChild(s);}" +
    "if(d.readyState === 'complete'||hS.loadImmediately){r();}" +
    "else if(w.attachEvent){w.attachEvent('onload',r)}" +
    "else{w.addEventListener('load',r,false)}})(window, document)";
  helpCrunchScript.async = true;
  helpCrunchScript.defer = true;
  helpCrunchScript.type = 'text/javascript';
  document.head.appendChild(helpCrunchScript);
  console.log('HelpCrunch script initialized.');
}
