import { NgModule, NgZone } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InvalidPeriodContextGuard, PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import {
  HasAccessUniversalImportUploadFileGuard,
  ListComponent,
  UniversalImportModule,
  UniversalImportWorkerBridgeService,
  UploadStepperComponent,
} from '@roadrecord/universal-import/common';
import { Store } from '@ngxs/store';
import { PARTNER_IMPORT_WORKER } from './partner-import-worker.token';
import { PartnerUniversalImportWorkerBridgeImplService } from './partner-universal-import-worker-bridge-impl.service';

const routes: Routes = [
  {
    path: 'upload',
    component: UploadStepperComponent,
    canActivate: [HasAccessUniversalImportUploadFileGuard],
    canDeactivate: [InvalidPeriodContextGuard],
  },
  {
    path: '',
    component: ListComponent,
    canDeactivate: [InvalidPeriodContextGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), UniversalImportModule],
  exports: [RouterModule],
  providers: [
    HasAccessUniversalImportUploadFileGuard,
    {
      provide: UniversalImportWorkerBridgeService,
      useClass: PartnerUniversalImportWorkerBridgeImplService,
      deps: [NgZone, Store, PeriodContextStateSelectorsService, PARTNER_IMPORT_WORKER],
    },
  ],
})
export class PartnerImportRoutingModule {}
