/**
 * https://github.com/tobinbradley/mapbox-gl-pitch-toggle-control
 */
export class PitchToggle {
  private _map: any;
  private _btn: HTMLButtonElement;
  private _container: HTMLDivElement;

  constructor(
    private translateText2D: string,
    private translateText3D: string,
    private _bearing = 0,
    private _pitch = 70,
    private _minpitchzoom = null
  ) {}

  onAdd(map) {
    this._map = map;

    this._btn = document.createElement('button');
    this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d';
    this._btn.type = 'button';
    this._btn['aria-label'] = this.translateText2D;
    this._btn['title'] = this.translateText2D;
    this._btn.onclick = () => {
      if (map.getPitch() === 0) {
        const options: any = { pitch: this._pitch, bearing: this._bearing };
        if (this._minpitchzoom && map.getZoom() > this._minpitchzoom) {
          options.zoom = this._minpitchzoom;
        }
        map.easeTo(options);
        this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d';
        this._btn['aria-label'] = this.translateText3D;
        this._btn['title'] = this.translateText3D;
      } else {
        map.easeTo({ pitch: 0, bearing: 0 });
        this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d';
        this._btn['aria-label'] = this.translateText2D;
        this._btn['title'] = this.translateText2D;
      }
    };

    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    this._container.appendChild(this._btn);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}
