import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth.service';
import { UserModel } from '../../../model/user.model';
import { fromForgotPassword } from '../forgot-password/from-forgot-password';

@Component({
  selector: 'rr-password-reset-sent',
  templateUrl: './password-reset-sent.component.html',
  styleUrls: ['./password-reset-sent.component.scss'],
})
export class PasswordResetSentComponent {
  constructor(router: Router, authService: AuthService<UserModel>) {
    if (fromForgotPassword.is === false) {
      router.navigate(['/']);
    }
  }
}
