import { CallbackOptions, Step } from '../../introjs.service';
import { INTROJS_TYPES_ENUM, IntrojsMock } from '@roadrecord/introjs/common';
import type { Options } from 'intro.js';

export class LoadedIntroAction {
  static readonly type = '[Intro] LOADED';

  constructor(
    readonly steps: Step[],
    readonly id: INTROJS_TYPES_ENUM | string,
    readonly callbacks?: CallbackOptions,
    readonly globalOptions?: Options | null,
    readonly mocks?: IntrojsMock[]
  ) {}
}
