<div
  [fxLayoutGap]="to.flex.fxLayoutGap"
  [fxLayoutGap.gt-sm]="to.flex.fxLayoutGap__gt_sm ? to.flex.fxLayoutGap__gt_sm : to.flex.fxLayoutGap"
  [fxLayout]="to.flex.fxLayout"
  [fxLayout.gt-sm]="to.flex.fxLayout__gt_sm ? to.flex.fxLayout__gt_sm : to.flex.fxLayout"
  [fxLayout.lt-md]="to.flex.fxLayout__lt_md ? to.flex.fxLayout__lt_md : to.flex.fxLayout"
  [ngClass]="to.cssClass"
>
  <formly-field
    *ngFor="let f of field.fieldGroup"
    [field]="f"
    [fxFlex]="f.templateOptions?.flex?.fxFlex"
    [fxFlex.gt-sm]="f.templateOptions?.flex?.fxFlex__gt_sm ? f.templateOptions.flex.fxFlex__gt_sm : f.templateOptions?.flex?.fxLayout"
  ></formly-field>
</div>
