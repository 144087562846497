import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { RecommendationChangeFilterAction } from '../../recommendation/state/action/recommendation-change-filter.action';
import { RecommendationStatusEnum } from '../../recommendation/state/model/recommendation-status.enum';

@Component({
  selector: 'rr-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterPanelComponent {
  RecommendationStatusEnum = RecommendationStatusEnum;
  @Input() windowMode = false;
  @Input()
  currentFilter: RecommendationStatusEnum | 'ALL';
  @Input()
  countAll: number;
  @Input()
  countOK: number;
  @Input()
  countWarning: number;
  @Input()
  countNotChecked: number;
  @Input()
  countError: number;
  @Input()
  disabled = false;

  constructor(private store: Store) {}

  changeFilter(status: RecommendationStatusEnum | 'ALL'): void {
    this.store.dispatch(new RecommendationChangeFilterAction(status));
  }
}
