import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { isNil } from '@roadrecord/type-guard';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { HasVehicleContextOptionsModel } from './model/has-vehicle-context-options.model';

/**
 * Bizonyos route-k csak bizonyos vehicle context -ben erhetok el, amiket a period context-bol nyerunk ki
 * A guard-nak az adott route data attributumaban kell atadni egy HasVehicleContextOptionsModel-t, amiben atadhat
 * callback-ket. A guard ezen callback-et hagytja sorban vegre es az elso true valasznal megal es beengedi a user-t
 * a route-ra, viszont ha semelyik callback nem ad vissza true-t akkor elutasitja a route-last es a / -ra dob vissza
 *
 * pl:
 * ``` canActivate: [HasVehicleContextGuard],
 *     canLoad: [HasVehicleContextGuard],
 *     data: {
 *         HasVehicleContextOptions: new HasVehicleContextOptionsModel([
 *               isCompanyVehicle,
 *               isPrivateVehicleAndIsSelfEmployed
 *         ])
 *     }```
 */
@Injectable({ providedIn: 'root' })
export class HasVehicleContextGuard implements CanActivate, CanLoad {
  constructor(private readonly router: Router, private readonly store: Store) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(next.data);
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(route.data);
  }

  private check(data: any): boolean | Observable<boolean> {
    if (data !== undefined && data.HasVehicleContextOptions instanceof HasVehicleContextOptionsModel) {
      return this.store
        .select(states => states.periodContext)
        .pipe(
          filter(state => !isNil(state) && Object.keys(state).length > 0 && isNil(state.notInited)),
          first(),
          map(periodContextState => {
            const options: HasVehicleContextOptionsModel = data.HasVehicleContextOptions;

            const hasContext = options.callbacks.find(callback => callback(periodContextState.model)) !== undefined;
            if (hasContext === true) {
              return true;
            }
            this.router.navigate(['/']);
            return false;
          })
        );
    }

    // TODO restricted page redirect
    this.router.navigate(['/']);
    return false;
  }
}
