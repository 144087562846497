import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';

/**
 * Azert van szukseg, mert csak bizonyos folyamatoknal kell mw-n behazudni SMR eseten,
 * hogy self employed es private
 */
export function isSelfEmployedPeriodContextFn(
  context: any,
  periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>
) {
  return periodContextStateSelectorsService.isSelfEmployed({ model: context });
}
