import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef, ɵstringify as stringify } from '@angular/core';

export class NgIfContext {
  public $implicit: any = null;
  public rrNgIfTyped: any = null;
}

function assertTemplate(property: string, templateRef: TemplateRef<any> | null): void {
  const isTemplateRefOrNull = !!(!templateRef || templateRef.createEmbeddedView);
  if (!isTemplateRefOrNull) {
    throw new Error(`${property} must be a TemplateRef, but received '${stringify(templateRef)}'.`);
  }
}

@Directive({
  selector: '[rrNgIfTyped]',
})
export class NgIfTypedDirective {
  /** @internal */
  public static ngIfUseIfTypeGuard: void;
  private _context: NgIfContext = new NgIfContext();
  private _thenTemplateRef: TemplateRef<NgIfContext> | null = null;
  private _elseTemplateRef: TemplateRef<NgIfContext> | null = null;
  private _thenViewRef: EmbeddedViewRef<NgIfContext> | null = null;
  private _elseViewRef: EmbeddedViewRef<NgIfContext> | null = null;
  private _lastContext: NgIfContext;

  constructor(private _viewContainer: ViewContainerRef, templateRef: TemplateRef<NgIfContext>) {
    this._thenTemplateRef = templateRef;
  }

  @Input()
  set rrNgIfTyped(condition: any) {
    this._lastContext = { ...this._context };
    this._context.$implicit = this._context.rrNgIfTyped = condition;
    this._updateView();
  }

  @Input()
  set rrNgIfTypedThen(templateRef: TemplateRef<NgIfContext> | null) {
    assertTemplate('rrNgIfTypedThen', templateRef);
    this._thenTemplateRef = templateRef;
    this._thenViewRef = null; // clear previous view if any.
    this._updateView();
  }

  @Input()
  set rrNgIfTypedElse(templateRef: TemplateRef<NgIfContext> | null) {
    assertTemplate('rrNgIfTypedElse', templateRef);
    this._elseTemplateRef = templateRef;
    this._elseViewRef = null; // clear previous view if any.
    this._updateView();
  }

  private _updateView(): void {
    if (this._context.$implicit !== false) {
      if (!this._thenViewRef || this._lastContext.rrNgIfTyped !== this._context.rrNgIfTyped) {
        this._viewContainer.clear();
        this._elseViewRef = null;
        if (this._thenTemplateRef) {
          this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef, this._context);
        }
      }
    } else {
      if (!this._elseViewRef) {
        this._viewContainer.clear();
        this._thenViewRef = null;
        if (this._elseTemplateRef) {
          this._elseViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef, this._context);
        }
      }
    }
  }
}
