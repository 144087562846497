import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { TranslocoService } from '@ngneat/transloco';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION } from '@roadrecord/dynamic-form';
import { DOCUMENT } from '@angular/common';
import {
  AbstractRRFormWithDynamicForm,
  BottomSheetRRFormWithDynamicForm,
  getBottomSheetComponentBase,
  RRFormWithDynamicFormBottomSheetConfig,
} from '@roadrecord/utils';
import { searchMissingFieldConfig } from './search-missing-field.config';
import { Observable } from 'rxjs';
import { VehicleModel, VehicleRelatedRemoteModel } from '@roadrecord/vehicle/model/common';
import { getFormDatas } from './get-form-datas.function';

// @ts-ignore
/**
 * Ez azert kell, mert kulonben tpl-ben nem latja az abstract mixin dolgokat
 */
export const _BASE = getBottomSheetComponentBase<VehicleModel, AverageFuelBottomSheetComponent>();

@Component({
  selector: 'rr-real-fuel-bottom-sheet',
  templateUrl: './average-fuel-bottom-sheet.component.html',
  styleUrls: ['./average-fuel-bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION,
      useValue: true,
    },
  ],
})
export class AverageFuelBottomSheetComponent
  extends _BASE
  implements AbstractRRFormWithDynamicForm<VehicleModel>, BottomSheetRRFormWithDynamicForm<VehicleModel>, OnInit, AfterViewInit {
  constructor(
    bottomSheetRef: MatBottomSheetRef<AverageFuelBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    data: RRFormWithDynamicFormBottomSheetConfig<VehicleModel> & { vehicleRelated: VehicleRelatedRemoteModel },
    @Inject(DOCUMENT) document: Document,
    translocoService: TranslocoService,
    messageDialogService: MessageDialogService,
    injector: Injector
  ) {
    super(injector, document, translocoService, messageDialogService, null, bottomSheetRef, data);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  setFormFields() {
    return searchMissingFieldConfig(
      this.bottomSheetData as RRFormWithDynamicFormBottomSheetConfig<VehicleModel> & { vehicleRelated: VehicleRelatedRemoteModel },
      this.injector
    );
  }

  getFormDatas(): Observable<Partial<VehicleModel>> {
    return getFormDatas(this.bottomSheetData);
  }
}
