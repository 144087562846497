import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { MessageDialogOptionsInterface } from '../model/message-dialog-options.interface';
import { MessageDialogService } from '../message-dialog.service';
import { isNil, isString } from '@roadrecord/type-guard';
import { timer } from 'rxjs';

export interface DeletePopupYesCallbackResult {
  result?: boolean;
  foreverHideDialog?: boolean;
  slideToggleValue?: boolean;
}

export interface DeletePopupFnParameters {
  matSnackBar: MatSnackBar;
  translocoService: TranslocoService;
  messageDialogService: MessageDialogService;
  yesCallback: (finishCallback: () => void, result: DeletePopupYesCallbackResult) => void;
  noCallback?: () => void;
  successText?: string;
  slideToggleLabel?: string;
  text?: string;
}

export function deletePopup({
  matSnackBar,
  translocoService,
  messageDialogService,
  yesCallback,
  noCallback,
  successText = 'COMMON.SUCCESS_DELETE',
  slideToggleLabel,
  text,
}: DeletePopupFnParameters): void {
  const dialogOptions: MessageDialogOptionsInterface = {
    id: null,
    text: 'COMMON.ARE_YOU_SURE_TO_DELETE',
    spriteClasses: ['common-sprite', 'common-ceruzay'],
    enableCancel: true,
    confirmLabel: 'COMMON.ACTION.YES',
    cancelLabel: 'COMMON.ACTION.NO',
  };

  if (isString(slideToggleLabel)) {
    dialogOptions.showSlideToggle = slideToggleLabel;
  }

  if (isString(text)) {
    dialogOptions.text = text;
  }

  messageDialogService
    .open(dialogOptions)
    .afterClosed()
    .subscribe(result => {
      if (result.result) {
        yesCallback(() => {
          if (typeof successText === 'string') {
            timer(0).subscribe(() => matSnackBar.open(translocoService.translate(successText)));
          }
        }, result);
      } else {
        if (!isNil(noCallback)) {
          noCallback();
        }
      }
    });
}
