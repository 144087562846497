import { hasAccessUniversalImportUploadFile, UniversalImportService } from '@roadrecord/universal-import/common';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * Segédfüggvény ami eldönti, hogy ha a Fix utak importálás gombra kattintuk akkor a listás nézetet jelenítse meg vagy
 * a felületet ahol kiválasztható az import típusa.
 * @param universalImportService - UniversalImportService
 * @param defaultOrder - Oszlop neve amire rendezni akarunk
 * @param importRoutePrefix - URL path
 * @param router - Router
 * @param route - Route
 * @param defaultImportSelectedButton Boolen
 * @returns {void} - nincs visszatérési érték
 */
export function clickFuelingImportRightMenuButton(
  universalImportService: UniversalImportService<any>,
  defaultOrder: string,
  importRoutePrefix: string,
  router: Router,
  route: ActivatedRoute
): void {
  universalImportService
    .getAll(
      { active: defaultOrder, direction: 'asc' },
      {
        pageIndex: 0,
        pageSize: 1,
        length: 1,
      },
      ''
    )
    .subscribe(response => {
      const routePath = [`${importRoutePrefix.length > 0 ? `${importRoutePrefix}/` : ''}import`];

      if (response.results.length === 0) {
        hasAccessUniversalImportUploadFile.hasAccess = true;
        routePath.push('upload');
      }

      if (importRoutePrefix.length === 0) {
        router.navigate([...routePath], { relativeTo: route });
      } else if (importRoutePrefix === '..') {
        router.navigate([...routePath], { relativeTo: route });
      } else {
        router.navigate([...routePath]);
      }
    });
}
