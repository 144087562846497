import { NotFoundServerError } from '../exception//not-found-server.error';
import { ExpiredTokenError } from '../exception/expired-token.error';
import { MaintenanceModeError } from '../exception/maintenance-mode-error';
import { NotFoundEndpointError } from '../exception/not-found-endpoint.error';
import { NotFoundInternetError } from '../exception/not-found-internet.error';
import { RRHttpErrorResponse } from '../exception/rr-http-error-response';
import { HttpErrorResponse } from '@angular/common/http';
import { ServerSerrorMetaEnum } from '../enum/server-error-meta.enum';

export function throwHttpError(originalError: HttpErrorResponse): RRHttpErrorResponse {
  const error = new RRHttpErrorResponse(originalError);
  if (error.status === 0) {
    return new NotFoundInternetError(error);
  } else if (error.status === 404) {
    /**
     * amikor letezik az invalid period context meta es be van allitva akkor tovabb adjuk a kiertekelest, ezert nem
     * kell a transformacio NotFoundEndpointError -ra
     */
    if (
      !(error.hasMeta(ServerSerrorMetaEnum.INVALID_PERIOD_CONTEXT) && error.getMetaValue(ServerSerrorMetaEnum.INVALID_PERIOD_CONTEXT) === 1)
    ) {
      return new NotFoundEndpointError(error);
    }
  } else if (error.status === 504) {
    return new NotFoundServerError(error);
  } else if (error.status === 401) {
    return new ExpiredTokenError(error);
  } else if (error.status === 503) {
    return new MaintenanceModeError(error);
  }
  return error;
}
