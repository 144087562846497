import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PriceService } from '../price.service';
import { CalculatedInformationModel, CalculatedResponseModel, NavigateEventType, PlaceOrderModel, PriceItemModel } from '../model/models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailValidators } from 'ngx-validators';
import { BehaviorSubject, combineLatest, forkJoin, Observable } from 'rxjs';
import { commonHttpStreamErrorHandler } from '@roadrecord/utils';
import { deepEqual, globalPhoneControlValidationMessages } from '@roadrecord/common/common';
import { phoneNumberValidator3 } from '@roadrecord/ngx-phone-validators';
import { AppTypeEnum, environment } from '@roadrecord/environment';
import { isNil, isNotEmptyString, isString } from '@roadrecord/type-guard';
import moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, skip, startWith, switchMap, take } from 'rxjs/operators';
import { PriceStepEnum } from '../model/price-step.enum';
// @ts-ignore
import { STATE_LIST_CA, STATE_LIST_US } from '../model/state-list-us';
import { TranslocoService } from '@ngneat/transloco';
import { StateService } from './state.service';
import { laStateOptionsConfig } from './state.autocomplete';

const countryTranslatePrefix = 'PRICE.DATA_FORM.ORDER.FIELD.COUNTRY.VALUES.';

@UntilDestroy()
@Component({
  selector: 'rr-order-form',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  providers: [PriceService, StateService],
})
export class OrderComponent implements OnInit, OnChanges {
  @Input()
  priceList: PriceItemModel[] = [];

  @Input()
  selectedPricePackage: PriceItemModel;

  @Input()
  isBasicPrice = true;

  @Input()
  showInStepper = false;

  @Output()
  navigateEvent = new EventEmitter<NavigateEventType>();

  phoneControlValidationMessages = globalPhoneControlValidationMessages;
  calculatedInformation: CalculatedInformationModel = null;

  readonly loading$ = new BehaviorSubject(true);
  readonly loadingCalculate$ = new BehaviorSubject(false);
  readonly isAmericanVersion = environment.appType === AppTypeEnum.US;

  form: FormGroup;

  firstNameControl = new FormControl(null, [Validators.required, Validators.minLength(2)]);
  lastNameControl = new FormControl(null, [Validators.required, Validators.minLength(2)]);
  phoneNumberControl = new FormControl(null);
  streetControl1 = new FormControl(null, Validators.required);
  streetControl2 = new FormControl(null);
  cityControl = new FormControl(null, Validators.required);
  zipControl = new FormControl(null, Validators.required);
  countryControl = new FormControl(null, Validators.required);
  stateControl = new FormControl(null, Validators.required);
  numberOfVehicleControl = new FormControl(1, Validators.required);
  subscriptionStartDateControl = new FormControl(moment(), Validators.required);
  emailControl = new FormControl(undefined, [Validators.required, EmailValidators.normal]);
  companyNameControl = new FormControl(null);
  selectedPackageNameControl = new FormControl(null);
  paymentTypeControl = new FormControl('STRIPE', Validators.required);

  // stateInputControl = new FormControl(null, [Validators.required]);
  readonly laStateOptionsConfig = laStateOptionsConfig;
  formSubmitted = false;

  readonly countries: { label: string; value: string }[] = [
    { label: `${countryTranslatePrefix}US`, value: 'US' },
    { label: `${countryTranslatePrefix}CANADA`, value: 'CA' },
  ];

  @Input() isOnSale = false;

  constructor(
    private priceService: PriceService,
    private fb: FormBuilder,
    readonly translocoService: TranslocoService,
    readonly stateService: StateService
  ) {
    this.initForm();

    this.loading$.pipe(filter(v => v === false)).subscribe(() => this.form.enable({ emitEvent: false }));
  }

  private getSelectedPackageBillingInformation(): void {
    const subscriptionStartValue = isNil(this.subscriptionStartDateControl.value) ? new Date() : this.subscriptionStartDateControl.value;

    const forkJoinStreamList: Observable<any>[] = [
      this.priceService.getBillingInformation(),
      this.priceService.getCalculatePrice(
        moment(subscriptionStartValue).format('YYYY-MM-DD'),
        this.selectedPricePackage.id,
        this.numberOfVehicleControl.value
      ),
    ];

    this.loading$.next(true);

    forkJoin(forkJoinStreamList)
      .pipe(untilDestroyed(this))
      .subscribe(
        results => {
          const billingInformationResponse = results[0];
          const calculatedResponse = results[1];
          let disableStateControl = false;

          if (isNotEmptyString(billingInformationResponse.first_name)) {
            this.firstNameControl.patchValue(billingInformationResponse.first_name, { emitEvent: false });
          }

          if (isNotEmptyString(billingInformationResponse.last_name)) {
            this.lastNameControl.patchValue(billingInformationResponse.last_name, { emitEvent: false });
          }

          if (isNotEmptyString(billingInformationResponse.email)) {
            this.emailControl.patchValue(billingInformationResponse.email, { emitEvent: false });
          }

          if (isNotEmptyString(billingInformationResponse.phone_number)) {
            this.phoneNumberControl.patchValue(billingInformationResponse.phone_number, { emitEvent: false });
          }

          if (isNotEmptyString(billingInformationResponse.company_name)) {
            this.companyNameControl.patchValue(billingInformationResponse.company_name, { emitEvent: false });
          }

          if (isNotEmptyString(billingInformationResponse.address_line1)) {
            this.streetControl1.patchValue(billingInformationResponse.address_line1, { emitEvent: false });
          }

          if (isNotEmptyString(billingInformationResponse.address_line2)) {
            this.streetControl2.patchValue(billingInformationResponse.address_line2, { emitEvent: false });
          }

          if (isNotEmptyString(billingInformationResponse.city)) {
            this.cityControl.patchValue(billingInformationResponse.city, { emitEvent: false });
          }

          if (
            !isNil(billingInformationResponse.country) &&
            isString(billingInformationResponse.country) &&
            billingInformationResponse.country.length > 0 &&
            this.countries.some(country => country.value === billingInformationResponse.country)
          ) {
            this.countryControl.patchValue(billingInformationResponse.country);
            if (this.stateService.states.includes(billingInformationResponse.state)) {
              this.stateControl.patchValue(billingInformationResponse.state);
            }
          } else {
            disableStateControl = true;
          }

          if (isNotEmptyString(billingInformationResponse.zip)) {
            this.zipControl.patchValue(billingInformationResponse.zip, { emitEvent: false });
          }

          this.privateSetCalculateInformationModel(calculatedResponse);

          if (disableStateControl) {
            this.loading$
              .pipe(
                untilDestroyed(this),
                filter(v => v === false),
                take(1)
              )
              .subscribe(() => this.stateControl.disable({ emitEvent: false }));
          }
          this.loading$.next(false);
        },
        commonHttpStreamErrorHandler(() => this.loading$.next(false))
      );
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {
    combineLatest([
      this.numberOfVehicleControl.valueChanges.pipe(startWith(1), distinctUntilChanged()),
      this.subscriptionStartDateControl.valueChanges.pipe(startWith(this.subscriptionStartDateControl.value), distinctUntilChanged()),
    ])
      .pipe(skip(1), untilDestroyed(this))
      .subscribe((values: [number, Date]) => {
        const vehicleCount = values[0];
        const subscriptionStartDate = isNil(values[1]) ? new Date() : values[1];

        this.loadingCalculate$.next(true);
        this.selectedPricePackage.vehicle_count = vehicleCount;
        this.selectedPricePackage.subscription_start_date = values[1];

        this.priceService
          .getCalculatePrice(moment(subscriptionStartDate).format('YYYY-MM-DD'), this.selectedPricePackage.id, vehicleCount)
          .pipe(untilDestroyed(this))
          .subscribe(
            result => {
              this.privateSetCalculateInformationModel(result);
              this.loadingCalculate$.next(false);
            },
            commonHttpStreamErrorHandler(() => this.loadingCalculate$.next(false))
          );
      });

    if (this.isAmericanVersion) {
      this.phoneNumberControl.setValidators(Validators.compose([phoneNumberValidator3]));
      this.phoneControlValidationMessages = [
        {
          errorKey: 'phoneNumber',
          translateKey: 'COMMON.VALIDATION.PHONE.NO_PHONE_NUMBER',
        },
      ];
    }

    this.selectedPackageNameControl.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged((_old, _new) => deepEqual(_old, _new)),
        // Input beallitas miatt
        skip(1)
      )
      .subscribe(value => {
        this.selectedPricePackage = value;
        this.getSelectedPackageBillingInformation();
      });

    if (!isNil(this.selectedPricePackage) && !isNil(this.selectedPricePackage)) {
      const selectedPackage = this.priceList.find(item => item.id === this.selectedPricePackage.id);
      //this.selectedPackageNameControl.patchValue(this.selectedPricePackage.name, { emitEvent: false });
      this.selectedPackageNameControl.patchValue(selectedPackage);

      if (this.selectedPricePackage.subscription_start_date) {
        this.subscriptionStartDateControl.patchValue(this.selectedPricePackage.subscription_start_date, {
          emitEvent: false,
        });
      }
      this.getSelectedPackageBillingInformation();
    }
  }

  /**
   *
   * @private
   */
  private initForm(): void {
    this.emailControl.disable();

    this.form = this.fb.group({
      first_name: this.firstNameControl,
      last_name: this.lastNameControl,
      email: this.emailControl,
      phone_number: this.phoneNumberControl,
      company_name: this.companyNameControl,
      city: this.cityControl,
      zip: this.zipControl,
      country: this.countryControl,
      state: this.stateControl,
      address_line1: this.streetControl1,
      address_line2: this.streetControl2,
      vehicle_count: this.numberOfVehicleControl,
      subscription_start_date: this.subscriptionStartDateControl,
      selected_package_name: this.selectedPackageNameControl,
      payment_provider_type: this.paymentTypeControl,
    });
    this.form.disable({ emitEvent: false });

    this.countryControl.valueChanges.subscribe(country => {
      if (this.stateControl.disabled) {
        this.stateControl.enable();
      }
      if (country === 'US') {
        this.stateService.states = STATE_LIST_US;
      } else {
        this.stateService.states = STATE_LIST_CA;
      }
      this.stateControl.reset();
    });
  }

  /**
   *
   * @param pricesInformation
   * @private
   */
  private privateSetCalculateInformationModel(pricesInformation: CalculatedResponseModel): void {
    if (!isNil(pricesInformation)) {
      this.calculatedInformation = {
        package_name: this.selectedPricePackage.longname,
        vehicle_count: this.numberOfVehicleControl.value,
        ...pricesInformation,
      };
    } else {
      this.calculatedInformation = null;
    }
  }

  /**
   * US telefonszám formátumra alakítása
   * @param value
   */
  parseUsPhoneNumber(value: string) {
    return value.replace(new RegExp(/-/, 'g'), '').replace(new RegExp(/\(/, 'g'), '').replace(new RegExp(/\)/, 'g'), '');
  }

  /**
   * Árlistára navigálás
   */
  onNavigatePriceList() {
    const event: NavigateEventType = {
      step: PriceStepEnum.PACKAGE_LIST,
      data: this.selectedPricePackage,
    };
    this.navigateEvent.emit(event);
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.form.valid) {
      const value = this.form.value;
      this.loading$.next(true);
      this.form.disable();

      const payload: PlaceOrderModel = {
        ...value,
        tax_code: '123467',
        phone_number:
          !isNil(this.phoneNumberControl.value) && this.phoneNumberControl.value.indexOf('_') > -1
            ? this.phoneNumberControl.value.substring(0, this.phoneNumberControl.value.indexOf('_'))
            : this.phoneNumberControl.value,
        product_id: this.selectedPricePackage.id,
        vehicle_count: this.numberOfVehicleControl.value,
        subscription_start_date: moment(this.subscriptionStartDateControl.value).format('YYYY-MM-DD'),
      };

      this.priceService
        .placeOrder(payload)
        .pipe(
          untilDestroyed(this),
          switchMap(responsePlaceOrder => this.priceService.executeOrder(responsePlaceOrder.id, this.paymentTypeControl.value))
        )
        .subscribe(
          response => {
            if (this.showInStepper === true) {
              sessionStorage.setItem('payment-in-stepper', 'true');
            }
            window.location.href = response.redirect_url;
          },
          commonHttpStreamErrorHandler(() => this.loading$.next(false))
        );
    }
  }
}
