import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { CapitalizeDirectiveModule, FocusInputModule, TwoWaySwitchFormControlModule } from '@roadrecord/common/common';
import { ProgressWhenFirstLoadingModule } from '@roadrecord/progress-when-first-loading';
import { FormModule } from '@roadrecord/utils';
import { ValidatingModule } from '@roadrecord/validating';
import { DataFormComponent } from './data-form/data-form.component';
import { DetailsComponent } from './details.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    FlexLayoutModule,

    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatSnackBarModule,

    CapitalizeDirectiveModule,
    TwoWaySwitchFormControlModule,
    ValidatingModule,
    FormModule,
    ProgressWhenFirstLoadingModule,
    FocusInputModule,

    TranslocoModule,
  ],
  declarations: [DetailsComponent, DataFormComponent],
  exports: [DetailsComponent, DataFormComponent],
})
export class DetailsModule {}
