import { NgZone, Optional } from '@angular/core';
import { Store } from '@ngxs/store';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { UniversalImportWorkerBridgeService } from './universal-import-worker-bridge.service';
import { environment } from '@roadrecord/environment';

export class UniversalImportWorkerBridgeImplService extends UniversalImportWorkerBridgeService {
  constructor(
    ngZone: NgZone,
    store: Store,
    periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    private importWorker: () => Worker,
    @Optional() checkDate = true,
    @Optional() allowedRowCount = 500
  ) {
    super(ngZone, store, periodContextStateSelectorsService, environment.mode === 'e2e', checkDate, allowedRowCount);
  }

  getBaseWorker(): Worker {
    return this.importWorker();
  }
}
