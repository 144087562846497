import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DetailsModule, ListModule, PartnerCommonPipeModule } from '@roadrecord/partner/common';

import { PartnerRoutingModule } from './partner-routing.module';

@NgModule({
  imports: [CommonModule, PartnerRoutingModule, MatProgressBarModule, DetailsModule, ListModule, PartnerCommonPipeModule],
})
export class PartnerModule {}
