import { Pipe, PipeTransform } from '@angular/core';
import { isEmptyString } from '@roadrecord/type-guard';

@Pipe({
  name: 'isEmptyString',
})
export class IsEmptyStringPipe implements PipeTransform {
  transform(value: any, args?: any): boolean {
    return isEmptyString(value);
  }
}
