import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { disableMenuDetectRouteChangeOption } from '@roadrecord/common/common';
import { partnerDetailsRoutingWhenSaveAndNotResetStrategy } from '@roadrecord/partner/common';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { FirstStepsNextStepModel } from './model/first-steps-next-step.model';
import { StepperConfigModel } from './model/stepper-config.model';
import { NextStepConfig } from './next-step.config';
import { isBoolean, isNil } from '@roadrecord/type-guard';
import { environment } from '@roadrecord/environment';

@Injectable({ providedIn: 'root' })
export class FirstStepsService {
  private endpointPath = 'first-steps';

  constructor(protected readonly http: HttpClient, private translocoService: TranslocoService) {}

  getConfig(): Observable<StepperConfigModel> {
    return this.translocoService.langChanges$.pipe(
      startWith(this.translocoService.getActiveLang()),
      distinctUntilChanged(),
      switchMap(() => this.http.get<StepperConfigModel>(`${environment.apiUrl}${this.endpointPath}/config`))
    );
  }

  nextStep(config?: NextStepConfig): Observable<FirstStepsNextStepModel> {
    const options: { params?: { skip: 'all' | 'step' } } = {};
    if (!isNil(config)) {
      if (isBoolean(config.skipAll) && config.skipAll) {
        options.params = { skip: 'all' };
      } else if (isBoolean(config.skip) && config.skip) {
        options.params = { skip: 'step' };
      }
    }

    return this.http.get<FirstStepsNextStepModel>(`${environment.apiUrl}${this.endpointPath}/next-step`, options).pipe(
      tap(() => {
        disableMenuDetectRouteChangeOption.disable = true;
        partnerDetailsRoutingWhenSaveAndNotResetStrategy.strategy = false;
      })
    );
  }

  isFinished(): Observable<boolean> {
    return this.http.get<number>(`${environment.apiUrl}${this.endpointPath}/is-finished`).pipe(
      map(isFinished => isFinished === 1),
      tap(isFinished => {
        disableMenuDetectRouteChangeOption.disable = !isFinished;
        partnerDetailsRoutingWhenSaveAndNotResetStrategy.strategy = undefined;
      })
    );
  }
}
