import { Store } from '@ngxs/store';
import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { isNil, isNumeric } from '@roadrecord/type-guard';
import { CalendarStateModel } from '../../../state/model/calendar-state.model';
import { CalendarState } from '../../../state/calendar.state';
import { fromEvent, Subscription } from 'rxjs';
import { scrollTo } from '@roadrecord/common/common';
import { untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CalendarSaveLastScrollPositionAction } from '../../../state/action/calendar-save-last-scroll-position.action';

@Injectable()
export class ScrollStateHandler implements OnDestroy {
  private watchSubscription: Subscription | null = null;
  constructor(
    private store: Store,
    @Inject('') private calendarId: string,
    @Inject('') private document: Document,
    @Inject('') private ngZone: NgZone
  ) {}

  start() {
    if (this.watchSubscription === null) {
      this.restore();
      this.watch();
    }
  }

  private restore() {
    const calendarState = this.store.selectSnapshot<CalendarStateModel>(CalendarState.container(this.calendarId));
    if (isNil(calendarState) || isNil(calendarState.scrollTop)) {
      return;
    }

    const scrollTop: number = parseInt(`${calendarState.scrollTop}`, undefined);
    if (!isNil(scrollTop) && isNumeric(scrollTop) && scrollTop > 0) {
      const matSidenavContentElem = this.document.querySelector('.mat-sidenav-content');
      const agendaScrollTop = parseInt(`${matSidenavContentElem.scrollTop}`, undefined);
      if (agendaScrollTop === 0 || agendaScrollTop !== scrollTop) {
        scrollTo(matSidenavContentElem, scrollTop, 200, this.ngZone);
      }
    }
  }

  private watch() {
    this.watchSubscription = fromEvent(this.document.querySelector('.mat-sidenav-content'), 'scroll')
      .pipe(
        untilDestroyed(this, 'ngOnDestroy'),
        debounceTime(100),
        map((event: any) => event.target.scrollTop),
        distinctUntilChanged()
      )
      .subscribe((scrollTop: number) => this.store.dispatch(new CalendarSaveLastScrollPositionAction(this.calendarId, scrollTop)));
  }

  /**
   * Az angular keret nem fogja auto meghivni, csak tartottuk a nev konvenkciokat, azert hasznaljuk az interface-t.
   * FELHIVAS:
   * MINDIG GONDOSKODJ HOGY A HASZNALO MINDIG MEGHIVJA EZT HA MAR NINCS RA SZUKSEG
   */
  ngOnDestroy() {
    if (this.watchSubscription !== null) {
      this.watchSubscription.unsubscribe();
    }
  }
}
