import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from '@roadrecord/type-guard';

const placeholderSeparator = '%%';
const enabledHtmlElements = [
  {
    tagName: 'b',
    autoClosed: false,
  },
  {
    tagName: 'br',
    autoClosed: true,
  },
];

@Pipe({
  name: 'textPlaceholderHtmlTagReplacer',
})
export class TextPlaceholderHtmlTagReplacerPipe implements PipeTransform {
  transform(value: string, args: { disableHtmlElements: string[] } = { disableHtmlElements: [] }): any {
    // TODO ezt be kell vezetni, de jelenleg meg van par kod aminel mi allitunk elo HTML-t igy azokat fel kell oldani!
    // html entities sanitize
    // value = value.replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
    //   return '&#' + i.charCodeAt(0) + ';';
    // });
    enabledHtmlElements
      .filter(enabledElement => args.disableHtmlElements.indexOf(enabledElement.tagName) === -1)
      .forEach(enabledHtmlElement => {
        const enabledHtmlElementTagName = enabledHtmlElement.tagName;
        if (value.indexOf(`${placeholderSeparator}${enabledHtmlElementTagName}${placeholderSeparator}`) > -1) {
          if (enabledHtmlElement.autoClosed) {
            value = value.replace(
              new RegExp(`${placeholderSeparator}${enabledHtmlElementTagName}${placeholderSeparator}`, 'g'),
              `<${enabledHtmlElementTagName}/>`
            );
          } else {
            const match = value.match(
              new RegExp(
                // tslint:disable-next-line
                // `${placeholderSeparator}${enabledHtmlElementTagName}${placeholderSeparator}.*${placeholderSeparator}\/${enabledHtmlElementTagName}${placeholderSeparator}`,
                `${placeholderSeparator}${enabledHtmlElementTagName}${placeholderSeparator}*(?:${placeholderSeparator}\\/.+?${placeholderSeparator}[^${placeholderSeparator}]*${placeholderSeparator})?([^${placeholderSeparator}]*)${placeholderSeparator}\/${enabledHtmlElementTagName}${placeholderSeparator}`,
                'g'
              )
            );
            if (!isNil(match) && match.length > 0) {
              match.forEach(_match => {
                const splitted = _match.split(`${placeholderSeparator}${enabledHtmlElementTagName}${placeholderSeparator}`);
                splitted
                  .filter(dirtyFragment => dirtyFragment.length > 0)
                  .forEach(dirtyFragment => {
                    // cleaning
                    const cleanFragment = dirtyFragment.replace(
                      `${placeholderSeparator}\/${enabledHtmlElementTagName}${placeholderSeparator}`,
                      ''
                    );
                    value = value.replace(
                      `${placeholderSeparator}${enabledHtmlElementTagName}${placeholderSeparator}${dirtyFragment}`,
                      `<${enabledHtmlElementTagName}>${cleanFragment}</${enabledHtmlElementTagName}>`
                    );
                  });
              });
            }
          }
        }
      });

    args.disableHtmlElements.forEach(disableElem => (value = value.replace(new RegExp(`%%${disableElem}%%`, 'g'), ' ')));

    return value;
  }
}
