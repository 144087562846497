import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class InvalidPeriodContextGuard implements CanDeactivate<any> {
  constructor(private store: Store) {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return (
      this.store.selectSnapshot(states => states.menu.INVALID_PERIOD_CONTEXT) !== 2 /*InvalidPeriodContextEnum.FORCE_SELECT_PERIOD_CONTEXT*/
    );
  }
}
