import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Store } from '@ngxs/store';
import { HttpErrorInterceptor } from '@roadrecord/http-error-interceptor';
import { isNil, isString } from '@roadrecord/type-guard';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PartnerService } from '../partner.service';
import { PartnerModel } from '@roadrecord/partner/model';

@Injectable()
export class UpdatePartnerStateResolverService implements Resolve<any> {
  private httpErrorInterceptor: HttpErrorInterceptor;
  private partnerService: PartnerService;
  constructor(private store: Store, @Inject(HTTP_INTERCEPTORS) httpInterceptors: any[], http: HttpClient) {
    this.partnerService = new PartnerService(http);
    this.httpErrorInterceptor = httpInterceptors.find(httpInterceptor => httpInterceptor instanceof HttpErrorInterceptor);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const model = this.store.selectSnapshot<PartnerModel | string>(route.data.UpdatePartnerStateResolverService.stateSelect);
    if (!isNil(model) && !isString(model)) {
      this.httpErrorInterceptor.addDisableErrorCode(404);
      this.httpErrorInterceptor.addDisableErrorCode(403);
      return this.partnerService.getByIds([model.id], 'expand=trip_reason').pipe(
        switchMap(_remoteModel => {
          const remoteModel = _remoteModel.results[0];
          this.httpErrorInterceptor.removeDisableErrorCode(404);
          this.httpErrorInterceptor.removeDisableErrorCode(403);
          return this.store
            .dispatch(
              new UpdateFormValue({
                value: { partner: remoteModel },
                path: route.data.UpdatePartnerStateResolverService.updateKey,
              })
            )
            .pipe(map(() => remoteModel));
        }),
        catchError(error => {
          this.httpErrorInterceptor.removeDisableErrorCode(404);
          this.httpErrorInterceptor.removeDisableErrorCode(403);
          return this.store
            .dispatch(
              new UpdateFormValue({
                value: { partner: null },
                path: route.data.UpdatePartnerStateResolverService.updateKey,
              })
            )
            .pipe(map(() => error));
        })
      );
    }
    return undefined;
  }
}
