import { StateContext, Store } from '@ngxs/store';
import { GetUserStatesSuccessAction } from '@roadrecord/common/common';
import { asapScheduler } from 'rxjs';
import { AbstractPeriodContextStateModel } from '../model/abstract-period-context-state.model';
import { PeriodContextRefreshRemoteStateSuccessAction } from '../action/period-context-refresh-remote-state-success.action';
import { detectMenuRefreshRemoteSuccessActionFn } from './detect-menu-refresh-remote-success.action-fn';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';

export function detectUserStateSuccessActionFn<STATEMODEL extends AbstractPeriodContextStateModel<any>>(
  ngZone: NgZone,
  router: Router,
  store: Store,
  prefix: string
) {
  return (ctx: StateContext<STATEMODEL>, action: GetUserStatesSuccessAction): void => {
    asapScheduler.schedule(() => ctx.dispatch(new PeriodContextRefreshRemoteStateSuccessAction(action.states[`${prefix}.period-context`])));

    // bootstrap-nal is detectalni kell az invalid period context-et
    const menuPrefix = `${prefix}.menu`;
    detectMenuRefreshRemoteSuccessActionFn<STATEMODEL>(
      ngZone,
      router,
      store,
      prefix
    )(ctx, { state: action.states[menuPrefix], keyPath: menuPrefix });
  };
}
