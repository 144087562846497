import {
  AbstractFragmentPresenterClass,
  FragmentDialogTypeEnum,
  FragmentPresenterConstructor,
  PresenterConfigInterface,
} from '@roadrecord/fragment-dialog';
import { PartnerEditFragmentPresenter } from './partner-edit-fragment.presenter';
import { PartnerNewFragmentPresenter } from './partner-new-fragment.presenter';

export function _newPresenterFactory({
  store,
  matDialog,
  fragmentType,
  componentFactoryResolver,
  document,
  appRef,
  injector,
}: FragmentPresenterConstructor<any>): AbstractFragmentPresenterClass<any, any> {
  return new PartnerNewFragmentPresenter(store, matDialog, fragmentType, componentFactoryResolver, document, appRef, injector);
}

export function _editPresenterFactory({
  store,
  matDialog,
  fragmentType,
  componentFactoryResolver,
  document,
  appRef,
  injector,
}: FragmentPresenterConstructor<any>): AbstractFragmentPresenterClass<any, any> {
  return new PartnerEditFragmentPresenter(store, matDialog, fragmentType, componentFactoryResolver, document, appRef, injector);
}

export const PARTNER_NEW_FRAGMENT_PRESENTERS: PresenterConfigInterface[] = [
  {
    id: FragmentDialogTypeEnum.NEW_PARTNER,
    appRef: null,
    componentFactoryResolver: null,
    document: null,
    injector: null,
    presenterFactory: _newPresenterFactory,
  },
  {
    id: FragmentDialogTypeEnum.NEW_HEAD_OR_OFFICE_SITE,
    appRef: null,
    componentFactoryResolver: null,
    document: null,
    injector: null,
    presenterFactory: _newPresenterFactory,
  },
  {
    id: FragmentDialogTypeEnum.NEW_CHARGING_STATION,
    appRef: null,
    componentFactoryResolver: null,
    document: null,
    injector: null,
    presenterFactory: _newPresenterFactory,
  },
] as PresenterConfigInterface[];

export const PARTNER_EDIT_FRAGMENT_PRESENTERS: PresenterConfigInterface[] = [
  {
    id: FragmentDialogTypeEnum.EDIT_PARTNER,
    appRef: null,
    componentFactoryResolver: null,
    document: null,
    injector: null,
    presenterFactory: _editPresenterFactory,
  },
  {
    id: FragmentDialogTypeEnum.EDIT_HEAD_OR_OFFICE_SITE,
    appRef: null,
    componentFactoryResolver: null,
    document: null,
    injector: null,
    presenterFactory: _editPresenterFactory,
  },
  {
    id: FragmentDialogTypeEnum.EDIT_CHARGING_STATION,
    appRef: null,
    componentFactoryResolver: null,
    document: null,
    injector: null,
    presenterFactory: _editPresenterFactory,
  },
] as PresenterConfigInterface[];
