import { FragmentState } from '../fragment.state';
import { FragmentDialogTypeEnum } from '../model/fragment-dialog-type.enum';
import { isNil } from '@roadrecord/type-guard';
import { Store } from '@ngxs/store';

export function getMetadataByTypesFunction(
  store: Store,
  fragmentDialogTypes: (FragmentDialogTypeEnum | string)[] | FragmentDialogTypeEnum | string
) {
  // Ha fragment dialogkent nyitodik ki a layout, akkor van lehetoseg a search beallitasara
  const fragmentDialogSearchValue = store.selectSnapshot<any>(
    FragmentState.metadataByTypes(Array.isArray(fragmentDialogTypes) ? fragmentDialogTypes : [fragmentDialogTypes])
  );
  // TODO ez itt ideiglenes, majd RROHU-2377 -nel kell refactoralni hogy szukseg van-e ra meg ...
  return isNil(fragmentDialogSearchValue) ? '' : fragmentDialogSearchValue.search;
}
