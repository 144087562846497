import { isNil } from '@roadrecord/type-guard';
import { VehicleScopeServerEnum } from '@roadrecord/vehicle/model/common';
import { AbstractPeriodContextModel } from '@roadrecord/period-context/common';

/**
 * A user jelenleg kivalasztott auto-ja alapjan(user contextbol) megmondja hogy
 * magan auto van-e kivalasztva es nem egyeni vallalkozo-e
 */
export function isPrivateVehicleAndIsNotSelfEmployed(state: AbstractPeriodContextModel<any>): boolean {
  if (!isNil(state) && !isNil(state.vehicle) && !isNil(state.vehicle.type)) {
    return state.vehicle.type === VehicleScopeServerEnum.PRIVATE && state.vehicle.is_self_employed === false;
  }
  return false;
}
