import { StateContext } from '@ngxs/store';
import { produce } from '@ngxs-labs/immer-adapter';
import { AbstractPeriodContextStateModel } from '../model/abstract-period-context-state.model';

export function periodContextStartVehiclePullActionFn<STATEMODEL extends AbstractPeriodContextStateModel<any>>(
  ctx: StateContext<STATEMODEL>
) {
  // check is run
  if (ctx.getState().runVehiclePull === false) {
    // if is not run, set run flag is true
    produce<STATEMODEL>(ctx, draft => {
      draft.runVehiclePull = true;
    });
    // and start pull in app-layout
  }
}
