<div class="content" *ngIf="inited; else initTpl"
  ><ng-container *ngFor="let layoutConfig of layout; let i = index"
    ><h3 [ngClass]="{ 'mt-4': i > 0 }">{{ layoutConfig.label | transloco }}</h3
    ><rr-formly-form class="w-100" [form]="form" [fields]="layoutConfig.formConfig"></rr-formly-form></ng-container
></div>
<ng-template #initTpl>
  <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</ng-template>
<div class="footer" [ngClass]="{ 'show-warning': hasModify }" *ngIf="inited"
  ><div *ngIf="hasModify" class="warning-modify">{{ 'USER.APPLICATION_SETTINGS.TABS.HAS_NOT_SAVE_CHANGES_WARNING' | transloco }}</div
  ><button
    type="button"
    [disabled]="form.disabled"
    [ngClass]="{ 'invalid-form': form.submitted && form.invalid }"
    mat-raised-button
    (click)="onClickSubmit()"
    color="primary"
    class="submit-button"
  >
    <div
      class="invalid-form-button-overlay"
      *ngIf="form.submitted && form.invalid"
      matTooltipPosition="left"
      [matTooltip]="form.submitted === true && form.invalid ? ('COMMON.VALIDATION.BUTTON' | transloco) : undefined"
    >
      <mat-icon svgIcon="alert"></mat-icon>
    </div>
    {{ 'COMMON.ACTION.SAVE' | transloco }}
  </button></div
>
