import { Injectable } from '@angular/core';
import { CalendarMomentDateFormatter, DateFormatterParams } from 'angular-calendar';
import { CapitalizeDirective } from '@roadrecord/common/common';

@Injectable()
export class RRCalendarHeaderDateFormatter extends CalendarMomentDateFormatter {
  monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    const dayName = this.moment(date).locale(locale).format('dddd' /*locale === 'hu-hu' ? 'dddd' : 'dddd'*/);
    return CapitalizeDirective.capitalizeText(dayName);
  }

  dayViewTitle({ date, locale }: DateFormatterParams): string {
    const momentDate = this.moment(date).locale(locale);
    return `${momentDate.format('LL')}`;
  }
}
