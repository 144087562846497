<mat-horizontal-stepper
  *ngIf="currentStepViewModel"
  [ngClass]="{ 'auto-height': currentStepViewModel.type === FirstStepsComponentTypeEnum.VEHICLE }"
  [selectedIndex]="selectedIndex"
  [linear]="true"
  (selectionChange)="onSelectionChange($event)"
>
  <ng-template matStepperIcon="done">
    <mat-icon svgIcon="check" class="small"></mat-icon>
  </ng-template>
  <mat-step
    *ngFor="let step of config?.steps; let i = index"
    [label]="step.title"
    editable="false"
    [stepControl]="stepControl"
    [completed]="i < currentStepViewModel.step"
  >
    <ng-container *ngIf="i === currentStepViewModel.step">
      <ng-container [ngSwitch]="currentStepViewModel?.type">
        <rr-partner-wrapper-step
          *ngSwitchCase="FirstStepsComponentTypeEnum.PARTNER"
          [helpText]="currentStepViewModel?.help_text"
          [type]="currentStepViewModel.type"
          (save)="onSaveStep('PARTNER')"
        ></rr-partner-wrapper-step>
        <rr-partner-wrapper-step
          *ngSwitchCase="FirstStepsComponentTypeEnum.HEAD_OFFICE_OR_SITE"
          [helpText]="currentStepViewModel?.help_text"
          [type]="currentStepViewModel.type"
          (save)="onSaveStep('HEAD_OFFICE_OR_SITE')"
          (skipStep)="onSkipStep('HEAD_OFFICE_OR_SITE')"
        ></rr-partner-wrapper-step>
        <rr-vehicle-wrapper-step
          *ngSwitchCase="FirstStepsComponentTypeEnum.VEHICLE"
          [helpText]="currentStepViewModel?.help_text"
          [type]="currentStepViewModel.type"
          (save)="onSaveStep('VEHICLE')"
          (skipStep)="onSkipStep('VEHICLE')"
        ></rr-vehicle-wrapper-step>
        <rr-register-wrapper-step
          *ngSwitchCase="FirstStepsComponentTypeEnum.SOCIAL_REG_FINISH"
          [helpText]="currentStepViewModel?.help_text"
          (save)="onSaveStep('VEHICLE')"
        ></rr-register-wrapper-step>
      </ng-container>
    </ng-container>
  </mat-step>
</mat-horizontal-stepper>
