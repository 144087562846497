import { CookieService } from 'ngx-cookie-service';

export function _getRegisterReferer(cookieService: CookieService, document: Document, prefix: string) {
  const adversitementEntryCookieValue = cookieService.get(`${prefix}_entry`);
  return adversitementEntryCookieValue.length > 0 ? adversitementEntryCookieValue : document.referrer;
}

export function _getRegisterOptimonkReferer(cookieService: CookieService, document: Document, prefix: string) {
  const optimonkRefererCookieValue = cookieService.get(`${prefix}optimonk_referer`);
  return optimonkRefererCookieValue.length > 0 ? optimonkRefererCookieValue : '';
}
