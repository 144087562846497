import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JsonDifferComponent } from './json-differ/json-differ.component';
import { IsNilPipeModule } from '@roadrecord/common/common';

@NgModule({
  imports: [CommonModule, IsNilPipeModule],
  declarations: [JsonDifferComponent],
  exports: [JsonDifferComponent],
})
export class JsonDifferModule {}
