import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngxs/store';
import { UniversalImportService } from '@roadrecord/universal-import/common';
import { FinalizationOfRouteImportModel } from './model/finalization-of-route-import.model';

export const finalizationOfRouteImportEntityDefaultOrder = 'state';

@Injectable()
export class FinalizationOfRouteUniversalImportService extends UniversalImportService<FinalizationOfRouteImportModel> {
  constructor(http: HttpClient, store: Store, injector: Injector) {
    super(http, store, injector, 'fixed-destination/import', finalizationOfRouteImportEntityDefaultOrder);
  }
}
