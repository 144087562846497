import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { PartnerService } from './partner.service';

@Injectable()
export class HeadOfficeOrSiteService extends PartnerService {
  readonly uuid;

  constructor(http: HttpClient) {
    super(http, 'head-office-or-site', 'name');

    this.uuid = this.uuid = UUID.UUID();
  }
}
