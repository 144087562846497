<div class="map-box-wrapper h-100" [ngClass]="{ overlay: openedInFullScreenMode }" (click)="closeInFullScreenMode()">
  <mat-card class="rr-card2">
    <mat-card-content [ngClass]="{ 'map-blur-effect': showRefreshLayer }">
      <mgl-map
        *ngIf="browserHasWebGLSupport; else notSupportWebGL"
        [style]="mapboxMode === false ? osmMapUrl : 'mapbox://styles/mapbox/streets-v11'"
        [zoom]="defaultCenterZoom"
        [center]="mapCenter"
        [antialias]="true"
        [accessToken]="
          mapboxMode === false ? undefined : 'pk.eyJ1Ijoic3phYm9nYWJvciIsImEiOiJjazBwazkzY2owa2k3M2NxcmJxanc0dGpzIn0.9T8W9Tk8CcMzLwlCGokHrA'
        "
        [locale]="locale"
        (click)="onClickMap($event)"
        (load)="onLoadedMap()"
      >
        <mgl-control
          *ngIf="showUserLocation && geolocateUser"
          #geoLocate
          mglGeolocate
          [positionOptions]="{
            enableHighAccuracy: true,
            showAccuracyCircle: false
          }"
          [fitBoundsOptions]="{ zoom: defaultZoom }"
        ></mgl-control>
        <mgl-control mglFullscreen position="top-left" *ngIf="enableFullScreen"></mgl-control>
        <mgl-control mglNavigation position="top-left"></mgl-control>
        <mgl-control mglScale [unit]="environment.map.scaleUnit"></mgl-control>
        <mgl-control *ngIf="customButtonArea" position="top-right">
          <ng-container *ngTemplateOutlet="customButtonArea"></ng-container>
        </mgl-control>
        <ng-container *ngIf="markers$ | async as markers">
          <mgl-geojson-source
            #geoJSONSourceComponent
            id="markers"
            [data]="markers"
            [clusterRadius]="50"
            [cluster]="showMarkerInCluster"
            [clusterMaxZoom]="defaultZoom"
          ></mgl-geojson-source>

          <mgl-markers-for-clusters source="markers">
            <ng-template mglPoint let-feature>
              <mgl-marker
                [feature]="feature"
                [draggable]="feature.properties?.draggable"
                (dragStart)="onStartDragMarker()"
                (dragEnd)="onStopDragMarker(feature, markers, $event)"
              >
                <div
                  *ngIf="showMarketRouteNumber === true; else elseSimpleMarkerBlock"
                  matBadgeColor="accent"
                  matBadgeOverlap="true"
                  matBadgeSize="medium"
                  [matTooltip]="feature.properties?.title"
                  [matBadge]="feature.properties?.routerNumbers"
                >
                  <img
                    alt="marker"
                    (click)="onClickMarker(feature)"
                    (load)="onLoadMarkupImg(feature)"
                    src="/assets/images/map-marker-icon.svg"
                    class="custom-marker-icon"
                  />
                </div>
                <ng-template #elseSimpleMarkerBlock>
                  <img
                    alt="marker"
                    (click)="onClickMarker(feature)"
                    (load)="onLoadMarkupImg(feature)"
                    src="/assets/images/map-marker-icon.svg"
                    class="custom-marker-icon"
                  />
                </ng-template>
              </mgl-marker>

              <mgl-popup
                *ngIf="selectedPoint | markerPopUp: feature"
                [closeButton]="true"
                (close)="selectedPoint = null"
                [lngLat]="feature.geometry?.coordinates"
              >
                <div class="mgl-popup-content">
                  <div class="mgl-popup-icon-column">
                    <mat-icon
                      tabindex="0"
                      [svgIcon]="selectedPoint.partner | tripReasonIcon"
                      class="light-color align-text-bottom partner-type-icon"
                    >
                    </mat-icon>
                  </div>

                  <div>
                    <span class="popup-span" style="font-weight: 600">
                      {{ selectedPoint.partner.name }}
                    </span>
                    <span class="popup-span" style="padding-right: 8px" matTooltip="{{ selectedPoint.formatAddress }}">
                      {{ selectedPoint.formatAddress }}
                    </span>
                  </div>

                  <div class="mgl-popup-event-column" *ngIf="showMarketRouteNumber === false">
                    <button
                      type="button"
                      color="accent"
                      mat-mini-fab
                      style="transform: scale(0.75) !important"
                      (click)="onClickEdit()"
                      [matTooltip]="'PARTNER.DETAILS.POI_MAP.EDIT_BUTTON' | transloco"
                    >
                      <mat-icon svgIcon="pencil"></mat-icon>
                    </button>
                  </div>
                </div>
              </mgl-popup>
            </ng-template>
            <ng-template mglClusterPoint let-feature>
              <div class="marker-cluster-wrapper">
                <div class="marker-cluster" (click)="onClickClusterPoint(feature)">
                  {{ feature.properties?.point_count }}
                </div>
              </div>
            </ng-template>
          </mgl-markers-for-clusters>
        </ng-container>
      </mgl-map>
      <ng-template #notSupportWebGL>
        <div class="not-loading">
          <p style="text-align: center; line-height: 30px">
            {{ 'PARTNER.DETAILS.POI_MAP.NOT_SUPPORTED_1' | transloco }}<br />
            {{ 'PARTNER.DETAILS.POI_MAP.NOT_SUPPORTED_2' | transloco }}
          </p>
        </div>
      </ng-template>
      <div class="custom-overlay mat-elevation-z3" *ngIf="customButtons?.length > 0">
        <ng-container *ngFor="let conf of customButtons">
          <ng-container [ngSwitch]="conf.type">
            <button *ngSwitchCase="'fullscreen'" rrPreventClick (click)="conf.click()" type="button" mat-button [matTooltip]="conf.tooltip"
              ><mat-icon [svgIcon]="conf.icon"></mat-icon
            ></button>
          </ng-container> </ng-container
      ></div>
    </mat-card-content>

    <div *ngIf="showRefreshLayer" class="show-refresh-layer">
      <button
        color="primary"
        type="button"
        mat-fab
        [matTooltip]="'PARTNER.DETAILS.POI_MAP.SEARCH_BUTTON.TOOLTIP' | transloco"
        class="pulse-refresh-button"
        (click)="onRefreshButtonClick()"
        #showRefreshLayerTooltip="matTooltip"
      >
        <mat-icon svgIcon="refresh"></mat-icon>
      </button>
    </div>
  </mat-card>
</div>
