import {
  liveAutoCompleteCommonDiFactory,
  LiveAutoCompleteEditButtonOptionModel,
  LiveAutoCompleteNewButtonOptionModel,
  LiveAutoCompleteOptionsConfigModel,
} from '@roadrecord/live-auto-complete';
import { PartnerListModel } from '@roadrecord/partner/model';
import { FragmentDialogTypeEnum } from '@roadrecord/fragment-dialog';

export function commonChargingStationLiveAutoCompleteDiFactory(config: LiveAutoCompleteOptionsConfigModel<PartnerListModel>) {
  return liveAutoCompleteCommonDiFactory(
    'common--charging-station',
    () =>
      new LiveAutoCompleteOptionsConfigModel<PartnerListModel>({
        ...config,
        newOptions: new LiveAutoCompleteNewButtonOptionModel({
          label: 'PARTNER.LIVE_AUTO_COMPLETE.ADD_NEW_LABEL.CHARGING_STATION',
          newFragment: FragmentDialogTypeEnum.NEW_CHARGING_STATION,
          showTopRightCloseButton: config.newOptions.showTopRightCloseButton,
        }),
        editOptions: new LiveAutoCompleteEditButtonOptionModel({
          editFragment: FragmentDialogTypeEnum.EDIT_CHARGING_STATION,
          showTopRightCloseButton: config.newOptions.showTopRightCloseButton,
        }),
        prefixIconFn: undefined,
        validatorMessages: [{ errorKey: 'required', translateKey: 'PARTNER.LIVE_AUTO_COMPLETE.VALIDATOR.REQUIRED.CHARGING_STATION' }],
      })
  );
}
