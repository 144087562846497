import { NgZone } from '@angular/core';
import { timer } from 'rxjs';

function easeInOutQuad(t, b, c, d): number {
  t /= d / 2;
  if (t < 1) {
    return (c / 2) * t * t + b;
  }
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
}

export function scrollTo(element, to, duration, ngZone: NgZone, cb?: () => void): void {
  ngZone.runOutsideAngular(() => {
    const start = element.scrollTop;
    const change = to - start;
    let currentTime = 0;
    const increment = 30;

    const animateScroll = () => {
      currentTime += increment;
      element.scrollTop = easeInOutQuad(currentTime, start, change, duration);
      if (currentTime < duration) {
        timer(increment).subscribe(() => animateScroll());
      } else if (typeof cb === 'function') {
        ngZone.run(() => timer(0).subscribe(() => cb()));
      }
    };
    animateScroll();
  });
}
