import { CallbackOptions, IntrojsService, Step } from './introjs.service';
import { INTROJS_TYPES_ENUM, IntrojsConfigInterface, IntrojsMock } from './introjs-config.interface';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { CompleteIntroAction } from './state/action/complete-intro.action';
import { Options } from 'intro.js';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { isNil } from '@roadrecord/type-guard';

export const clickElementCb = element => element.click();

export function stepTitle(translatePrefix: string, step: number) {
  return `${translatePrefix}${step}.TITLE`;
}

export function stepContent(translatePrefix: string, step: number) {
  return `${translatePrefix}${step}.CONTENT`;
}

export function generateIntrojsConfig(
  _steps: Partial<Step>[],
  translatePrefix: string,
  callbacks?: CallbackOptions,
  globalOptions?: Options,
  mocks?: IntrojsMock[]
): IntrojsConfigInterface {
  return {
    steps: _steps.map((step, index) => {
      step.title = stepTitle(translatePrefix, index + 1);
      step.intro = stepContent(translatePrefix, index + 1);
      return step;
    }) as Step[],
    globalOptions,
    callbacks,
    mocks,
  };
}

export function introjsSendGoogleEvent(injector: Injector, type: INTROJS_TYPES_ENUM, action: string) {
  const tagManager = injector.get(GoogleTagManagerService);
  if (!isNil(tagManager)) {
    tagManager.pushTag({
      event: action,
      category: 'intro',
      label: type,
    });
  }
}

export function introjsCommonBeforeStart(injector: Injector, type: INTROJS_TYPES_ENUM, routeHome = true) {
  introjsSendGoogleEvent(injector, type, 'start');
  if (routeHome) {
    return injector.get(Router).navigate(['/']);
  }
  return Promise.resolve();
}

export function introjsCommonExit(injector: Injector, type: INTROJS_TYPES_ENUM, isComplete: boolean) {
  injector.get(Store).dispatch(new CompleteIntroAction(type, injector.get(IntrojsService).dontShowAgainCheckboxValue));
  introjsSendGoogleEvent(injector, type, isComplete ? 'complete' : 'abort');
}
