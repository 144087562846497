import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { GetCompanyMeAction } from '@roadrecord/common/common';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyMeStateUpdateResolverService implements Resolve<any> {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.store.dispatch(new GetCompanyMeAction());
    return null;
  }
}
