import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput } from '@angular/material/datepicker';
import { Select } from '@ngxs/store';
import { checkPropertyChange, globalDatePickerValidationMessages } from '@roadrecord/common/common';
import { I18nState } from '@roadrecord/i18n-state';
import { isNil, isString, isTrue } from '@roadrecord/type-guard';
import { ValidationMessageModel } from '@roadrecord/validating';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';

@Component({
  selector: 'rr-day-select-form-control',
  templateUrl: './day-select-form-control.component.html',
  styleUrls: ['./day-select-form-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DaySelectFormControlComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatDatepickerInput)
  datePickerInput: MatDatepickerInput<any>;
  @ViewChild(MatDatepicker)
  datePicker: MatDatepicker<any>;
  /**
   * form field label (translate key!)
   */
  @Input()
  label: string;
  /**
   * input name attributum
   */
  @Input()
  name: string;
  @Input()
  formGroup: FormGroup;
  @Input()
  year: string;
  @Input()
  month: string;
  /**
   * kivalaszthato a nap?
   */
  @Input()
  editable = false;
  /**
   * form field hint (translate key!)
   */
  @Input()
  hint: string;
  @Input()
  minDate: Date;
  @Input()
  maxDate: Date;
  @Input()
  removeControls = true;
  /**
   * a formcontrol ertekebol kalkulalja ki a min es max date-et
   * (adott datum tagyhonap elso es utolso napja)
   */
  @Input()
  autoCalculateMinMaxDate = false;
  @Input()
  disablePicker = false;
  /**
   * Mat input required attributum megadasa miatt kell
   */
  @Input()
  readonly required = false;
  /**
   * validator component-nek van lehetoseg atadni extra uzeneteket
   */
  @Input()
  extraErrorMessages: ValidationMessageModel[];
  @HostBinding('class.full-date-mode')
  @Input()
  readonly fullDateMode = false;
  formControl: AbstractControl;
  @Output()
  closed = new EventEmitter<void>();
  private formControlValueChangesSubscription: Subscription;
  private initedFullDateMode = false;

  @ViewChild('matDatepicker')
  matDatepicker: MatDatepicker<any>;
  @Select(I18nState.selectedLang)
  selectedLang$: Observable<string>;

  constructor(private cdr: ChangeDetectorRef, @Inject(MAT_DATE_FORMATS) private dateFormat: any) {}

  ngAfterViewInit(): void {
    if (this.fullDateMode && this.initedFullDateMode === false) {
      this.initedFullDateMode = true;
      (this.datePickerInput as any)._dateFormats = this.dateFormat;
      this.formControl.patchValue(this.formControl.value);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkExtraErrorMessageChanges(changes);
    if (!isNil(this.formControlValueChangesSubscription) && this.formControlValueChangesSubscription.closed === false) {
      return;
    }
    if (checkPropertyChange('fullDateMode', changes)) {
      if (isTrue(changes.fullDateMode.currentValue)) {
        this.removeControls = false;
        this.editable = false;
      }
    }
    if (
      checkPropertyChange('formGroup', changes) ||
      checkPropertyChange('autoCalculateMinMaxDate', changes) ||
      checkPropertyChange('name', changes)
    ) {
      if (!isNil(this.formGroup) && !isNil(this.name)) {
        this.formControl = this.formGroup.get(this.name);
        const streamPipes = [distinctUntilChanged()];
        if (!isNil(this.formControl.value)) {
          streamPipes.push(startWith(this.formControl.value));
        }
        this.formControlValueChangesSubscription = (this.formControl.valueChanges as any).pipe(...streamPipes).subscribe((value: Date) => {
          if (isNil(value)) {
            this.year = '';
            this.month = '';
            this.minDate = undefined;
            this.maxDate = undefined;
            return;
          }
          if (!isNil(this.autoCalculateMinMaxDate)) {
            if (this.autoCalculateMinMaxDate === true) {
              this.minDate = moment(value).startOf('month').toDate();
              this.maxDate = moment(value).endOf('month').toDate();
            }
          }
          if (value instanceof moment) {
            value = (value as any).toDate();
          } else if (isString(value)) {
            value = moment(value).toDate();
          }
          this.year = `${value.getFullYear()}`;
          const month = `${value.getMonth() + 1}`;
          this.month = month.length === 1 ? `0${month}` : month;
          this.cdr.markForCheck();
        });
      }
    }
  }

  /**
   * checkoljuk hogy valtozott-e az extraErrorMessages input, ha igen akkor merge-k a global date picker szabalyokkal
   */
  private checkExtraErrorMessageChanges(changes: SimpleChanges): void {
    if (checkPropertyChange('extraErrorMessages', changes)) {
      const newErrorMessages: ValidationMessageModel[] = changes.extraErrorMessages.currentValue || [];
      if (
        newErrorMessages.find(
          errorMessage =>
            globalDatePickerValidationMessages.find(globalErrorMessage => globalErrorMessage.errorKey === errorMessage.errorKey) !==
            undefined
        ) === undefined
      ) {
        this.extraErrorMessages = [...globalDatePickerValidationMessages, ...this.extraErrorMessages];
      }
    }
  }

  onClosed(): void {
    this.closed.emit();
  }

  onClickInput(): void {
    if (this.fullDateMode) {
      this.datePicker.open();
    }
  }

  ngOnInit(): void {
    // ha nincs error messages beallitva akkor a global date picker validation message-ket beallitjuk
    if (this.extraErrorMessages === undefined || this.extraErrorMessages.length === 0) {
      this.extraErrorMessages = globalDatePickerValidationMessages;
    }
  }
}
