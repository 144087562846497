<ng-template #cmpTemplate>
  <rr-trip-reason-details
    #details
    [routingMode]="false"
    [hasSubmitAndNew]="false"
    [controlBackButton]="true"
    [windowEditModelId]="windowEditModelId"
    (cancel)="cancel.emit()"
    (save)="uiSave($event)"
  ></rr-trip-reason-details>
</ng-template>
