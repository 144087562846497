import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { isEmpty, isNil } from '@roadrecord/type-guard';
import { PhoneValidators } from './phone-validators';

export function phoneNumberValidator(locale: string): AsyncValidatorFn {
  const ngxPhoneValidator = PhoneValidators.isPossibleNumberWithReason(locale);

  // tslint:disable-next-line
  return async function (c: AbstractControl): Promise<ValidationErrors | any> {
    const transformedValueControl: AbstractControl = { value: `${c.value}` } as AbstractControl;
    const ngxPhoneValidatorError = await ngxPhoneValidator(transformedValueControl);
    if (!isNil(ngxPhoneValidatorError)) {
      return ngxPhoneValidatorError;
    }
    if (locale === 'HU') {
      const value: string = c.value;
      if (!isNil(value) && value.length > 0) {
        const prefix = value.slice(0, 2);
        if ([20, 30, 70].indexOf(parseInt(prefix, undefined)) > -1 && value.length - 2 < 7) {
          return { phoneNumberTooShort: true };
        }
      }
    }
    return null;
  };
}

export function phoneNumberValidatorNgxFormly(locale: string): AsyncValidatorFn {
  const asyncCallback = phoneNumberValidator(locale);
  return async function (c: AbstractControl): Promise<ValidationErrors | any> {
    return (await asyncCallback(c)) === null;
  };
}

export function _phoneNumberValidator2(c: AbstractControl) {
  const value = !isNil(c.value) && c.value.indexOf('_') > -1 ? c.value.substring(0, c.value.indexOf('_')) : c.value;

  if (isNil(value)) {
    return false;
  }

  if (value.length !== 10) {
    return false;
  }
  return true;
}

export function phoneNumberValidator2(c: AbstractControl) {
  return _phoneNumberValidator2(c) ? null : { phoneNumber: true };
}

export function _phoneNumberValidator3(c: AbstractControl) {
  const value = !isNil(c.value) && c.value.indexOf('_') > -1 ? c.value.substring(0, c.value.indexOf('_')) : c.value;

  if (isNil(value) || isEmpty(value)) {
    return true;
  }

  return value.length === 10;
}

export function phoneNumberValidator3(c: AbstractControl) {
  return _phoneNumberValidator3(c) ? null : { phoneNumber: true };
}
