import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { isNil } from '@roadrecord/type-guard';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { PresenterStateController } from '../../presenter-state/presenter-state.controller';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseViewRemoteFieldErrorPlugin } from './base-view-remote-field-error.plugin';

/**
 * remote field hibakat koti ossze a form-val
 *
 * hasznalat:
 *
 * init: form init utan
 * this.form = new ...
 * this.remoteFieldErrorController = new RemoteFieldErrorController(this.form, this.translocoService, this);
 * +
 * letre kell hozni egy setter-t
 *
 *   @Input()
 *   set remoteErrors(errors: RemoteErrorsModel) {
 *           if (this.remoteFieldErrorController !== undefined) {
 *                this.remoteFieldErrorController.setRemoteErrors(errors);
 *           }
 *   }
 *
 */
@UntilDestroy()
@Injectable()
export class ViewRemoteFieldErrorPlugin<MODEL, IDTYPE> extends BaseViewRemoteFieldErrorPlugin<MODEL, IDTYPE> {
  constructor(
    private presenterStateController: PresenterStateController<MODEL, IDTYPE>,
    translocoService: TranslocoService,
    @Inject(DOCUMENT) document: Document,
    messageDialogService: MessageDialogService
  ) {
    super(translocoService, document, messageDialogService);
    this.presenterStateController.formGroup$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        filter(formGroup => !isNil(formGroup))
      )
      .subscribe(formGroup => this.initValidators(formGroup));
  }

  get formGroupLastValue(): FormGroup {
    return this.presenterStateController.formGroupLastValue;
  }
}
