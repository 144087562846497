import { GridHeaderOtherAction } from '../types/grid-header-other.action';
import { isNotDeletable, isNotEditable } from '@roadrecord/common/common';

export function generateDefaultGridHeaderOtherActions<E>(_this: any): GridHeaderOtherAction<E>[] {
  return [
    _this.addEditRowHeaderOtherAction === true
      ? {
          type: 'ITEM',
          label: { translate: 'COMMON.ACTION.ADD' },
          icon: 'plus',
          action: () => _this.onClickNewRow(),
        }
      : {
          type: 'ITEM',
          label: { translate: 'COMMON.ACTION.ADD' },
          icon: 'plus',
          route: ['./new'],
          relativeRoute: true,
        },
    {
      type: 'ITEM',
      label: { translate: 'COMMON.ACTION.EDIT' },
      icon: 'pencil',
      action: () =>
        _this.handleRowClick({
          element: _this.selection.selected[0],
          index: _this.dataSource.data.findIndex((elem: any) => elem.id === _this.selection.selected[0].id),
        }),
      disabled: () =>
        _this.isLoadingResults ||
        _this.selection.selected.length > 1 ||
        _this.selection.selected.length === 0 ||
        isNotEditable(_this.selection.selected[0]),
    },
    {
      type: 'ITEM',
      label: { translate: 'COMMON.ACTION.DELETE' },
      icon: 'delete',
      disabled: (entities: E[]) =>
        _this.isLoadingResults ||
        _this.selection.selected.length === 0 ||
        _this.selection.selected.length > 1 ||
        (_this.selection.selected.length === 1 && isNotDeletable(_this.selection.selected[0])),
      action: () => _this.deleteSelectedElements({ deleteElements: _this.selection.selected }),
    },
    {
      type: 'ITEM',
      icon: 'refresh',
      label: { translate: 'COMMON.ACTION.REFRESH' },
      action: _this.refresh.bind(_this),
      disabled: () => _this.isLoadingResults,
    },
  ];
}
