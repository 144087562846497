import { LiveAutoCompleteOptionsConfigModel } from '@roadrecord/live-auto-complete';
import { addressFormatter } from './address-formatter.function';
import { laPartnerOptionsConfig } from '@roadrecord/partner/live-auto-complete';
import { PartnerListHuModel } from '../model/partner-list-hu.model';
import { isNil } from '@roadrecord/type-guard';

function addressFn(model: PartnerListHuModel) {
  return isNil(model) || isNil(model.address) ? '' : `${model.address.postcode} ${addressFormatter(model, false)}`;
}

export function displayFn(model: PartnerListHuModel) {
  return `${model.name} (${addressFn(model)})`;
}

export const laPartnerOptionsConfigHu = new LiveAutoCompleteOptionsConfigModel<PartnerListHuModel>({
  ...laPartnerOptionsConfig,
  displayFn,
  optionDisplayFn: displayFn,
  lazyOptionElem: {
    ...laPartnerOptionsConfig.lazyOptionElem,
    options: {
      ...laPartnerOptionsConfig.lazyOptionElem.options,
      addressTextCb: model => addressFn(model),
    },
  },
});
