import { HTTP_INTERCEPTORS, HttpBackend, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IntrojsMock } from './introjs-config.interface';
import { isFunction, isNil } from '@roadrecord/type-guard';
import { tsDeepcopy } from '@roadrecord/ts-deepcopy';

/**
 * `HttpHandler` which applies an `HttpInterceptor` to an `HttpRequest`.
 *
 *
 */
export class HttpInterceptorHandler implements HttpHandler {
  constructor(private next: HttpHandler, private interceptor: HttpInterceptor) {}

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    return this.interceptor.intercept(req, this.next);
  }
}

/**
 * Update eseten figyelni kell ra, hogy lehet utana kell huzni az angular forrasa alapjan
 * forras: https://github.com/angular/angular/blob/8.2.x/packages/common/http/src/module.ts#L31
 */
@Injectable()
export class HttpInterceptingHandler implements HttpHandler {
  private chain: HttpHandler | null = null;

  constructor(private backend: HttpBackend, private injector: Injector) {}

  private _mocks: IntrojsMock[] | null = null;

  set mocks(value: IntrojsMock[]) {
    this._mocks = tsDeepcopy(value);
  }

  resetMocks() {
    this._mocks = null;
  }

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (this.chain === null) {
      const interceptors = this.injector.get(HTTP_INTERCEPTORS, []);
      this.chain = interceptors.reduceRight((next, interceptor) => new HttpInterceptorHandler(next, interceptor), this.backend);
    }
    if (!isNil(this._mocks)) {
      const mockIndex = this._mocks.findIndex(mock => req.url.endsWith(isFunction(mock.url) ? mock.url(req.url) : mock.url));
      if (mockIndex > -1) {
        const mock = this._mocks[mockIndex];
        this._mocks.splice(mockIndex, 1);
        return of(new HttpResponse({ status: mock.statusCode, body: isFunction(mock.body) ? mock.body() : mock.body }));
      }
    }
    return this.chain.handle(req);
  }
}
