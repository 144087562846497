import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractEntityService, HttpListResponseModel } from '@roadrecord/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IResetPasswordUser } from '../../../authentication/model/IResetPasswordUser';
import { CompanyMemberListModel } from './model/company-member-list.model';
import { CompanyMemberRoleEnum } from './model/company-member-role.enum';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

@Injectable()
export class CompanyMemberService extends AbstractEntityService<HttpListResponseModel<CompanyMemberListModel>, CompanyMemberListModel> {
  constructor(http: HttpClient) {
    super('company/member', 'full_name', http);
  }

  getToString(model: CompanyMemberListModel): string {
    return `${model.first_name} ${model.last_name}->${model.email}`;
  }

  getModelIdValue(model: CompanyMemberListModel): any {
    if (model !== undefined) {
      if (model.hasOwnProperty('id')) {
        return model.id;
      } else if (model.hasOwnProperty('email')) {
        return model.email;
      }
    }
    return undefined;
  }

  getAll(sort?: Sort, page?: PageEvent, simpleAllFilterValue?: string): Observable<HttpListResponseModel<CompanyMemberListModel>> {
    return super.getAll(sort, page, simpleAllFilterValue).pipe(
      map(response => {
        response.results = response.results.map(entry => {
          if (entry.role === CompanyMemberRoleEnum.OWNER) {
            entry.is_editable = false;
            entry.is_deletable = false;
          }
          return entry;
        });
        return response;
      })
    );
  }

  resendInvite(email: string) {
    return this.http.post(`${this.getEntityApiUrl()}/resend-invitation-activation/`, { email });
  }

  makePassword(user: IResetPasswordUser) {
    return this.http.post(`${this.getEntityApiUrl()}/make-password/`, { ...user });
  }
}
