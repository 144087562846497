import { NgModule } from '@angular/core';
import { NoopFunction } from '@roadrecord/common/common';
import { ILoadableConfig, LoadableModule, LoadableService } from '@roadrecord/external-packages/ngx-loadable';

export const lazyKeyPartnerLiveAutoCompleteOption = 'partner-live-auto-complete-option';

@NgModule({
  imports: [
    LoadableModule.forFeature({
      moduleConfigs: [
        {
          name: lazyKeyPartnerLiveAutoCompleteOption,
          loadChildren: () =>
            import('libs/partner/live-auto-complete/src/lib/live-auto-complete-lazy/live-auto-complete-lazy.module').then(
              module => module.LiveAutoCompleteLazyModule
            ),
        },
      ] as any,
    } as ILoadableConfig),
  ],
})
export class LiveAutoCompleteModule {
  constructor(private loadableService: LoadableService) {
    /**
     * Preloadoljuk, mert ha mar erre hivatkoznak akkor fogjak hasznalni is, viszont a feluleten lassabb net mellett
     * elofordulhat apro lag, ha nem preload-uk
     */
    Promise.resolve()
      .then(() => this.loadableService.preload(lazyKeyPartnerLiveAutoCompleteOption))
      .then(NoopFunction);
  }
}
