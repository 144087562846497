import { ChangeDetectionStrategy, Component, HostBinding, HostListener } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { BASIC_DATA_PAGE_PATH, VEHICLE_ROUTE_PATH, ViewportService } from '@roadrecord/common/common';
import { Observable, Subject } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { MenuState } from '../menu/state/menu.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { MenuStateModel } from '../menu/state/model/menu-state.model';
import { action } from '@storybook/addon-actions';

@UntilDestroy()
@Component({
  selector: 'rr-warning-display',
  templateUrl: './warning-display.component.html',
  styleUrls: ['./warning-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningDisplayComponent {
  readonly disabledMenuMessage$: Observable<string>;
  @Select(MenuState.disabled_menu_message)
  readonly _disabledMenuMessage$: Observable<string>;
  private readonly refresh$ = new Subject<void>();
  @Select(MenuState.disable_menu_action)
  readonly disabledMenAction$: Observable<MenuStateModel['disabled_menu_action'] | undefined>;
  @HostBinding('class.clickable')
  clickableCssClass = false;

  constructor(private readonly viewportService: ViewportService, private readonly store: Store, private readonly router: Router) {
    this.disabledMenuMessage$ = this.refresh$.pipe(
      untilDestroyed(this),
      startWith(null),
      switchMap(() => {
        return this._disabledMenuMessage$.pipe(
          map(disabledMenuMessage =>
            disabledMenuMessage
              ? disabledMenuMessage
              : this.viewportService.isMobile
              ? 'COMMON.MESSAGE_DIALOG.MOBILE_ONLY_BASIC_DATAS'
              : undefined
          )
        );
      })
    );

    this.disabledMenAction$.pipe(untilDestroyed(this)).subscribe(maybeAction => {
      if (maybeAction !== undefined) {
        if (this.clickableCssClass === false) {
          this.clickableCssClass = true;
        }
      }
    });
  }

  @HostListener('window:resize')
  onResizeWindow(): void {
    this.refresh$.next();
  }

  @HostListener('click')
  onHostClick(): void {
    this.disabledMenAction$.pipe(take(1), untilDestroyed(this)).subscribe(maybeAction => {
      if (maybeAction !== undefined) {
        switch (maybeAction) {
          case 'GO_TO_VEHICLES':
            this.router.navigate([BASIC_DATA_PAGE_PATH, VEHICLE_ROUTE_PATH]);
            break;
        }
      }
    });
  }
}
