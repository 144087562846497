<rr-grid
  [multiSelection]="false"
  [hasCheckboxColumn]="true"
  [columnDefinitions]="displayedColumns"
  [hasIconColumn]="false"
  [titleText]="['COMPANY_MEMBER.LIST.TITLE', 'COMPANY_MEMBER.LIST.SELECTED_TITLE']"
  titleIcon="account-network"
  [hasActionsColumn]="false"
  [hasFilter]="true"
  [rightClickContextMenuOption]="rightClickContextMenuOption"
  [secureRemove]="true"
  [secureRemoveDialogFullNameKey]="secureDialogFullNameLabelKeyFn"
  [secureRemoveDialogTranslateDialogTitle]="'COMPANY_MEMBER.SECURE_REMOVE.CONFIRM_DELETE'"
  [secureRemoveDialogTranslateLastAndFirstNameLabel]="'COMPANY_MEMBER.SECURE_REMOVE.WRITE_NAME'"
  [secureRemoveDialogTranslateFullNameLabel]="'COMPANY_MEMBER.SECURE_REMOVE.WRITE_FULL_NAME'"
  [secureRemoveDialogTranslateInputLabel]="'COMPANY_MEMBER.SECURE_REMOVE.INPUT.LABEL'"
  [secureRemoveDialogTranslateInputPlaceholder]="'COMPANY_MEMBER.SECURE_REMOVE.INPUT.PLACEHOLDER'"
  [secureRemoveDialogTranslateInputErrorMismatch]="'COMPANY_MEMBER.SECURE_REMOVE.INPUT.ERROR.MISMATCH'"
  [secureRemoveDialogTranslateButtonTooltip]="'COMPANY_MEMBER.SECURE_REMOVE.BUTTON_TOOLTIP'"
  #rrGrid
  [headerGroupActions]="[]"
  [headerOtherActions]="headerOtherActions"
></rr-grid>
