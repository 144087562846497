import { UniversalImportWorkerBridgeImplService } from '@roadrecord/universal-import/common';
import { NgZone } from '@angular/core';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { Store } from '@ngxs/store';

export class PartnerUniversalImportWorkerBridgeImplService extends UniversalImportWorkerBridgeImplService {
  constructor(
    ngZone: NgZone,
    store: Store,
    periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    importWorker: () => Worker
  ) {
    super(ngZone, store, periodContextStateSelectorsService, importWorker, false, 1000);
  }
}
