import { Constructor } from '../mixin/constructor';
import { TranslocoService } from '@ngneat/transloco';
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { mapTo, scan, startWith, switchMap, takeWhile } from 'rxjs/operators';
import { untilDestroyed } from '@ngneat/until-destroy';
import { throwNotOverrideError } from '../mixin/throw-not-override-error';

export interface WithCounterButton extends OnInit {
  disableButton: boolean;
  currentNumber: number;
  buttonText: string;
  readonly countInterval: number;
  readonly counterSub$: Subject<number>;
  counterLimit: number;
  stopCounter: () => void;
}

export type WithCounterButtonCtor = Constructor<WithCounterButton>;

export interface WithCounterButtonConstructorParams {
  readonly translateKeyPrefix: string;
  readonly translocoService: TranslocoService;
  readonly cdr: ChangeDetectorRef;
}

export function mixinWithCounterButton<T extends Constructor<WithCounterButtonConstructorParams>>(base: T): WithCounterButtonCtor & T {
  return class extends base implements OnInit {
    readonly countInterval = 1000;
    readonly counterSub$ = new Subject<number>();
    private counterSubSubscription: Subscription;

    constructor(...args: any[]) {
      super(...args);
    }

    private _disableButton = false;

    get disableButton(): boolean {
      return this._disableButton;
    }

    set disableButton(value: boolean) {
      this._disableButton = value;
    }

    private _currentNumber = 0;

    get currentNumber(): number {
      return this._currentNumber;
    }

    set currentNumber(value: number) {
      this._currentNumber = value;
    }

    private _buttonText: string;

    get buttonText(): string {
      return this._buttonText;
    }

    set buttonText(value: string) {
      this._buttonText = value;
    }

    private _counterLimit = 3;

    get counterLimit(): number {
      return this._counterLimit;
    }

    set counterLimit(value: number) {
      this._counterLimit = value;
    }

    onStartApp() {
      throw throwNotOverrideError('onStartApp');
    }

    initCounter() {
      const counterLimit = this.counterLimit + 1;
      this.counterSubSubscription = this.counterSub$
        .pipe(
          switchMap(endRange => {
            return timer(0, this.countInterval).pipe(
              mapTo(this.positiveOrNegative(endRange, this.currentNumber)),
              startWith(this.currentNumber),
              scan((acc: number, curr: number) => acc + curr),
              takeWhile(this.isApproachingRange(endRange, this.currentNumber))
            );
          }),
          untilDestroyed(this)
        )
        .subscribe((val: number) => {
          this.currentNumber = counterLimit - val;
          this.translocoService
            .selectTranslate(`${this.translateKeyPrefix}BUTTON_WITH_COUNTER`, { count: this.currentNumber })
            .pipe(untilDestroyed(this))
            .subscribe((translatedText: string) => (this.buttonText = translatedText));
          this.cdr.markForCheck();
          if (val === counterLimit) {
            this.onStartApp();
          }
        });
    }

    private startCounter() {
      timer(2000).subscribe(() => {
        this.buttonText = this.translocoService.translate(`${this.translateKeyPrefix}BUTTON_WITH_COUNTER`, { value: this.counterLimit });
        this.counterSub$.next(this.counterLimit + 1);
        this.cdr.markForCheck();
      });
    }

    stopCounter() {
      this.counterSubSubscription.unsubscribe();
    }

    private positiveOrNegative(endRange, currentNumber): 1 | -1 {
      return endRange > currentNumber ? 1 : -1;
    }

    private isApproachingRange(endRange, currentNumber): (val: number) => boolean {
      return endRange > currentNumber ? val => val <= endRange : val => val >= endRange;
    }

    ngOnInit(): void {
      this.initCounter();
      this.startCounter();
    }
  };
}
