import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { TranslocoModule } from '@ngneat/transloco';
import { Actions, NgxsModule, ofActionSuccessful, Store } from '@ngxs/store';
import {
  AppTypePipeModule,
  GetUserStatesAction,
  HoverStateModule,
  IsNilPipeModule,
  IsPresentPipeModule,
  PreventClickModule,
  SanitizePipeModule,
} from '@roadrecord/common/common';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { DraggableDialogModule, StickyNotificationBarModule } from '@roadrecord/common-components';
import { DialogModule } from '@roadrecord/dialog';
import { IdleStartDetectAction } from '@roadrecord/idle-detect';
import { PreferencesLoadAction } from '@roadrecord/preferences-state/common';
import { TextEllipsisModule } from '@roadrecord/text-ellipsis';
import { LoginSuccessAction } from '@roadrecord/user/common';
import { IS_WEBADMIN } from '@roadrecord/utils';
import { HotkeyModule } from 'angular2-hotkeys';
import { take } from 'rxjs/operators';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { MenuPageComponent } from './menu/menu-page/menu-page.component';
import { MenuComponent } from './menu/menu/menu.component';
import { GetItemPipe } from './menu/pipe/get-item.pipe';
import { MenuState } from './menu/state/menu.state';
import { PeriodContextDisplayModule } from './period-context-display/period-context-display.module';
import { WarningDisplayComponent } from './warning-display/warning-display.component';
import { NgOverrideModule } from '@roadrecord/ng-override';
import { SubscriptionDisplayComponent } from './subscription-display/subscription-display.component';
import { MatCardModule } from '@angular/material/card';
import { IntrojsModule } from '@roadrecord/introjs/common';
import { MenuItemHelpComponent } from './menu/menu-item-help/menu-item-help.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { ValidatingModule } from '@roadrecord/validating';
import { IMaskModule } from 'angular-imask';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    FlexLayoutModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatTooltipModule,
    MatMenuModule,
    MatListModule,
    MatTabsModule,
    MatProgressBarModule,
    PortalModule,
    TranslocoModule,
    DialogModule,
    DraggableDialogModule,

    MatProgressSpinnerModule,

    StickyNotificationBarModule,
    NgxsModule.forFeature([MenuState]),
    PeriodContextDisplayModule,
    HotkeyModule,
    TextEllipsisModule,
    PreventClickModule,
    IsPresentPipeModule,
    IsNilPipeModule,
    NgOverrideModule,
    SanitizePipeModule,
    MatCardModule,
    IntrojsModule,
    HoverStateModule,
    AppTypePipeModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    ValidatingModule,
    IMaskModule,
    MatSlideToggleModule,
  ],
  declarations: [
    AppLayoutComponent,
    MenuComponent,
    MenuPageComponent,
    WarningDisplayComponent,
    GetItemPipe,
    SubscriptionDisplayComponent,
    MenuItemHelpComponent,
  ],
  exports: [StickyNotificationBarModule],
  entryComponents: [WarningDisplayComponent],
})
export class AppLayoutModule {
  constructor(actions$: Actions, private store: Store, @Inject(IS_WEBADMIN) isWebadmin: boolean) {
    actions$.pipe(ofActionSuccessful(LoginSuccessAction), take(1)).subscribe(() => {
      let dispatchActions: any[];
      if (isWebadmin) {
        dispatchActions = [new GetUserStatesAction(), new IdleStartDetectAction()];
      } else {
        dispatchActions = [new GetUserStatesAction(), new PreferencesLoadAction(), new IdleStartDetectAction()];
      }
      dispatchActions.forEach(dispatchAction => store.dispatch(dispatchAction));
    });
  }
}
