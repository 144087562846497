import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { produce } from '@ngxs-labs/immer-adapter';
import { Action, Actions, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { isNil, isString } from '@roadrecord/type-guard';
import { asapScheduler, Observable, throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { PreferencesCloseContentWindowAction } from './action/preferences-close-content-window.action';
import { PreferencesLoadErrorAction } from './action/preferences-load-error.action';
import { PreferencesLoadSuccessAction } from './action/preferences-load-success.action';
import { PreferencesLoadAction } from './action/preferences-load.action';
import { PreferencesOpenContentWindowAction } from './action/preferences-open-content-window.action';
import { PreferencesSetFaqAction } from './action/preferences-set-faq.action';
import { PreferencesSetHelpVideoUrlAction } from './action/preferences-set-help-video-url.action';
import { isCompanyVehicle } from './context-function/is-company-vehicle.function';
import { isPrivateVehicleAndIsNotSelfEmployed } from './context-function/is-private-vehicle-and-is-not-self-employed.function';
import { FaqConfigModel } from './model/faq/faq-config.model';
import { HelpVideoModel } from './model/help-video/help-video.model';
import { PreferencesStateRemoteModel } from './model/preferences-state-remote.model';
import { PreferencesStateModel } from './model/preferences-state.model';
import { PreferencesContentWindowTypeEnum } from './model/preferences-content-window-type.enum';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '@roadrecord/environment';

const ENDPOINT_PREFERENCES = 'preferences';
const PREFERENCES_STATE_NAME = 'preferences';
const PREFERENCES_FAQ_DEFAULT_PERIOD_CONTEXT_KEY = 'company';
const PREFERENCES_FAQ_DEFAULT_URL = 'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.URLS.DEFAULT_FAQ_URL';
const PREFERENCES_FAQ_URL_LABEL_DEFAULT_URL = 'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.ITEM.CENTER_GYIK';
const PREFERENCES_FAQ_URL_LABEL_URL = 'APP_LAYOUT.NAV_TOOLBAR.HELP_MENU.ITEM.GYIK';

const logScopeName = 'PreferencesState';

@State<PreferencesStateModel>({
  name: PREFERENCES_STATE_NAME,
  defaults: {
    application_settings: [],
    faq_urls: [],
    help_video_urls: [],
    currentFaqConfig: undefined,
    currentHelpVideoUrl: undefined,
    openedContentWindows: {},
    version: 5,
  },
})
@Injectable()
export class PreferencesState {
  private loaded = false;
  private lastCurrentRoute: string;
  private defaultFaqUrl: string;
  /**
   * a jelenlegi faq (gyik) -et adja vissza (mindig tartalmazz valamit)
   */
  @Selector()
  static faq(state: PreferencesStateModel): FaqConfigModel {
    return state.currentFaqConfig;
  }

  /**
   * jelenlegi seged video url-t tartalmazza amennyiben van olyan
   */
  @Selector()
  static helpVideoUrl(state: PreferencesStateModel): string {
    return state.currentHelpVideoUrl;
  }

  @Selector()
  static hasOpenedContentWindow(state: PreferencesStateModel) {
    return (type: PreferencesContentWindowTypeEnum) => !isNil(state.openedContentWindows[type]);
  }

  static getApplicationData(key: string, defaultValue?: any) {
    return createSelector([PreferencesState], (state: PreferencesStateModel) => {
      const foundApplicationSetting = state.application_settings.find(applicationSetting => applicationSetting.key === key);
      if (!isNil(foundApplicationSetting)) {
        return foundApplicationSetting.values[0].data;
      }
      return isNil(defaultValue) ? null : defaultValue;
    });
  }

  constructor(
    private readonly http: HttpClient,
    private readonly actions: Actions,
    private readonly store: Store,
    private router: Router,
    private translocoService: TranslocoService
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (!this.loaded) {
        return;
      }
      const currentRoute = event.url;
      this.dispatchFaqAndHelpVideoConfigs(currentRoute);
    });
  }

  private dispatchFaqAndHelpVideoConfigs(currentRoute): void {
    // query es fragment parametereket eltavolitjuk
    currentRoute = currentRoute.split('?')[0];
    currentRoute = currentRoute.split('#')[0];
    if (this.lastCurrentRoute === currentRoute) {
      return;
    }
    // tarolni az eredeti teljes url-t taroljuk
    this.lastCurrentRoute = currentRoute;
    const periodContextState = this.store.selectSnapshot(states => states.periodContext.model);
    const periodContextTypeKeyName = isNil(periodContextState)
      ? PREFERENCES_FAQ_DEFAULT_PERIOD_CONTEXT_KEY
      : isPrivateVehicleAndIsNotSelfEmployed(periodContextState as any)
      ? 'private'
      : isCompanyVehicle(periodContextState as any)
      ? 'company'
      : 'self_employed';

    this.store.dispatch(new PreferencesSetFaqAction(this.createFaqConfig(currentRoute, periodContextTypeKeyName)));
    this.store.dispatch(new PreferencesSetHelpVideoUrlAction(this.createHelpVideoUrl(currentRoute, periodContextTypeKeyName)));
  }

  private createFaqConfig(route: string, periodContextTypeKeyName: string): FaqConfigModel {
    const faqUrls = this.store.selectSnapshot(states => (states[PREFERENCES_STATE_NAME] as PreferencesStateModel).faq_urls);
    let foundFaqUrlConfig = faqUrls.find(faqUrl => faqUrl.route === route);

    if (foundFaqUrlConfig === undefined) {
      // route-k vizsgalata amiben parameter van
      foundFaqUrlConfig = faqUrls.find(faqUrl => {
        if (faqUrl.route.indexOf(':') > -1) {
          const splittedBrowserRoute = route.split('/');
          return (
            faqUrl.route
              .split('/')
              .map((fragment, index) => (fragment.indexOf(':') > -1 ? splittedBrowserRoute[index] : fragment))
              .join('/') === route
          );
        }
        return false;
      });
    }

    let url: string;
    let label = PREFERENCES_FAQ_URL_LABEL_DEFAULT_URL;
    if (isNil(this.defaultFaqUrl)) {
      this.defaultFaqUrl = this.translocoService.translate(PREFERENCES_FAQ_DEFAULT_URL);
    }
    if (foundFaqUrlConfig === undefined) {
      url = this.defaultFaqUrl;
      console.warn(logScopeName, `Not found faq: ${route}`);
    } else {
      if (isString(foundFaqUrlConfig.urls[periodContextTypeKeyName]) && foundFaqUrlConfig.urls[periodContextTypeKeyName].length > 0) {
        url = foundFaqUrlConfig.urls[periodContextTypeKeyName];
        label = PREFERENCES_FAQ_URL_LABEL_URL;
      } else {
        url = this.defaultFaqUrl;
        console.warn(logScopeName, `Not found faq url config: ${route}`);
      }
    }
    return {
      url,
      label,
    };
  }

  private createHelpVideoUrl(route: string, periodContextTypeKeyName: string): string {
    const helpUrls = this.store.selectSnapshot<HelpVideoModel[]>(
      states => (states[PREFERENCES_STATE_NAME] as PreferencesStateModel).help_video_urls
    );
    let foundHelpVideoUrlConfig = helpUrls.find(faqUrl => faqUrl.route === route);

    if (foundHelpVideoUrlConfig === undefined) {
      // route-k vizsgalata amiben parameter van
      foundHelpVideoUrlConfig = helpUrls.find(faqUrl => {
        if (faqUrl.route.indexOf(':') > -1) {
          const splittedBrowserRoute = route.split('/');
          return (
            faqUrl.route
              .split('/')
              .map((fragment, index) => (fragment.indexOf(':') > -1 ? splittedBrowserRoute[index] : fragment))
              .join('/') === route
          );
        }
        return false;
      });
    }

    let url: string;
    if (foundHelpVideoUrlConfig === undefined) {
      console.warn(logScopeName, `Not found help video: ${route}`);
    } else {
      if (
        isString(foundHelpVideoUrlConfig.urls[periodContextTypeKeyName]) &&
        foundHelpVideoUrlConfig.urls[periodContextTypeKeyName].length > 0
      ) {
        url = foundHelpVideoUrlConfig.urls[periodContextTypeKeyName];
      } else {
        console.warn(logScopeName, `Not found help video config: ${route}`);
      }
    }
    return url;
  }

  @Action(PreferencesLoadAction)
  loadPreferences({ dispatch }: StateContext<PreferencesStateModel>): Observable<PreferencesStateRemoteModel> {
    return this.http.get<PreferencesStateRemoteModel>(`${environment.apiUrl}${ENDPOINT_PREFERENCES}/`).pipe(
      tap(preferences => asapScheduler.schedule(() => dispatch(new PreferencesLoadSuccessAction(preferences)))),
      catchError(err => {
        asapScheduler.schedule(() => dispatch(new PreferencesLoadErrorAction(err)));
        return throwError(err);
      })
    );
  }

  @Action(PreferencesLoadSuccessAction)
  loadPreferencesSuccess(ctx: StateContext<PreferencesStateModel>, action: PreferencesLoadSuccessAction): any {
    this.loaded = true;
    produce<PreferencesStateModel>(ctx, draft => {
      draft.application_settings = action.preferences.application_settings;
      draft.faq_urls = action.preferences.faq_urls;
      draft.help_video_urls = action.preferences.help_video_urls;
    });

    return this.dispatchFaqAndHelpVideoConfigs(this.router.url);
  }

  @Action(PreferencesSetFaqAction)
  setFaq(ctx: StateContext<PreferencesStateModel>, action: PreferencesSetFaqAction): any {
    produce<PreferencesStateModel>(ctx, draft => {
      draft.currentFaqConfig = action.faq;
    });
  }

  @Action(PreferencesSetHelpVideoUrlAction)
  setHelpVideoUrl(ctx: StateContext<PreferencesStateModel>, action: PreferencesSetHelpVideoUrlAction): any {
    produce<PreferencesStateModel>(ctx, draft => {
      if (isString(action.url)) {
        const url = new URL(action.url);
        let start: string | undefined;
        if (url.searchParams.has('start')) {
          start = url.searchParams.get('start');
        } else if (url.searchParams.has('t')) {
          start = url.searchParams.get('t');
        } else if (action.url.includes(';start=')) {
          start = action.url.split(';start=')[1];
        }

        draft.currentHelpVideoUrl = `${url.origin}/${url.pathname}?rel=0&showinfo=0${start !== undefined ? `&start=${start}` : ''}`;
      } else {
        draft.currentHelpVideoUrl = undefined;
      }
    });
  }

  @Action(PreferencesOpenContentWindowAction)
  preferencesOpenContentWindow(
    ctx: StateContext<PreferencesStateModel>,
    { windowType, ngModuleRef }: PreferencesOpenContentWindowAction
  ): void {
    produce(ctx, draft => {
      if (isNil(draft.openedContentWindows[windowType])) {
        draft.openedContentWindows[windowType] = {
          ngModuleRef: ngModuleRef,
        };
      }
    });
  }

  @Action(PreferencesCloseContentWindowAction)
  preferencesCloseContentWindow(ctx: StateContext<PreferencesStateModel>, { windowType }: PreferencesCloseContentWindowAction): void {
    produce(ctx, draft => {
      if ((windowType as any) === 'ALL') {
        Object.values(draft.openedContentWindows).forEach((v: any) => {
          if (!isNil(v.ngModuleRef)) {
            v.ngModuleRef.destroy();
          }
        });
        draft.openedContentWindows = {};
      } else {
        const stateType = draft.openedContentWindows[windowType];
        if (!isNil(stateType) && !isNil(stateType.ngModuleRef)) {
          stateType.ngModuleRef.destroy();
        }
        delete draft.openedContentWindows[windowType];
      }
    });
  }
}
