import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { checkPropertyChange, RRFormGroup } from '@roadrecord/common/common';
import { isNil } from '@roadrecord/type-guard';
import { Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { FormGroup } from '../../form2/form';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@UntilDestroy()
@Component({
  selector: 'rr-details-button-row',
  styleUrls: ['./details-button-row.component.scss'],
  templateUrl: './details-button-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsButtonRowComponent implements OnChanges {
  @Input()
  gaPrefix?: string;
  /**
   * a legkulso form group elem amihez tartozik a gombsor
   */
  @Input()
  form: RRFormGroup | FormGroup;
  /**
   * uj mode -ban vagyunk ? (delete gomb nem jelenhet meg uj modban)
   *
   */
  @Input()
  isNew = false;
  /**
   * gombot letiltasa
   *
   */
  @Input()
  disabled: boolean;
  @Input()
  disableDelete: boolean;
  @Input()
  disableSubmit: boolean;
  @Input()
  disableSubmitAndNew: boolean;
  /**
   * mentes es uj gomb megjelenitese
   *
   */
  @Input()
  hasSubmitAndNew = true;
  /**
   * delete gomb megjelenitese
   *
   */
  @Input()
  hasDelete = true;
  @Input()
  deleteTooltip: string;
  /**
   * torles jelzese
   *
   */
  @Output()
  delete = new EventEmitter<void>();
  /**
   * sima mentes jelzese
   */
  @Output()
  submitForm = new EventEmitter<void>();
  /**
   * ha csak menteni akarunk es nem akarunk redirectet vissza
   */
  @Output()
  justSaveSubmitForm = new EventEmitter<void>();
  /**
   * mentes es uj jelzese
   */
  @Output()
  submitAndNew = new EventEmitter<void>();
  private formStatusChangeSubscription: Subscription;
  @Input() hasJustSave = false;

  @Input() texts = {
    actions: {
      save: 'COMMON.ACTION.SAVE',
      edit: 'COMMON.ACTION.EDIT',
    },
  };

  constructor(private cdr: ChangeDetectorRef, private router: Router, @Optional() private gtmService: GoogleTagManagerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const form: FormGroup = checkPropertyChange('form', changes) ? changes.form.currentValue : undefined;
    if (!isNil(form)) {
      if (this.formStatusChangeSubscription !== undefined) {
        this.formStatusChangeSubscription.unsubscribe();
      }
      this.formStatusChangeSubscription = form.statusChanges.pipe(untilDestroyed(this)).subscribe(status => this.cdr.markForCheck());
    }
  }

  onDelete(): void {
    this.delete.emit();
  }

  onSubmit(): void {
    if (!isNil(this.gtmService)) {
      this.gtmService.pushTag({
        event: `Route: ${this.router.url}`,
        category: 'figyeloListaSubmit',
        label: `submit(${this.hasSubmitAndNew})`,
      });
    }
    this.submitForm.emit();
  }

  onJustSaveSubmit(): void {
    if (!isNil(this.gtmService)) {
      this.gtmService.pushTag({
        event: `Route: ${this.router.url}`,
        category: 'figyeloListaSubmit',
        label: `submit(${this.hasSubmitAndNew})`,
      });
    }
    this.justSaveSubmitForm.emit();
  }

  onSubmitAndNew(): void {
    if (!isNil(this.gtmService)) {
      this.gtmService.pushTag({
        event: `Route: ${this.router.url}`,
        category: 'figyeloListaSubmit',
        label: this.isNew ? 'submitCreateAndNew' : 'submitModifyAndNew',
      });
    }
    this.submitAndNew.emit();
  }
}
