import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { fadeInEnter, zoomIn2XEnter } from '@roadrecord/animations';
import { BASIC_DATA_PAGE_PATH, checkPropertyChange, TRIP_REASON_ROUTE_PATH } from '@roadrecord/common/common';
import { FragmentDialogTypeEnum, FragmentHideDialogAction, FragmentRemoveDialogAction } from '@roadrecord/fragment-dialog';
import { MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';
import { laTripReasonOptionsConfig, TripReasonService } from '@roadrecord/trip-reason/common';
import { isBoolean, isNil, isNumeric } from '@roadrecord/type-guard';
import {
  HasViewModelPlugin,
  HasViewSubmitPlugin,
  PresenterStateController,
  SaveModel,
  ViewRemoteFieldErrorPlugin,
} from '@roadrecord/utils';
import { rrFormErrorStateMatcher } from '@roadrecord/validating';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Subscription, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, skip, startWith } from 'rxjs/operators';
import { checkIsValidCoordinate } from '../function/check-is-valid-coordinate.function';
import { TripReasonModel } from '@roadrecord/trip-reason/model';
import { PARTNER_ADRESS_FORMATTER, PartnerAddressFormatterReturnType, PartnerModel, PartnerTypeEnum } from '@roadrecord/partner/model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DYNAMIC_FORM_CONFIG, DynamicFormConfigEnum, DynamicFormConfigInterface } from '@roadrecord/dynamic-form';

@UntilDestroy()
@Component({
  selector: 'rr-data-form, rr-partner-data-form',
  templateUrl: './data-form.component.html',
  styleUrls: ['./data-form.component.scss'],
  providers: [rrFormErrorStateMatcher, TripReasonService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInEnter, zoomIn2XEnter],
})
export class DataFormComponent implements OnInit, OnChanges, HasViewModelPlugin<PartnerModel>, HasViewSubmitPlugin<PartnerModel> {
  @ViewChild('formTpl', { static: true })
  formTpl: TemplateRef<any>;
  @ViewChild('buttonsTpl', { static: true })
  buttonsTpl: TemplateRef<any>;
  @Input()
  hasSubmitAndNew = true;
  @Input()
  isNew = true;
  @Input()
  editModel: PartnerModel;
  @Input()
  coordinateLoadingMask = false;
  @Input()
  type: PartnerTypeEnum;
  @Input()
  showAddTripReason = true;
  @Input()
  readonly disableAllSelectNewOption = false;
  @Output()
  submitForm = new EventEmitter<SaveModel<PartnerModel>>();
  @Output()
  delete = new EventEmitter<void>();
  @Output()
  selectTripReason = new EventEmitter<TripReasonModel>();
  form: FormGroup;
  nameControl = new FormControl(undefined, Validators.required);
  tripReasonControl = new FormControl(undefined, Validators.required);
  typeControl = new FormControl(undefined, Validators.required);
  laTripReasonOptionsConfig = laTripReasonOptionsConfig;
  checkIsValidCoordinateFn = checkIsValidCoordinate;
  PartnerTypeEnum = PartnerTypeEnum;
  @Input()
  typeControlIsVertical = false;
  @Input()
  dataFormTypeControlLabelBlock = false;
  nameControlLabel: string;
  loadingFullTripReasonList = false;
  fullTripReasonList: TripReasonModel[];
  addressFormFragmentFormlyFields: FormlyFieldConfig[];
  @Output() modifyAddressFields = new EventEmitter<boolean>();
  translateTypeSuffix: keyof typeof PartnerTypeEnum;
  gaType!: string;
  @Input() disableEventButtons;
  private fragmentDialogActionsSubscription: Subscription;
  private initedAddressFieldsValueChanges = false;

  constructor(
    private renderer2: Renderer2,
    readonly tripReasonService: TripReasonService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private translocoService: TranslocoService,
    private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    private store: Store,
    private actions: Actions,
    readonly presenterStateController: PresenterStateController<PartnerModel, number>,
    private messageDialogService: MessageDialogService,
    @Inject(DYNAMIC_FORM_CONFIG) dynamicFormConfigs: DynamicFormConfigInterface[],
    private injector: Injector,
    @Inject(PARTNER_ADRESS_FORMATTER) readonly partnerAddressFormatter: PartnerAddressFormatterReturnType
  ) {
    this.addressFormFragmentFormlyFields = dynamicFormConfigs
      .find(config => config.name === DynamicFormConfigEnum.PARTNER_ADDRESS_FORM_FRAGMENT)
      .config(this.injector);

    this.watchTypeControlChanges();
    this.subscribeFragmentDialogActions();

    // field hiba eseten ha edit mode-ban vagyunk akkor vissza rakjuk a disable allapotokat
    (this.presenterStateController.get(ViewRemoteFieldErrorPlugin) as ViewRemoteFieldErrorPlugin<PartnerModel, number>).settedRemoteErrors$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.setEditModelUIRestriction());
  }

  setEditModelUIRestriction(): void {
    if (
      this.isNew === false &&
      this.typeControl.value === PartnerTypeEnum.CHARGING_STATION &&
      this.typeControl.value === PartnerTypeEnum.CHARGING_STATION
    ) {
      this.tripReasonControl.disable();
    }
    if (this.isNew === true) {
      this.typeControl.disable();
    }
  }

  onSubmitReset(): void {
    if (this.typeControl.disabled) {
      if (this.isNew) {
        this.editModel.type = this.type;
      } else {
        this.editModel.type = this.typeControl.value;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (checkPropertyChange('type', changes)) {
      this.gaType = PartnerTypeEnum.toString(this.type);
    }

    if (checkPropertyChange('editModel', changes)) {
      if (isBoolean(this.isNew)) {
        const editModel = changes.editModel.currentValue;

        if (!isNil(editModel)) {
          this.form.patchValue(editModel);
        } else {
          this.form.patchValue({});
        }

        if (this.isNew === true) {
          this.typeControl.disable();
          this.setTripReasonControl(this.type);
        } /*else if (!isNil(this.editModel)) {
          // #RROHU-3235
          this.form.disable({ emitEvent: false });
          this.nameControl.enable({ emitEvent: false });
          this.tripReasonControl.enable({ emitEvent: false });
          this.typeControl.enable({ emitEvent: false });
        }*/
        this.initAddressFieldsValueChanges(this.isNew ? 0 : 1);
      }
    }
    if (checkPropertyChange('type', changes)) {
      switch (changes.type.currentValue) {
        case PartnerTypeEnum.CHARGING_STATION:
          this.translateTypeSuffix = 'CHARGING_STATION';
          this.nameControlLabel = 'PARTNER.DETAILS.DATA_FORM.LABEL.NAME.CHARGING_STATION';
          if (!this.isNew) {
            this.tripReasonControl.disable();
          }
          break;
        case PartnerTypeEnum.HEAD_OFFICE_OR_SITE:
          this.translateTypeSuffix = 'HEAD_OFFICE_OR_SITE';
          this.nameControlLabel = 'PARTNER.DETAILS.DATA_FORM.LABEL.NAME.HEAD_OFFICE_OR_SITE';
          break;
        case PartnerTypeEnum.PARTNER:
        default:
          this.translateTypeSuffix = 'PARTNER';
          this.nameControlLabel = 'PARTNER.DETAILS.DATA_FORM.LABEL.NAME.PARTNER';
      }
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.watchTripReasonChanges();
  }

  onSubmit(): void {
    if (this.typeControl.disabled) {
      if (this.isNew) {
        this.editModel.type = this.type;
      } else {
        this.editModel.type = this.typeControl.value;
      }
    }
  }

  onDelete(): void {
    this.delete.emit();
  }

  private initAddressFieldsValueChanges(skipIteration: number) {
    if (this.initedAddressFieldsValueChanges === false) {
      this.initedAddressFieldsValueChanges = true;
      timer(1000).subscribe(() => {
        const fieldNames = this.addressFormFragmentFormlyFields.reduce((next, acc) => {
          // Cím mezők összegyűjtése. Ha a felület partner módosítási módban van akkor a
          // postcocde változását nem figyeljük.
          return next.concat(acc.fieldGroup.map(fieldGroup => fieldGroup.key)).filter(item => {
            return this.isNew || (!this.isNew && item !== 'postcode');
          });
        }, []);

        combineLatest(
          fieldNames.map(fieldName => {
            const field = this.form.get(fieldName);
            return field.valueChanges.pipe(debounceTime(300), distinctUntilChanged(), startWith(field.value));
          })
        )
          .pipe(untilDestroyed(this), skip(skipIteration))
          .subscribe(values => {
            if (this.isNew) {
              if (values.find(value => isNil(value)) === undefined) {
                this.emitModifyAddressFields(fieldNames, values);
              }
            } else {
              // Űrlapon a koordináta értékek és ország mező értékének nullázása.
              this.emitModifyAddressFields(fieldNames, values);
            }
          });
      });
    }
  }

  private emitModifyAddressFields<T>(fieldNames: any[], values: T) {
    const editModelLastValue = this.presenterStateController.editModelLastValue;
    this.modifyAddressFields.emit(
      !isNil(
        fieldNames.find((fieldName, index) =>
          !isNil(editModelLastValue) ? editModelLastValue[fieldName] !== values[index] : undefined !== values[index]
        )
      )
    );
  }

  private watchTripReasonChanges(): void {
    this.tripReasonControl.valueChanges.subscribe((data: TripReasonModel) => {
      this.selectTripReason.emit(data);

      if (
        !isNil(data) &&
        data.is_fueling_station &&
        this.tripReasonControl.enabled &&
        (isNil(this.typeControl.value) ||
          (isNumeric(this.typeControl.value) && this.typeControl.value === PartnerTypeEnum.CHARGING_STATION))
      ) {
        this.tripReasonControl.disable();
      }
    });
  }

  private subscribeFragmentDialogActions(): void {
    this.fragmentDialogActionsSubscription = combineLatest([
      this.actions.pipe(
        ofActionSuccessful(FragmentHideDialogAction),
        filter(action => action.dialogType === FragmentDialogTypeEnum.NEW_TRIP_REASON),
        first()
      ),
      this.actions.pipe(
        // back button kezelese
        ofActionSuccessful(FragmentRemoveDialogAction),
        filter(action => action.dialogType === FragmentDialogTypeEnum.NEW_TRIP_REASON),
        first()
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe((_actions: [FragmentHideDialogAction, FragmentRemoveDialogAction]) => {
        if (_actions[0] !== undefined && _actions[0].data !== undefined) {
          this.tripReasonControl.patchValue(_actions[0].data);
        }

        if (_actions[0] !== undefined && _actions[1] !== undefined) {
          this.fragmentDialogActionsSubscription.unsubscribe();
          this.subscribeFragmentDialogActions();
        }
      });
  }

  private watchTypeControlChanges(): void {
    this.typeControl.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        filter(() => this.isNew === false),
        skip(1)
      )
      .subscribe(type => this.setTripReasonControl(type));
  }

  private setTripReasonControl(type): void {
    if (isNil(this.fullTripReasonList)) {
      this.loadingFullTripReasonList = true;
      this.cdr.markForCheck();
      this.tripReasonService
        .getAll(
          {
            active: 'trip_reason',
            direction: 'asc',
          },
          {
            pageIndex: 0,
            pageSize: 99999999,
            length: 1,
          },
          ''
        )
        .pipe(
          untilDestroyed(this),
          filter(response => {
            if (!Array.isArray(response.results) || response.results.length === 0) {
              this.messageDialogService.openError({
                id: null,
                text: 'PARTNER.DETAILS.DATA_FORM.TRIP_REASON.MESSAGE.NO_TRIP_REASONS',
              });
              return false;
            }
            return true;
          })
        )
        .subscribe(response => {
          this.fullTripReasonList = response.results;
          this.setTripReasonControl(type);
          this.loadingFullTripReasonList = false;
        });
      return;
    }

    const errorDialog = (text: string, snackbarText: string) => {
      return this.messageDialogService
        .open({
          id: null,
          type: MessageDialogTypeEnum.ERROR,
          title: 'COMMON.ERROR',
          text,
        })
        .afterClosed()
        .subscribe(() =>
          this.translocoService
            .selectTranslate([snackbarText, 'COMMON.ACTION.CLOSE_LOWER'])
            .pipe(untilDestroyed(this))
            .subscribe(translatedTexts =>
              this.router.navigate([`/${BASIC_DATA_PAGE_PATH}/${TRIP_REASON_ROUTE_PATH}`]).then(() =>
                this.matSnackBar.open(translatedTexts[0], translatedTexts[1], {
                  panelClass: 'rr-accent',
                })
              )
            )
        );
    };

    let found;
    switch (type) {
      case PartnerTypeEnum.PARTNER:
        found = this.fullTripReasonList.find(tripReason => tripReason.is_default);
        if (!isNil(found)) {
          this.tripReasonControl.patchValue(found);
        } else {
          errorDialog(
            'PARTNER.DETAILS.DATA_FORM.TRIP_REASON.MESSAGE.NOT_FOUND_DEFAULT',
            'PARTNER.DETAILS.DATA_FORM.TRIP_REASON.MESSAGE.REDIRECT_LIST_BECAUSE_NOT_FOUND_DEFAULT'
          );
        }
        if (this.tripReasonControl.disabled) {
          this.tripReasonControl.enable();
        }
        break;
      case PartnerTypeEnum.CHARGING_STATION:
        found = this.fullTripReasonList.find(tripReason => tripReason.is_fueling_station);
        if (!isNil(found)) {
          this.tripReasonControl.patchValue(found);
          this.selectTripReason.emit(found);
        }
        if (this.tripReasonControl.enabled) {
          this.tripReasonControl.disable();
        }
        break;
      case PartnerTypeEnum.HEAD_OFFICE_OR_SITE:
        found = this.fullTripReasonList.find(tripReason => tripReason.is_headquarters);
        if (!isNil(found)) {
          this.tripReasonControl.patchValue(found);
        }
        if (this.tripReasonControl.disabled) {
          this.tripReasonControl.enable();
        }
        break;
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      name: this.nameControl,
      trip_reason: this.tripReasonControl,
      type: this.typeControl,
    });
  }
}
