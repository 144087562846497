import { MatSnackBarConfig } from '@angular/material/snack-bar';

export const matSnackbarErrorConfigTopRight: MatSnackBarConfig = {
  panelClass: 'error',
  verticalPosition: 'top',
  horizontalPosition: 'right',
  duration: 10000,
};
export const matSnackbarErrorConfigTopCenter: MatSnackBarConfig = {
  panelClass: 'error',
  verticalPosition: 'top',
  horizontalPosition: 'center',
  duration: 6000,
};
export const matSnackbarErrorConfigBottomStart: MatSnackBarConfig = {
  panelClass: 'error',
  verticalPosition: 'bottom',
  horizontalPosition: 'start',
  duration: 10000,
};
