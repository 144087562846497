import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitButtonComponent } from './split-button/split-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslocoModule } from '@ngneat/transloco';
import { MatDividerModule } from '@angular/material/divider';
import { GetTranslateParamsPipeModule, IsDisabledPipeModule, IsFunctionPipeModule } from '@roadrecord/common/common';
import { HasTextEllipsisPipe } from './split-button/has-text-ellipsis.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    TranslocoModule,
    MatDividerModule,
    IsFunctionPipeModule,
    IsDisabledPipeModule,
    GetTranslateParamsPipeModule,
    MatTooltipModule,
  ],
  declarations: [SplitButtonComponent, HasTextEllipsisPipe],
  exports: [SplitButtonComponent],
})
export class SplitButtonModule {}
