import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { isString } from '@roadrecord/type-guard';

@Directive({
  selector: '[formControl][rrCapitalize],[formControlName][rrCapitalize]',
})
export class CapitalizeDirective {
  @Input() disableCapitalize = false;
  private lastValue: string;

  constructor(private ref: ElementRef) {}

  static capitalizeText(value: string): string {
    if (!isString(value)) {
      return value;
    }
    const firstChar = value.charAt(0);
    if (!/[A-ZÖÜÓŐÚÉÁŰÍ]/.test(firstChar)) {
      return `${firstChar.toUpperCase()}${value.slice(1)}`;
    }
    return value;
  }

  @HostListener('input', ['$event'])
  capitalize($event): void {
    $event.preventDefault();
    if ($event['__capitalize_directive_runned'] === true) {
      return;
    }
    let capitalizedText: string;
    if (this.disableCapitalize === false) {
      capitalizedText = CapitalizeDirective.capitalizeText($event.target.value);
      if (capitalizedText !== $event.target.value) {
        $event.target.value = capitalizedText;
      }
    }

    // amikor bemasoljak az erteket akkor kell kulon ertesites
    if (!this.lastValue || (this.lastValue && $event.target.value.length > 0 && this.lastValue !== capitalizedText)) {
      this.lastValue = this.ref.nativeElement.value = capitalizedText;
      // Propagation
      const evt = document.createEvent('HTMLEvents');
      evt.initEvent('input', false, true);
      evt['__capitalize_directive_runned'] = true;
      $event.target.dispatchEvent(evt);
    }
  }
}
