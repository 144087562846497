import { FragmentDialogTypeEnum } from '@roadrecord/fragment-dialog';
import {
  LiveAutocompleDisplayFunction,
  LiveAutoCompleteEditButtonOptionModel,
  LiveAutoCompleteNewButtonOptionModel,
  LiveAutocompleteOptionDisabledBindFunction,
  LiveAutocompleteOptionDisplayBindFunction,
  LiveAutocompleteOptionModelIconFunction,
  LiveAutoCompleteOptionsConfigModel,
  LiveAutocompleteOptionValueBindFunction,
  LiveAutocompletePrefixIconFunction,
} from '@roadrecord/live-auto-complete';
import { isNil, isString } from '@roadrecord/type-guard';
import { TripReasonModel } from '@roadrecord/trip-reason/model';

export const laTripReasonOptionDisabledBindFn: LiveAutocompleteOptionDisabledBindFunction<TripReasonModel> = (
  model: TripReasonModel,
  selectedModel: TripReasonModel
) => model.id === selectedModel.id;
export const laTripReasonOptionDisplayFn: LiveAutocompleteOptionDisplayBindFunction<TripReasonModel> = (model: TripReasonModel) =>
  model.trip_reason;
export const laTripReasonOptionValueBindFn: LiveAutocompleteOptionValueBindFunction<TripReasonModel> = (model: TripReasonModel) =>
  !isNil(model) ? model.id : null;
export const laTripReasonPrefixIconFn: LiveAutocompletePrefixIconFunction<TripReasonModel> = (model: TripReasonModel) => {
  if (isString(model) || isNil(model.id)) {
    return undefined;
  }
  return model.is_fueling_station ? 'gas-station' : model.is_private ? 'human-handsdown' : 'briefcase';
};

export const laTripReasonOptionModelIconFn: LiveAutocompleteOptionModelIconFunction<TripReasonModel> = (model: TripReasonModel) => ({
  icon: model.is_fueling_station ? 'gas-station' : model.is_private ? 'human-handsdown' : 'briefcase',
});
export const laTripReasonDisplayFn: LiveAutocompleDisplayFunction<TripReasonModel> = (model: TripReasonModel) => model.trip_reason;

export const laTripReasonOptionsConfig = new LiveAutoCompleteOptionsConfigModel({
  optionDisabledBindFn: laTripReasonOptionDisabledBindFn,
  optionDisplayFn: laTripReasonOptionDisplayFn,
  optionValueBindFn: laTripReasonOptionValueBindFn,
  optionModelIconFn: laTripReasonOptionModelIconFn,
  displayFn: laTripReasonDisplayFn,
  prefixIconFn: laTripReasonPrefixIconFn,
  placeholder: 'TRIP_REASON.LIVE_AUTO_COMPLETE.PLACEHOLDER',
  newOptions: new LiveAutoCompleteNewButtonOptionModel({
    label: 'TRIP_REASON.LIVE_AUTO_COMPLETE.ADD_NEW_LABEL',
    newFragment: FragmentDialogTypeEnum.NEW_TRIP_REASON,
  }),
  editOptions: new LiveAutoCompleteEditButtonOptionModel({
    editFragment: FragmentDialogTypeEnum.EDIT_TRIP_REASON,
  }),
  validatorMessages: [{ errorKey: 'required', translateKey: 'TRIP_REASON.LIVE_AUTO_COMPLETE.VALIDATOR.REQUIRED' }],
});
