import { Observable } from 'rxjs';

export const consoleLogValue = (text?: string) => <T>(source: Observable<T>) =>
  new Observable<T>(observer =>
    source.subscribe({
      next(x): void {
        console.info('StreamValue', `${text}`, x);
        observer.next(x);
      },
      error(err): void {
        observer.error(err);
      },
      complete(): void {
        observer.complete();
      },
    })
  );
