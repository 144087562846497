import { isNil } from '@roadrecord/type-guard';
import { DocumentDataCheckerActionUiConfig } from '@roadrecord/document-data-checker-action/model';

export function getRootActionById(config: DocumentDataCheckerActionUiConfig[], id: number) {
  for (let i = 0; i < config.length; i++) {
    const foundRootAction = config[i].actions.find(rootAction => rootAction.id === id);
    if (!isNil(foundRootAction)) {
      return foundRootAction;
    }
  }
  return undefined;
}
