import { NgModule } from '@angular/core';
import { ContextMenuModule } from 'ngx-contextmenu';
import { ContextMenuAttachDirective } from './context-menu.attach.directive';

@NgModule({
  declarations: [ContextMenuAttachDirective],
  imports: [ContextMenuModule.forRoot({ autoFocus: true })],
  exports: [ContextMenuModule, ContextMenuAttachDirective],
})
export class RightClickMenuModule {}
