import { Injectable, Injector } from '@angular/core';
import { produce } from '@ngxs-labs/immer-adapter';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  GetUserStatesSuccessAction,
  TRIAL_TIME_ROUTE_PATH,
  TRIAL_TIME_AUTO_SUBSCRIBE_ROUTE_PATH,
  TRIAL_TIME_EXPIRED_ROUTE_PATH,
  USER_LOGIN_PATH,
  USER_ROUTE_PATH,
} from '@roadrecord/common/common';
import { isNil, isNotEmptyString, isString } from '@roadrecord/type-guard';
import { CompanyContextStateModel } from './model/company-context-state.model';
import { CompanyResponseModel } from './model/company-response.model';
import { SubscriptionInfoEnum } from './model/subscription-info-enum';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { CompanyContextSubscriptionStateModel } from './model/company-context-subscription-state.model';
import { getCompanyContextSubscriptionDateFormat } from './company-context-subscription-date-format';
import { commonHttpStreamErrorHandler, openWindowOrMessageDialog } from '@roadrecord/utils';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { STATE_PREFIX_TOKEN } from './state-prefix.token';
import { SubscriptionGoToAction } from './action/subscription-go-to.action';
import { attachCompanyStateContextDynamicActions } from './attach-company-state-context-dynamic-actions.function';
import parse from 'date-fns/parse';
import isAfter from 'date-fns/isAfter';
import { environment } from '@roadrecord/environment';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpErrorInterceptor } from '@roadrecord/http-error-interceptor';

const moment = _rollupMoment || _moment;

@State<CompanyContextStateModel>({
  name: 'companyContext',
  defaults: {
    id: undefined,
    name: undefined,
    subscription: null,
    version: 4,
    phone_number_required: undefined,
  },
})
@Injectable()
export class CompanyContextState {
  private readonly prefix: string;

  // csak azert nem siman 'name' mert az tiltott!
  private router: Router;
  private httpErrorInterceptor!: HttpErrorInterceptor;

  @Selector()
  static companyName(state: CompanyContextStateModel): string {
    return state.name;
  }

  @Selector()
  static id(state: CompanyContextStateModel): number {
    return state.id;
  }

  @Selector()
  static subscriptionState(state: CompanyContextStateModel): SubscriptionInfoEnum {
    return state.subscription.state;
  }

  @Selector()
  static hasPhoneNumber(state: CompanyContextStateModel): boolean {
    return state.phone_number_required;
  }

  @Selector()
  static subscriptionNumberOfDaysElapsed(state: CompanyContextStateModel): number {
    return state.subscription.numberOfDaysElapsed;
  }

  @Selector()
  static subscription(state: CompanyContextStateModel): Partial<CompanyContextSubscriptionStateModel> {
    return state.subscription;
  }

  @Selector()
  static showOnSaleEndDate(state: CompanyContextStateModel): boolean {
    return !isNil(state.subscription) && state.subscription.state === 0 && !isNil(state.subscription.on_sale_end_date);
  }

  @Selector()
  static isTrialTimeExpired(state: CompanyContextStateModel): boolean {
    return (
      state.subscription.state === SubscriptionInfoEnum.NO_SUBSCRIPTION &&
      isNotEmptyString(state.subscription.system_access_trial_end_date) &&
      isAfter(new Date(), parse(state.subscription.system_access_trial_end_date, 'yyyy-MM-dd HH:mm:ss', new Date()))
    );
  }

  constructor(injector: Injector, private http: HttpClient) {
    this.prefix = injector.get<string>(STATE_PREFIX_TOKEN);
    this.router = injector.get(Router);
    this.httpErrorInterceptor = injector
      .get<any[]>(HTTP_INTERCEPTORS)
      .find(httpInterceptor => httpInterceptor instanceof HttpErrorInterceptor);

    attachCompanyStateContextDynamicActions(CompanyContextState, this.http);
  }

  @Action(GetUserStatesSuccessAction)
  getUserStatesSuccess(ctx: StateContext<CompanyContextStateModel>, action: GetUserStatesSuccessAction): any {
    if (isNil(action.states[`${this.prefix}.company-context`]) || isNil(action.states[`${this.prefix}.company-context`].company)) {
      return;
    }
    const company = action.states[`${this.prefix}.company-context`].company as CompanyResponseModel;
    if (
      CompanyContextState.isTrialTimeExpired({
        subscription: {
          state: company.subscription_state,
          system_access_trial_end_date: company.system_access_trial_end_date,
        },
      } as CompanyContextStateModel)
    ) {
      this.httpErrorInterceptor.addDisableErrorCode(400);
      return this.http.post<{ link: string }>(`${environment.apiUrl}company/trial-extension/`, '').pipe(
        tap(response => {
          this.setFromRemoteState(ctx, company, 'ok');
        }),
        catchError(error => {
          this.setFromRemoteState(ctx, company, 'error');
          return of('');
        }),
        tap(() => this.httpErrorInterceptor.removeDisableErrorCode(400))
      );
    }
    this.setFromRemoteState(ctx, company);
  }

  private setFromRemoteState(ctx: StateContext<CompanyContextStateModel>, company: CompanyResponseModel, state?: 'error' | 'ok') {
    let subscription: Partial<CompanyContextSubscriptionStateModel>;
    produce<CompanyContextStateModel>(ctx, draft => {
      draft.id = company.id;
      draft.name = company.name;
      draft.phone_number_required = company.phone_number_required;
      subscription = {
        state: company.subscription_state,
        subscription_start_date: company.subscription_start_date,
        subscription_end_date: company.subscription_end_date,
        system_access_trial_end_date: company.system_access_trial_end_date,
      };
      // #RROHU-2022
      if (!isNil(company.subscription_start_date) && isString(company.subscription_start_date)) {
        subscription.subscriptionStartDate = (moment as any)(company.subscription_start_date).format(
          getCompanyContextSubscriptionDateFormat()
        );
      }
      if (!isNil(company.subscription_end_date) && isString(company.subscription_end_date)) {
        subscription.subscriptionEndDate = (moment as any)(company.subscription_end_date).format(getCompanyContextSubscriptionDateFormat());
      }

      if (subscription.state === SubscriptionInfoEnum.EXPIRED_SUBSCRIPTION) {
        const eventdate = (moment as any)(company.subscription_end_date);
        const todaysdate = (moment as any)();
        subscription.numberOfDaysElapsed = todaysdate.diff(eventdate, 'days');
      } else if (subscription.state === SubscriptionInfoEnum.EXPIRING_SUBSCRIPTION) {
        const eventdate = (moment as any)(company.subscription_end_date);
        const todaysdate = (moment as any)();
        subscription.numberOfDaysRemaning = eventdate.diff(todaysdate, 'days');
      }

      subscription.on_sale_end_date = company.on_sale_end_date;
      draft.subscription = subscription;
    });

    if (state !== undefined) {
      this.router.navigate([
        '/',
        USER_ROUTE_PATH,
        TRIAL_TIME_ROUTE_PATH,
        state === 'ok' ? TRIAL_TIME_AUTO_SUBSCRIBE_ROUTE_PATH : TRIAL_TIME_EXPIRED_ROUTE_PATH,
      ]);
    }
  }

  @Action(SubscriptionGoToAction)
  goTo(ctx: StateContext<CompanyContextStateModel>, action: SubscriptionGoToAction) {
    this.http
      .get<{ link: string }>(`${environment.apiUrl}company/redirect/${action.redirectPath}/`)
      .subscribe(
        result => openWindowOrMessageDialog({ ...action.dialogParams, linkUrl: result.link, windowTarget: '_blank' }),
        commonHttpStreamErrorHandler()
      );
  }
}
