import { Pipe, PipeTransform } from '@angular/core';
import { LiveAutoCompleteOptionsConfigModel } from '../model/live-auto-complete-options-config.model';
import { isFunction, isNil } from '@roadrecord/type-guard';

@Pipe({
  name: 'hasPrefix',
})
export class HasPrefixPipe<DATA_MODEL> implements PipeTransform {
  transform(value: DATA_MODEL, options: LiveAutoCompleteOptionsConfigModel<DATA_MODEL>): any {
    if (!isNil(value)) {
      if (isFunction(options.prefixIconFn)) {
        return options.prefixIconFn(value);
      }
      return options.prefixIconFn;
    }
  }
}
