import { NgModule } from '@angular/core';
import { TranslocoDirective } from './transloco.directive';
import { CommonModule } from '@angular/common';
import { GetTranslateKeyDirective } from './get-translate-key.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [TranslocoDirective, GetTranslateKeyDirective],
  exports: [TranslocoDirective, GetTranslateKeyDirective],
})
export class TranslocoUtilsDirectiveModule {}
