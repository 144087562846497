import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { Observable, Subject } from 'rxjs';
import { focusElement2 } from '../../../function/focus-element.function';
import { ViewRemoteErrorsModel } from './model/view-remote-errors.model';

export class BaseViewRemoteFieldErrorPlugin<MODEL, IDTYPE> {
  protected remoteControlsErrors: object;
  protected remoteControlsErrorsLastValue: { [key: string]: any };
  protected readonly _settedRemoteErrors$ = new Subject<void>();

  constructor(
    protected translocoService: TranslocoService,
    @Inject(DOCUMENT) protected document: Document,
    protected messageDialogService: MessageDialogService
  ) {}

  get settedRemoteErrors$(): Observable<void> {
    return this._settedRemoteErrors$.asObservable();
  }

  get formGroupLastValue(): FormGroup {
    console.error('Not override formGroupLastValue getter!');
    return null;
  }

  /**
   * hibak jelzese amit a server kuld
   */
  setRemoteErrors(errors: ViewRemoteErrorsModel): void {
    this.remoteControlsErrors = errors;
    // this.logger.info('remoteErrors')
    if (errors !== undefined) {
      const form = this.formGroupLastValue;
      const notFoundFormControlErrorKeys = [];
      const errorsControlsValues = {};
      Object.entries(errors).forEach(errorEntry => {
        const controlName = errorEntry[0];
        const control = form.get(controlName);
        if (control === null) {
          notFoundFormControlErrorKeys.push(errorEntry);
        } else {
          errorsControlsValues[controlName] = control.value;
        }
      });

      if (Object.keys(errorsControlsValues).length > 0) {
        this.remoteControlsErrorsLastValue = errorsControlsValues;
      } else {
        this.remoteControlsErrorsLastValue = undefined;
      }

      if (notFoundFormControlErrorKeys.length > 0) {
        this.messageDialogService
          .openError({
            id: null,
            title: 'COMMON.GENERAL_ERROR',
            text: 'COMMON.UNKNOWN_ERROR',
            htmlMode: true,
            textParams: {
              errorText: notFoundFormControlErrorKeys.map(errorEntry => `${errorEntry[0]} => ${errorEntry[1]}`).join('<br/>'),
            },
          })
          .afterClosed()
          .subscribe(() => this.focusFirstInvalidElement(errorsControlsValues));
      } else {
        this.focusFirstInvalidElement(errorsControlsValues);
      }
      form.enable();
      if (this._settedRemoteErrors$.observers.length > 0) {
        this._settedRemoteErrors$.next();
      }
      this.formGroupLastValue.updateValueAndValidity();
    }
  }

  private focusFirstInvalidElement(errors: ViewRemoteErrorsModel) {
    const firstInvalidKey = Object.keys(errors).shift();

    // focus first invalid element
    focusElement2(this.document, firstInvalidKey, 200);
  }

  /**
   * control-hoz felveszi a validatort
   */
  setValidatorToControl(name: string, control: AbstractControl): void {
    control.setValidators(Validators.compose([control.validator, this.customRemoteValidatorMessage(name)]));
  }

  /**
   * validator
   * megnezi hogy a tarolt hibak-ban a control nevevel talal-e megfelelo kulcsot, ha igen akkor hibat jelez
   * a customError kulcsot az rr-validation component ismeri! es tudja hogy a hiba erteket irja ki
   */
  customRemoteValidatorMessage(name: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return this.remoteControlsErrors !== undefined &&
        this.remoteControlsErrors[name] !== undefined &&
        (this.remoteControlsErrorsLastValue === undefined || this.remoteControlsErrorsLastValue[name] === control.value)
        ? this.getErrorMessage(name)
        : undefined;
    };
  }

  protected getErrorMessage(name: string): { customError: any } {
    return { customError: this.remoteControlsErrors[name] };
  }

  /**
   * validatorok elhelyezese az control-okon
   *
   * @param form
   */
  protected initValidators(form: FormGroup): void {
    Object.entries(form.controls).forEach(formControlEntry => this.setValidatorToControl(formControlEntry[0], formControlEntry[1]));
  }
}
