/**
 * Executes deepcopy.
 * @param Tp Generic type of target/copied value.
 * @param tgt Target value to be copied.
 */
import { isNil } from '@roadrecord/type-guard';

export function tsDeepcopy<Tp>(tgt: Tp): Tp {
  let cp: Tp;
  if (isNil(tgt) || tgt['__disable_deep_copy__'] !== undefined || tgt['__disable_deep_copy__'] === false) {
    cp = tgt;
  } else if (tgt instanceof Date) {
    cp = new Date((tgt as any).getTime()) as any;
  } else if (Array.isArray(tgt)) {
    cp = [] as any;
    (tgt as any[]).forEach((v, i, arr) => {
      (cp as any).push(v);
    });
    cp = (cp as any).map((n: any) => tsDeepcopy<any>(n));
  } else if (typeof tgt === 'object' && tgt !== {}) {
    cp = { ...(tgt as Object) } as Tp;
    Object.keys(cp).forEach(k => {
      (cp as any)[k] = tsDeepcopy<any>((cp as any)[k]);
    });
  } else {
    cp = tgt;
  }
  return cp;
}
