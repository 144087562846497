import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgIfTypedDirective } from './ng-if-typed.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [NgIfTypedDirective],
  exports: [NgIfTypedDirective],
})
export class NgOverrideModule {}
