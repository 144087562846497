import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DynamicFormModule, RRTwoWaySwitchFieldModule } from '@roadrecord/dynamic-form';
import { FormModule } from '@roadrecord/utils';
import { ReactiveFormsModule } from '@angular/forms';
import { AverageFuelBottomSheetComponent } from './average-fuel-bottom-sheet.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { PreventClickModule } from '@roadrecord/common/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [AverageFuelBottomSheetComponent],
  imports: [
    CommonModule,
    DynamicFormModule,
    FormModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    RRTwoWaySwitchFieldModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslocoModule,
    PreventClickModule,
  ],
  entryComponents: [AverageFuelBottomSheetComponent],
})
export class AverageFuelBottomSheetModule {}
