import { fuelingRangeOpenWindowAction } from '@roadrecord/fueling-range/open-window-action';
import { monthStatusListOpenWindowAction } from '@roadrecord/mileage/common';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadableService } from '@roadrecord/external-packages/ngx-loadable';
import { Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { getRootActionById } from './get-root-action-by-id.function';
import {
  DocumentDataCheckerActionsEnum,
  DocumentDataCheckerActionTypeEnum,
  DocumentDataCheckerActionUiConfig,
  DocumentDataCheckerRouteStateModel,
} from '@roadrecord/document-data-checker-action/model';
import { DOCUMENT_DATA_CHECKER_ACTION_ROUTE_CONFIG_IDS_ENUM } from '@roadrecord/document-data-checker-action/ui';
import { gotoAnonymPartners, PartnerService } from '@roadrecord/partner/common';
import { NoopFunction } from '@roadrecord/common/common';
import { commonHttpStreamErrorHandler } from '@roadrecord/utils';
import { HttpClient } from '@angular/common/http';

export function handleDocumentDataCheckerAction(
  _actions: { action: DocumentDataCheckerActionsEnum }[],
  actionsConfig: DocumentDataCheckerActionUiConfig[],
  router: Router,
  store: Store,
  loadableService: LoadableService,
  injector: Injector,
  matSnackBar: MatSnackBar,
  translocoService: TranslocoService
) {
  const actions = _actions.map(action => action.action);
  const rootAction = getRootActionById(actionsConfig, actions[0]);
  if (rootAction.type === DocumentDataCheckerActionTypeEnum.ROUTE) {
    // route esemenyek kezelese
    if (rootAction.id === DOCUMENT_DATA_CHECKER_ACTION_ROUTE_CONFIG_IDS_ENUM.ANONYM_PARTNERS) {
      // specialis eset kezelese
      // TODO partner service eloallitasa
      gotoAnonymPartners(new PartnerService(injector.get(HttpClient)), router).subscribe(NoopFunction, commonHttpStreamErrorHandler());
    } else {
      router.navigate([rootAction.route], { state: { action: actions[1] } as DocumentDataCheckerRouteStateModel });
    }
  } else if (rootAction.type === DocumentDataCheckerActionTypeEnum.DIRECT_ACTION) {
    switch (rootAction.actions[0].action) {
      case DocumentDataCheckerActionsEnum.FLOATING_WINDOW__FUELING_RANGE:
        fuelingRangeOpenWindowAction(store, loadableService, injector, translocoService, matSnackBar);
        break;
      case DocumentDataCheckerActionsEnum.FLOATING_WINDOW__MILEAGE_LIST:
        monthStatusListOpenWindowAction(store, loadableService, injector, translocoService, matSnackBar);
        break;
    }
  }
}
