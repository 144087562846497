import { TranslocoService } from '@ngneat/transloco';
import { GridColumnModel } from '@roadrecord/grid';
import { UniversalImportConfig } from '@roadrecord/universal-import/common';
import { PartnerAddressFormatterReturnType } from '@roadrecord/partner/model';
import { getPartnerImportListPostcodeAndAddressColumns } from './model/get-partner-import-list-postcode-and-address-column.function';

export const importListColumnConfig: (_this: {
  translocoService: TranslocoService;
  importConfig: UniversalImportConfig<any>;
  partnerAddressFormatter: PartnerAddressFormatterReturnType;
}) => GridColumnModel<any>[] = (_this: {
  translocoService: TranslocoService;
  importConfig: UniversalImportConfig<any>;
  partnerAddressFormatter: PartnerAddressFormatterReturnType;
}) => {
  const translatePrefix = `${_this.importConfig.translatePrefix}.LIST.COLUMNS.`;

  return [
    { name: 'name', displayNameTranslateKey: `${translatePrefix}NAME.TITLE` },
    ...getPartnerImportListPostcodeAndAddressColumns(translatePrefix),
    {
      name: 'comment',
      displayNameTranslateKey: `${translatePrefix}COMMENT.TITLE`,
      cellContentTemplateCallback: (model: any) => _this.translocoService.translate(model.comment, { data: model }),
    },
  ];
};
