import { RRFormWithDynamicFormBottomSheetConfig } from '@roadrecord/utils';
import { Observable, of } from 'rxjs';
import { VehicleHuModel } from '@roadrecord/vehicle/model/hu';
import { VehicleModel } from '@roadrecord/vehicle/model/common';

export function getFormDatas(bottomSheetData: RRFormWithDynamicFormBottomSheetConfig<VehicleModel>): Observable<VehicleModel> {
  const data = bottomSheetData.data as VehicleHuModel;

  return of(({
    fuel_type: data.fuel_type,
    is_basic_norm: data.is_basic_norm,
    vehicle_type: data.vehicle_type,
  } as unknown) as VehicleModel);
}
