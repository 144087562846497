import { DOCUMENT } from '@angular/common';
import { ConstructorProvider } from '@angular/core';
import { PresenterStateController } from '../../presenter-state/presenter-state.controller';
import { ViewSubmitPlugin } from './view-submit.plugin';
import { Actions } from '@ngxs/store';

export const VIEW_SUBMIT_PLUGIN_PROVIDER: ConstructorProvider = {
  provide: ViewSubmitPlugin,
  deps: [PresenterStateController, DOCUMENT, Actions],
};
