<div *ngIf="pdfSrc === undefined; else pdfTpl" @fadeInEnter class="wid100 height100">
  <rr-header-panel
    (printPdf)="onPrintPdf($event)"
    [countError]="countError$ | async"
    [countWarning]="countWarning$ | async"
    [counter]="counter$ | async"
    [currentStepCounter]="currentStepCounter$ | async"
    [run]="run"
    [loading]="runPdfGeneration"
    [loadingProgressBarMode]="'indeterminate'"
    [visiblePrintButton]="visiblePrintButton"
    [hasError]="hasError"
    [printButtonLabel]="
      runPdfGeneration === false
        ? (countWarning$ | async) > 0
          ? 'REPORT_PRINT.HEADER.DEFINITELY_PRINT_BUTTON_LABEL'
          : 'REPORT_PRINT.HEADER.PRINT_BUTTON_LABEL'
        : 'REPORT_PRINT.HEADER.PRINTING_PRINT_BUTTON_LABEL'
    "
    (clickOpenErrorWindow)="onClickOpenErrorWindow()"
  ></rr-header-panel>
  <ng-container *ngIf="checkList$ | async | reportPrintCheckListFilter: (currentFilter$ | async) as filteredList">
    <ng-container *ngIf="filteredList.length > 0">
      <rr-result-panel
        *rrNgIfTyped="currentFilter$ | async as currentFilter"
        [countAll]="countAll$ | async"
        [countError]="countError$ | async"
        [countNotChecked]="countNotChecked$ | async"
        [countOK]="countOK$ | async"
        [countWarning]="countWarning$ | async"
        [currentFilter]="currentFilter"
        [runPdfGeneration]="runPdfGeneration"
        [run]="run"
        [pdfSrc]="pdfSrc"
        [filteredList]="filteredList"
        (clickAction)="onClickAction($event)"
      ></rr-result-panel>
    </ng-container>
  </ng-container>
</div>
<ng-template #pdfTpl>
  <div class="pdf-view-header" [ngClass]="{ loading: loadingInPdfView }">
    <mat-progress-bar *ngIf="loadingInPdfView" mode="indeterminate" color="accent"></mat-progress-bar>
    <span class="title">{{ linkFileName }}</span>
    <div class="button-row">
      <span *ngFor="let config of extraButtonsConfig?.items" [ngClass]="config?.wrapperClass"
        ><button
          type="button"
          [disabled]="loadingInPdfView"
          mat-button
          [matTooltip]="config.tooltip | transloco"
          rrPreventClick
          (click)="onClickExtraButton(config)"
          ><mat-icon [svgIcon]="config.svgIcon"></mat-icon></button
        ><ng-container *ngIf="config?.extraStyles?.length > 0"
          ><span [innerHTML]="'<style>' + config.extraStyles + '</style>' | sanitizeHtml"></span></ng-container></span
      ><span>
        <button
          type="button"
          [disabled]="loadingInPdfView"
          mat-icon-button
          [matTooltip]="'REPORT_PRINT.PDF.TOOLTIP.PDF_DOWNLOAD' | transloco"
          rrPreventClick
          (click)="onClickPdfDownload()"
        >
          <mat-icon svgIcon="file-pdf-box"></mat-icon></button></span
      ><span
        ><button
          type="button"
          [disabled]="loadingInPdfView"
          mat-icon-button
          [matTooltip]="'REPORT_PRINT.PDF.TOOLTIP.EXCEL_DOWNLOAD' | transloco"
          rrPreventClick
          (click)="onClickExcelOrCsvDownload(true)"
        >
          <mat-icon svgIcon="file-excel"></mat-icon></button></span
      ><span
        ><button
          type="button"
          [disabled]="loadingInPdfView"
          mat-icon-button
          [matTooltip]="'REPORT_PRINT.PDF.TOOLTIP.CSV_DOWNLOAD' | transloco"
          rrPreventClick
          (click)="onClickExcelOrCsvDownload(false)"
        >
          <mat-icon svgIcon="file-delimited"></mat-icon></button></span
      ><span>
        <button
          type="button"
          [disabled]="loadingInPdfView"
          mat-icon-button
          [matTooltip]="'REPORT_PRINT.PDF.TOOLTIP.PRINT' | transloco"
          rrPreventClick
          (click)="onClickPrint()"
        >
          <mat-icon svgIcon="printer"></mat-icon></button
      ></span>
    </div>
  </div>

  <div class="pdf-view">
    <!--    <ng2-pdfjs-viewer [pdfSrc]="pdfSrc"></ng2-pdfjs-viewer>-->
    <pdf-viewer
      [src]="pdfSrc"
      [render-text]="true"
      [autoresize]="true"
      [original-size]="false"
      [show-all]="true"
      class="wid100 height100"
      [@fadeInEnter]
    ></pdf-viewer>
  </div>
</ng-template>
