import { Constructor, RRFormGroup } from '@roadrecord/common/common';
import { AfterViewInit, Directive, Injector, OnInit } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslocoService } from '@ngneat/transloco';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { ManualViewRemoteFieldErrorPlugin } from '../controller/plugin/view-remote-field-error/manual-view-remote-field-error.plugin';
import { HttpListResponseModel } from '../../http/model/http-list-response.model';
import { AbstractEntityService } from '../../entity/service/abstract-entity.service';
import { FieldHttpError } from '../../http/exception/field-http-error';
import { Observable } from 'rxjs';
import { isFunction, isNil } from '@roadrecord/type-guard';

/**
 * mixin-elt osztalyban kell hasznalni
 * tovabba ezeknek leteznie kell a mixinelt osztalyban
 */
export interface AbstractRRFormWithDynamicForm<MODEL> extends OnInit, AfterViewInit {
  readonly viewRemoteFieldErrorPlugin: ManualViewRemoteFieldErrorPlugin<any, any>;
  readonly form: RRFormGroup;
  readonly formlyFields: FormlyFieldConfig[];
  readonly formlyData: Partial<MODEL>;

  setFormFields(): FormlyFieldConfig[];

  onClickSubmit(): void;

  onSubmit(formValue: MODEL): void;

  handleFormControlsServerError(responseError: unknown): void;

  getFormDatas?(): Observable<Partial<MODEL>>;
}

export type AbstractRRFormWithDynamicFormCtor<MODEL> = Constructor<AbstractRRFormWithDynamicForm<MODEL>>;

/**
 * aki extendalja majd a mixin-t annak ezeket super-ben at kell adnia
 */
export interface AbstractRRFormWithDynamicFormConstructorParams<MODEL> {
  readonly injector: Injector;
  readonly document: Document;
  readonly translocoService: TranslocoService;
  readonly messageDialogService: MessageDialogService;
  readonly entityService: AbstractEntityService<HttpListResponseModel<MODEL>, MODEL>;
}

/**
 * Ha valamilyen mezo hianyara barmilyen entitasra, fel akarunk dobni egy notifit
 * es utana bottom sheet-ben lehet modositani
 *
 * kotelezo provide:
 * {
      provide: DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION,
      useValue: true,
   }
 *
 * @param _base
 */
export function mixinAbstractRRFormWithDynamicFormWithGenericType<MODEL>(_base: any) {
  return (function mixinAbstractRRFormWithDynamicForm<T extends Constructor<AbstractRRFormWithDynamicFormConstructorParams<MODEL>>>(
    base: T
  ): AbstractRRFormWithDynamicFormCtor<MODEL> & T {
    @Directive()
    abstract class Mixin
      extends ((base as unknown) as Constructor<AbstractRRFormWithDynamicFormConstructorParams<MODEL>>)
      implements OnInit, AfterViewInit {
      readonly form = new RRFormGroup({});

      constructor(...args: any[]) {
        super(...args);
      }

      private _viewRemoteFieldErrorPlugin: ManualViewRemoteFieldErrorPlugin<any, any>;

      get viewRemoteFieldErrorPlugin() {
        return this._viewRemoteFieldErrorPlugin;
      }

      private _formlyFields: FormlyFieldConfig[];

      get formlyFields() {
        return this._formlyFields;
      }

      private _formlyData: Partial<MODEL>;

      get formlyData(): Partial<MODEL> {
        return this._formlyData;
      }

      ngOnInit() {
        this._formlyFields = this.setFormFields();
        if (isFunction(this.getFormDatas)) {
          this.getFormDatas().subscribe(data => {
            if (!isNil(data)) {
              this._formlyData = data;
            }
          });
        }
      }

      handleFormControlsServerError(responseError: unknown): void {
        if (!(responseError instanceof FieldHttpError)) {
          return;
        }
        const errors = {};
        Object.entries(responseError.error.errors).forEach(entry => (errors[entry[0]] = entry[1][0]));
        this.viewRemoteFieldErrorPlugin.setRemoteErrors(errors);
      }

      ngAfterViewInit() {
        this.setManualViewRemoteField();
      }

      setManualViewRemoteField() {
        this._viewRemoteFieldErrorPlugin = new ManualViewRemoteFieldErrorPlugin(
          this.translocoService,
          this.document,
          this.messageDialogService
        );
        this.viewRemoteFieldErrorPlugin.formGroupLastValue = this.form;
      }

      onClickSubmit() {
        this.form.submitted = true;
        if (this.form.valid) {
          const formValue = this.form.value;
          this.form.disable();
          this.onSubmit(formValue);
          // this.data.onSubmit(formValue).subscribe(
          //   () => this.bottomSheetRef.dismiss(),
          //   error => {
          //     maybeHandleHttpError(error);
          //     this.form.enable();
          //     this.handleFormControlsServerError(error);
          //   }
          // );
        }
      }

      abstract onSubmit(formValue: MODEL);

      abstract setFormFields(): FormlyFieldConfig[];

      /**
       * optional
       */
      abstract getFormDatas(): Observable<Partial<MODEL>>;
    }

    return (Mixin as unknown) as AbstractRRFormWithDynamicFormCtor<MODEL> & T;
  })(_base);
}
