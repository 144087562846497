import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, Actions, createSelector, NgxsOnInit, ofActionSuccessful, Selector, State, StateContext, Store } from '@ngxs/store';
import { isNil, isString } from '@roadrecord/type-guard';
import produce from 'immer';
import { asapScheduler } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { FragmentHideDialogAction } from './action/fragment-hide-dialog.action';
import { FragmentLoadDialogAction } from './action/fragment-load-dialog.action';
import { FragmentRemoveDialogAction } from './action/fragment-remove-dialog.action';
import { FragmentShowDialogAction } from './action/fragment-show-dialog.action';
import { FragmentDialogTypeEnum } from './model/fragment-dialog-type.enum';
import { FragmentStateInterface } from './model/fragment-state.interface';
import { FragmentStateModel } from './model/fragment-state.model';
import { Injectable } from '@angular/core';

@State<FragmentStateModel>({
  name: 'urlFragmentController',
  defaults: { opened: {}, metadata: {}, version: 3 },
})
@Injectable()
export class FragmentState implements NgxsOnInit {
  constructor(route: ActivatedRoute, private router: Router, private store: Store, private location: Location, actions: Actions) {
    actions
      .pipe(
        ofActionSuccessful({ type: '[APP] Loaded' }),
        take(1),
        switchMap(() =>
          this.store
            .select(states => {
              const appLoaded = states.applicationSettings.appLoaded;
              return !isNil(appLoaded) ? appLoaded : false;
            })
            .pipe(
              filter(loaded => loaded === true),
              take(1),
              switchMap(() => route.fragment)
            )
        )
      )
      .subscribe(fragment => {
        if (!isString(fragment)) {
          fragment = '';
        }
        const openedState = this.store.selectSnapshot(FragmentState.opened);
        if (fragment.length > 0 || (openedState !== undefined && Object.keys(openedState).length > 0)) {
          asapScheduler.schedule(() => store.dispatch(new FragmentLoadDialogAction(fragment)));
        }
      });
  }

  @Selector()
  static opened(state: FragmentStateModel): FragmentStateInterface {
    return state.opened;
  }

  static openedByType(type: FragmentDialogTypeEnum | string): any {
    return createSelector([FragmentState], (state: FragmentStateModel): any => {
      return state.opened[type];
    });
  }

  static metadataByType(type: FragmentDialogTypeEnum | string): any {
    return createSelector([FragmentState], (state: FragmentStateModel): any => {
      return state.metadata[type];
    });
  }

  /**
   * Tobb tipust lehet megadni es az elso ami ad eredmenyt azt adjuk vissza
   */
  static metadataByTypes(type: (FragmentDialogTypeEnum | string)[]): any {
    return createSelector([FragmentState], (state: FragmentStateModel): any => {
      return type.map(t => state.metadata[t]).find(metadata => !isNil(metadata));
    });
  }

  @Action(FragmentLoadDialogAction)
  fragmentLoadDialog({ setState, getState, dispatch }: StateContext<FragmentStateModel>, action: FragmentLoadDialogAction): any {
    return setState(
      produce(getState(), draft => {
        const oldKeys = Object.keys(draft.opened);
        if (action.dialogType) {
          // sima command
          const newDataState = {};
          const newMetaState = {};
          action.dialogType
            .split('|||')
            .map(type => type.split('='))
            .forEach(type => {
              let data: { data: any; meta: any };
              try {
                data = JSON.parse(type[1]);
              } catch (e) {}
              newDataState[type[0]] = data === undefined || data.data === undefined ? {} : data.data;
              newMetaState[type[0]] = data === undefined || data.meta === undefined ? {} : data.meta;
            });

          draft.opened = newDataState;
          draft.metadata = newMetaState;
        } else if (isString(action.dialogType) && action.dialogType.length === 0) {
          // reset
          draft.opened = {};
        }

        /**
         * vizsgaljuk hogyha a regi kulcsok kozott van olyan ami az uj kulcsok kozott nincs jelen akkor a ki kell valtani a tipussal
         * a FragmentRemoveDialogAction action-t
         */
        const newKeys = Object.keys(draft.opened);
        const removeActions = [];
        // const hideActions = [];
        oldKeys.forEach(key => {
          if (newKeys.indexOf(key) === -1) {
            // hideActions.push(new FragmentHideDialogAction(key));
            removeActions.push(new FragmentRemoveDialogAction(key));
          }
        });
        if (removeActions.length > 0) {
          asapScheduler.schedule(() => dispatch(removeActions));
        }
      })
    );
  }

  @Action(FragmentRemoveDialogAction)
  fragmentRemoveDialog({ setState, getState }: StateContext<FragmentStateModel>, action: FragmentRemoveDialogAction): any {
    return setState(
      produce(getState(), draft => {
        if (action.dialogType) {
          delete draft.opened[action.dialogType];
          if (!isNil(draft.metadata[action.dialogType])) {
            delete draft.metadata[action.dialogType];
          }
        }
      })
    );
  }

  @Action(FragmentShowDialogAction)
  fragmentShowDialog({ getState, dispatch, setState }: StateContext<FragmentStateModel>, action: FragmentShowDialogAction<any>): any {
    const state = getState();
    if (Object.keys(state.opened).indexOf(action.dialogType) === -1) {
      if (!isNil(action.cmpData)) {
        setState(
          produce(state, draft => {
            draft.metadata[action.dialogType] = action.cmpData;
          })
        );
      }

      const fragment = this.generateFragments(state.opened);
      const urlData: { data?: string; meta?: object } = {};
      if (action.data !== undefined) {
        urlData.data = action.data;
      }
      if (action.cmpData !== undefined) {
        urlData.meta = action.cmpData;
      }
      fragment.push(`${action.dialogType}${Object.keys(urlData).length > 0 ? `=${JSON.stringify(urlData)}` : ``}`);
      asapScheduler.schedule(() => this.router.navigate([], { fragment: fragment.join('|||') }));
    }
  }

  /**
   * fragmenteket generalja data-val egyutt
   */
  private generateFragments(opened: object): string[] {
    return Object.entries(opened).map(entry => `${entry[0]}${entry[1] !== undefined ? `=${JSON.stringify(entry[1])}` : ''}`);
  }

  @Action(FragmentHideDialogAction)
  fragmentHideDialog({ getState, setState }: StateContext<FragmentStateModel>, action: FragmentHideDialogAction): any {
    if (Object.keys(getState().opened).indexOf(action.dialogType) > -1 || action.locationBack === true) {
      this.location.back();
    }
  }

  ngxsOnInit({ getState, setState }: StateContext<FragmentStateModel>): void | any {
    return setState(
      produce(getState(), draft => {
        if (draft.opened === undefined) {
          draft.opened = {};
        }
        if (draft.metadata === undefined) {
          draft.metadata = {};
        }
        if (draft.version === undefined) {
          draft.version = 1;
        }
      })
    );
  }
}
