import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FieldWrapper, FormlyConfig, FormlyFieldConfig, ɵdefineHiddenProp as defineHiddenProp } from '@ngx-formly/core';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { isNil } from '@roadrecord/type-guard';
import { Subject } from 'rxjs';
import { FieldType } from '@ngx-formly/material';

interface MatFormlyFieldConfig extends FormlyFieldConfig {
  _matprefix: TemplateRef<any>;
  _matsuffix: TemplateRef<any>;
  __formField__: MaterialWrapperFormField;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'rr-wrapper-mat-form-field',
  template: `
    <!-- fix https://github.com/angular/material2/pull/7083 by setting width to 100% -->
    <mat-form-field
      [hideRequiredMarker]="true"
      [floatLabel]="to.floatLabel"
      [appearance]="to.appearance"
      [color]="to.color"
      [style.width]="'100%'"
      [ngClass]="field.templateOptions?.matFormFieldClass"
      [matTooltip]="field.templateOptions?.tooltip"
      class="maybe-has-tooltip"
    >
      <ng-container #fieldComponent></ng-container>
      <mat-label *ngIf="to.label && to.hideLabel !== true">
        {{ to.label }}
        <span *ngIf="to.required && to.hideRequiredMarker !== true" class="mat-form-field-required-marker">*</span>
      </mat-label>

      <ng-container matPrefix>
        <ng-container *ngIf="to.prefix | isString; else prefixTemplateRef">{{ to.prefix }}</ng-container>
        <ng-template #prefixTemplateRef>
          <ng-container *ngTemplateOutlet="to.prefix ? to.prefix : formlyField._matprefix"></ng-container>
        </ng-template>
      </ng-container>

      <ng-container matSuffix>
        <ng-container *ngIf="to.suffix | isString; else suffixTemplateRef">{{ to.suffix }}</ng-container>
        <ng-template #suffixTemplateRef>
          <ng-container *ngTemplateOutlet="to.suffix ? to.suffix : formlyField._matsuffix"></ng-container>
        </ng-template>
      </ng-container>

      <!-- fix https://github.com/angular/material2/issues/7737 by setting id to null  -->
      <mat-error [id]="null"
        ><rr-validation-messages [errors]="field.formControl?.errors" [messages]="validationMessages"></rr-validation-messages
      ></mat-error>
      <!-- fix https://github.com/angular/material2/issues/7737 by setting id to null  -->
      <mat-hint *ngIf="!to?.hideDescription && to.description?.length > 0" [id]="null">{{ to.description | transloco }}</mat-hint>
    </mat-form-field>
  `,
  styleUrls: ['./material-wrapper-form.field.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: MaterialWrapperFormField }],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaterialWrapperFormField
  extends FieldWrapper<MatFormlyFieldConfig>
  implements OnInit, OnDestroy, MatFormFieldControl<any>, AfterViewInit, AfterContentChecked {
  // TODO: remove `any`, once dropping angular `V7` support.
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent!: ViewContainerRef;

  // TODO: remove `any`, once dropping angular `V7` support.
  @ViewChild(MatFormField, { static: true }) formField!: MatFormField;

  stateChanges = new Subject<void>();
  _errorState = false;
  private initialGapCalculated = false;

  constructor(
    private config: FormlyConfig,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private focusMonitor: FocusMonitor
  ) {
    super();
  }

  ngOnInit() {
    this.formField._control = this;
    defineHiddenProp(this.field, '__formField__', this.formField);

    const ref = this.config.resolveFieldTypeRef(this.formlyField);
    if (ref && !(ref.instance instanceof FieldType)) {
      console.warn(`Component '${ref.componentType.name}' must extend 'FieldType' from '@ngx-formly/material/form-field'.`);
    }

    // fix for https://github.com/angular/material2/issues/11437
    if (
      this.formlyField.hide &&
      !isNil(this.formlyField.templateOptions['appearance']) &&
      this.formlyField.templateOptions.appearance === 'outline'
    ) {
      this.initialGapCalculated = true;
    }

    // this.focusMonitor.monitor(this.elementRef, true).subscribe(origin => {
    //   if (!origin && this.field.focus) {
    //     this.field.focus = false;
    //   }
    //
    //   this.stateChanges.next();
    // });
  }

  ngAfterContentChecked() {
    if (!this.initialGapCalculated || this.formlyField.hide) {
      return;
    }

    this.formField.updateOutlineGap();
    this.initialGapCalculated = true;
  }

  ngAfterViewInit() {
    // temporary fix for https://github.com/angular/material2/issues/7891
    if (this.formField.appearance !== 'outline' && this.to.hideFieldUnderline === true) {
      const underlineElement = this.formField._elementRef.nativeElement.querySelector('.mat-form-field-underline');
      if (!isNil(underlineElement)) {
        this.renderer.removeChild(underlineElement.parentNode, underlineElement);
      }
    }
  }

  ngOnDestroy() {
    delete this.formlyField.__formField__;
    this.stateChanges.complete();
    // this.focusMonitor.stopMonitoring(this.elementRef);
  }

  setDescribedByIds(ids: string[]): void {}
  onContainerClick(event: MouseEvent): void {
    this.formlyField.focus = true;
    this.stateChanges.next();
  }

  get errorState() {
    const showError = !isNil(this.options) && !isNil(this.options.showError) ? this.options.showError(this) : null;
    if (showError !== this._errorState) {
      this._errorState = showError;
      this.stateChanges.next();
    }

    return showError;
  }
  get controlType() {
    return this.to.type;
  }
  get focused() {
    return !!this.formlyField.focus && !this.disabled;
  }
  get disabled() {
    return !!this.to.disabled;
  }
  get required() {
    return !!this.to.required;
  }
  get placeholder() {
    return this.to.placeholder || '';
  }
  get shouldPlaceholderFloat() {
    return this.shouldLabelFloat;
  }
  get value() {
    return this.formControl.value;
  }
  get ngControl() {
    return this.formControl as any;
  }
  get empty() {
    return !this.formControl.value;
  }
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get formlyField() {
    return this.field as MatFormlyFieldConfig;
  }

  get validationMessages() {
    return this.to.validationMessages;
  }
}
