import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { isFunction, isNil } from '@roadrecord/type-guard';
import { focusElement2 } from '../../../function/focus-element.function';
import { PresenterStateController, VIEW_COMPONENT } from '../../presenter-state/presenter-state.controller';
import { SaveModel } from '../presenter-save/model/save.model';
import { HasViewSubmitPlugin } from './has-view-submit-plugin.interface';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { CheckModifierSubmitAction } from '../../../check-modify/state/action/check-modifier-submit.action';
import { filter } from 'rxjs/operators';

let id = 0;
/**
 * ez a controller a data-form submit feladatat latja el:
 * validacio:
 *          ha valid akkor jelzes a hivonak,
 *          ha invalid akkor az osszes mezon hiba kijelzes + first invalid mezore focus
 */
@Injectable()
export class ViewSubmitPlugin<MODEL, IDTYPE> implements OnDestroy {
  readonly id = id++;
  private currentScope: HasViewSubmitPlugin<MODEL>;
  private presenterSaveSubmitActionSubscription: Subscription;

  constructor(
    private presenterStateController: PresenterStateController<MODEL, IDTYPE>,
    @Inject(DOCUMENT) private document: Document,
    private readonly actions$: Actions
  ) {
    this.registerPresenter();
    this.handleSaveSubmitAction();
  }

  /**
   * Amikor programatically akarjuk a submit esemenyt kivaltani ugy hogy nincs meg a cmp referencia kozvetlenul
   */
  private handleSaveSubmitAction() {
    this.presenterSaveSubmitActionSubscription = this.actions$
      .pipe(
        ofActionSuccessful(CheckModifierSubmitAction),
        filter((action: CheckModifierSubmitAction) => action.viewSubmitPluginId === this.id)
      )
      .subscribe((action: CheckModifierSubmitAction) => action.dataFormCmpRef.onSubmit());
  }

  // submitForm(reset = false, valid?: () => void, invalid?: () => void): void {
  submitForm({
    reset = false,
    redirect = true,
    valid,
    invalid,
  }: {
    reset: boolean;
    redirect?: boolean;
    valid?: () => void;
    invalid?: () => void;
  }): void {
    const form = this.presenterStateController.formGroupLastValue;
    // jelzes a validatornak hogy kijelezheti az osszes mezon ha van hiba
    form.submitted = true;
    if (form.valid) {
      const formValue = !isNil(this.currentScope.getFormData) ? this.currentScope.getFormData() : form.value;
      const submit = this.getSubmitEmitter();
      // jelzunk a hivonak
      form.disable({ /*emitEvent: false,*/ onlySelf: true });
      submit.emit(new SaveModel({ reset, model: formValue, redirect }));

      if (valid !== undefined) {
        valid();
      }
    } else {
      // megkeressuk az elso invalid kulcsot a form-ban
      const firstInvalidKey = Object.entries(form.controls).find(entry => entry[1].invalid && entry[1].enabled);
      // focus first invalid element
      focusElement2(this.document, firstInvalidKey[0]);

      if (invalid !== undefined) {
        invalid();
      }
    }
  }

  private getSubmitEmitter(): EventEmitter<SaveModel<MODEL>> {
    return this.currentScope.submitForm;
  }

  private registerPresenter(): void {
    // tslint:disable:no-invalid-this
    const view: any = this.presenterStateController.get(VIEW_COMPONENT);
    // TODO check submit emitter (constructor kell cserelni)
    // https://stackoverflow.com/a/44520175
    // https://netbasal.com/create-and-test-decorators-in-javascript-85e8d5cf879c
    let onSubmitOriginalMethod;
    let onSubmitResetOriginalMethod;
    let onSubmitWithoutRedirectMethod;
    if (view.prototype === undefined) {
      onSubmitOriginalMethod = !isNil(view.ORIGINAL_ONSUBMIT) ? view.ORIGINAL_ONSUBMIT : view.onSubmit;
      if (view.ORIGINAL_ONSUBMIT === undefined) {
        view.ORIGINAL_ONSUBMIT = onSubmitOriginalMethod;
      }

      onSubmitResetOriginalMethod = !isNil(view.ORIGINAL_ONSUBMIT_RESET) ? view.ORIGINAL_ONSUBMIT_RESET : view.onSubmitReset;
      if (view.ORIGINAL_ONSUBMIT_RESET === undefined) {
        view.ORIGINAL_ONSUBMIT_RESET = onSubmitResetOriginalMethod;
      }

      onSubmitWithoutRedirectMethod = !isNil(view.ORIGINAL_ONSUBMIT_WITHOUT_REDIRECT)
        ? view.ORIGINAL_ONSUBMIT_WITHOUT_REDIRECT
        : view.onSubmitWithoutRedirect;
      if (view.ORIGINAL_ONSUBMIT_WITHOUT_REDIRECT === undefined) {
        view.ORIGINAL_ONSUBMIT_WITHOUT_REDIRECT = onSubmitWithoutRedirectMethod;
      }
    } else {
      onSubmitOriginalMethod = !isNil(view.prototype.ORIGINAL_ONSUBMIT) ? view.prototype.ORIGINAL_ONSUBMIT : view.prototype.onSubmit;
      if (view.prototype.ORIGINAL_ONSUBMIT === undefined) {
        view.prototype.ORIGINAL_ONSUBMIT = onSubmitOriginalMethod;
      }

      onSubmitResetOriginalMethod = !isNil(view.prototype.ORIGINAL_ONSUBMIT_RESET)
        ? view.prototype.ORIGINAL_ONSUBMIT_RESET
        : view.prototype.onSubmitReset;
      if (view.prototype.ORIGINAL_ONSUBMIT_RESET === undefined) {
        view.prototype.ORIGINAL_ONSUBMIT_RESET = onSubmitResetOriginalMethod;
      }

      onSubmitWithoutRedirectMethod = !isNil(view.prototype.ORIGINAL_ONSUBMIT_WITHOUT_REDIRECT)
        ? view.prototype.ORIGINAL_ONSUBMIT_WITHOUT_REDIRECT
        : view.prototype.onSubmitWithoutRedirect;
      if (view.prototype.ORIGINAL_ONSUBMIT_WITHOUT_REDIRECT === undefined) {
        view.prototype.ORIGINAL_ONSUBMIT_WITHOUT_REDIRECT = onSubmitWithoutRedirectMethod;
      }
    }

    if (onSubmitOriginalMethod === undefined) {
      throw new Error('Not found submit emitter or onSubmit method');
    }
    if (onSubmitResetOriginalMethod === undefined) {
      throw new Error('Not found submit emitter or onSubmitReset method');
    }

    const __this = this;
    const newOnSubmit = function (): void {
      onSubmitOriginalMethod.call(this, false);
      // TODO model check?
      __this.currentScope = this;
      __this.submitForm({ reset: false });
    };

    const newOnSubmitReset = function (): void {
      onSubmitResetOriginalMethod.call(this, true);
      // TODO model check?
      __this.currentScope = this;
      __this.submitForm({ reset: true });
    };

    const newOnSubmitWithoutRedirectMethod = function (): void {
      if (isFunction(onSubmitWithoutRedirectMethod)) {
        onSubmitWithoutRedirectMethod.call(this, true);
      }
      __this.currentScope = this;
      __this.submitForm({ reset: false, redirect: false });
    };

    if (view.prototype === undefined) {
      view.onSubmit = newOnSubmit;
      view.onSubmitReset = newOnSubmitReset;
      view.onSubmitWithoutRedirect = newOnSubmitWithoutRedirectMethod;
    } else {
      view.prototype.onSubmit = newOnSubmit;
      view.prototype.onSubmitReset = newOnSubmitReset;
      view.prototype.onSubmitWithoutRedirect = newOnSubmitWithoutRedirectMethod;
    }
  }

  ngOnDestroy() {
    if (!isNil(this.presenterSaveSubmitActionSubscription)) {
      this.presenterSaveSubmitActionSubscription.unsubscribe();
    }
  }
}
