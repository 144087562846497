<label>{{ to.fieldLabel | transloco }}: </label>
<rr-two-way-switch-form-control
  [formControl]="formControl"
  [icon_name_false]="to.iconNameFalse"
  [icon_name_true]="to.iconNameTrue"
  [matTooltip]="undefined"
  [option_false]="to.optionFalse"
  [option_false_tooltip]="to.optionFalseTooltip"
  [option_true]="to.optionTrue"
  [option_true_tooltip]="to.optionTrueTooltip"
  [inputAttrName]="key"
></rr-two-way-switch-form-control>
