import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsStringPipe } from './is-string.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [IsStringPipe],
  exports: [IsStringPipe],
})
export class IsStringPipeModule {}
