import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { laTripReasonOptionsConfig, TripReasonService } from '@roadrecord/trip-reason/common';

@Component({
  selector: 'rr-bulk-edit-trip-reason-bottom-sheet',
  templateUrl: './bulk-edit-trip-reason-bottom-sheet.component.html',
  styleUrls: ['./bulk-edit-trip-reason-bottom-sheet.component.scss'],
  providers: [TripReasonService],
})
export class BulkEditTripReasonBottomSheetComponent {
  tripReasonControl = new FormControl(undefined, Validators.required);
  laTripReasonOptionsConfig = laTripReasonOptionsConfig;

  constructor(readonly tripReasonService: TripReasonService) {}
}
