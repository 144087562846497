import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { NgxsModule } from '@ngxs/store';
import { VehicleAccountState } from './state/vehicle-account.state';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { GridModule } from '@roadrecord/grid';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CollectedDataBottomSheetComponent } from './collected-data-bottom-sheet/collected-data-bottom-sheet.component';
import { MatIconModule } from '@angular/material/icon';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

@NgModule({
  declarations: [ListComponent, CollectedDataBottomSheetComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([VehicleAccountState]),
    MatSnackBarModule,
    GridModule,
    TranslocoModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatBottomSheetModule,
  ],
  exports: [ListComponent],
  entryComponents: [CollectedDataBottomSheetComponent],
})
export class VehicleAccountModule {}
