export class HasVehicleContextOptionsModel {
  readonly callbacks: any[];

  constructor(data: any[] | HasVehicleContextOptionsModel) {
    if (Array.isArray(data)) {
      this.callbacks = data;
    } else {
      Object.assign(this, data);
    }
  }
}
