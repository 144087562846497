import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CompanyContextState, goToOrderInANewTab } from '@roadrecord/company-context/common';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { TranslocoService } from '@ngneat/transloco';
import { mixinWithCounterButton, WithCounterButton, WithCounterButtonCtor } from '@roadrecord/common/common';
import { UntilDestroy } from '@ngneat/until-destroy';

export class AutoSubscribeBase {
  constructor(readonly translateKeyPrefix: string, readonly translocoService: TranslocoService, readonly cdr: ChangeDetectorRef) {}
}

export const _AutoSubscribeBase: WithCounterButtonCtor & typeof AutoSubscribeBase = mixinWithCounterButton(AutoSubscribeBase);

@UntilDestroy()
@Component({
  selector: 'rr-auto-subscribe',
  templateUrl: './auto-subscribe.component.html',
  styleUrls: ['./auto-subscribe.component.scss'],
})
export class AutoSubscribeComponent extends _AutoSubscribeBase implements WithCounterButton, OnInit {
  constructor(private http: HttpClient, translocoService: TranslocoService, private store: Store, cdr: ChangeDetectorRef) {
    super('USER.TRIAL_TIME.AUTO_SUBSCRIBE.', translocoService, cdr);
    this.counterLimit = 10;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onClickSelectPackage() {
    goToOrderInANewTab(this.store.selectSnapshot(CompanyContextState.subscriptionState), this.http, this.translocoService);
  }

  onStartApp() {
    window.location.replace('/');
  }
}
