import { InjectionToken, Injector } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { GridColumnModel, NotEntitiesBackgroundImageType } from '@roadrecord/grid';
import { UniversalUploadSourceTypes } from './universal-upload-source-types';
import { PartnerAddressFormatterReturnType } from '@roadrecord/partner/model';
import { Observable } from 'rxjs';
import { UniversalImportControllerType } from '@roadrecord/worker/universal-import';
import { Store } from '@ngxs/store';

export type UniversalImportFileType = 'EXCEL' | 'CSV' | 'JSON' | 'ZIP';

export interface UniversalImportButtonConfig<TYPE_ENUM> {
  type: TYPE_ENUM;
  acceptedFileTypes: UniversalImportFileType[];
  importSampleUrl: (() => string) | null;
  maxFileSize: number;
  controllerType: UniversalImportControllerType;
}

export interface UniversalImportButton<TYPE_ENUM> {
  logoUrl: string;
  translateKey: string;
  config: UniversalImportButtonConfig<TYPE_ENUM>;
  /**
   * Default értéke false
   */
  defaultSelected?: boolean;
}

export interface UniversalImportConfig<TYPE_ENUM> {
  hooks: {
    listRowDblClick?: (injector: Injector, event: unknown) => Observable<unknown>;
  };
  importButtons: UniversalImportButton<TYPE_ENUM>[];
  listColumnConfig: (_this: {
    translocoService: TranslocoService;
    importConfig: UniversalImportConfig<any>;
    partnerAddressFormatter: PartnerAddressFormatterReturnType;
  }) => GridColumnModel<any>[];
  translatePrefix: string;
  /**
   * import lista route
   */
  importListRoute: string;
  /**
   * Abban az esetben van ertelme amikor van az import lista elott meg egy layout, pl finalization of routes calendar
   */
  importMainRoute?: string;
  uploadSourceTypes: UniversalUploadSourceTypes<TYPE_ENUM>[];
  gaCategory: string;
  // Google TagManager-hez használt id attribútum prefix
  gtmPrefix: string;
  getHeaderRowIndex: (sourceType: TYPE_ENUM) => number;
  removeFirstRowsNumber: (sourceType: TYPE_ENUM) => 0 | 1;
  hasFabBack: boolean;
  gridFilterLabel?: string;
  gridFilterPlaceholder?: string;
  gridFilterHint?: string;
  gridNotEntitiesBackgroundImage?: NotEntitiesBackgroundImageType;
  beforeRouteUploadLayout?: (store: Store) => void;
}

export const UNIVERSAL_IMPORT_CONFIG = new InjectionToken<UniversalImportConfig<any>>('UNIVERSAL_IMPORT_CONFIG');
