<ng-container *ngFor="let positionType of allPositionType">
  <div
    *ngIf="allNotification[positionType[0] + '_' + positionType[1]].length > 0"
    class="simple-notification-wrapper"
    [ngClass]="positionType"
  >
    <rr-simple-notification
      *ngFor="let a of allNotification[positionType[0] + '_' + positionType[1]]; let i = index"
      [autoId]="a.autoId"
      [item]="a"
      [timeOut]="timeOut"
      [clickToClose]="clickToClose"
      [clickIconToClose]="clickIconToClose"
      [maxLength]="maxLength"
      [showProgressBar]="showProgressBar"
      [pauseOnHover]="pauseOnHover"
      [theClass]="theClass"
      [rtl]="rtl"
      [animate]="animate"
      [position]="i"
    >
    </rr-simple-notification>
  </div>
</ng-container>
