import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tripReasonIcon',
})
export class TripReasonIconPipe implements PipeTransform {
  transform(event: any): string {
    if (event.trip_reason.is_fueling_station === true) {
      return 'gas-station';
    } else if (event.trip_reason.is_private === true) {
      return 'human-handsdown';
    }
    return 'briefcase';
  }
}
