<ng-container *ngIf="viewType">
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    class="rr-calendar-header-row"
    *ngIf="replaceHeaderTextTemplateToFullCustomReference; else generalHeader"
  >
    <ng-container *ngTemplateOutlet="headerTextTemplate"></ng-container>
  </div>
  <ng-template #generalHeader>
    <div fxLayout="row" fxLayoutAlign="end center" class="rr-calendar-header-row" *ngIf="hasListViewButton">
      <span *ngIf="!headerTextTemplate; else headerTextTemplateElem">{{ headerText }}</span>
      <ng-template #headerTextTemplateElem> <ng-container *ngTemplateOutlet="headerTextTemplate"></ng-container> </ng-template>
      <span class="flex-spacer"></span>
      <ng-container *ngTemplateOutlet="beforeTopRightButtonsTemplateRef"></ng-container>

      <ng-container *ngIf="showViewSelector">
        <mat-button-toggle-group
          #calendarViewGroup="matButtonToggleGroup"
          [value]="selectedCalendarViewType"
          (change)="changeCalenderViewToggleGroup(calendarViewGroup.value)"
          style="margin-right: 10px; margin-top: 3px"
        >
          <mat-button-toggle value="{{ RRCalendarViewType.LIST }}" matTooltip="{{ 'CALENDAR.HEADER.TOOLTIP.BUTTON.LIST' | transloco }}">
            <mat-icon>list</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle
            value="{{ RRCalendarViewType.CALENDAR }}"
            matTooltip="{{ 'CALENDAR.HEADER.TOOLTIP.BUTTON.CALENDAR' | transloco }}"
          >
            <mat-icon>calendar_today</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </ng-container>

      <ng-container *ngIf="headerFixTripImportActions?.length > 0">
        <rr-split-button
          [color]="headerFixTripImportActionButtonColor"
          [buttonStyle]="headerFixTripImportActionButtonType"
          class="mr-2 header-other-actions"
          startWithFirstElement="true"
          [actions]="$any(headerFixTripImportActions)"
          [matTooltip]="headerFixTripImportActionsTooltip"
          label="FINALIZATION_OF_ROUTE.CALENDAR.HEADER.TOP_RIGHT_MENU.RECOMMENDATION.LABEL"
        >
        </rr-split-button>
      </ng-container>

      <ng-container *ngIf="headerOtherActions?.length > 0">
        <rr-split-button
          [color]="headerOtherActionButtonColor"
          [buttonStyle]="headerOtherActionButtonType"
          class="mr-2 header-other-actions"
          startWithFirstElement="true"
          [actions]="$any(headerOtherActions)"
          [matTooltip]="headerOtherActionTooltip"
          label="FINALIZATION_OF_ROUTE.CALENDAR.HEADER.TOP_RIGHT_MENU.RECOMMENDATION.LABEL"
        >
        </rr-split-button>
      </ng-container>

      <button
        class="mr-2 min-width-auto"
        type="button"
        color="primary"
        mat-raised-button
        *ngIf="topRightMenuExtraButton"
        (click)="topRightMenuExtraButton.click($event)"
        [disabled]="(topRightMenuExtraButton.isDisabled | isFunction) ? topRightMenuExtraButton.isDisabled() : false"
        [ngClass]="topRightMenuExtraButton?.cssClass"
        [matTooltip]="topRightMenuExtraButton?.toolTip"
      >
        {{ topRightMenuExtraButton.label | transloco }}
      </button>

      <ng-container *ngIf="topRightMenuAfterButtons?.length > 0">
        <rr-split-button
          [color]="topRightMenuAfterButtonColor"
          withoutSeparator="true"
          label="CALENDAR.HEADER.MENU_BUTTON_TEXT"
          [actions]="topRightMenuAfterButtons | addDefaultTopRightSplitButtonActions: viewType:toggleViewTypeFn()"
        ></rr-split-button>
      </ng-container>
    </div>
  </ng-template>

  <mat-progress-bar
    *ngIf="isLoading"
    mode="indeterminate"
    [color]="viewType === RRCalendarViewType.CALENDAR ? 'warn' : 'accent'"
    class="rr-card-loader-progress-bar"
    [ngClass]="{ 'list-progress-bar': viewType === RRCalendarViewType.LIST }"
  ></mat-progress-bar>

  <rr-floating-action
    [selectedTrips]="selectedTrips"
    [actionCompleted]="tripActionHasSuccess"
    (closeWindow)="closeFloatingWindow($event)"
    (selectedTripEvent)="onSelectedTripEvent($any($event))"
  >
  </rr-floating-action>

  <ng-container [ngSwitch]="viewType">
    <rr-calendar-calendar-view
      *ngSwitchCase="RRCalendarViewType.CALENDAR"
      [calendarId]="calendarId"
      [hasListViewButton]="hasListViewButton"
      [viewDate]="viewDate"
      [weekStartsOn]="weekStartsOn"
      [weekendDays]="weekendDays"
      [locale]="locale"
      [cellHeaderTemplate]="cellHeaderTemplate"
      [cellContainerTemplate]="cellContainerTemplate"
      [setHeaderDayNumberCssClassFn]="setHeaderDayNumberCssClassFn"
      [isLoading]="isLoading"
      [hasSpecialDaysLayer]="hasSpecialDaysLayer"
      [events]="events$ | async"
      (beforeViewRender)="beforeViewRender.emit($event)"
      [calendarOptions]="calendarOptions"
      (contextMenuOnDayCell)="contextMenuOnDayCell.emit($event)"
      [rightClickContextMenuOption]="rightClickContextMenuOption"
      [enabledRightClickContextMenu]="enabledRightClickContextMenu"
      (clickCell)="clickCell.emit($event)"
    ></rr-calendar-calendar-view>
    <rr-agenda
      *ngSwitchCase="RRCalendarViewType.LIST"
      [selectedTripEvent]="selectedTripEvent"
      [listEvents]="$any(listEvents$) | async"
      [options]="options"
      [listItemTitleAfterDateCellTemplateRef]="listItemTitleAfterDateCellTemplateRef"
      [listItemDescriptionCellTemplateRef]="listItemDescriptionCellTemplateRef"
      [listItemCellTemplateRef]="listItemCellTemplateRef"
      [calendarId]="calendarId"
      [clearSelectionDay]="floatingWindowSelectedDate"
      (refreshCalenderEvent)="onnRefreshCalendar()"
      (selectedTripChangeEvent)="onSelectedChangeTrips($any($event))"
      (tripActionHasSuccess)="onTripActionHasSuccess($any($event))"
      (openEvent)="isLoading = true; clickCell.emit($any($event))"
    ></rr-agenda>
  </ng-container>
</ng-container>
