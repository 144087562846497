import { Injector } from '@angular/core';
import { isNil, isNotEmptyString } from '@roadrecord/type-guard';
import { checkIsValidCoordinate } from '../details/function/check-is-valid-coordinate.function';
import {
  FragmentDialogTypeEnum,
  FragmentHideDialogAction,
  FragmentRemoveDialogAction,
  FragmentShowDialogAction,
} from '@roadrecord/fragment-dialog';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { EMPTY, Observable, race } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { PartnerService } from '../partner.service';
import { HttpClient } from '@angular/common/http';

export function rowDblClickListFn(
  injector: Injector,
  event: any,
  afterAddedPopupPartner?: (injector: Injector, rowId: number, partnerId: number) => Observable<unknown>
) {
  const store = injector.get(Store);
  const actions$ = injector.get(Actions);
  const partnerService = new PartnerService(injector.get(HttpClient));

  if (event.status === 2) {
    let search = event.name;

    if (checkIsValidCoordinate(event)) {
      search = `${event.latitude.replace(',', '.').trim()},${event.longitude.replace(',', '.').trim()}`;
    } else if (
      !isNil(event.postcode) &&
      isNotEmptyString(event.postcode) &&
      !isNil(event.city) &&
      isNotEmptyString(event.city) &&
      !isNil(event.street) &&
      !isNil(event.house_number) &&
      isNotEmptyString(event.city)
    ) {
      search = `${event.postcode} ${event.city} ${event.street} ${event.house_number}`;
    }

    store.dispatch(
      new FragmentShowDialogAction<null>(FragmentDialogTypeEnum.NEW_PARTNER, null, { search })
    );

    return race([
      actions$.pipe(
        ofActionSuccessful(FragmentHideDialogAction),
        filter(action => action.dialogType === FragmentDialogTypeEnum.NEW_PARTNER)
      ),
      actions$.pipe(
        ofActionSuccessful(FragmentRemoveDialogAction),
        filter(action => action.dialogType === FragmentDialogTypeEnum.NEW_PARTNER)
      ),
    ]).pipe(
      switchMap(action => {
        if (action instanceof FragmentRemoveDialogAction || action.cancel === true || action.locationBack === true) {
          // cancel-t nyomtak
          return EMPTY;
        }

        return partnerService.get(action.data.id).pipe(
          switchMap(newPartner => {
            return afterAddedPopupPartner(injector, event.id, newPartner.id);
          })
        );
      })
    );
  }
}
