import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { baseNotAccessUrl, MenuService } from '@roadrecord/app-layout/common';
import { deepEqual, FUELING_ROUTE_PATH, MONTH_ACTIVITIES_PAGE_PATH } from '@roadrecord/common/common';
import { deepmerge } from '@roadrecord/deepmerge';
import { AbstractEntityService, ENTITY_SERVICE_TOKEN, HttpListResponseModel } from '@roadrecord/utils';
import { FuelingRangeRefreshAction } from '@roadrecord/fueling-range/common';
import {
  generateDefaultGridHeaderOtherActions,
  GridColumnModel,
  GridComponent,
  gridDefaultRightClickOption,
  GridDeleteElementsOption,
  GridHeaderOtherAction,
  GridRightClickOptionModel,
} from '@roadrecord/grid';
import { isNil } from '@roadrecord/type-guard';
import moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, timer } from 'rxjs';
import { distinctUntilChanged, skip, take, tap } from 'rxjs/operators';
import { FuelingService } from '../fueling.service';
import { FuelingModel } from '../model/fueling.model';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DecimalPipe } from '@angular/common';
import { FuelingDayListModel } from '../model/fueling-day-list.model';
import { FuelTypeEnum } from '@roadrecord/vehicle/model/common';
import { UpdatedMileageListAction } from '@roadrecord/mileage/state';
import { AppTypeEnum, environment } from '@roadrecord/environment';

const getTranslateKey = (key: string) => `FUELING.LIST.COLUMN.${key}`;
const getTranslateHeaderKey = (key: string) => `${getTranslateKey(key)}.HEADER`;
const getTranslateCellKey = (key: string) => `${getTranslateKey(key)}.CELL`;
const emptyStringOrTranslate = (value: unknown, translateKey: string, translocoService: TranslocoService, params: unknown) =>
  isNil(value) ? '' : translocoService.translate(translateKey, params);

@UntilDestroy()
@Component({
  selector: 'rr-day-list, rr-fueling-day-list',
  templateUrl: './day-list.component.html',
  styleUrls: ['./day-list.component.scss'],
  providers: [FuelingService, { provide: ENTITY_SERVICE_TOKEN, useExisting: FuelingService }],
})
export class DayListComponent implements OnInit, AfterViewInit {
  private selectedDayText: string;
  @ViewChild('rrGrid', { static: true })
  rrGrid: GridComponent;
  private decimalPipe = new DecimalPipe(this.translocoService.getActiveLang());
  displayedColumns: GridColumnModel<FuelingDayListModel>[] = [
    {
      name: 'fueling_datetime',
      displayNameTranslateKey: getTranslateHeaderKey('FUELING_DATETIME'),
      cellContentTemplateCallback: (fueling: FuelingDayListModel) =>
        moment(fueling.fueling_datetime).format(environment.appType === AppTypeEnum.HU ? 'HH:mm' : 'h:mm A'),
    },
    {
      name: 'place_name',
      displayNameTranslateKey: getTranslateHeaderKey('PLACE_NAME'),
      cellContentTemplateCallback: (fueling: FuelingDayListModel) => fueling.refueling_location.name,
      cellTooltipFn: (fueling: FuelingDayListModel) => fueling.refueling_location.address,
    },
    {
      name: 'cost',
      displayNameTranslateKey: getTranslateHeaderKey('COST'),
      cellContentTemplateCallback: (fueling: FuelingDayListModel) =>
        emptyStringOrTranslate(fueling.cost, getTranslateCellKey('COST'), this.translocoService, {
          value: this.decimalPipe.transform(fueling.cost),
        }),
    },
    {
      name: 'quantity',
      displayNameTranslateKey: getTranslateHeaderKey('QUANTITY'),
      cellContentTemplateCallback: (fueling: FuelingDayListModel) =>
        emptyStringOrTranslate(
          fueling.quantity,
          getTranslateCellKey(this.currentPeriodContext.vehicle.fuel_type === FuelTypeEnum.ELECTRIC ? 'QUANTITY_ELECTRIC' : 'QUANTITY'),
          this.translocoService,
          {
            value: this.decimalPipe.transform(fueling.quantity),
          }
        ),
    },
    {
      name: 'mileage',
      displayNameTranslateKey: getTranslateHeaderKey('MILEAGE'),
      cellContentTemplateCallback: (fueling: FuelingDayListModel) =>
        emptyStringOrTranslate(fueling.mileage, getTranslateCellKey('MILEAGE'), this.translocoService, {
          value: this.decimalPipe.transform(fueling.mileage),
        }),
    },
    {
      name: 'distance',
      displayNameTranslateKey: getTranslateHeaderKey('DISTANCE'),
      cellContentTemplateCallback: (fueling: FuelingDayListModel) =>
        emptyStringOrTranslate(fueling.distance, getTranslateCellKey('DISTANCE'), this.translocoService, {
          value: this.decimalPipe.transform(fueling.distance),
        }),
    },
  ];
  gridTitles: string[];
  rightClickContextMenuOption: GridRightClickOptionModel<FuelingModel>;
  deleteElementsOptions: GridDeleteElementsOption<number> = {
    slideToggleText: 'FUELING.DELETE_DIALOG_RECALCULATION_TOGGLE',
    removeBatchCb: (id, result) => {
      return this.fuelingService.removeBatchV2(id, result.slideToggleValue);
    },
  };
  private currentPeriodContext: any;
  hasNotEntitiesDataLabelLinkCallback = this.redirectToNew.bind(this);

  headerOtherActions: GridHeaderOtherAction<any>[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translocoService: TranslocoService,
    private menuService: MenuService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    private fuelingService: FuelingService,
    @Inject(MAT_DATE_FORMATS) private dateFormat: any,
    private actions$: Actions
  ) {
    this.checkPeriodContextChange();

    this.detectAutoRefreshFromPopup();
  }

  private redirectToNew() {
    this.router.navigate(['../new'], { queryParams: { selectedDay: this.selectedDayText }, relativeTo: this.route });
  }

  private detectAutoRefreshFromPopup() {
    this.actions$.pipe(ofActionSuccessful(UpdatedMileageListAction), untilDestroyed(this)).subscribe(() => this.rrGrid.refresh());
  }

  private checkPeriodContextChange(): void {
    this.store
      .select(this.periodContextStateSelectorsService.context)
      .pipe(
        tap(periodContext => (this.currentPeriodContext = periodContext)),
        skip(1),
        distinctUntilChanged((_old, _new) => deepEqual(_old, _new)),
        untilDestroyed(this),
        take(1)
      )
      .subscribe(() => {
        this.rrGrid.isLoadingResults = true;
        this.menuService
          .hasMenuItemByUrl()
          .pipe(untilDestroyed(this))
          .subscribe(v => {
            if (v === true) {
              // notAccessRedirect(this.injector, [`/${MONTH_ACTIVITIES_PAGE_PATH}/${FUELING_ROUTE_PATH}`]);
              this.router.navigate([`/${MONTH_ACTIVITIES_PAGE_PATH}/${FUELING_ROUTE_PATH}`]);
            } else {
              // notAccessRedirect(this.injector);
              this.router.navigate([baseNotAccessUrl]);
            }
          });
      });
  }

  ngOnInit(): void {
    this.selectedDayText = this.route.snapshot.queryParamMap.get('selectedDay');
    this.translocoService
      .selectTranslate('FUELING.LIST.TITLE', { date: moment(this.selectedDayText).format(this.dateFormat.display.dateInput) })
      .pipe(untilDestroyed(this))
      .subscribe((translatedText: string) => {
        this.gridTitles = [translatedText, ''];
      });
  }

  listDatabaseCallback(database: FuelingService): Observable<any> {
    return database.getDayList(this.selectedDayText);
  }

  getListDatabaseCallback(): (
    database: AbstractEntityService<HttpListResponseModel<any>, any>,
    sort: Sort,
    page: PageEvent
  ) => Observable<any> {
    return this.listDatabaseCallback.bind(this);
  }

  onEditRow($event: FuelingDayListModel): void {
    // TODO grid feature-t csinalni belole
    this.router.navigate([$event.id], {
      relativeTo: this.route,
      queryParams: { from: 'dayList', selectedDay: this.selectedDayText },
    });
  }

  onSelectionChange($event: any[]): any {
    if (this.gridTitles === undefined) {
      return timer(200).subscribe(() => this.onSelectionChange($event));
    }
    this.gridTitles = [
      this.gridTitles[0],
      this.translocoService.translate('FUELING.LIST.SELECTED_TITLE', {
        date: moment(this.selectedDayText).format(this.dateFormat.display.dateInput),
        selected: $event.length,
      }),
    ];
  }

  ngAfterViewInit(): void {
    const backToMapItem = {
      label: 'FUELING.LIST.BACK_TO_CALENDAR_VIEW',
      icon: 'undo',
      click: () => this.router.navigate(['../'], { relativeTo: this.route }),
    };
    const rightClickContextMenuOption = deepmerge(gridDefaultRightClickOption(this.rrGrid), {
      rows: [backToMapItem],
      grid: [backToMapItem],
    });
    let addIndex = rightClickContextMenuOption.rows.findIndex(elem => elem.icon === 'plus');
    rightClickContextMenuOption.rows[addIndex] = {
      label: 'COMMON.ACTION.ADD',
      icon: 'plus',
      click: () =>
        this.router.navigate(['../new'], {
          relativeTo: this.route,
          queryParams: { from: 'dayList', selectedDay: this.selectedDayText },
        }),
    };
    const rowAddItem = rightClickContextMenuOption.rows[addIndex];
    addIndex = rightClickContextMenuOption.grid.findIndex(elem => elem.icon === 'plus');
    rightClickContextMenuOption.grid[addIndex] = rowAddItem;

    this.rightClickContextMenuOption = rightClickContextMenuOption;

    const headerOtherActions = generateDefaultGridHeaderOtherActions<any>(this.rrGrid);
    headerOtherActions.shift();
    headerOtherActions.unshift({
      type: 'ITEM',
      label: { translate: 'COMMON.ACTION.ADD' },
      icon: 'plus',
      action: () =>
        this.router.navigate(['../new'], {
          relativeTo: this.route,
          queryParams: { from: 'dayList', selectedDay: this.selectedDayText },
        }),
    });
    headerOtherActions.push({
      type: 'ITEM',
      icon: 'undo',
      label: { translate: 'FUELING.LIST.BACK_TO_CALENDAR_VIEW' },
      route: ['../'],
      relativeRoute: true,
    });
    this.headerOtherActions = headerOtherActions;

    this.cdr.detectChanges();
  }

  @Dispatch()
  dispatchRefuelingStagesRefreshAction(): FuelingRangeRefreshAction {
    return new FuelingRangeRefreshAction();
  }
}
