import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@roadrecord/environment';
import { Store } from '@ngxs/store';
import { isNil } from '@roadrecord/type-guard';
import { PeriodContextStateSelectorsService } from '../state/selector/period-context-state-selectors.service';

@Injectable()
export class PeriodicStateContextInterceptor implements HttpInterceptor {
  constructor(private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>, private store: Store) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url } = req;
    const isAssetsUrl = req.url.startsWith('./assets/') || req.url.startsWith('/assets/') || req.url.startsWith('assets/');
    if (
      isAssetsUrl === false &&
      (req.url.startsWith(environment.apiUrl) ||
        req.url.startsWith(environment.api2Url) ||
        req.url.startsWith(environment.api3Url) ||
        req.url.startsWith(environment.webadminApiUrl) ||
        req.url.startsWith(environment.webadminApi2Url) ||
        req.url.startsWith(`${environment.baseUrl}/batch/`)) &&
      !req.url.includes('period-context')
    ) {
      // console.log(req.url);
      const stateMenuDisabledMenuPageGroup = this.store.selectSnapshot(states => states.menu['disabled_menu_page_group']);
      if (
        stateMenuDisabledMenuPageGroup !== undefined &&
        Array.isArray(stateMenuDisabledMenuPageGroup) &&
        stateMenuDisabledMenuPageGroup.length > 0
      ) {
        req = req.clone({
          headers: req.headers.set('User-State-Context', JSON.stringify({ vehicle_id: null, year: null, month: null })),
        });
      } else {
        const periodContext = this.store.selectSnapshot(this.periodContextStateSelectorsService.context);
        if (!isNil(periodContext)) {
          const {
            year,
            month,
            vehicle: { id: vehicleId },
          } = periodContext;
          req = req.clone({
            headers: req.headers.set('User-State-Context', JSON.stringify({ vehicle_id: vehicleId, year, month })),
          });
        }
      }
    }

    return next.handle(req);
  }
}
