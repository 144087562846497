import { isBoolean } from '@roadrecord/type-guard';
import { IS_DELETABLE_KEY } from './has-deletable';
import { IS_EDITABLE_KEY } from './has-editable';

export function hasEditable(obj: any): boolean {
  return isBoolean(obj[IS_EDITABLE_KEY]);
}

export function isEditable(obj: any): boolean {
  const _isEditable = obj[IS_EDITABLE_KEY];
  const _hasEditable = hasEditable(obj);
  return !_hasEditable || _isEditable === true;
}

export function isNotEditable(obj: any): boolean {
  if (hasEditable(obj) === false) {
    return false;
  }
  return !obj[IS_EDITABLE_KEY];
}

export function hasDeletable(obj: any): boolean {
  return isBoolean(obj[IS_DELETABLE_KEY]);
}

export function isDeletable(obj: any): boolean {
  const _isDeletable = obj[IS_DELETABLE_KEY];
  const _hasDeletable = hasDeletable(obj);
  return !_hasDeletable || _isDeletable === true;
}

export function isNotDeletable(obj: any): boolean {
  if (hasDeletable(obj) === false) {
    return false;
  }
  return !obj[IS_DELETABLE_KEY];
}
