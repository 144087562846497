import { FragmentDialogTypeEnum } from '../model/fragment-dialog-type.enum';

/**
 * fragment state hasznalja belsoleg
 */
export class FragmentLoadDialogAction {
  static readonly type = '[Fragment] Load dialog';

  constructor(readonly dialogType: FragmentDialogTypeEnum | string) {}
}
