import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Injector, NgZone, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ForeverHideTypesEnum, MessageDialogService } from '@roadrecord/message-dialog';
import {
  ApplicationSettingsTab,
  ApplicationSettingsTabCtor,
  ApplicationSettingsTabLayout,
  mixinApplicationSettingsTab,
} from '../tab.mixin';
import { DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION } from '@roadrecord/dynamic-form';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { ApplicationSettingsFormUserConfig, ApplicationSettingsService } from '@roadrecord/user/application-settings';
import { map, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

export class UserTabComponentBase {
  constructor(
    readonly layout: ApplicationSettingsTabLayout[],
    readonly applicationSettingsService: ApplicationSettingsService,
    readonly cdr: ChangeDetectorRef,
    readonly translocoService: TranslocoService,
    readonly document: Document,
    readonly messageDialogService: MessageDialogService,
    readonly injector: Injector,
    readonly ngZone: NgZone,
    readonly matSnackBar: MatSnackBar
  ) {}
}

export const _UserTabComponentBase: ApplicationSettingsTabCtor & typeof UserTabComponentBase = mixinApplicationSettingsTab(
  UserTabComponentBase
);

@Component({
  selector: 'rr-user-tab',
  templateUrl: './user-tab.component.html',
  styleUrls: ['./../company-tab/company-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION, useValue: true }],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    '[class.inited]': 'inited',
  },
})
export class UserTabComponent extends _UserTabComponentBase implements ApplicationSettingsTab, OnInit {
  constructor(
    applicationSettingsService: ApplicationSettingsService,
    cdr: ChangeDetectorRef,
    translocoService: TranslocoService,
    @Inject(DOCUMENT) document: Document,
    messageDialogService: MessageDialogService,
    injector: Injector,
    ngZone: NgZone,
    matSnackBar: MatSnackBar
  ) {
    super(
      [
        {
          label: 'USER.APPLICATION_SETTINGS.TABS.USER.MESSAGE_DIALOG_REVOKE_HIDE_SETTINGS',
          tooltip: 'USER.APPLICATION_SETTINGS.TABS.USER.MESSAGE_DIALOG_HIDE_FUNCTION_DESCRIPTION_TOOLTIP',
          formConfig: [
            {
              type: 'flex-layout',
              templateOptions: {
                cssClass: 'w-100',
                flex: {
                  fxLayout: 'column',
                  fxLayout__gt_sm: 'row',
                  fxLayoutGap: '1em',
                },
              },
              fieldGroup: [
                {
                  type: 'dialog-list-control',
                  key: 'message_dialog.forever_hide',
                  templateOptions: {
                    flex: {
                      fxFlex: '100%',
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
      applicationSettingsService,
      cdr,
      translocoService,
      document,
      messageDialogService,
      injector,
      ngZone,
      matSnackBar
    );
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getAllHttp(): Observable<ApplicationSettingsFormUserConfig> {
    return this.applicationSettingsService.getAllUser().pipe(
      map(response => ({
        message_dialog: {
          forever_hide: ForeverHideTypesEnum.getValues().map(key => ({
            key,
            value: response.message_dialog.forever_hide.indexOf(key) === -1,
          })),
        },
      })),
      tap(x => console.log('x', x))
    );
  }

  saveAllHttp(formValue: ApplicationSettingsFormUserConfig): Observable<void> {
    return this.applicationSettingsService.saveAllUser({
      message_dialog: { forever_hide: formValue.message_dialog.forever_hide.filter(v => v.value === false).map(v => v.key) },
    });
  }
}
