import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { PreventClickModule } from '@roadrecord/common/common';
import { LiveAutoCompleteModule } from '@roadrecord/live-auto-complete';
import { FormModule } from '@roadrecord/utils';
import { ValidatingModule } from '@roadrecord/validating';
import { FormComponent } from './form/form.component';
import { PeriodContextDisplayComponent } from './period-context-display.component';
import { YearMonthFieldComponent } from './year-month-field/year-month-field.component';
import { FakeCalendarHeaderComponent } from './year-month-field/fake-calendar-header.component';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatSelectModule,
    ValidatingModule,
    FormModule,
    TranslocoModule,
    MatDividerModule,
    PreventClickModule,
    FlexLayoutModule,
    LiveAutoCompleteModule,
    MatDatepickerModule,
  ],
  declarations: [PeriodContextDisplayComponent, FormComponent, YearMonthFieldComponent, FakeCalendarHeaderComponent],
  exports: [PeriodContextDisplayComponent, YearMonthFieldComponent],
  entryComponents: [PeriodContextDisplayComponent, FakeCalendarHeaderComponent],
})
export class PeriodContextDisplayModule {}
