import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textEllipsis',
})
export class TextEllipsisPipe implements PipeTransform {
  transform(value: string, options: { maxChars: number; fillTpl?: string }): any {
    const cnt = value.length;
    return `${value.slice(0, options.maxChars)}${cnt > options.maxChars ? (options.fillTpl !== undefined ? options.fillTpl : '...') : ''}`;
  }
}
