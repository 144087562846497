import { Component, OnDestroy, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ENTITY_SERVICE_TOKEN } from '@roadrecord/utils';
import { GridComponent } from '@roadrecord/grid';
import { TripReasonService } from '../trip-reason.service';
import { listColumnConfig } from './list-column.config';
import { timer } from 'rxjs';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ActivatedRoute, Router } from '@angular/router';
import { TripReasonModel } from '@roadrecord/trip-reason/model';

@Component({
  selector: 'rr-list',
  templateUrl: './list.component.html',
  providers: [TripReasonService, { provide: ENTITY_SERVICE_TOKEN, useExisting: TripReasonService }],
})
export class ListComponent implements OnDestroy {
  displayedColumns = listColumnConfig(this);
  @ViewChild('rrGrid', { static: true })
  rrGrid: GridComponent;
  private newHotkeyRef: Hotkey | Hotkey[];

  constructor(
    private hotkeysService: HotkeysService,
    readonly translocoService: TranslocoService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.addNewHotkey();
  }

  hasActionEditDisableCb = (entity: TripReasonModel) => !entity.is_editable;
  hasActionRemoveDisableCb = (entity: TripReasonModel) => !entity.is_deletable;

  private addNewHotkey() {
    timer(0).subscribe(() => {
      this.newHotkeyRef = this.hotkeysService.add(
        new Hotkey(
          'alt+shift+n',
          () => {
            this.router.navigate(['./new'], { relativeTo: this.route });
            return true;
          },
          ['INPUT'],
          this.translocoService.translate('COMMON.ACTION.ADD')
        )
      );
    });
  }

  ngOnDestroy(): void {
    this.hotkeysService.remove(this.newHotkeyRef);
  }
}
