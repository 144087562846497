export class SaveModel<M> {
  reset: boolean;
  model: M;
  removeFieldsBeforeSave: string[];
  redirect: boolean;

  constructor(data?: Partial<SaveModel<M>>) {
    if (data !== undefined) {
      Object.assign(this, data);
    }
  }
}
