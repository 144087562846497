<ng-template [ngIf]="!isNew && hasDelete">
  <button
    type="button"
    (click)="onDelete()"
    [disabled]="disableDelete !== undefined ? disableDelete : disabled"
    mat-icon-button
    [matTooltip]="deleteTooltip || 'COMMON.ACTION.DELETE' | transloco"
    matTooltipPosition="left"
  >
    <mat-icon svgIcon="delete"></mat-icon>
  </button>
</ng-template>
<ng-content select="before-save-or-edit"></ng-content>
<rr-submit-button-with-error
  [gaPrefix]="gaPrefix"
  [disabled]="disableSubmit !== undefined ? disableSubmit : disabled"
  [formSubmitted]="form.submitted"
  [formInvalid]="form.invalid"
  [buttonLabel]="isNew ? texts.actions.save : texts.actions.edit"
  invalidTooltip="COMMON.VALIDATION.BUTTON"
  (clickButton)="onSubmit()"
></rr-submit-button-with-error>

<rr-submit-button-with-error
  *ngIf="hasJustSave"
  [gaPrefix]="gaPrefix"
  [disabled]="disableSubmit !== undefined ? disableSubmit : disabled"
  [formSubmitted]="form.submitted"
  [formInvalid]="form.invalid"
  buttonLabel="COMMON.ACTION.JUST_SAVE"
  invalidTooltip="COMMON.VALIDATION.BUTTON"
  (clickButton)="onJustSaveSubmit()"
></rr-submit-button-with-error>
