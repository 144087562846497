import { UniversalUploadSourceTypes } from '@roadrecord/universal-import/common';

export enum FinalizationOfRouteImportUploadSourceTypesEnum {
  UNIVERSAL = 'universal',
  BILLINGO = 'billingo',
  MINI_CRM = 'minicrm',
  VOLVO = 'volvo',
  INNVOICE = 'innvoice',
  GOOGLE_TIMELINE = 'google-timeline',
  I_CELL = 'i-cell',
}

export const finalizationOfRouteImportUploadSourceTypes: UniversalUploadSourceTypes<FinalizationOfRouteImportUploadSourceTypesEnum>[] = [
  {
    translateKey: 'UNIVERSAL',
    value: FinalizationOfRouteImportUploadSourceTypesEnum.UNIVERSAL,
  },
  {
    translateKey: 'BILLINGO',
    value: FinalizationOfRouteImportUploadSourceTypesEnum.BILLINGO,
  },
  {
    translateKey: 'MINI_CRM',
    value: FinalizationOfRouteImportUploadSourceTypesEnum.MINI_CRM,
  },
  {
    translateKey: 'VOLVO_ON_CALL',
    value: FinalizationOfRouteImportUploadSourceTypesEnum.VOLVO,
  },
  {
    translateKey: 'INNVOICE',
    value: FinalizationOfRouteImportUploadSourceTypesEnum.INNVOICE,
  },
  {
    translateKey: 'GOOGLE_TIMELINE',
    value: FinalizationOfRouteImportUploadSourceTypesEnum.GOOGLE_TIMELINE,
  },
  {
    translateKey: 'I_CELL',
    value: FinalizationOfRouteImportUploadSourceTypesEnum.I_CELL,
  },
];
