import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FirstStepsHelpModel } from '../../../model/first-steps-next-step.model';
import { timer } from 'rxjs';
import { isNil } from '@roadrecord/type-guard';
import { showSkipButton } from './show-skip-button';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'rr-first-stepper-help-box',
  templateUrl: './first-stepper-help-box.component.html',
  styleUrls: ['./first-stepper-help-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstStepperHelpBoxComponent implements AfterViewChecked {
  @ViewChildren('helpTextElem', { read: ElementRef })
  helpTextElem: QueryList<ElementRef>;
  @Input() helpText: FirstStepsHelpModel;
  @Input() iconType = 'information-outline';
  @Input() layoutName = 'unknown';
  @Output() detailsHeight = new EventEmitter<string>();
  @Output() skipStep = new EventEmitter<void>();

  readonly showSkipButton = showSkipButton;

  private helpTextElemHeight = 0;

  constructor(private gtmService: GoogleTagManagerService) {}

  ngAfterViewChecked(): void {
    timer(400).subscribe(() => {
      if (
        !isNil(this.helpText) &&
        this.helpTextElem.length === 1 &&
        this.helpTextElem.first.nativeElement.offsetHeight > 0 &&
        this.helpTextElem.first.nativeElement.offsetHeight > this.helpTextElemHeight
      ) {
        this.helpTextElemHeight = this.helpTextElem.first.nativeElement.offsetHeight;
        this.detailsHeight.emit(`calc(100% - ${this.helpTextElem.first.nativeElement.offsetHeight}px - 1em)`);
      }
    });
  }

  onClickSkipStep() {
    this.gtmService.pushTag({
      event: this.layoutName,
      label: 'SkipStep',
      category: 'FirstStepper',
    });
    this.skipStep.emit();
  }
}
