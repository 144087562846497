import { StateContext } from '@ngxs/store';
import { asapScheduler, Observable, throwError } from 'rxjs';
import { produce } from '@ngxs-labs/immer-adapter';
import { catchError, tap } from 'rxjs/operators';
import { AbstractPeriodContextStateModel } from '../model/abstract-period-context-state.model';
import { PeriodContextRefreshRemoteStateSuccessAction } from '../action/period-context-refresh-remote-state-success.action';
import { PeriodContextRefreshRemoteStateErrorAction } from '../action/period-context-refresh-remote-state-error.action';
import { PeriodContextService } from '../../period-context.service';
import { AbstractPeriodContextModel } from '../model/abstract-period-context.model';

export function periodContextRefreshActionFn<
  MODEL extends AbstractPeriodContextModel<any>,
  STATEMODEL extends AbstractPeriodContextStateModel<MODEL>
>(periodContextService: PeriodContextService<MODEL, STATEMODEL>) {
  return (ctx: StateContext<STATEMODEL>): Observable<STATEMODEL> => {
    const dispatch = ctx.dispatch;

    produce<STATEMODEL>(ctx, draft => {
      draft.loading = true;
    });
    return periodContextService.refreshFromUserState().pipe(
      tap(periodContextState =>
        asapScheduler.schedule(() => dispatch(new PeriodContextRefreshRemoteStateSuccessAction(periodContextState)))
      ),
      catchError(err => {
        asapScheduler.schedule(() => dispatch(new PeriodContextRefreshRemoteStateErrorAction(err)));
        return throwError(err);
      })
    );
  };
}
