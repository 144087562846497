import { StateContext } from '@ngxs/store';
import { produce } from '@ngxs-labs/immer-adapter';
import { AbstractPeriodContextStateModel } from '../model/abstract-period-context-state.model';

export function periodContextStopVehiclePullActionFn<STATEMODEL extends AbstractPeriodContextStateModel<any>>(
  ctx: StateContext<STATEMODEL>
) {
  // check is run
  if (ctx.getState().runVehiclePull === true) {
    // set run flag is false
    produce<STATEMODEL>(ctx, draft => {
      draft.runVehiclePull = false;
    });
    // and start pull in app-layout
  }
}
