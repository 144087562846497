import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VehicleRelatedRemoteModel } from '@roadrecord/vehicle/model/common';
import { environment } from '@roadrecord/environment';
import { VehicleHuModel } from '@roadrecord/vehicle/model/hu';
import { laVehicleOptionsConfig } from '@roadrecord/vehicle/autocomplete/hu';
import { VehicleService } from '@roadrecord/vehicle/service/common';

@Injectable()
export class VehicleHuService extends VehicleService<VehicleHuModel> {
  getVehiclesRelated(): Observable<VehicleRelatedRemoteModel> {
    return this.http.get<VehicleRelatedRemoteModel>(`${environment.baseUrl}/api/v1/vehiclesrelated/`);
  }

  protected loadLiveAutocompleteConfig(): void {
    this._liveAutocompleteConfig = laVehicleOptionsConfig;
  }
}
