let hasWebGLSupport: boolean;

export function hasWebGLSupportProviderFactory(document: Document): boolean {
  if (hasWebGLSupport === undefined) {
    const canvas = document.createElement('canvas');
    // Get WebGLRenderingContext from canvas element.
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    // Report the result.
    hasWebGLSupport = gl && gl instanceof WebGLRenderingContext;
  }
  return hasWebGLSupport;
}
