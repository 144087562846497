import { TranslocoService } from '@ngneat/transloco';
import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';
import { isEmptyString, isNil, isString } from '@roadrecord/type-guard';

export class TranslocoExtension implements FormlyExtension {
  constructor(private translocoService: TranslocoService) {}

  prePopulate(field: FormlyFieldConfig) {
    const to = field.templateOptions || {};
    if (!to.transloco || to._translocoTranslated) {
      return;
    }

    to._translocoTranslated = true;

    const translatedTexts: any = {};
    if (!isNil(to.label) && !isEmptyString(to.label)) {
      translatedTexts['templateOptions.label'] = this.translocoService.selectTranslate(to.label);
    }
    if (!isNil(to.placeholder) && !isEmptyString(to.placeholder)) {
      translatedTexts['templateOptions.placeholder'] = this.translocoService.selectTranslate(to.placeholder);
    }
    if (!isNil(to.tooltip) && !isEmptyString(to.tooltip)) {
      translatedTexts['templateOptions.tooltip'] = this.translocoService.selectTranslate(to.tooltip);
    }
    if (!isNil(to.prefix) && isString(to.prefix)) {
      translatedTexts['templateOptions.prefix'] = this.translocoService.selectTranslate(to.prefix);
    }
    if (!isNil(to.suffix) && isString(to.suffix)) {
      translatedTexts['templateOptions.suffix'] = this.translocoService.selectTranslate(to.suffix);
    }

    field.expressionProperties = {
      ...(field.expressionProperties || {}),
      ...translatedTexts,
    };
  }
}

export function registerTranslocoExtension(translocoService: TranslocoService) {
  return {
    /* validation not required, because use out implementation validation component */
    extensions: [
      {
        name: 'transloco',
        extension: new TranslocoExtension(translocoService),
      },
    ],
  };
}
