import { isBoolean } from '@roadrecord/type-guard';

export function getFeatureFlagsFromStore() {
  const rawEnableFeaturesStoreValue = localStorage.getItem('rrEnableFeatures');
  return rawEnableFeaturesStoreValue && rawEnableFeaturesStoreValue.length > 0 ? JSON.parse(rawEnableFeaturesStoreValue) : {};
}

export function storeFeatureFlags(enableFeatures) {
  localStorage.setItem('rrEnableFeatures', JSON.stringify(enableFeatures));
  // environment.featureFlags = Object.entries(enableFeatures)
  //   .filter(entry => entry[1] === true)
  //   .map(entry => entry[0]);
}

const featureFlagsInterface = {
  enable: (name: string) => {
    const enableFeatures = getFeatureFlagsFromStore();
    if (enableFeatures[name] === undefined) {
      console.warn('FeatureFlag', `Not found feature: ${name}`);
    } else {
      enableFeatures[name] = true;
      console.info('FeatureFlag', `Enabled feature: ${name}`);
      storeFeatureFlags(enableFeatures);
    }
  },
  disable: (name: string) => {
    const enableFeatures = getFeatureFlagsFromStore();
    if (enableFeatures[name] === undefined) {
      console.warn('FeatureFlag', `Not found feature: ${name}`);
    } else {
      enableFeatures[name] = false;
      console.info('FeatureFlag', `Disabled feature: ${name}`);
      storeFeatureFlags(enableFeatures);
    }
  },
  getStatus: (name: string) => {
    const enableFeatures = getFeatureFlagsFromStore();
    if (enableFeatures[name] === undefined) {
      console.warn('FeatureFlag', `Not found feature: ${name}`);
    } else {
      console.info('FeatureFlag', `${name} feature is ${enableFeatures[name] === true ? 'enabled' : 'disabled'}`);
    }
  },
  listStatus: () => {
    const enableFeatures = getFeatureFlagsFromStore();
    console.table(enableFeatures);
  },
};

export function attachFeatureFlagsInterface(window: Window) {
  (window as any).rr = {
    featureFlags: featureFlagsInterface,
  };
}

export function getFeatureFlagStatus(name: string) {
  const feature = getFeatureFlagsFromStore()[name];
  return isBoolean(feature) ? feature : false;
}
