import { HttpClient, HttpResponse } from '@angular/common/http';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { BatchRequestModel } from '../../batch-request/model/batch-request.model';
import { deepDelta } from '@roadrecord/common/common';
import { isNil } from '@roadrecord/type-guard';
import { Observable, of } from 'rxjs';
import { environment } from '@roadrecord/environment';

export const PATCH_NOT_MODIFIED = Symbol('PATCH_NOT_MODIFIED');

/**
 * grid eseten az entitas service-nek meg kell ezt az osztaly valositania
 */
export abstract class AbstractEntityService<HTTPMODEL, MODEL> {
  protected nextRequestUseApiUrl3 = false;

  protected constructor(
    protected _entityUrl: string,
    protected _entityDefaultOrder: string,
    protected http: HttpClient,
    protected useApiUrl2 = false,
    protected useApiUrl3 = false
  ) {}

  get entityDefaultOrder(): string {
    return this._entityDefaultOrder;
  }

  get(id: number, params?: string): Observable<MODEL> {
    // if (url[url.length - 1] !== '/') {
    //   return `${url}/`;
    // }
    return this.http.get<MODEL>(this.getListOrResourceUrl(id, params));
  }

  /**
   * teljes lekerdezes parameterhetoseggel
   */
  getAll(
    sort: Sort = { active: '', direction: 'asc' },
    page: PageEvent = {
      pageIndex: 1,
      pageSize: 99999999,
      length: 1,
    },
    simpleAllFilterValue = ''
  ): Observable<HTTPMODEL> {
    return this.http.get<HTTPMODEL>(this.getListOrResourceUrl(undefined, this.getUrlParameters(sort, page, simpleAllFilterValue)));
  }

  getAllBatch(sort: Sort, page: PageEvent, simpleAllFilterValue: string): BatchRequestModel {
    return {
      method: 'get',
      relative_url: this.getListOrResourceUrl(undefined, this.getUrlParameters(sort, page, simpleAllFilterValue)).replace(
        this.getApiUrl(),
        `/${this.getBaseApiEndpoint()}/`
      ),
    };
  }

  getAllWithExtraQueryParams(
    sort: Sort = { active: '', direction: 'asc' },
    page: PageEvent = {
      pageIndex: 0,
      pageSize: 99999999,
      length: 1,
    },
    simpleAllFilterValue = '',
    queryParams: object
  ): Observable<HTTPMODEL> {
    const extraParams = Object.entries(queryParams).map(entry => `${entry[0]}=${encodeURIComponent(entry[1])}`);
    return this.http.get<HTTPMODEL>(
      this.getListOrResourceUrl(undefined, `${this.getUrlParameters(sort, page, simpleAllFilterValue)}&${extraParams.join('&')}`)
    );
  }

  getByIds(ids: any[], extraQueryParam = ''): Observable<HTTPMODEL> {
    return this.http.get<HTTPMODEL>(
      `${this.getEntityApiUrl()}/?byIds=${ids.join(',')}&page_size=99999&page=1&ordering=${this._entityDefaultOrder}${
        extraQueryParam.length > 0 ? `&${extraQueryParam}` : ''
      }`
    );
  }

  create(object: any): Observable<MODEL> {
    return this.http.post<MODEL>(`${this.getEntityApiUrl()}/`, object);
  }

  createStreamResponse(object: any, queryParams?: any): Observable<HttpResponse<MODEL>> {
    return this.http.post<MODEL>(`${this.getEntityApiUrl()}/${this.generateQueryParams(queryParams)}`, object, { observe: 'response' });
  }

  private generateQueryParams(queryParams: any) {
    return isNil(queryParams) || Object.keys(queryParams).length === 0
      ? ''
      : `?${Object.entries(queryParams)
          .reduce((prev, curr, all) => {
            prev.push(`${curr[0]}=${curr[1]}`);
            return prev;
          }, [])
          .join('&')}`;
  }

  // TODO IDTYPE-t genericben atvenni az id-hoz!
  update(id: any, object: any, params?: any): Observable<MODEL> {
    return this.http.put<MODEL>(`${this.getEntityApiUrl()}/${id}/`, object);
  }

  updateStreamResponse(id: any, object: any, queryParams?: any): Observable<HttpResponse<MODEL>> {
    return this.http.put<MODEL>(`${this.getEntityApiUrl()}/${id}/${this.generateQueryParams(queryParams)}`, object, {
      observe: 'response',
    });
  }

  patch(id: any, object: Partial<MODEL>): Observable<void> {
    return this.http.patch<void>(`${this.getEntityApiUrl()}/${id}/`, object);
  }

  patchStreamResponse(id: any, object: any, queryParams?: any): Observable<HttpResponse<void>> {
    return this.http.patch<void>(`${this.getEntityApiUrl()}/${id}/${this.generateQueryParams(queryParams)}`, object, {
      observe: 'response',
    });
  }

  patchStreamResponseWithAutoDifferenceCalculation(
    id: any,
    newModel: any,
    oldModel: any,
    patchRemoveFieldsBeforeDiff = [],
    queryParams?: any
  ): Observable<HttpResponse<void>> {
    const _oldModel = this.removeFieldFromModel(patchRemoveFieldsBeforeDiff, oldModel);
    const _newModel = this.removeFieldFromModel(patchRemoveFieldsBeforeDiff, newModel);

    const diff = deepDelta(_oldModel, _newModel);
    if (Object.keys(diff).length === 0) {
      // bar void a stream, de bugos es kell a null, mert nem indul el :(
      return of(PATCH_NOT_MODIFIED) as any;
    }
    return this.patchStreamResponse(id, diff, queryParams);
  }

  private removeFieldFromModel(patchRemoveFieldsBeforeDiff: any[], model: any) {
    return patchRemoveFieldsBeforeDiff.length > 0
      ? Object.entries(model).reduce((curr, next) => {
          if (patchRemoveFieldsBeforeDiff.indexOf(next[0]) === -1) {
            curr[next[0]] = next[1];
          }
          return curr;
        }, {})
      : model;
  }

  remove(id: string | number): Observable<any> {
    return this.http.delete<any>(`${this.getEntityApiUrl()}/${id}/`);
  }

  removeBatch(id: string | number): BatchRequestModel {
    return { method: 'delete', relative_url: `/${environment.baseApiEndpoint}/${this._entityUrl}/${id}/` };
  }

  removeStreamResponse(id: string | number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.getEntityApiUrl()}/${id}/`, { observe: 'response' });
  }

  // EZ KELL A VIEW PRESENTER PLUGINNAK, HA NEM JOL VALASZOL AKKOR A DATA FORM EDIT -NEL UJ-NAK FOGJA ERZEKELNI!
  abstract getModelIdValue(model: MODEL): any;

  abstract getToString(model: MODEL): string;

  protected getUrlParameters(sort: Sort, page: PageEvent, simpleAllFilterValue: string): string {
    if (isNil(simpleAllFilterValue)) {
      simpleAllFilterValue = '';
    }
    const searchParam = this.getSearchParam(simpleAllFilterValue);
    const pageParam = this.getPageParam(page);
    const sortParam = this.getSortParam(sort);
    return [searchParam, pageParam, sortParam].filter(param => param.length).join('&');
  }

  protected getListOrResourceUrl(idOrFirstPath: number | string, params?: string): string {
    const resourceId = !isNil(idOrFirstPath) ? `/${idOrFirstPath}` : '';
    const queryParams = params ? `?${params}` : '';
    return `${this.getEntityApiUrl()}${resourceId}/${queryParams ? `${queryParams}` : ''}`;
  }

  protected getSortParam(sort: Sort): string {
    const direction = sort.direction === 'asc' ? '' : '-';
    const field = sort.active;
    const defaultOrdering = this._entityDefaultOrder ? `ordering=${this._entityDefaultOrder}` : '';
    return sort.direction.toString().length ? `ordering=${direction}${field}` : defaultOrdering;
  }

  protected getPageParam(page: PageEvent): string {
    return `page_size=${page.pageSize}&page=${page.pageIndex + 1}`;
  }

  protected getSearchParam(filterValue: string): string {
    return filterValue !== undefined && filterValue.length ? `search=${encodeURIComponent(filterValue)}` : '';
  }

  get entityUrl(): string {
    return this._entityUrl;
  }

  protected getApiUrl(): string {
    if (this.nextRequestUseApiUrl3 === true || this.useApiUrl3 === true) {
      this.nextRequestUseApiUrl3 = false;
      return environment.api3Url;
    }
    return this.useApiUrl2 ? this.getApiUrl2() : environment.apiUrl;
  }

  protected getApiUrl2(): string {
    return environment.api2Url;
  }

  protected getEntityApiUrl(): string {
    return `${this.getApiUrl()}${this._entityUrl}`;
  }

  protected getEntityApiUrl2(): string {
    return `${this.getApiUrl2()}${this._entityUrl}`;
  }

  protected getBaseApiEndpoint(): string {
    return this.useApiUrl2 ? environment.baseApi2Endpoint : environment.baseApiEndpoint;
  }
}
