import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CalendarsState } from './state/model/calendars-state.interface';
import { CalendarStateModel } from './state/model/calendar-state.model';
import { RRCalendarViewType } from './calendar/rr-calendar-view-type';
import { Store } from '@ngxs/store';
import { isNil } from '@roadrecord/type-guard';

@Injectable()
export class CalendarRedirectToListGuard implements CanActivate {
  constructor(private store: Store, private router: Router, private route: ActivatedRoute) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    routeState: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string;
    let params = '';
    if (routeState.url.indexOf('?') > -1) {
      const splittedUrl = routeState.url.split('?');
      url = splittedUrl[0];
      params = splittedUrl[1];
    } else {
      url = routeState.url;
    }
    let stateCalendarId = url;
    if (url.indexOf('/list') > -1) {
      stateCalendarId = url.split('/list')[0];
    }
    const calendarState = this.store.selectSnapshot<CalendarStateModel>((states: { calendars: CalendarsState }) => {
      if (!isNil(states) && !isNil(states.calendars) && !isNil(states.calendars.container)) {
        return (states.calendars as CalendarsState).container[stateCalendarId];
      }
      return undefined;
    });
    if (calendarState !== undefined) {
      if (calendarState.viewType === RRCalendarViewType.LIST) {
        return this.router.parseUrl(`${url}/list${params.length > 0 ? `?${params}` : ''}`);
      } else {
        return true;
      }
    }
    return true;
  }
}
