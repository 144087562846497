import {
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { flatten, HashMap, TRANSLOCO_TRANSPILER, TranslocoService, TranslocoTranspiler } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { getOriginalPath, mergeTranslateObjects } from '../utils/merge-translate-objects.function';
import { isNil } from '@roadrecord/type-guard';

export type translateFn = (key: string, params?: HashMap) => string;

@Directive({
  selector: '[rrTransloco]',
})
export class TranslocoDirective implements OnInit, OnDestroy {
  // tslint:disable-next-line:no-input-rename
  @Input('rrTransloco') key: string;
  // tslint:disable-next-line:no-input-rename
  @Input('rrTranslocoParams') params: HashMap = {};
  // tslint:disable-next-line:no-input-rename
  @Input('rrTranslocoFromPath') fromPath: string;
  // tslint:disable-next-line:no-input-rename
  @Input('rrTranslocoOverridePath') overridePath: string;
  private subscription: Subscription;
  private currentLang: string;
  private view: EmbeddedViewRef<{ $implicit: any }>;
  private mergeStore: { [key: string]: string };
  private translateStore: { [key: string]: string };

  constructor(
    private translocoService: TranslocoService,
    @Optional() private tpl: TemplateRef<{ $implicit: (key: string, params?: HashMap) => string; getKey: (key: string) => string }>,
    @Inject(TRANSLOCO_TRANSPILER) private parser: TranslocoTranspiler,
    private vcr: ViewContainerRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = this.translocoService.langChanges$.subscribe(activeLang => {
      this.currentLang = activeLang;
      this.translateStore = {};
      this.mergeStore = flatten(mergeTranslateObjects(this.fromPath, this.overridePath));
      if (this.view) {
        this.view.context['$implicit'] = this.getTranslateFn();
        this.view.context['getKey'] = this.getKeyFn();
      } else {
        this.view = this.vcr.createEmbeddedView(this.tpl, {
          $implicit: this.getTranslateFn(),
          getKey: this.getKeyFn(),
        });
      }
      this.cdr.markForCheck();
    });
  }

  getTranslateFn(): translateFn {
    return (key: string, params?: HashMap) => {
      const withParams = params ? `${key}${JSON.stringify(params)}` : key;
      if (this.translateStore.hasOwnProperty(withParams)) {
        return this.translateStore[withParams];
      }
      const translateKey = getOriginalPath(this.mergeStore, key);
      const translatedText = this.translocoService.translateObject(translateKey, params);
      if (isNil(translatedText)) {
        console.error('Not found key: ', translateKey);
        return '';
      }
      this.translateStore[withParams] = translatedText;
      return translatedText;
    };
  }

  getKeyFn() {
    return (key: string, params?: HashMap) => {
      return getOriginalPath(this.mergeStore, key);
    };
  }
}
