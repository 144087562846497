import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { environment } from '@roadrecord/environment';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[data-cy]',
})
export class DataCyDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    if (environment.mode !== 'e2e') {
      renderer.removeAttribute(el.nativeElement, 'data-cy');
    }
  }
}
