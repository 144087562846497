import { DOCUMENT } from '@angular/common';
import { ConstructorProvider } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { PresenterStateController } from '../../presenter-state/presenter-state.controller';
import { ViewRemoteFieldErrorPlugin } from './view-remote-field-error.plugin';

export const VIEW_REMOTE_FIELD_ERROR_PLUGIN_PROVIDER: ConstructorProvider = {
  provide: ViewRemoteFieldErrorPlugin,
  deps: [PresenterStateController, TranslocoService, DOCUMENT, MessageDialogService],
};
