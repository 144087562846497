import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RecaptchaLoaderService, RecaptchaModule } from 'ng-recaptcha';
import { ForgotPasswordModule } from './authentication/layout/forgot-password/forgot-password.module';
import { LoginModule } from './authentication/layout/login/login.module';
import { RegisterModule } from './authentication/layout/register/register.module';
import { ResetPasswordModule } from './authentication/layout/reset-password/reset-password.module';
import { PreloadedRecaptchaAPIService } from './preloaded-recaptcha-api-service';
import { UserRoutingModule } from './user-routing.module';
import { UnauthWrapperComponent } from './unauth-wrapper/unauth-wrapper.component';

@NgModule({
  imports: [
    CommonModule,
    UserRoutingModule,
    LayoutModule,
    MatDialogModule,
    MatSnackBarModule,
    RecaptchaModule,
    LoginModule,
    ForgotPasswordModule,
    RegisterModule,
    ResetPasswordModule,
  ],
  declarations: [UnauthWrapperComponent],
  providers: [
    {
      provide: RecaptchaLoaderService,
      useFactory: () => new PreloadedRecaptchaAPIService(),
    },
  ],
})
export class UserModule {}
