import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FuelingRangeOpenWindowDirective } from './fueling-range-open-window.directive';

@NgModule({
  declarations: [FuelingRangeOpenWindowDirective],
  imports: [CommonModule],
  exports: [FuelingRangeOpenWindowDirective],
})
export class FuelingRangeModule {}
