import { Location } from '@angular/common';
import { ConstructorProvider, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ENTITY_SERVICE_TOKEN } from '../../../entity/service/entity-service.token';
import { PresenterStateController } from '../../../form/controller/presenter-state/presenter-state.controller';
import { CheckNewOrLoadPlugin } from './check-new-or-load.plugin';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

export const CHECK_NEW_OR_LOAD_CONTROLLER_PROVIDER: ConstructorProvider = {
  provide: CheckNewOrLoadPlugin,
  deps: [PresenterStateController, ENTITY_SERVICE_TOKEN, ActivatedRoute, Location, [new Optional(), GoogleTagManagerService]],
};
