import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { checkPropertyChange, globalEmailControlValidationMessages } from '@roadrecord/common/common';
import {
  commonHttpStreamErrorHandler,
  HasViewModelPlugin,
  HasViewSubmitPlugin,
  PresenterStateController,
  SaveModel,
  ViewRemoteFieldErrorPlugin,
} from '@roadrecord/utils';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { rrFormErrorStateMatcher } from '@roadrecord/validating';
import { EmailValidators } from 'ngx-validators';
import { CompanyMemberDetailsModel } from '../../model/company-member-details.model';
import { CompanyMemberService } from './../../company-member.service';
import { CompanyMemberRoleEnum } from './../../model/company-member-role.enum';
import { CompanyMemberStatusEnum } from './../../model/company-member-status.enum';
import { SecureDeleteDialogComponent } from '@roadrecord/secure-delete-dialog';
import { DYNAMIC_FORM_CONFIG, DynamicFormConfigEnum, DynamicFormConfigInterface } from '@roadrecord/dynamic-form';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { timer } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from '@roadrecord/type-guard';

@UntilDestroy()
@Component({
  selector: 'rr-data-form, rr-company-member-data-form',
  templateUrl: './data-form.component.html',
  styleUrls: ['./data-form.component.scss'],
  providers: [rrFormErrorStateMatcher],
  animations: [
    trigger('transitionMessages', [
      state('enter', style({ width: '100%', opacity: 1, transform: 'translateY(0%)' })),
      transition('void => enter', [
        style({ opacity: 0, width: '100%', transform: 'translateY(-100%)' }),
        animate('350ms cubic-bezier(0.55, 0, 0.55, 0.2)'),
      ]),
      transition('* => void', [
        animate(
          '200ms cubic-bezier(0.55, 0, 0.55, 0.2)',
          style({
            width: '100%',
            opacity: 0,
            transform: 'translateY(-100%)',
          })
        ),
      ]),
    ]),
  ],
})
export class DataFormComponent
  implements
    OnInit,
    OnChanges,
    HasViewModelPlugin<CompanyMemberDetailsModel>,
    HasViewSubmitPlugin<CompanyMemberDetailsModel>,
    AfterViewInit {
  @ViewChild('formTpl', { static: true })
  formTpl: TemplateRef<any>;
  @ViewChild('buttonsTpl', { static: true })
  buttonsTpl: TemplateRef<any>;
  @Input()
  hasSubmitAndNew = true;
  @Input()
  isNew = true;
  @Input()
  editModel: CompanyMemberDetailsModel;
  @Output()
  submitForm = new EventEmitter<SaveModel<CompanyMemberDetailsModel>>();
  @Output()
  delete = new EventEmitter<any>();
  form: FormGroup;
  emailControl = new FormControl(undefined, [Validators.required, EmailValidators.normal]);
  roleControl = new FormControl(CompanyMemberRoleEnum.USER, [Validators.required]);
  emailControlValidationMessages = globalEmailControlValidationMessages;
  CompanyUserRoleEnum = CompanyMemberRoleEnum;
  CompanyUserStatusEnum = CompanyMemberStatusEnum;
  showSecureDeleteDialog = false;
  @Output() removeUser = new EventEmitter<void>();
  @ViewChild(SecureDeleteDialogComponent) secureDeleteDialogComponent: SecureDeleteDialogComponent;
  readonly nameFormFragmentFormlyFields: FormlyFieldConfig[];

  constructor(
    private renderer2: Renderer2,
    private fb: FormBuilder,
    @Optional() private presenterStateController: PresenterStateController<CompanyMemberDetailsModel, number>,
    private companyUserService: CompanyMemberService,
    private messageDialogService: MessageDialogService,
    private matSnackBar: MatSnackBar,
    private translocoService: TranslocoService,
    private cdr: ChangeDetectorRef,
    @Inject(DYNAMIC_FORM_CONFIG) dynamicFormConfigs: DynamicFormConfigInterface[],
    private ngZone: NgZone,
    private injector: Injector
  ) {
    this.nameFormFragmentFormlyFields = dynamicFormConfigs
      .find(config => config.name === DynamicFormConfigEnum.COMPANY_MEMBER_NAME_FORM_FRAGMENT)
      .config(this.injector);

    if (presenterStateController !== null) {
      const viewRemoteFieldErrorPlugin: ViewRemoteFieldErrorPlugin<CompanyMemberDetailsModel, number> = presenterStateController.get(
        ViewRemoteFieldErrorPlugin
      );
      viewRemoteFieldErrorPlugin.settedRemoteErrors$.pipe(untilDestroyed(this)).subscribe(() => this.setEditModelUIRestriction());
    }
  }

  ngOnInit(): void {
    this.initForm();
  }

  setEditModelUIRestriction(): void {
    if (this.isNew === false) {
      this.emailControl.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (checkPropertyChange('editModel', changes)) {
      const editModel = changes.editModel.currentValue;
      if (!isNil(editModel)) {
        if (this.form.get('status') === null) {
          this.form.addControl('status', new FormControl(editModel.status, Validators.required));
        }
        this.form.get('email').disable();
      } else {
        if (this.form !== undefined) {
          if (this.form.get('status') !== null) {
            this.form.removeControl('status');
          }
          this.form.get('email').enable();
        }
      }
    }
  }

  onSubmit(): void {}

  onSubmitReset(): void {}

  onClickResendInvite() {
    this.presenterStateController.loading$.next(true);
    this.companyUserService.resendInvite(this.editModel.email).subscribe(
      () => {
        this.presenterStateController.loading$.next(false);
        this.matSnackBar.open(
          this.translocoService.translate('COMPANY_MEMBER.RESEND_INVITE.SUCCESS_RESEND', { email: this.editModel.email })
        );
      },
      commonHttpStreamErrorHandler(() => this.presenterStateController.loading$.next(false))
    );
  }

  onDelete(id: any) {
    this.delete.emit(id);
  }

  openDelete(): void {
    this.showSecureDeleteDialog = true;
    this.cdr.detectChanges();
    this.secureDeleteDialogComponent.openDelete();
  }

  private initForm(): void {
    this.form = this.fb.group({
      email: this.emailControl,
      role: this.roleControl,
    });
  }

  ngAfterViewInit(): void {
    /**
     * Template trukk miatt, itt kell a kezi idozites mert ngxFormly -ban nincs
     */
    timer(600)
      .pipe(switchMap(() => this.ngZone.onStable.pipe(take(1))))
      .subscribe(() => {
        this.nameFormFragmentFormlyFields[0].fieldGroup[0]['_elementRefs'][0].nativeElement.focus();
      });
  }

  getFirstName() {
    return this.form.get('first_name').value;
  }

  getLastName() {
    return this.form.get('last_name').value;
  }
}
