import { isNil } from '@roadrecord/type-guard';

export function checkIsValidCoordinate(location: { latitude: any; longitude: any }): boolean {
  return (
    !isNil(location) &&
    !isNil(location.latitude) &&
    !isNaN(parseFloat(location.latitude.toString())) &&
    !isNil(location.longitude) &&
    !isNaN(parseFloat(location.longitude.toString()))
  );
}
