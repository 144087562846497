import { DOCUMENT, Location } from '@angular/common';
import { AfterViewInit, Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isBoolean, isFunction, isNil, isTrue } from '@roadrecord/type-guard';
import { DialogOptionsModel } from '../model/dialog-options.model';

@Component({
  selector: 'rr-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements AfterViewInit {
  maximized = false;
  readonly data: DialogOptionsModel;
  loading = false;
  @HostBinding('class.maximize-mode')
  maximizeModeClass = false;

  private config = {
    disableClose: false,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '',
    height: '',
    minWidth: '',
    minHeight: '',
    maxWidth: 800,
    maxHeight: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: '',
    },
  };
  private storeSize: { width: number; height: number };

  constructor(
    dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: DialogOptionsModel,
    @Inject(DOCUMENT) private readonly document: Document,
    private location: Location,
    private readonly dialogRef: MatDialogRef<DialogComponent>
  ) {
    dialog.afterOpened.subscribe(() => {
      if (!document.body.classList.contains('no-scroll')) {
        document.body.classList.add('no-scroll');
      }
    });
    dialog.afterAllClosed.subscribe(() => {
      document.body.classList.remove('no-scroll');
    });

    if (!isNil(data)) {
      if (!isNil(data.loading$)) {
        data.loading$.subscribe(loading => (this.loading = loading));
      }

      // if (data.cancelButtonLabelTranslateKey === undefined) {
      //   data.cancelButtonLabelTranslateKey = 'ACTIONS.CANCEL';
      // }
      if (data.cancelButtonLabelTooltipKey === undefined) {
        data.cancelButtonLabelTooltipKey = 'COMMON.ACTION.TOOLTIP.CANCEL';
      }
      this.data = data;

      if (isBoolean(this.data.maximizeMode) && isTrue(this.data.maximizeMode)) {
        this.maximizeModeClass = true;
      }

      if (isBoolean(this.data.startMaximizedSize) && isTrue(this.data.startMaximizedSize)) {
        this.maximizeModeClass = true;
      }
    }
  }

  maximize(): void {
    this.maximized = true;
    const rrDialogElem: HTMLElement = this.document.querySelector('.rr-dialog');
    this.storeSize = { width: rrDialogElem.offsetWidth, height: rrDialogElem.offsetHeight };
    this.dialogRef.updateSize('100%', '100%');
  }

  minimize(): void {
    this.maximized = false;
    this.dialogRef.updateSize(`${this.storeSize.width}px`, `${this.storeSize.height}px`);
  }

  ngAfterViewInit(): void {
    if (isBoolean(this.data.startMaximizedSize) && isTrue(this.data.startMaximizedSize)) {
      this.maximize();
    }
  }

  preventClick(): void {
    this.data.preventCloseCb().subscribe(close => (close ? this.dialogRef.close('') : undefined));
  }

  onClickClose(): void {
    if (isFunction(this.data.cancel)) {
      this.data.cancel();
    } else {
      this.location.back();
    }
  }
}
