<mat-form-field
  [formGroup]="formGroup"
  *ngIf="formGroup"
  class="wid100"
  [ngClass]="{ disabled: disablePicker || formControl?.disabled, xx: fullDateMode }"
>
  <mat-label *ngIf="label"> {{ label | transloco }} </mat-label>
  <input
    matInput
    [name]="name"
    [matDatepicker]="dayPicker"
    [formControlName]="name"
    [min]="minDate"
    [max]="maxDate"
    [readonly]="editable === false"
    [required]="required"
    (click)="onClickInput()"
    class="noselect"
  />
  <span matPrefix *ngIf="!fullDateMode && (selectedLang$ | async) === 'hu'">{{ year }}.{{ month }}.</span>
  <ng-container matSuffix>
    <span *ngIf="!fullDateMode && (selectedLang$ | async) === 'en'">/{{ month }}/{{ year }}</span>
    <mat-datepicker-toggle
      [for]="dayPicker"
      class="rr-datepicker-icon"
      [matTooltip]="disablePicker || formControl?.disabled ? undefined : ('COMPONENT.DAY_SELECT_FORM_CONTROL.DATE_SUFFIX_ICON' | transloco)"
      matTooltipPosition="below"
    ></mat-datepicker-toggle>
  </ng-container>
  <mat-datepicker
    #matDatepicker
    [panelClass]="removeControls ? 'remove-controls' : undefined"
    #dayPicker
    [disabled]="disablePicker || formControl?.disabled"
    (closed)="onClosed()"
  ></mat-datepicker>
  <mat-hint *ngIf="hint">{{ hint | transloco }}</mat-hint>
  <mat-error> <rr-validation-messages [messages]="extraErrorMessages" [errors]="formControl?.errors"></rr-validation-messages> </mat-error>
</mat-form-field>
