import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsEmptyStringPipe } from './is-empty-string.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [IsEmptyStringPipe],
  exports: [IsEmptyStringPipe],
})
export class IsEmptyStringPipeModule {}
