import { ModuleWithProviders, NgModule } from '@angular/core';
import { UpdateVehicleStateResolverService } from './update-vehicle-state-resolver.service';

@NgModule()
export class UpdateVehicleStateResolverModule {
  static forChild(): ModuleWithProviders<UpdateVehicleStateResolverModule> {
    return {
      ngModule: UpdateVehicleStateResolverModule,
      providers: [UpdateVehicleStateResolverService],
    };
  }
}
