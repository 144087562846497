import { ApplicationRef, ComponentFactoryResolver, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { AbstractFragmentPresenterClass, FragmentDialogTypeEnum, FragmentHideDialogAction } from '@roadrecord/fragment-dialog';
import { tripReasonResetModel } from '../details/details.component';
import { ModalWrapperComponent } from '../modal-wrapper/modal-wrapper/modal-wrapper.component';
import { tripReasonDetailsRoutingWhenSaveAndNotResetStrategy } from '../trip-reason-details-routing-when-save-and-not-reset.strategy';
import { TripReasonModel } from '@roadrecord/trip-reason/model';

export class TripReasonNewFragmentPresenter extends AbstractFragmentPresenterClass<TripReasonModel, ModalWrapperComponent> {
  constructor(
    store: Store,
    matDialog: MatDialog,
    fragmentType: FragmentDialogTypeEnum,
    componentFactoryResolver: ComponentFactoryResolver,
    document: Document,
    appRef: ApplicationRef,
    injector: Injector
  ) {
    super(
      store,
      matDialog,
      componentFactoryResolver,
      fragmentType,
      ModalWrapperComponent,
      document,
      appRef,
      injector,
      tripReasonDetailsRoutingWhenSaveAndNotResetStrategy,
      ['trip-reason-dialog'],
      {
        maxWidth: 600,
        height: '90%',
        maxHeight: 312,
        width: '90%',
      }
    );
  }

  uiCloseDialog(cancel?: boolean): void {
    this.store.dispatch(new FragmentHideDialogAction(this.fragmentType, undefined, false, cancel));
  }

  get formValue(): TripReasonModel {
    if (this.uiSaved === true) {
      // uj mentes van
      return tripReasonResetModel;
    }
    const formValue = this.cmpRef.instance.details.first.presenterStateController.formGroupLastValue.value;
    const newFormValue: TripReasonModel = {} as TripReasonModel;
    /**
     * kis hack mert mi undefined-t hasznalunk de a material form null-t add vissza :( a formModel getternel
     */
    // tslint:disable-next-line
    Object.entries(formValue).forEach(entry => (newFormValue[entry[0]] = entry[1] === null ? undefined : entry[1]));
    return newFormValue;
  }

  get newModel(): TripReasonModel {
    return tripReasonResetModel;
  }

  directSubmit(): void {
    this.cmpRef.instance.details.first.dataForm.onSubmit();
  }
}
