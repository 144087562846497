import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher, ThemePalette } from '@angular/material/core';
import { Subscription, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgxMatDateAdapter } from './core/date-adapter';
import {
  createMissingDateImplError,
  DEFAULT_STEP,
  formatTwoDigitTimeValue,
  LIMIT_TIMES,
  MERIDIANS,
  NUMERIC_REGEX,
  PATTERN_INPUT_HOUR,
  PATTERN_INPUT_MINUTE,
  PATTERN_INPUT_SECOND,
} from './utils/date-utils';
import moment, { Moment } from 'moment';
import { isNil, isString } from '@roadrecord/type-guard';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { deepEqual } from '@roadrecord/common/common';

export class TimePickerErrorStateMatcher extends ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: (FormGroupDirective | NgForm | null) & { form: { submitted: boolean } }): boolean {
    return control.invalid;
  }
}

@UntilDestroy()
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ngx-mat-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: 'ngx-mat-timepicker',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NgxMatTimepickerComponent),
      multi: true,
    },
  ],
  exportAs: 'ngxMatTimepicker',
  encapsulation: ViewEncapsulation.None,
})
export class NgxMatTimepickerComponent<D extends Moment> implements ControlValueAccessor, OnInit, OnChanges {
  public form: FormGroup;
  @Input() disabled = false;
  hourControl = new FormControl(
    { value: null, disabled: this.disabled },
    Validators.compose([Validators.required, Validators.pattern(PATTERN_INPUT_HOUR)])
  );
  minuteControl = new FormControl(
    {
      value: null,
      disabled: this.disabled,
    },
    Validators.compose([Validators.required, Validators.pattern(PATTERN_INPUT_MINUTE)])
  );
  secondControl = new FormControl(
    {
      value: null,
      disabled: this.disabled,
    },
    Validators.compose([Validators.required, Validators.pattern(PATTERN_INPUT_SECOND)])
  );
  @Input() showSpinners = true;
  @Input() stepHour: number = DEFAULT_STEP;
  @Input() stepMinute: number = DEFAULT_STEP;
  @Input() stepSecond: number = DEFAULT_STEP;
  @Input() showSeconds = false;
  @Input() disableMinute = false;
  @Input() enableMeridian = false;
  @Input() defaultTime: number[];
  @Input() color: ThemePalette = 'primary';
  /**
   * ha true akkor string-et kell beadni es azt kapunk vissza is!
   */
  @Input() autoCastStringToMoment = false;
  /**
   * ha true akkor ha nem kapunk erteket akkor is beallitunk valamit!
   */
  @Input() autoSetTime = false;
  @Input() tooltip: string;
  public meridian: string = MERIDIANS.AM;
  public pattern = PATTERN_INPUT_HOUR;
  private holdChangeIntervalSubscription: Subscription;
  private _disabled: boolean;
  private _model: D;
  readonly errorStateMatcher = new TimePickerErrorStateMatcher();
  private _autoCastMomentToString = false;

  @Input()
  set autoCastMomentToString(val: boolean) {
    this._autoCastMomentToString = coerceBooleanProperty(val);
  }

  constructor(@Optional() public _dateAdapter: NgxMatDateAdapter<D>, private cd: ChangeDetectorRef) {
    if (!this._dateAdapter) {
      throw createMissingDateImplError('NgxMatDateAdapter');
    }
    this.form = new FormGroup({
      hour: this.hourControl,
      minute: this.minuteControl,
      second: this.secondControl,
    });
  }

  /** Whether or not the form is valid */
  public get valid(): boolean {
    return this.form.valid;
  }

  /** Hour */
  private get hour() {
    const val = Number(this.form.controls['hour'].value);
    return isNaN(val) ? 0 : val;
  }

  private get minute() {
    const val = Number(this.form.controls['minute'].value);
    return isNaN(val) ? 0 : val;
  }

  private get second() {
    const val = Number(this.form.controls['second'].value);
    return isNaN(val) ? 0 : val;
  }

  ngOnInit() {
    this.form.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(400),
        distinctUntilChanged((_old, _new) => deepEqual(_old, _new))
      )
      .subscribe(() => this._updateModel());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled && !changes.disabled.firstChange) {
      this.disabled ? this.form.disable() : this.form.enable();
    }

    this.disableMinute ? this.minuteControl.disable() : this.minuteControl.enable();
  }

  /**
   * Writes a new value to the element.
   * @param obj
   */
  writeValue(val: D | string): void {
    if (!isNil(val)) {
      if (isString(val)) {
        if (this.autoCastStringToMoment === true) {
          this._model = this.getSettedMomentHoursAndMinutesFromString(val);
        } else {
          throw new Error('Value is string, but not set autoCastStringToMoment');
        }
      } else {
        this._model = val;
      }
      this._updateHourMinuteSecond();
    } else {
      if (this.autoSetTime === true) {
        this._model = this._dateAdapter.today();
        if (this.defaultTime != null) {
          this._dateAdapter.setTimeByDefaultValues(this._model, this.defaultTime);
        }
        this._updateHourMinuteSecond();
      }
    }
  }

  /** Registers a callback function that is called when the control's value changes in the UI. */
  registerOnChange(fn: (_: any) => {}): void {
    this._onChange = fn;
  }

  /**
   * Set the function to be called when the control receives a touch event.
   */
  registerOnTouched(fn: () => {}): void {
    this._onTouched = fn;
  }

  /** Enables or disables the appropriate DOM element */
  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this.cd.markForCheck();
  }

  /**
   * Format input
   * @param input
   */
  public formatInput(input: HTMLInputElement) {
    input.value = input.value.replace(NUMERIC_REGEX, '');
  }

  /** Toggle meridian */
  public toggleMeridian() {
    this.meridian = this.meridian === MERIDIANS.AM ? MERIDIANS.PM : MERIDIANS.AM;
    this.change('hour');
  }

  /** Change property of time */
  public change(prop: string, up?: boolean) {
    const next = this._getNextValueByProp(prop, up);
    this.form.controls[prop].setValue(prop === 'hour' && this.enableMeridian ? next : formatTwoDigitTimeValue(next), {
      onlySelf: false,
      emitEvent: false,
    });
    this._updateModel();
  }

  holdChange(prop: string, up?: boolean) {
    this.holdChangeIntervalSubscription = timer(500, 250)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.change(prop, up);
      });
  }

  stopHoldChange() {
    if (!isNil(this.holdChangeIntervalSubscription) && this.holdChangeIntervalSubscription.closed === false) {
      this.holdChangeIntervalSubscription.unsubscribe();
      delete this.holdChangeIntervalSubscription;
    }
  }

  private _onChange: any = () => {};

  private _onTouched: any = () => {};

  /** Update controls of form by model */
  private _updateHourMinuteSecond() {
    let _hour = this._dateAdapter.getHour(this._model);
    const _minute = this._dateAdapter.getMinute(this._model);
    const _second = this._dateAdapter.getSecond(this._model);

    if (this.enableMeridian) {
      if (_hour > LIMIT_TIMES.meridian) {
        _hour = _hour - LIMIT_TIMES.meridian;
        this.meridian = MERIDIANS.PM;
      } else {
        this.meridian = MERIDIANS.AM;
      }
    }

    this.form.controls['hour'].setValue(this.enableMeridian ? _hour : formatTwoDigitTimeValue(_hour));
    this.form.controls['minute'].setValue(formatTwoDigitTimeValue(_minute));
    this.form.controls['second'].setValue(formatTwoDigitTimeValue(_second));
  }

  /** Update model */
  private _updateModel() {
    if (isNil(this._model)) {
      this._model = moment() as D;
      if (isNil(this.hourControl.value)) {
        this.hourControl.patchValue(this.enableMeridian ? '0' : '00');
      } else {
        this.minuteControl.patchValue('00');
      }
      this.secondControl.patchValue('00');
    }

    let _hour = this.hour;
    if (this.enableMeridian && this.meridian === MERIDIANS.PM && _hour !== LIMIT_TIMES.meridian) {
      _hour = _hour + LIMIT_TIMES.meridian;
    }

    this._dateAdapter.setHour(this._model, _hour);
    this._dateAdapter.setMinute(this._model, this.minute);
    this._dateAdapter.setSecond(this._model, this.second);
    this._onTouched();
    if (this._autoCastMomentToString) {
      if (this.enableMeridian) {
        this._onChange(moment(`${_hour}:${this.minute} ${this.meridian}`, ['h:mm A']).format('HH:mm'));
      } else {
        this._onChange(this._model.format('HH:mm'));
      }
    } else {
      this._onChange(this._model);
    }
  }

  /**
   * Get next value by property
   * @param prop
   * @param up
   */
  private _getNextValueByProp(prop: string, up?: boolean): number {
    const keyProp = prop[0].toUpperCase() + prop.slice(1);
    const min = LIMIT_TIMES[`min${keyProp}`];
    let max = LIMIT_TIMES[`max${keyProp}`];

    if (prop === 'hour' && this.enableMeridian) {
      max = LIMIT_TIMES.meridian;
    }

    let next;
    if (up == null) {
      next = this[prop] % max;
    } else {
      next = up ? this[prop] + this[`step${keyProp}`] : this[prop] - this[`step${keyProp}`];
      if (prop === 'hour' && this.enableMeridian) {
        next = next % (max + 1);
        if (next === 0) next = up ? 1 : max;
      } else {
        next = next % max;
      }
      if (up) {
        next = next > max ? next - max + min : next;
      } else {
        next = next < min ? next - min + max : next;
      }
    }

    return next;
  }

  private getSettedMomentHoursAndMinutesFromString(hoursAndMinutes: string): D {
    const [hour, minute] = hoursAndMinutes.split(':');
    return moment().set({ hour: +hour, minute: +minute, second: 0 }) as D;
  }
}
