import { isNil } from '@roadrecord/type-guard';
import { ValidationMessageModel } from '@roadrecord/validating';
import { LiveAutocompleDisplayFunction } from '../function-type/function/live-autocomple-display.function';
import { LiveAutocompleteOptionDisabledBindFunction } from '../function-type/function/live-autocomple-option-disabled-bind.function';
import { LiveAutocompleteOptionDisplayBindFunction } from '../function-type/function/live-autocomple-option-display-bind.function';
import { LiveAutocompleteOptionValueBindFunction } from '../function-type/function/live-autocomple-option-value-bind.function';
import { LiveAutocompleteOptionModelIconFunction } from '../function-type/function/live-autocomplete-option-model-icon.function';
import { LiveAutocompletePrefixIconFunction } from '../function-type/function/live-autocomplete-prefix-icon.function';
import { LiveAutoCompleteNewButtonOptionModel } from './live-auto-complete-new-button-option.model';
import { LiveAutoCompleteLazyOptionElemConfig } from './live-auto-complete-lazy-option-elem.config';
import { LiveAutoCompleteEditButtonOptionModel } from './live-auto-complete-edit-button-option.model';

const cache: { [key: string]: LiveAutoCompleteOptionsConfigModel<unknown> } = {};

export type liveAutoCompleteDiFactoryReturnType<E> = () => LiveAutoCompleteOptionsConfigModel<E>;

export function liveAutoCompleteCommonDiFactory(key: string, generateFn: () => LiveAutoCompleteOptionsConfigModel<unknown>) {
  return () => {
    if (cache[key] !== undefined) {
      return cache[key];
    }
    const cacheValue = generateFn();
    cache[key] = cacheValue;
    return cacheValue;
  };
}

export interface LiveAutoCompleteOptionsConfigModelInterface<MODEL> {
  /**
   * mikor legyen disable a talalati lista elem
   */
  readonly optionDisabledBindFn: LiveAutocompleteOptionDisabledBindFunction<MODEL>;
  /**
   * hogy nezzen ki a talalati lista elem
   */
  readonly optionDisplayFn: LiveAutocompleteOptionDisplayBindFunction<MODEL>;
  /**
   * milyen erteket taroljunk el a controlban a talalati lista elem kivalasztasakor
   */
  readonly optionValueBindFn: LiveAutocompleteOptionValueBindFunction<MODEL>;
  /**
   * talalati lista elem icon
   */
  readonly optionModelIconFn: LiveAutocompleteOptionModelIconFunction<MODEL>;
  /**
   * kivalasztott elem hogy nezzen ki az inputban
   */
  readonly displayFn: LiveAutocompleDisplayFunction<MODEL>;
  readonly prefixIconFn: LiveAutocompletePrefixIconFunction<MODEL>;
  readonly newOptions?: LiveAutoCompleteNewButtonOptionModel;
  readonly editOptions?: LiveAutoCompleteEditButtonOptionModel<MODEL>;
  readonly validatorMessages?: ValidationMessageModel[];
  readonly label?: string;
  readonly placeholder?: string;
  readonly lazyOptionElem?: LiveAutoCompleteLazyOptionElemConfig;
}

export class LiveAutoCompleteOptionsConfigModel<MODEL> implements LiveAutoCompleteOptionsConfigModelInterface<MODEL> {
  readonly optionDisabledBindFn: LiveAutocompleteOptionDisabledBindFunction<MODEL>;
  readonly optionDisplayFn: LiveAutocompleteOptionDisplayBindFunction<MODEL>;
  readonly optionValueBindFn: LiveAutocompleteOptionValueBindFunction<MODEL>;
  readonly optionModelIconFn: LiveAutocompleteOptionModelIconFunction<MODEL>;
  readonly displayFn: LiveAutocompleDisplayFunction<MODEL>;
  readonly prefixIconFn: LiveAutocompletePrefixIconFunction<MODEL>;
  readonly newOptions?: LiveAutoCompleteNewButtonOptionModel;
  readonly editOptions?: LiveAutoCompleteEditButtonOptionModel<MODEL>;
  readonly validatorMessages?: ValidationMessageModel[];
  readonly label?: string;
  readonly placeholder?: string;
  readonly lazyOptionElem?: LiveAutoCompleteLazyOptionElemConfig;

  constructor(_this: LiveAutoCompleteOptionsConfigModelInterface<MODEL>) {
    if (isNil(_this) || Object.keys(_this).length === 0) {
      throw new Error('Not found options');
    }
    if (_this.newOptions !== undefined && !(_this.newOptions instanceof LiveAutoCompleteNewButtonOptionModel)) {
      throw new Error('New options type missing LiveAutoCompleteNewButtonOptionModel');
    }
    Object.assign(this, _this);
  }
}
