import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPanelComponent } from './filter-panel/filter-panel.component';
import { RecommendationCheckComponent } from './recommendation-check.component';
import { RecommendationCheckListFilterPipe } from './recommendation-check-list-filter.pipe';
import { NgOverrideModule } from '@roadrecord/ng-override';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { PreventClickModule, TextPlaceholderHtmlTagReplacerModule } from '@roadrecord/common/common';
import { GetLabelByCurrentLangPipeModule } from '@roadrecord/report-print/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [FilterPanelComponent, RecommendationCheckComponent, RecommendationCheckListFilterPipe],
  exports: [RecommendationCheckComponent],
  imports: [
    CommonModule,
    NgOverrideModule,
    MatButtonModule,
    MatTooltipModule,
    TranslocoModule,
    TextPlaceholderHtmlTagReplacerModule,
    GetLabelByCurrentLangPipeModule,
    MatExpansionModule,
    FlexLayoutModule,
    PreventClickModule,
  ],
})
export class RecommendationCheckModule {}
