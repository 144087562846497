import { PreferencesContentWindowTypeEnum } from '../model/preferences-content-window-type.enum';

/**
 * ha modositas van akkor a type-ra ra kell keresni mert circular dep miatt nem az osztaly-t hasznaljuk par helyen
 */
export class PreferencesCloseContentWindowAction {
  static readonly type = '[Preferences] Close content window';

  constructor(readonly windowType: PreferencesContentWindowTypeEnum) {}
}
