import { Injectable } from '@angular/core';
import { AbstractEntityService, HttpListResponseModel } from '@roadrecord/utils';
import { HttpClient } from '@angular/common/http';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { VehicleAccountListModel } from './model/vehicle-account-list.model';
import { map } from 'rxjs/operators';
import { isNil } from '@roadrecord/type-guard';

@Injectable()
export class VehicleAccountService extends AbstractEntityService<HttpListResponseModel<VehicleAccountListModel>, VehicleAccountListModel> {
  constructor(http: HttpClient) {
    super('vehicle/account-binding', 'full_name', http);
  }

  getModelIdValue(model: VehicleAccountListModel): any {
    return model.account_id;
  }

  getToString(model: VehicleAccountListModel): string {
    return model.email;
  }

  getAll(
    sort: Sort = { active: '', direction: 'asc' },
    page: PageEvent = {
      pageIndex: 1,
      pageSize: 99999999,
      length: 1,
    },
    simpleAllFilterValue: string = '',
    userId?: number
  ): Observable<HttpListResponseModel<VehicleAccountListModel>> {
    return this.http
      .get<HttpListResponseModel<VehicleAccountListModel>>(
        this.getListOrResourceUrl(isNil(userId) ? 'new' : userId, this.getUrlParameters(sort, page, simpleAllFilterValue))
      )
      .pipe(
        map(response => {
          response.results = response.results.map(entry => {
            entry.originalStatus = entry.status;
            if (entry.role === 1 /* circular dep CompanyMemberRoleEnum.OWNER*/) {
              entry.is_editable = false;
              entry.is_deletable = false;
            }
            return entry;
          });
          return response;
        })
      );
  }
}
