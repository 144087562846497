import { FormGroup } from '@angular/forms';

export class RRFormGroup extends FormGroup {
  submitted = false;

  reset(value?: any, options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    this.submitted = false;
    super.reset(value, options);
  }
}
