import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuelTypeModelByIdPipe } from './fuel-type-model-by-id.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [FuelTypeModelByIdPipe],
  exports: [FuelTypeModelByIdPipe],
})
export class FuelTypeModelByIdPipeModule {}
