import { ComponentPortal } from '@angular/cdk/portal';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { checkPropertyChange } from '@roadrecord/common/common';
import { isNil } from '@roadrecord/type-guard';
import { MenuService } from '../menu.service';
import { MenuItemModel } from '../model/menu-item.model';

export const trackByFn = (index, item: MenuItemModel) => {
  return item.route;
};

@Component({
  selector: 'rr-menu-page',
  templateUrl: './menu-page.component.html',
  styleUrls: ['./menu-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    // trigger('fadeInEnter', [
    //   transition(
    //     ':enter',
    //     useAnimation(fadeInRight, {
    //       params: { timing: 0.15, delay: 0 },
    //     })
    //   ),
    // ]),
  ],
})
export class MenuPageComponent implements OnChanges, AfterViewChecked {
  @Input()
  readonly menuPageRoute: string;
  @Input()
  readonly items: MenuItemModel[];
  @Input()
  customBackButtonLabel;
  @Input()
  readonly customBackButtonLabelCallback: (menuService: MenuService) => void;
  @Input()
  readonly isClosed: boolean;
  @Output() closeMenu = new EventEmitter<(menuService: MenuService) => void>();
  @Output() selectMenuItem = new EventEmitter<MenuItemModel>();
  @Output()
  selectWithoutRoute = new EventEmitter<MenuItemModel>();
  @ViewChild('customArea')
  customArea: ElementRef<HTMLDivElement>;
  private lastCustomAreaHeight: number;
  customTemplateAreaComponentPortal: ComponentPortal<any>;
  trackByFn = trackByFn;
  @Input() beforeMenuPanelCardRef: ElementRef<any>;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  @Input()
  set customTemplateArea(value: any) {
    if (!isNil(value)) {
      this.customTemplateAreaComponentPortal = new ComponentPortal<any>(value);
    } else {
      this.customTemplateAreaComponentPortal = undefined;
    }
  }

  ngAfterViewChecked(): void {
    if (!isNil(this.customArea) && this.customArea.nativeElement.offsetHeight !== this.lastCustomAreaHeight) {
      this.cdr.markForCheck();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (checkPropertyChange('items', changes)) {
      this.cdr.detectChanges();
    }
    // default bezaras label beallitasa
    // if (
    //   changes.hasOwnProperty('customBackButtonLabel') &&
    //   (checkPropertyChange('customBackButtonLabel', changes) || changes['customBackButtonLabel'].isFirstChange())
    // ) {
    //   if (isNil(changes['customBackButtonLabel'].currentValue) || changes['customBackButtonLabel'].currentValue.length === 0) {
    //     this.customBackButtonLabel = 'APPLAYOUT.SIDENAV.CLOSE';
    //     this.cdr.markForCheck();
    //   }
    // }
  }

  onClose(): void {
    this.closeMenu.emit(this.customBackButtonLabelCallback);
  }

  onClickItem($event, item: MenuItemModel): void {
    $event.stopPropagation();
    this.selectMenuItem.emit(item);
  }

  getRouterLink(item: MenuItemModel): string[] {
    return !isNil(this.menuPageRoute) && this.menuPageRoute.length > 0
      ? ['/', this.menuPageRoute, ...item.route.split('?')[0].split('/')]
      : ['/', ...item.route.split('?')[0].split('/')];
  }

  // mukodik, csak a query param miatt nem jelolodik ki a menu
  /* [queryParams]="getRouterLinkQueryParams(item)" */
  // getRouterLinkQueryParams(item: MenuItemModel): Params | null {
  //   const route = item.route.split('?');
  //   if (route.length === 2) {
  //     return route[1].split('&').reduce((acc, next) => {
  //       const elem = next.split('=');
  //       acc[elem[0]] = elem[1];
  //       return acc;
  //     }, {});
  //   }
  //   return null;
  // }

  onClickRouterFnItem($event, item: MenuItemModel): void {
    $event.stopPropagation();
    this.selectWithoutRoute.emit(item);
  }

  calcScrollAreaHeight(): string {
    if (isNil(this.customArea)) {
      this.lastCustomAreaHeight = 0;
    } else {
      this.lastCustomAreaHeight = this.customArea.nativeElement.offsetHeight;
    }
    return `calc(100% - ${this.lastCustomAreaHeight}px)`;
  }
}
