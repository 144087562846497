import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CheckModifiedManualForm } from './check-modified-manual-form.class';
import { checkModifiedForm } from './function/check-modified-form.function';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { MessageDialogResultInterface } from '@roadrecord/message-dialog';
import { map, switchMap, take } from 'rxjs/operators';
import { HasPresenterSavePluginController } from '../controller/plugin/presenter-save/has-presenter-save-plugin.interface';
import { ViewSubmitPlugin } from '../controller/plugin/view-submit/view-submit.plugin';
import { isNil, isTrue } from '@roadrecord/type-guard';
import { CheckModifierCustomImplementationSubmittedAction } from './state/action/check-modifier-custom-implementation-submitted.action';

export interface CheckModifiedManualFormGuardOptions {
  /* ha nincs megadva akkor true */
  enableAfterSaveFirstButton?: boolean;
  /* ha nincs megadva akkor false, arra hasznaljuk hogy az egyedi layoutokon ahol nem RR MVP van azt jelezzuk */
  hasCustomSaveImplementation?: boolean;
  checkRules?: {
    /**
     * ebben az esetben nem vegzunk sanitizaciot, hanem egy az egyben hasonlitjuk ossze teljes melysegben
     * az original es form model-t
     */
    simpleDeepEqual: boolean;
  };
}

export const CHECK_MODIFIED_MANUAL_FORM_GUARD_OPTIONS = new InjectionToken<CheckModifiedManualFormGuardOptions>(
  'CHECK_MODIFIED_MANUAL_FORM_GUARD_OPTIONS'
);

@Injectable()
/**
 * Kezzel kezelt form -nal vizsgaljuk hogy valtozott-e a form ami nincs elmentve, ha igen
 * akkor figyelmeztetest dobunk es megadalyozzuk a route -rol valo tavozast
 */
export class CheckModifiedManualFormGuard implements CanDeactivate<any> {
  constructor(
    private store: Store,
    private actions$: Actions,
    @Optional() @Inject(CHECK_MODIFIED_MANUAL_FORM_GUARD_OPTIONS) private options: CheckModifiedManualFormGuardOptions
  ) {
    if (isNil(this.options)) {
      // set default
      this.options = { enableAfterSaveFirstButton: true };
    }
  }

  static check(
    component: CheckModifiedManualForm<any> & HasPresenterSavePluginController<any, any>,
    viewSubmitPluginId,
    options: CheckModifiedManualFormGuardOptions = { enableAfterSaveFirstButton: true }
  ): Observable<MessageDialogResultInterface> {
    return checkModifiedForm({
      formModel: component.formModel,
      originalModel: component.originalModel,
      routedDataFormCmpRef: component.dataForm,
      viewSubmitPluginId,
      options,
    });
  }

  canDeactivate(
    component: CheckModifiedManualForm<any> & HasPresenterSavePluginController<any, any>,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.store.selectSnapshot(states => states.menu).invalid_period_context !== 0 /*InvalidPeriodContextEnum.DISABLED*/) {
      // invalid period context mod eseten nem kezelunk semmit, mert hatterben redirecteljuk a usert
      return true;
    }
    if (!(component instanceof CheckModifiedManualForm)) {
      throw new Error('Component not extended check modified manual form class');
    }
    const viewSubmitPluginId = !isNil(component.presenterStateController)
      ? (component.presenterStateController.get(ViewSubmitPlugin) as ViewSubmitPlugin<any, any>).id
      : null;
    return CheckModifiedManualFormGuard.check(component, viewSubmitPluginId, this.options).pipe(
      switchMap(result => {
        if (
          result.result === false &&
          isTrue(result.afterOkFirst) &&
          !isNil(this.options) &&
          isTrue(this.options.hasCustomSaveImplementation)
        ) {
          // Ha nem RR MVP-t hasznalunk, akkor kulon jelezz vissza a layout a sikeres submitrol
          return this.actions$.pipe(
            ofActionSuccessful(CheckModifierCustomImplementationSubmittedAction),
            take(1),
            map((action: CheckModifierCustomImplementationSubmittedAction) => ({ result: action.success }))
          );
        }
        return of(result);
      }),
      map(result => {
        return result.result;
      })
    );
  }
}
