import { ChangeDetectorRef, Component, Inject, Injector, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { baseNotAccessUrl, MenuService } from '@roadrecord/app-layout/common';
import { deepEqual, MILEAGE_END_OF_MONTH, MILEAGE_START_OF_MONTH } from '@roadrecord/common/common';
import { commonHttpStreamErrorHandler, ENTITY_SERVICE_TOKEN } from '@roadrecord/utils';
import {
  EditColumnsConfigs,
  GridColumnModel,
  GridComponent,
  GridHeaderOtherAction,
  IconColumnDataModel,
  InitEditControlEvent,
  setIcon,
} from '@roadrecord/grid';
import { distinctUntilChanged, filter, map, skip, switchMap, take, tap } from 'rxjs/operators';
import { listColumnConfig } from '../model/list-column.config';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DocumentDataCheckerActionsEnum, isDocumentDataCheckerRouteStateModel } from '@roadrecord/document-data-checker-action/model';
import { fuelingRangeOpenWindowAction } from '@roadrecord/fueling-range/open-window-action';
import { LoadableService } from '@roadrecord/external-packages/ngx-loadable';
import { monthStatusListOpenWindowAction } from '../window-content/window-content.action';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { MILEAGE_DATE_FORMAT, MileageDateFormat } from '../mileage-date-format.token';
import { MILEAGE_RECORD_TYPE_ENUM, MileageListModel } from '@roadrecord/mileage/model';
import { Validators } from '@angular/forms';
import { MileageListService } from './mileage-list.service';
import { getEditCellNumberMaskConfig } from './get-edit-cell-number-mask.config';
import { UpdatedMileageListAction } from '@roadrecord/mileage/state';
import { FuelTypeEnum } from '@roadrecord/vehicle/model/common';
import { openMileageEditFormBottomSheet } from '../details/open-mileage-edit-form-bottom-sheet.function';
import { MileageService } from '../mileage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

export const setIconFn = (model: MileageListModel): IconColumnDataModel => {
  let iconName: string;
  switch (model.record_type) {
    case MILEAGE_RECORD_TYPE_ENUM.MONTHLY_STARTING_ODOMETER_READING:
      iconName = 'clock-start';
      break;
    case MILEAGE_RECORD_TYPE_ENUM.END_MONTH_ODOMETER_READING:
    case MILEAGE_RECORD_TYPE_ENUM.MONTHLY_CLOSING_ODOMETER_READING:
      iconName = 'clock-end';
      break;
    case MILEAGE_RECORD_TYPE_ENUM.FILLING:
      iconName = 'gas-station';
      break;
    case MILEAGE_RECORD_TYPE_ENUM.FIX_ODOMETER_READING:
      iconName = 'counter';
      break;
  }
  return { value: iconName };
};

const editCellTranslatePrefix = 'MILEAGE.LIST.EDIT.';

@UntilDestroy()
@Component({
  selector: 'rr-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [MileageListService, { provide: ENTITY_SERVICE_TOKEN, useExisting: MileageListService }, MileageService],
})
export class ListComponent implements OnInit {
  @Input() routingMode = true;
  @Input() hasFilter = false;
  displayedColumns: GridColumnModel<MileageListModel>[] = listColumnConfig(this);
  @ViewChild('rrGrid', { static: true })
  rrGrid: GridComponent;
  mileageType: string;
  setIconFn: setIcon = setIconFn;
  @Input() titleText = ['MILEAGE.LIST.TITLE.MILEAGE_OF_END_MONTH', 'MILEAGE.LIST.SELECTED_TITLE'];
  @Input() gridId: string;
  currentPeriodContext: any;
  editRowNewModel = {};
  readonly headerOtherActions: GridHeaderOtherAction<any>[] = [
    {
      type: 'ITEM',
      label: { translate: 'COMMON.ACTION.ADD' },
      icon: 'plus',
      action: () => {
        this.rrGrid.isLoadingResults = true;
        const layoutType = this.route.snapshot.data.startOfMonth === true ? 'START' : 'END';
        this.mileageService
          .getData(layoutType)
          .pipe(
            untilDestroyed(this),
            switchMap(data => {
              this.rrGrid.isLoadingResults = false;
              this.rrGrid.cdr.markForCheck();
              return openMileageEditFormBottomSheet(this.matBottomSheet, layoutType, data);
            })
          )
          .subscribe(
            () => this.rrGrid.refresh(),
            commonHttpStreamErrorHandler(() => (this.rrGrid.isLoadingResults = false))
          );
      },
    },
    {
      type: 'ITEM',
      icon: 'undo',
      label: { translate: 'MILEAGE.LIST.FAB.MONTH_STATUS' },
      route: ['status'],
      relativeRoute: true,
    },
  ];
  private editCellNumberMaskConfig = getEditCellNumberMaskConfig();
  editColumnsConfig: EditColumnsConfigs = {
    mileage: {
      name: 'mileage',
      inputType: 'TEXT',
      placeholder: `${editCellTranslatePrefix}MILEAGE`,
      validators: [Validators.required, Validators.min(1)],
      textMask: () => this.editCellNumberMaskConfig.mileage,
      suffix: 'COMMON.UNIT.KM',
    },
    cost: {
      name: 'cost',
      inputType: 'TEXT',
      placeholder: `${editCellTranslatePrefix}COST`,
      validators: [Validators.required, Validators.min(0)],
      textMask: () => this.editCellNumberMaskConfig.cost,
      suffix: 'CURRENCIES.DEFAULT',
    },
    quantity: {
      name: 'quantity',
      inputType: 'TEXT',
      placeholder: `${editCellTranslatePrefix}QUANTITY`,
      validators: [Validators.required, Validators.min(0.1)],
      textMask: () => this.editCellNumberMaskConfig.quantity,
      suffix: '',
    },
  };

  constructor(
    private route: ActivatedRoute,
    readonly translocoService: TranslocoService,
    private store: Store,
    private router: Router,
    private menuService: MenuService,
    private loadableService: LoadableService,
    private injector: Injector,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    private matSnackBar: MatSnackBar,
    @Inject(MILEAGE_DATE_FORMAT) readonly dateFormat: MileageDateFormat,
    private ngZone: NgZone,
    private actions$: Actions,
    private matBottomSheet: MatBottomSheet,
    private mileageService: MileageService,
    private cdr: ChangeDetectorRef
  ) {
    this.mileageType = this.route.snapshot.data.startOfMonth === true ? MILEAGE_START_OF_MONTH : MILEAGE_END_OF_MONTH;
    this.checkPeriodContextChange();

    // Ez akkor van amikor router es lebego ablakban is ez a lista van megnyitva
    this.actions$
      .pipe(
        ofActionSuccessful(UpdatedMileageListAction),
        untilDestroyed(this),
        filter(action => action.routedMode !== this.routingMode)
      )
      .subscribe(() => this.rrGrid.refresh());
  }

  hasActionRemoveDisableCb = (entity: MileageListModel) => entity.record_type === MILEAGE_RECORD_TYPE_ENUM.FILLING;

  ngOnInit(): void {
    if (this.routingMode) {
      this.route.paramMap
        .pipe(
          untilDestroyed(this),
          take(1),
          map(() => window.history.state),
          filter(state => isDocumentDataCheckerRouteStateModel(state))
        )
        .subscribe(state => {
          switch (state.action) {
            case DocumentDataCheckerActionsEnum.FLOATING_WINDOW__FUELING_RANGE:
              fuelingRangeOpenWindowAction(this.store, this.loadableService, this.injector, this.translocoService as any, this.matSnackBar);
              break;
            case DocumentDataCheckerActionsEnum.FLOATING_WINDOW__MILEAGE_LIST:
              monthStatusListOpenWindowAction(
                this.store,
                this.loadableService,
                this.injector,
                this.translocoService as any,
                this.matSnackBar
              );
              break;
          }
        });
    }
  }

  onModifiedRow() {
    if (this.ngZone.isStable) {
      this._onModifiedRow();
    } else {
      this.ngZone.onStable.pipe(untilDestroyed(this), take(1)).subscribe(this._onModifiedRow.bind(this));
    }
  }

  initEditControl({ name, control, model }: InitEditControlEvent<MileageListModel>) {
    /**
     * https://roadrecord.myjetbrains.com/youtrack/issue/RROHU-2744#focus=Comments-4-5246.0-0
     * 1 -es pont
     */
    if (model.record_type !== MILEAGE_RECORD_TYPE_ENUM.FILLING && ['cost', 'quantity'].indexOf(name) > -1) {
      control.disable();
    }

    this.editColumnsConfig.quantity.suffix = this.generateQuantityEditCellSuffix();
  }

  private checkPeriodContextChange(): void {
    this.store
      .select(this.periodContextStateSelectorsService.context)
      .pipe(
        tap(periodContext => (this.currentPeriodContext = periodContext)),
        untilDestroyed(this),
        skip(1),
        distinctUntilChanged((_old, _new) => deepEqual(_old, _new))
      )
      .subscribe(() => {
        this.rrGrid.isLoadingResults = true;
        this.menuService.hasMenuItemByUrl().subscribe(v => {
          if (v === true) {
            this.rrGrid.refresh();
          } else {
            // notAccessRedirect(this.injector);
            this.router.navigate([baseNotAccessUrl]);
          }
        });
      });
  }

  private _onModifiedRow() {
    this.store.dispatch(new UpdatedMileageListAction(this.routingMode));
  }

  private generateQuantityEditCellSuffix() {
    return `MILEAGE.LIST.COLUMN.${
      this.currentPeriodContext.vehicle.fuel_type === FuelTypeEnum.ELECTRIC ? 'QUANTITY_ELECTRIC' : 'QUANTITY'
    }.VALUE`;
  }
}
