import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { TranslocoService } from '@ngneat/transloco';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION } from '@roadrecord/dynamic-form';
import { DOCUMENT } from '@angular/common';
import {
  AbstractRRFormWithDynamicForm,
  BottomSheetRRFormWithDynamicForm,
  getBottomSheetComponentBase,
  RRFormWithDynamicFormBottomSheetConfig,
} from '@roadrecord/utils';
import { searchMissingFieldConfig } from '../details/search-missing-field.config';
import { VehicleHuModel } from '@roadrecord/vehicle/model/hu';

const _BASE = getBottomSheetComponentBase<VehicleHuModel, RealFuelBottomSheetComponent>();

@Component({
  selector: 'rr-real-fuel-bottom-sheet',
  /**
   * TODO jo lenne megoldani hogy ezt hasznalja mert ugyan az:
   * ./../../../../../../finalization-of-route/common/src/lib/average-fuel-bottom-sheet/average-fuel-bottom-sheet.component.html
   */
  templateUrl: './real-fuel-bottom-sheet.component.html',
  styleUrls: [
    /**
     * TODO jo lenne megoldani hogy ezt hasznalja mert ugyan az:
     * ./../../../../../../finalization-of-route/common/src/lib/average-fuel-bottom-sheet/average-fuel-bottom-sheet.component.scss
     */
    './real-fuel-bottom-sheet.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION,
      useValue: true,
    },
  ],
})
export class RealFuelBottomSheetComponent
  extends _BASE
  implements AbstractRRFormWithDynamicForm<VehicleHuModel>, BottomSheetRRFormWithDynamicForm<VehicleHuModel>, OnInit, AfterViewInit {
  constructor(
    bottomSheetRef: MatBottomSheetRef<RealFuelBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) data: RRFormWithDynamicFormBottomSheetConfig<VehicleHuModel>,
    @Inject(DOCUMENT) document: Document,
    translocoService: TranslocoService,
    messageDialogService: MessageDialogService,
    injector: Injector
  ) {
    super(injector, document, translocoService, messageDialogService, null, bottomSheetRef, data);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  setFormFields() {
    return searchMissingFieldConfig(this.bottomSheetData, this.injector);
  }
}
