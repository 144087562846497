<ng-container *ngIf="stroked === false; else strokedTpl">
  <div class="split-button-container" [ngClass]="{ 'split-button-hover': withoutSeparator }">
    <button
      type="button"
      mat-button
      [disabled]="disabled"
      [color]="color"
      class="split-button-left {{ buttonStyle }}"
      (click)="startWithFirstElement ? onClickAction($any(actions[0])) : groupActionsMenuButton._elementRef.nativeElement.click()"
    >
      {{
        (startWithFirstElement ? ($any(actions[0]).label.translate | transloco: ($any(actions[0]).label | getTranslateParams)) : label)
          | transloco
      }}
    </button>
    <button
      #groupActionsMenuButton
      type="button"
      mat-button
      [disabled]="disabled"
      [color]="color"
      class="split-button-right {{ buttonStyle }}"
      [matMenuTriggerFor]="menu"
    >
      <mat-icon svgIcon="chevron-down"></mat-icon>
    </button>
  </div>
</ng-container>
<ng-template #strokedTpl>
  <div class="split-button-container" [ngClass]="{ 'split-button-stroked-hover': withoutSeparator }">
    <button
      type="button"
      [disabled]="disabled"
      mat-raised-button
      [color]="color"
      class="split-button-left"
      (click)="startWithFirstElement ? onClickAction($any(actions[0])) : groupActionsMenuButton._elementRef.nativeElement.click()"
    >
      {{
        (startWithFirstElement ? ($any(actions[0]).label.translate | transloco: ($any(actions[0]).label | getTranslateParams)) : label)
          | transloco
      }}
    </button>
    <button
      #groupActionsMenuButton
      type="button"
      [disabled]="disabled"
      mat-raised-button
      [color]="color"
      class="split-button-right"
      [matMenuTriggerFor]="menu"
    >
      <mat-icon svgIcon="chevron-down"></mat-icon>
    </button>
  </div>
</ng-template>
<mat-menu #menu="matMenu" xPosition="before" classList="split-button-overlay">
  <ng-template matMenuContent>
    <ng-container *ngFor="let action of actions; trackBy: trackByFn">
      <ng-container *ngIf="action.type === 'ITEM'; else dividerTpl">
        <ng-container *ngIf="action.label.translate | transloco: (action.label | getTranslateParams) as label">
          <button mat-menu-item (click)="onClickAction(action)" [disabled]="data | isDisabled: action" [attr.id]="action.id">
            <mat-icon *ngIf="action.icon" [svgIcon]="action.icon"></mat-icon>
            <span>{{ label }}</span>
          </button></ng-container
        ></ng-container
      ><ng-template #dividerTpl><mat-divider></mat-divider></ng-template>
    </ng-container>
  </ng-template>
</mat-menu>
