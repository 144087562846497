import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { USER_LOGIN_PATH } from '@roadrecord/common/common';
import { Observable } from 'rxjs';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { AuthState } from '../../state/auth.state';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
  @SelectSnapshot(AuthState.isLoggedIn) readonly isLoggedIn: boolean;
  private readonly loginPath = `/${USER_LOGIN_PATH}`;

  constructor(private readonly router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check();
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check();
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    const check = this.check();
    if (check instanceof UrlTree) {
      return this.router.navigate([this.loginPath]).then(() => false);
    }
    return check;
  }

  check(): boolean | UrlTree {
    if (!this.isLoggedIn) {
      return this.router.createUrlTree([this.loginPath]);
    }
    return this.isLoggedIn;
  }
}
