import { produce } from '@ngxs-labs/immer-adapter';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BatchRequestService } from '@roadrecord/utils';
import { DATE_FORMAT, NoopFunction } from '@roadrecord/common/common';
import { isNil } from '@roadrecord/type-guard';
import moment from 'moment';
import { asapScheduler, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { FinalizationOfRouteService } from '../../finalization-of-route.service';
import { FinalizationOfRouteRouteModel } from '../../model/finalization-of-route-route.model';
import { FinalizationOfRouteSummaryModel } from '../../model/finalization-of-route-summary.model';
import { FinalizationOfRouteModel } from '../../model/finalization-of-route.model';
import { FinalizationOfRouteAddRowAfterAction } from './action/finalization-of-route-add-row-after.action';
import { FinalizationOfRouteAddRowBeforeAction } from './action/finalization-of-route-add-row-before.action';
import { FinalizationOfRouteCancelFailureAction } from './action/finalization-of-route-cancel-failure.action';
import { FinalizationOfRouteCancelSuccessAction } from './action/finalization-of-route-cancel-success.action';
import { FinalizationOfRouteCancelAction } from './action/finalization-of-route-cancel.action';
import { FinalizationOfRouteChangeSummaryAction } from './action/finalization-of-route-change-summary.action';
import { FinalizationOfRouteCheckModifiedFailureAction } from './action/finalization-of-route-check-modified-failure.action';
import { FinalizationOfRouteCheckModifiedSuccessAction } from './action/finalization-of-route-check-modified-success.action';
import { FinalizationOfRouteCheckModifiedAction } from './action/finalization-of-route-check-modified.action';
import { FinalizationOfRouteGetDataFailureAction } from './action/finalization-of-route-get-data-failure.action';
import { FinalizationOfRouteGetDataSuccessAction } from './action/finalization-of-route-get-data-success.action';
import { FinalizationOfRouteGetDataAction } from './action/finalization-of-route-get-data.action';
import { FinalizationOfRouteGetSummaryFailureAction } from './action/finalization-of-route-get-summary-failure.action';
import { FinalizationOfRouteGetSummarySuccessAction } from './action/finalization-of-route-get-summary-success.action';
import { FinalizationOfRouteInsertEmptyRowFailureAction } from './action/finalization-of-route-insert-empty-row-failure.action';
import { FinalizationOfRouteInsertEmptyRowSuccessAction } from './action/finalization-of-route-insert-empty-row-success.action';
import { FinalizationOfRouteInsertEmptyRowAction } from './action/finalization-of-route-insert-empty-row.action';
import { FinalizationOfRouteModifyRowFailureAction } from './action/finalization-of-route-modify-row-failure.action';
import { FinalizationOfRouteModifyRowNotModifiedAction } from './action/finalization-of-route-modify-row-not-modified.action';
import { FinalizationOfRouteModifyRowSuccessAction } from './action/finalization-of-route-modify-row-success.action';
import { FinalizationOfRouteModifyRowAction } from './action/finalization-of-route-modify-row.action';
import { FinalizationOfRouteMoveRowFailureAction } from './action/finalization-of-route-move-row-failure.action';
import { FinalizationOfRouteMoveRowSuccessAction } from './action/finalization-of-route-move-row-success.action';
import { FinalizationOfRouteMoveRowAction } from './action/finalization-of-route-move-row.action';
import { FinalizationOfRouteRemoveRowFailureAction } from './action/finalization-of-route-remove-row-failure.action';
import { FinalizationOfRouteRemoveRowSuccessAction } from './action/finalization-of-route-remove-row-success.action';
import { FinalizationOfRouteRemoveRowAction } from './action/finalization-of-route-remove-row.action';
import { FinalizationOfRouteResetStateAction } from './action/finalization-of-route-reset-state.action';
import { FinalizationOfRouteSaveFailureAction } from './action/finalization-of-route-save-failure.action';
import { FinalizationOfRouteSaveSuccessAction } from './action/finalization-of-route-save-success.action';
import { FinalizationOfRouteSaveAction } from './action/finalization-of-route-save.action';
import { FinalizationOfRouteSetDelegationTimeFailureAction } from './action/finalization-of-route-set-delegation-time-failure.action';
import { FinalizationOfRouteSetDelegationTimeSuccessAction } from './action/finalization-of-route-set-delegation-time-success.action';
import { FinalizationOfRouteSetDelegationTimeAction } from './action/finalization-of-route-set-delegation-time.action';
import { FinalizationOfRouteStartFailureAction } from './action/finalization-of-route-start-failure.action';
import { FinalizationOfRouteStartSuccessAction } from './action/finalization-of-route-start-success.action';
import { FinalizationOfRouteStepDayAction } from './action/finalization-of-route-step-day.action';
import { FinalizationOfRouteStepNextDayAction } from './action/finalization-of-route-step-next-day.action';
import { FinalizationOfRouteStepPreviousDayAction } from './action/finalization-of-route-step-previous-day.action';
import { FinalizationOfRouteStateModel } from './model/finalization-of-route-state.model';
import { AbstractPeriodContextStateModel, PERIOD_CONTEXT_STATE_NAME } from '@roadrecord/period-context/common';
import { isCompanyVehicle } from '@roadrecord/preferences-state/common';
import { Injectable } from '@angular/core';
import { StartResponse } from './model/start.response';
import { FinalizationOfRouteStepSkipPreviousDayAction } from './action/finalization-of-route-step-skip-previous-day.action';
import { FinalizationOfRouteStepSkipNextDayAction } from './action/finalization-of-route-step-skip-next-day.action';
import { FinalizationOfRouteDayTypeEnum } from '../../model/finalization-of-route-day-type.enum';
import { FinalizationOfRouteRefreshSummaryAction } from './action/finalization-of-route-refresh-summary.action';
import { AppTypeEnum, environment } from '@roadrecord/environment';
import { FinalizationOfRouteRemoveAllAction } from './action/finalization-of-route-remove-all.action';
import { FinalizationOfRouteRemoveAllFailureAction } from './action/finalization-of-route-remove-all-failure.action';
import { FinalizationOfRouteRemoveAllSuccessAction } from './action/finalization-of-route-remove-all-success.action';
import { FinalizationOfRouteFlightTripRowAction } from './action/finalization-of-route-flight-trip-row.action';
import { FinalizationOfRouteFlightTripRowSuccessAction } from './action/finalization-of-route-flight-trip-row.success.action';
import { FinalizationOfRouteFlightTripRowFailureAction } from './action/finalization-of-route-flight-trip-row.failure.action';

/**
 * finalization introjs config-ba at kellett masolni cirtual dep miatt, ha ez valtozik ott is at kell irni!
 */
export const finalizationOfRouteStateDefault: FinalizationOfRouteStateModel = {
  loading: true,
  selectedDay: undefined,
  stepDay: undefined,
  data: undefined,
  summary: undefined,
  selectedSummaryIndex: 0,
  saveSnackBar: undefined,
  dayStepperOptions: undefined,
  version: 3,
};

@State<FinalizationOfRouteStateModel>({
  name: 'finalizationOfRouteDetailsPage',
  defaults: finalizationOfRouteStateDefault,
})
@Injectable()
export class DetailsState {
  constructor(private finalizationOfRouteService: FinalizationOfRouteService, private batchRequestService: BatchRequestService) {}

  @Selector()
  static loading(state: FinalizationOfRouteStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static delegationTime(state: FinalizationOfRouteStateModel): { start: string; end: string } {
    return state.data.delegation_time;
  }

  @Selector()
  static isHoliday(state: FinalizationOfRouteStateModel): boolean {
    return (
      state.data.selected_day_type === FinalizationOfRouteDayTypeEnum.CALENDAR_DAY_VIEW_TYPE_HOLIDAY ||
      state.data.selected_day_type === FinalizationOfRouteDayTypeEnum.CALENDAR_DAY_VIEW_TYPE_HOLIDAY_INSTEAD_OF_WORKDAY
    );
  }

  @Selector()
  static selectedDay(state: FinalizationOfRouteStateModel): Date {
    return state.selectedDay;
  }

  @Selector()
  static selectedDayText(state: FinalizationOfRouteStateModel): string {
    return moment(state.selectedDay).format(DATE_FORMAT);
  }

  @Selector()
  static stepDay(state: FinalizationOfRouteStateModel): number {
    return state.stepDay;
  }

  @Selector()
  static selectedDayType(state: FinalizationOfRouteStateModel): number {
    return state.data.selected_day_type;
  }

  @Selector()
  static routes(state: FinalizationOfRouteStateModel): FinalizationOfRouteRouteModel[] {
    return state.data.routes;
  }

  @Selector()
  static nextRoute(state: FinalizationOfRouteStateModel): string {
    return state.nextRoute;
  }

  @Selector()
  static summary(state: FinalizationOfRouteStateModel): FinalizationOfRouteSummaryModel[] {
    return state.summary;
  }

  @Selector([states => states[PERIOD_CONTEXT_STATE_NAME]])
  static selectedSummary(
    state: FinalizationOfRouteStateModel,
    periodContextState: AbstractPeriodContextStateModel<any>
  ): FinalizationOfRouteSummaryModel {
    const summary = !isCompanyVehicle(periodContextState.model)
      ? state.summary[0]
      : state.summary.find(_summary => _summary.fueling_range_index === state.selectedSummaryIndex);
    return !isNil(summary) ? summary : state.summary[0];
  }

  @Selector()
  static dayStepperOptions(state: FinalizationOfRouteStateModel): StartResponse {
    return state.dayStepperOptions;
  }

  @Action([
    FinalizationOfRouteInsertEmptyRowAction,
    FinalizationOfRouteGetDataAction,
    FinalizationOfRouteRemoveRowAction,
    FinalizationOfRouteCancelAction,
    FinalizationOfRouteSaveAction,
    FinalizationOfRouteRemoveRowAction,
    FinalizationOfRouteMoveRowAction,
    FinalizationOfRouteModifyRowAction,
    FinalizationOfRouteSetDelegationTimeAction,
    FinalizationOfRouteFlightTripRowAction,
  ])
  loading(ctx: StateContext<FinalizationOfRouteStateModel>): void {
    produce(ctx, draft => {
      draft.loading = true;
    });
  }

  @Action([
    FinalizationOfRouteGetDataSuccessAction,
    FinalizationOfRouteGetDataFailureAction,
    FinalizationOfRouteModifyRowNotModifiedAction,
    FinalizationOfRouteSetDelegationTimeFailureAction,
    FinalizationOfRouteSetDelegationTimeSuccessAction,
    FinalizationOfRouteSaveFailureAction,
    FinalizationOfRouteCancelFailureAction,
    FinalizationOfRouteStartFailureAction,
    FinalizationOfRouteFlightTripRowSuccessAction,
    FinalizationOfRouteSetDelegationTimeFailureAction,
  ])
  stopLoading(ctx: StateContext<FinalizationOfRouteStateModel>): void {
    produce(ctx, draft => {
      draft.loading = false;
    });
  }

  @Action(FinalizationOfRouteSaveAction)
  save(ctx: StateContext<FinalizationOfRouteStateModel>): Observable<any> {
    const state = ctx.getState();
    const dateText = DetailsState.selectedDayText(state);
    const dispatch = ctx.dispatch;

    const operators: any[] = [
      tap((response?: { snackbar: string }) => asapScheduler.schedule(() => dispatch(new FinalizationOfRouteSaveSuccessAction(response)))),
    ];

    operators.push(
      catchError(error => {
        asapScheduler.schedule(() => dispatch(new FinalizationOfRouteSaveFailureAction(error)));
        return of(error);
      })
    );
    return (this.finalizationOfRouteService.save(dateText) as any).pipe(...operators);
  }

  @Action(FinalizationOfRouteCheckModifiedAction)
  finalizationOfRouteCheckModified(ctx: StateContext<FinalizationOfRouteStateModel>, action: FinalizationOfRouteCheckModifiedAction): any {
    ctx.patchState({ nextRoute: action.url });
    const dispatch = ctx.dispatch;
    return this.finalizationOfRouteService.checkModified(DetailsState.selectedDayText(ctx.getState())).pipe(
      tap(response => asapScheduler.schedule(() => dispatch(new FinalizationOfRouteCheckModifiedSuccessAction(response)))),
      catchError(error => {
        asapScheduler.schedule(() => dispatch(new FinalizationOfRouteCheckModifiedFailureAction(error)));
        return of(error);
      })
    );
  }

  @Action(FinalizationOfRouteCancelAction)
  cancel(ctx: StateContext<FinalizationOfRouteStateModel>): any {
    return (this.finalizationOfRouteService.cancel(DetailsState.selectedDayText(ctx.getState())) as any).pipe(
      ...this._getCancelOperators(ctx)
    );
  }

  private _getCancelOperators(ctx: StateContext<FinalizationOfRouteStateModel>): any[] {
    const dispatch = ctx.dispatch;
    return [
      tap(() => asapScheduler.schedule(() => dispatch(new FinalizationOfRouteCancelSuccessAction()))),
      catchError(error => {
        asapScheduler.schedule(() => dispatch(new FinalizationOfRouteCancelFailureAction(error)));
        return of(error);
      }),
    ];
  }

  @Action(FinalizationOfRouteResetStateAction)
  resetState(ctx: StateContext<FinalizationOfRouteStateModel>, action: FinalizationOfRouteResetStateAction): void {
    produce(ctx, () => action.state);
  }

  @Action(FinalizationOfRouteStepDayAction)
  stepDay(ctx: StateContext<FinalizationOfRouteStateModel>, action: FinalizationOfRouteStepDayAction): void {
    this._stepDay(ctx, action.stepDay);
  }

  @Action(FinalizationOfRouteStepNextDayAction)
  stepNextDay(ctx: StateContext<FinalizationOfRouteStateModel>): void {
    this._stepDay(ctx, 1);
  }

  @Action(FinalizationOfRouteStepPreviousDayAction)
  stepPreviousDay(ctx: StateContext<FinalizationOfRouteStateModel>): void {
    this._stepDay(ctx, -1);
  }

  @Action(FinalizationOfRouteStepSkipPreviousDayAction)
  stepSkipPreviousDay(ctx: StateContext<FinalizationOfRouteStateModel>): void {
    const state = ctx.getState();
    this._stepDay(ctx, moment(state.selectedDay).diff(moment(state.dayStepperOptions.options.day_has_routes.prev), 'days') * -1);
  }

  @Action(FinalizationOfRouteStepSkipNextDayAction)
  stepSkipNextDay(ctx: StateContext<FinalizationOfRouteStateModel>): void {
    const state = ctx.getState();
    this._stepDay(ctx, moment(state.selectedDay).diff(moment(state.dayStepperOptions.options.day_has_routes.next), 'days') * -1);
  }

  private _stepDay(ctx: StateContext<FinalizationOfRouteStateModel>, stepDay: number): void {
    const isChanged = true;
    if (isChanged) {
      ctx.dispatch(new FinalizationOfRouteSaveAction());
    }
    produce(ctx, draft => {
      draft.stepDay = stepDay;
    });
  }

  @Action(FinalizationOfRouteSaveSuccessAction)
  maybeStepDay(ctx: StateContext<FinalizationOfRouteStateModel>, action: FinalizationOfRouteSaveSuccessAction): void {
    if (!isNil(action.response)) {
      produce(ctx, draft => {
        draft.saveSnackBar = action.response.snackbar;
      });
    }
  }

  /**
   * layout adatok betoltese
   */
  @Action(FinalizationOfRouteGetDataAction)
  getData(ctx: StateContext<FinalizationOfRouteStateModel>): Observable<any> {
    const dateText = DetailsState.selectedDayText(ctx.getState());
    this.batchRequestService.start();
    this.batchGetData(dateText, ctx);
    const dispatch = ctx.dispatch;

    return this.finalizationOfRouteService.start(dateText).pipe(
      tap(response => asapScheduler.schedule(() => dispatch(new FinalizationOfRouteStartSuccessAction(response)))),
      catchError(error => {
        asapScheduler.schedule(() => dispatch(new FinalizationOfRouteStartFailureAction(error)));
        return throwError(error);
      }),
      switchMap(() => this.batchRequestService.end())
    );
  }

  @Action(FinalizationOfRouteStartSuccessAction)
  finalizationOfRouteStartSuccess(ctx: StateContext<FinalizationOfRouteStateModel>, action: FinalizationOfRouteStartSuccessAction) {
    produce(ctx, draft => {
      draft.dayStepperOptions = action.response;
    });
  }

  @Action(FinalizationOfRouteGetDataSuccessAction)
  getDataSuccess(ctx: StateContext<FinalizationOfRouteStateModel>, action: FinalizationOfRouteGetDataSuccessAction): void {
    produce(ctx, draft => {
      if (environment.appType === AppTypeEnum.US) {
        action.data.routes = action.data.routes.map(route => {
          if (!isNil(route.time)) {
            const originalTime = route.time;
            Object.defineProperty(route, 'originalTime', {
              enumerable: false,
              writable: false,
              configurable: false,
              value: originalTime,
            });
            route.time = moment(originalTime, 'HH:mm').format('h:mm A');
          }
          return route;
        });
      }
      draft.data = action.data;
    });
  }

  @Action(FinalizationOfRouteAddRowBeforeAction)
  addRowBefore(ctx: StateContext<FinalizationOfRouteStateModel>, action: FinalizationOfRouteAddRowBeforeAction): void {
    this._insertRow(ctx, action.rowIndex);
  }

  @Action(FinalizationOfRouteAddRowAfterAction)
  addRowAfter(ctx: StateContext<FinalizationOfRouteStateModel>, action: FinalizationOfRouteAddRowAfterAction): void {
    this._insertRow(ctx, action.rowIndex, 1);
  }

  private _insertRow(ctx: StateContext<FinalizationOfRouteStateModel>, rowIndex: number, shift = 0): void {
    const state = ctx.getState();
    const routes = DetailsState.routes(state);
    let remoteRowIndex = routes[rowIndex].row_index + shift;
    const dispatch = ctx.dispatch;

    if (remoteRowIndex < 0) {
      remoteRowIndex = 0;
    }

    asapScheduler.schedule(() => dispatch(new FinalizationOfRouteInsertEmptyRowAction(remoteRowIndex)));
  }

  @Action(FinalizationOfRouteInsertEmptyRowAction)
  insertRow(ctx: StateContext<FinalizationOfRouteStateModel>, action: FinalizationOfRouteInsertEmptyRowAction): any {
    const dateText = DetailsState.selectedDayText(ctx.getState());
    const dispatch = ctx.dispatch;

    this.batchRequestService.start();
    this.batchRequestService
      .add(this.finalizationOfRouteService.insertEmptyRowBatch(dateText, action.rowIndex))
      .pipe(
        tap(() => asapScheduler.schedule(() => dispatch(new FinalizationOfRouteInsertEmptyRowSuccessAction()))),
        catchError(error => {
          asapScheduler.schedule(() => dispatch(new FinalizationOfRouteInsertEmptyRowFailureAction(error)));
          return of(error);
        })
      )
      .subscribe(NoopFunction);
    this.batchGetData(dateText, ctx, false);
    return this.batchRequestService.end();
  }

  private batchGetData(dateText, ctx: StateContext<FinalizationOfRouteStateModel>, getSummary = true): void {
    const dispatch = ctx.dispatch;

    (this.batchRequestService.add(this.finalizationOfRouteService.getBatch(dateText)) as any)
      .pipe(
        ...this.finalizationOfRouteService.getBatchOperators(),
        tap((data: FinalizationOfRouteModel) => asapScheduler.schedule(() => dispatch(new FinalizationOfRouteGetDataSuccessAction(data)))),
        catchError(error => {
          asapScheduler.schedule(() => dispatch(new FinalizationOfRouteGetDataFailureAction(error)));
          return of(error);
        })
      )
      .subscribe(NoopFunction);
    if (getSummary) {
      this.batchRequestService
        .add(this.finalizationOfRouteService.getSummaryBatch(dateText))
        .pipe(
          tap((data: FinalizationOfRouteSummaryModel[]) =>
            asapScheduler.schedule(() => dispatch(new FinalizationOfRouteGetSummarySuccessAction(data)))
          ),
          catchError(error => {
            asapScheduler.schedule(() => dispatch(new FinalizationOfRouteGetSummaryFailureAction(error)));
            return of(error);
          })
        )
        .subscribe(NoopFunction);
    }
  }

  @Action(FinalizationOfRouteRefreshSummaryAction)
  refreshSummaryAction(ctx: StateContext<FinalizationOfRouteStateModel>) {
    const dateText = DetailsState.selectedDayText(ctx.getState());
    produce(ctx, draft => {
      draft.loading = true;
    });

    return this.finalizationOfRouteService.getSummary(dateText).pipe(
      tap((data: FinalizationOfRouteSummaryModel[]) => {
        produce(ctx, draft => {
          draft.loading = false;
        });
        asapScheduler.schedule(() => ctx.dispatch(new FinalizationOfRouteGetSummarySuccessAction(data)));
      }),
      catchError(error => {
        produce(ctx, draft => {
          draft.loading = false;
        });
        asapScheduler.schedule(() => ctx.dispatch(new FinalizationOfRouteGetSummaryFailureAction(error)));
        return of(error);
      })
    );
  }

  @Action(FinalizationOfRouteRemoveAllAction)
  deleteAllTripOnDay(ctx: StateContext<FinalizationOfRouteStateModel>) {
    const dispatch = ctx.dispatch;
    const selectedDayText = DetailsState.selectedDayText(ctx.getState());

    this.batchRequestService.start();
    this.batchRequestService
      .add(this.finalizationOfRouteService.deleteAllRowBatch(selectedDayText))
      .pipe(
        tap(() => asapScheduler.schedule(() => dispatch(new FinalizationOfRouteRemoveAllSuccessAction()))),
        catchError(error => {
          asapScheduler.schedule(() => dispatch(new FinalizationOfRouteRemoveAllFailureAction(error)));
          return of(error);
        })
      )
      .subscribe(NoopFunction);
    return this.batchRequestService.end().pipe(
      tap(() =>
        dispatch([
          new FinalizationOfRouteResetStateAction({
            autoFill: true,
            selectedDay: selectedDayText,
          }),
          new FinalizationOfRouteGetDataAction(),
        ])
      )
    );
  }

  @Action(FinalizationOfRouteRemoveRowAction)
  removeRow(ctx: StateContext<FinalizationOfRouteStateModel>, action: FinalizationOfRouteRemoveRowAction): any {
    const dateText = DetailsState.selectedDayText(ctx.getState());
    const dispatch = ctx.dispatch;

    this.batchRequestService.start();
    this.batchRequestService
      .add(this.finalizationOfRouteService.removeRowBatch(dateText, action.rowIndex))
      .pipe(
        tap(() => asapScheduler.schedule(() => dispatch(new FinalizationOfRouteRemoveRowSuccessAction()))),
        catchError(error => {
          asapScheduler.schedule(() => dispatch(new FinalizationOfRouteRemoveRowFailureAction(error)));
          return of(error);
        })
      )
      .subscribe(NoopFunction);
    this.batchGetData(dateText, ctx);
    return this.batchRequestService.end();
  }

  @Action(FinalizationOfRouteMoveRowAction)
  moveRow(ctx: StateContext<FinalizationOfRouteStateModel>, action: FinalizationOfRouteMoveRowAction): any {
    const dateText = DetailsState.selectedDayText(ctx.getState());
    const dispatch = ctx.dispatch;

    this.batchRequestService.start();
    this.batchRequestService
      .add(this.finalizationOfRouteService.moveRowBatch(dateText, action.oldIndex, action.newIndex))
      .pipe(
        tap(() => asapScheduler.schedule(() => dispatch(new FinalizationOfRouteMoveRowSuccessAction()))),
        catchError(error => {
          asapScheduler.schedule(() => dispatch(new FinalizationOfRouteMoveRowFailureAction(error)));
          return of(error);
        })
      )
      .subscribe(NoopFunction);
    this.batchGetData(dateText, ctx);
    return this.batchRequestService.end();
  }

  @Action(FinalizationOfRouteModifyRowAction)
  modifyRow(ctx: StateContext<FinalizationOfRouteStateModel>, action: FinalizationOfRouteModifyRowAction): any {
    const state = ctx.getState();
    const modifyEventData = action.data;

    if (!isNil(modifyEventData.mileage) && modifyEventData.mileage === 0) {
      // https://roadrecord.myjetbrains.com/youtrack/issue/RROHU-2554
      modifyEventData.mileage = null;
    }

    const dateText = DetailsState.selectedDayText(state);
    const dispatch = ctx.dispatch;

    this.batchRequestService.start();
    this.batchRequestService
      .add(this.finalizationOfRouteService.modifyRowBatch(dateText, action.rowIndex, modifyEventData))
      .pipe(
        tap(() => asapScheduler.schedule(() => dispatch(new FinalizationOfRouteModifyRowSuccessAction()))),
        catchError(error => {
          asapScheduler.schedule(() => dispatch(new FinalizationOfRouteModifyRowFailureAction(error)));
          return of(error);
        })
      )
      .subscribe(NoopFunction);
    this.batchGetData(dateText, ctx);
    return this.batchRequestService.end();
  }

  @Action(FinalizationOfRouteGetSummarySuccessAction)
  getSummarySuccess(ctx: StateContext<FinalizationOfRouteStateModel>, action: FinalizationOfRouteGetSummarySuccessAction): void {
    produce(ctx, draft => {
      draft.summary = action.summary;
    });
  }

  @Action(FinalizationOfRouteSetDelegationTimeAction)
  setDelegationTime(
    { getState, dispatch }: StateContext<FinalizationOfRouteStateModel>,
    action: FinalizationOfRouteSetDelegationTimeAction
  ): any {
    return this.finalizationOfRouteService.setDelegationTime(DetailsState.selectedDayText(getState()), action.start, action.end).pipe(
      tap(() => asapScheduler.schedule(() => dispatch(new FinalizationOfRouteSetDelegationTimeSuccessAction()))),
      catchError(error => {
        asapScheduler.schedule(() => dispatch(new FinalizationOfRouteSetDelegationTimeFailureAction(error)));
        return of(error);
      })
    );
  }

  @Action(FinalizationOfRouteChangeSummaryAction)
  changeSummary(ctx: StateContext<FinalizationOfRouteStateModel>, action: FinalizationOfRouteChangeSummaryAction): void {
    produce(ctx, draft => {
      draft.selectedSummaryIndex = action.index;
    });
  }
  @Action(FinalizationOfRouteFlightTripRowAction)
  flightTrip(ctx: StateContext<FinalizationOfRouteStateModel>, action: FinalizationOfRouteFlightTripRowAction): any {
    const dispatch = ctx.dispatch;
    const dateText = DetailsState.selectedDayText(ctx.getState());

    this.batchRequestService.start();
    this.batchRequestService
      .add(this.finalizationOfRouteService.flightTripBatch(dateText, action.rowIndex))
      .pipe(
        tap(() => asapScheduler.schedule(() => dispatch(new FinalizationOfRouteFlightTripRowSuccessAction()))),
        catchError(error => {
          asapScheduler.schedule(() => dispatch(new FinalizationOfRouteFlightTripRowFailureAction(error)));
          return of(error);
        })
      )
      .subscribe(NoopFunction);
    this.batchGetData(dateText, ctx);
    return this.batchRequestService.end();
  }
}
