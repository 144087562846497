import { FormControl, FormGroup, Validators } from '@angular/forms';

/**
 * Unit test miatt van szukseg a kiemelesre
 */
export const createForm = () =>
  new FormGroup({
    file: new FormControl(null, Validators.required),
    started: new FormControl(false, Validators.requiredTrue),
  });
