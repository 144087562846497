import { ApplicationRef, ComponentFactoryResolver, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import {
  AbstractFragmentPresenterClass,
  FragmentDialogTypeEnum,
  FragmentHideDialogAction,
  FragmentState,
} from '@roadrecord/fragment-dialog';
import { partnerDetailsRoutingWhenSaveAndNotResetStrategy } from '../details/partner-details-routing-when-save-and-not-reset.strategy';
import { currentHeadOrOfficeModalWrapperType, ModalWrapperComponent } from '../modal-wrapper/modal-wrapper/modal-wrapper.component';
import { PartnerModel, PartnerTypeEnum } from '@roadrecord/partner/model';
import { isNil } from '@roadrecord/type-guard';

export class PartnerEditFragmentPresenter extends AbstractFragmentPresenterClass<PartnerModel, ModalWrapperComponent> {
  private readonly type: PartnerTypeEnum;

  constructor(
    store: Store,
    matDialog: MatDialog,
    fragmentType: FragmentDialogTypeEnum,
    componentFactoryResolver: ComponentFactoryResolver,
    document: Document,
    appRef: ApplicationRef,
    injector: Injector
  ) {
    super(
      store,
      matDialog,
      componentFactoryResolver,
      fragmentType,
      ModalWrapperComponent,
      document,
      appRef,
      injector,
      partnerDetailsRoutingWhenSaveAndNotResetStrategy,
      // (wrapperCmp: ModalWrapperComponent): Observable<boolean> => this._preventCloseCb(wrapperCmp),
      ['partner-dialog'],
      {
        height: '90%',
        width: '90%',
        maxWidth: '1540px',
      }
    );

    switch (fragmentType) {
      case FragmentDialogTypeEnum.NEW_PARTNER:
      case FragmentDialogTypeEnum.EDIT_PARTNER:
        this.type = PartnerTypeEnum.PARTNER;
        break;
      case FragmentDialogTypeEnum.NEW_HEAD_OR_OFFICE_SITE:
      case FragmentDialogTypeEnum.EDIT_HEAD_OR_OFFICE_SITE:
        this.type = PartnerTypeEnum.HEAD_OFFICE_OR_SITE;
        break;
      case FragmentDialogTypeEnum.NEW_CHARGING_STATION:
      case FragmentDialogTypeEnum.EDIT_CHARGING_STATION:
        this.type = PartnerTypeEnum.CHARGING_STATION;
        break;
    }
  }

  uiCloseDialog(cancel?: boolean): void {
    this.store.dispatch(new FragmentHideDialogAction(this.fragmentType, undefined, false, cancel));
  }

  get newModel(): PartnerModel {
    if (
      this.cmpRef.instance.details.first.presenterStateController.editModelLastValue &&
      !this.cmpRef.instance.details.first.presenterStateController.isNewLastValue
    ) {
      const partnerValue = this.cmpRef.instance.details.first.presenterStateController.editModelLastValue;
      const partnerNewValue: PartnerModel = {} as PartnerModel;
      /**
       * kis hack mert mi undefined-t hasznalunk de a material form null-t add vissza :( a formModel getternel
       */
      // tslint:disable-next-line
      Object.entries(partnerValue).forEach(entry => (partnerNewValue[entry[0]] = entry[1] === null ? undefined : entry[1]));
      // ez azert kell mert az eredeti modelben related object van viszont a form id-t tarol
      partnerNewValue.trip_reason = !isNil(partnerNewValue.trip_reason) ? (partnerNewValue.trip_reason.id as any) : undefined;

      return partnerNewValue;
    } else {
      const model: Partial<PartnerModel> = {};

      model.trip_reason = !isNil(this.cmpRef.instance.details.first.dataForm.tripReasonControl.value)
        ? this.cmpRef.instance.details.first.dataForm.tripReasonControl.value
        : undefined;
      return model as PartnerModel;
    }
  }

  get formValue(): PartnerModel {
    const formValue = this.cmpRef.instance.details.first.presenterStateController.formGroupLastValue.value;
    const newFormValue: PartnerModel = {} as PartnerModel;
    /**
     * kis hack mert mi undefined-t hasznalunk de a material form null-t add vissza :( a formModel getternel
     */
    // tslint:disable-next-line
    Object.entries(formValue).forEach(entry => (newFormValue[entry[0]] = entry[1] === null ? undefined : entry[1]));
    console.log('edit', newFormValue);
    return newFormValue;
  }

  fillCmpInputs(instance: ModalWrapperComponent): void {
    currentHeadOrOfficeModalWrapperType.type = this.type;

    instance.windowEditModelId = this.store.selectSnapshot<any>(FragmentState.metadataByTypes([this.fragmentType])).windowEditModelId;
  }

  directSubmit(): void {
    this.cmpRef.instance.details.first.dataForm.onSubmit();
  }
}
