import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { RRTwoWaySwitchFieldComponent } from './rr-two-way-switch-field.component';
import { TwoWaySwitchFormControlModule } from '@roadrecord/common/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'rr-two-way-switch',
          component: RRTwoWaySwitchFieldComponent,
          defaultOptions: { templateOptions: { disableAnimationWrapper: true } },
        },
      ],
    }),
    TwoWaySwitchFormControlModule,
    ReactiveFormsModule,
    MatTooltipModule,
    TranslocoModule,
  ],
  declarations: [RRTwoWaySwitchFieldComponent],
})
export class RRTwoWaySwitchFieldModule {}
