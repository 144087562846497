import {
  LiveAutocompleDisplayFunction,
  LiveAutocompleteOptionDisabledBindFunction,
  LiveAutocompleteOptionDisplayBindFunction,
  LiveAutocompleteOptionModelIconFunction,
  LiveAutoCompleteOptionsConfigModel,
  LiveAutocompleteOptionValueBindFunction,
  LiveAutocompletePrefixIconFunction,
} from '@roadrecord/live-auto-complete';
import { isNil, isString } from '@roadrecord/type-guard';
import { VehicleHuModel } from '@roadrecord/vehicle/model/hu';

const getIconNameFromUrl = (url: string): string => {
  if (url === undefined) {
    return '';
  } else {
    return url.split('/').pop().split('.').shift();
  }
};

const laVehicleOptionDisabledBindFn: LiveAutocompleteOptionDisabledBindFunction<VehicleHuModel> = (
  model: VehicleHuModel,
  selectedModel: VehicleHuModel
) => model.id === selectedModel.id;

const laVehicleOptionDisplayFn: LiveAutocompleteOptionDisplayBindFunction<VehicleHuModel> = (model: VehicleHuModel) => model.plate_number;
const laVehicleOptionValueBindFn: LiveAutocompleteOptionValueBindFunction<VehicleHuModel> = (model: VehicleHuModel) =>
  !isNil(model) ? model.id : null;
const laVehiclePrefixIconFn: LiveAutocompletePrefixIconFunction<VehicleHuModel> = (model: VehicleHuModel) =>
  isString(model) ? undefined : getIconNameFromUrl(model.icon);
const laVehicleOptionModelIconFn: LiveAutocompleteOptionModelIconFunction<VehicleHuModel> = (model: VehicleHuModel) => ({
  icon: getIconNameFromUrl(model.icon),
});
const laVehicleDisplayFn: LiveAutocompleDisplayFunction<VehicleHuModel> = (model: VehicleHuModel) => model.plate_number;

export const laVehicleOptionsConfig = new LiveAutoCompleteOptionsConfigModel({
  optionDisabledBindFn: laVehicleOptionDisabledBindFn,
  optionDisplayFn: laVehicleOptionDisplayFn,
  optionValueBindFn: laVehicleOptionValueBindFn,
  optionModelIconFn: laVehicleOptionModelIconFn,
  displayFn: laVehicleDisplayFn,
  prefixIconFn: laVehiclePrefixIconFn,
  validatorMessages: [{ errorKey: 'required', translateKey: 'VEHICLE.LIVE_AUTO_COMPLETE.VALIDATOR.REQUIRED' }],
  label: 'VEHICLE.LIVE_AUTO_COMPLETE.LABEL',
  placeholder: 'VEHICLE.LIVE_AUTO_COMPLETE.PLACEHOLDER',
});
