import * as countries from 'i18n-iso-countries';
import { GridColumnModel } from '@roadrecord/grid';
import { TranslocoService } from '@ngneat/transloco';
import { PartnerAddressFormatterReturnType, PartnerListModel } from '@roadrecord/partner/model';
import { getPartnerListPostcodeAndAddressColumns } from './get-partner-list-postcode-and-address-column.function';

// tslint:disable
export const commonPartnerListColumnConfig: (_this: {
  translocoService: TranslocoService;
  partnerAddressFormatter: PartnerAddressFormatterReturnType;
}) => GridColumnModel<PartnerListModel>[] = (_this: {
  translocoService: TranslocoService;
  partnerAddressFormatter: PartnerAddressFormatterReturnType;
}): GridColumnModel<PartnerListModel>[] => {
  let _currentLangCode = _this.translocoService.getActiveLang();
  let currentLangCode = _this.translocoService.getActiveLang().split('-')[0].toUpperCase();
  let countriesNames = countries.getNames(currentLangCode);
  return [
    { name: 'name', displayNameTranslateKey: 'PARTNER.LIST.COLUMN.NAME' },
    {
      name: 'trip_reason',
      displayNameTranslateKey: 'PARTNER.LIST.COLUMN.TRIP_REASON',
      cellContentTemplateCallback: model => model.trip_reason.trip_reason,
    },
    ...getPartnerListPostcodeAndAddressColumns(_this.partnerAddressFormatter),
    {
      name: 'country',
      displayNameTranslateKey: 'PARTNER.LIST.COLUMN.COUNTRY',
      cellContentTemplateCallback: model => {
        if (_this.translocoService.getActiveLang() !== _currentLangCode) {
          _currentLangCode = _this.translocoService.getActiveLang();
          currentLangCode = _this.translocoService.getActiveLang().split('-')[0].toUpperCase();
          countriesNames = countries.getNames(currentLangCode);
        }
        return countriesNames[model.address.country];
      },
    },
  ];
};
