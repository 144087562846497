<ng-template #content>
  <mat-card class="rr-card2">
    <mat-progress-bar *ngIf="loading$ | async" color="warn" mode="indeterminate"></mat-progress-bar>
    <mat-card-content>
      <div class="card-content-text" *ngIf="!loadCompanyRegistrationComplete">
        <h2 class="mb-1 centered-registration-title title-height">{{ 'USER.REGISTER.TRY_IT' | transloco }}</h2>
        <h3 class="mb-0">{{ 'USER.REGISTER.TRY_IT_AFTER_TEXT' | transloco }}</h3>
      </div>
      <img class="navallobadge" src="/assets/images/navallobadge/nav-allo-emblema-websitera.svg" />
      <form (keyup.enter)="onKeyupForm($event)" [formGroup]="form" fxLayout="column" fxLayoutAlign="center stretch" novalidate>
        <rr-formly-form class="w-100" [form]="form" [fields]="nameFormFragmentFormlyFields"></rr-formly-form>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
          <mat-form-field class="wid100 x--company-name">
            <mat-label>{{ 'USER.REGISTER.COMPANY_NAME' | transloco }}</mat-label>
            <input
              [placeholder]="'USER.REGISTER.EX_COMPANY_NAME' | transloco"
              class="input-element"
              formControlName="companyName"
              matInput
              name="companyName"
              [required]="isAmericanVersion === false"
            />
            <mat-error><rr-validation-messages [errors]="companyNameControl?.errors"></rr-validation-messages></mat-error>
            <mat-hint>{{ 'USER.REGISTER.EX_COMPANY_NAME' | transloco }}</mat-hint>
          </mat-form-field>
          <mat-form-field class="wid100 x--area-of-interest">
            <mat-label>{{ 'USER.REGISTER.AREA_OF_INTEREST.LABEL' | transloco }}</mat-label>
            <mat-select [formControl]="areaOfInterestControl" required name="area_of_interest" panelClass="area_of_interest">
              <mat-option *ngFor="let entry of AreaOfInterestEnum" [value]="entry[1] + ''">
                {{ 'USER.REGISTER.AREA_OF_INTEREST.VALUE.' + entry[0] | transloco }}
              </mat-option>
            </mat-select>
            <mat-error><rr-validation-messages [errors]="areaOfInterestControl?.errors"></rr-validation-messages></mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
          <mat-form-field class="wid100 x--email" *ngIf="showEmail">
            <mat-label>{{ 'USER.REGISTER.EMAIL' | transloco }}</mat-label>
            <input
              [placeholder]="'USER.REGISTER.EX_EMAIL' | transloco"
              class="input-element"
              formControlName="email"
              matInput
              name="email"
              required
              type="email"
            />
            <mat-error>
              <rr-validation-messages [errors]="emailControl?.errors" [messages]="emailControlValidationMessages"></rr-validation-messages>
            </mat-error>
            <mat-hint *ngIf="showEmailHint">{{ 'USER.REGISTER.HINT.EMAIL' | transloco }}</mat-hint>
          </mat-form-field>
          <mat-form-field class="wid100 x--phone-number" *ngIf="null | appTypeIsHu; else usTpl">
            <mat-label>{{ 'USER.REGISTER.PHONE_NUMBER' | transloco }}</mat-label>
            <input
              [placeholder]="'USER.REGISTER.EX_PHONE_NUMBER' | transloco"
              class="input-element phone-input-element"
              [imask]="telephoneMask"
              unmask="typed"
              formControlName="phoneNumber"
              matInput
              name="phoneNumber"
              required
              type="text"
            />
            <span class="phone-prefix" matPrefix>{{ 'USER.REGISTER.PHONE_NUMBER_PREFIX' | transloco }}&nbsp;</span>
            <mat-error>
              <rr-validation-messages
                [errors]="phoneNumberControl?.errors"
                [messages]="phoneControlValidationMessages"
              ></rr-validation-messages>
            </mat-error>
            <mat-hint *ngIf="phoneNumberControl?.value?.length === 0">{{ 'USER.REGISTER.HINT.PHONE_NUMBER' | transloco }}</mat-hint>
          </mat-form-field>
          <ng-template #usTpl
            ><mat-form-field class="wid100 x--phone-number">
              <mat-label>{{ 'USER.REGISTER.PHONE_NUMBER' | transloco }}</mat-label>
              <input
                [imask]="{ mask: '{(}000{)}000{-}0000', parse: parseUsPhoneNumber, lazy: false }"
                unmask="typed"
                [placeholder]="'USER.REGISTER.EX_PHONE_NUMBER' | transloco"
                class="input-element phone-input-element"
                formControlName="phoneNumber"
                matInput
                name="phoneNumber"
                required
                type="text"
              />
              <span class="phone-prefix" matPrefix>{{ 'USER.REGISTER.PHONE_NUMBER_PREFIX' | transloco }}&nbsp;</span>
              <mat-error>
                <rr-validation-messages
                  [errors]="phoneNumberControl?.errors"
                  [messages]="phoneControlValidationMessages"
                ></rr-validation-messages>
              </mat-error>
              <mat-hint *ngIf="phoneNumberControl?.value?.length === 0">{{ 'USER.REGISTER.HINT.PHONE_NUMBER' | transloco }}</mat-hint>
            </mat-form-field></ng-template
          >
        </div>

        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="1em" *ngIf="showPassword">
          <mat-form-field class="wid100 x--password" rrPreventClick data-cy="password-control">
            <mat-label>{{ 'USER.REGISTER.PASSWORD' | transloco }}</mat-label>
            <input
              [placeholder]="'USER.REGISTER.PASSWORD' | transloco"
              class="input-element"
              formControlName="password"
              matInput
              name="password"
              required
              [type]="passwordType"
            />
            <mat-hint class="password-hint" *ngIf="passwordControl.invalid || passwordControl?.value?.length === 0">{{
              'COMMON.VALIDATION.PASSWORD_PATTERN' | transloco
            }}</mat-hint>
            <button tabindex="-1" type="button" mat-icon-button matSuffix (click)="changeVisibilityPassword()" rrPreventClick>
              <mat-icon
                class="visible-icon"
                [matTooltip]="(!visiblePassword ? 'COMMON.TOOLTIP.PASSWORD_SHOW' : 'COMMON.TOOLTIP.PASSWORD_HIDE') | transloco"
                matTooltipPosition="left"
                [svgIcon]="!visiblePassword ? 'eye' : 'eye-off'"
              >
              </mat-icon>
            </button>
            <mat-error>
              <rr-validation-messages
                [errors]="passwordControl?.errors"
                [messages]="passwordControlValidationMessages"
              ></rr-validation-messages>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="wid100 x--confirm-password" data-cy="confirm-password-control">
            <mat-label>{{ 'USER.REGISTER.CONFIRM_PASSWORD' | transloco }}</mat-label>
            <input
              [placeholder]="'USER.REGISTER.CONFIRM_PASSWORD' | transloco"
              class="input-element"
              formControlName="confirmPassword"
              matInput
              name="confirmPassword"
              required
              [type]="passwordType"
            />
            <button tabindex="-1" type="button" mat-icon-button matSuffix (click)="changeVisibilityPassword()" rrPreventClick>
              <mat-icon
                class="visible-icon"
                [matTooltip]="(!visiblePassword ? 'COMMON.TOOLTIP.PASSWORD_SHOW' : 'COMMON.TOOLTIP.PASSWORD_HIDE') | transloco"
                matTooltipPosition="left"
                [svgIcon]="!visiblePassword ? 'eye' : 'eye-off'"
              >
              </mat-icon>
            </button>
            <mat-error>
              <rr-validation-messages
                [errors]="confirmPasswordControl?.errors"
                [messages]="passwordControlValidationMessages"
              ></rr-validation-messages>
            </mat-error>
          </mat-form-field>
        </div>

        <div
          class="checkbox-area-wrapper"
          [ngStyle]="loadCompanyRegistrationComplete ? { 'margin-top': '40px' } : undefined"
          fxLayout="column"
          fxLayoutAlign="start start"
        >
          <mat-checkbox class="magazine-cb" formControlName="magazineSubscribe" name="magazineSubscribe">
            {{ 'USER.REGISTER.SUBSCRIBE_MAGAZINE' | transloco }}
          </mat-checkbox>
          <mat-checkbox class="statement-cb" formControlName="privacyStatement" name="privacyStatement" *ngIf="showPrivacyCheckBox">
            {{ 'USER.REGISTER.ACCEPT' | transloco }}
            <a class="privacy-statement-link" [href]="'USER.REGISTER.LINKS.PRIVACY_POLICY' | transloco" target="_blank">{{
              'USER.REGISTER.PRIVACY_STATEMENT' | transloco
            }}</a>
          </mat-checkbox>
          <mat-error *ngIf="showPrivacyCheckBox" class="privacy-statement-error">
            <rr-validation-messages
              *ngIf="form['submitted']"
              [errors]="privacyStatementControl?.errors"
              [messages]="[{ errorKey: 'required', translateKey: 'USER.REGISTER.PRIVACY_STATEMENT_REQUIED' }]"
            ></rr-validation-messages>
          </mat-error>
        </div>
        <div fxFlexOffset="20px" fxLayout="row" fxLayoutAlign="center center">
          <button
            (click)="onSubmit()"
            [disabled]="(form['submitted'] && form.invalid) || form.disabled"
            [ngClass]="{
              'invalid-form': form['submitted'] && form.invalid,
              'w-100': isMobileOrTablet,
              'mb-3': loadCompanyRegistrationComplete
            }"
            class="submit-button"
            color="primary"
            mat-raised-button
            type="submit"
          >
            <div
              *ngIf="form['submitted'] && form.invalid"
              class="invalid-form-button-overlay"
              matTooltip="{{ form['submitted'] === true && form.invalid ? ('COMMON.VALIDATION.BUTTON' | transloco) : undefined }}"
              matTooltipPosition="left"
            >
              <mat-icon svgIcon="alert"></mat-icon>
            </div>
            {{ submitLabel | transloco }}
          </button>
        </div>
      </form>
      <div class="zero-spam" *ngIf="!loadCompanyRegistrationComplete">{{ 'USER.REGISTER.YOUR_DATAS' | transloco }}</div>
      <div
        *ngIf="null | appTypeIsUs"
        class="terms-and-privacy"
        [innerHTML]="'USER.REGISTER.TERMS_AND_PRIVACY' | transloco | sanitizeHtml"
      ></div>
    </mat-card-content>
  </mat-card>

  <rr-social-buttons-card
    [form]="form"
    *ngIf="!loadCompanyRegistrationComplete"
    withEmailButtonTranslateKey="USER.SOCIAL.LOGIN_WITH_EMAIL"
    (clickWithEmail)="onClickLoginWithEmail()"
    withEmailIcon="login-variant"
  >
  </rr-social-buttons-card>

  <rr-unauthenticated-footer *ngIf="!loadCompanyRegistrationComplete"></rr-unauthenticated-footer>
</ng-template>

<div class="scroll" *ngIf="!loadCompanyRegistrationComplete; else socialFinishTpl">
  <div *ngIf="!isMobileOrTablet; else mobileOrTabletTpl" class="wrapper-component">
    <div fxFlex.gt-md fxLayout="row" fxLayoutAlign="center center">
      <div class="wid100" fxLayout="column" fxLayoutAlign="center center">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>
  </div>
  <ng-template #mobileOrTabletTpl>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-template>
</div>
<ng-template #socialFinishTpl><ng-container *ngTemplateOutlet="content"></ng-container></ng-template>
<re-captcha #captchaRef="reCaptcha" *ngIf="useCaptcha" size="invisible" [siteKey]="reCaptchaKey" (resolved)="onResolveCaptcha($event)">
</re-captcha>
